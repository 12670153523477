import React from "react";

const MailGreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M3.33325 10.6666C3.33325 8.15249 3.33325 6.89541 4.1143 6.11436C4.89535 5.33331 6.15243 5.33331 8.66659 5.33331H11.3333C13.8474 5.33331 15.1045 5.33331 15.8855 6.11436C16.6666 6.89541 16.6666 8.15249 16.6666 10.6666C16.6666 13.1808 16.6666 14.4379 15.8855 15.2189C15.1045 16 13.8474 16 11.3333 16H8.66659C6.15243 16 4.89535 16 4.1143 15.2189C3.33325 14.4379 3.33325 13.1808 3.33325 10.6666Z"
        fill="#2C8B52"
      />
      <path
        d="M6 8L7.43926 9.19939C8.66369 10.2197 9.2759 10.7299 10 10.7299C10.7241 10.7299 11.3363 10.2197 12.5607 9.19939L14 8"
        stroke="white"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default MailGreen;
