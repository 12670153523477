import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Row,
  Col,
  ConfigProvider,
  Divider,
} from "antd";
import "tailwindcss/tailwind.css";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import PersonIconGreenOutline from "../../assets/svg/PersonIconGreenOutline";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import { NotificationContext } from "../../context/NotificationContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import PropertyService from "../../services/PropertyService";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";

const AddNewProperty = ({ open, onCancel, count, getMyProperties }) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const {
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
    getRelatedSearchedPostalCode,
  } = CreateJobPostServices();
  const { fetchPropertyMetaData, createProperty } = PropertyService();

  const [countryList, setCountryList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [postalCodeRequired, setPostalCodeRequired] = useState(true);
  const [local1SelectData, setLocation1SelectorData] = useState([]);
  const [local2SelectData, setLocation2SelectorData] = useState([]);
  const [postalCodeData, setPostalCodeData] = useState(null);
  const [allPostalCodes, setAllPostalCodes] = useState([]);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const [suburbId, setSuburbId] = useState(null);
  const [stateId, setStateId] = useState(null);

  const [labels, setLabels] = useState({
    level_1_label: "State",
    level_2_label: "Suburb",
  });

  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [isStateSelected, setIsStateSelected] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchMetaData();
    fetchCountryData();
  }, []);

  const fetchMetaData = async () => {
    try {
      await fetchPropertyMetaData({ user_id: getLocalStoragedata("userId") })
        .then((response) => {
          if (response?.data?.success) {
            var roleObject = [];
            var typeObject = [];
            response?.data?.output.property_role.forEach((role) => {
              let data = {
                value: role?.role_id,
                label: role?.role,
              };
              roleObject.push(data);
            });

            response?.data?.output.property_type.forEach((type) => {
              let data = {
                value: type?.type_id,
                label: type?.type,
              };
              typeObject.push(data);
            });

            setRoles(roleObject);
            setTypes(typeObject);
          } else {
            openNotification("warning", response?.data?.message);

            var roleObject = [];
            var typeObject = [];
            response?.data?.output.property_role.forEach((role) => {
              let data = {
                value: role?.role_id,
                label: role?.role,
              };
              roleObject.push(data);
            });

            response?.data?.output.property_type.forEach((type) => {
              let data = {
                value: type?.type_id,
                label: type?.type,
              };
              typeObject.push(data);
            });

            setRoles(roleObject);
            setTypes(typeObject);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchCountryData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            setCountryList(response?.data?.output);
            var countryObject = [];
            response?.data?.output.forEach((country) => {
              let data = {
                value: country?.country_id,
                label: country?.country,
              };
              countryObject.push(data);
            });
            setCountryData(countryObject);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleCreateProperty = async (e) => {
    setLoading(true);
    const data = {
      user_id: getLocalStoragedata("userId"),
      property_type_id: e.propertyType,
      property_role_id: e.role,
      property_name: e.propertyName,
      country_id: e.country,
      state_id: stateId,
      suburb_id: suburbId,
      address: e.address,
      is_default: count == 0 ? 1 : e.defaultProperty ? 1 : 0,
    };

    try {
      await createProperty(data)
        .then((response) => {
          if (response?.data?.success) {
            onCancel();
            getMyProperties();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }

    setLoading(false);
  };

  const fetchLocationLevel1Data = async (e) => {
    try {
      await getAllLocationLevel1Data({ country_id: e })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location1) => {
              let data = {
                value: location1?.level1_id,
                label: location1?.level1_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation1SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchLocationLevel2Data = async (e) => {
    try {
      await getAllLocationLevel2Data({
        country_id: selectedCountry,
        level1_id: e,
      })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location2) => {
              let data = {
                value: location2?.level2_id,
                label: location2?.level2_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation2SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: selectedCountry,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setAllPostalCodes(response?.data.output);

            var postalCodeObject = [];
            response?.data.output?.forEach((item) => {
              let data = {
                value: item?.suberb_id,
                label:
                  item?.postal_code + ", " + item?.suberb + ", " + item?.state,
              };
              postalCodeObject.push(data);
            });
            setPostalCodeData(postalCodeObject);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //edit button disable when not change the field
  const compareData = (initial, updated) => {
    let changes = {};

    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });

    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false); // Return null if no changes
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      {
        propertyName: null,
        propertyType: null,
        role: null,
        country: null,
        stateProvince: null,
        citySuburb: null,
        address: null,
        zip: null,
        defaultProperty: null,
      },
      allFields
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: "#e0efe2",
            headerBg: "#e0efe2",
          },
        },
      }}
    >
      <Modal
        title={
          <div className="flex justify-between items-center">
            <span className="font-bold text-2xl text-primaryDark">
              New Property
            </span>
          </div>
        }
        width={800}
        open={open}
        onCancel={() => {
          if (isFieldChanged) {
            setShowCloseConfirmation(true);
          } else {
            onCancel();
          }
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={handleCreateProperty}
          className="gap-4"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row className="flex flex-row mb-4" gutter={16}>
            <Col span={24} md={{ span: 12 }}>
              <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                <LocationIconCard />
                Property Name *
              </p>
              <Form.Item
                name="propertyName"
                rules={[
                  {
                    required: true,
                    message: "Property Name is required!",
                  },
                ]}
                className="mb-0"
              >
                <Input
                  size="large"
                  placeholder="Ex : Home"
                  maxLength={50}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.value === "") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={{ span: 12 }}>
              <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                <LocationIconCard />
                Property Type *
              </p>
              <Form.Item
                name="propertyType"
                rules={[
                  {
                    required: true,
                    message: "Property Type is required!",
                  },
                ]}
                className="mb-0"
              >
                <Select
                  size="large"
                  options={types}
                  placeholder="Select Propert Type"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="flex flex-col mb-4">
            <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
              <PersonIconGreenOutline />
              Role *
            </p>
            <Form.Item
              name="role"
              rules={[{ required: true, message: "Role is required!" }]}
              className="mb-0"
            >
              <Select
                size="large"
                options={roles}
                placeholder="Select Your Role"
              />
            </Form.Item>
          </Row>

          <Divider className="bg-primaryLight my-3" />

          <Row className="flex flex-col mb-4">
            <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
              <LocationIconCard />
              Country *
            </p>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Country is required!" }]}
              className="mb-0"
            >
              <Select
                size="large"
                placeholder="Select Country"
                options={countryData}
                onSelect={(e) => {
                  setSelectedCountry(e);

                  setIsCountrySelected(true);
                  setIsStateSelected(false);

                  form.resetFields(["zip", "stateProvince", "citySuburb"]);
                  setPostalCodeData(null);
                  setLocation1SelectorData([]);
                  setLocation2SelectorData([]);
                  setSuburbId(null);
                  setStateId(null);

                  let selectedCountry = countryList.find(
                    (country) => country.country_id === e
                  );
                  setPostalCodeRequired(
                    selectedCountry.postal_code_required === 1 ? true : false
                  );

                  setLabels({
                    level_1_label: selectedCountry?.level_1_label,
                    level_2_label: selectedCountry?.level_2_label,
                  });

                  if (selectedCountry.postal_code_required === 0) {
                    fetchLocationLevel1Data(e);
                  }
                }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Row>

          <Row className="flex flex-col mb-4">
            <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
              <LocationIconCard />
              Address *
            </p>
            <Form.Item
              name="address"
              rules={[{ required: true, message: "Address is required!" }]}
              className="mb-0"
            >
              <Input
                size="large"
                placeholder="Enter your address"
                maxLength={150}
              />
            </Form.Item>
          </Row>

          <Row className="flex flex-col mb-4">
            {postalCodeRequired && (
              <>
                <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                  <LocationIconCard />
                  Postal Code *
                </p>
                <Form.Item
                  name="zip"
                  rules={[
                    { required: true, message: "Postal Code is required!" },
                  ]}
                  className="mb-0"
                >
                  <Select
                    size="large"
                    className="w-full"
                    placeholder="Search postal code"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={postalCodeData}
                    notFoundContent={
                      postalCodeData === null
                        ? "Please enter your property postal code"
                        : "No Data"
                    }
                    onSearch={(e) => {
                      if (e?.length === 4) {
                        handleGetRelatedPostalCode(e);
                      }
                      if (e?.length < 4) {
                        setPostalCodeData(null);
                      }
                    }}
                    onSelect={(e) => {
                      let suburb = allPostalCodes.find(
                        (item) => item.suberb_id === e
                      );

                      setSuburbId(suburb.suberb_id);
                      setStateId(suburb.state_id);
                    }}
                    disabled={!isCountrySelected}
                  />
                </Form.Item>
              </>
            )}
          </Row>

          <Row className="flex flex-col mb-4">
            {!postalCodeRequired && (
              <Row className="flex flex-row" gutter={16}>
                <Col span={24} md={{ span: 12 }}>
                  <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                    <LocationIconCard />
                    {labels.level_1_label} *
                  </p>
                  <Form.Item
                    name="stateProvince"
                    rules={[
                      {
                        required: true,
                        message: `${labels.level_1_label} is required!`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Select
                      size="large"
                      placeholder={`Select ${labels.level_1_label}`}
                      options={local1SelectData}
                      onSelect={(e) => {
                        setStateId(e);
                        fetchLocationLevel2Data(e);
                        setIsStateSelected(true);
                        setSuburbId(null);
                        form.setFieldValue("citySuburb", null);
                      }}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={!isCountrySelected}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={{ span: 12 }}>
                  <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                    <LocationIconCard />
                    {labels.level_2_label} *
                  </p>
                  <Form.Item
                    name="citySuburb"
                    rules={[
                      {
                        required: true,
                        message: `${labels.level_2_label} is required!`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Select
                      size="large"
                      placeholder={`Select ${labels.level_2_label}`}
                      options={local2SelectData}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onSelect={(e) => {
                        setSuburbId(e);
                      }}
                      disabled={!isStateSelected}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Row>

          <Row className="flex flex-col mb-4">
            <Form.Item
              name="defaultProperty"
              valuePropName="checked"
              className="mb-0"
            >
              <Checkbox
                defaultChecked={count == 0 ? true : false}
                disabled={count == 0}
              >
                <div className="flex flex-col">
                  <span className="flex text-sm">Set as default property</span>
                  <p className="text-xxs text-gray-500">
                    This property will be used as a default location in your job
                    postings, you can change it anytime.
                  </p>
                </div>
              </Checkbox>
            </Form.Item>
          </Row>

          <div className="flex flex-col gap-2  mb-4"></div>

          <Form.Item className="mb-0">
            <Button
              size="large"
              htmlType="submit"
              className="w-full border-primaryDark font-semibold text-primaryDark"
              disabled={loading}
            >
              Save Property
            </Button>
          </Form.Item>
        </Form>
        {showCloseConfirmation && (
          <NotificationAlertBox
            open={showCloseConfirmation}
            onCancel={() => setShowCloseConfirmation(false)}
            content={
              <div>
                <p className="text-md font-bold text-darkestColor text-center">
                  You have unsaved changes. Would you like to save before
                  leaving?
                </p>
                <p className="text-xs text-textColorThree text-center">
                  You've made changes to property. Save before leaving the page,
                  or any unsaved changes will be lost.{" "}
                </p>

                <div className="flex justify-center items-center mt-5 gap-4">
                  <Button
                    type="default"
                    className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No, Discard
                  </Button>
                  <Button
                    type="primary"
                    className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                    onClick={() => {
                      setShowCloseConfirmation(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            }
            title="Are you sure?"
          />
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default AddNewProperty;
