import { Button, Form, Spin, Statistic } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import LoginServices from "../../services/LoginServices";
import PassCode from "../../assets/svg/PassCode";
import { InputOTP } from "antd-input-otp";
import QuickSignUpService from "../../services/QuickSignUpService";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { AuthContext } from "../../context/AuthContext";
import { maskPhoneNumber } from "../../helpers/phoneNumberStructure/PhoneNumberStructure";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";
import WorkerSignUpLayout from "./WorkerSignUpLayout";

const SetOTP = () => {
  let { openNotification, handleError } = useContext(NotificationContext);
  let { setToken } = useContext(AuthContext);
  const { getOtpByMsg, verifyByMsgOtp, getAllAuthUserData } = LoginServices();
  const { userRegister } = QuickSignUpService();
  const { setStepperWorkerCurrentSteps, workerFormValues } =
    useContext(WorkerSignUpContext);

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState(null);

  const [countDownTime, setCountDownTime] = useState(0);
  const [blockCountDownTime, setBlockCountDownTime] = useState(0);

  const [enteredOTPValue, setEnteredOTPValue] = useState();
  const [attemptReleaseTime, setAttemptReleaseTime] = useState(0);
  const [otpReference, setOtpReference] = useState();

  const { Countdown } = Statistic;
  const deadline = Date.now() + 1000 * 60 * 2;

  useEffect(() => {
    generateOTP();

    //button enter
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //generate
  const generateOTP = async () => {
    setPageLoading(true);
    setEnteredOTPValue();
    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "App-Type": 1, //worker
      },
    };
    try {
      await getOtpByMsg(
        {
          country_id: workerFormValues?.country,
          mobile_number: workerFormValues?.phoneNumber,
          sms_type: 1,
          should_generate: 1,
          is_web: 1,
        },
        config
      )
        .then((response) => {
          if (response?.data?.success) {
            setAttemptReleaseTime(response?.data?.output?.attempt_release_time);
            //if attept count was exceed get blocked time
            const attemptReleaseTimeInMillis =
              response?.data?.output?.attempt_release_time * 1000;
            setBlockCountDownTime(Date.now() + attemptReleaseTimeInMillis);
            setCountDownTime(deadline);
            setOtpReference(response?.data?.output?.reference);
            setPageLoading(false);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //countdown finshed
  const onFinishCountDown = () => {
    setCountDownTime(0);
  };

  //block countdown finshed
  const onFinishBlockCountDown = () => {
    setAttemptReleaseTime(0);
    setCountDownTime(0);
  };

  //verify OTP
  const handleOTPVerification = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "App-Type": 1, //worker
      },
    };
    try {
      await verifyByMsgOtp(
        {
          mobile_number: workerFormValues?.phoneNumber,
          otp: enteredOTPValue,
          reference: otpReference,
        },
        config
      )
        .then((response) => {
          if (response?.data?.success) {
            handleUserRegistration();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  //user registration
  const handleUserRegistration = async () => {
    try {
      await userRegister({
        user_type_id: 6, // (2 : customer 6: worker)
        full_name: encryptSecureData(workerFormValues?.name),
        last_name: "",
        street_address: "",
        email: workerFormValues?.email,
        company_name: null,
        abn: null,
        dl: "",
        apt: "",
        country_id: workerFormValues?.country,
        city: "",
        state_id: workerFormValues?.state,
        postal_code: workerFormValues?.postalCode,
        suberb_id: workerFormValues?.suburb,
        identification_key: encryptSecureData(workerFormValues?.phoneNumber),
        dial_code: workerFormValues?.dialCode,
        country_code: workerFormValues?.countryCode,
        push_id:
          "eoM0ifgwQHSWs89sUxLAnl:APA91bHO41NKQ9XAx-rBkuQIkoqTZDDCbGMSHLIsQPWqf-6IDmvqwuncygE6D52sC_XGbzrHxPjFQPhx5vK4RkitQUSd7T0gWIcOX_z7vDf558Oo0pV48A3Tpezlfnbp4-C11QCXFRgf",
        terms_id: 2,
        device_id:
          "cd003f2740985f95a88eb36aa5759a6ae114b1a4b61b62dc87b7ac23ad3918ac",
        dob: null,
        gender_id: null,
        os_type: 1,
      })
        .then((response) => {
          if (response?.data?.success) {
            setToken(response?.data?.output?.token);
            setLocalStorageData("token", response?.data?.output?.token);
            setLocalStorageData("userId", response?.data?.output?.user_id);
            getAuthUserData(response?.data?.output?.token);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  //get all auth user data
  const getAuthUserData = async (token) => {
    let config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Custom-Header": "random-value-1234567890",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await getAllAuthUserData(config)
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("userId", response?.data?.output?.user_id);
            setLocalStorageData("userData", response?.data?.output);
            setLocalStorageData(
              "profilePic",
              response?.data?.output?.profile_picture
            );
            setLocalStorageData("userName", response?.data?.output?.first_name);
            // setCurrentUserData(response?.data?.output);
            setStepperWorkerCurrentSteps(2);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };
  return (
    <WorkerSignUpLayout>
      <div className="w-full sm:max-w-[600px]">
        {pageLoading ? (
          <div className="flex flex-col justify-center items-center">
            <Spin />
          </div>
        ) : (
          <>
            {attemptReleaseTime === 0 ? (
              //if phone number was not blocked.................................................
              <div className="bg-primaryLightest py-5 px-10 rounded-lg">
                <p className="text-[20px] md:text-[32px] font-bold text-primaryDarkest">
                  Enter OTP to continue
                </p>

                <p className="text-base mb-5">
                  A six-digit verification code has been sent to{" "}
                  <span className="font-bold">
                    {maskPhoneNumber(workerFormValues?.phoneNumber)}
                  </span>{" "}
                  Enter the verification code to continue sign up
                </p>
                <Form
                  className="flex flex-col gap-2 w-full"
                  onFinish={handleOTPVerification}
                >
                  {/* OTP input */}
                  <span className="flex flex-row gap-2">
                    <PassCode />
                    <p className="text-sm font-medium">Enter the OTP *</p>
                  </span>

                  <div>
                    <Form.Item
                      name="pin"
                      className="flex items-start w-full mb-0"
                      rules={[
                        {
                          required: true,
                          message: "PIN is required!",
                        },
                        // { min: 1, message: "Invalid PIN!" },
                      ]}
                    >
                      <InputOTP
                        type="password"
                        autoFocus
                        onChange={(e) => {
                          setEnteredOTPValue(e.join(""));
                          setError(null);
                        }}
                        length={6}
                        inputType="numeric"
                        inputClassName="w-full"
                        className="!md:w-[50px] !h-[50px] !max-w-60"
                      />
                    </Form.Item>
                  </div>

                  {error && (
                    <p className="text-sm font-medium text-warringColorOne">
                      Incorrect PIN. Please try again.
                    </p>
                  )}

                  <div className="flex justify-center items-center">
                    {countDownTime === 0 ? (
                      <p
                        className="cursor-pointer underline text-[20px] font-medium text-primaryDarkest"
                        onClick={() => {
                          generateOTP();
                        }}
                      >
                        Resend
                      </p>
                    ) : (
                      <Countdown
                        value={countDownTime}
                        onFinish={onFinishCountDown}
                        format="mm:ss"
                        valueStyle={{
                          fontSize: "30px",
                          fontWeight: 700,
                          color: "#2C8B52",
                        }}
                      />
                    )}
                  </div>

                  <Form.Item>
                    <Button
                      type="primary"
                      className="w-full bg-primaryDarkest text-base font-bold h-12 rounded-lg text-white "
                      loading={loading}
                      htmlType="submit"
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              //if phone number was blocked......................................................
              <div className="flex flex-col justify-center items-center bg-primaryLightest py-5 px-10 rounded-lg">
                <p className="text-[20px] md:text-[30px] font-bold text-warringColorOne">
                  Failed !
                </p>

                <p className="text-base mb-5">
                  You cannot resend OTPs due to multiple number of
                  failed attempts.
                </p>

                <Countdown
                  value={blockCountDownTime}
                  onFinish={onFinishBlockCountDown}
                  format="mm:ss"
                  valueStyle={{
                    fontSize: "30px",
                    fontWeight: 700,
                    color: "#2C8B52",
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </WorkerSignUpLayout>
  );
};

export default SetOTP;
