import React, { useContext, useEffect, useState } from "react";
import { JobPostSummary } from "../../components/createJobPost/JobPostSummary";
import NavBar from "../../components/navbar/NavBar";
import JobpostLayout from "./jobpostLayout/JobpostLayout";
import { WorkerCountCircle } from "../../components/createJobPost/WorkerCountCircle";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import CreateJobPostHelpers from "../../helpers/apiHelpers/CreateJobPostHelpers";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";

const SummaryAndPost = () => {
  const [showSummary, setShowSummary] = useState(false);
  const {
    selectedJobDetailsData,
    taskerListCount,
    selectedTaskerArrayForJobPost,
  } = useContext(CreateJobPostContext);

  const { fetchAllTaskerListCount } = CreateJobPostHelpers();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSummary(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    //get tasker list count
    // fetchAllTaskerListCount({
    //   job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
    //   postal_code: selectedJobDetailsData?.propertyData?.suburb_id,
    //   country_id: selectedJobDetailsData?.propertyData?.country_id,
    // });
  }, []);

  return (
    <>
      <NavBar />
      <div className="content">
        <div className="flex flex-col w-full justify-center items-center">
          <JobpostLayout>
            <div className="w-full flex flex-col items-center p-2 sm:mt-6 xxsm:mt-2">
              <p className="font-bold text-[32px] text-primaryDarkest">
                Job Summary
              </p>

              {/* {!showSummary ? (
                <div className="mb-10">
                  <WorkerCountCircle
                    width="290px"
                    height="290px"
                    fontSize={45}
                    textSize={25}
                    taskerListCount={taskerListCount}
                  />
                </div>
              ) : ( */}
              <JobPostSummary
                taskerListCount={selectedTaskerArrayForJobPost?.length}
              />
              {/* )} */}
            </div>
          </JobpostLayout>
        </div>
      </div>
    </>
  );
};

export default SummaryAndPost;

// import React, { useContext, useEffect, useState } from "react";
// import { JobPostSummary } from "../../components/createJobPost/JobPostSummary";
// import NavBar from "../../components/navbar/NavBar";
// import JobpostLayout from "./jobpostLayout/JobpostLayout";
// import { WorkerCountCircle } from "../../components/createJobPost/WorkerCountCircle";
// import { CreateJobPostContext } from "../../context/CreateJobPostContext";
// import CreateJobPostHelpers from "../../helpers/apiHelpers/CreateJobPostHelpers";
// import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";

// const SummaryAndPost = () => {
//   const [showSummary, setShowSummary] = useState(false);
//   const { selectedJobDetailsData, taskerListCount } =
//     useContext(CreateJobPostContext);

//   const { fetchAllTaskerListCount } = CreateJobPostHelpers();

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowSummary(true);
//     }, 3000);

//     return () => clearTimeout(timer);
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);

//     //get tasker list count
//     fetchAllTaskerListCount({
//       job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
//       postal_code: selectedJobDetailsData?.propertyData?.suburb_id,
//       country_id: selectedJobDetailsData?.propertyData?.country_id,
//     });
//   }, []);

//   return (
//     <>
//       <NavBar />
//       <div className="content">
//         <div className="flex flex-col w-full justify-center items-center">
//           <JobpostLayout>
//             <div className="w-full flex flex-col items-center p-2 sm:mt-6 xxsm:mt-2">
//               <p className="font-bold text-[32px] text-primaryDarkest">
//                 Job Summary
//               </p>

//               {!showSummary ? (
//                 <div className="mb-10">
//                   <WorkerCountCircle
//                     width="290px"
//                     height="290px"
//                     fontSize={45}
//                     textSize={25}
//                     taskerListCount={taskerListCount}
//                   />
//                 </div>
//               ) : (
//                 <JobPostSummary taskerListCount={taskerListCount} />
//               )}
//             </div>
//           </JobpostLayout>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SummaryAndPost;
