import React from "react";

const NavbarLogout = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80176 8C8.81144 6.25998 8.8886 5.31765 9.50331 4.70294C10.2063 4 11.3376 4 13.6004 4H14.4004C16.6631 4 17.7945 4 18.4974 4.70294C19.2004 5.40589 19.2004 6.53726 19.2004 8.8V15.2C19.2004 17.4627 19.2004 18.5941 18.4974 19.2971C17.7945 20 16.6631 20 14.4004 20H13.6004C11.3376 20 10.2063 20 9.50331 19.2971C8.8886 18.6824 8.81144 17.74 8.80176 16"
        stroke="#939292"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.7998 18C6.91419 18 5.97138 18 5.38559 17.4142C4.7998 16.8284 4.7998 15.8856 4.7998 14V10C4.7998 8.11438 4.7998 7.17157 5.38559 6.58579C5.97138 6 6.91419 6 8.7998 6"
        stroke="#939292"
        strokeWidth="1.5"
      />
      <path
        d="M14.4002 12.0004L7.2002 12.0004M7.2002 12.0004L8.8002 13.6004M7.2002 12.0004L8.8002 10.4004"
        stroke="#939292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavbarLogout;
