import { useContext } from "react";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import { NotificationContext } from "../../context/NotificationContext";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { decryptSecureData } from "../encryptHelpers/encryption";
import { getLocalStoragedata } from "../encryptHelpers/storageHelper";
import { useNavigate } from "react-router-dom";

const CreateJobPostHelpers = () => {
  const navigate = useNavigate();
  const { openNotification, handleError } = useContext(NotificationContext);
  const {
    setTaskerListCount,
    setJobSummarySteps,
    setPostedJobPostId,
    setJobPostLoading,
    setJobPostList,
    selectedTaskerArrayForJobPost,
  } = useContext(CreateJobPostContext);

  const {
    getAllTakerCount,
    postTheJob,
    postJobAnswer,
    postWebJobAnswer,
    getAllJobPostList,
    deleteJobPost,
  } = CreateJobPostServices();

  //get tasker list count............................................................
  const fetchAllTaskerListCount = async (data) => {
    try {
      await getAllTakerCount(data)
        .then((response) => {
          if (response?.data?.success) {
            setTaskerListCount(response?.data?.output?.count);
          } else {
            setTaskerListCount(0);
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //post the tasker job post............................................................
  const postTheTaskerJobPost = async (data) => {
    setJobPostLoading(true);
    try {
      await postTheJob(data)
        .then((response) => {
          if (response?.data?.success) {
            setPostedJobPostId(response?.data?.output?.job_id);
            postTaskerJobPostAnswer(response?.data?.output?.job_id);
          } else {
            openNotification("warning", response?.data?.message);
            setJobPostLoading(false);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //post the tasker job post answer............................................................
  const postTaskerJobPostAnswer = async (jobTypeId) => {
    try {
      await postWebJobAnswer({
        job_id: jobTypeId,
        customer_name: decryptSecureData(
          getLocalStoragedata("userData")?.first_name
        ),
        questions: [],
        worker_ids: selectedTaskerArrayForJobPost,
      })
        .then((response) => {
          if (response?.data?.success) {
            setJobPostLoading(false);
            navigate("/job-post-success");
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };
  // const postTaskerJobPostAnswer = async (jobTypeId) => {
  //   try {
  //     await postJobAnswer({
  //       job_id: jobTypeId,
  //       customer_name: decryptSecureData(
  //         getLocalStoragedata("userData")?.first_name
  //       ),
  //       is_resubmit: 0,
  //       questions: [],
  //     })
  //       .then((response) => {
  //         if (response?.data?.success) {
  //           setJobPostLoading(false);
  //           navigate("/job-post-success");
  //         } else {
  //           openNotification("warning", response?.data?.message);
  //         }
  //       })
  //       .catch((error) => {
  //         handleError(error);
  //       });
  //   } catch (error) {
  //     openNotification("error", error);
  //   }
  // };

  //get all job Post list...................................................................................
  const handleGetAllJobPostList = async (data) => {
    try {
      await getAllJobPostList(data)
        .then((response) => {
          if (response?.data?.success) {
            setJobPostList({
              jobCount: response?.data?.output?.job_count,
              jobList: response?.data?.output?.jobs,
            });
          } else {
            openNotification("warning", response?.data?.message);
            setJobPostList({
              jobCount: 0,
              jobList: null,
            });
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //delete all job Post list...................................................................................
  const handleDeleteJobPost = async (data) => {
    try {
      await deleteJobPost(data)
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
            setJobPostList({
              jobCount: 0,
              jobList: null,
            });
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return {
    fetchAllTaskerListCount,
    postTheTaskerJobPost,
    postTaskerJobPostAnswer,

    handleGetAllJobPostList,
    handleDeleteJobPost,
  };
};

export default CreateJobPostHelpers;
