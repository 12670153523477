import React, { useContext, useEffect, useState } from "react";
import WorkerSignUpLayout from "./WorkerSignUpLayout";
import PersonIconGreenOutline from "../../assets/svg/PersonIconGreenOutline";
import { Button, Checkbox, Form, Input, Select, Typography } from "antd";
import CountryGreenOutlineIcon from "../../assets/svg/CountryGreenOutlineIcon";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import EmailGreenOutline from "../../assets/svg/EmailGreenOutline";
import ContactIcon from "../../assets/svg/ContactIcon";
import PhoneInput from "react-phone-input-2";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";
import { NotificationContext } from "../../context/NotificationContext";
import LoginServices from "../../services/LoginServices";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import axios from "axios";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { Link } from "react-router-dom";

const PersonalInfo = () => {
  const {
    setStepperWorkerCurrentSteps,
    workerFormValues,
    setWorkerFormValues,
  } = useContext(WorkerSignUpContext);
  const { Text } = Typography;

  let { openNotification, handleError } = useContext(NotificationContext);
  const { phoneNumberVerification } = LoginServices();
  const {
    getAllCountries,
    getRelatedSearchedPostalCode,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
  } = CreateJobPostServices();

  const [countryCode, setCountryCode] = useState("au");
  const [countryCodeList, setCountryCodeList] = useState([
    "au",
    "lk",
    "nz",
    "sg",
    "id",
  ]);
  const [postalCodeSelectorData, setPostalCodeSelectorData] = useState([]);
  //all postal code data
  const [postalCodeListWithAllData, setPostalCodeListWithAllData] = useState(
    []
  );
  const [phoneNumberFieldMinLength, setPhoneNumberFieldMinLength] =
    useState(10);
  const [phoneNumberFieldMaxLength, setPhoneNumberFieldMaxLength] =
    useState(15);
  const [allCountryListDetails, setAllCountryListDetails] = useState([]);
  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [location1SelectorData, setLocation1SelectorData] = useState([]);
  const [location2SelectorData, setLocation2SelectorData] = useState([]);

  const [selectedCountryTypeDetails, setSelectedCountryTypeDetails] = useState({
    postal_code_required: workerFormValues?.isPostalCodeRequired === 0 ? 0 : 1,
    level_1_label: "State",
    level_2_label: "Suburb",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCountryData();

    if (!workerFormValues?.phoneNumber) {
      fetchCountryCode();
    }

    if (workerFormValues.postalCode) {
      handleGetRelatedPostalCode(workerFormValues.postalCode);
    }

    if (workerFormValues.country) {
      fetchLocationLevel1Data(workerFormValues.country);
      fetchLocationLevel2Data(workerFormValues.state);
    }

    //button enter
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //get country code by IP address
  const fetchCountryCode = async () => {
    try {
      const { data } = await axios.get("https://ipapi.co/json/");
      if (data && data.country_code) {
        setCountryCode(data.country_code.toLowerCase());
      }
    } catch (error) {
      // handleError(error);
      console.log(error);
    }
  };

  //get Country list
  const fetchCountryData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //set country selector dropdown values
  const setCountryData = async (countries) => {
    setAllCountryListDetails(countries);
    //set default country according to the IP address
    const countryCodeList = await countries?.map((item) => {
      return item?.country_code?.toLowerCase();
    });
    setCountryCodeList(countryCodeList);

    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  //get location level 1 list
  const fetchLocationLevel1Data = async (data) => {
    try {
      await getAllLocationLevel1Data({ country_id: data })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel1Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel1Data = (locations1) => {
    var locationLevel1Object = [];
    locations1?.forEach((location1) => {
      let data = {
        value: location1?.level1_id,
        label: location1?.level1_location,
      };
      locationLevel1Object.push(data);
    });
    setLocation1SelectorData(locationLevel1Object);
  };

  //get location level 2 list
  const fetchLocationLevel2Data = async (data) => {
    try {
      await getAllLocationLevel2Data({
        country_id: workerFormValues?.country,
        level1_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel2Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel2Data = (locations2) => {
    var locationLevel2Object = [];
    locations2?.forEach((location2) => {
      let data = {
        value: location2?.level2_id,
        label: location2?.level2_location,
      };
      locationLevel2Object.push(data);
    });
    setLocation2SelectorData(locationLevel2Object);
  };

  //form handle function methods
  const onFinish = async (e) => {
    try {
      await phoneNumberVerification({
        mobile_number: encryptSecureData("+" + e?.phoneNumber),
        user_type_id: 6, //if 2-client, 6-worker
      })
        .then((response) => {
          if (response?.data?.success) {
            setStepperWorkerCurrentSteps(1);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //get postalcode list
  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: workerFormValues?.country,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setPostalCodeListWithAllData(response?.data.output);
            setPostalCodeData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setPostalCodeData = (items) => {
    var postalCodeObject = [];
    items?.forEach((item) => {
      let data = {
        value: item?.suberb_id,
        label: item?.postal_code + ", " + item?.suberb + ", " + item?.state,
      };
      postalCodeObject.push(data);
    });
    setPostalCodeSelectorData(postalCodeObject);
  };

  return (
    <WorkerSignUpLayout>
      <div className="w-full sm:max-w-[600px] mb-10">
        <div className="bg-primaryLightest py-5 px-10 rounded-lg">
          <p className="text-[20px] md:text-[32px] font-bold text-primaryDarkest">
            Let's Begin With You
          </p>
          <p className="text-base mb-5 font-semibold">
            Start your task search with a short introduction, Let us connect you
            with opportunities to shine.
          </p>
          {/* <p className="text-base mb-5">
            <span className="font-bold">New to JobsNinja?</span> Sign up in 2
            minutes to continue.
          </p> */}

          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            fields={[
              { name: ["name"], value: workerFormValues?.name },
              { name: ["country"], value: workerFormValues?.country },
              { name: ["state"], value: workerFormValues?.state },
              { name: ["suburb"], value: workerFormValues?.suburb },
              { name: ["postalCode"], value: workerFormValues?.suburb },
              { name: ["email"], value: workerFormValues?.email },
              {
                name: ["phoneNumber"],
                value: workerFormValues?.phoneNumber?.replace("+", ""),
              },
            ]}
          >
            {/* Your name */}
            <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
              <PersonIconGreenOutline />
              <p className="text-sm font-medium">Your Name *</p>
            </div>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input
                size="large"
                placeholder="Enter your Name"
                maxLength={50}
                onChange={(e) => {
                  setWorkerFormValues({
                    ...workerFormValues,
                    name: e?.target?.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e?.key === " " && e?.target.value === "") {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            {/* Country */}
            <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
              <CountryGreenOutlineIcon />
              <p className="text-sm font-medium">Country *</p>
            </div>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Country is required!" }]}
            >
              <Select
                size="large"
                placeholder="Select your Country"
                options={countrySelectorData}
                onSelect={async (e) => {
                  setPostalCodeSelectorData([]);

                  fetchLocationLevel1Data(e);

                  const countryDetails = await allCountryListDetails?.find(
                    (item) => item?.country_id === e
                  );

                  setWorkerFormValues({
                    ...workerFormValues,
                    country: e,
                    isPostalCodeRequired: countryDetails?.postal_code_required,
                    isEmailRequired: countryDetails?.email_required,
                    state: null,
                    suburb: null,
                    currency: countryDetails.currency_label,
                  });

                  const choosenCountry = allCountryListDetails?.find(
                    (item) => item?.country_id === e
                  );
                  //set phonenumber field country according to the selected country
                  setCountryCode(choosenCountry?.country_code?.toLowerCase());
                  //set phone number length
                  setPhoneNumberFieldMinLength(
                    choosenCountry?.number_length + 2
                  );

                  setPhoneNumberFieldMaxLength(
                    choosenCountry?.number_max_length + 2
                  );

                  //use for location level 1 and 2 input form
                  setSelectedCountryTypeDetails({
                    postal_code_required: countryDetails?.postal_code_required,
                    level_1_label: countryDetails?.level_1_label,
                    level_2_label: countryDetails?.level_2_label,
                  });
                }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>

            {selectedCountryTypeDetails?.postal_code_required === 0 ? (
              <>
                {/* State */}
                <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
                  <LocationIconCard />
                  <p className="text-sm font-medium">
                    {selectedCountryTypeDetails?.level_1_label} *
                  </p>
                </div>
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: `${selectedCountryTypeDetails?.level_1_label} is required!`,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder={`Select your ${selectedCountryTypeDetails?.level_1_label}`}
                    options={location1SelectorData}
                    onSelect={(e) => {
                      setWorkerFormValues({
                        ...workerFormValues,
                        state: e,
                        suburb: null,
                      });
                      fetchLocationLevel2Data(e);
                    }}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>

                {/* Suburb */}
                <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
                  <LocationIconCard />
                  <p className="text-sm font-medium">
                    {selectedCountryTypeDetails?.level_2_label} *
                  </p>
                </div>
                <Form.Item
                  name="suburb"
                  rules={[
                    {
                      required: true,
                      message: `${selectedCountryTypeDetails?.level_2_label} is required!`,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder={`Select your ${selectedCountryTypeDetails?.level_2_label}`}
                    options={location2SelectorData}
                    onSelect={(e) => {
                      setWorkerFormValues({
                        ...workerFormValues,
                        suburb: e,
                      });
                    }}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </>
            ) : (
              <>
                {/* Postal code */}
                <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
                  <LocationIconCard />
                  <p className="text-sm font-medium">Postal Code *</p>
                </div>
                <Form.Item
                  name="postalCode"
                  rules={[
                    { required: true, message: "Postal code is required!" },
                  ]}
                >
                  <Select
                    size="large"
                    className="w-full"
                    placeholder="Enter postal code"
                    disabled={workerFormValues?.country === null}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={postalCodeSelectorData}
                    notFoundContent={
                      postalCodeSelectorData?.length === 0
                        ? "Please enter your property postal code"
                        : "No Data"
                    }
                    onSearch={(e) => {
                      if (e?.length === 4) {
                        handleGetRelatedPostalCode(e);
                      }
                      if (e?.length < 4) {
                        setPostalCodeSelectorData([]);
                      }
                    }}
                    onSelect={async (e) => {
                      // setSelectedSuburb(e);

                      const selectedLocation =
                        await postalCodeListWithAllData?.find(
                          (item) => item?.suberb_id === e
                        );

                      setWorkerFormValues({
                        ...workerFormValues,
                        postalCode: selectedLocation?.postal_code,
                        state: selectedLocation?.state_id,
                        suburb: selectedLocation?.suberb_id,
                      });
                    }}
                  />
                </Form.Item>
              </>
            )}

            {/* Email */}

            <div>
              <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
                <EmailGreenOutline />
                <p className="text-sm font-medium">
                  E-Mail {workerFormValues?.isEmailRequired === 1 && "*"}
                </p>
              </div>
              <Form.Item
                name="email"
                rules={[
                  workerFormValues?.isEmailRequired === 1 && {
                    required: true,
                    message: "Email is required!",
                  },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter email"
                  maxLength={100}
                  onChange={(e) => {
                    setWorkerFormValues({
                      ...workerFormValues,
                      email: e?.target?.value,
                    });
                  }}
                />
              </Form.Item>
            </div>

            {/* Phone Number */}
            <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
              <ContactIcon />
              <p className="text-sm font-medium">Phone Number *</p>
            </div>
            <Form.Item
              name="phoneNumber"
              className="text-start mb-3"
              rules={[
                {
                  required: true,
                  message: "Phone number is required!",
                },

                {
                  min: phoneNumberFieldMinLength,
                  message: "Invalid phone number!",
                },
                {
                  max: phoneNumberFieldMaxLength,
                  message: "Invalid phone number!",
                },
              ]}
            >
              <PhoneInput
                country={countryCode}
                inputProps={{
                  autoFocus: true,
                }}
                onlyCountries={countryCodeList}
                inputClass="!w-full md:!h-[40px] !rounded-lg md:!text-md"
                searchStyle={{
                  width: "85%",
                }}
                dropdownClass="!w-[250px] sm:!w-[400px]"
                buttonClass="!rounded-l-lg"
                enableSearch
                enableAreaCodes={false}
                autoFormat={false}
                placeholder="+00 000 000 0000"
                onChange={(e, details) => {
                  setWorkerFormValues({
                    ...workerFormValues,
                    phoneNumber: "+" + e,
                    dialCode: "+" + e.toString().slice(0, 2),
                    countryCode: allCountryListDetails?.find(
                      (item) =>
                        item?.dial_code === "+" + e.toString().slice(0, 2)
                    )?.country_code,
                  });

                  //set phone number length
                  setPhoneNumberFieldMinLength(
                    allCountryListDetails?.find(
                      (item) =>
                        item?.country_code?.toLowerCase() ===
                        details?.countryCode
                    )?.number_length + 2
                  );

                  setPhoneNumberFieldMaxLength(
                    allCountryListDetails?.find(
                      (item) =>
                        item?.country_code?.toLowerCase() ===
                        details?.countryCode
                    )?.number_max_length + 2
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          "You must agree to the Terms & Conditions"
                        ),
                },
              ]}
            >
              <Checkbox>
                I agree to the{" "}
                <Link to="/terms-condition">
                  <Text underline strong>
                    Terms & Conditions
                  </Text>
                </Link>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="w-full bg-primaryDarkest mt-5"
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </WorkerSignUpLayout>
  );
};

export default PersonalInfo;
