import React from "react";

const RightGreenIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.86084"
        y="0.494629"
        width="16.7529"
        height="16.7529"
        rx="8.37646"
        fill="#074924"
      />
      <path
        d="M13.461 6.01514L8.22514 11.251L5.84521 8.87105"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RightGreenIcon;
