import { Modal, Popover, QRCode, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import DefaultCover from "../../assets/img/defaultcover.webp";
import ImageCropper from "../../components/imageCropper/ImageCropper";
import Enlarge from "../../assets/svg/Enlarge";
import QRCard from "../../components/workerProfile/QRCard";

const { Text } = Typography;

const CoverSection = ({ data, getWorkerProfileData, editable }) => {
  const publicUrl = process.env.REACT_APP_DOMAIN;
  const [showQR, setShowQR] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isOpenImageCropper, setOpenImageCropper] = useState(null);

  const initials = useMemo(() => {
    let name = data?.full_name
      ? decryptSecureData(data?.full_name)
      : "Jobs Ninja";

    const nameArray = name.split(" ").filter(Boolean);

    if (nameArray.length >= 2) {
      let fl = nameArray[0][0];
      let sl = nameArray[1][0];
      return fl + sl;
    } else {
      let fl = name[0];
      let sl = name[1];
      return fl + sl;
    }
  });

  return (
    <div className="relative w-full h-72">
      <div className="relative w-full h-full overflow-hidden md:rounded-2xl">
        {/* Cover Image */}
        <img
          src={data?.cover_photo || DefaultCover}
          alt="cover image"
          className="w-full h-full z-0 object-cover"
        />

        {/* Overlay */}
        {!data?.cover_photo && (
          <>
            {editable && (
              <div className="absolute md:flex inset-0 bg-black bg-opacity-60 z-10 flex-row p-4 px-12 justify-between items-center">
                <div className="flex w-full"></div>
                <div className="hidden md:visible md:flex flex-col gap-2 justify-center items-center w-full lg:w-[60%]">
                  <p className="flex text-white text-sm font-normal text-center">
                    Add a cover photo and profile image to make a strong first
                    impression. A personalized profile helps clients recognize
                    your work and builds trust from the start!
                  </p>
                  <button
                    className="flex h-10 rounded-md w-full bg-white justify-center items-center text-primaryDarkest font-semibold"
                    onClick={() => {
                      setOpenImageCropper("cover");
                      setPopoverOpen(false);
                    }}
                  >
                    Update Image
                  </button>
                </div>
                <div className="flex lg:w-[20%]"></div>
              </div>
            )}
          </>
        )}

        <div className="flex flex-row absolute left-6 top-6 items-end gap-4">
          <div className="flex w-48 h-60 overflow-hidden rounded-2xl z-50 bg-[#2C8B52] border-2 border-white justify-center items-center">
            {data?.profile_picture ? (
              <img
                src={data?.profile_picture}
                alt="cover image"
                className="w-full h-full object-cover"
              />
            ) : (
              <Text className="flex text-7xl font-bold text-white uppercase">
                {initials}
              </Text>
            )}
          </div>
          <div className="flex flex-col bg-black bg-opacity-50 p-1 rounded-lg justify-center items-center z-50">
            <p className="flex text-white text-xs font-normal text-center">
              View Business Card
            </p>
            <div className="flex flex-row p-1 bg-white items-end">
              <QRCode
                value={`${publicUrl + data?.username}`}
                size={100}
                bordered={false}
                onClick={() => {
                  setShowQR(true);
                }}
                className="cursor-pointer"
              />
              <div
                className="flex cursor-pointer"
                onClick={() => {
                  setShowQR(true);
                }}
              >
                <Enlarge />
              </div>
            </div>
          </div>
        </div>

        {editable && (
          <Popover
            trigger="click"
            content={
              <div className="flex flex-col gap-2">
                <Text
                  className="cursor-pointer hover:underline"
                  onClick={() => {
                    setOpenImageCropper("profile");
                    setPopoverOpen(false);
                  }}
                >
                  Update Profile Picture
                </Text>
                <Text
                  className="cursor-pointer hover:underline"
                  onClick={() => {
                    setOpenImageCropper("cover");
                    setPopoverOpen(false);
                  }}
                >
                  Update Cover Picture
                </Text>
              </div>
            }
            placement="bottomRight"
            open={popoverOpen}
            onOpenChange={setPopoverOpen}
          >
            <div className="absolute right-4 top-2 cursor-pointer bg-textColorThree h-fit rounded-full px-2 z-50">
              <CiMenuKebab className="w-6 h-6 text-white rotate-90" />
            </div>
          </Popover>
        )}
      </div>

      {isOpenImageCropper !== null && (
        <ImageCropper
          modelOpen={isOpenImageCropper !== null}
          handleCancel={() => {
            setOpenImageCropper(null);
          }}
          title={
            isOpenImageCropper === "profile"
              ? "Update Profile Picture"
              : "Update Cover Picture"
          }
          currentImage={
            isOpenImageCropper === "profile"
              ? data?.profile_picture
              : data?.cover_photo
          }
          rWidth={isOpenImageCropper === "profile" ? 45 : 538}
          rHeight={isOpenImageCropper === "profile" ? 58 : 135}
          type={isOpenImageCropper}
          getWorkerProfileData={getWorkerProfileData}
        />
      )}

      {showQR && (
        <Modal
          open={showQR}
          footer={null}
          width={550}
          closeIcon={null}
          maskClosable={true}
          onCancel={() => {
            setShowQR(false);
          }}
        >
          <QRCard
            full_name={data?.full_name}
            work_title={data?.work_title}
            rate={data?.rate}
            rate_count={data?.rate_count}
            username={data?.username}
          />
        </Modal>
      )}
    </div>
  );
};

export default CoverSection;
