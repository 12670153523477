import React, { useContext } from "react";
import { Form, Input, Button, Divider } from "antd";

import EmailGreenOutline from "../../assets/svg/EmailGreenOutline";
import RecomColorIcon from "../../assets/svg/RecomColorIcon";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import HeaderConfig from "../../helpers/HeaderConfig";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";
import LoginServices from "../../services/LoginServices";
import ClientProfileService from "../../services/ClientProfileService";
import { NotificationContext } from "../../context/NotificationContext";

const AddEmailForm = ({
  setRecomondationSteps,
  submitRecomondationData,
  setUserEmail,
  fetchRecomondationOTP,
  addFormValues,
}) => {
  const { configAuth } = HeaderConfig();
  const { getAllAuthUserData } = LoginServices();
  const { changeClinetDetails } = ClientProfileService();
  let { openNotification, handleError } = useContext(NotificationContext);

  const handleFormSubmit = (value) => {
    setUserEmail(value?.email);
    if (
      getLocalStoragedata("userData")?.email == null &&
      getLocalStoragedata("token")
    ) {
      submitRecomondationData({}, "success", value?.email);
      handleUpdateClientDetails(value);
    }

    if (!getLocalStoragedata("token")) {
      fetchRecomondationOTP(value?.email, "setOtp");
    }
  };

  const handleUpdateClientDetails = async (values) => {
    try {
      const response = await changeClinetDetails(
        {
          user_id: getLocalStoragedata("userData")?.user_id,
          first_name: encryptSecureData(addFormValues?.name),
          email: encryptSecureData(values?.email),
        },
        configAuth
      );

      if (response?.data?.success) {
        getAuthUserData();
      } else {
        openNotification("warning", response?.data?.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getAuthUserData = async () => {
    try {
      await getAllAuthUserData(configAuth)
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("userData", response?.data?.output);
            setLocalStorageData("userId", response?.data?.output?.user_id);
            setLocalStorageData(
              "userTypeId",
              response?.data?.output?.user_type_id
            );
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <div className="w-full">
      <p className="font-bold text-primaryDark text-md mt-2">
        Almost there! Please add your email to publish your recommendation.
      </p>

      <Divider className="bg-gray-300 my-3" />

      <Form
        layout="vertical"
        onFinish={handleFormSubmit}
        className="md:px-5"
        requiredMark={false}
      >
        <Form.Item
          name="email"
          label={
            <span className="flex items-center ">
              <EmailGreenOutline classNamemt="mt-2" />
              <span>Email address</span>
              <span className="text-red-500">*</span>
            </span>
          }
          rules={[
            { required: true, message: "Email is required!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input
            maxLength={100}
            placeholder="Enter email address"
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-center items-center">
            <Button
              type="primary"
              htmlType="submit"
              className="bg-primaryDarkest md:px-20"
              size="large"
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEmailForm;
