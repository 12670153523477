import React from "react";

const EditImage = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.875 5.06984C21.8184 4.12646 23.348 4.12646 24.2913 5.06984C25.2347 6.01323 25.2347 7.54275 24.2913 8.48614L20.215 12.5625C20.2052 12.5722 20.1956 12.5819 20.1861 12.5914C19.9648 12.8128 19.8107 12.9669 19.638 13.1016C19.4349 13.26 19.2151 13.3959 18.9825 13.5067C18.7848 13.6009 18.5781 13.6698 18.2811 13.7687C18.2683 13.773 18.2554 13.7773 18.2423 13.7817L16.4806 14.3689C16.0578 14.5098 15.5916 14.3998 15.2765 14.0847C14.9614 13.7695 14.8513 13.3034 14.9923 12.8806L15.5795 11.1189C15.5839 11.1058 15.5882 11.0928 15.5924 11.0801C15.6914 10.7831 15.7602 10.5764 15.8545 10.3786C15.9653 10.1461 16.1012 9.92631 16.2596 9.72318C16.3943 9.55044 16.5484 9.39641 16.7698 9.1751C16.7793 9.16558 16.7889 9.15594 16.7987 9.14617L20.875 5.06984ZM23.2744 6.08676C22.8927 5.70501 22.2737 5.70501 21.892 6.08676L21.7657 6.21305C21.7706 6.22862 21.776 6.24465 21.7817 6.26112C21.8601 6.48705 22.009 6.78645 22.2919 7.06932C22.5747 7.3522 22.8741 7.50112 23.1001 7.57951C23.1165 7.58522 23.1326 7.59054 23.1481 7.5955L23.2744 7.46922C23.6562 7.08746 23.6562 6.46852 23.2744 6.08676ZM22.061 8.68266C21.8056 8.53946 21.5351 8.34636 21.2749 8.08624C21.0148 7.82612 20.8217 7.55561 20.6785 7.30021L17.8156 10.1631C17.5546 10.4241 17.4672 10.5133 17.3936 10.6077C17.2994 10.7284 17.2186 10.8591 17.1527 10.9973C17.1012 11.1054 17.0606 11.2235 16.9438 11.5737L16.7278 12.2217L17.1395 12.6333L17.7875 12.4173C18.1377 12.3006 18.2558 12.2599 18.3638 12.2084C18.5021 12.1425 18.6327 12.0618 18.7535 11.9676C18.8479 11.894 18.9371 11.8065 19.1981 11.5455L22.061 8.68266Z"
        fill="#074924"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6922 4.36231L14.6372 4.3623C12.424 4.36229 10.6895 4.36228 9.33626 4.54423C7.95111 4.73046 6.85809 5.11908 6.00018 5.97699C5.14227 6.8349 4.75365 7.92791 4.56742 9.31307C4.38548 10.6664 4.38549 12.4008 4.3855 14.614V14.724C4.38549 16.9372 4.38548 18.6716 4.56742 20.0249C4.75365 21.4101 5.14227 22.5031 6.00018 23.361C6.85809 24.2189 7.95111 24.6075 9.33626 24.7938C10.6895 24.9757 12.424 24.9757 14.6372 24.9757H14.7472C16.9604 24.9757 18.6948 24.9757 20.0481 24.7938C21.4333 24.6075 22.5263 24.2189 23.3842 23.361C24.2421 22.5031 24.6307 21.4101 24.817 20.0249C24.9989 18.6716 24.9989 16.9372 24.9989 14.724V14.669C24.9989 14.2719 24.6769 13.9499 24.2798 13.9499C23.8827 13.9499 23.5607 14.2719 23.5607 14.669C23.5607 16.9492 23.5592 18.5869 23.3916 19.8333C23.385 19.8825 23.3782 19.9309 23.3711 19.9785L20.7115 17.5848C19.4657 16.4636 17.6103 16.3519 16.239 17.3157L15.9531 17.5166C15.4766 17.8515 14.8284 17.7953 14.4166 17.3835L10.3038 13.2707C9.21517 12.1821 7.46901 12.1239 6.31041 13.1377L5.82458 13.5628C5.82894 11.8444 5.8536 10.5396 5.99274 9.5047C6.15753 8.27901 6.47094 7.54008 7.01711 6.99391C7.56327 6.44775 8.30221 6.13433 9.52789 5.96955C10.7743 5.80198 12.412 5.80045 14.6922 5.80045C15.0893 5.80045 15.4113 5.47851 15.4113 5.08138C15.4113 4.68424 15.0893 4.36231 14.6922 4.36231ZM5.99274 19.8333C6.15753 21.059 6.47094 21.7979 7.01711 22.3441C7.56327 22.8902 8.30221 23.2037 9.52789 23.3685C10.7743 23.536 12.412 23.5375 14.6922 23.5375C16.9723 23.5375 18.6101 23.536 19.8565 23.3685C21.0822 23.2037 21.8211 22.8902 22.3673 22.3441C22.6008 22.1106 22.7917 21.8419 22.9467 21.5149C22.9094 21.4922 22.8737 21.4656 22.84 21.4353L19.7494 18.6538C19.0019 17.981 17.8887 17.914 17.0659 18.4923L16.78 18.6932C15.7318 19.4299 14.3056 19.3064 13.3997 18.4004L9.28684 14.2876C8.73362 13.7344 7.84623 13.7048 7.25743 14.22L5.82401 15.4742C5.82648 17.3424 5.84558 18.7388 5.99274 19.8333Z"
        fill="#074924"
      />
    </svg>
  );
};

export default EditImage;
