import React, { useContext, useRef, useState, useEffect } from "react";
import { Input, Button, Modal, Form, Row, Col, Divider } from "antd";
import NavBar from "../components/navbar/NavBar";
import UserColorProfile from "../components/userColorProfile/UserColorProfile";
import { NotificationContext } from "../context/NotificationContext";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import DefaultProfile from "../assets/img/noImageProfile.png";
import LockIcon from "../assets/svg/LockIcon";
import EditIcon from "../assets/svg/EditIcon";
import {
  decryptSecureData,
  encryptSecureData,
} from "../helpers/encryptHelpers/encryption";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../helpers/encryptHelpers/storageHelper";
import ClientProfileService from "../services/ClientProfileService";
import HeaderConfig from "../helpers/HeaderConfig";
import PublicProfileService from "../services/PublicProfileService";
import LoginServices from "../services/LoginServices";
import { Link } from "react-router-dom";
import CreateJobPostServices from "../services/CreateJobPostServices";
import PersonGreenOutlineIcon from "../assets/svg/PersonGreenOutlineIcon";
import EmailGreenIcon from "../assets/svg/EmailGreenIcon";

const MyAccount = () => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const [previewUrl, setPreviewUrl] = useState();
  const [imgUploading, setImgUploading] = useState(false);
  const [imgUploadDialogOpen, setImgUploadDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const { changeClinetDetails } = ClientProfileService();
  const [form] = Form.useForm();
  const { configAuth } = HeaderConfig();
  const { fileUpload, updateProfile } = PublicProfileService();
  const { getAllAuthUserData } = LoginServices();
  const [initialFormValues, setInitialFormValues] = useState({});
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [initials, setInitials] = useState("--");
  const [fullName, setFullName] = useState("");

  const { getAllCountries } = CreateJobPostServices();

  //profcard
  const imageRef = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [browseButtonState, setBrowseButtonState] = useState(false);
  const [uploadButtonState, setUploadButtonState] = useState(true);
  const [uploadButtonLoading, setUploadButtonLoading] = useState(false);
  const [emailRequired, setEmailRequired] = useState(0);
  const [buttonStatus, setButtonStatus] = useState(false);

  useEffect(() => {
    const userData = getLocalStoragedata("userData");
    const initialValues = {
      first_name: userData?.first_name
        ? decryptSecureData(userData?.first_name)
        : "",
      email:
        userData?.email === "null" || !decryptSecureData(userData?.email)
          ? ""
          : decryptSecureData(userData?.email),
    };

    if (userData?.profile_picture) {
      setPreviewUrl(userData?.profile_picture);
    }
    form.setFieldsValue(initialValues);
    setInitialFormValues(initialValues);

    setFullName(initialValues.first_name || "");
    fetchCountryData();
  }, []);

  const isFormChanged = () => {
    const currentValues = form?.getFieldsValue();
    setButtonStatus(
      currentValues?.first_name !== initialFormValues?.first_name ||
        currentValues?.email !== initialFormValues?.email
    );
  };

  const handleImageUploadDialogOpen = () => {
    setUploadButtonState(true);
    setImgUploadDialogOpen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      openNotification("error", "File size exceeds the limit of 10 MB");
      return;
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      openNotification("error", "Please upload a JPEG/PNG image file.");
      return;
    }

    if (uploadButtonState) {
      setUploadButtonState(false);
    }
    // Get the selected file
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = imageRef.current;
      image.src = e.target.result;
      if (cropper) {
        cropper.destroy();
      }
      const newCropper = new Cropper(image, {
        aspectRatio: 1 / 1,
      });
      setCropper(newCropper);
    };
    // // Read the selected file as a data URL
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (cropper) {
      const imgurl = cropper.getCroppedCanvas().toDataURL();
      const img = document.createElement("img");
      img.src = imgurl;
      //document.getElementById("cropped_result").appendChild(img);

      cropper.getCroppedCanvas().toBlob((blob) => {
        updateProfilePicture(blob);
      });
      setUploadButtonState(true);
    }
  };

  const updateProfilePicture = async (profileImageBlob) => {
    setUploadButtonLoading(true);
    setBrowseButtonState(true);
    setImgUploading(true);
    try {
      const reader = new FileReader();
      reader.readAsDataURL(profileImageBlob);
      reader.onloadend = async () => {
        const base64data = reader.result.split(",")[1];
        const data = {
          userId: getLocalStoragedata("userData")?.user_id,
          usecaseId: 1,
          file: base64data,
        };

        try {
          const response = await fileUpload(data);
          if (response?.data?.success) {
            const newImageUrl = response?.data?.output?.url;
            updateLink(newImageUrl);
            setPreviewUrl(newImageUrl);
          } else {
            openNotification("warning", response?.data?.message);
          }
        } catch (error) {
          handleError(error);
        }

        setImgUploading(false);
        setUploadButtonLoading(false);
        setBrowseButtonState(false);
        setImgUploadDialogOpen(false);
      };
    } catch (error) {
      openNotification("error", error.message);
      setImgUploading(false);
      setUploadButtonLoading(false);
      setBrowseButtonState(false);
    }
  };
  const updateLink = async (url) => {
    const data = {
      user_id: getLocalStoragedata("userData")?.user_id,
      file_extension: url,
    };
    updateProfileURL(data);
  };

  const updateProfileURL = async (data) => {
    try {
      await updateProfile(data)
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            getAuthUserData();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    } catch (error) {
      openNotification("error", error);
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    }
  };

  const handleUpdateClientDetails = async (values) => {
    setSaveButtonLoading(true);
    try {
      const encryptedFirstName = encryptSecureData(values?.first_name);
      const encryptedEmail =
        values?.email === "" || values?.email === undefined
          ? null
          : encryptSecureData(values?.email);

      const response = await changeClinetDetails(
        {
          user_id: getLocalStoragedata("userData")?.user_id,
          first_name: encryptedFirstName,
          email: encryptedEmail,
        },
        configAuth
      );

      if (response?.data?.success) {
        openNotification("success", response?.data?.message);
        getAuthUserData();
      } else {
        openNotification("warning", response?.data?.message);
      }
    } catch (error) {
      handleError(error);
    }
    setSaveButtonLoading(false);
    setButtonStatus(false);
  };

  const getAuthUserData = async () => {
    try {
      await getAllAuthUserData(configAuth)
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("userData", response?.data?.output);
            setLocalStorageData("userId", response?.data?.output?.user_id);
            setLocalStorageData(
              "userTypeId",
              response?.data?.output?.user_type_id
            );
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchCountryData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            setEmailRequired(
              response?.data.output?.find(
                (item) =>
                  item?.country_id ===
                  getLocalStoragedata("userData")?.country_id
              )?.email_required
            );
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const getInitials = (name) => {
    if (!name || typeof name !== "string") return "--";
    const nameArray = name.trim().split(" ");
    const firstInitial = nameArray[0]?.charAt(0).toUpperCase() || ""; // Ensure firstInitial is set correctly
    const lastInitial =
      nameArray.length > 1 ? nameArray[1]?.charAt(0).toUpperCase() : "";
    return `${firstInitial}${lastInitial}` || "--";
  };
  useEffect(() => {
    setInitials(getInitials(fullName));
  }, [fullName]);

  return (
    <div>
      <NavBar />

      <div className="flex flex-col w-full justify-center items-center mt-28 mb-28">
        <div className="max-w-[1073px] xl:w-[1073px] mx-2 w-full px-3">
          {/* My Account */}
          <div>
            <p className="text-base font-semibold text-primaryDark">
              My Account
            </p>

            {/* Avatar */}
            <div className="flex items-center justify-center ml-3 mt-4 w-24 h-[98px] rounded-full border-[1px]">
              <div className="flex w-full h-full rounded-full">
                {/* Preview the selected image */}
                {previewUrl == null ? (
                  <div className="flex  w-full h-full rounded-full text-white">
                    <UserColorProfile
                      name={initials}
                      color={"#2c8b52"}
                      size="100px"
                      textSize="40px"
                    />
                  </div>
                ) : (
                  <img
                    src={previewUrl}
                    alt=""
                    className="flex w-24 h-[98px] rounded-full border-[2px]"
                  />
                )}

                {/* Button to trigger file input click */}
                <label
                  className="absolute cursor-pointer mt-[75px] ml-[62px]"
                  onClick={handleImageUploadDialogOpen}
                >
                  <div className=" rounded-full p-1 bg-white items-center justify-center">
                    <EditIcon className="w-[18px] h-[18px] flex" />
                  </div>
                </label>
              </div>
            </div>

            {/* Profile details */}
            <div className="mt-10">
              <p className="text-sm font-semibold mb-2">Personal Details</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={handleUpdateClientDetails}
                onValuesChange={isFormChanged}
              >
                <Row className="flex flex-col md:flex-row w-full" gutter={16}>
                  {/* name */}
                  <Col span={24} md={12}>
                    <span className="flex flex-row gap-2 mb-1">
                      <PersonGreenOutlineIcon />
                      <p className="text-sm font-medium">
                        Your Name / Company Name *
                      </p>
                    </span>

                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your full name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Ex: Sarah Johnson"
                        size="large"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  {/* email */}
                  <Col span={24} md={12}>
                    <span className="flex flex-row gap-2 mb-1">
                      <EmailGreenIcon />
                      <p className="text-sm font-medium">
                        Email {emailRequired === 1 && "*"}
                      </p>
                    </span>

                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: emailRequired === 1 ? true : false,
                          message: "Please enter your email",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter email"
                        size="large"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Button
                    size="large"
                    htmlType="submit"
                    type="primary"
                    className="bg-primaryDarkest px-10"
                    loading={saveButtonLoading}
                    disabled={!buttonStatus}
                  >
                    Save changes
                  </Button>
                </Form.Item>
              </Form>
              <Divider className="bg-gray-200 my-2" />

              <p className="text-sm font-semibold">Registered phone number</p>
              <p className="text-base font-medium">
                {decryptSecureData(
                  getLocalStoragedata("userData")?.mobile_number ||
                    getLocalStoragedata("userContactNumber")
                )}
              </p>

              <Divider className="bg-gray-200 my-2" />

              {/* <p className="text-sm font-semibold">Edit PIN</p> */}
              {/* <div className="flex flex-col md:flex-row gap-10 items-center justify-start">
                <div className="flex flex-row gap-2 justify-start items-center">
                  <LockIcon />
                  <p className="text-base font-medium">
                    <span className="text-md font-bold">Edit 4 digit pin.</span>
                    <span className="text-md font-normal">
                      you can use this pin to log in to the website.
                    </span>
                  </p>
                </div>
                <Link
                  to={{
                    pathname: "/reset-pin",
                  }}
                  state={{ from: "myAccount", type: 2 }}
                  className="text-primaryDark text-sm font-semibold underline text-start md:text-center"
                >
                  Change PIN
                </Link>
              </div> */}

              <div className="flex flex-col md:flex-row gap-4 items-start justify-start">
                {/* First Column */}
                <div className="flex flex-col gap-2 justify-start items-start">
                  <p className="text-sm font-semibold">Edit PIN</p>
                  <div className="flex flex-row gap-2 justify-start items-center">
                    <LockIcon />
                    <p className="text-base font-medium">
                      <span className="text-md font-bold">
                        Edit 4 digit pin.
                      </span>
                      <span className="text-md font-normal">
                        you can use this pin to log in to the website.
                      </span>
                    </p>
                  </div>
                </div>

                {/* Second Column */}
                <div className="flex flex-col justify-start items-start md:items-center">
                  <Link
                    to={{
                      pathname: "/reset-pin",
                    }}
                    state={{ from: "myAccount", type: 2 }}
                    className="text-primaryDark text-sm font-semibold underline text-start sm:text-center"
                  >
                    Change PIN
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {imgUploadDialogOpen && (
        <Modal
          open={imgUploadDialogOpen}
          onCancel={() => {
            setImgUploadDialogOpen(false);
          }}
          footer={false}
        >
          <div className="flex flex-row gap-3 p-4 w-full justify-center">
            <div className="flex items-center justify-center border-[2px] min-h-[120px] h-[300px] w-[80%]">
              <img
                src={previewUrl != null ? previewUrl : DefaultProfile}
                className="max-w-full max-h-full"
                ref={imageRef}
                alt="Preview"
              />
            </div>
            <div className="flex flex-col gap-2 items-center justify-center w-[30%]">
              {/* Input element to select a file */}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden" // Hide the default input style
                id="file-input" // Add an id for styling purposes
                disabled={browseButtonState}
              />
              {/* Button to trigger file input click */}
              <label
                htmlFor="file-input"
                className="cursor-pointer w-20 text-center bg-primaryBgColor  rounded-lg border-[2px]"
              >
                Browse
              </label>
              <Button
                disabled={uploadButtonState || imgUploading}
                type="primary"
                onClick={handleCrop}
                loading={uploadButtonLoading}
              >
                Upload
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyAccount;
