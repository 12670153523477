import React, { useContext, useState } from "react";
import { Button, Divider } from "antd";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import Icon1 from "../../assets/svg/subscription/Icon1";
import Icon2 from "../../assets/svg/subscription/Icon2";
import Icon3 from "../../assets/svg/subscription/Icon3";
import Icon4 from "../../assets/svg/subscription/Icon4";
import Icon5 from "../../assets/svg/subscription/Icon5";
import CancelSubscriptionModal from "../../components/subscription/CancelSubscriptionModal";
import PackageSubscription from "./PackageSubscription";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";

const PackageDetailCard = ({
  currentPackageDetails,
  handleGetSubscriptionDetails,
}) => {
  const [
    openCloseCancelSubscriptionModal,
    setOpenCloseCancelSubscriptionModal,
  ] = useState(false);
  let {
    openClosePaymentSubscripDrawer,
    setOpenClosePaymentSubscripDrawer,
    handleOpenClosePaymentSubscripDrawer,
  } = useContext(SubscriptionContext);

  const card = [
    {
      title: "Unlimited Leads",
      icon: <Icon1 />,
    },
    {
      title: "Own Profile And QR",
      icon: <Icon2 />,
    },
    {
      title: "Unlimited Chat",
      icon: <Icon3 />,
    },
    {
      title: "Unlimited Job Types",
      icon: <Icon4 />,
    },
    {
      title: "Task Management",
      icon: <Icon5 />,
    },
  ];

  return (
    <div className="max-w-[700px] md:p-3 mx-3 mb-10">
      {/* top subscription status label........................................... */}
      <div>
        {currentPackageDetails?.package_id <= 1 ? (
          <>
            {currentPackageDetails?.day_count > 0 ? (
              //free trial active.............................
              <div className="rounded-md mt-4 w-full bg-thirdColor">
                <p className="text-white text-base font-semibold text-center py-2">
                  Active
                </p>
              </div>
            ) : (
              //free trial expired............................
              <div className="rounded-md mt-4 w-full bg-secondaryColor">
                <p className="text-white text-base font-semibold text-center py-2">
                  Your free trial has ended!
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            {currentPackageDetails?.day_count > 0 ? (
              //premium active................................
              <div className="rounded-md mt-4 w-full bg-thirdColor">
                <p className="text-white text-base font-semibold text-center py-2">
                  Active
                </p>
              </div>
            ) : (
              //premium expired................................
              <div className="rounded-md mt-4 w-full bg-warringColorOne">
                <p className="text-white text-base font-semibold text-center py-2">
                  Subscription Expired
                </p>
              </div>
            )}
          </>
        )}
      </div>

      {/* subscription card details......................................... */}
      <div className="border-[2px] mt-5 p-3 rounded-lg  w-full bg-white">
        <div className="flex flex-col justify-between items-center w-full">
          <p className="text-xl text-primaryDarkest font-bold  text-center whitespace-nowrap mb-2">
            {currentPackageDetails?.package_id <= 1 ? (
              <>
                {currentPackageDetails?.day_count <= 0
                  ? currentPackageDetails?.premium_package_name
                  : "JobsNinja Free Trial"}
              </>
            ) : (
              currentPackageDetails?.premium_package_name
            )}
          </p>
          <p className="text-sm font-normal text-center">
            {currentPackageDetails?.package_id <= 1 ? (
              <>
                {currentPackageDetails?.day_count <= 0
                  ? "Get unlimited access to job leads and allow clients to contact you directly through your public profile and unique QR code. Enjoy the full power of JobsNinja."
                  : "Enjoy unlimited access to job leads and allow clients to contact you directly through your public profile and unique QR code during your free trial. Once the trial ends, you can subscribe to our Premium package to continue accessing these features."}
              </>
            ) : (
              "Get unlimited access to job leads and allow clients to contact you directly through your public profile and unique QR code. Enjoy the full power of JobsNinja."
            )}
          </p>
        </div>

        <div className="w-full mt-5">
          <div className="flex flex-row justify-between text-sm font-normal">
            <p className="text-black text-center text-[16px] ">
              Package duration
            </p>
            <p className="text-black font-semibold ml-2">
              {currentPackageDetails?.total_day_count} Days
            </p>
          </div>

          <Divider className="bg-gray-200 my-2" />

          <div className="flex flex-row justify-between text-sm font-normal">
            <p className="text-black text-center text-[16px] pt-2">Price</p>

            {currentPackageDetails?.package_id <= 1 ? (
              <>
                {currentPackageDetails?.day_count <= 0 ? (
                  <p className="font-bold text-primaryDark text-[34px]">
                    {currentPackageDetails?.premium_package_price}{" "}
                    <span className="text-[22px]">
                      {currentPackageDetails?.premium_currency_label}
                    </span>
                  </p>
                ) : (
                  <p className="text-primaryDark text-[20px] md:text-[34px] font-bold">
                    Free
                  </p>
                )}
              </>
            ) : (
              <p className="font-bold text-primaryDark text-[34px]">
                {currentPackageDetails?.premium_package_price}{" "}
                <span className="text-[22px]">
                  {currentPackageDetails?.premium_currency_label}
                </span>
              </p>
            )}
          </div>

          <Divider className="bg-gray-200 my-2" />

          <div className="flex flex-col">
            <p className="font-bold text-primaryDarkest text-center text-sm">
              Discover Unlimited Power
            </p>

            <div className="grid grid-cols-5  md:grid-cols-5 gap-3 mt-2">
              {card.map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg px-1 py-1 flex flex-col items-center shadow-md w-full"
                >
                  <div className="mb-1">{item.icon}</div>
                  <p className="text-center font-medium text-[10px]">
                    {item.title}
                  </p>
                </div>
              ))}
            </div>

            {/* payment button........................................................... */}
            <>
              {getLocalStoragedata("userData")?.country_id === 4 &&
              (getLocalStoragedata("userData")?.has_verified === 0 ||
                getLocalStoragedata("userData")?.has_verified === 1 ||
                getLocalStoragedata("userData")?.has_verified === 3 ||
                getLocalStoragedata("userData")?.has_verified === 4 ||
                getLocalStoragedata("userData")?.has_verified === 5) ? (
                <>
                  {/* for singapoor not verified worker account has no payment options */}
                </>
              ) : (
                <>
                  {currentPackageDetails?.package_id <= 1 ? (
                    //if free package.........................#################################################
                    <>
                      {currentPackageDetails?.day_count <= 0 && (
                        //if free package expired..................................
                        <>
                          <Button
                            type="primary"
                            size="large"
                            className=" text-white text-sm font-semibold rounded-md mt-4 w-full bg-primaryDarkest"
                            onClick={() => {
                              setOpenClosePaymentSubscripDrawer(true);
                            }}
                          >
                            Subscribe Now
                          </Button>
                          <p className="text-xs text-center mt-2 mb-6">
                            You will be charged automatically until you cancel
                            your subscription
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    //if premium package.........................#################################################
                    <>
                      {currentPackageDetails?.day_count > 0 ? (
                        //package not expire...............................................
                        <>
                          {currentPackageDetails?.is_unsubscribe === 0 && (
                            // not unsubscribe---------------
                            <>
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  setOpenCloseCancelSubscriptionModal(true);
                                }}
                              >
                                <p className="text-sm font-semibold text-black underline decoration-black text-center mt-2">
                                  Cancel subscription
                                </p>
                              </div>

                              <p className="text-xs text-center mt-2 mb-6">
                                You will be charged automatically until you
                                cancel your subscription
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        //package expire..................................................
                        <>
                          {currentPackageDetails?.is_unsubscribe === 0 ? (
                            // not unsubscribe---------------
                            <>
                              <Button
                                type="primary"
                                size="large"
                                className=" text-white text-sm font-semibold rounded-md mt-4 w-full bg-primaryDarkest"
                                onClick={() => {
                                  setOpenClosePaymentSubscripDrawer(true);
                                }}
                              >
                                Pay Now
                              </Button>

                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  setOpenCloseCancelSubscriptionModal(true);
                                }}
                              >
                                <p className="text-sm font-semibold text-black underline decoration-black text-center mt-2">
                                  Cancel subscription
                                </p>
                              </div>

                              <p className="text-xs text-center mt-2 mb-6">
                                You will be charged automatically until you
                                cancel your subscription
                              </p>
                            </>
                          ) : (
                            // unsubscribe------------------
                            <>
                              <Button
                                type="primary"
                                size="large"
                                className=" text-white text-sm font-semibold rounded-md mt-4 w-full bg-primaryDarkest"
                                onClick={() => {
                                  setOpenClosePaymentSubscripDrawer(true);
                                }}
                              >
                                Subscribe Now
                              </Button>

                              <p className="text-xs text-center mt-2 mb-6">
                                You will be charged automatically until you
                                cancel your subscription
                              </p>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </div>

          {openCloseCancelSubscriptionModal && (
            <CancelSubscriptionModal
              open={openCloseCancelSubscriptionModal}
              onCancel={() => {
                setOpenCloseCancelSubscriptionModal(false);
              }}
              handleGetSubscriptionDetails={handleGetSubscriptionDetails}
            />
          )}

          {openClosePaymentSubscripDrawer && (
            <PackageSubscription
              open={openClosePaymentSubscripDrawer}
              onClose={handleOpenClosePaymentSubscripDrawer}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageDetailCard;
