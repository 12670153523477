import React from "react";

const BackIconNew = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1496 13.1992L14.8496 16.4992L18.1496 19.7992"
        stroke="#9CCEB0"
        stroke-width="2.0625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 16.5C5.5 11.3146 5.5 8.72183 7.11091 7.11091C8.72183 5.5 11.3146 5.5 16.5 5.5C21.6855 5.5 24.2782 5.5 25.8891 7.11091C27.5 8.72183 27.5 11.3146 27.5 16.5C27.5 21.6854 27.5 24.2782 25.8891 25.8891C24.2782 27.5 21.6855 27.5 16.5 27.5C11.3146 27.5 8.72183 27.5 7.11091 25.8891C5.5 24.2782 5.5 21.6854 5.5 16.5Z"
        stroke="#9CCEB0"
        stroke-width="2.0625"
      />
    </svg>
  );
};

export default BackIconNew;
