import axios from "axios";
import HeaderConfig from "../helpers/HeaderConfig";

const CreateJobPostServices = () => {
  const { baseUrl, configHeader, configAuth, formconfig } = HeaderConfig();

  const getAllJobCategories = async () => {
    return await axios.post(
      baseUrl + "jobs/category/list",
      { status: 1 },
      configHeader
    );
  };

  const getRelatedSearchedJobTypes = async (data) => {
    return await axios.post(baseUrl + "job/types/search", data, configHeader);
  };

  const getRelatedSearchedPostalCode = async (data) => {
    return await axios.post(baseUrl + "postalcode/all", data, configHeader);
  };

  const getAllCountries = async (data) => {
    return await axios.post(baseUrl + "country/list", data, configHeader);
  };

  const getAllLocationLevel1Data = async (data) => {
    return await axios.post(
      baseUrl + "location/data/level1",
      data,
      configHeader
    );
  };

  const getAllLocationLevel2Data = async (data) => {
    return await axios.post(
      baseUrl + "location/data/level2",
      data,
      configHeader
    );
  };

  const getAllTakerList = async (data) => {
    return await axios.post(baseUrl + "worker/list", data, configHeader);
  };

  const getAllTakerCount = async (data) => {
    return await axios.post(baseUrl + "trady/search", data, configAuth);
  };

  const postTheJob = async (data) => {
    return await axios.post(baseUrl + "job/submit/all", data, formconfig);
  };

  const postJobAnswer = async (data) => {
    return await axios.post(baseUrl + "job/submit/answer", data, configAuth);
  };

  const postWebJobAnswer = async (data) => {
    return await axios.post(baseUrl + "job/web/answer", data, configAuth);
  };

  const getAllJobPostList = async (data) => {
    return await axios.post(baseUrl + "job/my/web", data, configAuth);
  };

  const getAllJobTypes = async () => {
    return await axios.post(
      baseUrl + "job/type/list",
      { job_category_id: 0 },
      configAuth
    );
  };

  const deleteJobPost = async (data) => {
    return await axios.post(baseUrl + "job/delete", data, configAuth);
  };

  const getTaskerListForJobPost = async (data) => {
    return await axios.post(baseUrl + "trady/job/web", data, configAuth);
  };

  const getTaskerSearchListForJobPost = async (data) => {
    return await axios.post(baseUrl + "worker/job/web", data, configHeader);
  };

  const fetchContactDetals = async (data) => {
    return await axios.post(baseUrl + "worker/contact/data", data, configAuth);
  };

  const fetchContactDetalsForNotLoginUser = async (data) => {
    return await axios.post(
      baseUrl + "worker/contact/view",
      data,
      configHeader
    );
  };

  return {
    getAllJobCategories,
    getRelatedSearchedJobTypes,
    getRelatedSearchedPostalCode,
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
    getAllTakerList,
    getAllTakerCount,

    postTheJob,
    postJobAnswer,
    postWebJobAnswer,
    getAllJobPostList,
    getAllJobTypes,
    deleteJobPost,
    getTaskerListForJobPost,

    getTaskerSearchListForJobPost,

    fetchContactDetals,

    fetchContactDetalsForNotLoginUser,
  };
};

export default CreateJobPostServices;
