import { Image, Typography } from "antd";
import React, { useState } from "react";
import DropDownIcon from "../../assets/svg/DropDownIcon";
import DropDownIconUpward from "../../assets/svg/DropDownIconUpward";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { AddJobTypes } from "./servicesEditSteps/AddJobTypes";
import { AddJobTypesDetails } from "./servicesEditSteps/AddJobTypesDetails";

const { Text } = Typography;

const ServicesCard = ({
  data,
  openedService,
  setOpenedService,
  onComplete,
  fetchServices,
  currency,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [openAddJobType, setOpenAddJobType] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(null);

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center ${
          openedService === data.job_category_id
            ? "bg-[#E0EFE2]"
            : "bg-[#F2F2F2]"
        } rounded-lg w-full p-2 h-20`}
      >
        <div className="flex flex-row gap-2 justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center">
            <div className="hidden xs:block">
              <Image
                src={data?.image}
                width={50}
                height={50}
                preview={false}
                className="rounded-md"
              />
            </div>

            <div className="flex flex-col">
              <Text className="text-base font-bold">{data?.job_category}</Text>
              <Text className="text-sm">
                {data?.job_type.length} Job types added
              </Text>
            </div>
          </div>
          <div className="xs:w-40 flex justify-between gap-3 xs:gap-0">
            <Button
              type="default"
              className="border-primaryDarkest text-primaryDarkest hover:bg-primaryDarkest text-xs font-semibold flex flex-row justify-center gap-1"
              onClick={() => setSelectedCategory(true)}
            >
              + <p className="hidden xs:block">Add New</p>
            </Button>
            <button
              onClick={() => {
                setOpenedService(
                  openedService === data.job_category_id
                    ? null
                    : data.job_category_id
                );
              }}
            >
              {openedService === data.job_category_id ? (
                <DropDownIconUpward />
              ) : (
                <DropDownIcon />
              )}
            </button>
          </div>
        </div>
      </div>
      {openedService === data.job_category_id && (
        <>
          {data.job_type.length == 0 ? (
            <div className="flex w-full justify-center items-center">
              No Job Types
            </div>
          ) : (
            <>
              {data.job_type.map((type) => (
                <div
                  className={`flex flex-col gap-2 border border-cardBorderColorOne rounded-lg w-full p-4 min-h-24`}
                >
                  <div className="flex flex-row gap-2 w-full items-center justify-between">
                    <Text className="text-base font-bold">{type.job_type}</Text>
                    <button
                      onClick={() => {
                        setOpenAddJobType(true);
                        setSelectedJobType(type);
                      }}
                    >
                      <div className="flex border justify-center items-center p-1 rounded-md cursor-pointer">
                        <EditOutlined />
                      </div>
                    </button>
                  </div>
                  <div className="flex flex-row w-full md:w-1/2 gap-2 justify-between items-center">
                    <Text className="text-base">Rate</Text>
                    <Text className="text-base">
                      <span className="text-xs">({currency})</span>
                      {type.min} /{type.uom_label}
                    </Text>
                  </div>
                  <div className="flex flex-row w-full md:w-1/2 gap-2 justify-between items-center">
                    <Text className="text-base">Service Area</Text>
                    <Text className="text-base">{type.service_level}</Text>
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}

      {selectedCategory && (
        <AddJobTypes
          open={selectedCategory}
          onClose={() => {
            setSelectedCategory(false);
          }}
          jobType={data}
          onComplete={onComplete}
        />
      )}

      {openAddJobType && (
        <AddJobTypesDetails
          open={openAddJobType}
          onClose={() => {
            setOpenAddJobType(false);
          }}
          onComplete={() => {
            setOpenAddJobType(false);
            fetchServices();
          }}
          data={selectedJobType}
          image={data}
          type={1}
          fetchServices={fetchServices}
        />
      )}
    </>
  );
};

export default ServicesCard;
