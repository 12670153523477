import React from "react";

const UserNotFoundIcon = () => {
  return (
    <svg
      width="275"
      height="275"
      viewBox="0 0 275 275"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.244 183.209C118.011 177.452 127.457 174.075 137.644 174.075C147.832 174.075 157.277 177.452 165.044 183.209"
        stroke="#074924"
        stroke-width="13.7"
        stroke-linecap="round"
      />
      <ellipse
        cx="165.044"
        cy="123.842"
        rx="9.13333"
        ry="13.7"
        fill="#074924"
      />
      <ellipse
        cx="110.244"
        cy="123.842"
        rx="9.13333"
        ry="13.7"
        fill="#074924"
      />
      <path
        d="M46.3108 137.542C46.3108 94.4871 46.3108 72.9596 59.6863 59.5841C73.0618 46.2087 94.5892 46.2087 137.644 46.2087C180.699 46.2087 202.227 46.2087 215.602 59.5841C228.978 72.9596 228.978 94.4871 228.978 137.542C228.978 180.597 228.978 202.124 215.602 215.5C202.227 228.875 180.699 228.875 137.644 228.875C94.5892 228.875 73.0618 228.875 59.6863 215.5C46.3108 202.124 46.3108 180.597 46.3108 137.542Z"
        stroke="#074924"
        stroke-width="13.7"
      />
    </svg>
  );
};

export default UserNotFoundIcon;
