import React from "react";

const EditIconInfo = () => {
  return (
    <svg
      width="43"
      height="41"
      viewBox="0 0 43 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.544922"
        width="41"
        height="39"
        rx="11.5"
        stroke="#EAEAEA"
      />
      <path
        d="M13.7178 30.373H29.7178"
        stroke="#484848"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M23.6058 12.036L24.3473 11.2945C25.5759 10.0659 27.5678 10.0659 28.7964 11.2945C30.0249 12.523 30.0249 14.5149 28.7964 15.7435L28.0548 16.485M23.6058 12.036C23.6058 12.036 23.6985 13.6117 25.0888 15.002C26.4792 16.3923 28.0548 16.485 28.0548 16.485M23.6058 12.036L16.7888 18.853C16.3271 19.3147 16.0962 19.5456 15.8977 19.8001C15.6635 20.1004 15.4627 20.4253 15.2988 20.7691C15.16 21.0605 15.0567 21.3702 14.8502 21.9897L13.9752 24.6147M28.0548 16.485L21.2379 23.302C20.7761 23.7637 20.5453 23.9946 20.2907 24.1931C19.9904 24.4273 19.6655 24.6281 19.3218 24.792C19.0303 24.9309 18.7206 25.0341 18.1011 25.2406L15.4761 26.1156M15.4761 26.1156L14.8344 26.3295C14.5296 26.4311 14.1935 26.3518 13.9663 26.1245C13.7391 25.8973 13.6597 25.5612 13.7613 25.2564L13.9752 24.6147M15.4761 26.1156L13.9752 24.6147"
        stroke="#484848"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default EditIconInfo;
