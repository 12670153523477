import { createContext, useState } from "react";

export const WorkerSignUpContext = createContext({});

export function WorkerSignUpContextProvider({ children }) {
  const [stepperWorkerCurrentSteps, setStepperWorkerCurrentSteps] = useState(0);
  const [workerFormValues, setWorkerFormValues] = useState({
    name: null,
    country: null,
    postalCode: null,
    state: null,
    suburb: null,
    email: null,
    phoneNumber: null,
    dialCode: null,
    countryCode: null,
    isPostalCodeRequired: 0,
    isEmailRequired: 0,
    currency: "USD",
  });
  const [initialServicesList, setInitialServicesList] = useState([]);
  const [servicesAreaSuburbList, setServicesAreaSuburbList] = useState([]);
  const [selectedServicesAreaSuburbList, setSelectedServicesAreaSuburbList] =
    useState([]);

  return (
    <WorkerSignUpContext.Provider
      value={{
        stepperWorkerCurrentSteps,
        setStepperWorkerCurrentSteps,

        workerFormValues,
        setWorkerFormValues,

        initialServicesList,
        setInitialServicesList,

        servicesAreaSuburbList,
        setServicesAreaSuburbList,

        selectedServicesAreaSuburbList,
        setSelectedServicesAreaSuburbList,
      }}
    >
      {children}
    </WorkerSignUpContext.Provider>
  );
}
