import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
import OnDemandWorkers from "../sections/home/OnDemandWorkers";
import PopularServices from "../sections/services/PopularServices";
import { useLocation } from "react-router-dom";
import { NotificationContext } from "../context/NotificationContext";
import PopularJobServices from "../services/PopularJobServices";

const Services = () => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { getAllPopularJoblist } = PopularJobServices();
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllPopularJoblist();
  }, []);

  const fetchAllPopularJoblist = async () => {
    try {
      await getAllPopularJoblist()
        .then((response) => {
          if (response?.data?.success) {
            setJobs(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };
  return (
    <div>
      <NavBar />
      <div className="content">
        <div className="mt-32 flex flex-col gap-8 md:gap-10 lg:gap-16">
          <OnDemandWorkers
            title="No Hassle, Just Results"
            description="Get the job done effortlessly. On Jobsninja, you can find and hire skilled professionals tailored to your needs. Whether it’s a quick fix or a major project, we connect you with reliable workers who are ready to help."
          />
          <PopularServices jobs={jobs} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
