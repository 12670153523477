import { QRCode, Typography } from "antd";
import React, { useContext } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import html2canvas from "html2canvas";
import ReviewStar from "../../assets/svg/ReviewStar";
import JNFooter from "../../assets/img/jobninja_footer.png";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";

const { Text } = Typography;

const QRCard = ({ full_name, work_title, rate, rate_count, username }) => {
  const { openNotification } = useContext(NotificationContext);
  const publicUrl = process.env.REACT_APP_DOMAIN;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`${publicUrl + username}`)
      .then(() => {
        openNotification("success", "URL copied to clipboard!");
      })
      .catch(() => {
        openNotification("warning", "Failed to copy URL!");
      });
  };

  const handleDownload = () => {
    const element = document.getElementById("myqrcode");
    if (element) {
      html2canvas(element, {
        backgroundColor: "#ffffff",
        allowTaint: true,
        useCORS: true,
      })
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "profile_qr.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error while downloading image", error);
        });
    }
  };
  return (
    <div className="flex flex-col gap-2 m-0">
      <div id="myqrcode" className="flex flex-col bg-[#E0EFE2] rounded-lg p-4">
        <div className="flex flex-col xs:flex-row gap-4 justify-between">
          <div className="flex flex-col gap-2 justify-between">
            <div className="flex flex-col gap-0">
              <Text className="flex text-xl md:text-2xl font-bold">
                {decryptSecureData(full_name)}
              </Text>
              <Text className="flex text-lg font-medium text-[#7B7B7B]">
                {decryptSecureData(work_title)}
              </Text>
              <div className="flex flex-row text-base font-semibold gap-1 items-center">
                <ReviewStar width={14} />
                <span className="flex text-base font-bold text-[#F7BA5D]">
                  {rate}
                </span>
                <span className="flex text-base text-[#7B7B7B]">
                  ({rate_count})
                </span>
              </div>
            </div>
            <div className="flex w-full items-center">
              <img src={JNFooter} className="h-10" />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-2 mt-2">
            <QRCode value={`${publicUrl + username}`} />
          </div>
        </div>
      </div>
      <div className="flex flex-col xs:flex-row gap-4 justify-between items-center">
        <div
          className="flex flex-row gap-2 justify-center items-center w-full rounded-md bg-primaryDarkest p-2 cursor-pointer"
          onClick={copyToClipboard}
        >
          <Text className="flex text-sm text-white font-bold">
            Copy Link to Clipboard
          </Text>
        </div>
        <div
          className="flex flex-row gap-2 justify-center items-center w-full rounded-md border border-primaryDarkest p-2 cursor-pointer"
          onClick={handleDownload}
        >
          <Text className="flex text-sm font-bold text-primaryDarkest">
            Download
          </Text>
        </div>
      </div>
    </div>
  );
};

export default QRCard;
