import React from "react";

const EmptyLinkedIn = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.3305 9.79431C50.0961 8.60934 49.6299 7.50772 48.9811 6.53864L48.28 7.00805C47.6819 6.11473 46.9126 5.34543 46.0193 4.74737L46.4887 4.04623C45.5196 3.39745 44.418 2.93124 43.233 2.69682L43.0693 3.52455C42.5528 3.42237 42.0182 3.36867 41.4703 3.36867L39.6306 3.36867V2.5249L35.9511 2.5249V3.36867H32.2717V2.5249H28.5922V3.36867L24.9127 3.36867V2.5249H21.2333V3.36867H17.5538V2.5249H13.8744V3.36867H12.0346C11.4867 3.36867 10.9521 3.42237 10.4356 3.52455L10.2719 2.69682C9.08693 2.93124 7.9853 3.39745 7.01622 4.04623L7.48563 4.74737C6.59231 5.34543 5.82301 6.11473 5.22495 7.00805L4.52381 6.53864C3.87503 7.50772 3.40882 8.60934 3.1744 9.79431L4.00213 9.95806C3.89996 10.4745 3.84625 11.0091 3.84625 11.557V13.3968H3.00248V17.0762H3.84625V20.7557H3.00248V24.4351H3.84625V28.1146H3.00248V31.7941H3.84625V35.4735H3.00248V39.153H3.84625V40.9927C3.84625 41.5407 3.89996 42.0752 4.00213 42.5917L3.1744 42.7554C3.40882 43.9404 3.87503 45.042 4.52381 46.0111L5.22495 45.5417C5.82301 46.435 6.59231 47.2043 7.48563 47.8024L7.01623 48.5035C7.9853 49.1523 9.08693 49.6185 10.2719 49.8529L10.4356 49.0252C10.9521 49.1274 11.4867 49.1811 12.0346 49.1811H13.8744V50.0249H17.5538V49.1811H21.2333V50.0249H24.9127V49.1811H28.5922V50.0249H32.2717V49.1811H35.9511V50.0249H39.6306V49.1811H41.4703C42.0182 49.1811 42.5528 49.1274 43.0693 49.0252L43.233 49.8529C44.418 49.6185 45.5196 49.1523 46.4887 48.5035L46.0193 47.8024C46.9126 47.2043 47.6819 46.435 48.28 45.5417L48.9811 46.0111C49.6299 45.042 50.0961 43.9404 50.3305 42.7554L49.5028 42.5917C49.605 42.0752 49.6587 41.5407 49.6587 40.9927V39.153H50.5024V35.4735H49.6587V31.7941H50.5024V28.1146H49.6587V24.4352H50.5024V20.7557H49.6587V17.0762H50.5024V13.3968H49.6587V11.557C49.6587 11.0091 49.605 10.4745 49.5028 9.95806L50.3305 9.79431Z"
        fill="#CDCDCD"
        stroke="#939292"
        strokeWidth="1.68754"
        strokeDasharray="3.38 3.38"
      />
      <path
        d="M16.7964 21.8505H21.3936V36.6333H16.7964V21.8505ZM19.1031 14.5078C19.63 14.5078 20.1451 14.6641 20.5832 14.9568C21.0214 15.2495 21.3628 15.6656 21.5645 16.1524C21.7661 16.6393 21.8189 17.1749 21.7161 17.6917C21.6133 18.2085 21.3596 18.6832 20.987 19.0558C20.6144 19.4284 20.1397 19.6821 19.6229 19.7849C19.1061 19.8877 18.5704 19.835 18.0836 19.6333C17.5968 19.4317 17.1807 19.0902 16.888 18.6521C16.5952 18.214 16.439 17.6989 16.439 17.172C16.439 16.8221 16.5079 16.4757 16.6418 16.1524C16.7756 15.8292 16.9719 15.5355 17.2193 15.2881C17.4667 15.0407 17.7604 14.8445 18.0836 14.7106C18.4068 14.5767 18.7533 14.5078 19.1031 14.5078Z"
        fill="#939292"
      />
      <path
        d="M24.271 21.8548H28.6734V23.8691C29.1184 23.1174 29.7573 22.4992 30.5232 22.0791C31.2891 21.659 32.1539 21.4525 33.027 21.4811C37.673 21.4811 38.534 24.5352 38.534 28.5152V36.6376H34.0179V29.5223C34.0179 27.8004 34.0179 25.6073 31.6299 25.6073C29.2419 25.6073 28.8683 27.4755 28.8683 29.4086V36.7188H24.271V21.8548Z"
        fill="#939292"
      />
    </svg>
  );
};

export default EmptyLinkedIn;
