import { Button, Form, Input, Modal } from "antd";
import React, { useContext, useState } from "react";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";

const PublicURLEditor = ({ open, onClose, getWorkerProfileData, data }) => {
  const publicUrl = process.env.REACT_APP_DOMAIN;
  const { openNotification, handleError } = useContext(NotificationContext);
  const { usernameCheck, changeUsername } = PublicProfileService();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(data?.username);
  const [userNameExist, setUsernameExist] = useState(null);
  const [invalid, setInvalid] = useState(false);

  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const [timeoutId, setTimeoutId] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const checkUsername = async (e) => {
    try {
      await usernameCheck({
        user_id: getLocalStoragedata("userId"),
        username: e,
      })
        .then((response) => {
          if (response?.data?.success) {
            setUsernameExist(false);
            setUsername(e.target.value);
          } else {
            setUsernameExist(true);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error.message || "An error occurred");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setLoading(true);
    setUsernameExist(null);
    setInvalid(false);

    setUsername(e.target.value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (e.target.value !== "" && /^[a-z0-9-_]+$/.test(e.target.value)) {
        checkUsername(e.target.value);
      } else {
        setLoading(false);
        setInvalid(true);
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleSave = async () => {
    setSubmitting(true);
    try {
      await changeUsername({
        user_id: getLocalStoragedata("userId"),
        username: username.toLowerCase(),
      })
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", "URL updated successfully.");
            onClose();
            getWorkerProfileData();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setSubmitting(false);
    } catch (error) {
      openNotification("error", error.message || "An error occurred");
      setSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        if (data?.username !== username) {
          setShowCloseConfirmation(true);
        } else {
          onClose();
        }
      }}
      footer={null}
      width={1000}
      title="Edit Public Profile URL"
      className="p-2"
    >
      <div className="flex flex-col gap-2">
        <div className="flex w-full">
          <p className="flex flex-row text-sm font-medium text-textColorFour">
            Customize your URL to make it easier to share and promote your
            profile. Keep it short, unique, and professional!
          </p>
        </div>

        <Form
          className="flex flex-col gap-2"
          onFinish={handleSave}
          initialValues={{ username: username }}
        >
          <div className="text-center overflow-x-scroll w-full">
            <div className="flex flex-col gap-1 py-2 px-1 rounded-md bg-[#F0F0F0] min-w-[500px]">
              <Form.Item
                className="mb-0"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "URL is required!",
                  },
                  {
                    pattern: /^[a-z0-9-_]+$/,
                    message:
                      "URL can only contain letters, numbers, hyphens, and underscores!",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="username"
                  maxLength={50}
                  className="flex"
                  addonBefore={publicUrl}
                  onChange={handleChange}
                  loading={loading}
                />
              </Form.Item>
            </div>
          </div>

          {userNameExist === null ? (
            <></>
          ) : userNameExist ? (
            <p className="text-xs font-medium text-red-500 mt-2">
              This URL is already in use. Please choose a different one.
            </p>
          ) : (
            <p className="text-xs font-medium text-green-700 mt-2">
              This URL is available.
            </p>
          )}

          <div className="w-full flex justify-end">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-primaryDark rounded-md"
                disabled={
                  submitting ||
                  userNameExist ||
                  data?.username === username ||
                  invalid
                }
                loading={loading || submitting}
              >
                Save Changes
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>

      {showCloseConfirmation && (
        <NotificationAlertBox
          open={showCloseConfirmation}
          onCancel={() => {
            setShowCloseConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-medium text-darkestColor text-center">
                Are you sure you want to discard changes?
              </p>
              <p className="text-xs text-textColorThree text-center">
                All entered details will be lost.
              </p>

              <div className="flex justify-center items-center mt-5 gap-4"></div>
            </div>
          }
          title="Are you sure?"
        />
      )}
    </Modal>
  );
};

export default PublicURLEditor;
