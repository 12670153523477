import React from "react";

const XIcon = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.6749 0.00927734H9.18484C4.50699 0.00927734 0.714844 3.80143 0.714844 8.47928V38.9693C0.714844 43.6471 4.50699 47.4393 9.18484 47.4393H39.6749C44.3527 47.4393 48.1449 43.6471 48.1449 38.9693V8.47928C48.1449 3.80143 44.3527 0.00927734 39.6749 0.00927734Z"
        fill="black"
      />
      <path
        d="M36.7151 11.1892C35.3551 11.1892 34.2851 11.1192 33.2351 11.1892C32.6897 11.2556 32.1807 11.4978 31.7851 11.8792C29.8851 13.9492 28.0551 16.0892 26.1951 18.2092C25.9451 18.4892 25.6751 18.7592 25.3651 19.0892C23.9851 17.2592 22.5351 15.6292 21.4251 13.7992C20.9533 12.8254 20.173 12.0347 19.2055 11.55C18.238 11.0654 17.1376 10.9139 16.0751 11.1192C14.218 11.2522 12.3543 11.2689 10.4951 11.1692C14.0851 15.8592 17.4951 20.3392 20.9851 24.8892L10.9851 36.2692C12.3451 36.2692 13.4051 36.3492 14.4451 36.2692C14.9942 36.216 15.5105 35.9841 15.9151 35.6092C17.2951 34.1392 18.5851 32.6092 19.9151 31.0892L22.9951 27.5892C24.8751 30.0392 26.7451 32.3292 28.4351 34.7592C28.7626 35.3119 29.2451 35.7562 29.8229 36.0371C30.4006 36.318 31.0482 36.423 31.6851 36.3392C33.8051 36.1992 35.9451 36.3392 38.3151 36.3392L27.3851 21.8792C30.4851 18.3192 33.5151 14.8492 36.7151 11.1892ZM32.5451 32.8592C32.6951 33.0592 32.8351 33.2592 33.0551 33.5792C31.8151 33.7292 30.9251 33.7792 30.0551 32.5792C25.5551 26.5792 20.9451 20.5792 16.3751 14.6292C16.1851 14.3692 16.0051 14.0992 15.7451 13.7492C16.3559 13.5334 17.0226 13.536 17.6316 13.7567C18.2406 13.9773 18.7542 14.4023 19.0851 14.9592C23.5251 21.0092 28.0551 26.9192 32.5451 32.8592Z"
        fill="white"
      />
    </svg>
  );
};

export default XIcon;
