import React, { useContext, useState } from "react";
import NavbarLogo from "../../assets/svg/NavbarLogo";
import { Button, Form, Input, Statistic } from "antd";
import { NotificationContext } from "../../context/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { useNavigate } from "react-router-dom";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import LoginServices from "../../services/LoginServices";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import HeaderConfig from "../../helpers/HeaderConfig";

const { Countdown } = Statistic;

const OtpValidateCard = ({ type }) => {
  let {
    otpAttemptCount,
    setOtpAttemptCount,
    enteredPhoneNumber,
    setToken,

    setCurrentUserData,
  } = useContext(AuthContext);
  let { openNotification, handleError } = useContext(NotificationContext);
  const {
    setStepperCurrentSteps,
    setCompletedStep,
    locationValues,
    setTaskerList,
    selectedCountryType,
  } = useContext(CreateJobPostContext);

  const { baseUrl } = HeaderConfig();
  const { getOtp, otpValidate, getAllAuthUserData } = LoginServices();
  const { getAllTakerList } = CreateJobPostServices();

  const navigate = useNavigate();

  const deadline = Date.now() + 1000 * 60 * 2;

  const [countDownTime, setCountDownTime] = useState(deadline);
  const [enteredOTPValue, setEnteredOTPValue] = useState();

  const [resetButtonDisable, setResetButtonDisable] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleResendOtp = async () => {
    setEnteredOTPValue();
    try {
      await getOtp({
        mobile_number: encryptSecureData(enteredPhoneNumber),
        user_type_id: 6,
      })
        .then((response) => {
          if (response?.data?.success) {
            setCountDownTime(deadline);
            setOtpAttemptCount(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      await otpValidate({
        mobile_number: encryptSecureData(enteredPhoneNumber),
        otp: enteredOTPValue,
        reference: otpAttemptCount?.reference,
      })
        .then((response) => {
          if (response?.data?.success) {
            //  setToken(response?.data?.output?.token);
            //  setLocalStorageData("token", response?.data?.output?.token);
            if (response?.data?.output?.token) {
              getAuthUserData(response?.data?.output?.token);
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  const getAuthUserData = async (token) => {
    let config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Origin: baseUrl,
        "X-Custom-Header": "random-value-1234567890",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await getAllAuthUserData(config)
        .then((response) => {
          if (response?.data?.success) {
            setToken(token);
            setLocalStorageData("token", token);
            setLocalStorageData("userId", response?.data?.output?.user_id);
            setLocalStorageData("userData", response?.data?.output);
            setLocalStorageData(
              "profilePic",
              response?.data?.output?.profile_picture
            );
            setLocalStorageData("userName", response?.data?.output?.first_name);
            setCurrentUserData(response?.data?.output);

            if (type === 2) {
              // setStepsOpenHandler({
              //   location: false,
              //   job: false,
              //   description: false,
              // });
              fetchAllTaskerListData();
              setCompletedStep(1);
              setStepperCurrentSteps(1);
              navigate("../create-job-post");
            } else {
              navigate("../subscription");
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const onFinishCountDown = () => {
    setResetButtonDisable(false);
  };

  const fetchAllTaskerListData = async () => {
    try {
      await getAllTakerList({
        job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
        country_id: locationValues?.country,
        state_id:
          selectedCountryType === 1
            ? locationValues?.state
            : locationValues?.district,
        suburb_id:
          selectedCountryType === 1
            ? locationValues?.suburb
            : locationValues?.city,
        language_ids: [],
      })
        .then((response) => {
          if (response?.data?.success) {
            setTaskerList(response?.data.output);
          } else {
            setTaskerList([]);
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <div className="flex w-full justify-center items-center mt-28">
      <div className="max-w-[1073px] xl:w-[1073px] mx-2">
        <div className="flex justify-center items-center">
          <div className="shadow-lg px-4 md:px-5 pt-10 pb-16 rounded-lg flex flex-col justify-center items-center md:w-[440px]">
            <NavbarLogo />

            <p className="text-sm md:text-lg font-bold my-5">
              Enter OTP to Login
            </p>

            <div className="flex flex-col text-xs font-normal text-textColorTwo mt-3 text-center">
              <div>A six-digit verification code has been sent to your </div>
              <div className="font-bold text-textColorOne">
                Jobs Ninja Mobile App
              </div>
              <div>Enter the verification code to log in to your account.</div>
            </div>

            <Form onFinish={handleVerifyOtp} className="mt-8 w-full">
              <Form.Item
                name="otp"
                rules={[{ required: true, message: "OTP is required!" }]}
              >
                <Input
                  autoFocus
                  placeholder="Enter the code"
                  className="text-center"
                  value={enteredOTPValue}
                  onChange={(e) => {
                    setEnteredOTPValue(e?.target?.value);
                  }}
                  maxLength={6}
                  onPaste={(e) => {
                    const clipboardData =
                      e.clipboardData || window.clipboardData;
                    const pastedText = clipboardData.getData("text");
                    if (!/^\d*$/.test(pastedText)) {
                      e.preventDefault();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                    } else {
                      const key = e.key;
                      if (
                        !/^[0-9]*$/.test(key) &&
                        key !== "Backspace" &&
                        key !== "v" &&
                        !e.ctrlKey
                      ) {
                        e.preventDefault();
                      }
                    }
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  className="w-full bg-primaryDarkest text-sm font-semibold mt-3"
                  loading={loading}
                  htmlType="submit"
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>

            <div className="mt-5 flex flex-col gap-3 justify-center items-center text-xs font-normal text-textColorOne">
              <Countdown
                value={countDownTime}
                onFinish={onFinishCountDown}
                format="mm:ss"
                valueStyle={{
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              />

              <p className="text-textColorTwo">Didn’t receive the OTP yet?</p>

              <button
                onClick={() => {
                  setResetButtonDisable(true);
                  handleResendOtp();
                }}
                className={`text-center border-none font-semibold ${
                  resetButtonDisable
                    ? "text-primaryLightest"
                    : "text-primaryLight"
                }  underline text-sm`}
                disabled={resetButtonDisable}
              >
                Resend
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpValidateCard;
