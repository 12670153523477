import React from "react";

const Linkedln = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.105 0.00927734H8.61501C3.93715 0.00927734 0.14502 3.80143 0.14502 8.47928V38.9693C0.14502 43.6471 3.93715 47.4393 8.61501 47.4393H39.105C43.7829 47.4393 47.575 43.6471 47.575 38.9693V8.47928C47.575 3.80143 43.7829 0.00927734 39.105 0.00927734Z"
        fill="#0077B5"
      />
      <path
        d="M18.0247 18.4292H13.1147V34.2792H18.0247V18.4292Z"
        fill="white"
      />
      <path
        d="M15.5353 16.3391C16.1165 16.345 16.6863 16.178 17.1723 15.8593C17.6582 15.5405 18.0385 15.0844 18.2645 14.549C18.4906 14.0136 18.5523 13.423 18.4419 12.8524C18.3314 12.2818 18.0537 11.7569 17.6442 11.3446C17.2346 10.9322 16.7117 10.6509 16.1419 10.5365C15.572 10.4222 14.9811 10.4798 14.4441 10.7022C13.9071 10.9246 13.4485 11.3017 13.1264 11.7855C12.8043 12.2693 12.6333 12.8379 12.6353 13.4191C12.634 13.8012 12.708 14.1799 12.853 14.5335C12.998 14.887 13.2113 15.2086 13.4805 15.4797C13.7498 15.7509 14.0699 15.9663 14.4224 16.1138C14.775 16.2612 15.1531 16.3378 15.5353 16.3391Z"
        fill="white"
      />
      <path
        d="M25.8655 25.9593C25.8655 23.7293 26.8655 22.3993 28.8655 22.3993C30.6655 22.3993 31.5355 23.6793 31.5355 25.9593V34.2793H36.4155V24.2793C36.4155 20.0293 34.0055 17.9693 30.6455 17.9693C29.6916 17.9557 28.7506 18.19 27.9145 18.6493C27.0784 19.1086 26.3758 19.777 25.8755 20.5893V18.4593H21.1655V34.3093H25.8755L25.8655 25.9593Z"
        fill="white"
      />
    </svg>
  );
};

export default Linkedln;
