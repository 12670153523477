import React from "react";

const MiniCompanyIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6673 16.8379L3.33398 16.8379"
        stroke="#2C8B52"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M13.3337 16.8372V6.17057C13.3337 4.91349 13.3337 4.28495 12.9431 3.89443C12.5526 3.50391 11.9241 3.50391 10.667 3.50391H9.33366C8.07658 3.50391 7.44804 3.50391 7.05752 3.89443C6.66699 4.28495 6.66699 4.91349 6.66699 6.17057V16.8372"
        stroke="#2C8B52"
        strokeWidth="0.75"
      />
      <path
        d="M15.9993 16.8353V9.83529C15.9993 8.89898 15.9993 8.43082 15.7746 8.09453C15.6774 7.94894 15.5524 7.82394 15.4068 7.72666C15.0705 7.50195 14.6023 7.50195 13.666 7.50195"
        stroke="#2C8B52"
        strokeWidth="0.75"
      />
      <path
        d="M4 16.8353V9.83529C4 8.89898 4 8.43082 4.22471 8.09453C4.32199 7.94894 4.44699 7.82394 4.59257 7.72666C4.92887 7.50195 5.39703 7.50195 6.33333 7.50195"
        stroke="#2C8B52"
        strokeWidth="0.75"
      />
      <path
        d="M10 16.8379V14.8379"
        stroke="#2C8B52"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M8.66602 5.50391H11.3327"
        stroke="#2C8B52"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M8.66602 7.50195H11.3327"
        stroke="#2C8B52"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M8.66602 9.50586H11.3327"
        stroke="#2C8B52"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M8.66602 11.5039H11.3327"
        stroke="#2C8B52"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MiniCompanyIcon;
