import React from "react";

const NoSkillAndExperience = () => {
  return (
    <svg
      width="245"
      height="246"
      viewBox="0 0 245 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6614_214806)">
        <path
          d="M244.654 188.051H-0.0065918V188.173H244.654V188.051Z"
          fill="#EBEBEB"
        />
        <path
          d="M220.138 195.924H203.932V196.046H220.138V195.924Z"
          fill="#EBEBEB"
        />
        <path
          d="M162.066 197.255H157.814V197.377H162.066V197.255Z"
          fill="#EBEBEB"
        />
        <path
          d="M203.443 191.383H194.053V191.505H203.443V191.383Z"
          fill="#EBEBEB"
        />
        <path
          d="M46.7968 192.205H25.6631V192.328H46.7968V192.205Z"
          fill="#EBEBEB"
        />
        <path
          d="M54.2541 192.205H51.1567V192.328H54.2541V192.205Z"
          fill="#EBEBEB"
        />
        <path
          d="M110.164 194.27H64.3242V194.392H110.164V194.27Z"
          fill="#EBEBEB"
        />
        <path
          d="M115.962 166.227H21.4793C20.7391 166.226 20.0297 165.931 19.5068 165.407C18.9839 164.883 18.6902 164.173 18.6902 163.433V30.6168C18.6966 29.8809 18.9932 29.1773 19.5154 28.6587C20.0376 28.1401 20.7434 27.8486 21.4793 27.8473H115.962C116.703 27.8473 117.414 28.1417 117.938 28.6656C118.462 29.1896 118.756 29.9003 118.756 30.6413V163.433C118.756 164.174 118.462 164.885 117.938 165.409C117.414 165.933 116.703 166.227 115.962 166.227ZM21.4793 27.9452C20.7716 27.9465 20.0933 28.2285 19.5933 28.7294C19.0933 29.2303 18.8125 29.9091 18.8125 30.6168V163.433C18.8125 164.141 19.0933 164.82 19.5933 165.32C20.0933 165.821 20.7716 166.103 21.4793 166.105H115.962C116.67 166.103 117.349 165.822 117.85 165.321C118.351 164.82 118.633 164.141 118.634 163.433V30.6168C118.633 29.9087 118.351 29.2299 117.85 28.7291C117.349 28.2283 116.67 27.9464 115.962 27.9452H21.4793Z"
          fill="#EBEBEB"
        />
        <path
          d="M221.807 166.227H127.319C126.579 166.226 125.869 165.931 125.345 165.407C124.821 164.884 124.527 164.174 124.525 163.433V30.6168C124.533 29.8805 124.831 29.1768 125.354 28.6584C125.877 28.14 126.583 27.8485 127.319 27.8473H221.807C222.542 27.8498 223.247 28.142 223.768 28.6604C224.289 29.1788 224.585 29.8818 224.591 30.6168V163.433C224.591 164.172 224.298 164.882 223.777 165.405C223.255 165.929 222.546 166.224 221.807 166.227ZM127.319 27.9452C126.611 27.9464 125.932 28.2283 125.432 28.7291C124.931 29.2299 124.649 29.9087 124.648 30.6168V163.433C124.649 164.141 124.931 164.82 125.432 165.321C125.932 165.822 126.611 166.103 127.319 166.105H221.807C222.515 166.103 223.194 165.822 223.695 165.321C224.196 164.82 224.478 164.141 224.479 163.433V30.6168C224.478 29.9087 224.196 29.2299 223.695 28.7291C223.194 28.2283 222.515 27.9464 221.807 27.9452H127.319Z"
          fill="#EBEBEB"
        />
        <path
          d="M202.215 43.246H178.37V72.6003H202.215V43.246Z"
          fill="#F5F5F5"
        />
        <path
          d="M200.815 44.6454H179.774V71.191H200.815V44.6454Z"
          fill="#EBEBEB"
        />
        <path
          d="M198.261 64.4922V71.191H182.324V64.4922C182.32 62.7545 182.93 61.0712 184.045 59.7385C185.16 58.4058 186.71 57.5091 188.421 57.2063C187.306 56.7701 186.378 55.9581 185.798 54.9105C185.218 53.863 185.022 52.6458 185.243 51.4691C185.465 50.2924 186.091 49.2302 187.013 48.466C187.935 47.7018 189.095 47.2836 190.292 47.2836C191.49 47.2836 192.65 47.7018 193.571 48.466C194.493 49.2302 195.119 50.2924 195.341 51.4691C195.563 52.6458 195.367 53.863 194.787 54.9105C194.207 55.9581 193.279 56.7701 192.164 57.2063C193.875 57.5091 195.425 58.4058 196.54 59.7385C197.655 61.0712 198.264 62.7545 198.261 64.4922Z"
          fill="#E0E0E0"
        />
        <path
          d="M61.8974 50.0818H42.0457V63.4109H61.8974V50.0818Z"
          fill="#E0E0E0"
        />
        <path
          d="M51.974 45.8591L36.0417 50.082L51.974 54.3048L67.9063 50.082L51.974 45.8591Z"
          fill="#EBEBEB"
        />
        <path
          d="M60.1798 60.2938V52.22C60.1794 52.1431 60.1537 52.0685 60.1069 52.0076C60.0601 51.9466 59.9946 51.9026 59.9205 51.8824L52.0522 49.744C51.9627 49.7199 51.8673 49.7321 51.7867 49.7779C51.7061 49.8237 51.6468 49.8995 51.6216 49.9887C51.609 50.033 51.6054 50.0794 51.6109 50.1252C51.6164 50.171 51.631 50.2152 51.6537 50.2552C51.6765 50.2953 51.707 50.3305 51.7435 50.3586C51.78 50.3868 51.8217 50.4074 51.8663 50.4193L59.4801 52.4891V60.284C59.2762 60.3653 59.1069 60.5152 59.0015 60.7078C58.8961 60.9004 58.8611 61.1237 58.9025 61.3393C58.9439 61.5549 59.0592 61.7494 59.2284 61.8892C59.3977 62.0291 59.6104 62.1056 59.83 62.1056C60.0495 62.1056 60.2622 62.0291 60.4315 61.8892C60.6007 61.7494 60.716 61.5549 60.7574 61.3393C60.7988 61.1237 60.7638 60.9004 60.6584 60.7078C60.553 60.5152 60.3838 60.3653 60.1798 60.284V60.2938Z"
          fill="#F5F5F5"
        />
        <path
          d="M103.039 32.3931L99.7559 51.5646H117.063L103.039 32.3931Z"
          fill="#E0E0E0"
        />
        <path
          d="M129.497 51.5646H105.907L103.04 32.3931H126.63L129.497 51.5646Z"
          fill="#F5F5F5"
        />
        <path
          d="M127.153 35.9064H103.563L103.04 32.3931H126.63L127.153 35.9064Z"
          fill="#EBEBEB"
        />
        <path
          d="M110.722 39.5223H108.686L108.383 37.4672H110.413L110.722 39.5223Z"
          fill="#E0E0E0"
        />
        <path
          d="M113.819 39.5223H111.783L111.48 37.4672H113.511L113.819 39.5223Z"
          fill="#E0E0E0"
        />
        <path
          d="M116.917 39.5223H114.881L114.578 37.4672H116.608L116.917 39.5223Z"
          fill="#E0E0E0"
        />
        <path
          d="M120.014 39.5223H117.978L117.675 37.4672H119.705L120.014 39.5223Z"
          fill="#E0E0E0"
        />
        <path
          d="M123.111 39.5223H121.076L120.772 37.4672H122.803L123.111 39.5223Z"
          fill="#E0E0E0"
        />
        <path
          d="M126.209 39.5223H124.173L123.87 37.4672H125.9L126.209 39.5223Z"
          fill="#E0E0E0"
        />
        <path
          d="M108.06 42.4533H106.029L105.721 40.4031H107.756L108.06 42.4533Z"
          fill="#E0E0E0"
        />
        <path
          d="M111.162 42.4533H109.127L108.818 40.4031H110.854L111.162 42.4533Z"
          fill="#E0E0E0"
        />
        <path
          d="M114.254 42.4533H112.224L111.916 40.4031H113.951L114.254 42.4533Z"
          fill="#E0E0E0"
        />
        <path
          d="M117.352 42.4533H115.321L115.013 40.4031H117.049L117.352 42.4533Z"
          fill="#E0E0E0"
        />
        <path
          d="M120.449 42.4533H118.419L118.11 40.4031H120.146L120.449 42.4533Z"
          fill="#E0E0E0"
        />
        <path
          d="M123.547 42.4533H121.516L121.208 40.4031H123.244L123.547 42.4533Z"
          fill="#E0E0E0"
        />
        <path
          d="M126.644 42.4533H124.613L124.305 40.4031H126.341L126.644 42.4533Z"
          fill="#E0E0E0"
        />
        <path
          d="M108.5 45.3893H106.469L106.161 43.3341H108.192L108.5 45.3893Z"
          fill="#E0E0E0"
        />
        <path
          d="M111.598 45.3893H109.567L109.259 43.3341H111.289L111.598 45.3893Z"
          fill="#E0E0E0"
        />
        <path
          d="M114.695 45.3893H112.664L112.356 43.3341H114.387L114.695 45.3893Z"
          fill="#E0E0E0"
        />
        <path
          d="M117.793 45.3893H115.762L115.454 43.3341H117.484L117.793 45.3893Z"
          fill="#E0E0E0"
        />
        <path
          d="M120.89 45.3893H118.859L118.551 43.3341H120.581L120.89 45.3893Z"
          fill="#E0E0E0"
        />
        <path
          d="M123.987 45.3893H121.957L121.648 43.3341H123.679L123.987 45.3893Z"
          fill="#E0E0E0"
        />
        <path
          d="M127.085 45.3893H125.054L124.746 43.3341H126.776L127.085 45.3893Z"
          fill="#E0E0E0"
        />
        <path
          d="M108.941 48.3203H106.91L106.602 46.27H108.632L108.941 48.3203Z"
          fill="#E0E0E0"
        />
        <path
          d="M112.038 48.3203H110.003L109.699 46.27H111.73L112.038 48.3203Z"
          fill="#E0E0E0"
        />
        <path
          d="M115.135 48.3203H113.1L112.796 46.27H114.827L115.135 48.3203Z"
          fill="#E0E0E0"
        />
        <path
          d="M118.233 48.3203H116.197L115.894 46.27H117.925L118.233 48.3203Z"
          fill="#E0E0E0"
        />
        <path
          d="M121.33 48.3203H119.295L118.991 46.27H121.022L121.33 48.3203Z"
          fill="#E0E0E0"
        />
        <path
          d="M124.427 48.3203H122.392L122.083 46.27H124.119L124.427 48.3203Z"
          fill="#E0E0E0"
        />
        <path
          d="M219.424 112.373H201.579C200.987 112.373 200.507 112.852 200.507 113.444V138.943C200.507 139.534 200.987 140.014 201.579 140.014H219.424C220.016 140.014 220.496 139.534 220.496 138.943V113.444C220.496 112.852 220.016 112.373 219.424 112.373Z"
          fill="#E0E0E0"
        />
        <path
          d="M216.591 109.652H198.745C198.154 109.652 197.674 110.131 197.674 110.723V136.222C197.674 136.814 198.154 137.293 198.745 137.293H216.591C217.183 137.293 217.663 136.814 217.663 136.222V110.723C217.663 110.131 217.183 109.652 216.591 109.652Z"
          fill="#F5F5F5"
        />
        <path
          d="M214.178 114.002H201.158C201.072 114.002 200.987 113.986 200.908 113.954C200.829 113.921 200.757 113.873 200.696 113.813C200.635 113.752 200.588 113.68 200.555 113.601C200.523 113.522 200.506 113.437 200.507 113.351C200.507 113.178 200.575 113.013 200.697 112.891C200.82 112.769 200.985 112.7 201.158 112.7H214.178C214.351 112.7 214.517 112.769 214.639 112.891C214.761 113.013 214.829 113.178 214.829 113.351C214.83 113.437 214.814 113.522 214.781 113.601C214.749 113.68 214.701 113.752 214.64 113.813C214.58 113.873 214.508 113.921 214.428 113.954C214.349 113.986 214.264 114.002 214.178 114.002Z"
          fill="#EBEBEB"
        />
        <path
          d="M214.178 117.393H201.158C200.985 117.393 200.82 117.324 200.697 117.202C200.575 117.08 200.507 116.915 200.507 116.742C200.506 116.656 200.523 116.571 200.555 116.492C200.587 116.412 200.635 116.34 200.696 116.279C200.756 116.218 200.828 116.17 200.907 116.137C200.987 116.104 201.072 116.087 201.158 116.087H214.178C214.264 116.087 214.349 116.104 214.429 116.137C214.508 116.17 214.58 116.218 214.64 116.279C214.701 116.34 214.749 116.412 214.781 116.492C214.814 116.571 214.83 116.656 214.829 116.742C214.829 116.915 214.761 117.08 214.639 117.202C214.517 117.324 214.351 117.393 214.178 117.393Z"
          fill="#EBEBEB"
        />
        <path
          d="M214.178 120.749H201.158C201.072 120.75 200.987 120.734 200.908 120.701C200.829 120.669 200.757 120.621 200.696 120.56C200.635 120.5 200.588 120.428 200.555 120.348C200.523 120.269 200.506 120.184 200.507 120.099C200.507 119.926 200.575 119.761 200.697 119.638C200.82 119.516 200.985 119.448 201.158 119.448H214.178C214.351 119.448 214.517 119.516 214.639 119.638C214.761 119.761 214.829 119.926 214.829 120.099C214.83 120.184 214.814 120.269 214.781 120.348C214.749 120.428 214.701 120.5 214.64 120.56C214.58 120.621 214.508 120.669 214.428 120.701C214.349 120.734 214.264 120.75 214.178 120.749Z"
          fill="#EBEBEB"
        />
        <path
          d="M214.178 124.126H201.158C201.072 124.126 200.988 124.109 200.909 124.076C200.83 124.043 200.758 123.995 200.697 123.935C200.637 123.875 200.589 123.803 200.556 123.724C200.524 123.645 200.507 123.56 200.507 123.475C200.506 123.389 200.523 123.304 200.555 123.224C200.587 123.145 200.635 123.072 200.696 123.011C200.756 122.95 200.828 122.902 200.907 122.869C200.987 122.836 201.072 122.819 201.158 122.819H214.178C214.264 122.819 214.349 122.836 214.429 122.869C214.508 122.902 214.58 122.95 214.64 123.011C214.701 123.072 214.749 123.145 214.781 123.224C214.814 123.304 214.83 123.389 214.829 123.475C214.829 123.56 214.812 123.645 214.78 123.724C214.747 123.803 214.699 123.875 214.639 123.935C214.578 123.995 214.506 124.043 214.428 124.076C214.349 124.109 214.264 124.126 214.178 124.126Z"
          fill="#EBEBEB"
        />
        <path
          d="M214.178 127.497H201.158C201.072 127.498 200.987 127.482 200.908 127.449C200.829 127.417 200.757 127.369 200.696 127.308C200.635 127.248 200.588 127.176 200.555 127.096C200.523 127.017 200.506 126.932 200.507 126.846C200.506 126.761 200.523 126.676 200.555 126.597C200.588 126.517 200.635 126.445 200.696 126.385C200.757 126.324 200.829 126.276 200.908 126.244C200.987 126.211 201.072 126.195 201.158 126.196H214.178C214.264 126.195 214.349 126.211 214.428 126.244C214.508 126.276 214.58 126.324 214.64 126.385C214.701 126.445 214.749 126.517 214.781 126.597C214.814 126.676 214.83 126.761 214.829 126.846C214.83 126.932 214.814 127.017 214.781 127.096C214.749 127.176 214.701 127.248 214.64 127.308C214.58 127.369 214.508 127.417 214.428 127.449C214.349 127.482 214.264 127.498 214.178 127.497Z"
          fill="#EBEBEB"
        />
        <path
          d="M214.178 130.873H201.158C201.072 130.873 200.987 130.856 200.907 130.823C200.828 130.79 200.756 130.742 200.696 130.681C200.635 130.62 200.587 130.548 200.555 130.468C200.523 130.389 200.506 130.303 200.507 130.218C200.507 130.045 200.575 129.879 200.697 129.757C200.82 129.635 200.985 129.567 201.158 129.567H214.178C214.351 129.567 214.517 129.635 214.639 129.757C214.761 129.879 214.829 130.045 214.829 130.218C214.83 130.303 214.814 130.389 214.781 130.468C214.749 130.548 214.701 130.62 214.64 130.681C214.58 130.742 214.508 130.79 214.429 130.823C214.349 130.856 214.264 130.873 214.178 130.873Z"
          fill="#EBEBEB"
        />
        <path
          d="M211.243 134.245H201.158C200.985 134.245 200.82 134.176 200.697 134.054C200.575 133.932 200.507 133.767 200.507 133.594C200.506 133.509 200.523 133.424 200.555 133.344C200.588 133.265 200.635 133.193 200.696 133.133C200.757 133.072 200.829 133.024 200.908 132.992C200.987 132.959 201.072 132.943 201.158 132.943H211.243C211.328 132.943 211.413 132.959 211.492 132.992C211.572 133.024 211.644 133.072 211.704 133.133C211.765 133.193 211.813 133.265 211.845 133.344C211.878 133.424 211.894 133.509 211.893 133.594C211.893 133.767 211.825 133.932 211.703 134.054C211.581 134.176 211.415 134.245 211.243 134.245Z"
          fill="#EBEBEB"
        />
        <path
          d="M190.07 73.8823V75.1056"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M190.07 77.4891V95.364"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.38 2.38"
        />
        <path
          d="M190.07 96.5576V97.7809H188.847"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M186.41 97.7808H143.78"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.44 2.44"
        />
        <path
          d="M142.562 97.7808H141.338V99.0041"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M141.338 101.602V105.502"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.6 2.6"
        />
        <path
          d="M141.339 106.804V108.027H140.115"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.409 108.027H133.343"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.71 2.71"
        />
        <path
          d="M131.987 108.027H130.764"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLnejoin="round"
        />
        <path
          d="M209.481 142.583V151.689H141.338V122.819H131.665"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.45 2.45"
        />
        <path
          d="M131.665 42.4532H144.818V93.4894H120.772"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.45 2.45"
        />
        <path
          d="M64.7695 57.9357H76.8802V108.624H95.1514"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.45 2.45"
        />
        <path
          d="M37.7934 154.904L32.3668 151.479L26.8032 154.904V136.613H37.7934V154.904Z"
          fill="#E0E0E0"
        />
        <path
          d="M38.3478 141.001C41.69 137.659 41.69 132.24 38.3478 128.898C35.0056 125.556 29.5869 125.556 26.2447 128.898C22.9025 132.24 22.9025 137.659 26.2447 141.001C29.5869 144.343 35.0056 144.343 38.3478 141.001Z"
          fill="#F5F5F5"
        />
        <path
          d="M37.4344 140.088C40.2721 137.25 40.2721 132.649 37.4344 129.811C34.5967 126.974 29.9959 126.974 27.1581 129.811C24.3204 132.649 24.3204 137.25 27.1581 140.088C29.9959 142.925 34.5967 142.925 37.4344 140.088Z"
          fill="#EBEBEB"
        />
        <path
          d="M43.7778 135.189H81.5289V122.819H95.1516"
          stroke="#EBEBEB"
          strokeWidth="0.978641"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2.45 2.45"
        />
        <path
          d="M122.324 210.148C174.721 210.148 217.198 207.668 217.198 204.609C217.198 201.55 174.721 199.07 122.324 199.07C69.9259 199.07 27.4492 201.55 27.4492 204.609C27.4492 207.668 69.9259 210.148 122.324 210.148Z"
          fill="#F5F5F5"
        />
        <path
          d="M55.179 70.1978C55.179 70.1978 52.7324 71.1031 51.9593 74.4549C51.1861 77.8067 53.2413 85.0389 53.5691 88.4592C53.897 91.8796 50.1439 98.8133 59.896 99.4445C69.6482 100.076 71.0574 73.4665 66.541 71.6071C62.0246 69.7476 55.179 70.1978 55.179 70.1978Z"
          fill="#263238"
        />
        <path
          d="M66.0811 71.9936C68.8017 70.7116 69.9663 79.5683 69.017 85.9588"
          stroke="#263238"
          strokeWidth="0.24466"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.2109 68.4169L90.3706 65.2265C90.3706 65.2265 89.8813 64.6442 92.1126 63.5775C94.3439 62.5108 95.7042 61.9334 96.6192 62.2221C97.5343 62.5108 98.7918 64.5708 98.694 65.2852C98.5961 65.9996 92.4992 66.993 92.4992 66.993L91.6282 69.4396L89.2109 68.4169Z"
          fill="#E4897B"
        />
        <path
          d="M70.3477 84.7309C70.3477 84.7309 80.9953 81.5356 82.7373 79.9013C84.4792 78.2669 89.4067 66.0927 89.4067 66.0927L92.9836 67.5606C92.9836 67.5606 89.3186 81.702 86.2212 84.9755C83.1238 88.2491 71.2186 94.0524 71.2186 94.0524L70.3477 84.7309Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M70.3477 84.7309C70.3477 84.7309 80.9953 81.5356 82.7373 79.9013C84.4792 78.2669 89.4067 66.0927 89.4067 66.0927L92.9836 67.5606C92.9836 67.5606 89.3186 81.702 86.2212 84.9755C83.1238 88.2491 71.2186 94.0524 71.2186 94.0524L70.3477 84.7309Z"
          fill="white"
        />
        <path
          d="M51.0884 193.266L53.4518 194.656C54.5604 193.165 55.7599 191.743 57.0434 190.399C57.7285 189.675 58.4478 188.872 59.1964 188.011C60.6937 186.294 62.3134 184.331 64.0113 182.222L65.5331 180.319C71.4343 172.872 77.8444 164.123 80.2421 158.207C80.9859 156.367 81.3479 154.781 81.1865 153.636C80.6971 150.104 79.3271 144.785 77.7612 139.647C75.3733 131.793 72.687 124.351 72.687 124.351L66.0861 135.532C66.0861 135.532 68.1118 140.361 69.9566 145.504C71.7719 150.554 73.4112 155.912 72.8044 157.306C72.7457 157.443 72.6723 157.605 72.5891 157.796C71.493 160.066 68.4593 165.209 65.0144 170.89L63.9624 172.617C62.1128 175.651 60.1897 178.768 58.4086 181.63C57.7138 182.756 57.0385 183.842 56.3975 184.87C53.3539 189.729 51.0884 193.266 51.0884 193.266Z"
          fill="#E4897B"
        />
        <path
          opacity="0.2"
          d="M65.67 180.314C62.5482 184.229 59.5486 187.801 57.3173 190.394C56.9993 188.799 56.6763 186.91 56.4219 184.85C59.0006 180.711 62.1567 175.602 65.0241 170.87C64.9633 174.031 65.1794 177.191 65.67 180.314Z"
          fill="black"
        />
        <path
          d="M53.887 147.96C54.7042 153.534 55.8443 158.584 56.6272 161.593C57.0823 163.398 57.3905 164.465 57.3905 164.465C57.4016 170.188 57.7381 175.906 58.3985 181.591C58.6481 183.832 58.9172 185.995 59.1961 187.987C60.2237 195.395 61.3051 200.709 61.3051 200.709L64.1187 200.342L64.011 182.193L63.9523 172.573L63.874 159.484L63.8447 157.204L63.7077 147.251L63.507 132.571L53.7206 128.167C52.4093 133.77 52.8986 141.247 53.887 147.96Z"
          fill="#E4897B"
        />
        <path
          opacity="0.2"
          d="M80.0757 148.053C77.5752 149.781 74.2038 150.818 71.8893 151.376C71.356 149.551 70.666 147.5 69.932 145.504C68.0873 140.361 66.0615 135.532 66.0615 135.532L72.6624 124.351C72.6624 124.351 75.3488 131.793 77.7367 139.647C78.6273 142.504 79.4395 145.421 80.0757 148.053Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M63.791 153.436C60.7497 153.37 57.7137 153.152 54.6945 152.78C54.4107 151.249 54.1318 149.629 53.8871 147.96C52.9085 141.247 52.4192 133.77 53.7208 128.192L63.5072 132.596L63.7078 147.275L63.791 153.436Z"
          fill="black"
        />
        <path
          d="M80.5502 147.08C74.4288 150.676 56.4218 152.203 56.4218 152.203L53.4859 143.924L53.8186 152.134H52.9427C52.9427 152.134 46.3663 129.87 55.1055 115.279L70.7638 114.07L72.4764 122.134C72.4764 122.134 76.8705 133.672 80.5502 147.08Z"
          fill="#263238"
        />
        <path
          d="M69.0658 204.34C68.6046 204.065 68.0972 203.876 67.5684 203.782C67.5684 203.782 65.8362 202.495 64.4661 198.067L62.4061 197.832C62.0561 197.8 61.7047 197.877 61.3999 198.052C61.0952 198.227 60.8519 198.492 60.7033 198.811C60.6594 198.899 60.6297 198.993 60.6152 199.09C60.5833 199.588 60.5833 200.088 60.6152 200.587C60.7404 202.258 60.9659 203.92 61.2905 205.563L61.6526 205.514L61.3492 202.373C61.2513 201.306 62.2397 202.432 62.6361 203.543C63.0324 204.653 63.693 205.793 66.0222 205.5C68.7379 205.172 69.8487 204.908 69.0658 204.34Z"
          fill="#2E353A"
        />
        <path
          d="M63.8984 196.501C63.8984 196.501 64.6324 198.503 65.611 200.705C66.0603 201.846 66.7248 202.891 67.5683 203.782C67.5683 203.782 65.8655 204.335 64.9504 203.269C64.0354 202.202 62.4598 198.351 60.7031 198.796C60.7967 198.483 60.9281 198.182 61.0946 197.901C61.3588 197.465 63.8984 196.501 63.8984 196.501Z"
          fill="#E4897B"
        />
        <path
          d="M51.8271 202.603C51.7287 202.072 51.5329 201.565 51.2497 201.105C51.2497 201.105 51.0686 198.957 53.4761 194.999L52.287 193.301C52.0816 193.017 51.794 192.804 51.4632 192.689C51.1323 192.574 50.7742 192.564 50.4374 192.66C50.3428 192.687 50.2523 192.726 50.1683 192.777C49.7734 193.08 49.3975 193.407 49.0428 193.756C47.8743 194.958 46.7795 196.229 45.7644 197.563L46.0433 197.798L48.1963 195.488C48.9303 194.71 48.7395 196.197 48.1719 197.225C47.6042 198.253 47.1932 199.51 48.9352 201.076C50.9854 202.906 51.9201 203.562 51.8271 202.603Z"
          fill="#2E353A"
        />
        <path
          d="M54.2737 193.531C54.2737 193.531 53.2608 195.41 52.2577 197.602C51.7019 198.695 51.3598 199.884 51.2497 201.105C51.2497 201.105 49.7133 200.2 49.9041 198.806C50.0949 197.411 51.9348 193.687 50.4375 192.66C50.7348 192.525 51.0468 192.425 51.3672 192.361C51.8614 192.258 54.2737 193.531 54.2737 193.531Z"
          fill="#E4897B"
        />
        <path
          d="M54.944 113.659L54.856 116.023L71.0378 115.391L71.1454 112.597L54.944 113.659Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M54.944 113.659L54.856 116.023L71.0378 115.391L71.1454 112.597L54.944 113.659Z"
          fill="white"
        />
        <path
          d="M60.4113 112.866L59.6089 112.877L59.6638 116.962L60.4662 116.952L60.4113 112.866Z"
          fill="#263238"
        />
        <path
          d="M67.3871 112.874L63.8491 113.057L63.9878 115.739L67.5258 115.556L67.3871 112.874Z"
          fill="white"
        />
        <path
          d="M70.3049 112.154L69.5024 112.164L69.5566 116.25L70.3591 116.239L70.3049 112.154Z"
          fill="#263238"
        />
        <path
          d="M50.5501 114.658L54.2494 116.772C55.1122 117.099 55.8314 117.722 56.28 118.528C57.1853 119.996 55.8837 125.594 55.1791 125.594C54.4745 125.594 50.4963 124.723 50.5501 123.95C50.6039 123.177 52.1453 119.497 52.1453 119.497L48.5684 117.271L50.5501 114.658Z"
          fill="#E4897B"
        />
        <path
          d="M54.8558 113.654C54.8558 113.654 53.8772 99.3072 50.5498 91.1062C50.5498 91.1062 57.4003 87.4265 70.3477 84.745C70.3477 84.745 74.0568 93.773 71.1453 112.768L54.8558 113.654Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M65.6162 99.9042C65.8702 99.9042 66.0762 99.6982 66.0762 99.4442C66.0762 99.1902 65.8702 98.9843 65.6162 98.9843C65.3622 98.9843 65.1562 99.1902 65.1562 99.4442C65.1562 99.6982 65.3622 99.9042 65.6162 99.9042Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M66.541 105.013C66.5292 105.127 66.4754 105.233 66.39 105.31C66.3046 105.387 66.1937 105.43 66.0786 105.43C65.9636 105.43 65.8526 105.387 65.7672 105.31C65.6818 105.233 65.628 105.127 65.6162 105.013C65.6095 104.948 65.6165 104.883 65.6367 104.821C65.657 104.759 65.6899 104.702 65.7335 104.654C65.7771 104.605 65.8303 104.567 65.8898 104.54C65.9492 104.514 66.0136 104.5 66.0786 104.5C66.1437 104.5 66.208 104.514 66.2675 104.54C66.3269 104.567 66.3802 104.605 66.4238 104.654C66.4673 104.702 66.5003 104.759 66.5205 104.821C66.5407 104.883 66.5477 104.948 66.541 105.013Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M66.541 110.302C66.5292 110.417 66.4754 110.523 66.39 110.6C66.3046 110.677 66.1937 110.72 66.0786 110.72C65.9636 110.72 65.8526 110.677 65.7672 110.6C65.6818 110.523 65.628 110.417 65.6162 110.302C65.6095 110.238 65.6165 110.172 65.6367 110.11C65.657 110.048 65.6899 109.992 65.7335 109.943C65.7771 109.895 65.8303 109.856 65.8898 109.83C65.9492 109.803 66.0136 109.79 66.0786 109.79C66.1437 109.79 66.208 109.803 66.2675 109.83C66.3269 109.856 66.3802 109.895 66.4238 109.943C66.4673 109.992 66.5003 110.048 66.5205 110.11C66.5407 110.172 66.5477 110.238 66.541 110.302Z"
          fill="white"
        />
        <path
          d="M50.55 91.1064C47.5064 92.7799 36.4722 105.463 37.3383 109.236C38.2044 113.008 50.55 118.915 50.55 118.915L52.6687 115.259C52.6687 115.259 44.5852 109.236 44.1986 108.267C43.812 107.298 53.0944 99.9974 53.0944 99.9974C53.212 98.0601 53.1547 96.1162 52.9232 94.1892C52.8246 93.5151 52.5547 92.8777 52.1391 92.3379C51.7236 91.7981 51.1764 91.3741 50.55 91.1064Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M50.55 91.1064C47.5064 92.7799 36.4722 105.463 37.3383 109.236C38.2044 113.008 50.55 118.915 50.55 118.915L52.6687 115.259C52.6687 115.259 44.5852 109.236 44.1986 108.267C43.812 107.298 53.0944 99.9974 53.0944 99.9974C53.212 98.0601 53.1547 96.1162 52.9232 94.1892C52.8246 93.5151 52.5547 92.8777 52.1391 92.3379C51.7236 91.7981 51.1764 91.3741 50.55 91.1064Z"
          fill="white"
        />
        <path
          d="M56.2655 93.6509C56.2655 93.6509 53.1681 88.5913 59.9452 86.8444C66.7222 85.0976 68.7872 89.1687 68.7872 89.1687L65.4109 88.5864L64.9852 96.6553C63.4243 93.8623 60.8846 91.7461 57.8558 90.7149L56.2655 93.6509Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M56.2655 93.6509C56.2655 93.6509 53.1681 88.5913 59.9452 86.8444C66.7222 85.0976 68.7872 89.1687 68.7872 89.1687L65.4109 88.5864L64.9852 96.6553C63.4243 93.8623 60.8846 91.7461 57.8558 90.7149L56.2655 93.6509Z"
          fill="white"
        />
        <path
          d="M63.9621 92.6867C61.6906 91.6255 59.5135 90.3733 57.4541 88.9434C59.0444 87.4461 58.7557 85.2344 58.3496 83.8545C58.2234 83.4171 58.0597 82.9915 57.8602 82.5823L62.543 83.4582C62.4351 84.1053 62.3746 84.7595 62.362 85.4154C62.3446 85.9084 62.3889 86.4015 62.4941 86.8834C62.8122 88.2829 63.6097 88.3024 63.6097 88.3024C64.4416 89.834 63.9621 92.6867 63.9621 92.6867Z"
          fill="#E4897B"
        />
        <path
          opacity="0.2"
          d="M62.3719 85.4301C62.3545 85.923 62.3988 86.4162 62.504 86.898C60.6446 86.6534 59.2353 85.1658 58.3497 83.874C58.2235 83.4367 58.0598 83.011 57.8604 82.6018L62.5431 83.4777C62.4387 84.1235 62.3815 84.776 62.3719 85.4301Z"
          fill="black"
        />
        <path
          d="M61.4421 70.2761C66.487 70.2761 66.9078 72.7227 67.1965 76.9162C67.5586 82.1617 66.3891 85.8903 61.2953 84.7453C54.3812 83.2186 54.6748 70.2761 61.4421 70.2761Z"
          fill="#E4897B"
        />
        <path
          d="M64.5835 76.6812C64.9084 77.2913 65.306 77.8597 65.7677 78.3742C65.6473 78.5418 65.4834 78.6733 65.2936 78.7544C65.1038 78.8355 64.8955 78.8631 64.6911 78.8342L64.5835 76.6812Z"
          fill="#DE5753"
        />
        <path
          d="M62.0736 76.3434C62.0736 76.6761 61.9023 76.9501 61.6821 76.955C61.4619 76.9599 61.2711 76.6957 61.2662 76.3581C61.2613 76.0204 61.4326 75.7513 61.6528 75.7464C61.873 75.7415 62.0638 76.0057 62.0736 76.3434Z"
          fill="#263238"
        />
        <path
          d="M61.5645 75.7614L62.2838 75.4629C62.2838 75.4629 61.9363 76.0843 61.5645 75.7614Z"
          fill="#263238"
        />
        <path
          d="M66.2328 76.1873C66.2328 76.52 66.0615 76.794 65.8413 76.7989C65.6211 76.8038 65.4303 76.5347 65.4254 76.2019C65.4205 75.8692 65.5918 75.5903 65.8169 75.5854C66.0419 75.5805 66.223 75.8496 66.2328 76.1873Z"
          fill="#263238"
        />
        <path
          d="M65.7236 75.6046L66.4429 75.3062C66.4429 75.3062 66.0955 75.9227 65.7236 75.6046Z"
          fill="#263238"
        />
        <path
          d="M62.0394 73.5302C61.7171 73.4902 61.3898 73.5246 61.0828 73.6306C60.7758 73.7366 60.4971 73.9115 60.2681 74.1419"
          stroke="#263238"
          strokeWidth="0.34642"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.9995 73.3538C65.2782 73.2841 65.5691 73.2783 65.8504 73.3368C66.1317 73.3953 66.3961 73.5165 66.6241 73.6914"
          stroke="#263238"
          strokeWidth="0.34642"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.0581 77.3808C57.0581 77.3808 58.9713 74.0339 58.6875 71.4503C58.6875 71.4503 61.5647 68.485 65.4353 71.416C65.4353 71.416 67.05 74.2002 67.1528 76.3679C67.1528 76.3679 67.9553 69.0917 60.9531 68.9107C53.9509 68.7296 53.9558 78.9809 57.567 82.044C57.567 82.044 55.8739 79.4702 57.0581 77.3808Z"
          fill="#263238"
        />
        <path
          d="M57.7331 78.2471C57.7146 78.0132 57.6501 77.7852 57.5434 77.5762C57.4367 77.3671 57.2899 77.1812 57.1113 77.029C56.9327 76.8767 56.7259 76.7612 56.5026 76.6889C56.2793 76.6167 56.044 76.5891 55.8101 76.6079C54.5477 76.7449 53.9409 79.3334 57.0823 79.9646C57.5227 80.0478 57.7772 79.6123 57.7331 78.2471Z"
          fill="#E4897B"
        />
        <path
          d="M64.1189 81.0411C64.1189 81.0411 62.4112 81.4277 61.7065 79.901"
          stroke="#263238"
          strokeWidth="0.34642"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.7116 71.4504C58.7116 71.4504 63.3699 69.3855 66.4526 72.111C66.4526 72.111 67.6612 68.5194 63.5167 67.2961C59.3721 66.0728 55.1982 70.1978 55.1982 70.1978L58.7116 71.4504Z"
          fill="#263238"
        />
        <path
          d="M58.7118 71.4506C58.7118 71.4506 55.9961 74.1321 56.3876 79.4754C56.779 84.8188 56.2652 88.1902 55.2034 91.5323C54.1416 94.8744 56.9209 95.8726 56.9209 95.8726C56.9209 95.8726 53.755 96.6555 53.1042 93.2645C52.6736 91.043 53.6914 86.2281 53.1825 80.4932C52.6736 74.7584 54.6896 70.7019 58.7118 71.4506Z"
          fill="#263238"
        />
        <path
          d="M134.287 59.5453C133.397 59.5453 132.527 59.8093 131.786 60.304C131.046 60.7986 130.469 61.5017 130.128 62.3243C129.788 63.1469 129.698 64.052 129.872 64.9253C130.046 65.7985 130.475 66.6007 131.104 67.2303C131.734 67.8598 132.536 68.2886 133.409 68.4623C134.282 68.636 135.188 68.5468 136.01 68.2061C136.833 67.8654 137.536 67.2884 138.03 66.5481C138.525 65.8078 138.789 64.9374 138.789 64.047C138.789 62.8531 138.315 61.7081 137.471 60.8638C136.626 60.0196 135.481 59.5453 134.287 59.5453ZM134.287 63.6115C134.029 63.6115 133.776 63.5349 133.562 63.3914C133.347 63.2478 133.179 63.0438 133.08 62.805C132.981 62.5663 132.956 62.3036 133.006 62.0502C133.056 61.7967 133.181 61.5639 133.364 61.3812C133.546 61.1985 133.779 61.0741 134.033 61.0237C134.286 60.9733 134.549 60.9991 134.787 61.098C135.026 61.1969 135.23 61.3644 135.374 61.5792C135.517 61.7941 135.594 62.0467 135.594 62.3051C135.594 62.4766 135.56 62.6465 135.494 62.805C135.429 62.9635 135.333 63.1076 135.211 63.2289C135.09 63.3502 134.946 63.4464 134.787 63.5121C134.629 63.5777 134.459 63.6115 134.287 63.6115Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M134.287 59.5453C133.397 59.5453 132.527 59.8093 131.786 60.304C131.046 60.7986 130.469 61.5017 130.128 62.3243C129.788 63.1469 129.698 64.052 129.872 64.9253C130.046 65.7985 130.475 66.6007 131.104 67.2303C131.734 67.8598 132.536 68.2886 133.409 68.4623C134.282 68.636 135.188 68.5468 136.01 68.2061C136.833 67.8654 137.536 67.2884 138.03 66.5481C138.525 65.8078 138.789 64.9374 138.789 64.047C138.789 62.8531 138.315 61.7081 137.471 60.8638C136.626 60.0196 135.481 59.5453 134.287 59.5453ZM134.287 63.6115C134.029 63.6115 133.776 63.5349 133.562 63.3914C133.347 63.2478 133.179 63.0438 133.08 62.805C132.981 62.5663 132.956 62.3036 133.006 62.0502C133.056 61.7967 133.181 61.5639 133.364 61.3812C133.546 61.1985 133.779 61.0741 134.033 61.0237C134.286 60.9733 134.549 60.9991 134.787 61.098C135.026 61.1969 135.23 61.3644 135.374 61.5792C135.517 61.7941 135.594 62.0467 135.594 62.3051C135.594 62.4766 135.56 62.6465 135.494 62.805C135.429 62.9635 135.333 63.1076 135.211 63.2289C135.09 63.3502 134.946 63.4464 134.787 63.5121C134.629 63.5777 134.459 63.6115 134.287 63.6115Z"
          fill="white"
        />
        <path
          d="M178.83 65.4562H89.7397C87.7156 65.4562 86.0747 67.0971 86.0747 69.1212V201.884C86.0747 203.908 87.7156 205.549 89.7397 205.549H178.83C180.854 205.549 182.495 203.908 182.495 201.884V69.1212C182.495 67.0971 180.854 65.4562 178.83 65.4562Z"
          fill="#407BFF"
        />
        <path
          opacity="0.8"
          d="M177.392 73.599H91.1782V198.885H177.392V73.599Z"
          fill="white"
        />
        <path
          d="M157.217 64.2625H111.358C110.836 64.2625 110.414 64.6853 110.414 65.2068V71.0885C110.414 71.61 110.836 72.0329 111.358 72.0329H157.217C157.739 72.0329 158.161 71.61 158.161 71.0885V65.2068C158.161 64.6853 157.739 64.2625 157.217 64.2625Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M157.217 64.2625H111.358C110.836 64.2625 110.414 64.6853 110.414 65.2068V71.0885C110.414 71.61 110.836 72.0329 111.358 72.0329H157.217C157.739 72.0329 158.161 71.61 158.161 71.0885V65.2068C158.161 64.6853 157.739 64.2625 157.217 64.2625Z"
          fill="white"
        />
        <path
          d="M151.937 79.2504H138.119C137.838 79.2554 137.56 79.1832 137.317 79.0417C137.074 78.9003 136.874 78.6949 136.739 78.4479C136.502 78.005 136.15 77.6346 135.719 77.3764C135.288 77.1182 134.795 76.9818 134.293 76.9818C133.79 76.9818 133.297 77.1182 132.866 77.3764C132.436 77.6346 132.083 78.005 131.846 78.4479C131.711 78.6946 131.511 78.8996 131.268 79.041C131.024 79.1824 130.747 79.2548 130.466 79.2504H116.633C116.244 79.2504 115.87 79.0958 115.595 78.8205C115.32 78.5452 115.165 78.1718 115.165 77.7825V69.5374C115.165 69.1481 115.32 68.7747 115.595 68.4994C115.87 68.2241 116.244 68.0695 116.633 68.0695H151.937C152.327 68.0695 152.7 68.2241 152.975 68.4994C153.251 68.7747 153.405 69.1481 153.405 69.5374V77.758C153.409 77.9528 153.373 78.1463 153.301 78.3273C153.228 78.5082 153.121 78.6729 152.984 78.8119C152.848 78.9508 152.685 79.0611 152.505 79.1364C152.325 79.2117 152.132 79.2505 151.937 79.2504ZM134.288 75.9964C134.969 75.9951 135.638 76.1799 136.221 76.5309C136.805 76.8819 137.282 77.3857 137.6 77.988C137.654 78.0773 137.73 78.1507 137.821 78.2006C137.912 78.2505 138.015 78.2751 138.119 78.2718H151.937C152.067 78.2718 152.192 78.2202 152.283 78.1285C152.375 78.0367 152.427 77.9122 152.427 77.7825V69.5374C152.427 69.4076 152.375 69.2832 152.283 69.1914C152.192 69.0997 152.067 69.0481 151.937 69.0481H116.633C116.503 69.0481 116.379 69.0997 116.287 69.1914C116.195 69.2832 116.144 69.4076 116.144 69.5374V77.758C116.144 77.8878 116.195 78.0122 116.287 78.104C116.379 78.1958 116.503 78.2473 116.633 78.2473H130.461C130.564 78.2503 130.666 78.2255 130.757 78.1756C130.847 78.1257 130.923 78.0524 130.975 77.9635C131.297 77.3662 131.775 76.8676 132.359 76.5211C132.942 76.1746 133.609 75.9932 134.288 75.9964Z"
          fill="#263238"
        />
        <g opacity="0.2">
          <path
            d="M151.937 79.2504H138.119C137.838 79.2554 137.56 79.1832 137.317 79.0417C137.074 78.9003 136.874 78.6949 136.739 78.4479C136.502 78.005 136.15 77.6346 135.719 77.3764C135.288 77.1182 134.795 76.9818 134.293 76.9818C133.79 76.9818 133.297 77.1182 132.866 77.3764C132.436 77.6346 132.083 78.005 131.846 78.4479C131.711 78.6946 131.511 78.8996 131.268 79.041C131.024 79.1824 130.747 79.2548 130.466 79.2504H116.633C116.244 79.2504 115.87 79.0958 115.595 78.8205C115.32 78.5452 115.165 78.1718 115.165 77.7825V69.5374C115.165 69.1481 115.32 68.7747 115.595 68.4994C115.87 68.2241 116.244 68.0695 116.633 68.0695H151.937C152.327 68.0695 152.7 68.2241 152.975 68.4994C153.251 68.7747 153.405 69.1481 153.405 69.5374V77.758C153.409 77.9528 153.373 78.1463 153.301 78.3273C153.228 78.5082 153.121 78.6729 152.984 78.8119C152.848 78.9508 152.685 79.0611 152.505 79.1364C152.325 79.2117 152.132 79.2505 151.937 79.2504ZM134.288 75.9964C134.969 75.9951 135.638 76.1799 136.221 76.5309C136.805 76.8819 137.282 77.3857 137.6 77.988C137.654 78.0773 137.73 78.1507 137.821 78.2006C137.912 78.2505 138.015 78.2751 138.119 78.2718H151.937C152.067 78.2718 152.192 78.2202 152.283 78.1285C152.375 78.0367 152.427 77.9122 152.427 77.7825V69.5374C152.427 69.4076 152.375 69.2832 152.283 69.1914C152.192 69.0997 152.067 69.0481 151.937 69.0481H116.633C116.503 69.0481 116.379 69.0997 116.287 69.1914C116.195 69.2832 116.144 69.4076 116.144 69.5374V77.758C116.144 77.8878 116.195 78.0122 116.287 78.104C116.379 78.1958 116.503 78.2473 116.633 78.2473H130.461C130.564 78.2503 130.666 78.2255 130.757 78.1756C130.847 78.1257 130.923 78.0524 130.975 77.9635C131.297 77.3662 131.775 76.8676 132.359 76.5211C132.942 76.1746 133.609 75.9932 134.288 75.9964Z"
            fill="white"
          />
        </g>
        <path
          d="M149.843 66.6357H118.747C118.433 66.6357 118.179 66.8899 118.179 67.2033V69.4053C118.179 69.7188 118.433 69.9729 118.747 69.9729H149.843C150.157 69.9729 150.411 69.7188 150.411 69.4053V67.2033C150.411 66.8899 150.157 66.6357 149.843 66.6357Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M149.828 66.6357H118.732C118.419 66.6357 118.165 66.8899 118.165 67.2033V69.4053C118.165 69.7188 118.419 69.9729 118.732 69.9729H149.828C150.142 69.9729 150.396 69.7188 150.396 69.4053V67.2033C150.396 66.8899 150.142 66.6357 149.828 66.6357Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M142.293 142.025H133.583V150.735H142.293V142.025Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M141.275 154.239H134.601C134.331 154.239 134.072 154.131 133.881 153.941C133.69 153.75 133.583 153.491 133.583 153.221C133.584 152.952 133.692 152.694 133.883 152.504C134.074 152.315 134.332 152.208 134.601 152.208H141.275C141.544 152.208 141.802 152.315 141.993 152.504C142.184 152.694 142.292 152.952 142.293 153.221C142.293 153.491 142.186 153.75 141.995 153.941C141.804 154.131 141.545 154.239 141.275 154.239Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M169.303 142.025H146.902C146.389 142.025 145.973 142.442 145.973 142.955V142.96C145.973 143.473 146.389 143.89 146.902 143.89H169.303C169.817 143.89 170.233 143.473 170.233 142.96V142.955C170.233 142.442 169.817 142.025 169.303 142.025Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M164.41 146.918H146.902C146.389 146.918 145.973 147.335 145.973 147.848V147.853C145.973 148.366 146.389 148.783 146.902 148.783H164.41C164.924 148.783 165.34 148.366 165.34 147.853V147.848C165.34 147.335 164.924 146.918 164.41 146.918Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M169.303 151.812H146.902C146.389 151.812 145.973 152.228 145.973 152.742V152.746C145.973 153.26 146.389 153.676 146.902 153.676H169.303C169.817 153.676 170.233 153.26 170.233 152.746V152.742C170.233 152.228 169.817 151.812 169.303 151.812Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M156.581 156.705H146.902C146.389 156.705 145.973 157.121 145.973 157.635V157.639C145.973 158.153 146.389 158.569 146.902 158.569H156.581C157.095 158.569 157.511 158.153 157.511 157.639V157.635C157.511 157.121 157.095 156.705 156.581 156.705Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M160.03 86.5023H108.299C107.852 86.501 107.424 86.3227 107.107 86.0064C106.791 85.6901 106.613 85.2615 106.611 84.8141C106.611 84.3664 106.789 83.937 107.106 83.6204C107.422 83.3038 107.852 83.126 108.299 83.126H160.03C160.478 83.126 160.908 83.3038 161.224 83.6204C161.541 83.937 161.719 84.3664 161.719 84.8141C161.717 85.2615 161.539 85.6901 161.223 86.0064C160.906 86.3227 160.478 86.501 160.03 86.5023Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M168.657 96.4938H134.513C134.266 96.4925 134.029 96.3935 133.855 96.2184C133.681 96.0432 133.583 95.8063 133.583 95.5592C133.583 95.3127 133.681 95.0762 133.855 94.9018C134.03 94.7275 134.266 94.6295 134.513 94.6295H168.657C168.904 94.6295 169.141 94.7275 169.315 94.9018C169.489 95.0762 169.587 95.3127 169.587 95.5592C169.587 95.8063 169.489 96.0432 169.315 96.2184C169.141 96.3935 168.905 96.4925 168.657 96.4938Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M163.764 101.387H134.513C134.266 101.386 134.029 101.287 133.855 101.112C133.681 100.937 133.583 100.7 133.583 100.453C133.583 100.206 133.681 99.9697 133.855 99.7954C134.03 99.621 134.266 99.5231 134.513 99.5231H163.764C164.011 99.5231 164.247 99.621 164.422 99.7954C164.596 99.9697 164.694 100.206 164.694 100.453C164.694 100.7 164.596 100.937 164.422 101.112C164.248 101.287 164.011 101.386 163.764 101.387Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M168.657 106.28H134.513C134.266 106.279 134.029 106.18 133.855 106.005C133.681 105.83 133.583 105.593 133.583 105.346C133.583 105.099 133.681 104.863 133.855 104.688C134.03 104.514 134.266 104.416 134.513 104.416H168.657C168.904 104.416 169.141 104.514 169.315 104.688C169.489 104.863 169.587 105.099 169.587 105.346C169.587 105.593 169.489 105.83 169.315 106.005C169.141 106.18 168.905 106.279 168.657 106.28Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M158.871 111.174H134.513C134.266 111.173 134.029 111.074 133.855 110.898C133.681 110.723 133.583 110.486 133.583 110.239C133.583 109.993 133.681 109.756 133.855 109.582C134.03 109.408 134.266 109.31 134.513 109.31H158.871C159.118 109.31 159.354 109.408 159.528 109.582C159.703 109.756 159.801 109.993 159.801 110.239C159.801 110.486 159.703 110.723 159.529 110.898C159.355 111.074 159.118 111.173 158.871 111.174Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M136.025 117.006L136.563 118.092L137.767 118.269L136.896 119.115L137.101 120.314L136.025 119.746L134.953 120.314L135.159 119.115L134.288 118.269L135.486 118.092L136.025 117.006Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M142.102 117.006L142.64 118.092L143.839 118.269L142.968 119.115L143.173 120.314L142.102 119.746L141.025 120.314L141.231 119.115L140.36 118.269L141.564 118.092L142.102 117.006Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M148.175 117.006L148.713 118.092L149.917 118.269L149.046 119.115L149.251 120.314L148.175 119.746L147.098 120.314L147.304 119.115L146.438 118.269L147.636 118.092L148.175 117.006Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M154.252 117.006L154.785 118.092L155.989 118.269L155.118 119.115L155.323 120.314L154.252 119.746L153.175 120.314L153.381 119.115L152.51 118.269L153.713 118.092L154.252 117.006Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M160.324 117.006L160.862 118.092L162.061 118.269L161.195 119.115L161.401 120.314L160.324 119.746L159.247 120.314L159.453 119.115L158.587 118.269L159.786 118.092L160.324 117.006Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M136.025 123.24L136.563 124.327L137.767 124.503L136.896 125.349L137.101 126.548L136.025 125.98L134.953 126.548L135.159 125.349L134.288 124.503L135.486 124.327L136.025 123.24Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M142.102 123.24L142.64 124.327L143.839 124.503L142.968 125.349L143.173 126.548L142.102 125.98L141.025 126.548L141.231 125.349L140.36 124.503L141.564 124.327L142.102 123.24Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M148.175 123.24L148.713 124.327L149.917 124.503L149.046 125.349L149.251 126.548L148.175 125.98L147.098 126.548L147.304 125.349L146.438 124.503L147.636 124.327L148.175 123.24Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M154.252 123.24L154.785 124.327L155.989 124.503L155.118 125.349L155.323 126.548L154.252 125.98L153.175 126.548L153.381 125.349L152.51 124.503L153.713 124.327L154.252 123.24Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M160.324 123.24L160.862 124.327L162.061 124.503L161.195 125.349L161.401 126.548L160.324 125.98L159.247 126.548L159.453 125.349L158.587 124.503L159.786 124.327L160.324 123.24Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M136.025 129.474L136.563 130.561L137.767 130.737L136.896 131.583L137.101 132.782L136.025 132.215L134.953 132.782L135.159 131.583L134.288 130.737L135.486 130.561L136.025 129.474Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M142.102 129.474L142.64 130.561L143.839 130.737L142.968 131.583L143.173 132.782L142.102 132.215L141.025 132.782L141.231 131.583L140.36 130.737L141.564 130.561L142.102 129.474Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M148.175 129.474L148.713 130.561L149.917 130.737L149.046 131.583L149.251 132.782L148.175 132.215L147.098 132.782L147.304 131.583L146.438 130.737L147.636 130.561L148.175 129.474Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M154.252 129.474L154.785 130.561L155.989 130.737L155.118 131.583L155.323 132.782L154.252 132.215L153.175 132.782L153.381 131.583L152.51 130.737L153.713 130.561L154.252 129.474Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M160.324 129.474L160.862 130.561L162.061 130.737L161.195 131.583L161.401 132.782L160.324 132.215L159.247 132.782L159.453 131.583L158.587 130.737L159.786 130.561L160.324 129.474Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M142.293 166.491H133.583V175.201H142.293V166.491Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M141.275 178.705H134.601C134.331 178.705 134.072 178.597 133.881 178.406C133.69 178.216 133.583 177.957 133.583 177.687C133.584 177.418 133.692 177.16 133.883 176.97C134.074 176.78 134.332 176.674 134.601 176.674H141.275C141.544 176.674 141.802 176.78 141.993 176.97C142.184 177.16 142.292 177.418 142.293 177.687C142.293 177.957 142.186 178.216 141.995 178.406C141.804 178.597 141.545 178.705 141.275 178.705Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M169.303 166.491H146.902C146.389 166.491 145.973 166.908 145.973 167.421V167.426C145.973 167.939 146.389 168.356 146.902 168.356H169.303C169.817 168.356 170.233 167.939 170.233 167.426V167.421C170.233 166.908 169.817 166.491 169.303 166.491Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M164.41 171.384H146.902C146.389 171.384 145.973 171.801 145.973 172.314V172.319C145.973 172.832 146.389 173.249 146.902 173.249H164.41C164.924 173.249 165.34 172.832 165.34 172.319V172.314C165.34 171.801 164.924 171.384 164.41 171.384Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M169.303 176.278H146.902C146.389 176.278 145.973 176.694 145.973 177.208V177.212C145.973 177.726 146.389 178.142 146.902 178.142H169.303C169.817 178.142 170.233 177.726 170.233 177.212V177.208C170.233 176.694 169.817 176.278 169.303 176.278Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M156.581 181.171H146.902C146.389 181.171 145.973 181.587 145.973 182.1V182.105C145.973 182.619 146.389 183.035 146.902 183.035H156.581C157.095 183.035 157.511 182.619 157.511 182.105V182.1C157.511 181.587 157.095 181.171 156.581 181.171Z"
          fill="black"
        />
        <path
          d="M128.024 94.6295H96.6196V136.329H128.024V94.6295Z"
          fill="white"
        />
        <path
          d="M126.556 96.0975H98.0874V134.861H126.556V96.0975Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M126.556 96.0975H98.0874V134.861H126.556V96.0975Z"
          fill="white"
        />
        <path
          d="M126.556 126.201V134.862H124.344C123.732 134.278 123.058 133.765 122.333 133.33C121.984 133.119 121.609 132.955 121.218 132.841C117.895 131.94 122.686 123.054 122.686 123.054C123.791 123.407 125.166 124.659 126.556 126.201Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M126.556 126.201V134.862H124.344C123.732 134.278 123.058 133.765 122.333 133.33C121.984 133.119 121.609 132.955 121.218 132.841C117.895 131.94 122.686 123.054 122.686 123.054C123.791 123.407 125.166 124.659 126.556 126.201Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M126.556 134.832V134.862H124.345C123.733 134.279 123.058 133.765 122.333 133.33L122.989 129.234C123.629 129.765 124.196 130.377 124.677 131.055C125.514 132.198 126.149 133.476 126.556 134.832Z"
          fill="black"
        />
        <path
          d="M120.459 122.697C120.391 123.049 120.322 123.407 120.244 123.759C120.166 124.111 120.058 124.596 119.955 125.012C118.888 129.303 117.127 133.061 114.602 134.862H105.97C103.46 133.584 102.981 131.436 103.064 129.186C103.118 127.664 103.431 126.113 103.553 124.782C103.592 124.322 103.592 123.861 103.553 123.402C103.49 122.707 103.357 121.87 103.196 120.955C102.447 116.693 100.984 110.542 101.728 107.386C102.614 103.55 105.413 102.493 105.413 102.493C105.413 102.493 106.362 102.429 107.82 102.439C108.897 102.439 110.267 102.493 111.7 102.63H111.803C113.411 102.775 115.004 103.056 116.564 103.472L116.921 103.574C117.23 103.667 117.538 103.765 117.836 103.873C118.032 103.941 118.223 104.015 118.414 104.093C118.661 104.198 118.885 104.353 119.069 104.548C121.286 106.824 121.834 115.166 120.459 122.697Z"
          fill="#263238"
        />
        <path
          d="M117.88 104.568C120.988 103.1 122.328 113.234 121.218 120.549"
          stroke="#263238"
          strokeWidth="0.24466"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M124.912 134.861H102.579C102.486 134.44 102.394 134.02 102.296 133.604C101.744 131.168 101.013 128.777 100.108 126.45C101.023 125.906 101.979 125.433 102.966 125.036C103.152 124.952 103.343 124.874 103.548 124.796C104.321 124.488 105.222 124.17 106.23 123.866L106.553 123.773C107.311 123.553 108.128 123.348 108.999 123.166C110.817 122.785 112.663 122.552 114.519 122.472C115.931 122.405 117.347 122.428 118.756 122.54C119.309 122.579 119.872 122.633 120.445 122.707C121.164 122.795 121.913 122.907 122.651 123.049C122.735 123.181 124.095 125.344 124.658 131.059C124.795 132.185 124.878 133.447 124.912 134.861Z"
          fill="#407BFF"
        />
        <path
          d="M106.357 128.226C106.357 128.226 103.803 121.934 111.779 121.224C119.755 120.515 121.345 125.486 121.345 125.486L117.641 124.204L115.684 133.232C114.426 129.789 111.936 126.935 108.696 125.222L106.357 128.226Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M106.357 128.226C106.357 128.226 103.803 121.934 111.779 121.224C119.755 120.515 121.345 125.486 121.345 125.486L117.641 124.204L115.684 133.232C114.426 129.789 111.936 126.935 108.696 125.222L106.357 128.226Z"
          fill="white"
        />
        <path
          d="M115.351 129.235C113.297 127.104 110.964 125.261 108.417 123.754C110.325 122.139 110.13 119.595 109.748 117.995C109.627 117.493 109.463 117.001 109.259 116.527L114.558 117.809C114.394 118.547 114.285 119.296 114.23 120.05C114.184 120.605 114.206 121.164 114.294 121.714C114.573 123.333 115.483 123.402 115.483 123.402C116.349 125.222 115.351 129.235 115.351 129.235Z"
          fill="#E4897B"
        />
        <path
          opacity="0.2"
          d="M114.25 120.045C114.204 120.6 114.225 121.159 114.313 121.709C112.204 121.317 110.683 119.531 109.748 118.005C109.627 117.502 109.463 117.011 109.259 116.537L114.558 117.819C114.402 118.552 114.299 119.296 114.25 120.045Z"
          fill="black"
        />
        <path
          d="M112.571 102.606C118.34 102.606 118.825 105.395 119.158 110.2C119.569 116.209 118.228 120.476 112.4 119.174C104.488 117.418 104.825 102.606 112.571 102.606Z"
          fill="#E4897B"
        />
        <path
          d="M116.144 109.936C116.514 110.64 116.967 111.297 117.494 111.893C117.359 112.086 117.172 112.238 116.955 112.332C116.738 112.425 116.499 112.456 116.266 112.422L116.144 109.936Z"
          fill="#DE5753"
        />
        <path
          d="M113.291 109.564C113.291 109.95 113.1 110.263 112.84 110.268C112.581 110.273 112.351 109.97 112.351 109.583C112.351 109.197 112.542 108.889 112.796 108.884C113.051 108.879 113.281 109.163 113.291 109.564Z"
          fill="#263238"
        />
        <path
          d="M112.708 108.884L113.535 108.536C113.535 108.536 113.134 109.246 112.708 108.884Z"
          fill="#263238"
        />
        <path
          d="M118.052 109.363C118.052 109.75 117.861 110.063 117.602 110.068C117.342 110.072 117.112 109.769 117.112 109.383C117.112 108.996 117.303 108.688 117.558 108.683C117.812 108.678 118.042 108.981 118.052 109.363Z"
          fill="#263238"
        />
        <path
          d="M117.47 108.703L118.292 108.36C118.292 108.36 117.92 109.075 117.47 108.703Z"
          fill="#263238"
        />
        <path
          d="M113.251 106.325C113.251 106.325 111.94 106.153 111.226 107.024"
          stroke="#263238"
          strokeWidth="0.34642"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M116.643 106.138C116.961 106.058 117.293 106.051 117.614 106.118C117.936 106.185 118.237 106.324 118.497 106.525"
          stroke="#263238"
          strokeWidth="0.34642"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M107.556 110.733C107.556 110.733 109.743 106.906 109.42 103.946C109.42 103.946 112.708 100.555 117.137 103.907C117.137 103.907 118.991 107.097 119.094 109.573C119.094 109.573 120.014 101.255 111.999 101.044C103.984 100.834 104.003 112.568 108.133 116.071C108.133 116.071 106.2 113.126 107.556 110.733Z"
          fill="#263238"
        />
        <path
          d="M108.315 111.717C108.27 111.178 108.014 110.678 107.602 110.327C107.191 109.976 106.657 109.802 106.118 109.842C104.65 109.999 103.974 112.964 107.586 113.684C108.085 113.786 108.378 113.282 108.315 111.717Z"
          fill="#E4897B"
        />
        <path
          d="M115.635 114.946C115.635 114.946 113.677 115.387 112.87 113.64"
          stroke="#263238"
          stroke-width="0.34642"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M109.42 103.946C109.42 103.946 114.749 101.583 118.277 104.705C118.277 104.705 119.662 100.594 114.935 99.1901C110.208 97.7858 105.403 102.513 105.403 102.513L109.42 103.946Z"
          fill="#263238"
        />
        <path
          d="M109.42 103.946C109.42 103.946 106.308 107.015 106.758 113.126C107.208 119.238 106.616 123.103 105.403 126.93C104.189 130.756 107.36 131.896 107.36 131.896C107.36 131.896 103.739 132.787 102.995 128.912C102.506 126.367 103.671 120.857 103.083 114.296C102.496 107.734 104.84 103.09 109.42 103.946Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M103.612 134.862H102.58C102.487 134.441 102.394 134.02 102.296 133.604L103.074 132.924C103.294 133.558 103.474 134.205 103.612 134.862Z"
          fill="black"
        />
        <path
          d="M103.078 134.862H98.0874V128.011C98.7046 127.427 99.3802 126.907 100.103 126.46C100.821 126.764 101.449 127.247 101.925 127.865C102.4 128.482 102.709 129.212 102.819 129.983C102.963 130.956 103.047 131.936 103.069 132.919C103.093 133.619 103.088 134.289 103.078 134.862Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M103.078 134.862H98.0874V128.011C98.7046 127.427 99.3802 126.907 100.103 126.46C100.821 126.764 101.449 127.247 101.925 127.865C102.4 128.482 102.709 129.212 102.819 129.983C102.963 130.956 103.047 131.936 103.069 132.919C103.093 133.619 103.088 134.289 103.078 134.862Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M128.024 141.189H96.6196V182.83H128.024V141.189Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M122.744 147.643H101.327C101.08 147.641 100.843 147.542 100.669 147.367C100.495 147.192 100.397 146.955 100.397 146.708C100.397 146.462 100.495 146.225 100.669 146.051C100.844 145.876 101.08 145.778 101.327 145.778H122.744C122.867 145.778 122.988 145.801 123.102 145.848C123.215 145.894 123.318 145.963 123.405 146.049C123.492 146.135 123.561 146.238 123.608 146.351C123.655 146.464 123.679 146.586 123.679 146.708C123.679 146.831 123.655 146.952 123.608 147.066C123.561 147.179 123.492 147.282 123.405 147.369C123.318 147.456 123.215 147.525 123.102 147.572C122.988 147.619 122.867 147.643 122.744 147.643Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M122.749 150.985H101.327C100.813 150.985 100.397 151.401 100.397 151.915V151.919C100.397 152.433 100.813 152.849 101.327 152.849H122.749C123.263 152.849 123.679 152.433 123.679 151.919V151.915C123.679 151.401 123.263 150.985 122.749 150.985Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M112.958 158.06H101.327C101.08 158.059 100.843 157.96 100.669 157.785C100.495 157.61 100.397 157.373 100.397 157.126C100.397 156.879 100.495 156.643 100.669 156.468C100.844 156.294 101.08 156.196 101.327 156.196H112.958C113.08 156.195 113.202 156.219 113.315 156.265C113.428 156.312 113.532 156.38 113.618 156.467C113.705 156.553 113.774 156.656 113.821 156.769C113.868 156.882 113.892 157.003 113.892 157.126C113.892 157.374 113.794 157.611 113.619 157.787C113.443 157.962 113.206 158.06 112.958 158.06Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M122.744 163.267H101.327C101.08 163.267 100.844 163.169 100.669 162.994C100.495 162.82 100.397 162.584 100.397 162.337C100.397 162.09 100.495 161.853 100.669 161.678C100.843 161.503 101.08 161.404 101.327 161.402H122.744C122.992 161.402 123.23 161.501 123.405 161.676C123.58 161.851 123.679 162.089 123.679 162.337C123.679 162.46 123.655 162.581 123.608 162.694C123.561 162.807 123.492 162.91 123.405 162.996C123.318 163.083 123.215 163.151 123.102 163.197C122.988 163.244 122.867 163.267 122.744 163.267Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M122.744 168.473H101.327C101.08 168.473 100.844 168.375 100.669 168.201C100.495 168.027 100.397 167.79 100.397 167.543C100.397 167.297 100.495 167.06 100.669 166.886C100.844 166.712 101.08 166.614 101.327 166.614H122.744C122.867 166.613 122.988 166.637 123.102 166.683C123.215 166.73 123.318 166.798 123.405 166.884C123.492 166.971 123.561 167.073 123.608 167.187C123.655 167.3 123.679 167.421 123.679 167.543C123.679 167.666 123.655 167.787 123.608 167.9C123.561 168.014 123.492 168.116 123.405 168.203C123.318 168.289 123.215 168.357 123.102 168.404C122.988 168.45 122.867 168.474 122.744 168.473Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M108.065 173.665H101.327C101.08 173.665 100.844 173.567 100.669 173.393C100.495 173.218 100.397 172.982 100.397 172.735C100.396 172.613 100.42 172.491 100.466 172.378C100.513 172.265 100.581 172.161 100.668 172.075C100.754 171.988 100.857 171.919 100.97 171.872C101.083 171.825 101.204 171.801 101.327 171.801H108.065C108.188 171.8 108.309 171.824 108.423 171.87C108.537 171.917 108.64 171.986 108.727 172.073C108.814 172.16 108.883 172.263 108.929 172.377C108.976 172.49 109 172.612 108.999 172.735C108.999 172.858 108.975 172.979 108.928 173.092C108.881 173.205 108.812 173.308 108.725 173.394C108.638 173.481 108.535 173.549 108.422 173.595C108.309 173.642 108.187 173.666 108.065 173.665Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M117.851 178.891H101.327C101.08 178.891 100.844 178.793 100.669 178.618C100.495 178.444 100.397 178.208 100.397 177.961C100.397 177.715 100.495 177.478 100.669 177.304C100.844 177.129 101.08 177.031 101.327 177.031H117.851C117.974 177.031 118.095 177.054 118.208 177.101C118.322 177.147 118.425 177.216 118.512 177.302C118.598 177.388 118.667 177.491 118.714 177.604C118.761 177.717 118.786 177.839 118.786 177.961C118.786 178.084 118.761 178.205 118.714 178.318C118.667 178.431 118.598 178.534 118.512 178.62C118.425 178.707 118.322 178.775 118.208 178.821C118.095 178.868 117.974 178.891 117.851 178.891Z"
          fill="white"
        />
        <path
          d="M197.938 175.079C197.523 174.913 197.074 174.849 196.628 174.892C196.183 174.936 195.755 175.085 195.379 175.328C193.006 176.684 192.013 182.252 191.597 186.583C191.748 180.442 192.756 171.947 196.783 167.323L196.416 167.005C193.045 170.871 191.734 177.212 191.279 182.776C191.215 180.408 191.068 177.843 190.755 175.402C190.863 170.161 191.318 165.405 192.517 163.785L192.12 163.492C191.063 164.921 190.554 168.551 190.349 172.789C189.747 169.613 188.788 166.917 187.286 165.532L186.958 165.889C188.749 167.538 189.737 171.301 190.256 175.46C190.256 176.048 190.256 176.645 190.231 177.242C189.767 174.795 189.14 172.784 188.313 171.977C185.524 169.266 182.246 171.937 182.216 171.977L182.529 172.348C182.647 172.251 185.5 169.936 187.971 172.348C189.057 173.4 189.776 176.84 190.246 180.471C190.31 184.821 190.525 188.912 190.672 191.29C190.672 191.667 190.638 191.882 190.638 191.902H190.711C190.765 192.724 190.804 193.252 190.814 193.37L191.244 193.335C191.244 193.634 191.288 193.825 191.293 193.898L191.783 193.854C191.783 193.815 191.709 193.091 191.655 191.897H191.807C191.807 191.76 191.968 177.853 195.648 175.749C195.959 175.543 196.315 175.416 196.685 175.378C197.056 175.339 197.43 175.391 197.777 175.529C199.93 176.42 201.08 180.54 201.089 180.584L201.579 180.452C201.486 180.275 200.316 176.062 197.938 175.079Z"
          fill="#263238"
        />
        <path
          d="M185.211 175.524C183.073 175.676 181.419 178.568 181.345 178.69L181.776 178.93C181.776 178.901 183.371 176.146 185.245 175.994C186.033 175.945 186.782 176.371 187.476 177.271C190.363 181.044 189.781 191.76 189.776 191.868L190.266 191.897C190.266 191.447 190.862 180.887 187.863 176.978C187.065 175.95 186.17 175.456 185.211 175.524Z"
          fill="#263238"
        />
        <g opacity="0.2">
          <path
            d="M197.938 175.079C197.523 174.913 197.074 174.849 196.628 174.892C196.183 174.936 195.755 175.085 195.379 175.328C193.006 176.684 192.013 182.252 191.597 186.583C191.748 180.442 192.756 171.947 196.783 167.323L196.416 167.005C193.045 170.871 191.734 177.212 191.279 182.776C191.215 180.407 191.068 177.843 190.755 175.402C190.863 170.161 191.318 165.405 192.517 163.785L192.12 163.492C191.063 164.92 190.554 168.551 190.349 172.789C189.747 169.613 188.788 166.917 187.286 165.532L186.958 165.889C188.749 167.538 189.737 171.301 190.256 175.46C190.256 176.048 190.256 176.645 190.231 177.241C189.767 174.795 189.14 172.784 188.313 171.976C185.524 169.266 182.246 171.937 182.216 171.976L182.529 172.348C182.647 172.25 185.5 169.936 187.971 172.348C189.057 173.4 189.776 176.84 190.246 180.471C190.31 184.821 190.525 188.912 190.672 191.29C190.672 191.667 190.638 191.882 190.638 191.902H190.711C190.765 192.724 190.804 193.252 190.814 193.369L191.244 193.335C191.244 193.634 191.288 193.825 191.293 193.898L191.783 193.854C191.783 193.815 191.709 193.091 191.655 191.897H191.807C191.807 191.76 191.968 177.853 195.648 175.749C195.959 175.543 196.315 175.416 196.685 175.378C197.056 175.339 197.43 175.391 197.777 175.529C199.93 176.419 201.08 180.54 201.089 180.584L201.579 180.451C201.486 180.275 200.316 176.062 197.938 175.079Z"
            fill="white"
          />
          <path
            d="M185.211 175.524C183.073 175.676 181.419 178.567 181.345 178.69L181.776 178.93C181.776 178.9 183.371 176.145 185.245 175.994C186.033 175.945 186.782 176.37 187.476 177.271C190.363 181.043 189.781 191.759 189.776 191.867L190.266 191.897C190.266 191.446 190.862 180.887 187.863 176.977C187.065 175.95 186.17 175.455 185.211 175.524Z"
            fill="white"
          />
        </g>
        <path
          d="M198.79 177.604C198.79 177.604 196.877 178.308 197.444 179.977C198.012 181.646 204.07 184.381 204.07 184.381C204.07 184.381 206.609 182.057 204.28 178.274C201.951 174.492 198.844 176.41 198.79 177.604Z"
          fill="#263238"
        />
        <path
          d="M190.461 174.428C190.461 174.428 192.502 174.34 192.619 176.101C192.737 177.863 188.215 182.722 188.215 182.722C188.215 182.722 184.976 181.552 185.671 177.163C186.366 172.774 189.957 173.347 190.461 174.428Z"
          fill="#263238"
        />
        <path
          d="M188.734 166.012C188.734 166.012 190.095 165.865 189.987 165.033C189.879 164.201 184.697 162.073 181.668 165.523C181.668 165.523 183.626 168.879 186.454 168.923C189.282 168.967 188.734 166.012 188.734 166.012Z"
          fill="#263238"
        />
        <path
          d="M191.269 164.001C191.269 164.001 190.041 164.598 189.693 163.834C189.346 163.071 192.629 158.525 197.033 159.837C197.033 159.837 197.15 163.722 194.777 165.253C192.404 166.785 191.269 164.001 191.269 164.001Z"
          fill="#263238"
        />
        <path
          d="M184.829 176.933C184.829 176.933 185.769 176.175 184.756 175.803C183.743 175.431 178.772 175.539 178.723 180.462C178.723 180.462 184.511 181.44 185.226 179.551C185.94 177.663 184.829 176.933 184.829 176.933Z"
          fill="#263238"
        />
        <path
          d="M196.636 165.89C196.636 165.89 195.697 165.131 196.71 164.759C197.723 164.387 202.694 164.495 202.743 169.418C202.743 169.418 196.955 170.396 196.24 168.507C195.526 166.619 196.636 165.89 196.636 165.89Z"
          fill="#263238"
        />
        <path
          d="M193.574 169.853C193.574 169.853 193.49 168.933 194.234 169.295C194.978 169.657 197.571 172.451 194.885 175.103C194.885 175.103 191.269 172.427 191.92 171.032C192.57 169.638 193.574 169.853 193.574 169.853Z"
          fill="#263238"
        />
        <path
          d="M183.978 171.169C183.978 171.169 184.536 170.43 183.719 170.342C182.902 170.254 179.212 171.233 180.068 174.908C180.068 174.908 184.556 174.57 184.746 173.043C184.937 171.517 183.978 171.169 183.978 171.169Z"
          fill="#263238"
        />
        <path
          d="M199.147 205.549H183.753L182.094 188.697H200.811L199.147 205.549Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M199.147 205.549H183.753L182.094 188.697H200.811L199.147 205.549Z"
          fill="white"
        />
        <path
          d="M201.633 186.837H181.272V190.556H201.633V186.837Z"
          fill="#407BFF"
        />
        <path
          opacity="0.5"
          d="M201.633 186.837H181.272V190.556H201.633V186.837Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6614_214806">
          <rect
            width="244.66"
            height="244.66"
            fill="white"
            transform="translate(-0.0065918 0.934448)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoSkillAndExperience;
