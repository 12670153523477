import React from "react";

const LanguageFullIcon = () => {
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 12.5C4.73858 12.5 2.5 10.2614 2.5 7.5C2.5 4.73858 4.73858 2.5 7.5 2.5C10.2614 2.5 12.5 4.73858 12.5 7.5C12.5 8.29984 12.3122 9.05582 11.9783 9.72624C11.8895 9.9044 11.86 10.108 11.9114 10.3003L12.2092 11.4133C12.3385 11.8965 11.8965 12.3385 11.4133 12.2092L10.3003 11.9114C10.108 11.86 9.9044 11.8895 9.72624 11.9783C9.05582 12.3122 8.29984 12.5 7.5 12.5ZM9.5 8.125C9.70711 8.125 9.875 8.29289 9.875 8.5C9.875 8.70711 9.70711 8.875 9.5 8.875H6.75C6.54289 8.875 6.375 8.70711 6.375 8.5C6.375 8.29289 6.54289 8.125 6.75 8.125H9.5ZM9.875 6.75C9.875 6.54289 9.70711 6.375 9.5 6.375H5.5C5.29289 6.375 5.125 6.54289 5.125 6.75C5.125 6.95711 5.29289 7.125 5.5 7.125H9.5C9.70711 7.125 9.875 6.95711 9.875 6.75Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default LanguageFullIcon;
