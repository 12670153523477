//2024-09-26 11:08:06 to 26th Sep 2024..........................
export function formatDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Function to get the correct day suffix
  const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`; // special case for 11th-13th
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  return `${getDayWithSuffix(day)} ${month} ${year}`;
}

export function isDateExceeded(nextPaymentDate) {
  const nextPayment = new Date(nextPaymentDate);
  const today = new Date();

  today.setHours(0, 0, 0, 0);
  nextPayment.setHours(0, 0, 0, 0);

  return nextPayment < today;
}
