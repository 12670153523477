import React from "react";

const ChatIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 17.327C13.6819 17.327 16.6667 14.3422 16.6667 10.6603C16.6667 6.97842 13.6819 3.99365 9.99999 3.99365C6.31809 3.99365 3.33332 6.97842 3.33332 10.6603C3.33332 11.7268 3.58373 12.7347 4.02896 13.6286C4.14728 13.8662 4.18666 14.1377 4.11807 14.3941L3.721 15.8781C3.54863 16.5223 4.138 17.1117 4.78222 16.9393L6.26625 16.5422C6.52261 16.4736 6.79413 16.513 7.03168 16.6313C7.92557 17.0766 8.93354 17.327 9.99999 17.327Z"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
      <path
        d="M7.33334 9.6604H12.6667"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M7.33334 11.9937H11"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChatIcon;
