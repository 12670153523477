import { Button, Input, Form, Select, Modal, Spin } from "antd";
import { Image, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";
import { NotificationContext } from "../../context/NotificationContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
// import NotificationAlertBox from "../../alertBox/NotificationAlertBox";
import PublicProfileServices from "../../services/PublicProfileService";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";
import PublicProfileService from "../../services/PublicProfileService";

const { Text } = Typography;

export const AddJobTypesDetailsSignup = ({ open, onClose, data, type }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { workerFormValues, setInitialServicesList } =
    useContext(WorkerSignUpContext);
  const {
    addJobType,
    fetchAllUOM,
    fetchAllServiceAreas,
    editJobType,
    deleteJobType,
  } = PublicProfileServices();
  const { workerServiceList } = PublicProfileService();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [allUOM, setAllUOM] = useState([]);
  const [allServiceLevels, setAllServiceLevels] = useState([]);

  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    getAllUOM();
    getAllServiceAreas();
  }, []);

  const getAllUOM = async () => {
    setLoading(true);
    try {
      await fetchAllUOM({
        status: 1,
      })
        .then((response) => {
          if (response?.data?.success) {
            let uomObject = [];
            response?.data.output?.forEach((uom) => {
              let data = {
                value: uom?.id,
                label: uom?.uom,
              };
              uomObject.push(data);
            });
            setAllUOM(uomObject);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const getAllServiceAreas = async () => {
    setLoading(true);
    try {
      await fetchAllServiceAreas({
        tasker_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            let levelObject = [];
            response?.data.output?.forEach((level) => {
              let data = {
                value: level?.level_id,
                label: level?.level,
              };
              levelObject.push(data);
            });
            setAllServiceLevels(levelObject);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setUploading(true);
    if (type == 1) {
      try {
        await editJobType({
          trady_job_type_id: data?.trady_job_type_id,
          user_id: getLocalStoragedata("userId"),
          job_category_id: data?.job_category_id,
          job_type_id: data?.job_type_id,
          uom_id: values.unit,
          service_level_id: values.serviceAreaLevel,
          min: values.rate,
        })
          .then((response) => {
            if (response?.data?.success) {
              openNotification("success", "Job type edited successfully!");
              fetchServices();
              onClose();
            } else {
              openNotification("warning", response?.data?.message);
            }
          })
          .catch((error) => {
            handleError(error);
          });
        setUploading(false);
      } catch (error) {
        openNotification("error", error);
        setUploading(false);
      }
    } else {
      try {
        await addJobType({
          user_id: getLocalStoragedata("userId"),
          job_category_id: data?.job_category_id,
          job_type_id: data?.id,
          uom_id: values.unit,
          service_level_id: values.serviceAreaLevel,
          min: values.rate,
        })
          .then((response) => {
            if (response?.data?.success) {
              openNotification("success", "Job type added successfully!");
              fetchServices();
              onClose();
            } else {
              openNotification("warning", response?.data?.message);
            }
          })
          .catch((error) => {
            handleError(error);
          });
        setUploading(false);
      } catch (error) {
        openNotification("error", error);
        setUploading(false);
      }
    }
  };

  const compareData = (initial, updated) => {
    let changes = {};
    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });
    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false);
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      {
        unit: type === 1 ? data?.uom_id : null,
        rate: data?.minserviceAreaLevel,
        serviceAreaLevel: data?.service_level_id,
      },
      allFields
    );
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteJobType({
        trady_job_type_id: data?.trady_job_type_id,
        status: 0,
      })
        .then((response) => {
          if (response?.data?.success) {
            onClose();
            fetchServices();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const fetchServices = async () => {
    try {
      await workerServiceList({
        user_id: getLocalStoragedata("userId"),
        status: 1,
        country_id: workerFormValues?.country,
      })
        .then((response) => {
          if (response?.data?.success) {
            setInitialServicesList(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        if (isFieldChanged) {
          setShowExitConfirmation(true);
        } else {
          onClose();
        }
      }}
      footer={null}
      width={1000}
      title="Add Job Type"
    >
      <div className="flex flex-col">
        {loading ? (
          <Spin />
        ) : (
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            initialValues={{
              unit: type === 1 ? data?.uom_id : null,
              rate: data?.min,
              serviceAreaLevel: data?.service_level_id,
            }}
            onFieldsChange={handleFormChange}
          >
            <div className="w-full bg-bgColorTwo rounded-md p-2 mb-4 flex flex-row gap-2">
              <Image
                src={data?.urgent_image_path}
                width={50}
                height={50}
                preview={false} // Disable preview on click, optional
              />

              <div className="flex flex-col">
                <Text className="font-medium text-base">{data?.job_type}</Text>
                <Text className="text-textColorFour text-sm">
                  {data?.job_category}
                </Text>
              </div>
            </div>
            <Text className="font-semibold text-lg">Rate</Text>
            <Form.Item
              label={
                <Text className="text-sm">
                  Unit of Measure &nbsp;<span className="text-red-500">*</span>
                </Text>
              }
              name="unit"
              rules={[
                {
                  required: true,
                  message: "Unit of Measure is required!",
                },
              ]}
            >
              <Select
                placeholder="Select your unit of measure"
                className="rounded-lg"
                dropdownStyle={{ color: "black" }} // Change dropdown text color to black
                size="large"
                options={allUOM}
              />
            </Form.Item>

            <Form.Item
              label={
                <Text className="text-sm">
                  Your Rate &nbsp;<span className="text-red-500">*</span>
                </Text>
              }
              name="rate"
              rules={[{ required: true, message: "Your Rate is required!" }]}
            >
              <Input
                placeholder="Type your rate for the job"
                className="rounded-lg"
                size="large"
              />
            </Form.Item>

            <Form.Item
              label={
                <Text className="font-semibold text-lg">
                  Service Area Level &nbsp;
                  <span className="text-red-500">*</span>
                </Text>
              }
              name="serviceAreaLevel"
              rules={[
                {
                  required: true,
                  message: "Please select Service Area Level!",
                },
              ]}
            >
              <Select
                placeholder="Select Service Area Level"
                className="rounded-lg"
                dropdownStyle={{ color: "black" }}
                size="large"
                options={allServiceLevels}
              />
            </Form.Item>

            <Form.Item>
              <div className="w-full flex flex-col sm:flex-row justify-between gap-2 mt-4">
                {type == 1 ? (
                  <Text
                    className="flex font-bold text-base text-red-600 cursor-pointer"
                    onClick={() => {
                      setShowDeleteConfirmation(true);
                    }}
                  >
                    Remove Job Type
                  </Text>
                ) : (
                  <div className="flex"></div>
                )}

                <div className="flex flex-col xs:flex-row gap-2">
                  <Button
                    onClick={onClose}
                    className="flex min-w-40 border-primaryDarkest text-primaryDarkest font-semibold rounded-md min-h-10"
                  >
                    Go Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="flex min-w-40 bg-primaryDarkest text-white font-semibold rounded-md min-h-10"
                    loading={uploading}
                    disabled={!isFieldChanged}
                  >
                    {type === 1 ? "Save Changes" : "Add Job Type"}
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>

      {showExitConfirmation && (
        <NotificationAlertBox
          open={showExitConfirmation}
          onCancel={() => {
            setShowExitConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-bold text-darkestColor text-center">
                Would you like to save before closing?
              </p>
              <p className="text-xs text-textColorThree text-center">
                You have unsaved job type details.
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  No, Discard
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    setShowExitConfirmation(false);
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          }
          title="Save Changes"
        />
      )}

      {showDeleteConfirmation && (
        <NotificationAlertBox
          open={showDeleteConfirmation}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-bold text-darkestColor text-center">
                {`Are you sure want to remove ${data?.job_type}?`}
              </p>
              <p className="text-xs text-textColorThree text-center">
                By confirming, Air Duct Cleaning will be removed from your
                service list
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                >
                  No
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    handleConfirmDelete();
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          }
          title="Remove Job Type"
        />
      )}
    </Modal>
  );
};
