import React from "react";

export const WorkerCountCircle = ({
  width,
  height,
  fontSize,
  textSize,
  taskerListCount,
}) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <p className="font-semibold text-[#2C8B52]" style={{ fontSize }}>
        Hooray!
      </p>
      <div
        className="bg-[#2C8B52] rounded-full flex justify-center items-center"
        style={{ width, height }}
      >
        <div className="flex flex-col items-center justify-center">
          <p
            className="text-textwhitecolor font-normal"
            style={{ fontSize: textSize }}
          >
            We found
          </p>
          <p
            className="text-textwhitecolor font-bold"
            style={{ fontSize: textSize * 3 }}
          >
            {taskerListCount}
          </p>
          <p
            className="text-textwhitecolor font-normal"
            style={{ fontSize: textSize }}
          >
            worker(s) for you
          </p>
        </div>
      </div>
    </div>
  );
};
