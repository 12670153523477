import React from "react";
import NoDataGray from "../../assets/svg/NoDataGray";

const NoData = ({ title, subTitle }) => {
  return (
    <div className="flex justify-center items-center mb-24">
      <div className="flex flex-col justify-center items-center gap-5 max-w-[500px]">
        <p className="text-3xl font-bold text-gray-400 text-center">{title}</p>

        <p className="text-base font-bold text-gray-400 text-center">
          {subTitle}
        </p>

        <NoDataGray />
      </div>
    </div>
  );
};

export default NoData;
