import React, { useContext, useEffect } from "react";
import { Button, Modal, Spin } from "antd";
import { useState } from "react";
import { Input } from "antd";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { Typography } from "antd";
import { AddCategoryCard } from "../AddCategoryCard";
import PublicProfileService from "../../../services/PublicProfileService";
import { NotificationContext } from "../../../context/NotificationContext";
const { Text } = Typography;

const AddCategory = ({ open, onClose, data, onComplete }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { allCategoryList } = PublicProfileService();

  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(false);
  const [rawCategoryList, setRawCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    setLoading(true);
    try {
      await allCategoryList({
        category_id: 0,
        country_id: data?.country_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setRawCategoryList(response?.data.output);
            setCategoryList(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const onSearch = (e) => {
    setSearchTerm(e.target.value);

    const searchResult = rawCategoryList.filter((item) =>
      item.job_category.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setCategoryList(searchResult);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={1000}
      title="Add Services"
    >
      <div className="flex flex-col">
        <div className="py-3">
          <Input
            value={searchTerm}
            onChange={onSearch}
            suffix={<SearchIcon className="w-6 h-6" />}
            placeholder="Search services (Ex : Cleaning)"
            className="border border-primaryDarkest px-4 py-2"
            style={{ borderRadius: 40 }}
          />
        </div>

        <div className="h-[63vh] overflow-y-auto">
          {loading ? (
            <div className="flex w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {categoryList.length > 0 ? (
                categoryList.map((item) => (
                  <AddCategoryCard
                    key={item.id}
                    data={item}
                    onComplete={onComplete}
                  />
                ))
              ) : (
                <Text>No services found</Text>
              )}
            </>
          )}
        </div>

        <div className="w-full flex p-2 justify-end">
          <Button className="rounded-xl" onClick={onClose}>
            <Text className="font-semibold">Go Back</Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCategory;
