import axios from "axios";
import HeaderConfig from "../helpers/HeaderConfig";

const LoginServices = () => {
  const { configHeader, configHeaderForOTP, baseUrl, configHeaderDiff } =
    HeaderConfig();

  //user itentification by mobile number
  const userIdentification = async (data) => {
    return await axios.post(
      baseUrl + "auth/identification/verify",
      data,
      configHeader
    );
  };

  //get otp from msg
  const getOtpByMsg = async (data, config) => {
    return await axios.post(baseUrl + "otp/generate", data, config);
  };

  const verifyByMsgOtp = async (data, config) => {
    return await axios.post(baseUrl + "otp/validate", data, config);
  };

  const resetPin = async (data) => {
    return await axios.post(baseUrl + "auth/pin/create", data, configHeader);
  };

  //get InApp OTP
  const getOtp = async (data, config) => {
    return await axios.post(baseUrl + "otp/firebase/send", data, config);
  };

  const pinValidate = async (data, config) => {
    return await axios.post(baseUrl + "auth/pin/verify", data, config);
  };

  const otpValidate = async (data) => {
    return await axios.post(
      baseUrl + "otp/firebase/validate",
      data,
      configHeaderDiff
    );
  };

  const getAllAuthUserData = async (config) => {
    return await axios.post(baseUrl + "auth/web/user", {}, config);
  };

  const userLogOut = async (data, config) => {
    return await axios.post(baseUrl + "logout", data, config);
  };

  const phoneNumberVerification = async (data) => {
    return await axios.post(baseUrl + "mobile/verify", data, configHeader);
  };

  return {
    getOtpByMsg,
    verifyByMsgOtp,
    resetPin,
    userIdentification,
    getOtp,
    otpValidate,
    getAllAuthUserData,
    userLogOut,
    pinValidate,
    phoneNumberVerification,
  };
};

export default LoginServices;
