import React from "react";

const NewUserGreenIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.834961"
        width="19.33"
        height="19.33"
        rx="9.665"
        fill="#3BB96E"
      />
      <path
        d="M8.03291 12.8024C7.10416 13.3525 6.63978 13.6275 6.29873 13.4323C5.95768 13.2372 5.96583 12.7012 5.98213 11.6291L5.98634 11.3518C5.99097 11.0471 5.99329 10.8948 5.93651 10.7609C5.87974 10.6271 5.7703 10.5268 5.55143 10.3263L5.35217 10.1438C4.58196 9.43829 4.19685 9.08553 4.28713 8.68939C4.37741 8.29325 4.88328 8.11608 5.89502 7.76174L6.15678 7.67007C6.44428 7.56938 6.58803 7.51904 6.69832 7.4181C6.8086 7.31716 6.87293 7.17706 7.00158 6.89685L7.11871 6.64175C7.57144 5.6557 7.79781 5.16267 8.19465 5.11297C8.59149 5.06327 8.89599 5.4898 9.50499 6.34288L9.66255 6.56358C9.83561 6.80599 9.92214 6.9272 10.0471 6.99868C10.172 7.07016 10.3212 7.08382 10.6196 7.11113L10.8912 7.13599C11.9412 7.2321 12.4663 7.28015 12.6212 7.64557C12.7762 8.01099 12.4585 8.45178 11.8232 9.33334L11.6588 9.56141C11.4783 9.81193 11.388 9.93718 11.3549 10.0823C11.3218 10.2274 11.3497 10.376 11.4055 10.673L11.4562 10.9435C11.6524 11.989 11.7506 12.5117 11.4495 12.7872C11.1484 13.0628 10.6476 12.9087 9.64593 12.6004L9.38678 12.5207C9.10214 12.4331 8.95981 12.3893 8.81444 12.4075C8.66907 12.4257 8.53711 12.5038 8.27319 12.6601L8.03291 12.8024Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4803 16.5081C11.3048 16.6835 11.0204 16.6835 10.8449 16.5081L9.64694 15.3101C9.4715 15.1346 9.4715 14.8502 9.64694 14.6748C9.82238 14.4993 10.1068 14.4993 10.2823 14.6748L11.4803 15.8727C11.6557 16.0482 11.6557 16.3326 11.4803 16.5081ZM14.7747 15.6096C14.5993 15.785 14.3148 15.785 14.1394 15.6096L12.6419 14.1121C12.4665 13.9367 12.4665 13.6522 12.6419 13.4768C12.8173 13.3013 13.1018 13.3013 13.2772 13.4768L14.7747 14.9743C14.9501 15.1497 14.9501 15.4341 14.7747 15.6096ZM9.38378 14.4116C9.20834 14.587 8.9239 14.587 8.74846 14.4116L8.44896 14.1121C8.27352 13.9367 8.27352 13.6522 8.44896 13.4768C8.6244 13.3013 8.90885 13.3013 9.08429 13.4768L9.38378 13.7763C9.55922 13.9517 9.55922 14.2362 9.38378 14.4116ZM15.9727 13.2136C15.7972 13.3891 15.5128 13.3891 15.3374 13.2136L15.0379 12.9141C14.8624 12.7387 14.8624 12.4542 15.0379 12.2788C15.2133 12.1034 15.4977 12.1034 15.6732 12.2788L15.9727 12.5783C16.1481 12.7537 16.1481 13.0382 15.9727 13.2136ZM14.4752 11.7161C14.2998 11.8916 14.0153 11.8916 13.8399 11.7161L12.9414 10.8177C12.766 10.6422 12.766 10.3578 12.9414 10.1823C13.1168 10.0069 13.4013 10.0069 13.5767 10.1823L14.4752 11.0808C14.6506 11.2563 14.6506 11.5407 14.4752 11.7161Z"
        fill="white"
      />
    </svg>
  );
};

export default NewUserGreenIcon;
