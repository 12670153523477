import React from "react";

const CalendarOrange = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.0059" cy="12.5107" r="12" fill="#F3A64B" />
      <path
        d="M15.2537 9.37141C15.4851 9.37141 15.7166 9.36076 15.9471 9.37141C16.3451 9.39465 16.5127 9.13124 16.5088 8.81455C16.4991 8.003 16.4991 7.19145 16.5088 6.37989C16.5088 6.09904 16.4168 5.89567 16.1446 5.79883H15.1229C15.015 5.82893 14.9204 5.89471 14.8546 5.98547C14.7888 6.07622 14.7558 6.18657 14.7607 6.29854C14.7607 7.15852 14.7607 8.01753 14.7607 8.8775C14.7637 9.18837 14.9486 9.3685 15.2537 9.37141Z"
        fill="white"
      />
      <path
        d="M7.48296 6.89514C7.48296 7.54076 7.48296 8.18639 7.48296 8.83202C7.48296 9.20003 7.65244 9.3695 8.01657 9.37047H8.68383C9.05764 9.37047 9.22518 9.20196 9.22518 8.82621C9.22518 8.00981 9.21937 7.19438 9.22518 6.37799C9.22518 6.0952 9.12834 5.89377 8.85815 5.80273H7.84031C7.56818 5.89958 7.4665 6.10392 7.48199 6.3838C7.49071 6.55424 7.48296 6.72566 7.48296 6.89514Z"
        fill="white"
      />
      <path
        d="M18.562 8.57518C18.562 8.54419 18.562 8.51417 18.562 8.48318C18.5568 8.19273 18.4469 7.91393 18.2526 7.69796C18.0584 7.48199 17.7927 7.34334 17.5045 7.30749C17.4076 7.29587 17.3979 7.32008 17.3979 7.40433C17.3979 7.89307 17.3979 8.38213 17.3979 8.87152C17.4033 9.05044 17.3725 9.2286 17.3074 9.39535C17.2423 9.5621 17.1443 9.71402 17.0192 9.84203C16.8941 9.97003 16.7444 10.0715 16.5792 10.1404C16.414 10.2092 16.2366 10.2441 16.0576 10.2428C15.7874 10.2486 15.5162 10.2496 15.2451 10.2428C14.4587 10.2235 13.8989 9.64724 13.897 8.85893C13.897 8.37471 13.897 7.89049 13.897 7.40627C13.897 7.31427 13.8747 7.29199 13.7827 7.29199C12.5993 7.29522 11.4159 7.29522 10.2324 7.29199C10.1424 7.29199 10.1162 7.31136 10.1172 7.4053C10.122 7.88952 10.1172 8.37374 10.1172 8.85796C10.1172 9.65208 9.55065 10.2283 8.76137 10.2428C8.50376 10.2428 8.24616 10.2428 7.98661 10.2428C7.18281 10.2341 6.61627 9.66177 6.61433 8.85893C6.61433 8.42216 6.61433 7.98733 6.61433 7.54863C6.61433 7.2581 6.61433 7.2581 6.33252 7.34526C5.77469 7.52248 5.45317 7.94666 5.4522 8.5345C5.4522 11.6781 5.4522 14.8219 5.4522 17.9662C5.44631 18.1631 5.48853 18.3586 5.57519 18.5356C5.81634 18.9995 6.20565 19.2135 6.72086 19.2135H17.2904C17.4609 19.2205 17.631 19.1918 17.7897 19.1292C17.9484 19.0666 18.0923 18.9716 18.2121 18.8501C18.3319 18.7286 18.4251 18.5835 18.4855 18.4239C18.5459 18.2643 18.5723 18.0939 18.563 17.9235C18.5604 14.809 18.5601 11.6929 18.562 8.57518ZM17.0125 17.3192C17.0125 17.7386 16.8188 17.9342 16.3946 17.9342H7.58762C7.1799 17.9342 6.97847 17.7328 6.97847 17.3221C6.97847 15.7462 6.97847 14.1699 6.97847 12.5932C6.97847 12.1836 7.17893 11.9851 7.59052 11.9851H16.3937C16.812 11.9851 17.0076 12.1787 17.0076 12.6029C17.0083 14.175 17.0086 15.7471 17.0086 17.3192H17.0125Z"
        fill="white"
      />
      <path
        d="M8.05506 14.1117C8.05506 14.3771 8.18289 14.5088 8.44922 14.5126C8.81044 14.5175 9.17264 14.5185 9.53484 14.5126C9.78663 14.5126 9.92222 14.3829 9.92222 14.135C9.92996 13.7637 9.92996 13.3922 9.92222 13.0203C9.92222 12.7704 9.7847 12.6455 9.53484 12.6436C9.16812 12.6436 8.80141 12.6436 8.43469 12.6436C8.18677 12.6436 8.05893 12.7762 8.05506 13.0309C8.05506 13.212 8.05506 13.3931 8.05506 13.5733C8.05506 13.7534 8.05312 13.9306 8.05506 14.1117Z"
        fill="white"
      />
      <path
        d="M14.3695 14.5107C14.7459 14.5197 15.1223 14.5197 15.4987 14.5107C15.7467 14.5049 15.8658 14.3683 15.8687 14.1165C15.8687 13.9354 15.8687 13.7543 15.8687 13.5742C15.8687 13.3941 15.8687 13.2227 15.8687 13.0464C15.8687 13.0367 15.8687 13.027 15.8687 13.0173C15.8609 12.7762 15.735 12.6455 15.4939 12.6435C15.1227 12.6397 14.7511 12.6397 14.3792 12.6435C14.1439 12.6435 14.0083 12.7675 14.0015 12.9999C13.9918 13.3805 13.9928 13.763 14.0015 14.1436C13.9971 14.1931 14.0035 14.2428 14.0204 14.2895C14.0374 14.3361 14.0643 14.3784 14.0995 14.4135C14.1346 14.4485 14.177 14.4754 14.2237 14.4922C14.2703 14.509 14.3201 14.5153 14.3695 14.5107Z"
        fill="white"
      />
      <path
        d="M12.5197 12.6426C12.3347 12.6426 12.1488 12.6426 11.9628 12.6426C11.7769 12.6426 11.5919 12.6426 11.406 12.6426C11.1619 12.6426 11.0273 12.7791 11.0244 13.03C11.0225 13.3928 11.0215 13.7573 11.0215 14.1233C11.0215 14.3635 11.1484 14.5039 11.3856 14.5107C11.7659 14.521 12.1465 14.521 12.5274 14.5107C12.7656 14.5049 12.8915 14.3664 12.8944 14.1233C12.8944 13.7524 12.8993 13.3815 12.8944 13.0106C12.8944 12.7772 12.7569 12.6465 12.5197 12.6426Z"
        fill="white"
      />
      <path
        d="M9.55239 15.6137C9.17599 15.6066 8.79959 15.6066 8.42319 15.6137C8.18495 15.6137 8.06003 15.7571 8.05615 16.0011C8.05615 16.1822 8.05615 16.3633 8.05615 16.5434C8.05615 16.7236 8.05615 16.9153 8.05615 17.1013C8.05615 17.3501 8.18786 17.4886 8.43288 17.4886C8.80476 17.4931 9.17632 17.4931 9.54755 17.4886C9.77998 17.4886 9.91653 17.3598 9.92234 17.1284C9.93138 16.7474 9.93138 16.3662 9.92234 15.9846C9.92712 15.9348 9.92084 15.8846 9.90395 15.8375C9.88706 15.7904 9.85998 15.7476 9.82465 15.7121C9.78931 15.6767 9.74658 15.6495 9.69952 15.6325C9.65246 15.6155 9.60222 15.6091 9.55239 15.6137Z"
        fill="white"
      />
      <path
        d="M12.5177 15.6137C12.1471 15.6079 11.7762 15.6079 11.405 15.6137C11.1629 15.6137 11.0273 15.758 11.0254 16.0011C11.0215 16.3672 11.0215 16.7333 11.0254 17.0993C11.0254 17.3492 11.1677 17.4867 11.4205 17.4867C11.6006 17.4867 11.7817 17.4867 11.9618 17.4867C12.142 17.4867 12.3231 17.4867 12.5032 17.4867C12.756 17.4867 12.8906 17.3501 12.8974 17.0993C12.9012 16.7333 12.9012 16.3672 12.8974 16.0011C12.8954 15.7561 12.7647 15.6176 12.5177 15.6137Z"
        fill="white"
      />
      <path
        d="M15.8688 16.0084C15.8688 15.7517 15.7381 15.621 15.4814 15.6132C15.1147 15.6081 14.748 15.6081 14.3813 15.6132C14.1401 15.6132 14.0055 15.744 13.9997 15.9832C13.991 16.3648 13.991 16.7463 13.9997 17.1269C14.0055 17.3574 14.144 17.4814 14.3774 17.4843C14.7493 17.4882 15.1208 17.4882 15.4921 17.4843C15.7303 17.4843 15.861 17.3468 15.8649 17.1085C15.8649 16.9226 15.8649 16.7366 15.8649 16.5507C15.8649 16.3648 15.8707 16.1895 15.8688 16.0084Z"
        fill="white"
      />
    </svg>
  );
};

export default CalendarOrange;
