import React from "react";

const FillX = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.831 17.5502C76.8177 13.8549 75.3439 10.3147 72.7309 7.70163C70.1179 5.08862 66.5776 3.61478 62.8823 3.60156H17.4237C13.7284 3.61478 10.1882 5.08862 7.57517 7.70163C4.96215 10.3147 3.48832 13.8549 3.4751 17.5502V63.0088C3.48832 66.7041 4.96215 70.2443 7.57517 72.8573C10.1882 75.4704 13.7284 76.9442 17.4237 76.9574H62.8823C66.5776 76.9442 70.1179 75.4704 72.7309 72.8573C75.3439 70.2443 76.8177 66.7041 76.831 63.0088V17.5502Z"
        fill="#232323"
      />
      <path
        d="M59.7203 58.8927L44.417 36.64L61.4012 16.8711H57.5126L54.176 20.7346L42.6608 34.1313L31.7227 18.2007L30.8195 16.8711H17.3475L20.634 21.6377L35.1346 42.7865L17.2974 63.6844H21.1608L36.9409 45.2952L48.6567 62.3046L49.5599 63.6342H63.0319L59.7203 58.8927ZM51.1404 60.6237L38.973 43.0624L37.2419 40.5537L23.1428 19.9318H29.2139L40.6287 36.5146L42.3598 39.0234L57.2367 60.6237H51.1404Z"
        fill="white"
      />
    </svg>
  );
};

export default FillX;
