import React, { useContext, useEffect } from "react";
import { Steps } from "antd";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";

const WorkerSignUpLayout = ({ children }) => {
  const { stepperWorkerCurrentSteps, setStepperWorkerCurrentSteps } =
    useContext(WorkerSignUpContext);

  const handleStepClick = (step) => {
    if (stepperWorkerCurrentSteps === 1) {
      setStepperWorkerCurrentSteps(step);
    }
  };

  return (
    <div className="flex w-full justify-center items-center">
      <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 rounded-lg mt-10">
        <div className="w-full flex flex-col justify-center items-center">
          <p className="text-center mb-5 text-base md:text-2xl font-bold text-primaryDarkest">
            Worker Registration
          </p>
          <div className="w-full sm:max-w-[800px] bg-gray-100 p-2 rounded-md">
            <Steps
              className="text-xs font-semibold"
              size="small"
              onChange={(e) => {
                handleStepClick(e);
              }}
              current={stepperWorkerCurrentSteps}
              direction="horizontal"
              items={[
                {
                  title: "Personal Info",
                },
                {
                  title: "OTP Verify",
                },
                {
                  title: "PIN Setup",
                },
                {
                  title: "Services",
                },
                {
                  title: "Service Area",
                },
              ]}
            />
          </div>
        </div>

        <div className="w-full flex justify-center items-center mt-10">
          {children}
        </div>
      </div>
    </div>
  );
};

export default WorkerSignUpLayout;
