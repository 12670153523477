import React from "react";

const TimeSavingIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3399 0.635872L28.329 0.601562C28.3246 0.607998 28.3194 0.613778 28.3134 0.61872L28.3321 0.634316L28.3399 0.635872Z"
        fill="#B6E3F7"
      />
      <path
        d="M55.8005 28.9516C55.9577 25.2034 55.3035 21.4654 53.8828 17.993C50.7468 10.3574 45.2727 5.09957 37.541 2.21468C34.5856 1.11028 31.4791 0.691818 28.33 0.601562L28.3414 0.637665C28.3414 3.55209 28.3458 6.46652 28.3546 9.38095L38.1469 9.399C39.1517 9.399 39.0302 9.37275 38.6493 10.2195C36.8826 14.1371 35.1055 18.0531 33.318 21.9675C33.2524 22.1119 33.2015 22.2612 33.1095 22.491H41.8887C41.4585 23.2081 41.0908 23.8284 40.7181 24.447C36.6779 31.1478 32.6361 37.8475 28.5927 44.5461C28.5204 44.6659 28.4285 44.7758 28.3529 44.8907C28.3529 44.9366 28.3349 44.9842 28.3267 45.0318C28.3267 48.5436 28.3267 52.0548 28.3267 55.5655L28.3152 55.6016C30.9882 55.5031 33.6234 55.1831 36.1963 54.4184C47.4892 51.0773 55.3654 40.8752 55.8005 28.9516Z"
        fill="#FEA200"
      />
      <path
        d="M28.3413 55.5648C28.3413 52.0542 28.3413 48.543 28.3413 45.0312L25.4762 49.6769C25.2529 48.9434 25.2792 48.223 25.2135 47.5239C24.7752 42.806 24.363 38.0865 23.9493 33.3669C23.8376 32.0869 23.8508 32.0869 22.5471 32.0869C20.5391 32.0869 18.531 32.0705 16.5246 32.0968C16.0156 32.0968 15.858 31.995 15.9532 31.4568C17.2164 24.3064 18.4675 17.1543 19.7066 10.0006C19.769 9.63959 19.8708 9.43774 20.3059 9.44267C22.9822 9.46892 25.6585 9.46072 28.3348 9.46564C28.3416 9.43645 28.3509 9.40789 28.3627 9.38031C28.3627 6.46588 28.3583 3.55145 28.3496 0.637021H28.3085C26.985 0.5939 25.6606 0.68296 24.3548 0.902869C17.6313 1.94655 11.9634 4.98077 7.54346 10.1614C1.76398 16.9339 -0.301526 24.7943 1.40112 33.4949C3.00197 41.6753 7.61898 47.8488 14.8269 52.0383C18.9793 54.4473 23.501 55.5944 28.3085 55.5747H28.3496L28.3413 55.5648Z"
        fill="#FECD00"
      />
      <path
        d="M28.3268 9.46658C25.6505 9.46658 22.9742 9.46658 20.2979 9.4436C19.8562 9.4436 19.761 9.64052 19.6986 10.0015C18.4541 17.1541 17.2029 24.3062 15.9452 31.4577C15.85 31.996 16.0076 32.1043 16.5166 32.0977C18.523 32.0715 20.531 32.0879 22.5391 32.0879C23.8427 32.0879 23.8296 32.0879 23.9413 33.3679C24.355 38.0874 24.7622 42.8069 25.2055 47.5248C25.2712 48.2239 25.2449 48.9443 25.4682 49.6778L28.3333 45.0321C28.3333 44.9845 28.3514 44.9369 28.3596 44.891C28.3596 44.5628 28.335 44.2346 28.335 43.9195C28.3317 32.4358 28.329 20.9514 28.3268 9.46658Z"
        fill="#409FCB"
      />
      <path
        d="M28.3264 9.46675C28.3264 20.9538 28.3292 32.4381 28.3346 43.9197C28.3346 44.2479 28.3511 44.5663 28.3593 44.8912C28.4397 44.7763 28.5234 44.6664 28.599 44.5466C32.6413 37.848 36.6831 31.1483 40.7244 24.4475C41.0971 23.8288 41.4649 23.2085 41.895 22.4914H33.1158C33.2078 22.2617 33.2587 22.1124 33.3244 21.9679C35.0987 18.0525 36.8736 14.1371 38.649 10.2216C39.0299 9.37321 39.1514 9.40111 38.1466 9.40111L28.3543 9.38306C28.3427 9.41011 28.3333 9.43811 28.3264 9.46675Z"
        fill="#3747D5"
      />
    </svg>
  );
};

export default TimeSavingIcon;
