import React from "react";
import { Divider, Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";

const NotificationAlertBox = ({
  open,
  onCancel,
  content,
  title,
  popupWidth,
}) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon color="#000000" />}
      footer={false}
      width={popupWidth || 400}
    >
      <div className="flex flex-row justify-start mt-3 md:mt-0">
        <p className="text-sm font-medium">{title}</p>
      </div>
      <Divider className="mt-2 mb-5 bg-gray-300" />
      {content}
    </Modal>
  );
};

export default NotificationAlertBox;
