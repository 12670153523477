import React from "react";

export const CalendarIconGreen = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.24121 10.6077C4.24121 8.09359 4.24121 6.83651 5.02226 6.05546C5.80331 5.27441 7.06039 5.27441 9.57454 5.27441H12.2412C14.7554 5.27441 16.0124 5.27441 16.7935 6.05546C17.5745 6.83651 17.5745 8.09359 17.5745 10.6077V11.9411C17.5745 14.4552 17.5745 15.7123 16.7935 16.4934C16.0124 17.2744 14.7554 17.2744 12.2412 17.2744H9.57454C7.06039 17.2744 5.80331 17.2744 5.02226 16.4934C4.24121 15.7123 4.24121 14.4552 4.24121 11.9411V10.6077Z"
        stroke="#2C8B52"
        strokeWidth="1.5"
      />
      <path
        d="M7.57471 5.27441V4.27441"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.2412 5.27441V4.27441"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.57471 8.60742H17.2414"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.908 13.9408C14.908 14.3089 14.6096 14.6074 14.2414 14.6074C13.8732 14.6074 13.5747 14.3089 13.5747 13.9408C13.5747 13.5726 13.8732 13.2741 14.2414 13.2741C14.6096 13.2741 14.908 13.5726 14.908 13.9408Z"
        fill="#2C8B52"
      />
      <path
        d="M14.908 11.2741C14.908 11.6423 14.6096 11.9408 14.2414 11.9408C13.8732 11.9408 13.5747 11.6423 13.5747 11.2741C13.5747 10.9059 13.8732 10.6074 14.2414 10.6074C14.6096 10.6074 14.908 10.9059 14.908 11.2741Z"
        fill="#2C8B52"
      />
      <path
        d="M11.5745 13.9408C11.5745 14.3089 11.2761 14.6074 10.9079 14.6074C10.5397 14.6074 10.2412 14.3089 10.2412 13.9408C10.2412 13.5726 10.5397 13.2741 10.9079 13.2741C11.2761 13.2741 11.5745 13.5726 11.5745 13.9408Z"
        fill="#2C8B52"
      />
      <path
        d="M11.5745 11.2741C11.5745 11.6423 11.2761 11.9408 10.9079 11.9408C10.5397 11.9408 10.2412 11.6423 10.2412 11.2741C10.2412 10.9059 10.5397 10.6074 10.9079 10.6074C11.2761 10.6074 11.5745 10.9059 11.5745 11.2741Z"
        fill="#2C8B52"
      />
      <path
        d="M8.24129 13.9408C8.24129 14.3089 7.94282 14.6074 7.57463 14.6074C7.20644 14.6074 6.90796 14.3089 6.90796 13.9408C6.90796 13.5726 7.20644 13.2741 7.57463 13.2741C7.94282 13.2741 8.24129 13.5726 8.24129 13.9408Z"
        fill="#2C8B52"
      />
      <path
        d="M8.24129 11.2741C8.24129 11.6423 7.94282 11.9408 7.57463 11.9408C7.20644 11.9408 6.90796 11.6423 6.90796 11.2741C6.90796 10.9059 7.20644 10.6074 7.57463 10.6074C7.94282 10.6074 8.24129 10.9059 8.24129 11.2741Z"
        fill="#2C8B52"
      />
    </svg>
  );
};
