import React from "react";

const EmailGreenIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 10.3665C3.33301 7.85238 3.33301 6.5953 4.11406 5.81425C4.89511 5.0332 6.15218 5.0332 8.66634 5.0332H11.333C13.8472 5.0332 15.1042 5.0332 15.8853 5.81425C16.6663 6.5953 16.6663 7.85238 16.6663 10.3665C16.6663 12.8807 16.6663 14.1378 15.8853 14.9188C15.1042 15.6999 13.8472 15.6999 11.333 15.6999H8.66634C6.15218 15.6999 4.89511 15.6999 4.11406 14.9188C3.33301 14.1378 3.33301 12.8807 3.33301 10.3665Z"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
      <path
        d="M6 7.70117L7.43926 8.90056C8.66369 9.92091 9.2759 10.4311 10 10.4311C10.7241 10.4311 11.3363 9.92091 12.5607 8.90056L14 7.70117"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default EmailGreenIcon;
