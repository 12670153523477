import React from "react";

export const FavouriteWorkers = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.2844 24.2423C33.9171 23.8029 33.3717 23.5169 32.7544 23.5169C32.5897 23.5169 32.4071 23.5636 32.3091 23.5869C31.3257 23.8236 24.9931 25.3523 24.9931 25.3523L20.8844 20.8423C20.5184 20.4409 20.0077 20.1836 19.4211 20.1836C18.3171 20.1836 17.4277 21.0789 17.4277 22.1836C17.4277 22.7016 17.6124 23.1696 17.9357 23.5243L22.6284 28.6969C22.6284 28.6969 22.0944 33.7683 22.0944 34.1836C22.0944 34.6343 22.1917 35.0203 22.4424 35.3543C24.1677 37.6543 27.6671 38.8503 32.7544 38.8503H33.7151L37.4077 34.1836C37.4077 34.1836 37.4077 33.6976 37.4077 33.5169C37.4077 30.8503 37.7877 28.4469 34.2844 24.2423Z"
        fill="#2C8B52"
      />
      <path
        d="M20.0884 34.8509C20.0884 34.5336 20.6184 29.3642 20.6184 29.3642L19.7264 28.3809C19.2211 28.6809 18.8664 28.8509 18.7551 28.8509C18.0837 28.8509 8.75505 22.8922 8.75505 16.9342C8.75505 13.9429 11.2424 11.5176 14.3111 11.5176C16.1311 11.5176 17.7417 12.3749 18.7551 13.6942C19.7684 12.3749 21.3791 11.5176 23.1991 11.5176C26.2684 11.5176 28.7551 13.9429 28.7551 16.9342C28.7551 19.1696 27.4424 21.4042 25.7671 23.3249C25.7671 23.3249 31.7411 21.8702 32.9011 21.5909C32.9751 21.5729 33.1884 21.5176 33.4217 21.5176C33.8604 21.5176 34.2484 21.6676 34.5764 21.9082C34.6937 21.1282 34.7551 20.3302 34.7551 19.5176C34.7551 10.6809 27.5917 3.51758 18.7551 3.51758C9.91839 3.51758 2.76172 10.6809 2.76172 19.5176C2.76172 28.3542 9.91839 35.5176 18.7551 35.5176C19.2271 35.5176 19.6937 35.4956 20.1557 35.4556C20.1031 35.2676 20.0884 35.1082 20.0884 34.8509Z"
        fill="#2C8B52"
      />
    </svg>
  );
};
