import React from "react";

const DotProgress = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.524902"
        y="0.324463"
        width="16.7529"
        height="16.7529"
        rx="8.37646"
        fill="#CDCDCD"
      />
    </svg>
  );
};

export default DotProgress;
