import React from "react";

const BusinessIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10.6699C4 13.1841 4 15.7745 4.87868 16.5555C5.75736 17.3366 7.17157 17.3366 10 17.3366C12.8284 17.3366 14.2426 17.3366 15.1213 16.5555C16 15.7745 16 13.1841 16 10.6699"
        stroke="#2C8B52"
        strokeWidth="0.9"
      />
      <path
        d="M11.7732 12.1385L15.7781 10.9371C16.1751 10.818 16.3736 10.7584 16.4995 10.6157C16.5241 10.5879 16.5462 10.5581 16.5658 10.5266C16.6663 10.3649 16.6663 10.1577 16.6663 9.74319C16.6663 8.10976 16.6663 7.29304 16.2177 6.74164C16.1315 6.63566 16.0346 6.53879 15.9286 6.45256C15.3772 6.00391 14.5605 6.00391 12.9271 6.00391H7.07229C5.43886 6.00391 4.62214 6.00391 4.07074 6.45256C3.96476 6.53879 3.86789 6.63566 3.78166 6.74164C3.33301 7.29304 3.33301 8.10976 3.33301 9.74319C3.33301 10.1577 3.33301 10.3649 3.43353 10.5266C3.45311 10.5581 3.47528 10.5879 3.49982 10.6157C3.62577 10.7584 3.82428 10.818 4.22128 10.9371L8.22613 12.1385"
        stroke="#2C8B52"
        strokeWidth="0.9"
      />
      <path
        d="M6.33398 6.00391C6.88295 5.98999 7.44015 5.64051 7.62695 5.12412C7.63269 5.10824 7.63858 5.09056 7.65036 5.05522L7.66747 5.00391C7.69559 4.91954 7.70966 4.87733 7.72471 4.83991C7.91683 4.36199 8.36706 4.03748 8.88121 4.00634C8.92148 4.00391 8.96595 4.00391 9.05489 4.00391H10.9467C11.0357 4.00391 11.0801 4.00391 11.1204 4.00634C11.6345 4.03748 12.0848 4.36199 12.2769 4.83991C12.2919 4.87733 12.306 4.91953 12.3341 5.00391L12.3512 5.05522C12.363 5.09036 12.3689 5.10828 12.3747 5.12412C12.5615 5.64051 13.1184 5.98999 13.6673 6.00391"
        stroke="#2C8B52"
        strokeWidth="0.9"
      />
      <path
        d="M11.333 11.0039H8.66634C8.48225 11.0039 8.33301 11.1531 8.33301 11.3372V12.7782C8.33301 12.9145 8.41599 13.0371 8.54254 13.0877L9.0093 13.2744C9.64506 13.5287 10.3543 13.5287 10.9901 13.2744L11.4568 13.0877C11.5834 13.0371 11.6663 12.9145 11.6663 12.7782V11.3372C11.6663 11.1531 11.5171 11.0039 11.333 11.0039Z"
        stroke="#2C8B52"
        strokeWidth="0.9"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BusinessIcon;
