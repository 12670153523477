import React, { useContext, useState } from "react";
import { Button, Input, Form, Modal, Row } from "antd";
import { Typography } from "antd";
import PublicProfileService from "../../services/PublicProfileService";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";
const { Text } = Typography;
const { TextArea } = Input;

const SkillsAndExperience = ({ open, onClose, getWorkerProfileData, data }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { updateSkillExperience } = PublicProfileService();
  const [isFieldsChanged, setIsFieldChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(data?.work_description?.length);

  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await updateSkillExperience({
        user_id: getLocalStoragedata("userId"),
        work_title: values.title.trim(),
        work_description: values.description.trim(),
      })
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            onClose();
            getWorkerProfileData();
          } else {
            openNotification("warning", response?.data?.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          handleError(error);
          setLoading(false);
        });
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const compareData = (initial, updated) => {
    let changes = {};

    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });

    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false); // Return null if no changes
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      { title: data?.work_title, description: data?.work_description },
      allFields
    );
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        if (isFieldsChanged) {
          setShowExitConfirmation(true);
        } else {
          onClose();
        }
      }}
      footer={null}
      width={1000}
      title="Skills and Experience"
      className="p-2"
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{
          title: data?.work_title,
          description: data?.work_description,
        }}
        onFieldsChange={handleFormChange}
      >
        <Row className="flex flex-col mb-4">
          <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
            Title *
          </p>
          <Form.Item
            name="title"
            className="mb-0"
            rules={[{ required: true, message: "Title is required!" }]}
          >
            <Input size="large" placeholder="Title" maxLength={50} />
          </Form.Item>
        </Row>
        <Row className="flex flex-col">
          <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
            Description *
          </p>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Description is required!" }]}
          >
            <TextArea
              placeholder="Type Here..."
              className="rounded-lg"
              autoSize={{ minRows: 8 }}
              style={{
                resize: "none",
                padding: "12px",
              }}
              size="large"
              maxLength={500}
              onChange={(e) => {
                setCount(e.target.value.length);
              }}
            />
          </Form.Item>
          <p className="flex justify-end -mt-4">{count} /500</p>
        </Row>

        <Form.Item>
          <div className="w-full flex justify-end mt-4">
            <Button
              type="primary"
              htmlType="submit"
              className="bg-primaryDark rounded-xl"
              disabled={!isFieldsChanged}
              loading={loading}
            >
              Save Changes
            </Button>
          </div>
        </Form.Item>
      </Form>

      {showExitConfirmation && (
        <NotificationAlertBox
          open={showExitConfirmation}
          onCancel={() => {
            setShowExitConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-bold text-darkestColor text-center">
                You have unsaved changes. Would you like to save before leaving?
              </p>
              <p className="text-xs text-textColorThree text-center">
                You've made changes to your skills and experiences. Save before
                leaving the page, or any unsaved changes will be lost.
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  No, Discard
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    setShowExitConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          }
          title="Are you sure?"
        />
      )}
    </Modal>
  );
};

export default SkillsAndExperience;
