import React, { useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import { useContext } from "react";
import { SubscriptionContext } from "../context/SubscriptionContext";
import { Button, ConfigProvider, Segmented, Spin } from "antd";
import SubscriptionIcon from "../assets/svg/SubscriptionIcon";
import PaymentIcon from "../assets/svg/PaymentIcon";
import PackageDetailCard from "../sections/subuscription/PackageDetailCard";
import PackageSubscription from "../sections/subuscription/PackageSubscription";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../context/NotificationContext";
import CancelSubscriptionModal from "../components/subscription/CancelSubscriptionModal";
import ChangeBillingAddres from "../sections/subuscription/ChangeBillingAddres";
import PaymentHistory from "../sections/subuscription/PaymentHistory";
import { AuthContext } from "../context/AuthContext";
import HeaderConfig from "../helpers/HeaderConfig";
import LoginServices from "../services/LoginServices";
import SubscriptionServices from "../services/SubscriptionServices";

const Subscription = () => {
  let {
    currentPackageDetails,
    setCurrentPackageDetails,

    openClosePaymentSubscripDrawer,
    setOpenClosePaymentSubscripDrawer,
    handleOpenClosePaymentSubscripDrawer,

    openCloseChangeBillingAddress,
    handleOpenCloseChangeBillingAddress,

    // setNextPackageDetails,

    setChangedBillingAddress,
  } = useContext(SubscriptionContext);
  let { openNotification, handleError } = useContext(NotificationContext);
  let { setCurrentUserData } = useContext(AuthContext);

  const { configAuth } = HeaderConfig();
  const { getAllAuthUserData } = LoginServices();
  const {
    getSubscriptionDetails,
    getSubscriptionDetailsWeb,
    getKeysByPaymentInitiating,
  } = SubscriptionServices();

  const [selectedSegment, setSelectedSegment] = useState(1);
  const [loading, setLoading] = useState(true);
  const [
    openCloseCancelSubscriptionModal,
    setOpenCloseCancelSubscriptionModal,
  ] = useState(false);

  useEffect(() => {
    handleGetSubscriptionDetails();

    if (getLocalStoragedata("token")) {
      getAuthUserData();
    }

    window.scrollTo(0, 0);

    handlePaymentInitialize();
  }, []);

  const handleGetSubscriptionDetails = async () => {
    setLoading(true);
    try {
      await getSubscriptionDetailsWeb(configAuth, {
        trady_id: getLocalStoragedata("userData")?.user_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setCurrentPackageDetails(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  const getAuthUserData = async () => {
    try {
      await getAllAuthUserData(configAuth)
        .then((response) => {
          if (response?.data?.success) {
            setCurrentUserData(response?.data?.output);
            setChangedBillingAddress({
              addressline01: response?.data?.output?.address_line1,
              addressline02: response?.data?.output?.address_line2,
              postalCode: response?.data?.output?.postal_code,
              cityorsuburb: response?.data?.output?.suberb,
              stateorprovince: response?.data?.output?.state,
              country: response?.data?.output?.country,
            });
            setLocalStorageData("userData", response?.data?.output);
            setLocalStorageData("userId", response?.data?.output?.user_id);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handlePaymentInitialize = async () => {
    try {
      await getKeysByPaymentInitiating(configAuth, {
        user_id: getLocalStoragedata("userData")?.user_id,
        country_id: getLocalStoragedata("userData")?.country_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            // setNextPackageDetails(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <div className="bg-[#EFF8F0]">
      <NavBar />
      <div className="content relative overflow-hidden">
        <div className="absolute -right-48 -bottom-32 bg-primaryLightest rounded-full w-[400px] h-[400px] md:w-[500px] md:h-[500px] lg:w-[600px] lg:h-[600px] z-0"></div>
        <div className="absolute -right-44 -bottom-11 bg-[#a7e7c1] rounded-full w-[280px] h-[280px] md:w-[400px] md:h-[400px] lg:w-[450px] lg:h-[450px] z-0"></div>

        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center mt-10 w-full">
            <Segmented
              onChange={(e) => {
                setSelectedSegment(e);
              }}
              options={[
                {
                  label: (
                    <div className="flex flex-row gap-1 justify-center items-center">
                      <SubscriptionIcon
                        color={selectedSegment === 1 ? "#3bb96e" : "#818181"}
                      />
                      <p
                        className={`text-sm font-semibold ${
                          selectedSegment === 1
                            ? "text-primaryColor"
                            : "text-textColorTwo"
                        } `}
                      >
                        Subscription
                      </p>
                    </div>
                  ),
                  value: 1,
                },
                {
                  label: (
                    <div className="flex flex-row gap-1 justify-center items-center">
                      <PaymentIcon
                        color={selectedSegment === 2 ? "#3bb96e" : "#818181"}
                      />
                      <p
                        className={`text-sm font-semibold ${
                          selectedSegment === 2
                            ? "text-primaryColor"
                            : "text-textColorTwo"
                        } `}
                      >
                        Payment History
                      </p>
                    </div>
                  ),
                  value: 2,
                },
              ]}
            />

            {selectedSegment === 1 ? (
              <div className="w-full flex flex-col justify-center items-center z-10">
                {loading ? (
                  <div className="mt-10">
                    <Spin />
                  </div>
                ) : (
                  <>
                    <PackageDetailCard
                      currentPackageDetails={currentPackageDetails}
                      handleGetSubscriptionDetails={
                        handleGetSubscriptionDetails
                      }
                    />
                  </>
                )}
              </div>
            ) : (
              <PaymentHistory />
            )}
          </div>
        </div>
      </div>
      {openClosePaymentSubscripDrawer && (
        <PackageSubscription
          open={openClosePaymentSubscripDrawer}
          onClose={handleOpenClosePaymentSubscripDrawer}
        />
      )}

      {openCloseCancelSubscriptionModal && (
        <CancelSubscriptionModal
          open={openCloseCancelSubscriptionModal}
          onCancel={() => {
            setOpenCloseCancelSubscriptionModal(false);
          }}
          handleGetSubscriptionDetails={handleGetSubscriptionDetails}
        />
      )}

      {openCloseChangeBillingAddress && (
        <ChangeBillingAddres
          open={openCloseChangeBillingAddress}
          onClose={handleOpenCloseChangeBillingAddress}
        />
      )}
    </div>
  );
};

export default Subscription;
