export function maskPhoneNumber(phoneNumber) {
  // Extract the first 3 characters (+ and country code)
  const prefix = phoneNumber?.slice(0, 3);

  // Extract the last 3 digits
  const suffix = phoneNumber?.slice(-3);

  // Replace middle characters with 'x'
  const maskedPart = phoneNumber?.slice(3, -3)?.replace(/\d/g, "x");

  // Combine all parts
  return `${prefix}${maskedPart}${suffix}`;
}
