import React, { useContext, useEffect, useState } from "react";
import { WorkerCountCircle } from "./WorkerCountCircle";
import { Button, Checkbox, Divider, Typography } from "antd";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import { EditPencilIcon } from "../../assets/svg/EditPencilIcon";
import UrgentRed from "../../assets/svg/UrgentRed";
import HomeOutline from "../../assets/svg/HomeOutline";
import DiscriptionIcon from "../../assets/svg/DiscriptionIcon";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";
import { useNavigate } from "react-router-dom";
import { CalendarIconGreen } from "../../assets/svg/CalendarIconGreen";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import CalendarOrange from "../../assets/svg/CalendarOrange";
import DoubleArrowBlack from "../../assets/svg/DoubleArrowBlack";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import CreateJobPostHelpers from "../../helpers/apiHelpers/CreateJobPostHelpers";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import PropertyIcon from "../../assets/svg/PropertyIcon";

const { Text } = Typography;

export const JobPostSummary = ({ taskerListCount }) => {
  const navigate = useNavigate();
  const {
    selectedJobType,
    selectedJobDetailsData,
    setStepperCurrentSteps,
    jobPostLoading,
  } = useContext(CreateJobPostContext);
  const { postTheTaskerJobPost } = CreateJobPostHelpers();
  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);
  const [openEditAlertBox, setOpenEditAlertBox] = useState(false);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const descriptionLimit = 250;

  const handleToggleDescription = () => {
    setShowFullDescription((prev) => !prev);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //post the job
  const handlePostTheJob = async () => {
    const data = await new FormData();
    data.append("job_category_id", 0);
    data.append("job_type_id", getLocalStoragedata("searchSelectedJobTypeId"));
    data.append("property_id", selectedJobDetailsData?.propertyData?.id);
    data.append("country_id", selectedJobDetailsData?.propertyData?.country_id);
    data.append("state_id", selectedJobDetailsData?.propertyData?.state_id);
    data.append("suburb_id", selectedJobDetailsData?.propertyData?.suburb_id);
    data.append(
      "job_time_id",
      selectedJobDetailsData?.urgency === 1
        ? 1
        : selectedJobDetailsData?.urgency === 2
        ? 3
        : 5
    );
    data.append("job_title", selectedJobType);
    data.append("job_description", selectedJobDetailsData?.description);
    // data.append("audio", null);
    // data.append("audio_ext", null);
    // data.append("audio_duration", null);
    data.append(
      "first_name",
      decryptSecureData(getLocalStoragedata("userData")?.first_name)
    );
    data.append(
      "email_address",
      decryptSecureData(getLocalStoragedata("userData")?.email)
    );
    data.append(
      "mobile_number",
      decryptSecureData(getLocalStoragedata("userData")?.mobile_number)
    );
    data.append("dial_code", getLocalStoragedata("userData")?.dial_code);
    data.append("country_code", getLocalStoragedata("userData")?.country_code);
    data.append("user_id", getLocalStoragedata("userData")?.user_id);

    data.append("is_draft", 0);
    data.append("is_call_allowed", selectedJobDetailsData?.dataSharing);
    data.append("is_web", 1);

    postTheTaskerJobPost(data);
  };

  return (
    <>
      <div className="md:flex gap-4 items-center justify-center py-4 mb-4">
        <WorkerCountCircle
          width="250px"
          height="250px"
          fontSize={50}
          textSize={20}
          taskerListCount={taskerListCount}
        />
        <div className="md:h-64 md:border-r xxsm:border-t xxsm:w-full xxsm:my-2 md:ml-2 border-grayDividerLine1"></div>
        <div className="flex flex-col gap-4 w-full">
          <Text className="text-sm text-primaryDark">Job Type :</Text>

          <Text className="text-md font-semibold text-textColorThree">
            {selectedJobType}
          </Text>

          {/* ............................................................................................. */}
          <div className="bg-white sm:w-96 xxsm:w-full rounded-xl p-5">
            <div className="flex justify-between">
              <div className="flex gap-1">
                <LocationIconCard />
                <Text className="text-sm text-primaryDark">Job Location :</Text>
              </div>
              {/* <Button
                type="default"
                className="border px-2 py-0 text-xxsm text-textColorThree border-buttonBorderZero rounded-lg bg-primaryLightest"
                onClick={() => setOpenEditAlertBox(true)}
              >
                <EditPencilIcon />
                Edit
              </Button> */}
            </div>
            <div className="pb-2 px-4">
              <div className="flex gap-1">
                <PropertyIcon width={22} height={23} />
                <Text className="text-md font-medium text-textColorThree">
                  {selectedJobDetailsData?.propertyData?.property_name}
                </Text>
              </div>
              <Text className="text-sm text-textColorThree">
                {selectedJobDetailsData?.propertyData?.address},{" "}
                {selectedJobDetailsData?.propertyData?.state},{" "}
                {selectedJobDetailsData?.propertyData?.suburb},{" "}
                {selectedJobDetailsData?.propertyData?.postal_code},{" "}
                {selectedJobDetailsData?.propertyData?.country}
              </Text>
            </div>
            <Divider className="my-2 bg-slate-400" />

            <div className="flex gap-1">
              <CalendarIconGreen />
              <Text className="text-sm text-primaryDark">Job Urgency :</Text>
            </div>
            <div className="py-2 px-4">
              {selectedJobDetailsData?.urgency === 2 ? (
                <div className="flex gap-1">
                  <CalendarOrange />
                  <Text className="text-md font-medium text-textColorThree">
                    Within few days
                  </Text>
                </div>
              ) : selectedJobDetailsData?.urgency === 3 ? (
                <div className="flex gap-1">
                  <DoubleArrowBlack />
                  <Text className="text-md font-medium text-textColorThree">
                    I’m flexible
                  </Text>
                </div>
              ) : (
                <div className="flex gap-1">
                  <UrgentRed />
                  <Text className="text-md font-medium text-textColorThree">
                    Urgent
                  </Text>
                </div>
              )}
            </div>
            <Divider className="my-2 bg-slate-400" />

            {/* description............................................................................................. */}
            <div className="flex gap-1">
              <DiscriptionIcon />
              <Text className="text-sm text-primaryDark">
                Job Description :
              </Text>
            </div>
            {/* <div className="py-2 px-4">
              <Text className="text-xs font-medium text-textColorThree">
                {selectedJobDetailsData?.description}
              </Text>
            </div> */}
            <div className="py-2 px-4">
              <Text className="text-xs font-medium text-textColorThree">
                {selectedJobDetailsData?.description.length > descriptionLimit
                  ? showFullDescription
                    ? selectedJobDetailsData?.description
                    : `${selectedJobDetailsData?.description.slice(
                        0,
                        descriptionLimit
                      )}...`
                  : selectedJobDetailsData?.description}
              </Text>
              {selectedJobDetailsData?.description.length >
                descriptionLimit && (
                <Button
                  type="link"
                  className="p-0 ml-1 text-primaryDark"
                  onClick={handleToggleDescription}
                >
                  {showFullDescription ? "See Less" : "See More"}
                </Button>
              )}
            </div>
          </div>

          {/* <div className="flex flex-row gap-1 w-full ">
            <Checkbox
              checked={selectedJobDetailsData?.dataSharing === 1 ? true : false}
              onChange={(e) => {
                setSelectedJobDetailsData({
                  ...selectedJobDetailsData,
                  dataSharing: e?.target?.checked ? 1 : 0,
                });
              }}
            />
            <Text className="text-sm text-textColorFour">
              I like to share my contact details with selected workers
            </Text>
          </div> */}
          <div className="w-full flex justify-between gap-2">
            <Button
              type="default"
              className="text-primaryDarkest border border-primaryDarkest text-sm font-medium w-full"
              onClick={() => {
                setOpenCloseNotificationAlertBox(true);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className=" bg-primaryDarkest border border-primaryDarkest text-sm font-medium  w-full"
              onClick={handlePostTheJob}
              loading={jobPostLoading}
              htmlType="submit"
            >
              Post Job
            </Button>
          </div>
        </div>
      </div>
      {openCloseNotificationAlertBox && (
        <NotificationAlertBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
          }}
          title="Cancel Job Post"
          content={
            <div>
              <p className="text-md font-medium text-darkestColor text-center">
                Are you sure you want to discard this job?
              </p>
              <p className="text-xs text-textColorThree text-center">
                All entered details will be lost.
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    navigate("../");
                  }}
                >
                  Cancel Job Post
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    setOpenCloseNotificationAlertBox(false);
                  }}
                >
                  Go Back
                </Button>
              </div>
            </div>
          }
        />
      )}

      {/* Edit Button Notification Alert Box */}
      {openEditAlertBox && (
        <NotificationAlertBox
          open={openEditAlertBox}
          onCancel={() => setOpenEditAlertBox(false)}
          title="Edit"
          content={
            <div>
              <p className="text-md font-medium text-darkestColor text-center">
                Would you like to go back and edit the
              </p>
              <p className="text-md font-medium text-darkestColor text-center">
                details before posting?
              </p>
              <p className="text-xs text-textColorThree text-center">
                You're about to make changes to your job post.
              </p>
              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    setOpenEditAlertBox(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    setStepperCurrentSteps(0);
                  }}
                >
                  Edit Job Details
                </Button>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};
