import { Button, Progress } from "antd";
import ArrowDownOutline from "../../assets/svg/ArrowDownOutline";
import { useContext, useEffect, useState } from "react";
import ArrowUp from "../../assets/svg/ArrowUp";
import ArrowLeftProgress from "../../assets/svg/ArrowLeftProgress";
import DotProgress from "../../assets/svg/DotProgress";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import RightGreenIcon from "../../assets/svg/RightGreenIcon";
import ImageCropper from "../imageCropper/ImageCropper";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import SkillsAndExperience from "./SkillsAndExperience";
import UpdateWorkSamples from "./UpdateWorkSamples";
import LanguageEditor from "./LanguageEditor";
import Verification from "./Verification";
import RequestRecomondation from "../recomondation/RequestRecomondation";

const WorkerProfileProgressBar = ({ data, getWorkerProfileData }) => {
  const { openNotification, handleError } = useContext(NotificationContext);

  const { getprofileCompletionInfo } = PublicProfileService();

  const [showDetails, setShowDetails] = useState(false);

  const [profileCompletionData, setProfileCompletionData] = useState({});

  const [isOpenImageCropper, setOpenImageCropper] = useState(null);
  const [openProfileDetailsEditor, setOpenProfileDetailsEditor] =
    useState(false);
  const [openCloseSkillsExperience, setOpenCloseSkillsExperience] =
    useState(false);
  const [editWorkSample, setEditWorkSample] = useState(false);
  const [openLanguageEditor, setOpenLanguageEditor] = useState(false);
  const [openVerification, setOpenVerification] = useState(false);
  const [openCloseRequestRecomondation, setOpenCloseRequestRecomondation] =
    useState(false);

  const handleToggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  useEffect(() => {
    if (data) {
      fetchprofileCompletionInfo();
    }
  }, []);

  const fetchprofileCompletionInfo = async () => {
    try {
      await getprofileCompletionInfo({
        user_id: data?.user_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setProfileCompletionData(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const initialCompletionDetails = [
    {
      index: 1,
      label: "Upload a profile picture",
      description:
        "A profile picture helps clients recognize and trust you at a glance. Upload yours now!",
      point: profileCompletionData?.completion_data?.is_done_point1 || 0,
      open: () => {
        setOpenImageCropper("profile");
      },
    },

    {
      index: 4,
      label: "Add your skills & experiences",
      description:
        "Highlighting your skills and experience can make you stand out to clients. Add them to your profile!",
      point: profileCompletionData?.completion_data?.is_done_point2 || 0,
      open: () => {
        setOpenCloseSkillsExperience(true);
      },
    },

    {
      index: 6,
      label: "Update language preferences",
      description:
        "Set your language preferences to connect with clients more effectively. Make updates today!",
      point: profileCompletionData?.completion_data?.is_done_point3 || 0,
      open: () => {
        setOpenLanguageEditor(true);
      },
    },
  ];

  const secondCompletionSteps = [
    {
      index: 2,
      label: "Upload a cover picture",
      description:
        "Your cover picture sets the first impression. Choose one that represents your expertise!",
      point: profileCompletionData?.completion_data?.is_done_point5 || 0,
      open: () => {
        setOpenImageCropper("cover");
      },
    },
    {
      index: 3,
      label: "Add your email in profile details",
      description:
        "Adding your email makes it easy for clients to reach out directly. Update your profile today!",
      point: profileCompletionData?.completion_data?.is_done_point4 || 0,
      open: () => {
        setOpenProfileDetailsEditor(true);
      },
    },
    {
      index: 7,
      label: "Verify Your Profile",
      description:
        "Verified profiles build trust and appear higher in searches. Complete your verification now!",
      point: profileCompletionData?.completion_data?.is_done_point6,
      open: () => {
        setOpenVerification(true);
      },
    },
  ];
  const thiredCompletionSteps = [
    {
      index: 5,
      label: "Add your work highlights",
      description:
        "Showcasing work highlights boosts your chances of getting noticed by clients. Upload now!",
      point: profileCompletionData?.completion_data?.is_done_point7 || 0,
      open: () => {
        setEditWorkSample(true);
      },
    },

    {
      index: 8,
      label: "Get at Least 3 Recommendations from Clients",
      description:
        "Recommendations make you more credible and visible. Reach out to clients for their support!",
      point: profileCompletionData?.completion_data?.is_done_point8 || 0,
      open: () => {
        setOpenCloseRequestRecomondation(true);
      },
    },
    {
      index: 9,
      label: "Get Overall 5 Recommendations from Clients",
      description:
        "Recommendations make you more credible and visible. Reach out to clients for their support!",
      point: profileCompletionData?.completion_data?.is_done_point9 || 0,
      open: () => {
        setOpenCloseRequestRecomondation(true);
      },
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-2 bg-white rounded-lg p-4 shadow-lg w-full mt-4">
        <div className="flex flex-row w-full gap-2 justify-between">
          <div className="items-start justify-start w-full">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <h1
                className={`font-bold text-primaryDarkest text-xl ${
                  profileCompletionData?.completion_rate == 100 && !showDetails
                    ? "md:text-xl"
                    : "md:text-2xl"
                } `}
              >
                Profile Completion{" "}
                {profileCompletionData?.completion_rate == 100 &&
                  !showDetails &&
                  "100%"}
              </h1>

              {profileCompletionData?.completion_rate == 100 &&
                !showDetails && (
                  <div
                    className="flex justify-end items-end text-primaryDark cursor-pointer"
                    onClick={handleToggleDetails}
                  >
                    <h1>{showDetails ? "Hide Details" : "Show Details"}</h1>
                    {showDetails ? <ArrowUp /> : <ArrowDownOutline />}
                  </div>
                )}
            </div>

            {!showDetails && profileCompletionData?.completion_rate < 100 && (
              <p>
                {profileCompletionData?.completion_rate <= 10
                  ? "You are not listed in worker search yet"
                  : "Complete your profile to increase your chances of getting hired!"}
              </p>
            )}
          </div>

          {!showDetails && profileCompletionData?.completion_rate < 100 && (
            <div className="flex items-center justify-center">
              <Progress
                className="font-bold text-primaryDarkest"
                type="circle"
                percent={profileCompletionData?.completion_rate}
                strokeColor={
                  profileCompletionData?.completion_rate < 10
                    ? { "0%": "#FF4A4A", "10%": "#FF4A4A" }
                    : profileCompletionData?.completion_rate < 40
                    ? { "0%": "#FF4A4A", "10%": "#FF4A4A" }
                    : profileCompletionData?.completion_rate < 70
                    ? { "0%": "#F3DF06", "40%": "#F3DF06" }
                    : profileCompletionData?.completion_rate < 100
                    ? { "0%": "#000000", "100%": "#3BB96E" }
                    : { "0%": "#000000", "100%": "#3BB96E" }
                }
                strokeWidth={15}
                size={130}
              />
            </div>
          )}
        </div>

        {/* steps cards....................... */}
        {showDetails && (
          <div className="flex flex-col md:flex-row mt-4 gap-6">
            <div className="flex flex-col  gap-3 w-full md:w-2/3">
              {/* not completed......... */}

              {profileCompletionData?.completion_rate < 40 ? (
                <>
                  <div className="text-start">
                    <p>To reach the next milestone (40%)</p>
                  </div>

                  {initialCompletionDetails?.map((detail, index) => (
                    <>
                      <div
                        key={index}
                        className={`flex items-center px-3 py-1 transition gap-2 ${
                          detail?.point <= 0
                            ? "hover:bg-gray-100 cursor-pointer rounded-lg"
                            : ""
                        }`}
                        onClick={(e) => {
                          e?.preventDefault();
                          detail?.open();
                        }}
                      >
                        <div className="flex-shrink-0 self-start mt-1">
                          {detail?.point > 0 ? (
                            <RightGreenIcon />
                          ) : (
                            <DotProgress />
                          )}
                        </div>

                        <div className="flex-grow text-left">
                          <p className="font-semibold text-black">
                            {detail.label}
                          </p>
                          <p className="text-textColorFour">
                            {detail.description}
                          </p>
                        </div>

                        {detail?.point <= 0 && (
                          <div className="flex-shrink-0">
                            <ArrowLeftProgress />
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </>
              ) : profileCompletionData?.completion_rate < 70 ? (
                <>
                  <div className="text-start">
                    <p>To reach the next milestone (70%)</p>
                  </div>

                  {secondCompletionSteps?.map((detail, index) => (
                    <>
                      <div
                        key={index}
                        className={`flex items-center px-3 py-1 transition gap-2 ${
                          detail?.point <= 0
                            ? "hover:bg-gray-100 cursor-pointer rounded-lg"
                            : ""
                        }`}
                        onClick={(e) => {
                          e?.preventDefault();
                          detail?.open();
                        }}
                      >
                        <div className="flex-shrink-0 self-start mt-1">
                          {detail?.point > 0 ? (
                            <RightGreenIcon />
                          ) : (
                            <DotProgress />
                          )}
                        </div>

                        <div className="flex-grow text-left">
                          <p className="font-semibold text-black">
                            {detail.label}
                          </p>
                          <p className="text-textColorFour">
                            {detail.description}
                          </p>
                        </div>

                        {detail?.point <= 0 && (
                          <div className="flex-shrink-0">
                            <ArrowLeftProgress />
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </>
              ) : profileCompletionData?.completion_rate < 100 ? (
                <>
                  <div className="text-start">
                    <p>To reach the next milestone (100%)</p>
                  </div>

                  {thiredCompletionSteps?.map((detail, index) => (
                    <>
                      <div
                        key={index}
                        className={`flex items-center px-3 py-1 transition gap-2 ${
                          detail?.point <= 0
                            ? "hover:bg-gray-100 cursor-pointer rounded-lg"
                            : ""
                        }`}
                        onClick={(e) => {
                          e?.preventDefault();
                          detail?.open();
                        }}
                      >
                        <div className="flex-shrink-0 self-start mt-1">
                          {detail?.point > 0 ? (
                            <RightGreenIcon />
                          ) : (
                            <DotProgress />
                          )}
                        </div>

                        <div className="flex-grow text-left">
                          <p className="font-semibold text-black">
                            {detail.label}
                          </p>
                          <p className="text-textColorFour">
                            {detail.description}
                          </p>
                        </div>

                        {detail?.point <= 0 && (
                          <div className="flex-shrink-0">
                            <ArrowLeftProgress />
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </>
              ) : profileCompletionData?.completion_rate == 100 ? (
                <>
                  <p className="text-xl font-semibold">
                    Excellent! You are all set and have higher ranking
                    in search listing!
                  </p>

                  <p className="text-base font-normal md:max-w-[800px]">
                    To stand out even more, gather additional recommendations
                    from clients and maximize your visibility for new
                    opportunities!
                  </p>

                  <Button
                    size="large"
                    className="border-primaryDarkest border-[1px] text-primaryDarkest font-semibold mt-5 w-fit"
                    onClick={() => {
                      setOpenCloseRequestRecomondation(true);
                    }}
                  >
                    Get More Recommendation
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="flex items-start w-full md:w-1/3 justify-center">
              <div className="">
                <Progress
                  className="font-bold text-primaryDarkest"
                  type="circle"
                  percent={profileCompletionData?.completion_rate}
                  strokeColor={
                    profileCompletionData?.completion_rate < 10
                      ? { "0%": "#FF4A4A", "10%": "#FF4A4A" }
                      : profileCompletionData?.completion_rate < 40
                      ? { "0%": "#FF4A4A", "10%": "#FF4A4A" }
                      : profileCompletionData?.completion_rate < 70
                      ? { "0%": "#F3DF06", "40%": "#F3DF06" }
                      : profileCompletionData?.completion_rate < 100
                      ? { "0%": "#000000", "100%": "#3BB96E" }
                      : { "0%": "#000000", "100%": "#3BB96E" }
                  }
                  strokeWidth={15}
                  size={200}
                  format={(percent) => `${percent}%`}
                />
                <p className="text-primaryDark text-sm font-semibold mt-1">
                  {profileCompletionData?.completion_rate <= 10
                    ? "You are not listed in worker search yet"
                    : profileCompletionData?.completion_rate <= 40
                    ? "lower priority in search listing"
                    : profileCompletionData?.completion_rate <= 70
                    ? "You have higher priority in search listing"
                    : ""}
                </p>
              </div>
            </div>
          </div>
        )}

        {profileCompletionData?.completion_rate < 100 && (
          <div
            className="flex justify-center items-center text-primaryDark cursor-pointer"
            onClick={handleToggleDetails}
          >
            <h1>{showDetails ? "Hide Details" : "Show Details"}</h1>
            {showDetails ? <ArrowUp /> : <ArrowDownOutline />}
          </div>
        )}

        {profileCompletionData?.completion_rate == 100 && showDetails && (
          <div
            className="flex justify-center items-center text-primaryDark cursor-pointer"
            onClick={handleToggleDetails}
          >
            <h1>Hide Details</h1>
            <ArrowUp />
          </div>
        )}
      </div>

      {isOpenImageCropper !== null && (
        <ImageCropper
          modelOpen={isOpenImageCropper !== null}
          handleCancel={() => {
            setOpenImageCropper(null);
          }}
          title={
            isOpenImageCropper === "profile"
              ? "Update Profile Picture"
              : "Update Cover Picture"
          }
          currentImage={
            isOpenImageCropper === "profile"
              ? data?.profile_picture
              : data?.cover_photo
          }
          rWidth={isOpenImageCropper === "profile" ? 45 : 538}
          rHeight={isOpenImageCropper === "profile" ? 58 : 135}
          type={isOpenImageCropper}
          getWorkerProfileData={getWorkerProfileData}
        />
      )}

      {openProfileDetailsEditor && (
        <UpdatePersonalInfo
          open={openProfileDetailsEditor}
          onClose={() => {
            setOpenProfileDetailsEditor(false);
          }}
          getWorkerProfileData={getWorkerProfileData}
          data={data}
        />
      )}

      {openCloseSkillsExperience && (
        <SkillsAndExperience
          open={openCloseSkillsExperience}
          onClose={() => {
            setOpenCloseSkillsExperience(false);
          }}
          getWorkerProfileData={getWorkerProfileData}
          data={data}
        />
      )}

      {editWorkSample && (
        <UpdateWorkSamples
          open={editWorkSample}
          onClose={() => {
            setEditWorkSample(false);
          }}
          getWorkerProfileData={getWorkerProfileData}
          data={data}
        />
      )}

      {openLanguageEditor && (
        <LanguageEditor
          open={openLanguageEditor}
          onClose={() => {
            setOpenLanguageEditor(false);
          }}
          getWorkerProfileData={getWorkerProfileData}
          data={data}
        />
      )}

      {openVerification && (
        <Verification
          open={openVerification}
          onClose={() => {
            setOpenVerification(false);
          }}
          getWorkerProfileData={getWorkerProfileData}
          data={data}
        />
      )}

      {openCloseRequestRecomondation && (
        <RequestRecomondation
          open={openCloseRequestRecomondation}
          onCancel={() => {
            setOpenCloseRequestRecomondation(false);
          }}
          data={data}
        />
      )}
    </>
  );
};

export default WorkerProfileProgressBar;
