import React, { useContext, useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import SubscriptionDetail from "../assets/img/SubscriptionImg.webp";
import { Button, Col, Divider, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { SubscriptionContext } from "../context/SubscriptionContext";
import Icon1 from "../assets/svg/subscription/Icon1";
import Icon2 from "../assets/svg/subscription/Icon2";
import Icon3 from "../assets/svg/subscription/Icon3";
import Icon4 from "../assets/svg/subscription/Icon4";
import Icon5 from "../assets/svg/subscription/Icon5";

const SubscriptionDetails = () => {
  let { userLocation, fetchCountryCode } = useContext(SubscriptionContext);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCountryCode();
  }, []);

  const card = [
    {
      title: "Unlimited Leads",
      icon: <Icon1 />,
    },
    {
      title: "Own Profile And QR",
      icon: <Icon2 />,
    },
    {
      title: "Unlimited Chat",
      icon: <Icon3 />,
    },
    {
      title: "Unlimited Job Types",
      icon: <Icon4 />,
    },
    {
      title: "Task Management",
      icon: <Icon5 />,
    },
  ];

  return (
    <div>
      <NavBar />
      <div className="content">
        <div className="flex justify-center items-center my-28  lg-p-5 md:p-5 p-5">
          <div className="max-w-[1073px] xl:w-[1073px] lg:w-[1073px] w-full bg-[#E0EFE2] rounded-xl lg:pr-5">
            <Row gutter={16}>
              <Col
                span={24}
                lg={{ span: 10 }}
                className="flex justify-center items-center lg:justify-start lg:items-end"
              >
                <img src={SubscriptionDetail} className="rounded-lg" />
              </Col>

              <Col
                span={24}
                lg={{ span: 14 }}
                className="flex flex-col justify-center items-center md-justify-center md:py-14"
              >
                <div className="p-5 md:p-6 lg:py-3">
                  <p className="text-3xl text-primaryDarkest font-bold pt-2 text-center">
                    JobsNinja Worker Premium
                  </p>

                  <p className="mt-3 text-center text-[16px] font-semibold">
                    Get unlimited access to job leads and allow clients to
                    contact you directly through your public profile and unique
                    QR code. Enjoy the full power of JobsNinja.
                  </p>

                  <p className="text-textColorFour text-center text-[16px] pt-2">
                    Package duration
                    <span className="text-primaryDark font-semibold ml-2">
                      30 Days
                    </span>
                  </p>

                  {["au", "nz", "sg"].includes(userLocation) && (
                    <>
                      <Divider className="bg-textColorTwo my-2" />
                      <div className="flex justify-center text-center">
                        <p className="text-[64px] font-bold text-primaryDark">
                          {userLocation === "au" && (
                            <>
                              65 <span className="text-[45px]">AUD</span>
                            </>
                          )}
                          {userLocation === "nz" && (
                            <>
                              65 <span className="text-[45px]">NZD</span>
                            </>
                          )}
                          {userLocation === "sg" && (
                            <>
                              40 <span className="text-[45px]">SGD</span>
                            </>
                          )}
                        </p>
                      </div>
                    </>
                  )}

                  <Divider className="bg-textColorTwo my-2" />
                  <p className="font-bold text-primaryDarkest text-center">
                    Discover Unlimited Power
                  </p>

                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 mt-2">
                    {card.map((item, index) => (
                      <div
                        key={index}
                        className="bg-white rounded-lg px-1 py-1 flex flex-col items-center shadow-md "
                      >
                        <div className="mb-1">{item.icon}</div>
                        <p className="text-center font-medium text-[12px]">
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>

                  {["au", "nz", "sg"].includes(userLocation) && (
                    <>
                      <Button
                        type="primary"
                        size="large"
                        className=" text-white text-sm font-semibold rounded-md mt-4 w-full bg-primaryDarkest"
                        onClick={() => {
                          navigate("../new-login", {
                            state: { from: "subscription", type: 6 },
                          });
                        }}
                      >
                        Subscribe Now
                      </Button>

                      <p className="text-xs text-center mt-2 mb-6">
                        You will be charged automatically until you cancel your
                        subscription
                      </p>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
