import React from "react";

const ReviewStar = ({ height, width }) => {
  return (
    <svg
      width={width || 23}
      height={height || 23}
      viewBox={`0 0 23 23`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3291 1.53782C10.7403 0.707483 11.9246 0.707483 12.3357 1.53782L14.9666 6.85063C15.1296 7.17991 15.4439 7.40821 15.8074 7.46153L21.6732 8.32187C22.5899 8.45633 22.9559 9.58263 22.2932 10.2303L18.0534 14.3741C17.7907 14.6309 17.6706 15.0003 17.7323 15.3626L18.7267 21.2071C18.8821 22.1205 17.924 22.8166 17.1033 22.3865L11.8521 19.6348C11.5266 19.4642 11.1382 19.4642 10.8128 19.6348L5.56156 22.3865C4.74086 22.8166 3.78277 22.1205 3.93818 21.2071L4.93258 15.3626C4.99421 15.0003 4.87418 14.6309 4.61141 14.3741L0.371597 10.2303C-0.291039 9.58263 0.0749174 8.45633 0.991673 8.32187L6.85744 7.46153C7.22098 7.40821 7.53522 7.17991 7.69827 6.85063L10.3291 1.53782Z"
        fill="#F7BA5D"
      />
    </svg>
  );
};

export default ReviewStar;
