import { Button, Checkbox, Modal, Spin, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import PublicProfileService from "../../services/PublicProfileService";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";

const { Text } = Typography;

const LanguageEditor = ({ open, onClose, getWorkerProfileData, data }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { getLanguageList, languageUpdate } = PublicProfileService();
  const [loading, setLoading] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [initialSelectedLanguages, setInitialSelectedLanguages] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  useEffect(() => {
    fetchAllLanguages();
    if (data?.languages) {
      const initialLangs = data.languages.map((lang) => lang.language_id);
      setSelectedLanguages(initialLangs);
      setInitialSelectedLanguages(initialLangs);
    }
  }, [data]);

  const fetchAllLanguages = async () => {
    setLoading(true);

    try {
      await getLanguageList({
        trady_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            setLanguageList(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const handleLanguageChange = (languageId) => {
    setSelectedLanguages((prevSelected) => {
      const isSelected = prevSelected.includes(languageId);

      if (isSelected) {
        return prevSelected.filter((id) => id !== languageId);
      } else {
        return [...prevSelected, languageId];
      }
    });
  };

  useEffect(() => {
    const isDifferent =
      selectedLanguages.length !== initialSelectedLanguages.length ||
      selectedLanguages.some(
        (langId) => !initialSelectedLanguages.includes(langId)
      );

    setIsChanged(isDifferent);
  }, [selectedLanguages, initialSelectedLanguages]);

  const handleSave = async () => {
    const dataToSave = {
      trady_id: getLocalStoragedata("userId"),
      languages: selectedLanguages,
    };

    try {
      await languageUpdate(dataToSave)
        .then((response) => {
          if (response?.data?.success) {
            openNotification(
              "success",
              "Your preferred languages have been successfully updated!"
            );
            onClose();
            getWorkerProfileData();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const sortedLanguages = languageList.sort((a, b) => {
    const isASelected = selectedLanguages.includes(a.id);
    const isBSelected = selectedLanguages.includes(b.id);

    if (isASelected && !isBSelected) return -1;
    if (!isASelected && isBSelected) return 1;
    return 0;
  });

  return (
    <Modal
      open={open}
      onCancel={() => {
        if (isChanged) {
          setShowExitConfirmation(true);
        } else {
          onClose();
        }
      }}
      footer={null}
      width={1000}
      title="Languages"
      className="p-2"
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          <Text className="flex text-xl font-bold text-primaryDarkest">
            Select all the languages you're comfortable communicating in.
          </Text>
          <p className="flex text-sm">
            This helps clients find you easily for jobs where your language
            skills are a great match.
          </p>
        </div>
        {loading ? (
          <Spin />
        ) : (
          <>
            <div className="flex flex-col bg-[#F1F1F1] p-2 rounded-md gap-1">
              <Text className="flex text-base font-semibold">
                Preferred Languages
              </Text>

              {sortedLanguages.map((language) => (
                <div
                  className="flex flex-row w-full justify-between"
                  key={language.id}
                >
                  <Text
                    className={`${
                      selectedLanguages.includes(language.id) ? "font-bold" : ""
                    }`}
                  >
                    {language?.language}
                  </Text>
                  <Checkbox
                    checked={selectedLanguages.includes(language.id)}
                    className="scale-125"
                    onChange={() => handleLanguageChange(language.id)}
                  />
                </div>
              ))}
            </div>

            <div className="flex w-full justify-end">
              <Button
                type="primary"
                htmlType="submit"
                className="text-xs font-semibold text-lightestColor bg-primaryDarkest rounded-md min-w-40"
                disabled={!isChanged} // Disable the button if no changes were made
                onClick={handleSave}
              >
                Save Changes
              </Button>
            </div>
          </>
        )}
      </div>
      {showExitConfirmation && (
        <NotificationAlertBox
          open={showExitConfirmation}
          onCancel={() => {
            setShowExitConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-medium text-darkestColor text-center">
                Are you sure you want to discard changes?
              </p>
              <p className="text-xs text-textColorThree text-center">
                Your changes will be lost.
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Yes,Exit
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    setShowExitConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          }
          title="Are you sure?"
        />
      )}
    </Modal>
  );
};

export default LanguageEditor;
