import React from "react";

const LanguageGreenOutLineIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8783 14.6184L13.3996 11.668L11.9209 14.6184"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1904 14.0938H14.6227"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4726 16.2435C14.8922 16.665 14.1805 16.9207 13.4066 16.9207C11.4719 16.9207 9.89648 15.3521 9.89648 13.4105C9.89648 11.4757 11.465 9.90039 13.4066 9.90039C15.3414 9.90039 16.9168 11.4688 16.9168 13.4105"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.96601 7.88911C10.0006 9.31943 9.30959 10.0104 7.87926 9.97586H5.17065C3.78179 10.0104 3.09082 9.31943 3.09082 7.88911V5.18051C3.09082 3.78474 3.78179 3.09375 5.17756 3.09375H7.88618C9.3165 3.09375 10.0075 3.78474 9.97292 5.18051"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.93426 5.75977H5.12891"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.52441 5.29102V5.76087"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.22955 5.75391C7.22955 6.96311 6.28291 7.94429 5.12207 7.94429"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.93454 7.94398C7.43013 7.94398 6.9741 7.6745 6.65625 7.24609"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.09082 12.084C3.09082 14.7581 5.25357 16.9208 7.92764 16.9208L7.20212 15.7116"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9101 7.93838C16.9101 5.26431 14.7473 3.10156 12.0732 3.10156L12.7988 4.31077"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LanguageGreenOutLineIcon;
