import React from "react";

const EmptyInsta = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.3652 9.79431C50.1308 8.60934 49.6646 7.50772 49.0158 6.53864L48.3146 7.00805C47.7166 6.11473 46.9473 5.34543 46.054 4.74737L46.5234 4.04623C45.5543 3.39745 44.4527 2.93124 43.2677 2.69682L43.104 3.52455C42.5875 3.42237 42.0529 3.36867 41.505 3.36867L39.6652 3.36867V2.5249L35.9858 2.5249V3.36867H32.3063V2.5249H28.6269V3.36867L24.9474 3.36867V2.5249H21.2679V3.36867H17.5885V2.5249H13.909V3.36867H12.0693C11.5214 3.36867 10.9868 3.42237 10.4703 3.52455L10.3066 2.69682C9.12159 2.93124 8.01997 3.39745 7.05089 4.04623L7.5203 4.74737C6.62698 5.34543 5.85768 6.11473 5.25962 7.00805L4.55848 6.53864C3.9097 7.50772 3.44349 8.60934 3.20907 9.79431L4.0368 9.95806C3.93462 10.4745 3.88092 11.0091 3.88092 11.557V13.3968H3.03715V17.0762H3.88092V20.7557H3.03715V24.4351H3.88092V28.1146H3.03715V31.7941H3.88092V35.4735H3.03715V39.153H3.88092V40.9927C3.88092 41.5407 3.93462 42.0752 4.0368 42.5917L3.20907 42.7554C3.44349 43.9404 3.9097 45.042 4.55848 46.0111L5.25962 45.5417C5.85768 46.435 6.62698 47.2043 7.5203 47.8024L7.0509 48.5035C8.01997 49.1523 9.12159 49.6185 10.3066 49.8529L10.4703 49.0252C10.9868 49.1274 11.5214 49.1811 12.0693 49.1811H13.909V50.0249H17.5885V49.1811H21.2679V50.0249H24.9474V49.1811H28.6269V50.0249H32.3063V49.1811H35.9858V50.0249H39.6652V49.1811H41.505C42.0529 49.1811 42.5875 49.1274 43.104 49.0252L43.2677 49.8529C44.4527 49.6185 45.5543 49.1523 46.5234 48.5035L46.054 47.8024C46.9473 47.2043 47.7166 46.435 48.3146 45.5417L49.0158 46.0111C49.6646 45.042 50.1308 43.9404 50.3652 42.7554L49.5375 42.5917C49.6396 42.0752 49.6933 41.5407 49.6933 40.9927V39.153H50.5371V35.4735H49.6933V31.7941H50.5371V28.1146H49.6933V24.4352H50.5371V20.7557H49.6933V17.0762H50.5371V13.3968H49.6933V11.557C49.6933 11.0091 49.6396 10.4745 49.5375 9.95806L50.3652 9.79431Z"
        fill="#CDCDCD"
        stroke="#939292"
        strokeWidth="1.68754"
        strokeDasharray="3.38 3.38"
      />
      <path
        d="M34.1622 20.5891C35.0684 20.5891 35.8029 19.8545 35.8029 18.9483C35.8029 18.0422 35.0684 17.3076 34.1622 17.3076C33.2561 17.3076 32.5215 18.0422 32.5215 18.9483C32.5215 19.8545 33.2561 20.5891 34.1622 20.5891Z"
        fill="#939292"
      />
      <path
        d="M26.9027 19.4155C25.5468 19.4155 24.2214 19.8176 23.0941 20.5709C21.9667 21.3241 21.0881 22.3948 20.5692 23.6474C20.0503 24.9001 19.9146 26.2785 20.1791 27.6083C20.4436 28.9381 21.0965 30.1596 22.0552 31.1183C23.014 32.077 24.2355 32.7299 25.5653 32.9945C26.8951 33.259 28.2735 33.1232 29.5261 32.6044C30.7788 32.0855 31.8494 31.2068 32.6027 30.0795C33.356 28.9521 33.758 27.6267 33.758 26.2709C33.7537 24.454 33.0301 22.7128 31.7454 21.4281C30.4607 20.1435 28.7195 19.4198 26.9027 19.4155ZM26.9027 30.6732C26.0352 30.6732 25.1872 30.416 24.4659 29.934C23.7446 29.4521 23.1824 28.767 22.8505 27.9656C22.5185 27.1641 22.4316 26.2822 22.6009 25.4314C22.7701 24.5806 23.1878 23.7991 23.8012 23.1857C24.4147 22.5722 25.1962 22.1545 26.047 21.9853C26.8978 21.816 27.7797 21.9029 28.5812 22.2349C29.3826 22.5668 30.0677 23.129 30.5496 23.8503C31.0316 24.5716 31.2888 25.4196 31.2888 26.2871C31.2803 27.4462 30.8149 28.5552 29.9937 29.3733C29.1726 30.1915 28.0618 30.6527 26.9027 30.657V30.6732Z"
        fill="#939292"
      />
      <path
        d="M32.3433 40.1957H21.2318C19.0143 40.1914 16.8889 39.3086 15.3208 37.7406C13.7528 36.1726 12.87 34.0471 12.8657 31.8296V20.7182C12.87 18.5006 13.7528 16.3752 15.3208 14.8072C16.8889 13.2391 19.0143 12.3563 21.2318 12.3521H32.3433C34.5608 12.3563 36.6863 13.2391 38.2543 14.8072C39.8223 16.3752 40.7051 18.5006 40.7094 20.7182V31.8296C40.7051 34.0471 39.8223 36.1726 38.2543 37.7406C36.6863 39.3086 34.5608 40.1914 32.3433 40.1957ZM21.2318 14.9025C20.4657 14.8938 19.7055 15.0383 18.996 15.3274C18.2865 15.6166 17.6419 16.0447 17.1001 16.5865C16.5584 17.1282 16.1303 17.7728 15.8411 18.4823C15.5519 19.1919 15.4075 19.952 15.4162 20.7182V31.8296C15.4075 32.5958 15.5519 33.3559 15.8411 34.0654C16.1303 34.775 16.5584 35.4195 17.1001 35.9613C17.6419 36.5031 18.2865 36.9312 18.996 37.2203C19.7055 37.5095 20.4657 37.654 21.2318 37.6453H32.3433C33.1094 37.654 33.8696 37.5095 34.5791 37.2203C35.2886 36.9312 35.9332 36.5031 36.475 35.9613C37.0168 35.4195 37.4448 34.775 37.734 34.0654C38.0232 33.3559 38.1677 32.5958 38.159 31.8296V20.7182C38.1677 19.952 38.0232 19.1919 37.734 18.4823C37.4448 17.7728 37.0168 17.1282 36.475 16.5865C35.9332 16.0447 35.2886 15.6166 34.5791 15.3274C33.8696 15.0383 33.1094 14.8938 32.3433 14.9025H21.2318Z"
        fill="#939292"
      />
    </svg>
  );
};

export default EmptyInsta;
