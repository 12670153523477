import React from "react";

const Facebook = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.815 0.00927734H9.32498C4.64713 0.00927734 0.85498 3.80143 0.85498 8.47928V38.9693C0.85498 43.6471 4.64713 47.4393 9.32498 47.4393H39.815C44.4928 47.4393 48.285 43.6471 48.285 38.9693V8.47928C48.285 3.80143 44.4928 0.00927734 39.815 0.00927734Z"
        fill="#0866FF"
      />
      <path
        d="M20.9553 38.0592H26.7253V23.5992H30.7253L31.1553 18.7592H26.6953V16.0092C26.6953 14.8692 26.9253 14.4092 28.0253 14.4092H31.1553V9.40918H27.1553C22.8553 9.40918 20.9253 11.2992 20.9253 14.9192V18.7792H17.9253V23.6792H20.9253L20.9553 38.0592Z"
        fill="white"
      />
    </svg>
  );
};

export default Facebook;
