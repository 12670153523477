import React, { useContext, useEffect, useState } from "react";
import { Button, Divider, Form, Input, Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";
import EmailGreenOutline from "../../assets/svg/EmailGreenOutline";
import WorkerContactDetails from "./WorkerContactDetails";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import { NotificationContext } from "../../context/NotificationContext";
import PhoneInput from "react-phone-input-2";
import ContactIcon from "../../assets/svg/ContactIcon";
import axios from "axios";

const GetEmailBox = ({ open, onCancel, workerData }) => {
  const { fetchContactDetalsForNotLoginUser, getAllCountries } =
    CreateJobPostServices();
  const { openNotification, handleError } = useContext(NotificationContext);

  // const [countryCode, setCountryCode] = useState("au");
  // const [phoneNumberFieldMinLength, setPhoneNumberFieldMinLength] =
  //   useState(10);
  // const [phoneNumberFieldMaxLength, setPhoneNumberFieldMaxLength] =
  //   useState(15);
  // const [countryList, setCountryList] = useState([]);

  const [openCloseContactDetails, setOpenCloseContactDetails] = useState(false);
  const [contactDetails, setContactDetails] = useState();

  // useEffect(() => {
  //   fetchCountryCode();
  // }, []);

  // //fetch default country by IP address
  // const fetchCountryCode = async () => {
  //   try {
  //     const { data } = await axios.get("https://ipapi.co/json/");
  //     if (data && data.country_code) {
  //       setCountryCode(data.country_code.toLowerCase());
  //       fetchCountryData(data.country_code);
  //     }
  //   } catch (error) {
  //     // handleError(error);
  //     console.log(error);
  //   }
  // };

  // const fetchCountryData = async (code) => {
  //   try {
  //     await getAllCountries({ status: 1 })
  //       .then((response) => {
  //         if (response?.data?.success) {
  //           setCountryList(response?.data.output);
  //           setPhoneNumberFieldMinLength(
  //             response?.data.output?.find((item) => item?.country_code === code)
  //               ?.number_length + 2
  //           );
  //           setPhoneNumberFieldMaxLength(
  //             response?.data.output?.find((item) => item?.country_code === code)
  //               ?.number_max_length + 2
  //           );
  //         } else {
  //           openNotification("warning", response?.data?.message);
  //         }
  //       })
  //       .catch((error) => {
  //         handleError(error);
  //       });
  //   } catch (error) {
  //     openNotification("error", error);
  //   }
  // };

  const handleGetEmail = async (e) => {
    try {
      await fetchContactDetalsForNotLoginUser({
        email_address: e?.email,
        mobile_number: null,
        // mobile_number: "+" + e?.phoneNumber,
        worker_id: workerData?.user_id,
        view_type: 2,
      })
        .then((response) => {
          if (response?.data?.success) {
            setContactDetails(response?.data?.output);
            setOpenCloseContactDetails(true);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        closeIcon={<CloseIcon color="#000000" />}
        footer={false}
        width={600}
      >
        <div>
          <p className="text-sm font-medium">Enter Email</p>

          <p className="text-base font-semibold text-primaryDark mt-3">
            Please enter your email to view this worker's contact information.
          </p>

          <Divider className="my-3 bg-slate-400" />

          <Form onFinish={handleGetEmail}>
            <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
              <EmailGreenOutline />
              Email address *
            </p>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Email is required!" },
                { type: "email", message: "Invalid email!" },
              ]}
            >
              <Input size="large" placeholder="Enter email address" />
            </Form.Item>

            {/* <div className="flex flex-row justify-start items-center text-base font-medium gap-1 mb-2">
              <ContactIcon />
              <p className="text-sm font-medium">Phone Number *</p>
            </div>
            <Form.Item
              name="phoneNumber"
              className="text-start mb-3"
              rules={[
                {
                  required: true,
                  message: "Phone number is required!",
                },
                {
                  min: phoneNumberFieldMinLength,
                  message: "Invalid phone number!",
                },
                {
                  max: phoneNumberFieldMaxLength,
                  message: "Invalid phone number!",
                },
              ]}
            >
              <PhoneInput
                country={countryCode}
                inputProps={{
                  autoFocus: true,
                }}
                onlyCountries={["au", "lk", "nz", "sg", "id"]}
                inputClass="!w-full md:!h-[45px] !rounded-lg md:!text-lg"
                searchStyle={{
                  width: "85%",
                }}
                dropdownClass="!w-[250px] sm:!w-[400px]"
                buttonClass="!rounded-l-lg"
                enableSearch
                enableAreaCodes={false}
                autoFormat={false}
                placeholder="+00 000 000 0000"
                onChange={(phoneNumber, details) => {
                  setPhoneNumberFieldMinLength(
                    countryList?.find(
                      (item) =>
                        item?.country_code?.toLowerCase() ===
                        details?.countryCode
                    )?.number_length + 2
                  );

                  setPhoneNumberFieldMaxLength(
                    countryList?.find(
                      (item) =>
                        item?.country_code?.toLowerCase() ===
                        details?.countryCode
                    )?.number_max_length + 2
                  );
                }}
              />
            </Form.Item> */}

            <div className="flex justify-center items-center mt-5">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-primaryDarkest px-20"
                  size="large"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>

      {openCloseContactDetails && (
        <WorkerContactDetails
          open={openCloseContactDetails}
          onCancel={() => {
            setOpenCloseContactDetails(false);
            onCancel();
          }}
          workerData={workerData}
          contactDetails={contactDetails}
        />
      )}
    </>
  );
};

export default GetEmailBox;
