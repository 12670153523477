import React from "react";

export const Reward = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6683 10.3652H3.33496"
        stroke="#2C8B52"
        strokeLinecap="round"
      />
      <path
        d="M10.002 3.69922V17.0326"
        stroke="#2C8B52"
        strokeLinecap="round"
      />
      <path
        d="M10.668 10.3652C10.668 11.838 11.8619 13.0319 13.3346 13.0319"
        stroke="#2C8B52"
        strokeLinecap="round"
      />
      <path
        d="M9.33464 10.3652C9.33464 11.838 8.14073 13.0319 6.66797 13.0319"
        stroke="#2C8B52"
        strokeLinecap="round"
      />
      <path
        d="M10.002 9.05698C10.002 8.06372 10.6779 7.19792 11.6415 6.95702C12.7107 6.68974 13.6791 7.65818 13.4119 8.72733C13.171 9.69093 12.3052 10.3669 11.3119 10.3669H10.002V9.05698Z"
        stroke="#2C8B52"
      />
      <path
        d="M10.0017 9.05698C10.0017 8.06372 9.3257 7.19792 8.3621 6.95702C7.29294 6.68974 6.3245 7.65818 6.59179 8.72733C6.83269 9.69093 7.69849 10.3669 8.69174 10.3669H10.0017V9.05698Z"
        stroke="#2C8B52"
      />
      <path
        d="M16.6683 10.3659C16.6683 13.5086 16.6683 15.0799 15.692 16.0562C14.7157 17.0326 13.1443 17.0326 10.0016 17.0326C6.85893 17.0326 5.28758 17.0326 4.31127 16.0562C3.33496 15.0799 3.33496 13.5086 3.33496 10.3659C3.33496 7.22319 3.33496 5.65184 4.31127 4.67553C5.28758 3.69922 6.85893 3.69922 10.0016 3.69922C13.1443 3.69922 14.7157 3.69922 15.692 4.67553C16.3411 5.32469 16.5587 6.23692 16.6316 7.69922"
        stroke="#2C8B52"
        strokeLinecap="round"
      />
    </svg>
  );
};
