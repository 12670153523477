import axios from "axios";
import HeaderConfig from "../helpers/HeaderConfig";

const PopularJobServices = () => {
  const { baseUrl, configHeader, configAuth, formconfig } = HeaderConfig();

  const getAllPopularJoblist = async () => {
    return await axios.post(baseUrl + "job/type/web", {}, configHeader);
  };

  return { getAllPopularJoblist };
};

export default PopularJobServices;
