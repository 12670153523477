import React from "react";

export const AiSearch = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.72412"
        y="4.48828"
        width="32.7273"
        height="32.7273"
        rx="16.3636"
        fill="#206CFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1819 17.0099C21.9533 17.0123 21.7507 16.8535 21.6854 16.6206L21.1085 14.5621C20.856 13.661 20.1838 12.9618 19.3306 12.7126L17.3814 12.1433C17.1609 12.0788 17.0071 11.867 17.0046 11.6241C17.002 11.3812 17.1513 11.1662 17.3704 11.0971L19.3071 10.4868C20.1548 10.2197 20.8121 9.50634 21.0456 8.60019L21.5789 6.52995C21.6392 6.29575 21.8384 6.13262 22.067 6.13021C22.2956 6.12779 22.4982 6.28668 22.5635 6.51955L23.1404 8.57808C23.3929 9.47909 24.0651 10.1784 24.9183 10.4276L26.8675 10.9969C27.088 11.0613 27.2418 11.2731 27.2443 11.516C27.2469 11.7589 27.0976 11.974 26.8785 12.043L24.9418 12.6533C24.0941 12.9205 23.4368 13.6338 23.2033 14.54L22.67 16.6102C22.6097 16.8444 22.4105 17.0075 22.1819 17.0099Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.061 19.2507C15.8261 19.2532 15.6195 19.0855 15.5599 18.8439L15.3753 18.0948C15.2076 17.4143 14.7007 16.887 14.0568 16.7232L13.348 16.5429C13.1194 16.4848 12.9572 16.2689 12.9546 16.0192C12.952 15.7696 13.1096 15.5504 13.3369 15.4874L14.0417 15.2922C14.682 15.1149 15.1776 14.5769 15.3309 13.8931L15.4997 13.1402C15.5542 12.8974 15.7571 12.7254 15.9921 12.7229C16.227 12.7204 16.4336 12.8881 16.4931 13.1297L16.6778 13.8789C16.8455 14.5593 17.3524 15.0867 17.9962 15.2504L18.7051 15.4307C18.9336 15.4889 19.0958 15.7048 19.0985 15.9544C19.1011 16.204 18.9435 16.4233 18.7162 16.4862L18.0113 16.6814C17.3711 16.8588 16.8754 17.3967 16.7221 18.0805L16.5533 18.8334C16.4989 19.0762 16.2959 19.2483 16.061 19.2507Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8315 23.8677C15.8315 21.3357 17.7372 19.2832 20.0879 19.2832C22.4386 19.2832 24.3442 21.3357 24.3442 23.8677V26.2596C24.3442 28.7915 22.4386 30.844 20.0879 30.844C17.7372 30.844 15.8315 28.7915 15.8315 26.2596V23.8677ZM14.166 24.8643C14.4726 24.8643 14.7212 25.132 14.7212 25.4623V26.2596C14.7212 29.452 17.1239 32.04 20.0879 32.04C23.0518 32.04 25.4546 29.452 25.4546 26.2596V25.4623C25.4546 25.132 25.7031 24.8643 26.0098 24.8643C26.3164 24.8643 26.5649 25.132 26.5649 25.4623V26.2596C26.5649 29.9111 23.9604 32.9074 20.6431 33.2107V35.0299C20.6431 35.3601 20.3945 35.6278 20.0879 35.6278C19.7813 35.6278 19.5327 35.3601 19.5327 35.0299V33.2107C16.2154 32.9074 13.6108 29.9111 13.6108 26.2596V25.4623C13.6108 25.132 13.8594 24.8643 14.166 24.8643Z"
        fill="white"
      />
    </svg>
  );
};
