import React from "react";

const ArrowRight = () => {
  return (
    <svg
      width="8"
      height="25"
      viewBox="0 0 8 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3024_21833)">
        <path
          d="M0.999634 17.541L6.99963 11.541L0.999634 5.54102"
          stroke="#074924"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3024_21833">
          <rect
            width="8"
            height="24"
            fill="white"
            transform="translate(-0.000366211 0.0410156)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRight;
