import React, { useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import OTPCard from "../../components/OTP/OTPCard";
import ResetPinCard from "../../components/OTP/ResetPinCard";
import PinResetSuccessCard from "../../components/OTP/PinResetSuccessCard";
import { useLocation } from "react-router-dom";

const ResetPIN = () => {
  const location = useLocation();
  const { state } = location;
  //state.type==6;worker----2;client
  //state.from==search,directSignin

  const [reSendPinSteps, setResendPinSteps] = useState("OTP_CARD");

  return (
    <div>
      <NavBar />
      <div className="flex content justify-center items-center">
        <div className="max-w-[1073px] xl:w-[1073px]">
          {reSendPinSteps === "OTP_CARD" ? (
            <OTPCard setResendPinSteps={setResendPinSteps} stateValue={state} />
          ) : reSendPinSteps === "RESET_PIN_CARD" ? (
            <ResetPinCard
              setResendPinSteps={setResendPinSteps}
              stateValue={state}
            />
          ) : reSendPinSteps === "PIN_CHANGE_SUCCESS" ? (
            <PinResetSuccessCard
              setResendPinSteps={setResendPinSteps}
              stateValue={state}
            />
          ) : (
            <OTPCard setResendPinSteps={setResendPinSteps} stateValue={state} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPIN;
