import { Button, Divider, Form, Input, Modal, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import VerificationIcon from "../../assets/svg/VerificationIcon";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import PublicProfileService from "../../services/PublicProfileService";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";

const { Text } = Typography;

const Verification = ({ open, onClose, getWorkerProfileData, data }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { requestVerification } = PublicProfileService();
  const [email, setEmail] = useState(decryptSecureData(data?.email));

  const handleVerification = async (e) => {
    try {
      await requestVerification({
        trady_id: getLocalStoragedata("userId"),
        status: 1,
        email_address: e.email,
      })
        .then((response) => {
          if (response?.data?.success) {
            onClose();
            getWorkerProfileData();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={1000}
      title="Verification Center"
      className="p-2"
    >
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col gap-2 justify-center items-center w-[80%]">
          {data?.has_verified === 5 ? (
            <>
              <VerificationIcon />
              <div className="flex flex-col justify-center items-center gap-0">
                <Text className="flex text-xl font-semibold text-red-600">
                  Unfortunately, your verification request has been rejected.
                </Text>
              </div>
              <Text className="flex text-sm text-center leading-tight">
                Do you have a Business Registration Number, certified expertise,
                or a professional license? You're eligible for a verified badge.
                Taking your business to the next level as a certified
                professional.
              </Text>
            </>
          ) : (
            <>
              <VerificationIcon />
              <div className="flex flex-col justify-center items-center gap-0">
                <Text className="flex text-xl font-semibold text-primaryDarkest">
                  Ready to earn your Verified Badge?
                </Text>
                <Text className="flex text-base font-medium text-primaryDarkest">
                  Start your verification process today!
                </Text>
              </div>
              <Text className="flex text-sm text-center leading-tight">
                Do you have a Business Registration Number, certified expertise,
                or a professional license? You're eligible for a verified badge.
                Taking your business to the next level as a certified
                professional.
              </Text>
            </>
          )}

          <Divider className="my-2 bg-[#939292]" />
          <Form
            className="flex flex-col gap-4 w-full justify-center items-center"
            onFinish={handleVerification}
            initialValues={{ email: decryptSecureData(data?.email) }}
          >
            <div className="flex flex-col w-full">
              <Text className="ml-2">
                Email <span className="text-red-500">*</span>
              </Text>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Email is required!" },
                  { type: "email", message: "Invalid email!" },
                ]}
                className="mb-0"
                maxLength={100}
              >
                <Input
                  placeholder="Email"
                  className="rounded-lg"
                  value={email}
                  size="large"
                  maxLength={100}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="flex min-w-72 h-10 text-base font-medium"
                disabled={email === null || email === ""}
              >
                Request Verification
              </Button>
            </Form.Item>
          </Form>
          <Text className="flex text-base font-semibold text-center w-[90%]">
            The JobsNinja team will contact you within 2 business days of
            receiving your request to initiate the verification process.
          </Text>
        </div>
      </div>
    </Modal>
  );
};

export default Verification;
