import React, { useContext, useEffect, useState } from "react";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import DotIcon from "../../assets/svg/DotIcon";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import { Button, Col, ConfigProvider, Input, Row, Select } from "antd";
import { NotificationContext } from "../../context/NotificationContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import PublicProfileService from "../../services/PublicProfileService";
import LanguageGreenOutLineIcon from "../../assets/svg/LanguageGreenOutLineIcon";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const SearchForWorkers = () => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const {
    selectedJobType,
    setNotLoginSteps,
    locationSearchValue,
    setLocationSearchValue,
  } = useContext(CreateJobPostContext);
  const {
    getRelatedSearchedPostalCode,
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
  } = CreateJobPostServices();
  const { getLanguageListNotAuthorize } = PublicProfileService();

  const [allCountryValues, setAllCountryValues] = useState();
  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [location1SelectorData, setLocation1SelectorData] = useState([]);
  const [location2SelectorData, setLocation2SelectorData] = useState([]);

  const [postalCodeListWithAllData, setPostalCodeListWithAllData] = useState(
    []
  );
  const [postalCodeSelectorData, setPostalCodeSelectorData] = useState([]);

  const [languageSelectorData, setLanguageSelectorData] = useState([]);

  useEffect(() => {
    fetchCountryData();
    fetchAllLanguages();

    if (locationSearchValue?.postalCodeSerchValue) {
      handleGetRelatedPostalCode(locationSearchValue?.postalCodeSerchValue);
    }

    if (
      locationSearchValue?.country &&
      locationSearchValue?.postalCodeIsRequired === 0
    ) {
      fetchLocationLevel1Data(locationSearchValue.country);
      fetchLocationLevel2Data(locationSearchValue.state);
    }
  }, []);

  const fetchCountryData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            setAllCountryValues(response?.data.output);
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const fetchLocationLevel1Data = async (data) => {
    try {
      await getAllLocationLevel1Data({ country_id: data })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel1Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel1Data = (locations1) => {
    var locationLevel1Object = [];
    locations1?.forEach((location1) => {
      let data = {
        value: location1?.level1_id,
        label: location1?.level1_location,
      };
      locationLevel1Object.push(data);
    });
    setLocation1SelectorData(locationLevel1Object);
  };

  const fetchLocationLevel2Data = async (data) => {
    try {
      await getAllLocationLevel2Data({
        country_id: locationSearchValue?.country,
        level1_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel2Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel2Data = (locations2) => {
    var locationLevel2Object = [];
    locations2?.forEach((location2) => {
      let data = {
        value: location2?.level2_id,
        label: location2?.level2_location,
      };
      locationLevel2Object.push(data);
    });
    setLocation2SelectorData(locationLevel2Object);
  };

  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: locationSearchValue?.country,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setPostalCodeListWithAllData(response?.data.output);
            setPostalCodeData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setPostalCodeData = (items) => {
    var postalCodeObject = [];
    items?.forEach((item) => {
      let data = {
        value: item?.suberb_id,
        label: item?.postal_code + ", " + item?.suberb + ", " + item?.state,
      };
      postalCodeObject.push(data);
    });
    setPostalCodeSelectorData(postalCodeObject);
  };

  //get all language list
  const fetchAllLanguages = async () => {
    try {
      await getLanguageListNotAuthorize({})
        .then((response) => {
          if (response?.data?.success) {
            setLanguageData(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLanguageData = (items) => {
    var languageObject = [];
    items?.forEach((item) => {
      let data = {
        value: item?.id,
        label: item?.language,
      };
      languageObject.push(data);
    });
    setLanguageSelectorData(languageObject);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            controlHeight: 38,
            colorBorder: "rgb(123,123,123)",
          },
          Input: {
            controlHeight: 38,
            colorBorder: "rgb(123,123,123)",
          },
        },
      }}
    >
      <div className="flex w-full justify-center items-center">
        <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 rounded-lg overflow-hidden mt-10">
          <div>
            <h1 className="text-xl md:text-[26px] font-bold text-primaryDarkest pt-5 md:pt-10 text-center">
              Search for workers
            </h1>

            <div className="pt-3 md:pt-5">
              <div className="flex flex-row justify-start items-center gap-2 pl-2">
                <DotIcon width="8" color="#40D37B" />
                <p className="text-xs md:text-sm font-normal text-textColorTwo">
                  Selected Job Type :
                </p>
                <p className="text-lg md:text-xl font-semibold text-textColorOne">
                  {selectedJobType}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5 mt-2 md:mt-5">
            {/* Property.............................................................................. */}
            <div className="p-4 rounded-lg border-[2px] pb-6 bg-white">
              <p className="flex flex-row items-center gap-1 text-base font-bold">
                <LocationIconCard />
                Select a Location <span className="text-red-500">*</span>
              </p>

              <div className="flex justify-center items-center">
                <div className="w-full max-w-[1000px]">
                  <div className="mt-2 md:mt-5 flex">
                    <Row className="w-full" gutter={8}>
                      <Col
                        span={24}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        className="pb-3 lg:pb-0"
                      >
                        <Select
                          autoFocus
                          className="w-full"
                          placeholder="Select country"
                          showSearch
                          value={locationSearchValue?.country}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={countrySelectorData}
                          onSelect={(e) => {
                            setLocationSearchValue({
                              ...locationSearchValue,
                              country: e,
                              postalCodeIsRequired: allCountryValues?.find(
                                (item) => item?.country_id === e
                              )?.postal_code_required,

                              countryName: countrySelectorData.find(
                                (item) => item?.value === e
                              )?.label,
                              state: null,
                              stateName: null,

                              suburb: null,
                              suburbName: null,

                              postalCodetype: null,
                              postalCodeSerchValue: null,
                            });

                            setLocation1SelectorData([]);
                            setLocation2SelectorData([]);

                            if (
                              allCountryValues?.find(
                                (item) => item?.country_id === e
                              )?.postal_code_required === 0
                            ) {
                              fetchLocationLevel1Data(e);
                            }
                          }}
                        />
                      </Col>

                      {locationSearchValue?.postalCodeIsRequired === 1 ? (
                        <>
                          <Col
                            span={24}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            className="pb-3 lg:pb-0"
                          >
                            <Select
                              className="w-full"
                              placeholder="Search postal code"
                              showSearch
                              value={locationSearchValue?.postalCodetype}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={postalCodeSelectorData}
                              notFoundContent={
                                postalCodeSelectorData?.length === 0
                                  ? "Please enter your property postal code"
                                  : "No Data"
                              }
                              onSearch={(e) => {
                                if (e?.length === 4) {
                                  handleGetRelatedPostalCode(e);
                                }
                                setLocationSearchValue({
                                  ...locationSearchValue,
                                  postalCodeSerchValue: e,
                                });
                              }}
                              onSelect={async (e) => {
                                const selectedLocation =
                                  await postalCodeListWithAllData?.find(
                                    (item) => item?.suberb_id === e
                                  );

                                setLocationSearchValue({
                                  ...locationSearchValue,
                                  state: selectedLocation?.state_id,
                                  stateName: selectedLocation?.state,
                                  suburb: selectedLocation?.suberb_id,
                                  postalCodetype: postalCodeSelectorData?.find(
                                    (item) => item.value === e
                                  )?.label,
                                });
                              }}
                            />
                          </Col>

                          <Col
                            span={24}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            className="pb-3 lg:pb-0"
                          >
                            <Input
                              placeholder="State"
                              disabled
                              value={locationSearchValue?.stateName}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col
                            span={24}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            className="pb-3 lg:pb-0"
                          >
                            <Select
                              className="w-full"
                              placeholder={
                                allCountryValues?.find(
                                  (item) =>
                                    item?.country_id ===
                                    locationSearchValue?.country
                                )?.level_1_label
                              }
                              showSearch
                              value={locationSearchValue?.state}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={location1SelectorData}
                              onSelect={(e) => {
                                setLocation2SelectorData([]);
                                fetchLocationLevel2Data(e);
                                setLocationSearchValue({
                                  ...locationSearchValue,
                                  state: e,
                                  stateName: location1SelectorData.find(
                                    (item) => item?.value === e
                                  )?.label,
                                  suburb: null,
                                  suburbName: null,
                                });
                              }}
                            />
                          </Col>

                          <Col
                            span={24}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            className="pb-3 lg:pb-0"
                          >
                            <Select
                              className="w-full"
                              placeholder={
                                allCountryValues?.find(
                                  (item) =>
                                    item?.country_id ===
                                    locationSearchValue?.country
                                )?.level_2_label
                              }
                              showSearch
                              value={locationSearchValue?.suburb}
                              options={location2SelectorData}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onSelect={(e) => {
                                setLocationSearchValue({
                                  ...locationSearchValue,
                                  suburb: e,
                                  suburbName: location2SelectorData?.find(
                                    (item) => item?.value === e
                                  )?.label,
                                });
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* About language................................................................................. */}
            <div className="p-4 rounded-lg border-[2px] bg-white">
              <p className="flex flex-row items-center gap-1 text-base font-bold">
                <LanguageGreenOutLineIcon />
                Preferred Language <span className="text-red-500">*</span>
              </p>

              <div className="w-full">
                <Select
                  placeholder="Select language"
                  className="w-full"
                  options={languageSelectorData}
                  value={locationSearchValue?.language}
                  onChange={(e) => {
                    setLocationSearchValue({
                      ...locationSearchValue,
                      language: e,
                    });
                  }}
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </div>

              <div className="pt-5 flex justify-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-primaryDark font-semibold px-8 flex flex-row gap-2 justify-center items-center"
                  disabled={
                    locationSearchValue?.country === null ||
                    locationSearchValue?.state === null ||
                    locationSearchValue?.suburb === null ||
                    locationSearchValue?.language === null
                  }
                  onClick={() => {
                    setNotLoginSteps(2);
                  }}
                >
                  Search <MdOutlineKeyboardArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default SearchForWorkers;
