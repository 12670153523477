import { Button, Checkbox, Form, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import PassCode from "../../assets/svg/PassCode";
import { InputOTP } from "antd-input-otp";
import BackIcon from "../../assets/svg/BackIcon";
import LoginServices from "../../services/LoginServices";
import { NotificationContext } from "../../context/NotificationContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { shaEncryption } from "../../helpers/encryptHelpers/encryption";
import HeaderConfig from "../../helpers/HeaderConfig";

const { Text } = Typography;

const ResetPinCard = ({ setResendPinSteps, stateValue }) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { resetPin } = LoginServices();

  // const location = useLocation();
  // const { state } = location;

  const { userLogOut } = LoginServices();
  const { configAuth } = HeaderConfig();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePINChange = async (e) => {
    //pin reset work with empty string therefore it prevented
    setLoading(true);
    let pinValue = null;
    let confirmPinValue = null;

    if (e?.pin === undefined || e?.confirmPin === undefined) {
      setError("PIN is required!");
      pinValue = null;
      confirmPinValue = null;
    } else if (
      e?.pin?.includes("") ||
      e?.confirmPin?.includes("") ||
      e?.pin?.length < 4 ||
      e?.confirmPin?.length < 4
    ) {
      setError("Invalid PIN!");
      pinValue = null;
      confirmPinValue = null;
    } else if (e?.pin?.join("") !== e?.confirmPin?.join("")) {
      setError("The entered PINs do not match.Re-Enter To Confirm");
      pinValue = null;
      confirmPinValue = null;
    } else {
      pinValue = e?.pin?.join("");
      confirmPinValue = e?.confirmPin?.join("");
    }

    if (pinValue !== null && confirmPinValue !== null) {
      try {
        await resetPin({
          user_id:
            getLocalStoragedata("userData")?.user_id ||
            getLocalStoragedata("userId"),
          pin: shaEncryption(pinValue),
          re_enter_pin: shaEncryption(confirmPinValue),
          is_web: 1,
          user_type_id: stateValue?.type === 2 ? 2 : 6,
          // (2 : customer 6: worker)
        })
          .then((response) => {
            if (response?.data?.success) {
              setResendPinSteps("PIN_CHANGE_SUCCESS");

              if (stateValue?.from === "myAccount") {
                logOut();
              }
            } else {
              openNotification("warning", response?.data?.message);
            }
          })
          .catch((error) => {
            handleError(error);
          });
      } catch (error) {
        openNotification("error", error);
      }
    }
    setLoading(false);
  };

  const logOut = () => {
    userLogOut({ token: getLocalStoragedata("token") }, configAuth)
      .then((response) => {
        if (response.data.success) {
          localStorage.clear();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <div
        className="flex flex-row gap-2 items-center cursor-pointer mb-5"
        onClick={() => {
          setResendPinSteps("OTP_CARD");
        }}
      >
        <BackIcon />
        <Text className="flex text-textColorTwo font-medium">Back</Text>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center shadow-lg rounded-lg w-full md:w-[500px] bg-primaryLightest p-8">
          <Text className="flex font-bold text-2xl md:text-4xl text-[#074924] text-center md:text-start leading-tight">
            Reset PIN
          </Text>
          <p>Please enter your new passcode here</p>

          <div className="flex w-full mt-4">
            <Form
              className="flex flex-col gap-2 w-full"
              onFinish={handlePINChange}
            >
              <div className="flex flex-col gap-2">
                <span className="flex flex-row gap-2">
                  <PassCode />
                  <p className="text-sm font-medium">New PIN *</p>
                </span>
                <Form.Item name="pin" className="flex items-start w-full mb-0">
                  <InputOTP
                    type="password"
                    autoFocus
                    length={4}
                    inputType="numeric"
                    inputClassName="w-full"
                    className="!md:w-[50px] !h-[50px] !max-w-60"
                    onChange={() => {
                      setError(null);
                    }}
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-2">
                <span className="flex flex-row gap-2">
                  <PassCode />
                  <p className="text-sm font-medium">Re- Enter New PIN *</p>
                </span>
                <Form.Item
                  name="confirmPin"
                  className="flex items-start w-full mb-0"
                >
                  <InputOTP
                    type="password"
                    length={4}
                    inputType="numeric"
                    inputClassName="w-full"
                    className="!md:w-[50px] !h-[50px] !max-w-60"
                    onChange={() => {
                      setError(null);
                    }}
                  />
                </Form.Item>
              </div>

              <p className="text-sm font-medium text-warringColorOne">
                {error}
              </p>

              <Form.Item>
                <Button
                  type="primary"
                  className="w-full bg-primaryDarkest text-base font-bold h-12 rounded-lg text-white mt-5"
                  loading={loading}
                  htmlType="submit"
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPinCard;
