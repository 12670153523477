import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/navbar/NavBar";
import GreenPhone from "../../assets/img/greenPhone.webp";
import YellowPhone from "../../assets/img/yellowPhone.webp";
import { Button, Form, Typography } from "antd";
import ContactIcon from "../../assets/svg/ContactIcon";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { NotificationContext } from "../../context/NotificationContext";
import LoginServices from "../../services/LoginServices";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { AuthContext } from "../../context/AuthContext";
import { setLocalStorageData } from "../../helpers/encryptHelpers/storageHelper";
import { QuickSignupContext } from "../../context/QuickSignUpContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";

const { Text } = Typography;

const NewLogin = () => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const { setFormValues, setStepperClientCurrentSteps } =
    useContext(QuickSignupContext);

  const { getAllCountries } = CreateJobPostServices();
  // let { setTryToLoginUserID } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  //state.type==6;worker----2;client
  //state.from==search,directSignin
  const [countryCode, setCountryCode] = useState("au");
  const [phoneNumberFieldMinLength, setPhoneNumberFieldMinLength] =
    useState(10);
  const [phoneNumberFieldMaxLength, setPhoneNumberFieldMaxLength] =
    useState(15);
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { userIdentification } = LoginServices();

  useEffect(() => {
    fetchCountryCode();

    //null all quick sign up form values
    setFormValues({
      name: null,
      country: null,
      postalCode: null,
      state: null,
      suburb: null,
      email: null,
      phoneNumber: null,
      dialCode: null,
      countryCode: null,
      isPostalCodeRequired: 0,
      isEmailRequired: 0,
    });

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //fetch default country by IP address
  const fetchCountryCode = async () => {
    try {
      const { data } = await axios.get("https://ipapi.co/json/");
      if (data && data.country_code) {
        setCountryCode(data.country_code.toLowerCase());
        fetchCountryData(data.country_code);
      }
    } catch (error) {
      // handleError(error);
      console.log(error);
    }
  };
  //country list
  const fetchCountryData = async (code) => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            setCountryList(response?.data.output);
            setPhoneNumberFieldMinLength(
              response?.data.output?.find((item) => item?.country_code === code)
                ?.number_length + 2
            );
            setPhoneNumberFieldMaxLength(
              response?.data.output?.find((item) => item?.country_code === code)
                ?.number_max_length + 2
            );
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      await userIdentification({
        identification_key: encryptSecureData("+" + e?.phoneNumber),
        app_type_id: state?.type, //2-client, 6-worker
        is_web: 1,
      })
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("userContactNumber", "+" + e?.phoneNumber);
            setLocalStorageData(
              "userCountryID",
              response?.data?.output?.country_id
            );
            setLocalStorageData("userId", response.data.output.user_id);
            navigate("/pin-verification", {
              state: { from: state?.from, type: state?.type },
            });
          } else {
            setErrorMessage(response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <NavBar />
      <div className="content flex justify-center items-center">
        <div className="max-w-[1073px] xl:w-[1073px] mx-2">
          <div className="flex justify-center items-center">
            <div className="flex flex-col md:flex-row justify-center shadow-lg rounded-lg w-full md:w-[800px] bg-primaryLightest my-10 p-4">
              <div className="w-full md:w-2/5 flex flex-col justify-center items-center p-4">
                <img
                  src={state?.type === 6 ? YellowPhone : GreenPhone}
                  className="w-[50%] md:w-[75%]"
                />
              </div>
              <div className="w-full md:w-3/5 flex flex-col justify-center items-centerpx-4 md:px-5">
                <Text className="flex font-bold text-2xl md:text-4xl text-[#074924] text-center md:text-start leading-tight">
                  Already Registered On JobsNinja{" "}
                  {state?.type === 6 ? "Worker" : "Client"} Mobile App Or
                  Website ?
                </Text>

                <Form className="w-full mt-2" onFinish={handleSubmit}>
                  <div className="w-full mt-5">
                    <div className="flex flex-row text-base font-medium gap-1">
                      <ContactIcon />
                      <p className="text-sm font-medium">
                        Enter your registered mobile number *
                      </p>
                    </div>

                    <Form.Item
                      name="phoneNumber"
                      className="text-start mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Phone number is required!",
                        },
                        {
                          min: phoneNumberFieldMinLength,
                          message: "Invalid phone number!",
                        },
                        {
                          max: phoneNumberFieldMaxLength,
                          message: "Invalid phone number!",
                        },
                      ]}
                    >
                      <PhoneInput
                        country={countryCode}
                        inputProps={{
                          autoFocus: true,
                        }}
                        onlyCountries={["au", "lk", "nz", "sg", "id"]}
                        inputClass="!w-full md:!h-[45px] !rounded-lg md:!text-lg"
                        searchStyle={{
                          width: "85%",
                        }}
                        dropdownClass="!w-[250px] sm:!w-[400px]"
                        buttonClass="!rounded-l-lg"
                        enableSearch
                        enableAreaCodes={false}
                        autoFormat={false}
                        placeholder="+00 000 000 0000"
                        // value={getLocalStoragedata("userContactNumber").replace(
                        //   "+",
                        //   ""
                        // )}
                        onChange={(phoneNumber, details) => {
                          setErrorMessage(null);
                          setPhoneNumberFieldMinLength(
                            countryList?.find(
                              (item) =>
                                item?.country_code?.toLowerCase() ===
                                details?.countryCode
                            )?.number_length + 2
                          );

                          setPhoneNumberFieldMaxLength(
                            countryList?.find(
                              (item) =>
                                item?.country_code?.toLowerCase() ===
                                details?.countryCode
                            )?.number_max_length + 2
                          );
                        }}
                        // onChange={(value) => {
                        //   setEnteredNotFormattedPhoneNumber(value);
                        //   setEnteredPhoneNumber("+" + value);
                        // }}
                        // value={enteredNotFormattedPhoneNumber}
                      />
                    </Form.Item>

                    <span className="text-red-600 font-medium">
                      {errorMessage}
                    </span>
                  </div>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      className="w-full mt-5 bg-primaryDarkest text-base font-semibold"
                      loading={loading}
                      htmlType="submit"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>

                <div className="flex flex-col gap-2">
                  <p className="flex flex-col md:flex-row gap-0 md:gap-2 text-[#074924] leading-tight break-keep">
                    <span className="font-bold">New to JobsNinja?</span>
                    Sign up in 2 minutes to continue.
                  </p>

                  <Button
                    size="large"
                    htmlType="submit"
                    // disabled={state?.type === 6}
                    className="w-full mt-5 text-[#074924] text-base font-semibold border-[1px] border-[#074924]"
                    onClick={() => {
                      if (state?.type === 6) {
                        navigate("../worker-sign-up", {
                          state: { from: state?.from, type: state?.type },
                        });
                      }
                      if (state?.type === 2) {
                        navigate("../quick-sign-up", {
                          state: { from: state?.from, type: state?.type },
                        });
                      }

                      setStepperClientCurrentSteps(0);
                    }}
                  >
                    Quick Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLogin;
