import React, { useMemo } from "react";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { Typography } from "antd";

const { Text } = Typography;

const UserBoxProfileImageCard = ({ data }) => {
  const initials = useMemo(() => {
    let name = data?.full_name
      ? decryptSecureData(data?.full_name)
      : "Jobs Ninja";

    if (name?.trim().split(" ").length >= 2) {
      let fl = name.split(" ")[0][0];
      let sl = name.split(" ")[1][0];
      return fl + sl;
    } else {
      let fl = name[0] || "-";
      let sl = name[1] || "-";
      return fl + sl;
    }
  });
  return (
    <div className="object-cover w-40 h-50 md:w-40 md:h-60 overflow-hidden rounded-2xl bg-[#2C8B52] border-4 border-white">
      {data?.profile_picture ? (
        <img
          src={data?.profile_picture}
          alt="cover image"
          className="w-40 h-50 md:w-40 md:h-60 object-cover"
        />
      ) : (
        <div className="flex justify-around items-center py-10 md:pt-20">
          <Text className="flex text-7xl font-bold text-white uppercase">
            {initials}
          </Text>
        </div>
      )}
    </div>
  );
};

export default UserBoxProfileImageCard;
