import React, { useContext, useEffect, useState } from "react";
import SmallI from "../../assets/svg/SmallI";
import { Button, Checkbox, Divider, Modal, Typography } from "antd";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";
import { NotificationContext } from "../../context/NotificationContext";

const { Text } = Typography;

const SearchServiceAreaList = ({ open, onCancel }) => {
  let { openNotification } = useContext(NotificationContext);

  const {
    servicesAreaSuburbList,
    selectedServicesAreaSuburbList,
    setSelectedServicesAreaSuburbList,
  } = useContext(WorkerSignUpContext);

  const [selectedArray, setSelectedArray] = useState([]);

  useEffect(() => {
    setSelectedArray(selectedServicesAreaSuburbList);
  }, []);

  // Function to toggle checkbox selection
  const handleSuburbSelect = (suburbId) => {
    let updatedSelection;

    if (selectedArray?.map((item) => item?.l1_id).includes(suburbId?.l1_id)) {
      updatedSelection = selectedArray?.filter(
        (id) => id?.l1_id !== suburbId?.l1_id
      ); // Deselect if already selected
    } else {
      if (selectedArray.length < 50) {
        updatedSelection = [...selectedArray, suburbId]; // Add if not selected and below the limit
      } else {
        openNotification(
          "warning",
          "Maximum location limit has been exceed. Try again!"
        );
        return;
      }
    }
    setSelectedArray(updatedSelection);
  };

  return (
    <Modal open={open} onCancel={onCancel} footer={false} width={700}>
      <Text className="flex text-base my-5 font-bold">Search Result</Text>

      {servicesAreaSuburbList?.length !== 0 && (
        <div className="flex flex-col rounded-md bg-[#F1F1F1] gap-2 p-2">
          <div className="flex flex-row justify-between items-center px-4">
            <div className="flex flex-row gap-1 items-center">
              <SmallI />
              <Text className="flex text-sm text-[#828282]">
                You have added {selectedArray?.length} out of 50 city(s)
              </Text>
            </div>

            {/* <div className="flex flex-row gap-2 items-center">
              <Text className="flex text-sm">Select All</Text>
              <Checkbox />
            </div> */}
          </div>

          <Divider className="bg-black my-3" />

          <div className="flex flex-col max-h-[50vh] overflow-y-scroll px-4">
            {servicesAreaSuburbList.map((suburb) => {
              const isChecked = selectedArray
                ?.map((item) => item?.l1_id)
                ?.includes(suburb.l1_id);

              return (
                <div
                  className="flex flex-row justify-between w-full cursor-pointer"
                  key={suburb.l1_id}
                  onClick={() => handleSuburbSelect(suburb)}
                >
                  <Text className="flex text-base">{suburb?.l1_name}</Text>
                  <Checkbox
                    className="flex w-4 h-4"
                    checked={isChecked}
                    // onClick={() => handleSuburbSelect(suburb)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="flex flex-row gap-5 justify-end items-center mt-5">
        <Button
          className="border-primaryDark border-[1px] text-primaryDark"
          size="large"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="large"
          className="bg-primaryDarkest"
          onClick={() => {
            setSelectedServicesAreaSuburbList(selectedArray);
            onCancel();
          }}
        >
          Add Service City(s)
        </Button>
      </div>
    </Modal>
  );
};

export default SearchServiceAreaList;
