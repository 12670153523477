import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, Divider } from "antd";
import NavBar from "../../components/navbar/NavBar";
import UserColorProfile from "../../components/userColorProfile/UserColorProfile";
import { NotificationContext } from "../../context/NotificationContext";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import DefaultProfile from "../../assets/img/noImageProfile.png";
import LockIcon from "../../assets/svg/LockIcon";
import { Reward } from "../../assets/svg/Reward";
import PersonIconGreenOutline from "../../assets/svg/PersonIconGreenOutline";
import { Link } from "react-router-dom";
import { ArrowRightGreen } from "../../assets/svg/ArrowRightGreen";
import EditIcon from "../../assets/svg/EditIcon";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import PublicProfileService from "../../services/PublicProfileService";
import LoginServices from "../../services/LoginServices";
import HeaderConfig from "../../helpers/HeaderConfig";

const MyAccountWorker = () => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const [previewUrl, setPreviewUrl] = useState();
  const [imgUploading, setImgUploading] = useState(false);
  const [imgUploadDialogOpen, setImgUploadDialogOpen] = useState(false);
  const { fileUpload, updateProfile } = PublicProfileService();
  const { getAllAuthUserData } = LoginServices();
  const { configAuth } = HeaderConfig();

  useEffect(() => {
    const userData = getLocalStoragedata("userData");
    if (userData?.profile_picture) {
      setPreviewUrl(userData?.profile_picture);
    }
  }, []);

  const imageRef = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [browseButtonState, setBrowseButtonState] = useState(false);
  const [uploadButtonState, setUploadButtonState] = useState(true);
  const [uploadButtonLoading, setUploadButtonLoading] = useState(false);

  const handleImageUploadDialogOpen = () => {
    setUploadButtonState(true);
    setImgUploadDialogOpen(true);
  };
  const handleFileChange = (event) => {
    const file = event?.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      openNotification("error", "File size exceeds the limit of 10 MB");
      return;
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      openNotification("error", "Please upload a JPEG/PNG image file.");
      return;
    }

    if (uploadButtonState) {
      setUploadButtonState(false);
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const image = imageRef?.current;
      image.src = e.target.result;
      if (cropper) {
        cropper.destroy();
      }
      const newCropper = new Cropper(image, {
        aspectRatio: 1 / 1,
      });
      setCropper(newCropper);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (cropper) {
      const imgurl = cropper?.getCroppedCanvas().toDataURL();
      const img = document?.createElement("img");
      img.src = imgurl;
      //document.getElementById("cropped_result").appendChild(img);

      cropper.getCroppedCanvas().toBlob((blob) => {
        updateProfilePicture(blob);
      });
      setUploadButtonState(true);
    }
  };

  const updateProfilePicture = async (profileImageBlob) => {
    setUploadButtonLoading(true);
    setBrowseButtonState(true);
    setImgUploading(true);

    try {
      const reader = new FileReader();
      reader.readAsDataURL(profileImageBlob);
      reader.onloadend = async () => {
        const base64data = reader.result.split(",")[1];
        const data = {
          userId:
            getLocalStoragedata("userData")?.user_id ||
            getLocalStoragedata("userId"),
          usecaseId: 2,
          file: base64data,
        };

        try {
          const response = await fileUpload(data);
          if (response?.data?.success) {
            const newImageUrl = response?.data?.output?.url;
            updateLink(newImageUrl);
            setPreviewUrl(newImageUrl);
          } else {
            openNotification("warning", response?.data?.message);
          }
        } catch (error) {
          handleError(error);
        }

        setImgUploading(false);
        setUploadButtonLoading(false);
        setBrowseButtonState(false);
        setImgUploadDialogOpen(false);
      };
    } catch (error) {
      openNotification("error", error.message);
      setImgUploading(false);
      setUploadButtonLoading(false);
      setBrowseButtonState(false);
    }
  };

  const updateLink = async (url) => {
    const data = {
      user_id:
        getLocalStoragedata("userData")?.user_id ||
        getLocalStoragedata("userId"),
      file_extension: url,
    };
    updateProfileURL(data);
  };

  const updateProfileURL = async (data) => {
    try {
      await updateProfile(data)
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            getAuthUserData();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    } catch (error) {
      openNotification("error", error);
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    }
  };

  const getAuthUserData = async () => {
    try {
      await getAllAuthUserData(configAuth)
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("userData", response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="flex flex-col w-full justify-center items-center mt-28 mb-28 px-4 md:px-10">
        <div className="max-w-[1073px] xl:w-[1073px] mx-2 w-full">
          <div>
            <p className="text-base font-semibold text-primaryDark">
              My Account
            </p>

            <div className="flex items-center justify-center ml-3 mt-4 w-24 h-[98px] rounded-full border-[1px]">
              <div className="flex w-full h-full rounded-full">
                {previewUrl == null ? (
                  <div className="flex  w-full h-full rounded-full text-white">
                    <UserColorProfile
                      name={"N Z"}
                      color={"#2c8b52"}
                      size="100px"
                      textSize="40px"
                    />
                  </div>
                ) : (
                  <img
                    src={previewUrl}
                    alt=""
                    className="flex w-24 h-[98px] rounded-full border-[2px]"
                  />
                )}

                <label
                  className="absolute cursor-pointer mt-[75px] ml-[62px]"
                  onClick={handleImageUploadDialogOpen}
                >
                  <div className=" rounded-full p-1 bg-white items-center justify-center">
                    <EditIcon className="w-[18px] h-[18px] flex" />
                  </div>
                </label>
              </div>
            </div>

            <div className="mt-10 flex flex-col gap-4">
              <Divider className="bg-gray-200 my-2" />
              <div>
                <p className="text-sm font-semibold text-darkestColor">
                  Registered phone number
                </p>
                <p className="text-base font-medium text-darkestColor">
                  {decryptSecureData(
                    getLocalStoragedata("userData")?.mobile_number ||
                      getLocalStoragedata("userContactNumber")
                  )}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-semibold text-darkestColor">
                  Edit PIN
                </p>
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-10 items-center justify-start">
                  <div className="flex flex-row gap-2 justify-start items-center">
                    <LockIcon />
                    <p className="text-base font-normal text-textColorThree max-w-96">
                      <span className="text-md font-bold">
                        Edit 4 digit pin.
                      </span>
                      <span className="text-md font-normal">
                        you can use this pin to log in to the website.
                      </span>
                    </p>
                  </div>
                  <Link
                    to={{
                      pathname: "/reset-pin",
                    }}
                    state={{ from: "myAccount", type: 6 }}
                    className="text-primaryDark text-sm font-semibold underline"
                  >
                    Change PIN
                  </Link>
                </div>
              </div>
              <Divider className="bg-gray-200 my-2" />
              <div className="flex flex-col gap-2 cursor-pointer xsm:w-full w-full sm:max-w-[550px] rounded-lg p-4 bg-gradient-to-r from-[#ecffd6] to-[#aff974]">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-md font-semibold text-darkestColor">
                      My Rewards
                    </p>
                    <div className="flex gap-2">
                      <Reward />
                      <p className="text-md font-normal text-textColorThree max-w-96">
                        View and track your rewards earned through completing
                        tasks, referrals, and other activities.
                      </p>
                    </div>
                  </div>
                  <div className="primaryDark">
                    <ArrowRightGreen />
                  </div>
                </div>
              </div>

              <Divider className="bg-gray-200 my-2" />
              <div className="flex flex-col gap-2">
                <p className="text-sm font-semibold text-darkestColor">
                  Currency Type
                </p>

                <p className="border-[1px] border-gray-400 py-2 px-6 w-fit rounded-lg text-center">
                  {getLocalStoragedata("userData")?.currency_label}
                </p>
              </div>

              <Divider className="bg-gray-200 my-2" />
              <div className="flex flex-col gap-2">
                <p className="text-sm font-semibold text-darkestColor">
                  Delete Profile
                </p>
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-10 items-center justify-start">
                  <div className="flex flex-row gap-2 justify-start items-center">
                    <PersonIconGreenOutline />
                    <p className="text-md font-normal text-textColorThree max-w-96">
                      Your account will be permanently removed from the system
                    </p>
                  </div>
                  <Link
                    className="text-textColorDelete text-sm font-semibold underline"
                    to="/delete-worker-account"
                  >
                    Delete Profile
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {imgUploadDialogOpen && (
        <Modal
          open={imgUploadDialogOpen}
          onCancel={() => {
            setImgUploadDialogOpen(false);
          }}
          footer={false}
        >
          <div className="flex flex-row gap-3 p-4 w-full justify-center">
            <div className="flex items-center justify-center border-[2px] min-h-[120px] h-[300px] w-[80%]">
              <img
                src={previewUrl != null ? previewUrl : DefaultProfile}
                className="max-w-full max-h-full"
                ref={imageRef}
                alt="Preview"
              />
            </div>
            <div className="flex flex-col gap-2 items-center justify-center w-[30%]">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
                id="file-input"
                disabled={browseButtonState}
              />
              <label
                htmlFor="file-input"
                className="cursor-pointer w-20 text-center bg-primaryBgColor  rounded-lg border-[2px]"
              >
                Browse
              </label>
              <Button
                disabled={uploadButtonState || imgUploading}
                type="primary"
                onClick={handleCrop}
                loading={uploadButtonLoading}
              >
                Upload
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyAccountWorker;
