import React, { useContext, useState } from "react";
import { Button, Card, Divider, Dropdown, Menu } from "antd";
import PropertyIcon from "../../assets/svg/PropertyIcon";
import { BsThreeDotsVertical } from "react-icons/bs";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import PersonIconGreenOutline from "../../assets/svg/PersonIconGreenOutline";
import DoneGrayIcon from "../../assets/svg/DoneGrayIcon";
import EditGrayOutlineIcon from "../../assets/svg/EditGrayOutlineIcon";
import DeleteGrayOutline from "../../assets/svg/DeleteGrayOutline";
import PropertyService from "../../services/PropertyService";
import { NotificationContext } from "../../context/NotificationContext";
import EditProperty from "./EditProperty";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";

const MyPropertyCard = ({ data, getMyProperties }) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const { deleteProperty, makeDefaultProperty } = PropertyService();

  const [openEditNewPropertyModal, setOpenEditNewPropertyModal] =
    useState(false);

  const [showDefaultConfirmation, setShowDefaultConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDefaultDeleteConfirmation, setShowDefaultDeleteConfirmation] =
    useState(false);

  const handleDelete = async () => {
    try {
      await deleteProperty({
        property_id: data.id,
        status: 0,
      })
        .then((response) => {
          if (response?.data?.success) {
            getMyProperties();
            setShowDeleteConfirmation(false);
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleMakeDefault = async () => {
    try {
      await makeDefaultProperty({
        property_id: data.id,
        user_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            getMyProperties();
            setShowDefaultConfirmation(false);
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const menu = (
    <Menu>
      {data?.is_default !== 1 && (
        <Menu.Item key="1">
          <span
            className="flex flex-row gap-1 justify-start items-center"
            onClick={() => {
              setShowDefaultConfirmation(true);
            }}
          >
            <DoneGrayIcon /> Set as default
          </span>
        </Menu.Item>
      )}

      <Menu.Item key="2">
        <span
          className="flex flex-row gap-1 justify-start items-center"
          onClick={() => {
            setOpenEditNewPropertyModal(true);
          }}
        >
          <EditGrayOutlineIcon /> Edit
        </span>
      </Menu.Item>

      <Menu.Item key="3">
        <span
          className="flex flex-row gap-1 justify-start items-center"
          onClick={() => {
            if (data?.is_default === 1) {
              setShowDefaultDeleteConfirmation(true);
            } else {
              setShowDeleteConfirmation(true);
            }
          }}
        >
          <DeleteGrayOutline /> Delete
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      className="rounded-xl bg-primaryLightest border-primaryLight border-[1px]"
      bordered={false}
    >
      <div className="flex justify-between items-center">
        <div>
          <span className="flex flex-row gap-1 justify-start items-center">
            <PropertyIcon />
            <p className="text-xl md:text-2xl font-bold truncate overflow-hidden whitespace-nowrap w-[180px] xs:w-[250px] sm:w-full">
              {data?.property_name}
            </p>
            {/* <p className="text-xl md:text-2xl font-bold">
              {data?.property_name.length > 50
                ? data?.property_name?.substring(0, 50) + "..."
                : data?.property_name}
            </p> */}
          </span>
          <p className="text-base font-normal">
            {data?.address}, {data?.state}, {data?.suburb} {data?.postal_code}
            ,&nbsp;
            {data?.country}
          </p>
        </div>

        <div className="flex flex-row gap-2">
          {data?.is_default === 1 && (
            <div className="bg-[#2C8B52] rounded-lg text-xs px-2 text-white">
              Default
            </div>
          )}
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <BsThreeDotsVertical
              className="cursor-pointer text-xl"
              onClick={(e) => e.preventDefault()}
            />
          </Dropdown>
        </div>
      </div>

      <Divider className="bg-primaryColor my-3" />

      <div className="flex flex-col sm:flex-row sm:space-x-4 text-sm mt-2">
        <p className="flex flex-row gap-1 justify-start items-center">
          <LocationIconCard /> Property Type:
          <span className="font-semibold">{data?.property_type}</span>
        </p>

        <div className="border-l-[2px] h-6 border-primaryColor hidden sm:block"></div>

        <p className="flex flex-row gap-1 justify-start items-center">
          <PersonIconGreenOutline /> Role:
          <span className="font-semibold">{data?.property_role}</span>
        </p>
      </div>

      {openEditNewPropertyModal && (
        <EditProperty
          open={openEditNewPropertyModal}
          onCancel={() => {
            setOpenEditNewPropertyModal(false);
          }}
          getMyProperties={getMyProperties}
          propertyData={data}
        />
      )}

      {showDefaultConfirmation && (
        <NotificationAlertBox
          open={showDefaultConfirmation}
          onCancel={() => setShowDefaultConfirmation(false)}
          title="Set As Default"
          content={
            <div>
              <p className="text-base font-medium text-darkestColor text-center">
                Are you sure you want to set this property as your default?
              </p>
              <p className="text-xs text-textColorThree text-center">
                It will be automatically selected for future job postings.
              </p>
              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  size="large"
                  type="primary"
                  className="text-primaryDarkest bg-white border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    setShowDefaultConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="default"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full text-white"
                  onClick={handleMakeDefault}
                >
                  Set As Default
                </Button>
              </div>
            </div>
          }
        />
      )}

      {showDeleteConfirmation && (
        <NotificationAlertBox
          open={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          title="Remove Property"
          content={
            <div>
              <p className="text-base font-medium text-darkestColor text-center">
                Are you sure you want to remove this property?
              </p>
              <p className="text-xs text-textColorThree text-center">
                This action cannot be undone
              </p>
              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  size="large"
                  type="default"
                  className="text-red-600 border border-red-600 text-sm font-semibold w-full"
                  onClick={handleDelete}
                >
                  Remove Property
                </Button>
                <Button
                  size="large"
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                  }}
                >
                  No, Keep It
                </Button>
              </div>
            </div>
          }
        />
      )}
      {showDefaultDeleteConfirmation && (
        <NotificationAlertBox
          open={showDefaultDeleteConfirmation}
          onCancel={() => setShowDefaultDeleteConfirmation(false)}
          title="Remove Default Property"
          content={
            <div>
              <p className="text-base font-medium text-darkestColor text-center">
                Default Property Remove Failed!
              </p>
              <p className="text-xs text-textColorThree text-center">
                Before removing the already default property, set another
                property as default. otherwise cannot remove this property
              </p>
              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  size="large"
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    setShowDefaultDeleteConfirmation(false);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          }
        />
      )}
    </Card>
  );
};

export default MyPropertyCard;
