import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Row,
  Col,
  ConfigProvider,
  Divider,
} from "antd";
import "tailwindcss/tailwind.css";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import PersonIconGreenOutline from "../../assets/svg/PersonIconGreenOutline";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import { NotificationContext } from "../../context/NotificationContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import PropertyService from "../../services/PropertyService";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";

const EditPropertySignUp = ({
  open,
  onCancel,
  getMyProperties,
  propertyData,
}) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const {
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
    getRelatedSearchedPostalCode,
  } = CreateJobPostServices();
  const { fetchPropertyMetaData, updateProperty, deleteProperty } =
    PropertyService();

  const [countryList, setCountryList] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    propertyData.country_id
  );
  const [postalCodeRequired, setPostalCodeRequired] = useState();
  const [local1SelectData, setLocation1SelectorData] = useState([]);
  const [local2SelectData, setLocation2SelectorData] = useState([]);
  const [postalCodeData, setPostalCodeData] = useState(null);
  const [allPostalCodes, setAllPostalCodes] = useState([]);

  const [suburbId, setSuburbId] = useState(propertyData.suburb_id);
  const [stateId, setStateId] = useState(propertyData.state_id);

  const [editedDataObject, setEditedDataObject] = useState(null);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const [labels, setLabels] = useState({
    level_1_label: "State",
    level_2_label: "Suburb",
  });

  const [isStateSelected, setIsStateSelected] = useState(true);
  const [isFieldChanged, setIsFieldChanged] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchMetaData();
    fetchCountryData();
  }, []);

  useEffect(() => {
    // Check if editing existing property
    if (propertyData) {
      form.setFieldsValue({
        propertyName: propertyData.property_name,
        propertyType: propertyData.property_type_id,
        role: propertyData.property_role_id,
        country: propertyData.country_id,
        stateProvince: propertyData.state_id,
        citySuburb: propertyData.suburb_id,
        address: propertyData.address,
        zip: propertyData.suburb_id,
        defaultProperty: propertyData.is_default,
      });

      // Fetch relevant data based on pre-selected values (if needed)
      fetchLocationLevel1Data(propertyData.country_id);
      fetchLocationLevel2Data(propertyData.state_id);
    }
  }, [propertyData]);

  const fetchMetaData = async () => {
    try {
      await fetchPropertyMetaData({ user_id: getLocalStoragedata("userId") })
        .then((response) => {
          if (response?.data?.success) {
            var roleObject = [];
            var typeObject = [];
            response?.data?.output.property_role.forEach((role) => {
              let data = {
                value: role?.role_id,
                label: role?.role,
              };
              roleObject.push(data);
            });

            response?.data?.output.property_type.forEach((type) => {
              let data = {
                value: type?.type_id,
                label: type?.type,
              };
              typeObject.push(data);
            });

            setRoles(roleObject);
            setTypes(typeObject);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchCountryData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            setCountryList(response?.data?.output);
            var countryObject = [];
            response?.data?.output.forEach((country) => {
              let data = {
                value: country?.country_id,
                label: country?.country,
              };
              countryObject.push(data);
            });

            let selectedCountry = response?.data?.output.find(
              (country) => country.country_id == propertyData.country_id
            );

            setLabels({
              level_1_label: selectedCountry?.level_1_label,
              level_2_label: selectedCountry?.level_2_label,
            });

            setPostalCodeRequired(
              selectedCountry.postal_code_required === 1 ? true : false
            );

            if (selectedCountry.postal_code_required === 1) {
              handleGetRelatedPostalCode(propertyData.postal_code);
            }

            setCountryData(countryObject);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleCreateProperty = async (e) => {
    const data = {
      property_id: propertyData.id,
      user_id: getLocalStoragedata("userId"),
      property_type_id: e.propertyType,
      property_role_id: e.role,
      property_name: e.propertyName,
      country_id: e.country,
      state_id: stateId,
      suburb_id: suburbId,
      address: e.address,
      is_default:
        propertyData.is_default == 1
          ? propertyData.is_default
          : e.defaultProperty
          ? 1
          : 0,
    };

    setEditedDataObject(data);
    setShowEditConfirmation(true);
  };

  const handleDataUpdate = async () => {
    try {
      await updateProperty(editedDataObject)
        .then((response) => {
          if (response?.data?.success) {
            onCancel();
            setShowEditConfirmation(false);
            getMyProperties();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchLocationLevel1Data = async (e) => {
    try {
      await getAllLocationLevel1Data({ country_id: e })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location1) => {
              let data = {
                value: location1?.level1_id,
                label: location1?.level1_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation1SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchLocationLevel2Data = async (e) => {
    try {
      await getAllLocationLevel2Data({
        country_id: selectedCountry,
        level1_id: e,
      })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location2) => {
              let data = {
                value: location2?.level2_id,
                label: location2?.level2_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation2SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: selectedCountry,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setAllPostalCodes(response?.data.output);

            var postalCodeObject = [];
            response?.data.output?.forEach((item) => {
              let data = {
                value: item?.suberb_id,
                label:
                  item?.postal_code + ", " + item?.suberb + ", " + item?.state,
              };
              postalCodeObject.push(data);
            });
            setPostalCodeData(postalCodeObject);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteProperty({
        property_id: propertyData.id,
        status: 0,
      })
        .then((response) => {
          if (response?.data?.success) {
            setShowDeleteConfirmation(false);
            getMyProperties();
            onCancel();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //edit button disable when not change the field
  const compareData = (initial, updated) => {
    let changes = {};

    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });

    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false); // Return null if no changes
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      {
        propertyName: propertyData.property_name,
        propertyType: propertyData.property_type_id,
        role: propertyData.property_role_id,
        country: propertyData.country_id,
        stateProvince: propertyData.state_id,
        citySuburb: propertyData.suburb_id,
        address: propertyData.address,
        zip: propertyData.suburb_id,
        defaultProperty: propertyData.is_default,
      },
      allFields
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: "#e0efe2",
            headerBg: "#e0efe2",
          },
        },
      }}
    >
      <Modal
        title={
          <div className="flex justify-between items-center">
            <span className="font-bold text-2xl text-primaryDark">
              Edit Property
            </span>
          </div>
        }
        width={800}
        open={open}
        onCancel={() => {
          if (isFieldChanged) {
            setShowCloseConfirmation(true);
          } else {
            onCancel();
          }
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          initialValues={propertyData ? propertyData : {}}
          onFinish={handleCreateProperty}
          className="gap-4"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row className="flex flex-row mb-4" gutter={16}>
            <Col span={24} md={{ span: 12 }}>
              <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                <LocationIconCard />
                Property Name *
              </p>
              <Form.Item
                name="propertyName"
                rules={[
                  {
                    required: true,
                    message: "Property Name is required!",
                  },
                ]}
                className="mb-0"
              >
                <Input
                  size="large"
                  placeholder="Ex: Home"
                  maxLength={50}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.value === "") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24} md={{ span: 12 }}>
              <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                <LocationIconCard />
                Property Type *
              </p>
              <Form.Item
                name="propertyType"
                rules={[
                  {
                    required: true,
                    message: "Property Type is required!",
                  },
                ]}
                className="mb-0"
              >
                <Select
                  size="large"
                  options={types}
                  placeholder="Select Property Type"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="flex flex-col mb-4">
            <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
              <PersonIconGreenOutline />
              Role *
            </p>
            <Form.Item
              name="role"
              rules={[{ required: true, message: "Role is required!" }]}
              className="mb-0"
            >
              <Select
                size="large"
                options={roles}
                placeholder="Select Your Role"
              />
            </Form.Item>
          </Row>

          <Divider className="bg-primaryLight my-3" />

          <Row className="flex flex-col mb-4">
            <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
              <LocationIconCard />
              Country *
            </p>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Country is required!" }]}
              className="mb-0"
            >
              <Select
                size="large"
                placeholder="Select Country"
                options={countryData}
                onSelect={(e) => {
                  setSelectedCountry(e);

                  setIsStateSelected(false);

                  form.resetFields(["zip", "stateProvince", "citySuburb"]);
                  setPostalCodeData(null);
                  setLocation1SelectorData([]);
                  setLocation2SelectorData([]);
                  setSuburbId(null);
                  setStateId(null);

                  let selectedCountry = countryList.find(
                    (country) => country.country_id === e
                  );

                  setPostalCodeRequired(
                    selectedCountry.postal_code_required === 1 ? true : false
                  );

                  setLabels({
                    level_1_label: selectedCountry?.level_1_label,
                    level_2_label: selectedCountry?.level_2_label,
                  });

                  if (selectedCountry.postal_code_required === 0) {
                    fetchLocationLevel1Data(e);
                  }
                }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Row>

          <Row className="flex flex-col mb-4">
            <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
              <LocationIconCard />
              Address *
            </p>
            <Form.Item
              name="address"
              rules={[{ required: true, message: "Address is required!" }]}
              className="mb-0"
            >
              <Input
                size="large"
                placeholder="Enter your address"
                maxLength={150}
              />
            </Form.Item>
          </Row>

          <Row className="flex flex-col mb-4">
            {postalCodeRequired && (
              <>
                <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                  <LocationIconCard />
                  Postal Code *
                </p>
                <Form.Item
                  name="zip"
                  rules={[
                    { required: true, message: "Postal Code is required!" },
                  ]}
                  className="mb-0"
                >
                  <Select
                    size="large"
                    className="w-full"
                    placeholder="Enter Postal Code"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={postalCodeData}
                    notFoundContent={
                      postalCodeData === null
                        ? "Please enter your property postal code"
                        : "No Data"
                    }
                    onSearch={(e) => {
                      if (e?.length === 4) {
                        handleGetRelatedPostalCode(e);
                      }

                      if (e?.length < 4) {
                        setPostalCodeData(null);
                      }
                    }}
                    onSelect={(e) => {
                      let suburb = allPostalCodes.find(
                        (item) => item.suberb_id === e
                      );

                      setSuburbId(suburb.suberb_id);
                      setStateId(suburb.state_id);
                    }}
                  />
                </Form.Item>
              </>
            )}
          </Row>

          <Row className="flex flex-col mb-4">
            {!postalCodeRequired && (
              <Row className="flex flex-row" gutter={16}>
                <Col span={24} md={{ span: 12 }}>
                  <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                    <LocationIconCard />
                    {labels.level_1_label} *
                  </p>
                  <Form.Item
                    name="stateProvince"
                    rules={[
                      {
                        required: true,
                        message: `${labels.level_1_label} is required!`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Select
                      size="large"
                      placeholder={`Select ${labels.level_1_label}`}
                      options={local1SelectData}
                      onSelect={(e) => {
                        setStateId(e);
                        fetchLocationLevel2Data(e);
                        setIsStateSelected(true);
                        setSuburbId(null);
                        form.setFieldValue("citySuburb", null);
                      }}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={{ span: 12 }}>
                  <p className="flex flex-row gap-1 justify-start items-center text-sm font-medium mb-2">
                    <LocationIconCard />
                    {labels.level_2_label} *
                  </p>
                  <Form.Item
                    name="citySuburb"
                    rules={[
                      {
                        required: true,
                        message: `${labels.level_2_label} is required!`,
                      },
                    ]}
                    className="mb-0"
                  >
                    <Select
                      size="large"
                      placeholder={`Select ${labels.level_2_label}`}
                      options={local2SelectData}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onSelect={(e) => {
                        setSuburbId(e);
                      }}
                      disabled={!isStateSelected}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Row>

          <Row className="flex flex-col mb-4">
            <Form.Item
              name="defaultProperty"
              valuePropName="checked"
              className="mb-0"
            >
              <Checkbox disabled={propertyData.is_default === 1}>
                <div className="flex flex-col">
                  <span className="flex text-sm">Set as default property</span>
                  <p className="text-xxs text-gray-500">
                    This property will be used as a default location in your job
                    postings, you can change it anytime.
                  </p>
                </div>
              </Checkbox>
            </Form.Item>
          </Row>

          <div className="flex flex-col gap-2  mb-4"></div>

          <Form.Item className="mb-0">
            <Button
              size="large"
              htmlType="submit"
              className="w-full border-primaryDark font-semibold text-primaryDark"
              disabled={!isFieldChanged}
            >
              Update Property
            </Button>
          </Form.Item>

          <div
            className="flex text-red-600 font-bold text-sm cursor-pointer mt-4 w-full justify-center items-center"
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          >
            Remove Property
          </div>
        </Form>

        {showEditConfirmation && (
          <NotificationAlertBox
            open={showEditConfirmation}
            onCancel={() => setShowEditConfirmation(false)}
            title="Save Changes"
            content={
              <div>
                <p className="text-base font-medium text-darkestColor text-center">
                  Are you sure you want to save the changes to this property?
                </p>
                <p className="text-xs text-textColorThree text-center">
                  Ensure all details are accurate before proceeding.
                </p>
                <div className="flex justify-center items-center mt-5 gap-4">
                  <Button
                    size="large"
                    type="primary"
                    className="border border-primaryDarkest text-sm font-semibold w-full bg-white text-primaryDarkest"
                    onClick={() => {
                      setShowEditConfirmation(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="default"
                    className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full text-white"
                    onClick={handleDataUpdate}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            }
          />
        )}

        {showDeleteConfirmation && (
          <NotificationAlertBox
            open={showDeleteConfirmation}
            onCancel={() => setShowDeleteConfirmation(false)}
            title="Remove Property"
            content={
              <div>
                <p className="text-base font-medium text-darkestColor text-center">
                  Are you sure you want to remove this property?
                </p>
                <p className="text-xs text-textColorThree text-center">
                  This action cannot be undone
                </p>
                <div className="flex justify-center items-center mt-5 gap-4">
                  <Button
                    size="large"
                    type="default"
                    className="text-red-600 border border-red-600 text-sm font-semibold w-full"
                    onClick={handleDelete}
                  >
                    Remove Property
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                    onClick={() => {
                      setShowDeleteConfirmation(false);
                    }}
                  >
                    No, Keep It
                  </Button>
                </div>
              </div>
            }
          />
        )}

        {showCloseConfirmation && (
          <NotificationAlertBox
            open={showCloseConfirmation}
            onCancel={() => setShowCloseConfirmation(false)}
            content={
              <div>
                <p className="text-md font-bold text-darkestColor text-center">
                  You have unsaved changes. Would you like to save before
                  leaving?
                </p>
                <p className="text-xs text-textColorThree text-center">
                  You've made changes to property. Save before leaving the page,
                  or any unsaved changes will be lost.{" "}
                </p>

                <div className="flex justify-center items-center mt-5 gap-4">
                  <Button
                    type="default"
                    className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    No, Discard
                  </Button>
                  <Button
                    type="primary"
                    className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                    onClick={() => {
                      setShowCloseConfirmation(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            }
            title="Are you sure?"
          />
        )}
      </Modal>
    </ConfigProvider>
  );
};

export default EditPropertySignUp;
