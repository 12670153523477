import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import MyPropertyCard from "../components/myProperties/MyPropertyCard";
import { Button, Pagination } from "antd";
import AddNewProperty from "../components/myProperties/AddNewProperty";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import PropertyService from "../services/PropertyService";
import { NotificationContext } from "../context/NotificationContext";

const MyProperties = () => {
  const { fetchMyProperties } = PropertyService();
  const { openNotification, handleError } = useContext(NotificationContext);

  const [openAddNewPropertyModal, setAddNewPropertyModal] = useState(false);
  const [properties, setProperties] = useState([]);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = properties.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getMyProperties();
  }, []);

  const getMyProperties = async () => {
    try {
      await fetchMyProperties({
        user_id: getLocalStoragedata("userId"),
        status: 1,
      })
        .then((response) => {
          if (response?.data?.success) {
            setProperties(response.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="flex flex-col w-full justify-center items-center my-28 p-4">
        <div className="max-w-[1073px] xl:w-[1073px] mx-2 w-full relative">
          {/* new property add button */}
          <div className="flex flex-col md:flex-row gap-4 justify-between items-center mb-5">
            <p className="text-xl font-semibold text-primaryDarkest">
              My Properties ({properties.length})
            </p>

            <Button
              size="large"
              className="border-primaryDarkest px-7 text-primaryDarkest font-semibold"
              onClick={() => {
                setAddNewPropertyModal(true);
              }}
            >
              + Add new property
            </Button>
          </div>

          <div className="flex flex-col gap-2 min-h-[80%]">
            {properties.length > 0 ? (
              <>
                {currentItems?.map((item, index) => (
                  <MyPropertyCard
                    data={item}
                    getMyProperties={getMyProperties}
                    key={index}
                  />
                ))}
              </>
            ) : (
              <div className="flex flex-col border-[1px] justify-center items-center border-dotted border-textColorFour bg-[#F1F1F1] p-4 rounded-md">
                <p className="flex font-medium text-textColorTwo text-center">
                  You don't have any added properties
                </p>
                <p
                  className="flex font-semibold text-primaryDark cursor-pointer"
                  onClick={() => {
                    setAddNewPropertyModal(true);
                  }}
                >
                  Add Property +
                </p>
              </div>
            )}
          </div>

          {properties.length > 10 && (
            <div className="flex justify-end mt-10">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={properties.length}
                onChange={handlePageChange}
                showSizeChanger={false}
                responsive={true}
              />
            </div>
          )}
        </div>
      </div>

      {openAddNewPropertyModal && (
        <AddNewProperty
          open={openAddNewPropertyModal}
          onCancel={() => {
            setAddNewPropertyModal(false);
          }}
          count={properties.length}
          getMyProperties={getMyProperties}
        />
      )}
    </div>
  );
};

export default MyProperties;
