import React from "react";
import CloseIcon from "../../assets/svg/CloseIcon";
import { Divider, Modal } from "antd";
import UserColorProfile from "../userColorProfile/UserColorProfile";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";

const WorkerSelectedSearchListForJobPost = ({
  open,
  onCancel,
  searchSelectedTaskerList,
  serSearchSelectedTaskerList,
  searchTaskerList,
}) => {
  return (
    <Modal open={open} onCancel={onCancel} footer={false} width={500}>
      <div className="mt-5">
        <div className="flex flex-row justify-between items-center">
          <p className="text-base font-semibold">
            Selected Workers ({searchSelectedTaskerList?.length})
          </p>

          <div
            className="cursor-pointer"
            onClick={() => {
              serSearchSelectedTaskerList([]);
            }}
          >
            <p className="text-base text-primaryDarkest font-semibold">
              Clear All
            </p>
          </div>
        </div>

        <Divider />

        <div className="mt-3">
          {searchSelectedTaskerList?.length === 0 ? (
            <div className="flex justify-center items-center">
              <p className="text-base text-black">No selected data</p>
            </div>
          ) : (
            <div>
              {searchTaskerList
                .filter((tasker) =>
                  searchSelectedTaskerList?.includes(tasker?.user_id)
                )
                ?.map((item) => {
                  return (
                    <div className="flex flex-row justify-between items-center mb-5 bg-slate-200 p-3 rounded-lg">
                      <div className="flex flex-row gap-3 justify-start items-center">
                        {item?.profile_picture === null ? (
                          <UserColorProfile
                            name={decryptSecureData(item?.full_name) || "N Z"}
                            color={"#3bb96e"}
                            size="40px"
                            textSize="15px"
                          />
                        ) : (
                          <img
                            src={item?.profile_picture}
                            alt=""
                            className="flex w-[40px] h-[40px] rounded-full border-[2px] object-cover"
                          />
                        )}
                        <div>
                          <p className="text-base font-semibold text-primaryDarkest">
                            {decryptSecureData(item?.full_name)}
                          </p>
                        </div>
                      </div>

                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          serSearchSelectedTaskerList(
                            searchSelectedTaskerList?.filter(
                              (id) => id !== item?.user_id
                            )
                          );
                        }}
                      >
                        <CloseIcon color="#000000" />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WorkerSelectedSearchListForJobPost;
