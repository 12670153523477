import React, { useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import DeleteAccountOtpCard from "../../components/deleteAccount/DeleteAccountOtpCard";
import DeleteAccountSuccess from "../../components/deleteAccount/DeleteAccountSuccess";
import { DeleteAccount } from "../../components/deleteAccount/DeleteAccount";

const DeleteWorkerAccount = () => {
  const [deleteAccountSteps, setDeleteAccountSteps] = useState("DELETE_ACCOUNT");

  return (
    <div>
      <NavBar />
      <div className="flex content justify-center items-center">
        <div className="max-w-[1073px] xl:w-[1073px]">
          {deleteAccountSteps === "DELETE_ACCOUNT" ? (
            <DeleteAccount setDeleteAccountSteps={setDeleteAccountSteps} />
          ) : deleteAccountSteps === "DELETE_ACCOUNT_OTP_CARD" ? (
            <DeleteAccountOtpCard setDeleteAccountSteps={setDeleteAccountSteps} />
          ) : deleteAccountSteps === "DELETE_ACCOUNT_SUCCESS" ? (
            <DeleteAccountSuccess setDeleteAccountSteps={setDeleteAccountSteps} />
          ) :  (
            <DeleteAccountOtpCard setDeleteAccountSteps={setDeleteAccountSteps}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteWorkerAccount;
