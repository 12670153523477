import React from "react";

const HighlySkillIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="56"
      height="61"
      viewBox="0 0 56 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0376 0.113037C29.3068 0.835483 30.5684 1.5711 31.8471 2.27661C37.623 5.47646 43.8545 7.80399 50.333 9.18124C51.7705 9.48791 53.2251 9.71367 54.6778 9.93379C55.0601 9.99211 55.2111 10.1012 55.2054 10.4982C55.127 15.751 55.4787 21.0151 55.0142 26.2509C54.3261 34.0755 51.3022 40.9877 46.1048 46.9629C43.5697 49.8342 40.6047 52.3083 37.3121 54.3003C34.4296 56.0782 31.5776 57.8937 28.7104 59.6866C28.4982 59.8183 28.3281 60.0403 28.0299 60.0215C27.7413 59.6283 27.7948 59.173 27.7948 58.7309C27.7948 50.9433 27.7948 43.1569 27.7948 35.3718C27.7948 24.0673 27.7948 12.7627 27.7948 1.45822C27.8044 1.01045 27.726 0.526939 28.0376 0.113037Z"
        fill="#3747D5"
      />
      <path
        d="M28.0383 0.113525V60.0371C27.1285 59.5216 26.3352 58.8462 25.5037 58.2253C21.7515 55.4446 17.8827 52.8107 14.2814 49.857C7.49379 44.2731 3.29234 37.1427 1.57393 28.5975C1.09726 26.1087 0.869352 23.58 0.893453 21.0475C0.893453 17.599 0.893453 14.1504 0.893453 10.7C0.893453 10.2729 0.945063 10.0152 1.4669 9.94745C9.42246 8.83744 16.8906 6.29195 23.9172 2.47653C25.3049 1.71833 26.664 0.903701 28.0383 0.113525Z"
        fill="#409FCB"
      />
    </svg>
  );
};

export default HighlySkillIcon;
