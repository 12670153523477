import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import { Button, Form, Typography } from "antd";
import { InputOTP } from "antd-input-otp";
import PassCode from "../../assets/svg/PassCode";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginServices from "../../services/LoginServices";
import { NotificationContext } from "../../context/NotificationContext";
import { shaEncryption } from "../../helpers/encryptHelpers/encryption";
import { AuthContext } from "../../context/AuthContext";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import BackIcon from "../../assets/svg/BackIcon";

const { Text } = Typography;

const PINConfirmation = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;
  //state.type==6;worker----2;client
  //state.from==search,directSignin

  let { openNotification, handleError } = useContext(NotificationContext);
  let { tryToLoginUserID, setToken } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { pinValidate, getAllAuthUserData } = LoginServices();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePinVerification = async (e) => {
    setLoading(true);

    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "App-Type": state?.type === 2 ? 2 : 1, //2-->client,1-->worker
      },
    };

    try {
      await pinValidate(
        {
          user_id: getLocalStoragedata("userId"),
          pin: shaEncryption(e?.pin?.join("")),
          is_web: 1,
        },
        config
      )
        .then((response) => {
          if (response?.data?.success) {
            if (response?.data?.output?.token) {
              setToken(response?.data?.output?.token);
              getAuthUserData(response?.data?.output?.token);
            }
          } else {
            setError(response?.data?.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          handleError(error);
          setLoading(true);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const getAuthUserData = async (token) => {
    let config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Custom-Header": "random-value-1234567890",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await getAllAuthUserData(config)
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("token", token);
            setLocalStorageData("userData", response?.data?.output);
            setLocalStorageData("userId", response?.data?.output?.user_id);
            setLocalStorageData(
              "userTypeId",
              response?.data?.output?.user_type_id
            );
            setLocalStorageData(
              "profilePic",
              response?.data?.output?.profile_picture
            );
            setLocalStorageData("userName", response?.data?.output?.first_name);

            if (state?.from === "directSignin" || state?.from === "myAccount") {
              //from delete worker account
              navigate("../");
            } else if (state?.from === "search") {
              //from search job type
              navigate("../create-job-post");
            } else if (state?.from === "subscription") {
              //from subscription
              navigate("../subscription");
            } else if (state?.from === "searchNotLogin") {
              navigate("/create-job-post", {
                state: { from: "searchJustNowLogin" },
              });
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };
  return (
    <div>
      <NavBar />
      <div className="content flex justify-center items-center">
        <div className="flex w-full justify-center items-center">
          <div className="max-w-[1073px] xl:w-[1073px] mx-2">
            <div
              className="flex flex-row gap-2 items-center cursor-pointer mb-5"
              onClick={() => {
                navigate("../new-login", {
                  state: { from: state?.from, type: state?.type },
                });
                //type-->2 = Client, 6--->worker
                //from: search,directSignin
              }}
            >
              <BackIcon />
              <Text className="flex text-textColorTwo font-medium">Back</Text>
            </div>
            <div className="flex justify-center items-center gap-2">
              <div className="flex flex-col justify-center shadow-lg rounded-lg w-full md:w-[500px] bg-primaryLightest p-8">
                <Text className="flex font-bold text-2xl md:text-4xl text-[#074924] text-center md:text-start leading-tight">
                  Enter your PIN
                </Text>
                <p>
                  <b>Enter a 4 digit pin.</b>&nbsp; You can use this pin to log
                  in to the website.
                </p>

                <div className="flex w-full mt-4">
                  <Form
                    className="flex flex-col gap-2 w-full"
                    onFinish={handlePinVerification}
                  >
                    <span className="flex flex-row gap-2">
                      <PassCode />
                      <p className="text-sm font-medium">
                        Enter 4 digits pin *
                      </p>
                    </span>
                    <Form.Item
                      name="pin"
                      className="flex items-start w-full mb-0"
                      rules={[
                        {
                          required: true,
                          message: "PIN is required!",
                        },
                        // { min: 1, message: "Invalid PIN!" },
                      ]}
                    >
                      <InputOTP
                        type="password"
                        autoFocus
                        length={4}
                        inputType="numeric"
                        inputClassName="w-full"
                        className="!md:w-[100px] !h-[50px] !max-w-60"
                        onChange={() => {
                          setError(null);
                        }}
                      />
                    </Form.Item>
                    {error && (
                      <p className="text-sm font-medium text-warringColorOne">
                        Incorrect PIN. Please try again.
                      </p>
                    )}

                    <Link
                      to="/reset-pin"
                      state={{ from: state?.from, type: state?.type }}
                      className="text-xs font-bold text-end cursor-pointer underline"
                    >
                      Forgot PIN?
                    </Link>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="w-full bg-primaryDarkest text-base font-bold h-12 rounded-lg text-white "
                        loading={loading}
                        htmlType="submit"
                      >
                        Continue
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PINConfirmation;
