import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import PopularJobServices from "../../services/PopularJobServices";
import { NotificationContext } from "../../context/NotificationContext";

const PopularJobs = () => {
  const navigate = useNavigate();

  const { openNotification, handleError } = useContext(NotificationContext);
  const { getAllPopularJoblist } = PopularJobServices();
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetchAllPopularJoblist();
  }, []);

  const fetchAllPopularJoblist = async () => {
    try {
      await getAllPopularJoblist()
        .then((response) => {
          if (response?.data?.success) {
            setJobs(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] overflow-hidden">
        <h2 className="title-level2 custom-font text-center">Popular Jobs</h2>
        <Row gutter={16} className="mt-8 md:mt-10">
          {jobs.map((job, index) => (
            <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} key={index}>
              <div
                className="shadow-lg mb-5 rounded-lg p-5 cursor-pointer hover:shadow-2xl"
                onClick={() => {
                  navigate("../services");
                }}
              >
                <div className="flex justify-center items-center">
                  <img
                    src={job?.web_image_path}
                    alt={job.job_category}
                    height={100}
                  />
                </div>

                <p className="text-base lg:text-xl xl:text-[24px] font-semibold text-start mt-2">
                  {job.job_category}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default PopularJobs;
