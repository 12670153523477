import React from "react";

const FillLinkedIn = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.2062 63.4468V17.9882C77.2062 10.2846 70.9612 4.03955 63.2575 4.03955L17.799 4.03955C10.0953 4.03955 3.85032 10.2846 3.85032 17.9882V63.4468C3.85032 71.1504 10.0953 77.3954 17.799 77.3954H63.2575C70.9612 77.3954 77.2062 71.1504 77.2062 63.4468Z"
        fill="#00B4EA"
      />
      <path
        d="M25.1537 33.8903H32.2535V56.72H25.1537V33.8903ZM28.7162 22.5508C29.5299 22.5508 30.3254 22.7921 31.002 23.2442C31.6786 23.6963 32.2059 24.3388 32.5173 25.0906C32.8287 25.8424 32.9102 26.6697 32.7515 27.4678C32.5927 28.2659 32.2008 28.999 31.6254 29.5744C31.05 30.1498 30.3169 30.5417 29.5188 30.7004C28.7207 30.8592 27.8935 30.7777 27.1417 30.4663C26.3899 30.1549 25.7473 29.6275 25.2952 28.9509C24.8431 28.2743 24.6018 27.4789 24.6018 26.6651C24.6018 26.1248 24.7082 25.5898 24.915 25.0906C25.1218 24.5915 25.4248 24.1379 25.8069 23.7558C26.1889 23.3738 26.6425 23.0707 27.1417 22.864C27.6408 22.6572 28.1759 22.5508 28.7162 22.5508Z"
        fill="white"
      />
      <path
        d="M36.696 33.8921H43.4948V37.003C44.1821 35.8421 45.1687 34.8873 46.3516 34.2385C47.5344 33.5897 48.8699 33.2708 50.2182 33.3151C57.3932 33.3151 58.7229 38.0315 58.7229 44.178V56.7217H51.7485V45.7334C51.7485 43.0741 51.7485 39.6873 48.0607 39.6873C44.3728 39.6873 43.7958 42.5724 43.7958 45.5578V56.8472H36.696V33.8921Z"
        fill="white"
      />
    </svg>
  );
};

export default FillLinkedIn;
