import React from "react";
import { Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";

const HoorayBox = ({ open, onCancel, content, title, popupWidth }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closeIcon={null}
      footer={false}
      width={popupWidth || 400}
      centered
    >
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="flex flex-row justify-center">
          <p className="text-2xl font-bold text-primaryDark">{title}</p>
        </div>
        {content}
      </div>
    </Modal>
  );
};

export default HoorayBox;
