import React from "react";

const DoubleArrowBlack = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.0059" cy="12.5107" r="12" fill="#2F2F2F" />
      <g clipPath="url(#clip0_2293_26513)">
        <path
          d="M18.1927 16.2105L14.3905 13.0526C14.3252 12.9972 14.2454 12.9616 14.1605 12.9503C14.0756 12.9389 13.9893 12.9521 13.9117 12.9883C13.8341 13.0246 13.7685 13.0823 13.7228 13.1547C13.6771 13.2271 13.6531 13.3112 13.6537 13.3968V14.5168C13.6537 14.6355 13.6065 14.7493 13.5226 14.8332C13.4387 14.9171 13.3249 14.9642 13.2063 14.9642H6.10509C5.98644 14.9642 5.87265 15.0113 5.78875 15.0952C5.70485 15.1791 5.65771 15.2929 5.65771 15.4116V17.6958C5.65771 17.8145 5.70485 17.9283 5.78875 18.0122C5.87265 18.0961 5.98644 18.1432 6.10509 18.1432H13.2084C13.3271 18.1432 13.4408 18.1903 13.5247 18.2742C13.6086 18.3581 13.6558 18.4719 13.6558 18.5906V19.7148C13.6552 19.8005 13.6792 19.8845 13.7249 19.9569C13.7706 20.0293 13.8362 20.087 13.9138 20.1233C13.9914 20.1595 14.0778 20.1727 14.1626 20.1614C14.2475 20.15 14.3273 20.1145 14.3926 20.059L18.1948 16.9011C18.2456 16.859 18.2865 16.8061 18.3145 16.7463C18.3425 16.6865 18.3569 16.6213 18.3567 16.5553C18.3565 16.4893 18.3417 16.4242 18.3133 16.3646C18.285 16.305 18.2438 16.2524 18.1927 16.2105Z"
          fill="white"
        />
        <path
          d="M6.71866 10.3432H10.2451V11.1253C10.244 11.3011 10.2935 11.4735 10.3878 11.6219C10.4821 11.7703 10.6171 11.8884 10.7766 11.9622C10.9001 12.0203 11.0349 12.0505 11.1714 12.0506C11.3861 12.0514 11.5943 11.9769 11.7598 11.8401L14.9715 9.17055C15.0757 9.08382 15.1596 8.9752 15.2172 8.8524C15.2748 8.7296 15.3047 8.59564 15.3047 8.46001C15.3047 8.32438 15.2748 8.19042 15.2172 8.06762C15.1596 7.94482 15.0757 7.8362 14.9715 7.74947L11.7598 5.07362C11.6248 4.96148 11.4607 4.8901 11.2866 4.86783C11.1125 4.84555 10.9357 4.8733 10.7768 4.94783C10.6179 5.02237 10.4835 5.1406 10.3893 5.2887C10.2952 5.43681 10.2451 5.60866 10.2451 5.78416V6.56523H6.71866C6.47363 6.56551 6.23871 6.66297 6.06544 6.83624C5.89218 7.00951 5.79471 7.24443 5.79443 7.48946V9.41898C5.79471 9.66401 5.89218 9.89893 6.06544 10.0722C6.23871 10.2455 6.47363 10.3429 6.71866 10.3432ZM6.88604 7.65683H10.4093C10.6543 7.65656 10.8892 7.55909 11.0625 7.38583C11.2358 7.21256 11.3332 6.97764 11.3335 6.7326V6.14312L14.1146 8.45896L11.3335 10.7748V10.1779C11.3336 10.0564 11.3098 9.93608 11.2635 9.82377C11.2171 9.71146 11.1491 9.60938 11.0632 9.52336C10.9774 9.43734 10.8755 9.36907 10.7633 9.32244C10.6511 9.27581 10.5308 9.25174 10.4093 9.2516H6.88604V7.65683Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2293_26513">
          <rect
            width="16.6667"
            height="16.6667"
            fill="white"
            transform="translate(3.67236 4.17773)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DoubleArrowBlack;
