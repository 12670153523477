import React from "react";

const EditGrayOutlineIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6663 9.34635V10.3464C16.6663 13.4891 16.6663 15.0604 15.69 16.0367C14.7137 17.013 13.1424 17.013 9.99967 17.013C6.85698 17.013 5.28563 17.013 4.30932 16.0367C3.33301 15.0604 3.33301 13.4891 3.33301 10.3464C3.33301 7.20366 3.33301 5.63231 4.30932 4.656C5.28563 3.67969 6.85698 3.67969 9.99967 3.67969H10.9997"
        stroke="#939292"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M13.101 4.64973L13.5336 4.21718C14.2502 3.50052 15.4122 3.50052 16.1288 4.21718C16.8455 4.93385 16.8455 6.09578 16.1288 6.81245L15.6963 7.24499M13.101 4.64973C13.101 4.64973 13.1551 5.56888 13.9661 6.3799C14.7771 7.19092 15.6963 7.24499 15.6963 7.24499M13.101 4.64973L9.12446 8.62631C8.85512 8.89565 8.72045 9.03032 8.60463 9.17881C8.468 9.35397 8.35087 9.5435 8.2553 9.74403C8.17428 9.91403 8.11406 10.0947 7.9936 10.4561L7.60795 11.613M15.6963 7.24499L11.7197 11.2216C11.4504 11.4909 11.3157 11.6256 11.1672 11.7414C10.9921 11.878 10.8025 11.9952 10.602 12.0907C10.432 12.1717 10.2513 12.232 9.88996 12.3524L8.73301 12.7381M8.73301 12.7381L7.9844 12.9876C7.80657 13.0469 7.61051 13.0006 7.47797 12.8681C7.34542 12.7355 7.29914 12.5395 7.35842 12.3616L7.60795 11.613M8.73301 12.7381L7.60795 11.613"
        stroke="#939292"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default EditGrayOutlineIcon;
