import React, { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import CreateJobPost from "../pages/CreateJobPost";
import Subscription from "../pages/Subscription";
import Login from "../pages/Login";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import { AuthContext } from "../context/AuthContext";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import UserDeletion from "../pages/UserDeletion";
import Support from "../pages/Support";
import WorkerQRCode from "../pages/WorkerQRCode";
import ClientQRCode from "../pages/ClientQRCode";
import TermsConditions from "../pages/TermsConditions";
import SubscriptionDetails from "../pages/SubscriptionDetails";
// import { SubscriptionContext } from "../context/SubscriptionContext";
import MyJobsWorkerList from "../pages/MyJobsWorkerList";
import MyJobs from "../pages/MyJobs";
import NewLogin from "../pages/new-login/NewLogin";
import ResetPIN from "../pages/forget-pin/ResetPIN";
import Services from "../pages/Services";
import OtpValidateCard from "../sections/login/OtpValidateCard";
import PINConfirmation from "../pages/new-login/PINConfirmation";
import QuickSignUp from "../pages/QuickSignUp";
import PublicProfile from "../pages/PublicProfile";
import MyAccount from "../pages/MyAccount";
import MyProperties from "../pages/MyProperties";
import SummaryAndPost from "../sections/createJobPost/SummaryAndPost";
import { NewJobPostSuccess } from "../sections/createJobPost/NewJobPostSuccess";
import PublicProfileEditor from "../pages/PublicProfileEditor";
import MyAccountWorker from "../pages/myWorkerAccount/MyAccountWorker";
import DeleteWorkerAccount from "../pages/myWorkerAccount/DeleteWorkerAccount";
import WorkerSignUp from "../pages/WorkerSignUp";
import WorkerSignUpSuccess from "../pages/WorkerSignUpSuccess";

const RouterSet = () => {
  let { token } = useContext(AuthContext);
  const tokenValue = getLocalStoragedata("token") || token;

  // let { userLocation, fetchCountryCode } = useContext(SubscriptionContext);

  // useEffect(() => {
  //   fetchCountryCode();
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/user/deletion" element={<UserDeletion />} />
        <Route path="/support" element={<Support />} />
        <Route path="/worker" element={<WorkerQRCode />} />
        <Route path="/client" element={<ClientQRCode />} />
        <Route path="/terms-condition" element={<TermsConditions />} />
        <Route path="/subscription-details" element={<SubscriptionDetails />} />
        <Route path="/services" element={<Services />} />

        <Route
          path="/new-login"
          element={<WithOutTokenRoute element={<NewLogin />} />}
        />
        <Route
          path="/pin-verification"
          element={<WithOutTokenRoute element={<PINConfirmation />} />}
        />
        <Route
          path="/quick-sign-up"
          element={<WithOutTokenRoute element={<QuickSignUp />} />}
        />
        <Route
          path="/worker-sign-up"
          element={<WithOutTokenRoute element={<WorkerSignUp />} />}
        />
        <Route
          path="/worker-sign-up-success"
          element={<WithOutTokenRoute element={<WorkerSignUpSuccess />} />}
        />

        <Route path="/reset-pin" element={<ResetPIN />} />
        <Route path="/otp-old" element={<OtpValidateCard />} />

        <Route path="/profile/:username" element={<PublicProfile />} />
        <Route path="/profile/:status" element={<PublicProfile />} />
        <Route path="/create-job-post" element={<CreateJobPost />} />

        {(tokenValue !== null) &
        (tokenValue !== "") &
        (tokenValue !== undefined) ? (
          <>
            <Route
              path="/subscription"
              element={<PrivateRoute element={<Subscription />} role={6} />}
            />
            <Route path="/my-jobs" element={<MyJobs />} />
            <Route path="/my-jobs/:id" element={<MyJobsWorkerList />} />
            <Route path="/public-profile" element={<PublicProfileEditor />} />
            <Route path="/my-account-client" element={<MyAccount />} />
            <Route path="/my-account-worker" element={<MyAccountWorker />} />
            <Route
              path="/delete-worker-account"
              element={<DeleteWorkerAccount />}
            />
            <Route path="/my-properties" element={<MyProperties />} />
            <Route path="/summary-and-post" element={<SummaryAndPost />} />
            <Route path="/job-post-success" element={<NewJobPostSuccess />} />
          </>
        ) : (
          <Route path="*" element={<Home />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default RouterSet;

const PrivateRoute = ({ element, role }) => {
  const userType = getLocalStoragedata("userTypeId");

  if (
    userType === role &&
    getLocalStoragedata("userData")?.premium_package_price > 0
  ) {
    return element;
  } else {
    return <Navigate to="/" replace />;
  }
};

const WithOutTokenRoute = ({ element }) => {
  if (getLocalStoragedata("token") && getLocalStoragedata("userTypeId")) {
    return <Navigate to="/" replace />;
  } else {
    return element;
  }
};
