import { Button, Modal, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ServicesCard from "./ServicesCard";
import PublicProfileService from "../../services/PublicProfileService";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import AddCategory from "./servicesEditSteps/AddCategory";

const EditServicesModal = ({
  open,
  onClose,
  data,
  refreshServices,
  currency,
}) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { workerServiceList } = PublicProfileService();

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openedService, setOpenedService] = useState(false);

  const [openNewService, setOpenNewService] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    try {
      await workerServiceList({
        user_id: data?.user_id,
        status: 1,
        country_id: data?.country_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setServices(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={1000}
      title="Add/Edit Services"
    >
      <div className="flex flex-col gap-4">
        <div className="flex">
          <Button
            className="border-[#074924] text-[#074924] font-semibold"
            size="large"
            onClick={() => setOpenNewService(true)}
          >
            <FaPlus />
            Add New Service Categories
          </Button>
        </div>
        <div className=" flex flex-col gap-2 overflow-y-auto">
          {loading ? (
            <Spin />
          ) : (
            <>
              {services.map((item, index) => {
                return (
                  <ServicesCard
                    key={index}
                    data={item}
                    openedService={openedService}
                    setOpenedService={(id) => {
                      setOpenedService(id);
                    }}
                    onComplete={() => {
                      setOpenNewService(false);
                      fetchServices();
                      refreshServices();
                    }}
                    fetchServices={() => {
                      fetchServices();
                      refreshServices();
                    }}
                    currency={currency}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>

      {openNewService && (
        <AddCategory
          open={openNewService}
          onClose={() => {
            setOpenNewService(false);
          }}
          data={data}
          onComplete={() => {
            setOpenNewService(false);
            fetchServices();
            refreshServices();
          }}
        />
      )}
    </Modal>
  );
};

export default EditServicesModal;
