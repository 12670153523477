import { Button, Divider, Modal, Typography } from "antd";
import { useContext, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import NavbarLogo from "../../assets/svg/NavbarLogo";
import { AuthContext } from "../../context/AuthContext";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { format } from "date-fns";
import { SubscriptionContext } from "../../context/SubscriptionContext";

export default function PaymentInvoice({
  invoiceModalOpen,
  modelClose,
  historyData,
}) {
  const { currentUserData } = useContext(AuthContext);
  const { currentPackageDetails, changedBillingAddres } =
    useContext(SubscriptionContext);

  const [loading, setLoading] = useState(false);
  const { Text } = Typography;

  const reportTemplateRef = useRef(null);
  const handleGeneratePdf = async () => {
    setLoading(true);
    const element = reportTemplateRef.current;
    const canvas = await html2canvas(element, { scale: 3 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");
    pdf.save(`${historyData?.transactoin_id}.pdf`);
    setLoading(false);
  };

  return (
    <Modal
      open={invoiceModalOpen}
      onCancel={modelClose}
      footer={false}
      width={700}
    >
      <div className="w-full flex flex-col items-center justify-center">
        <div
          ref={reportTemplateRef}
          className="w-full flex flex-col items-center justify-center px-8 py-5 bg-white rounded-lg"
        >
          <div className="w-full flex  items-center justify-between gap-4">
            <Text className="text-sm font-bold text-colorBlack">INVOICE</Text>
            <NavbarLogo className="w-36" />
          </div>

          <Divider className="my-1 bg-slate-200" />

          <div className="w-full flex items-center justify-between mt-5">
            <div className="flex flex-col justify-between h-[150px]">
              <div className="flex flex-col">
                <Text className="text-xxs font-bold text-primaryColor">
                  Worker name
                </Text>
                <Text className="text-xs font-semibold text-textColorThree">
                  {decryptSecureData(currentUserData?.first_name)}
                </Text>
              </div>

              <div className="flex flex-col">
                <Text className="text-xxs font-bold text-primaryColor">
                  Billing details
                </Text>
                <Text className="text-xs font-semibold text-textColorThree flex flex-col">
                  <div>
                    {decryptSecureData(changedBillingAddres?.addressline01)}
                  </div>
                  <div>
                    {decryptSecureData(changedBillingAddres?.addressline02)}
                  </div>
                  <div>{changedBillingAddres?.stateorprovince}</div>
                  <div>{changedBillingAddres?.cityorsuburb}</div>
                </Text>
              </div>
            </div>

            <div className="flex flex-col justify-between h-[150px]">
              <div className="flex flex-col">
                <Text className="text-xxs font-bold text-primaryColor">
                  Invoice date
                </Text>
                <Text className="text-xs font-semibold text-textColorThree">
                  {format(new Date(historyData?.payment_date), "yyyy MMM dd")}
                </Text>
              </div>

              <div className="flex flex-col">
                <Text className="text-xxs font-bold text-primaryColor">
                  Invoice number
                </Text>
                <Text className="text-xs font-semibold text-textColorThree">
                  {historyData?.transactoin_id}
                </Text>
              </div>

              <Text className="text-xs font-semibold text-textColorThree">
                JobsNinja pty Ltd. Australia.
              </Text>

              <div className="flex flex-col">
                <Text className="text-xxs font-bold text-primaryColor">
                  All billing inquiries
                </Text>
                <Text className="text-xs font-semibold text-textColorThree">
                  Jobsninja.com/support
                </Text>
              </div>
            </div>
          </div>

          <div className="w-full mt-5">
            <table className="w-full">
              <thead className="border-b border-black">
                <tr>
                  <th className="text-left py-2 text-xxs font-bold text-textColorThree">
                    Description
                  </th>
                  <th className="text-center py-2 text-xxs font-bold text-textColorThree">
                    QTY
                  </th>
                  <th className="text-center py-2 text-xxs font-bold text-textColorThree">
                    Per Unit ({currentPackageDetails?.premium_package_name})
                  </th>
                  <th className="text-center py-2 text-xxs font-bold text-textColorThree">
                    Amount ({currentPackageDetails?.premium_package_name})
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr className="border-b border-gray-400">
                  <td className="text-left py-2 text-xs font-semibold text-textColorThree">
                    {format(new Date(historyData?.payment_date), "yyyy MMM")}{" "}
                    Monthly subscription fee
                    <br />
                    <div className="text-primaryColor font-bold">
                      {currentPackageDetails?.package_name}
                    </div>
                  </td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree"></td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree"></td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree">
                    {historyData?.amount}.00
                  </td>
                </tr>

                <tr>
                  <td className="text-left py-2 text-xs font-semibold text-textColorThree"></td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree border-b border-gray-400">
                    <div className="flex flex-col justify-between ">
                      <Text className="text-xs font-semibold">Sub total</Text>
                      <Text className="text-xs font-semibold">Tax</Text>
                    </div>
                  </td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree border-b border-gray-400"></td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree border-b border-gray-400">
                    <div className="flex flex-col justify-between">
                      <Text className="text-xs font-semibold">
                        {historyData?.amount}.00
                      </Text>
                      <Text className="text-xs font-semibold">0.00</Text>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="text-left py-2 text-xs font-semibold text-textColorThree"></td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree">
                    <Text className="text-xs font-semibold text-start">
                      Total
                    </Text>
                  </td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree"></td>
                  <td className="text-center py-2 text-xs font-semibold text-textColorThree">
                    {historyData?.amount}.00
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full h-full flex items-center justify-center mt-4">
          <Button
            type="primary"
            className="bg-primaryColor"
            onClick={handleGeneratePdf}
            loading={loading}
          >
            Download
          </Button>
        </div>
      </div>
    </Modal>
  );
}
