import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import JobDetails from "../sections/createJobPost/JobDetails";
import { CreateJobPostContext } from "../context/CreateJobPostContext";
import SummaryAndPost from "../sections/createJobPost/SummaryAndPost";
import { useLocation, useNavigate } from "react-router-dom";
import SearchForWorkers from "../sections/createJobPost/SearchForWorkers";
import WorkerSearchResultList from "../sections/createJobPost/WorkerSearchResultList";
import WorkerSearchResultListAlreadyLogin from "../sections/createJobPost/WorkerSearchResultListAlreadyLogin";
import AdditionalDetails from "../sections/createJobPost/AdditionalDetails";

const CreateJobPost = () => {
  const {
    stepperCurrentSteps,
    notLoginSteps,
    setNotLoginSteps,
    loginJobPostSteps,
  } = useContext(CreateJobPostContext);

  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
    setNotLoginSteps(1);
  }, []);

  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm(
          "You have unsaved changes! Leaving this page will result in the loss of any unsaved data!"
        )
      ) {
        setfinishStatus(true);
        window.location.replace("/");
        window.history.pushState(null, null, window.location.pathname);
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (state === null) {
      navigate("../");
    }
  });

  return (
    <div className="bg-primaryLightest">
      <NavBar />
      <div className="content">
        {state?.from === "searchNotLogin" ? (
          <>
            {notLoginSteps === 1 ? (
              <SearchForWorkers />
            ) : notLoginSteps === 2 ? (
              <WorkerSearchResultList />
            ) : (
              <SearchForWorkers />
            )}
          </>
        ) : state?.from === "searchAreadyLogin" ? (
          <>
            {loginJobPostSteps === 1 ? (
              <>
                {stepperCurrentSteps === 2 ? (
                  <SummaryAndPost />
                ) : stepperCurrentSteps === 1 ? (
                  <AdditionalDetails />
                ) : (
                  <JobDetails type="searchAreadyLogin" />
                )}
              </>
            ) : loginJobPostSteps === 2 ? (
              <>
                <WorkerSearchResultListAlreadyLogin />
              </>
            ) : loginJobPostSteps === 3 ? (
              <>
                {stepperCurrentSteps === 2 ? (
                  <SummaryAndPost />
                ) : stepperCurrentSteps === 1 ? (
                  <AdditionalDetails />
                ) : (
                  <JobDetails type="searchAreadyLogin" />
                )}
              </>
            ) : (
              <>
                {stepperCurrentSteps === 2 ? (
                  <SummaryAndPost />
                ) : stepperCurrentSteps === 1 ? (
                  <AdditionalDetails />
                ) : (
                  <JobDetails type="searchAreadyLogin" />
                )}
              </>
            )}
          </>
        ) : state?.from === "searchJustNowLogin" ? (
          <>
            {stepperCurrentSteps === 2 ? (
              <SummaryAndPost />
            ) : stepperCurrentSteps === 1 ? (
              <AdditionalDetails />
            ) : (
              <JobDetails type="searchJustNowLogin" />
            )}
          </>
        ) : (
          <SearchForWorkers />
        )}
      </div>
    </div>
  );
};

export default CreateJobPost;
