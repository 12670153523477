import React from "react";

const GoogleBusiness = ({ size }) => {
  return (
    <svg
      width={size || 37}
      height={size || 37}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2224 0H6.65556C3.03989 0 0.10881 2.93109 0.10881 6.54676V30.1136C0.10881 33.7292 3.03989 36.6603 6.65556 36.6603H30.2224C33.8381 36.6603 36.7691 33.7292 36.7691 30.1136V6.54676C36.7691 2.93109 33.8381 0 30.2224 0Z"
        fill="#FFC46B"
      />
      <path
        d="M29.3327 12.2549H6.86214C6.32824 12.2549 5.89542 12.6877 5.89542 13.2216V29.5359C5.89542 30.0698 6.32824 30.5026 6.86214 30.5026H29.3327C29.8666 30.5026 30.2995 30.0698 30.2995 29.5359V13.2216C30.2995 12.6877 29.8666 12.2549 29.3327 12.2549Z"
        fill="#4989F5"
      />
      <path
        d="M29.0488 12.1172H7.11529C6.43585 12.1172 5.90714 17.3754 5.90714 18.0549L17.9185 30.502H29.0488C29.7303 30.5002 30.2823 29.948 30.2841 29.2666V13.3523C30.2821 12.6709 29.7302 12.119 29.0488 12.1172Z"
        fill="url(#paint0_linear_6447_30423)"
      />
      <path
        d="M11.4668 15.3099H18.4394V6.15845H12.5109L11.4668 15.3099Z"
        fill="#3C4BA6"
      />
      <path
        d="M25.4114 15.3099H18.4388V6.15845H24.3674L25.4114 15.3099ZM30.2981 7.89321L30.3068 7.9244C30.3046 7.9134 30.3004 7.90318 30.2981 7.89321Z"
        fill="#7BABF7"
      />
      <path
        d="M30.31 7.9244L30.3015 7.89321C30.0804 6.88095 29.1844 6.1591 28.1483 6.15845H24.3708L25.414 15.3101H32.3866L30.31 7.9244Z"
        fill="#3F51B5"
      />
      <path
        d="M6.57073 7.9244L6.57939 7.89321C6.80044 6.88095 7.69639 6.1591 8.73249 6.15845H12.5102L11.4654 15.3101H4.49284L6.57073 7.9244Z"
        fill="#7BABF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4661 15.3066C11.4661 17.2289 9.90526 18.7873 7.97984 18.7873C6.05444 18.7873 4.49355 17.2289 4.49355 15.3066H11.4661Z"
        fill="#709BE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4371 15.3066C18.4371 17.2289 16.8762 18.7873 14.9508 18.7873C13.0254 18.7873 11.4645 17.2289 11.4645 15.3066H18.4371Z"
        fill="#3C4BA6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.412 15.3066C25.412 17.2289 23.8512 18.7873 21.9257 18.7873C20.0003 18.7873 18.4395 17.2289 18.4395 15.3066H25.412Z"
        fill="#709BE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.383 15.3066C32.383 17.2289 30.8221 18.7873 28.8967 18.7873C26.9713 18.7873 25.4104 17.2289 25.4104 15.3066H32.383Z"
        fill="#3C4BA6"
      />
      <path
        d="M28.9023 24.7474C28.89 24.5815 28.8694 24.43 28.8404 24.2365H25.0396C25.0396 24.7681 25.0396 25.3482 25.0375 25.8801H27.2393C27.1926 26.128 27.0972 26.3641 26.9589 26.5751C26.8206 26.786 26.642 26.9675 26.4333 27.1092C26.4333 27.1092 26.4333 27.1 26.4317 27.0997C26.1623 27.2767 25.8586 27.3952 25.5405 27.4475C25.2216 27.506 24.8946 27.5046 24.5762 27.4434C24.2528 27.3765 23.9467 27.2438 23.6768 27.0536C23.2783 26.7711 22.9712 26.3785 22.7925 25.9239C22.779 25.8885 22.7664 25.8529 22.7536 25.8167V25.8127L22.7569 25.8101C22.5838 25.3017 22.5833 24.7503 22.7557 24.2415C22.8771 23.8847 23.0769 23.5596 23.3404 23.29C23.9587 22.6505 24.8826 22.4099 25.7341 22.6666C26.0608 22.7669 26.3592 22.9425 26.6052 23.1794L27.3481 22.4366C27.4791 22.3042 27.6153 22.1759 27.7413 22.0392C27.3647 21.6879 26.9226 21.414 26.4401 21.2334C25.5622 20.915 24.6019 20.9066 23.7185 21.2095C23.6876 21.22 23.657 21.231 23.6268 21.2422C22.6737 21.6005 21.8897 22.3031 21.4296 23.2113C21.2671 23.5328 21.1487 23.8748 21.0778 24.2279C20.657 26.3189 21.9329 28.3771 23.9928 28.9305C24.6662 29.1108 25.3822 29.1064 26.0608 28.9523C26.6775 28.813 27.2476 28.5167 27.7159 28.0922C28.2046 27.6426 28.5546 27.0437 28.7381 26.4075C28.8917 25.8684 28.9472 25.3062 28.9023 24.7474Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6447_30423"
          x1="5.90714"
          y1="21.3094"
          x2="30.284"
          y2="21.3094"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stop-color="#4079D8" />
          <stop offset="1" stop-color="#4989F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GoogleBusiness;
