import React from "react";

const MapIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66699 9.43277C4.66699 6.43449 7.05481 4.00391 10.0003 4.00391C12.9458 4.00391 15.3337 6.43449 15.3337 9.43277C15.3337 12.4076 13.6314 15.8788 10.9756 17.1202C10.3565 17.4096 9.64415 17.4096 9.02504 17.1202C6.36921 15.8788 4.66699 12.4076 4.66699 9.43277Z"
        stroke="#2C8B52"
        strokeWidth="0.9"
      />
      <circle cx="10" cy="9.33594" r="2" stroke="#2C8B52" strokeWidth="0.9" />
    </svg>
  );
};

export default MapIcon;
