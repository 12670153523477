import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import PersonalInfo from "../sections/Profile/PersonalInfo";
import AvailableIn from "../sections/Profile/AvailableIn";
import { Spin } from "antd";
import Services from "../sections/Profile/Services";
import PublicProfileService from "../services/PublicProfileService";
import { NotificationContext } from "../context/NotificationContext";
import CoverSection from "../sections/Profile/CoverSection";
import WorkSampleImageList from "../sections/Profile/WorkSampleImageList";
import { useLocation, useParams } from "react-router-dom";
import UserNotFoundIcon from "../assets/svg/UserNotFoundIcon";
import AddRecomondationLogInFlow from "../components/recomondation/AddRecomondationLogInFlow";
import AddRecomondationLogOutFlow from "../components/recomondation/AddRecomondationLogOutFlow";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import Review from "../sections/Profile/Review";

const PublicProfile = () => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { fetchPublicProfile } = PublicProfileService();
  const [isUserExist, setIsUserExist] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [profileLoading, setProfileLoading] = useState(false);

  const [openCloseAddRecomondation, setOpenCloseAddRecomondation] =
    useState(false);

  const { username } = useParams();
  const location = useLocation();

  useEffect(() => {
    getWorkerProfileData();

    if (new URLSearchParams(location.search).get("status") === "true") {
      setOpenCloseAddRecomondation(true);
    }
  }, []);

  const getWorkerProfileData = async () => {
    setProfileLoading(true);

    try {
      await fetchPublicProfile({
        username: username,
      })
        .then((response) => {
          if (response?.data?.success) {
            if (response.data.output.user_exsists == 0) {
              setIsUserExist(false);
            } else {
              setProfileData(response.data.output);
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setProfileLoading(false);
    } catch (error) {
      openNotification("error", error);
      setProfileLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <div className="flex flex-col w-full justify-center items-center mt-28">
        {isUserExist ? (
          <div className="max-w-[1280px] w-full mx-2">
            {profileLoading ? (
              <div className="flex w-full justify-center items-center">
                <Spin className="flex" />
              </div>
            ) : (
              <>
                <CoverSection
                  data={profileData}
                  getWorkerProfileData={getWorkerProfileData}
                  editable={false}
                />

                <PersonalInfo
                  data={profileData}
                  getWorkerProfileData={getWorkerProfileData}
                  editable={false}
                />

                {profileData?.portfolios.length !== 0 && (
                  <WorkSampleImageList
                    workSamples={profileData?.portfolios}
                    listSize="large"
                    data={profileData}
                    getWorkerProfileData={getWorkerProfileData}
                    editable={false}
                  />
                )}

                {profileData !== null && (
                  <Services
                    data={profileData}
                    getWorkerProfileData={getWorkerProfileData}
                    editable={false}
                  />
                )}

                {profileData !== null && (
                  <AvailableIn data={profileData} editable={false} />
                )}

                {(profileData?.favorited_count !== 0 ||
                  profileData?.completed_task_count !== 0 ||
                  profileData?.rate_count !== 0) && (
                  <Review data={profileData} />
                )}
              </>
            )}
          </div>
        ) : (
          <div className="items-center">
            <div className="flex justify-center w-1/7 ">
              <UserNotFoundIcon />
            </div>
            <p className="text-center font-bold text-green-900 text-[60px]">
              Oops!
            </p>
            <h1 className="text-center font-medium text-green-800 text-lg">
              The link you entered does not match any worker profile. <br />
              Please check the URL or try searching again.
            </h1>
          </div>
        )}
      </div>

      {openCloseAddRecomondation && (
        <>
          {getLocalStoragedata("token") ? (
            <AddRecomondationLogInFlow
              open={openCloseAddRecomondation}
              onCancel={() => {
                setOpenCloseAddRecomondation(false);
              }}
              data={profileData}
            />
          ) : (
            <AddRecomondationLogOutFlow
              open={openCloseAddRecomondation}
              onCancel={() => {
                setOpenCloseAddRecomondation(false);
              }}
              data={profileData}
            />
          )}
        </>
      )}
    </>
  );
};

export default PublicProfile;
