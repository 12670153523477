import React from "react";

const PersonGrayIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.0008"
        cy="7.2"
        r="3.2"
        stroke="#939292"
        strokeWidth="1.5"
      />
      <path
        d="M18.3996 16.4008C18.3996 18.389 18.3996 20.0008 11.9996 20.0008C5.59961 20.0008 5.59961 18.389 5.59961 16.4008C5.59961 14.4126 8.46499 12.8008 11.9996 12.8008C15.5342 12.8008 18.3996 14.4126 18.3996 16.4008Z"
        stroke="#939292"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PersonGrayIcon;
