import React from "react";

const MyAccountIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.9996"
        cy="9.59922"
        r="2.4"
        stroke="#939292"
        strokeWidth="1.5"
      />
      <circle cx="12" cy="12" r="8" stroke="#939292" strokeWidth="1.5" />
      <path
        d="M16.7753 18.4004C16.648 16.0872 15.9398 14.4004 12 14.4004C8.06017 14.4004 7.35193 16.0872 7.22461 18.4004"
        stroke="#939292"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MyAccountIcon;
