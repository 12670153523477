import React, { useContext, useEffect, useState } from "react";
import WorkerSignUpLayout from "./WorkerSignUpLayout";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Slider,
  Spin,
  Tag,
  Typography,
} from "antd";
import { NotificationContext } from "../../context/NotificationContext";
import HeaderConfig from "../../helpers/HeaderConfig";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import PublicProfileService from "../../services/PublicProfileService";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";
import axios from "axios";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import SmallI from "../../assets/svg/SmallI";
import SearchServiceAreaList from "../../components/workerSignup/SearchServiceAreaList";
import LocationPInIcon from "../../assets/svg/LocationPInIcon";
import { useNavigate } from "react-router-dom";
import NotificationAlertBox from "../../components/alertBox/NotificationAlertBox";

const { Text } = Typography;

const AddServiceAreaSignUp = () => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const { configAuth } = HeaderConfig();
  const navigate = useNavigate();
  const {
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
    getRelatedSearchedPostalCode,
  } = CreateJobPostServices();
  const {
    workerFormValues,
    servicesAreaSuburbList,
    setServicesAreaSuburbList,
    selectedServicesAreaSuburbList,
    setSelectedServicesAreaSuburbList,
  } = useContext(WorkerSignUpContext);
  const { fetchAIBaseURL, addTradySuburb } = PublicProfileService();

  const [aIBaseURL, setAIBaseURL] = useState(null);

  const [location1SelectorData, setLocation1SelectorData] = useState([]);
  const [location2SelectorData, setLocation2SelectorData] = useState([]);
  const [postalCodeRequired, setPostalCodeRequired] = useState();
  const [postalCodeData, setPostalCodeData] = useState(null);
  const [allPostalCodes, setAllPostalCodes] = useState([]);
  const [isStateSelected, setIsStateSelected] = useState(false);

  // const [suburbId, setSuburbId] = useState(null);
  const [sliderValue, setSliderValue] = useState(25);

  const [loading, setLoading] = useState(false);
  const [openSearchServiceAreaList, setOpenSearchServiceAreaList] =
    useState(false);

  const [isFieldChanged, setIsFieldChanged] = useState(false);
  // const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  // const [showSelectedAreaList, setShowSelectedAreaList] = useState([]);
  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);

  const [openCloseRemoveOne, setOpenCloseRemoveOne] = useState(false);
  const [suburbToRemove, setSuburbToRemove] = useState(null);
  const [areaLoading, setAreaLoading] = useState(false);

  const [form] = Form.useForm();

  const [labels, setLabels] = useState({
    level_1_label: "State",
    level_2_label: "Suburb",
  });

  useEffect(() => {
    getAIBaseURL();
    fetchCountryData();

    if (workerFormValues?.postalCode) {
      handleGetRelatedPostalCode(workerFormValues?.postalCode);
    }

    if (workerFormValues?.country) {
      fetchLocationLevel1Data(workerFormValues?.country);
      fetchLocationLevel2Data(workerFormValues?.state);
    }
  }, []);

  // useEffect(() => {
  //   setShowSelectedAreaList(
  //     servicesAreaSuburbList?.filter((suburb) =>
  //       selectedServicesAreaSuburbList.includes(suburb.l1_id)
  //     )
  //   );
  // }, [selectedServicesAreaSuburbList]);

  const getAIBaseURL = async () => {
    try {
      await fetchAIBaseURL()
        .then((response) => {
          if (response?.data.success) {
            setAIBaseURL(response?.data.output.url);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchCountryData = async () => {
    setLoading(true);
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            let selectedCountry = response?.data?.output.find(
              (country) => country.country_id == workerFormValues?.country
            );

            setPostalCodeRequired(
              selectedCountry.postal_code_required === 1 ? true : false
            );

            setLabels({
              level_1_label: selectedCountry?.level_1_label,
              level_2_label: selectedCountry?.level_2_label,
            });

            if (selectedCountry.postal_code_required === 1) {
              handleGetRelatedPostalCode(workerFormValues?.postalCode);
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const fetchLocationLevel1Data = async (e) => {
    try {
      await getAllLocationLevel1Data({ country_id: e })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location1) => {
              let data = {
                value: location1?.level1_id,
                label: location1?.level1_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation1SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchLocationLevel2Data = async (e) => {
    try {
      await getAllLocationLevel2Data({
        country_id: workerFormValues?.country,
        level1_id: e,
      })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location2) => {
              let data = {
                value: location2?.level2_id,
                label: location2?.level2_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation2SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: workerFormValues?.country,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setAllPostalCodes(response?.data.output);

            var postalCodeObject = [];
            response?.data.output?.forEach((item) => {
              let data = {
                value: item?.suberb_id,
                label:
                  item?.postal_code + ", " + item?.suberb + ", " + item?.state,
              };
              postalCodeObject.push(data);
            });
            setPostalCodeData(postalCodeObject);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const onFinish = async (values) => {
    setAreaLoading(true);
    var zipId = 0;

    if (postalCodeRequired) {
      zipId = await allPostalCodes.find(
        (item) => item.suberb_id === values?.zip
      )?.suberb_id;
    }

    const jsnObj = {
      l1_id: postalCodeRequired
        ? zipId?.toString()
        : values?.citySuburb?.toString(),
      country_id: workerFormValues?.country,
      radius: values.radius,
    };

    try {
      await axios
        .post(aIBaseURL, jsnObj, configAuth)
        .then((response) => {
          if (response?.data.success) {
            setServicesAreaSuburbList(response.data.output);
            setOpenSearchServiceAreaList(true);
            setAreaLoading(false);
          } else {
            setAreaLoading(false);
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setAreaLoading(false);
    } catch (error) {
      setAreaLoading(false);
      handleError(error);
    }
  };

  const onSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleAreaSave = async () => {
    const dataToSave = await selectedServicesAreaSuburbList.map((suburb) => {
      return {
        state_id: suburb.l2_id,
        suburb_id: suburb.l1_id,
      };
    });

    try {
      await addTradySuburb({
        user_id: getLocalStoragedata("userId"),
        country_id: workerFormValues?.country,
        service_suburbs: dataToSave,
      })
        .then((response) => {
          if (response?.data.success) {
            openNotification("success", response.data.message);
            navigate("../worker-sign-up-success");
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const compareData = (initial, updated) => {
    let changes = {};

    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });

    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false); // Return null if no changes
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      {
        zip: null,
        stateProvince: null,
        citySuburb: null,
        radius: 25,
      },
      allFields
    );
  };

  //remove one area item
  const handleTagClose = () => {
    if (suburbToRemove) {
      setSelectedServicesAreaSuburbList((prevList) =>
        prevList.filter((item) => item?.l1_id !== suburbToRemove)
      );
      setSuburbToRemove(null);
    }
  };

  const handleTagCloseConfirmation = (suburbId) => {
    setSuburbToRemove(suburbId);
    setOpenCloseRemoveOne(true);
  };

  return (
    <WorkerSignUpLayout>
      <div className="flex flex-col gap-4 w-full sm:max-w-[800px]">
        <div className="flex flex-col gap-2">
          <p className="text-[20px] md:text-[32px] font-bold text-primaryDark">
            Search Nearby Locations
          </p>
          <p className="flex text-sm text-[#7B7B7B]">
            Search nearby Locations and choose your preferred service locations
          </p>
          {loading ? (
            <div className="flex w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            <Form
              onFinish={onFinish}
              layout="vertical"
              form={form}
              className="flex flex-col gap-2"
              initialValues={{
                radius: 25,
                stateProvince: workerFormValues?.state,
                citySuburb: workerFormValues?.suburb,
                zip: workerFormValues?.suburb,
              }}
              onFieldsChange={handleFormChange}
            >
              <div className="flex flex-col w-full gap-4">
                {/* postalCodeRequired.............................................................. */}
                {postalCodeRequired && (
                  <div className="flex flex-col w-full">
                    <Text className="font-semibold ml-2">
                      Postal Code <span className="text-red-500">*</span>
                    </Text>
                    <Form.Item
                      name="zip"
                      rules={[
                        { required: true, message: "Postal Code is required!" },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        size="large"
                        className="w-full"
                        placeholder="Enter Postal Code"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={postalCodeData}
                        notFoundContent={
                          postalCodeData === null
                            ? "Please enter your property postal code"
                            : "No Data"
                        }
                        onSearch={(e) => {
                          if (e?.length === 4) {
                            handleGetRelatedPostalCode(e);
                          }

                          if (e?.length < 4) {
                            setPostalCodeData(null);
                          }
                        }}
                        // onSelect={(e) => {
                        //   let suburb = allPostalCodes.find(
                        //     (item) => item.suberb_id === e
                        //   );

                        //   setSuburbId(suburb.suberb_id);
                        // }}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>

              {/* not postalCodeRequired.............................................................. */}
              <div className="flex flex-col w-full gap-2">
                {!postalCodeRequired && (
                  <>
                    <div className="flex flex-col w-full">
                      <Text className="font-semibold ml-2">
                        {labels.level_1_label}{" "}
                        <span className="text-red-500">*</span>
                      </Text>
                      <Form.Item
                        name="stateProvince"
                        rules={[
                          {
                            required: true,
                            message: `${labels.level_1_label} is required!`,
                          },
                        ]}
                        className="mb-0"
                      >
                        <Select
                          size="large"
                          placeholder={`Select ${labels.level_1_label}`}
                          options={location1SelectorData}
                          onSelect={(e) => {
                            fetchLocationLevel2Data(e);
                            setIsStateSelected(true);
                            // setSuburbId(null);
                            form.setFieldValue("citySuburb", null);
                          }}
                          showSearch
                          className="rounded-lg"
                          optionFilterProp="label"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </div>

                    <div className="flex flex-col w-full">
                      <Text className="font-semibold ml-2">
                        {labels.level_2_label}{" "}
                        <span className="text-red-500">*</span>
                      </Text>
                      <Form.Item
                        name="citySuburb"
                        rules={[
                          {
                            required: true,
                            message: `${labels.level_2_label} is required!`,
                          },
                        ]}
                        className="mb-0"
                      >
                        <Select
                          size="large"
                          placeholder={`Select ${labels.level_2_label}`}
                          options={location2SelectorData}
                          className="rounded-lg"
                          showSearch
                          optionFilterProp="label"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // onSelect={(e) => {
                          //   setSuburbId(e);
                          // }}
                          // disabled={!isStateSelected}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col w-full p-2 rounded-md bg-[#F0F0F0]">
                <Text className="font-semibold text-base ml-2">
                  Expand search area
                </Text>
                <Form.Item name="radius" className="mb-0">
                  <Slider
                    min={15}
                    max={50}
                    trackStyle={{ backgroundColor: "green" }}
                    railStyle={{ backgroundColor: "#B8B8B8" }}
                    handleStyle={{
                      borderColor: "green",
                      backgroundColor: "green",
                    }}
                    onChange={onSliderChange}
                    value={sliderValue}
                  />
                </Form.Item>
                <Text className="font-semibold text-base ml-2 text-[#7B7B7B]">
                  Radius : {sliderValue}
                </Text>
              </div>
              <div className="flex w-full justify-center items-center mt-4">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="font-bold text-base bg-primaryDarkest px-16 md:px-24"
                    size="large"
                    loading={areaLoading}
                  >
                    Search
                  </Button>
                </Form.Item>
              </div>
            </Form>
          )}

          {/* showSelectedAreaList................................................................................ */}
          <div className="bg-primaryLightest p-3 rounded-lg flex flex-col items-center min-h-[200px]">
            <p className="text-base font-normal text-textColorOne">
              Selected Service Locations
            </p>
            <div className="flex flex-row justify-between items-center w-full">
              <p className="flex justify-start items-center gap-2 text-xs">
                <SmallI />
                You can add up to 50 service locations
              </p>

              <p className="flex text-sm bg-slate-300 px-2 py-1 rounded-xl">
                {selectedServicesAreaSuburbList?.length}/50
              </p>
            </div>

            <div
              className="cursor-pointer flex justify-end items-end w-full my-3"
              onClick={() => {
                setOpenCloseNotificationAlertBox(true);
              }}
            >
              <p className="text-sm font-semibold text-primaryDarkest cursor-pointer">
                Clear All
              </p>
            </div>

            <div className="w-full">
              <Row className="flex flex-row" gutter={16}>
                {selectedServicesAreaSuburbList?.map((suburb, index) => {
                  return (
                    <Col className="mb-2">
                      <Tag
                        className="flex flex-row gap-1 justify-center items-center p-1 rounded-lg w-fit"
                        key={index}
                        closeIcon
                        onClose={() => {
                          handleTagCloseConfirmation(suburb?.l1_id);
                        }}
                        // onClose={() => {
                        //   setSelectedServicesAreaSuburbList(
                        //     selectedServicesAreaSuburbList?.filter(
                        //       (item) => item !== suburb?.l1_id
                        //     )
                        //   );
                        // }}
                      >
                        <LocationPInIcon />
                        <p className="text-sm text-darkestColor text-left truncate w-[150px] lg:w-[250px]">
                          {suburb?.l1_id},&nbsp;{suburb?.l1_name}
                          ,&nbsp;
                          {suburb?.l2_name}
                        </p>
                      </Tag>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>

          <div className="flex justify-center items-center w-full bottom-0 left-0 my-4">
            <Button
              className="w-48 h-12 text-base font-bold"
              type="primary"
              disabled={selectedServicesAreaSuburbList?.length === 0}
              onClick={() => {
                handleAreaSave();
              }}
            >
              Continue
            </Button>
          </div>

          {openSearchServiceAreaList && (
            <SearchServiceAreaList
              open={openSearchServiceAreaList}
              onCancel={() => {
                setOpenSearchServiceAreaList(false);
              }}
            />
          )}

          {openCloseNotificationAlertBox && (
            <NotificationAlertBox
              open={openCloseNotificationAlertBox}
              onCancel={() => {
                setOpenCloseNotificationAlertBox(false);
              }}
              title="Clear All"
              content={
                <div>
                  <p className="text-md font-medium text-darkestColor text-center">
                    Are you sure you want to clear all selected service
                    locations?
                  </p>
                  <p className="text-xs text-textColorThree text-center">
                    Please note that any selections you have made will be
                    removed.
                  </p>

                  <div className="flex justify-center items-center mt-5 gap-4">
                    <Button
                      type="default"
                      className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                      onClick={() => {
                        setOpenCloseNotificationAlertBox(false);
                      }}
                    >
                      No
                    </Button>
                    <Button
                      type="primary"
                      className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                      onClick={() => {
                        setSelectedServicesAreaSuburbList([]);
                        setOpenCloseNotificationAlertBox(false);
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              }
            />
          )}

          {openCloseRemoveOne && (
            <NotificationAlertBox
              open={openCloseRemoveOne}
              onCancel={() => {
                setOpenCloseRemoveOne(false);
              }}
              title="Clear Suburb"
              content={
                <div>
                  <p className="text-md font-medium text-darkestColor text-center">
                    Are you sure you want to clear this selected service
                    location?
                  </p>
                  <p className="text-xs text-textColorThree text-center">
                    Please note that any selections you have made will be
                    removed.
                  </p>

                  <div className="flex justify-center items-center mt-5 gap-4">
                    <Button
                      type="default"
                      className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                      onClick={() => {
                        setOpenCloseRemoveOne(false);
                      }}
                    >
                      No
                    </Button>
                    <Button
                      type="primary"
                      className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                      onClick={() => {
                        handleTagClose();
                        setOpenCloseRemoveOne(false);
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              }
            />
          )}
        </div>
      </div>
    </WorkerSignUpLayout>
  );
};

export default AddServiceAreaSignUp;
