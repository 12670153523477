import React from "react";

const PremiumWhiteIcon = ({ width, height }) => {
  return (
    <svg
      width={width || 30}
      height={height || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1552 17.2958L22.3328 15.6446C22.4275 14.7636 22.4901 14.1819 22.441 13.8153L22.4582 13.8154C23.2606 13.8154 23.9111 13.1649 23.9111 12.3625C23.9111 11.5601 23.2606 10.9096 22.4582 10.9096C21.6557 10.9096 21.0052 11.5601 21.0052 12.3625C21.0052 12.7254 21.1383 13.0572 21.3583 13.3119C21.0425 13.5068 20.6296 13.9181 20.0081 14.5372L20.008 14.5372L20.008 14.5372C19.5292 15.0141 19.2898 15.2526 19.0228 15.2895C18.8748 15.31 18.724 15.289 18.5874 15.2288C18.3408 15.1202 18.1763 14.8254 17.8475 14.2358L16.1141 11.1279C15.9113 10.7642 15.7415 10.4597 15.5884 10.2147C16.2164 9.89433 16.6465 9.2414 16.6465 8.48802C16.6465 7.41811 15.7791 6.55078 14.7092 6.55078C13.6393 6.55078 12.772 7.41811 12.772 8.48802C12.772 9.2414 13.202 9.89433 13.83 10.2147C13.6769 10.4597 13.5072 10.7641 13.3043 11.1279L11.5709 14.2358C11.2421 14.8254 11.0777 15.1202 10.8311 15.2288C10.6944 15.289 10.5436 15.31 10.3957 15.2895C10.1286 15.2526 9.88918 15.0141 9.41037 14.5372C8.78888 13.9181 8.37595 13.5068 8.06015 13.3119C8.28013 13.0572 8.41318 12.7254 8.41318 12.3625C8.41318 11.5601 7.76269 10.9096 6.96025 10.9096C6.15782 10.9096 5.50732 11.5601 5.50732 12.3625C5.50732 13.1649 6.15782 13.8154 6.96025 13.8154L6.97741 13.8153C6.92831 14.1819 6.99088 14.7636 7.08564 15.6446L7.26324 17.2958C7.36182 18.2123 7.4438 19.0844 7.54421 19.8693H21.8742C21.9746 19.0844 22.0566 18.2123 22.1552 17.2958Z"
        fill="white"
      />
      <path
        d="M13.6554 23.9859H15.763C18.5099 23.9859 19.8834 23.9859 20.7998 23.1657C21.1998 22.8077 21.4531 22.1622 21.6359 21.3222H7.78257C7.96534 22.1622 8.21862 22.8077 8.6186 23.1657C9.53501 23.9859 10.9085 23.9859 13.6554 23.9859Z"
        fill="white"
      />
    </svg>
  );
};

export default PremiumWhiteIcon;
