import HeaderConfig from "../helpers/HeaderConfig";
import axios from "axios";

const WorkerProfileService = () => {
  const { baseUrl} = HeaderConfig();

  const deleteWorkerAccount = async (data,configAuth) => {
    return await axios.post(
      baseUrl + "auth/user/delete",
      data,
      configAuth
    );
  };

  return {
    deleteWorkerAccount
  };
};

export default WorkerProfileService;
