import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import { Col, ConfigProvider, Divider, Layout, Row, Select, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CalendarOrange from "../assets/svg/CalendarOrange";
import TaskerListCard from "../components/taskerCard/TaskerListCard";
import GreenPhone from "../assets/img/greenPhone.webp";
import Typography from "antd/es/typography/Typography";
import { FaCaretDown, FaChevronDown, FaChevronUp } from "react-icons/fa";
import WhiteLogo from "../assets/svg/WhiteLogo";
import CreateJobPostServices from "../services/CreateJobPostServices";
import { NotificationContext } from "../context/NotificationContext";
import NoData from "../components/noData/NoData";
import { formatDate } from "../helpers/dateFormatter/dateFormatter";
import BackIcon from "../assets/svg/BackIcon";
import DoubleArrowBlack from "../assets/svg/DoubleArrowBlack";
import UrgentRed from "../assets/svg/UrgentRed";
import NoImage from "../assets/img/no_image_jobtype.jpg";
import PublicProfileService from "../services/PublicProfileService";

const { Text } = Typography;

const MyJobsWorkerList = () => {
  const navigate = useNavigate();
  const param = useParams();
  const [jobId, countryId] = param?.id?.split("=").slice(1);

  const { getTaskerListForJobPost } = CreateJobPostServices();
  const { getLanguageListNotAuthorize } = PublicProfileService();

  const { openNotification, handleError } = useContext(NotificationContext);
  const [footerSeeMore, setFooterSeeMore] = useState(false);
  const [taskerList, setTaskerList] = useState({
    jobDetails: "",
    topList: [],
    list: [],
  });
  const [languageItems, setLanguageItems] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  useEffect(() => {
    fetchAllLanguages();
    fetchTaskerListForJobPost(null);
  }, []);

  //get all tasker list for job post............................
  const fetchTaskerListForJobPost = async (language) => {
    setListLoading(true);
    try {
      await getTaskerListForJobPost({
        job_id: Number(jobId),
        country_id: Number(countryId), //job country
        language_id: language,
      })
        .then((response) => {
          if (response?.data?.success) {
            setTaskerList({
              jobDetails: response?.data?.output,
              topList: response?.data?.output?.lang_user_ids,
              list: response?.data?.output?.workers,
            });
          } else {
            openNotification("warning", response?.data?.message);
            setListLoading(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setListLoading(false);
        });
    } catch (error) {
      openNotification("error", error);
      setListLoading(false);
    }
    setListLoading(false);
  };

  //get all language list
  const fetchAllLanguages = async () => {
    try {
      await getLanguageListNotAuthorize({})
        .then((response) => {
          if (response?.data?.success) {
            setLanguageData(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLanguageData = (items) => {
    var languageObject = [];
    items?.forEach((item) => {
      let data = {
        key: item?.id,
        label: item?.language?.split(":")[0],
      };
      languageObject.push(data);
    });
    setLanguageItems(languageObject);
  };

  const handleSelectLanguage = async ({ key }) => {
    const languageData = await languageItems?.find((item) => {
      return item?.key == key;
    });

    fetchTaskerListForJobPost(languageData?.key);
  };

  return (
    <>
      <Layout>
        <NavBar />
        <div className="content overflow-hidden bg-gray-100">
          <div className="flex w-full justify-center items-center z-10">
            <div className="max-w-[1073px] xl:w-[1073px] w-full mx-5 mb-10">
              <div
                className="flex flex-row justify-start items-center gap-3 pt-5 md:pt-5 mb-2 cursor-pointer"
                onClick={() => {
                  navigate("../my-jobs");
                }}
              >
                <BackIcon />
                <p className="text-sm font-medium text-gray-600">Back</p>
              </div>

              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col w-full">
                  {/* Tasker list................................................... */}
                  <div>
                    <Row className="flex flex-row" gutter={8}>
                      <Col
                        span={24}
                        lg={{ span: 6 }}
                        className="sidebar-jobDetails mb-10 lg:mb-0"
                      >
                        {/* language........................................ */}
                        <div className="bg-white rounded-md p-3 mb-3">
                          <p className="text-primaryDark font-semibold text-base">
                            Preferred Language :
                          </p>
                          <div className="flex flex-row gap-2 justify-end items-end mt-2">
                            <ConfigProvider
                              theme={{
                                components: {
                                  Select: {
                                    activeBorderColor: "rgb(82,196,26)",
                                    selectorBg: "rgb(224,239,226)",
                                    colorText: "rgb(7,73,36)",
                                  },
                                },
                              }}
                            >
                              <Select
                                size="small"
                                placeholder="Select language"
                                className="w-full rounded-md bg-primaryLightest"
                                dropdownClassName="bg-primaryLightest"
                                options={languageItems.map((item) => ({
                                  value: item.key,
                                  label: item.label,
                                }))}
                                // value={language}
                                onChange={(key) =>
                                  handleSelectLanguage({ key })
                                }
                                suffixIcon={<FaCaretDown />}
                                showSearch
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </ConfigProvider>
                          </div>
                        </div>

                        <div className="bg-white rounded-md p-3">
                          <p className="text-primaryDark font-semibold text-base">
                            Job ID :
                          </p>
                          <p className="font-normal text-base">
                            {taskerList?.jobDetails?.job_number}
                          </p>

                          <Divider className="my-3" />

                          <p className="text-primaryDark font-semibold text-base">
                            Posted on :
                          </p>
                          <p className="font-normal text-base">
                            {formatDate(taskerList?.jobDetails?.posted_date)}
                          </p>

                          <Divider className="my-3" />

                          <p className="text-primaryDark font-semibold text-base">
                            Job Type :
                          </p>
                          <div className="flex flex-row gap-2 justify-start items-center">
                            <img
                              src={
                                taskerList?.jobDetails?.web_image_path ||
                                NoImage
                              }
                              width={40}
                            />
                            <p className="font-semibold text-lg">
                              {taskerList?.jobDetails?.job_type}
                            </p>
                          </div>

                          <Divider className="my-3" />

                          <p className="text-primaryDark font-semibold text-base">
                            Job Location :
                          </p>
                          <p className="font-normal text-base">
                            {taskerList?.jobDetails?.state +
                              ", " +
                              taskerList?.jobDetails?.suburb +
                              ", " +
                              taskerList?.jobDetails?.postal_code +
                              "."}
                          </p>

                          {/* <Divider className="my-3" />

                          <p className="text-primaryDark font-semibold text-base">
                            Language :
                          </p> */}
                          {/* <p className="font-normal text-base">{language}</p> */}

                          <Divider className="my-3" />

                          <p className="text-primaryDark font-semibold text-base">
                            Job Urgency :
                          </p>

                          {taskerList?.jobDetails?.job_time_id === 2 ? (
                            <div className="flex gap-1">
                              <CalendarOrange />
                              <p className="text-md font-medium text-textColorThree">
                                Within few days
                              </p>
                            </div>
                          ) : taskerList?.jobDetails?.job_time_id === 3 ? (
                            <div className="flex gap-1">
                              <DoubleArrowBlack />
                              <p className="text-md font-medium text-textColorThree">
                                I’m flexible
                              </p>
                            </div>
                          ) : (
                            <div className="flex gap-1">
                              <UrgentRed />
                              <p className="text-md font-medium text-textColorThree">
                                Urgent
                              </p>
                            </div>
                          )}

                          <Divider className="my-3" />

                          <p className="text-primaryDark font-semibold text-base">
                            Job Description :
                          </p>
                          <p className="font-normal text-base break-words break-30 overflow-hidden">
                            {taskerList?.jobDetails?.job_description ||
                              "No description"}
                          </p>
                        </div>
                      </Col>
                      <Col
                        span={24}
                        lg={{ span: 18 }}
                        className="profile-cards-container"
                      >
                        {taskerList?.list?.length === 0 ? (
                          <NoData
                            title="No Related Workers Available"
                            subTitle="Currently, there are no related workers available for this job. Please try again later or explore other categories"
                          />
                        ) : (
                          <div>
                            {listLoading ? (
                              <div className="flex justify-center items-end mt-10">
                                <Spin />
                              </div>
                            ) : (
                              <TaskerListCard
                                taskerList={taskerList?.list}
                                topTaskerList={taskerList?.topList}
                              />
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer green area............................ */}
          {/* <div className="fixed -bottom-5 left-0 w-full my-5 px-4 bg-[#2C8B52]">
            <div className="flex w-full justify-center items-center">
              <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 py-8">
                {!footerSeeMore ? (
                  <div className="text-white flex flex-col sm:flex-row gap-5">
                    <div className="hidden md:block">
                      <WhiteLogo />
                    </div>

                    <div className="flex flex-col w-full">
                      <div className="flex flex-row justify-between items-center">
                        <Text className="text-2xl md:text-4xl font-bold text-white">
                          Looking for more?
                        </Text>

                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setFooterSeeMore(true);
                          }}
                        >
                          <Text className="text-base font-medium text-white flex flex-row gap-3 justify-start items-center">
                            Show more <FaChevronUp />
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-white flex flex-col sm:flex-row gap-5">
                    <img
                      src={GreenPhone}
                      width={134}
                      height={275}
                      alt="worker app"
                      className="hidden md:block"
                    />

                    <div className="flex flex-col w-full">
                      <div className="flex flex-row justify-between items-center">
                        <Text className="text-2xl md:text-4xl font-bold text-white">
                          Looking for more?
                        </Text>

                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setFooterSeeMore(false);
                          }}
                        >
                          <Text className="text-base font-medium text-white flex flex-row gap-3 justify-start items-center">
                            Show less <FaChevronDown />
                          </Text>
                        </div>
                      </div>

                      <Text className="text-xl text-white mt-10">
                        Login via
                      </Text>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          navigate("../client");
                        }}
                      >
                        <Text className="text-2xl md:text-4xl font-bold text-white underline">
                          Login via Jobsninja Client Mobile APP
                        </Text>
                      </div>

                      <Text className="text-lg text-gray-300 mt-10">
                        View worker offers, chat, and access advanced features
                        to continue the job process.
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export default MyJobsWorkerList;

// import React, { useContext, useEffect, useState } from "react";
// import NavBar from "../components/navbar/NavBar";
// import { Col, Divider, Layout, Row } from "antd";
// import { useNavigate, useParams } from "react-router-dom";
// import CalendarOrange from "../assets/svg/CalendarOrange";
// import TaskerListCard from "../components/taskerCard/TaskerListCard";
// import GreenPhone from "../assets/img/greenPhone.webp";
// import Typography from "antd/es/typography/Typography";
// import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import WhiteLogo from "../assets/svg/WhiteLogo";
// import CreateJobPostServices from "../services/CreateJobPostServices";
// import { NotificationContext } from "../context/NotificationContext";
// import NoData from "../components/noData/NoData";
// import { formatDate } from "../helpers/dateFormatter/dateFormatter";
// import BackIcon from "../assets/svg/BackIcon";
// import DoubleArrowBlack from "../assets/svg/DoubleArrowBlack";
// import UrgentRed from "../assets/svg/UrgentRed";
// import NoImage from "../assets/img/no_image_jobtype.jpg";

// const { Text } = Typography;

// const MyJobsWorkerList = () => {
//   const navigate = useNavigate();
//   const param = useParams();
//   const [jobId, countryId] = param?.id?.split("=").slice(1);

//   const { getTaskerListForJobPost } = CreateJobPostServices();
//   const { openNotification, handleError } = useContext(NotificationContext);
//   const [footerSeeMore, setFooterSeeMore] = useState(false);
//   const [taskerList, setTaskerList] = useState({
//     jobDetails: "",
//     list: [],
//   });

//   useEffect(() => {
//     fetchTaskerListForJobPost();
//   }, []);

//   //get all tasker list for job post............................
//   const fetchTaskerListForJobPost = async () => {
//     try {
//       await getTaskerListForJobPost({
//         job_id: Number(jobId),
//         country_id: Number(countryId), //job country
//       })
//         .then((response) => {
//           if (response?.data?.success) {
//             setTaskerList({
//               jobDetails: response?.data?.output,
//               list: response?.data?.output?.workers,
//             });
//           } else {
//             openNotification("warning", response?.data?.message);
//           }
//         })
//         .catch((error) => {
//           handleError(error);
//         });
//     } catch (error) {
//       openNotification("error", error);
//     }
//   };

//   return (
//     <>
//       <Layout>
//         <NavBar />
//         <div className="content overflow-hidden bg-gray-100">
//           <div className="flex w-full justify-center items-center z-10">
//             <div className="max-w-[1073px] xl:w-[1073px] w-full mx-5 mb-10">
//               <div
//                 className="flex flex-row justify-start items-center gap-3 pt-5 md:pt-10 mb-5 cursor-pointer"
//                 onClick={() => {
//                   navigate("../my-jobs");
//                 }}
//               >
//                 <BackIcon />
//                 <p className="text-sm font-medium text-gray-600">Back</p>
//               </div>

//               <div className="flex flex-col justify-center items-center">
//                 <div className="flex flex-col w-full">
//                   {/* Tasker list................................................... */}
//                   <div>
//                     <Row className="flex flex-row" gutter={8}>
//                       <Col
//                         span={24}
//                         lg={{ span: 6 }}
//                         className="sidebar-jobDetails"
//                       >
//                         <div className="bg-white rounded-md p-3">
//                           <p className="text-primaryDark font-semibold text-base">
//                             Job ID :
//                           </p>
//                           <p className="font-normal text-base">
//                             {taskerList?.jobDetails?.job_number}
//                           </p>

//                           <Divider className="my-3" />

//                           <p className="text-primaryDark font-semibold text-base">
//                             Posted on :
//                           </p>
//                           <p className="font-normal text-base">
//                             {formatDate(taskerList?.jobDetails?.posted_date)}
//                           </p>

//                           <Divider className="my-3" />

//                           <p className="text-primaryDark font-semibold text-base">
//                             Job Type :
//                           </p>
//                           <div className="flex flex-row gap-2 justify-start items-center">
//                             <img
//                               src={
//                                 taskerList?.jobDetails?.web_image_path ||
//                                 NoImage
//                               }
//                               width={40}
//                             />
//                             <p className="font-semibold text-lg">
//                               {taskerList?.jobDetails?.job_type}
//                             </p>
//                           </div>

//                           <Divider className="my-3" />

//                           <p className="text-primaryDark font-semibold text-base">
//                             Job Location :
//                           </p>
//                           <p className="font-normal text-base">
//                             {taskerList?.jobDetails?.state +
//                               ", " +
//                               taskerList?.jobDetails?.suburb +
//                               ", " +
//                               taskerList?.jobDetails?.postal_code +
//                               "."}
//                           </p>

//                           {/* <Divider className="my-3" />

//                           <p className="text-primaryDark font-semibold text-base">
//                             Language :
//                           </p> */}
//                           {/* <p className="font-normal text-base">{language}</p> */}

//                           <Divider className="my-3" />

//                           <p className="text-primaryDark font-semibold text-base">
//                             Job Urgency :
//                           </p>

//                           {taskerList?.jobDetails?.job_time_id === 2 ? (
//                             <div className="flex gap-1">
//                               <CalendarOrange />
//                               <p className="text-md font-medium text-textColorThree">
//                                 Within few days
//                               </p>
//                             </div>
//                           ) : taskerList?.jobDetails?.job_time_id === 3 ? (
//                             <div className="flex gap-1">
//                               <DoubleArrowBlack />
//                               <p className="text-md font-medium text-textColorThree">
//                                 I’m flexible
//                               </p>
//                             </div>
//                           ) : (
//                             <div className="flex gap-1">
//                               <UrgentRed />
//                               <p className="text-md font-medium text-textColorThree">
//                                 Urgent
//                               </p>
//                             </div>
//                           )}

//                           <Divider className="my-3" />

//                           <p className="text-primaryDark font-semibold text-base">
//                             Job Description :
//                           </p>
//                           <p className="font-normal text-base break-words break-30 overflow-hidden">
//                             {taskerList?.jobDetails?.job_description ||
//                               "No description"}
//                           </p>
//                         </div>
//                       </Col>
//                       <Col
//                         span={24}
//                         lg={{ span: 18 }}
//                         className="profile-cards-container"
//                       >
//                         {taskerList?.list?.length === 0 ? (
//                           <NoData
//                             title="No Related Workers Available"
//                             subTitle="Currently, there are no related workers available for this job. Please try again later or explore other categories"
//                           />
//                         ) : (
//                           <div className="mb-28">
//                             <TaskerListCard taskerList={taskerList?.list} />
//                           </div>
//                         )}
//                       </Col>
//                     </Row>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Footer green area............................ */}
//           <div className="fixed -bottom-5 left-0 w-full my-5 px-4 bg-[#2C8B52]">
//             <div className="flex w-full justify-center items-center">
//               <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 py-8">
//                 {!footerSeeMore ? (
//                   <div className="text-white flex flex-col sm:flex-row gap-5">
//                     <div className="hidden md:block">
//                       <WhiteLogo />
//                     </div>

//                     <div className="flex flex-col w-full">
//                       <div className="flex flex-row justify-between items-center">
//                         <Text className="text-2xl md:text-4xl font-bold text-white">
//                           Looking for more?
//                         </Text>

//                         <div
//                           className="cursor-pointer"
//                           onClick={() => {
//                             setFooterSeeMore(true);
//                           }}
//                         >
//                           <Text className="text-base font-medium text-white flex flex-row gap-3 justify-start items-center">
//                             Show more <FaChevronUp />
//                           </Text>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="text-white flex flex-col sm:flex-row gap-5">
//                     <img
//                       src={GreenPhone}
//                       width={134}
//                       height={275}
//                       alt="worker app"
//                       className="hidden md:block"
//                     />

//                     <div className="flex flex-col w-full">
//                       <div className="flex flex-row justify-between items-center">
//                         <Text className="text-2xl md:text-4xl font-bold text-white">
//                           Looking for more?
//                         </Text>

//                         <div
//                           className="cursor-pointer"
//                           onClick={() => {
//                             setFooterSeeMore(false);
//                           }}
//                         >
//                           <Text className="text-base font-medium text-white flex flex-row gap-3 justify-start items-center">
//                             Show less <FaChevronDown />
//                           </Text>
//                         </div>
//                       </div>

//                       <Text className="text-xl text-white mt-10">
//                         Login via
//                       </Text>
//                       <div
//                         className="cursor-pointer"
//                         onClick={() => {
//                           navigate("../client");
//                         }}
//                       >
//                         <Text className="text-2xl md:text-4xl font-bold text-white underline">
//                           Login via Jobsninja Client Mobile APP
//                         </Text>
//                       </div>

//                       <Text className="text-lg text-gray-300 mt-10">
//                         View worker offers, chat, and access advanced features
//                         to continue the job process.
//                       </Text>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </Layout>
//     </>
//   );
// };

// export default MyJobsWorkerList;
