import { createContext, useState } from "react";

export const QuickSignupContext = createContext({});

export function QuickSignupContextProvider({ children }) {
  const [stepperClientCurrentSteps, setStepperClientCurrentSteps] = useState(0);
  const [formValues, setFormValues] = useState({
    name: null,
    country: null,
    postalCode: null,
    state: null,
    suburb: null,
    email: null,
    phoneNumber: null,
    dialCode: null,
    countryCode: null,
    isPostalCodeRequired: 0,
    isEmailRequired: 0,
  });
  return (
    <QuickSignupContext.Provider
      value={{
        stepperClientCurrentSteps,
        setStepperClientCurrentSteps,

        formValues,
        setFormValues,
      }}
    >
      {children}
    </QuickSignupContext.Provider>
  );
}
