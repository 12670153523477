import { Image, Typography } from "antd";
import DropDownIconRight from "../../assets/svg/DropDownIconRight";
import { useState } from "react";
import { AddJobTypes } from "./servicesEditSteps/AddJobTypes";

const { Text } = Typography;

export const AddCategoryCard = ({ data, onComplete }) => {
  const [selectedCategory, setSelectedCategory] = useState(false);

  return (
    <>
      <div
        className="flex flex-col items-center justify-center bg-[#F2F2F2] rounded-lg mt-2 w-full p-2 h-16 cursor-pointer"
        onClick={() => {
          setSelectedCategory(true);
        }}
      >
        <div className="flex flex-row gap-2 justify-between w-full items-center">
          <div className="flex flex-row gap-4 items-center pl-3">
            <Image
              src={data?.icon_image}
              width={50}
              height={50}
              preview={false}
            />
            <div className="flex flex-col">
              <Text className="text-base font-bold">{data?.job_category}</Text>
            </div>
          </div>
          <div className="xxsm:w-30 sm:w-40 flex justify-end pr-4">
            <DropDownIconRight />
          </div>
        </div>
      </div>

      {selectedCategory && (
        <AddJobTypes
          open={selectedCategory}
          onClose={() => {
            setSelectedCategory(false);
          }}
          jobType={data}
          onComplete={onComplete}
        />
      )}
    </>
  );
};
