import React from "react";

const PremiumBlueIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.834961"
        width="19.33"
        height="19.33"
        rx="9.665"
        fill="#1D66F6"
      />
      <path
        d="M13.8592 11.6412L13.9593 10.7111C14.0127 10.2149 14.0479 9.88723 14.0202 9.68077L14.0299 9.68083C14.4819 9.68083 14.8483 9.31442 14.8483 8.86243C14.8483 8.41045 14.4819 8.04404 14.0299 8.04404C13.5779 8.04404 13.2115 8.41045 13.2115 8.86243C13.2115 9.06685 13.2865 9.25376 13.4104 9.39719C13.2325 9.50698 12.9999 9.73865 12.6498 10.0874L12.6498 10.0874C12.3801 10.356 12.2453 10.4903 12.0948 10.5111C12.0115 10.5227 11.9266 10.5108 11.8496 10.4769C11.7107 10.4158 11.6181 10.2497 11.4328 9.91759L10.4565 8.16702C10.3422 7.96214 10.2466 7.79065 10.1604 7.65266C10.5141 7.47219 10.7563 7.10441 10.7563 6.68006C10.7563 6.07741 10.2678 5.58887 9.66514 5.58887C9.0625 5.58887 8.57395 6.07741 8.57395 6.68006C8.57395 7.10441 8.81619 7.47219 9.16993 7.65266C9.08369 7.79066 8.98806 7.96212 8.87379 8.16702L7.89745 9.91759C7.71222 10.2497 7.6196 10.4158 7.4807 10.4769C7.40373 10.5108 7.3188 10.5227 7.23545 10.5111C7.08501 10.4903 6.95016 10.356 6.68047 10.0874C6.3304 9.73867 6.09781 9.50698 5.91993 9.39719C6.04384 9.25376 6.11878 9.06685 6.11878 8.86243C6.11878 8.41045 5.75237 8.04404 5.30039 8.04404C4.8484 8.04404 4.48199 8.41045 4.48199 8.86243C4.48199 9.31442 4.8484 9.68083 5.30039 9.68083L5.31005 9.68077C5.28239 9.88723 5.31764 10.2149 5.37101 10.7111L5.47105 11.6412C5.52658 12.1575 5.57275 12.6487 5.62931 13.0908H13.701C13.7575 12.6487 13.8037 12.1575 13.8592 11.6412Z"
        fill="white"
      />
      <path
        d="M9.07157 15.4096H10.2587C11.806 15.4096 12.5796 15.4096 13.0958 14.9475C13.3211 14.7459 13.4638 14.3823 13.5667 13.9092H5.76357C5.86652 14.3823 6.00919 14.7459 6.23449 14.9475C6.75067 15.4096 7.5243 15.4096 9.07157 15.4096Z"
        fill="white"
      />
    </svg>
  );
};

export default PremiumBlueIcon;
