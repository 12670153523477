import React from "react";

const LinkedIn = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1953 0H6.62849C3.01282 0 0.0817413 2.93109 0.0817413 6.54676V30.1136C0.0817413 33.7292 3.01282 36.6603 6.62849 36.6603H30.1953C33.811 36.6603 36.7421 33.7292 36.7421 30.1136V6.54676C36.7421 2.93109 33.811 0 30.1953 0Z"
        fill="#00B4EA"
      />
      <path
        d="M13.9017 14.2373H10.1066V26.4883H13.9017V14.2373Z"
        fill="white"
      />
      <path
        d="M11.9775 12.6218C12.4267 12.6264 12.8672 12.4973 13.2428 12.2509C13.6184 12.0045 13.9123 11.652 14.0871 11.2382C14.2618 10.8243 14.3095 10.3679 14.2241 9.92682C14.1387 9.48579 13.9241 9.0801 13.6076 8.76136C13.291 8.44262 12.8868 8.22522 12.4464 8.13682C12.0059 8.04841 11.5492 8.09299 11.1341 8.26489C10.7191 8.43678 10.3645 8.72823 10.1156 9.10216C9.86663 9.47609 9.7345 9.91562 9.73601 10.3648C9.73499 10.6602 9.79219 10.9529 9.90428 11.2262C10.0164 11.4995 10.1812 11.748 10.3893 11.9576C10.5975 12.1671 10.8449 12.3337 11.1174 12.4477C11.3899 12.5616 11.6822 12.6208 11.9775 12.6218Z"
        fill="white"
      />
      <path
        d="M19.9622 20.0578C19.9622 18.3341 20.7351 17.3061 22.281 17.3061C23.6722 17.3061 24.3447 18.2955 24.3447 20.0578V26.4886H28.1166V18.7592C28.1166 15.4743 26.2538 13.882 23.6568 13.882C22.9195 13.8715 22.1921 14.0526 21.5459 14.4076C20.8996 14.7626 20.3566 15.2793 19.9699 15.9071V14.2608H16.3293V26.5118H19.9699L19.9622 20.0578Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedIn;
