import { Button, Divider, Drawer } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../assets/svg/CloseIcon";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentCheckoutForm from "./PaymentCheckoutForm";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import PersonIconGreenOutline from "../../assets/svg/PersonIconGreenOutline";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { AuthContext } from "../../context/AuthContext";
import WorkDoneIcon from "../../assets/svg/WorkDoneIcon";
import WorkFailIcon from "../../assets/svg/WorkFailIcon";
import CountryIcon from "../../assets/svg/CountryIcon";
import EditIcon from "../../assets/svg/EditIcon";

const PackageSubscription = ({ open, onClose }) => {
  const stripePromise = loadStripe(
    "pk_test_51NabmjGJefYAl3ZY8E17ATmmnG3qSmBHOCgbxo8rO9cApzqEdFEAGBPrSbXbA5RBe45sWXAzL0SIpSAFIZUJ0KG900VJxTgJVG"
  );
  let {
    currentPackageDetails,
    paymentSucessStatus,
    setPaymentSuccessStatus,

    changedBillingAddres,

    setOpenCloseChangeBillingAddress,
  } = useContext(SubscriptionContext);

  let { currentUserData } = useContext(AuthContext);

  useEffect(() => {
    setPaymentSuccessStatus("status");
  }, []);

  return (
    <Drawer open={open} onClose={onClose} width={"large"} closeIcon={false}>
      <div className="w-full min-w-[260px] sm:w-[500px] md:w-[700px]">
        <div className="flex flex-row justify-between items-center mb-5">
          <p className="text-base font-bold text-textColorOne text-center">
            Payment & Subscription
          </p>

          <div
            className="cursor-pointer"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon color="#4d5056" />
          </div>
        </div>

        {paymentSucessStatus === "successed" ? (
          <div className="flex flex-col justify-center items-center">
            <WorkDoneIcon />

            <p className="text-base md:text-lg font-bold text-textColorOne text-center mt-5 max-w-[400px] w-full">
              Congratulations! You have successfully purchased monthly
              subscription
            </p>

            <p className="text-xs font-normal text-textColorTwo mt-3">
              New leads are waiting. Start applying now!
            </p>

            <Button
              type="primary"
              size="large"
              className="bg-primaryDarkest w-full max-w-[400px] mt-8"
              onClick={() => {
                onClose();
              }}
            >
              Done
            </Button>
          </div>
        ) : paymentSucessStatus === "failed" ? (
          <div className="flex flex-col justify-center items-center">
            <WorkFailIcon />

            <p className="text-base md:text-lg font-bold text-textColorOne text-center mt-5 max-w-[400px] w-full">
              We’re sorry, but your purchase of monthly subscription was not
              successful
            </p>

            <p className="text-xs font-normal text-textColorTwo mt-3">
              Your bank details may be wrong or try with another card
            </p>

            <Button
              type="primary"
              size="large"
              className="bg-primaryDarkest w-full max-w-[400px] mt-8"
              onClick={() => {
                setPaymentSuccessStatus("status");
              }}
            >
              Back to Billing
            </Button>
          </div>
        ) : (
          <div>
            <div className="px-1 md:px-5">
              <div className="w-full flex flex-col justify-center items-center">
                <div className="border-[2px] mt-5 p-3 rounded-lg max-w-[400px] w-full">
                  <p className="text-sm font-normal text-textColorTwo">
                    Package Details
                  </p>

                  <div className="w-full mt-5">
                    <div className="flex flex-row justify-between text-sm font-normal">
                      <p>Package</p>
                      <p>{currentPackageDetails?.premium_package_name}</p>
                    </div>

                    <div className="flex flex-row justify-between text-sm font-normal">
                      <p>Package price</p>
                      <p className="text-sm md:text-base font-medium">
                        {currentPackageDetails?.premium_package_price}{" "}
                        {currentPackageDetails?.premium_currency_label}
                      </p>
                    </div>

                    <div className="flex flex-row justify-between text-sm font-normal">
                      <p>Package duration</p>
                      <p className="text-sm md:text-base font-medium">
                        {(currentPackageDetails?.total_day_count / 30)?.toFixed(
                          0
                        )}{" "}
                        Month
                      </p>
                    </div>

                    <Divider className="my-2 bg-textColorTwo" />

                    <div className="flex flex-row justify-between text-sm md:text-base font-normal">
                      <p>Final Price</p>
                      <p className="text-base md:text-xl font-medium">
                        {currentPackageDetails?.premium_package_price}{" "}
                        {currentPackageDetails?.premium_currency_label}
                      </p>
                    </div>

                    <Divider className="my-2 bg-textColorTwo" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col justify-center items-center">
              <div className="max-w-[400px] w-full p-2 mt-5">
                <div className="flex flex-row justify-between">
                  <div>
                    <p className="text-sm font-medium flex flex-row justify-start items-center gap-1">
                      <PersonIconGreenOutline /> Full Name
                    </p>
                    <p className="text-sm font-normal text-textColorTwo">
                      {decryptSecureData(currentUserData?.first_name)}
                    </p>
                  </div>

                  <Divider
                    type="vertical"
                    className="h-6 bg-textColorThree my-2"
                  />

                  <div>
                    <p className="text-sm font-medium flex flex-row justify-start items-center gap-1">
                      <CountryIcon /> Country
                    </p>
                    <p className="text-sm font-normal text-textColorTwo">
                      {currentUserData?.country}
                    </p>
                  </div>
                </div>

                <div className="mt-5">
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-sm font-medium flex flex-row justify-start items-center gap-1">
                      <LocationIconCard /> Billing address
                    </p>

                    <div
                      onClick={() => {
                        setOpenCloseChangeBillingAddress(true);
                      }}
                    >
                      <EditIcon />
                    </div>
                  </div>
                  <p className="text-sm font-normal text-textColorTwo">
                    <p className="text-sm font-normal text-textColorTwo">
                      {[
                        decryptSecureData(changedBillingAddres?.addressline01),
                        decryptSecureData(changedBillingAddres?.addressline02),
                        changedBillingAddres?.stateorprovince,
                        changedBillingAddres?.cityorsuburb,
                      ]
                        .filter(Boolean)
                        .join(", ")}{" "}
                    </p>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center">
              <div className="max-w-[400px] w-full p-2 border-[2px] mt-5 rounded-lg">
                <Elements stripe={stripePromise}>
                  <PaymentCheckoutForm />
                </Elements>
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default PackageSubscription;
