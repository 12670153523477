import React from "react";

const LockIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8.36719H5C4.44772 8.36719 4 8.8149 4 9.36719V16.3672C4 16.9195 4.44772 17.3672 5 17.3672H15C15.5523 17.3672 16 16.9195 16 16.3672V9.36719C16 8.8149 15.5523 8.36719 15 8.36719Z"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.36719V5.36719C6 3.52624 7.56701 2.36719 9.5 2.36719C11.433 2.36719 13 3.52624 13 5.36719V5.86719"
        stroke="#2C8B52"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.3672V13.2005"
        stroke="#2C8B52"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
