import React from "react";

const LocationIconFull = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50007 2.66663C6.14365 2.66663 4.2334 4.80134 4.2334 7.19996C4.2334 9.57979 5.59517 12.1666 7.71983 13.1597C8.21513 13.3912 8.785 13.3912 9.2803 13.1597C11.405 12.1666 12.7667 9.57978 12.7667 7.19996C12.7667 4.80134 10.8565 2.66663 8.50007 2.66663ZM8.50007 7.99996C9.08917 7.99996 9.56673 7.5224 9.56673 6.93329C9.56673 6.34419 9.08917 5.86663 8.50007 5.86663C7.91096 5.86663 7.4334 6.34419 7.4334 6.93329C7.4334 7.5224 7.91096 7.99996 8.50007 7.99996Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default LocationIconFull;
