import React from "react";

const SuccessIcon = () => {
  return (
    <svg
      width="94"
      height="82"
      viewBox="0 0 94 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="46.702" cy="47.3079" r="25.1595" fill="#2C8B52" />
      <path
        d="M42.9845 56.43L34.3364 47.7819L36.4985 45.6199L42.9845 52.106L56.9049 38.1855L59.067 40.3476L42.9845 56.43Z"
        fill="white"
      />
      <path
        d="M7.15915 24.6763C7.97834 28.005 10.6167 30.5797 13.9644 31.3172L14.2055 31.3704L13.9644 31.4235C10.6167 32.1611 7.97834 34.7358 7.15915 38.0644C6.33997 34.7358 3.70161 32.1611 0.353921 31.4235L0.112793 31.3704L0.353918 31.3172C3.70161 30.5797 6.33997 28.005 7.15915 24.6763Z"
        fill="#2C8B52"
      />
      <path
        d="M21.5414 72.4675C22.1033 74.7506 23.9129 76.5164 26.209 77.0223L26.3744 77.0588L26.209 77.0952C23.9129 77.6011 22.1033 79.367 21.5414 81.6501C20.9796 79.367 19.17 77.6011 16.8739 77.0952L16.7085 77.0588L16.8739 77.0223C19.17 76.5164 20.9796 74.7506 21.5414 72.4675Z"
        fill="#2C8B52"
      />
      <path
        d="M52.7182 0C53.683 3.92029 56.7903 6.95256 60.733 7.82126L61.017 7.88383L60.733 7.94639C56.7903 8.81509 53.683 11.8474 52.7182 15.7677C51.7534 11.8474 48.6461 8.81509 44.7034 7.9464L44.4194 7.88383L44.7034 7.82126C48.6461 6.95256 51.7534 3.92029 52.7182 0Z"
        fill="#2C8B52"
      />
      <path
        d="M87.9738 38.0644C88.6613 40.858 90.8755 43.0188 93.6851 43.6378L93.8875 43.6824L93.6851 43.727C90.8755 44.346 88.6613 46.5068 87.9738 49.3004C87.2863 46.5068 85.072 44.346 82.2624 43.727L82.0601 43.6824L82.2624 43.6378C85.072 43.0188 87.2863 40.858 87.9738 38.0644Z"
        fill="#2C8B52"
      />
      <path
        d="M71.8598 67.9986C72.4067 70.2208 74.1681 71.9396 76.4029 72.432L76.5639 72.4675L76.4029 72.5029C74.1681 72.9953 72.4067 74.7142 71.8598 76.9363C71.313 74.7142 69.5516 72.9953 67.3167 72.5029L67.1558 72.4675L67.3167 72.432C69.5516 71.9396 71.313 70.2208 71.8598 67.9986Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default SuccessIcon;
