import { AuthContextProvider } from "./context/AuthContext";
import { CreateJobPostContextProvider } from "./context/CreateJobPostContext";
import { MyPublicProfileContextProvider } from "./context/MyPublicProfileContext";
import { NotificationProvider } from "./context/NotificationContext";
import { PopularJobContextProvider } from "./context/PopularJobContext";
import { QuickSignupContextProvider } from "./context/QuickSignUpContext";
import { SubscriptionContextProvider } from "./context/SubscriptionContext";
import { WorkerSignUpContextProvider } from "./context/WorkerSignUpContext";
import RouterSet from "./routers/RouterSet";

function App() {
  return (
    <NotificationProvider>
      <AuthContextProvider>
        <CreateJobPostContextProvider>
          <SubscriptionContextProvider>
            <PopularJobContextProvider>
              <QuickSignupContextProvider>
                <WorkerSignUpContextProvider>
                  <MyPublicProfileContextProvider>
                    <RouterSet />
                  </MyPublicProfileContextProvider>
                </WorkerSignUpContextProvider>
              </QuickSignupContextProvider>
            </PopularJobContextProvider>
          </SubscriptionContextProvider>
        </CreateJobPostContextProvider>
      </AuthContextProvider>
    </NotificationProvider>
  );
}

export default App;
