import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const DeleteAccountSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center mt-10">
      <div className="flex flex-col justify-center shadow-lg rounded-lg w-full md:w-[500px] bg-primaryLightest p-8">
        <div className="flex flex-col justify-center items-center">
          <svg
            width="108"
            height="107"
            viewBox="0 0 108 107"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="53.916" cy="53.5003" r="53.2434" fill="#2C8B52" />
            <path
              d="M46.0489 72.8049L27.7474 54.5035L32.3228 49.9281L46.0489 63.6542L75.5077 34.1953L80.0831 38.7707L46.0489 72.8049Z"
              fill="white"
            />
          </svg>

          <p className="text-lg font-bold my-4">
            Your account has been deleted permanently!
          </p>
          <p className="text-base font-normal text-center">
            Your previous data and settings have been cleared. You no longer
            have an active account. To continue using our service, please create
            a new account.
          </p>

          <Button
            type="primary"
            size="large"
            className="bg-primaryDarkest w-full max-w-[400px] mt-8"
            onClick={() => {
              navigate("../");
            }}
          >
            Back to Homepage
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountSuccess;
