import React from "react";

const DeleteGrayOutline = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6664 6.3457H4.33301"
        stroke="#939292"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M14.5554 8.01172L14.2488 12.6111C14.1308 14.3811 14.0718 15.266 13.4952 15.8055C12.9185 16.3451 12.0315 16.3451 10.2577 16.3451H9.7421C7.96824 16.3451 7.0813 16.3451 6.50463 15.8055C5.92796 15.266 5.86896 14.3811 5.75096 12.6111L5.44434 8.01172"
        stroke="#939292"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M8.33301 9.67969L8.66634 13.013"
        stroke="#939292"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M11.6663 9.67969L11.333 13.013"
        stroke="#939292"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M6.33301 6.3457C6.37026 6.3457 6.38889 6.3457 6.40577 6.34528C6.95474 6.33136 7.43902 5.98231 7.62582 5.46592C7.63156 5.45003 7.63746 5.43236 7.64924 5.39702L7.71396 5.20285C7.76921 5.03709 7.79684 4.95421 7.83348 4.88384C7.97968 4.60308 8.25017 4.40813 8.56275 4.35821C8.64109 4.3457 8.72846 4.3457 8.90318 4.3457H11.0962C11.2709 4.3457 11.3583 4.3457 11.4366 4.35821C11.7492 4.40813 12.0197 4.60308 12.1659 4.88384C12.2025 4.95421 12.2301 5.03709 12.2854 5.20285L12.3501 5.39702C12.3619 5.43231 12.3678 5.45004 12.3735 5.46592C12.5603 5.98231 13.0446 6.33136 13.5936 6.34528C13.6105 6.3457 13.6291 6.3457 13.6663 6.3457"
        stroke="#939292"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default DeleteGrayOutline;
