import React, { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "antd";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import { AuthContext } from "../../context/AuthContext";
import HeaderConfig from "../../helpers/HeaderConfig";
import SubscriptionServices from "../../services/SubscriptionServices";

const PaymentCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState(null);

  let { openNotification, handleError } = useContext(NotificationContext);
  let {
    setPaymentSuccessStatus,
    changedBillingAddres,
    setCurrentPackageDetails,
  } = useContext(SubscriptionContext);
  let { currentUserData } = useContext(AuthContext);

  const { configAuth } = HeaderConfig();
  const {
    getSubscriptionDetails,
    getSubscriptionDetailsWeb,
    getCheckoutPayment,
  } = SubscriptionServices();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    // Get the card element from Stripe Elements
    const cardElement = elements.getElement(CardElement);

    // Create a payment method using the card details
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
      // Send paymentMethod.id to your server to create a subscription
      //success
      await createSubscription(paymentMethod.id);
    }
  };

  const createSubscription = async (paymentMethodId) => {
    try {
      await getCheckoutPayment(configAuth, {
        country_id: currentUserData?.country_id,
        user_id: getLocalStoragedata("userData")?.user_id,
        payment_type: 1,
        payment_id: paymentMethodId,

        billing_country_id: changedBillingAddres?.country,
        address_1: changedBillingAddres?.addressline01,
        address_2: changedBillingAddres?.addressline02,
        postal_code: changedBillingAddres?.postalCode,
        city: changedBillingAddres?.cityorsuburb,
        state: changedBillingAddres?.stateorprovince,
      })
        .then((response) => {
          if (response?.data?.success) {
            setPaymentSuccessStatus("successed");
            handleGetSubscriptionDetails();
          } else {
            setPaymentSuccessStatus("failed");
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  const handleGetSubscriptionDetails = async () => {
    try {
      await getSubscriptionDetailsWeb(configAuth, {
        trady_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            setCurrentPackageDetails(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-sm font-normal text-textColorTwo mb-5">
        Please enter your card details
      </p>
      <CardElement
        onChange={(e) => {
          setError(e?.error?.message);
        }}
      />
      <div className="flex justify-center items-center mt-10 mb-2">
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="bg-primaryDarkest w-full"
          disabled={!stripe}
          loading={loading}
        >
          Pay Now
        </Button>
      </div>

      {error && <div style={{ color: "red" }}>{error}</div>}
      {/* {message && <div style={{ color: "green" }}>{message}</div>} */}
    </form>
  );
};

export default PaymentCheckoutForm;
