import { createContext } from "react";

export const PopularJobContext = createContext({});

export function PopularJobContextProvider({ children }) {
  return (
    <PopularJobContext.Provider value={{}}>
      {children}
    </PopularJobContext.Provider>
  );
}
