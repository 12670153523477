import HeaderConfig from "../helpers/HeaderConfig";
import axios from "axios";

const PublicProfileService = () => {
  const { baseUrl, configAuth, configHeader } = HeaderConfig();

  const fetchPublicWorkerProfile = async (data) => {
    return await axios.post(
      baseUrl + "worker/public/profile",
      data,
      configAuth
    );
  };
  const fetchPublicProfile = async (data) => {
    return await axios.post(
      baseUrl + "worker/profile/public",
      data,
      configHeader
    );
  };

  const fileUpload = async (data) => {
    return await axios.post(
      "https://us-central1-jobninja-staging.cloudfunctions.net/uploadFile",
      data,
      configAuth
    );
  };

  const updateSkillExperience = async (data) => {
    return await axios.post(
      baseUrl + "worker/experience/manage",
      data,
      configAuth
    );
  };

  const usernameCheck = async (data) => {
    return await axios.post(baseUrl + "username/check", data, configAuth);
  };

  const changeUsername = async (data) => {
    return await axios.post(baseUrl + "username/change", data, configAuth);
  };

  const cvUpload = async (data, config) => {
    return await axios.post(
      "https://us-central1-jobninja-staging.cloudfunctions.net/uploadFile",
      data,
      config
    );
  };

  const cvUrlUpdate = async (data) => {
    return await axios.post(baseUrl + "trady/resume/upload", data, configAuth);
  };

  const updateProfile = async (data) => {
    return await axios.post(baseUrl + "user/image/manage", data, configAuth);
  };

  const updateCover = async (data) => {
    return await axios.post(baseUrl + "cover/image/manage", data, configAuth);
  };

  const updatePersonalData = async (data) => {
    return await axios.post(
      baseUrl + "worker/profile/manage",
      data,
      configAuth
    );
  };

  const getLanguageList = async (data) => {
    return await axios.post(baseUrl + "language/all", data, configAuth);
  };

  const getLanguageListNotAuthorize = async (data) => {
    return await axios.post(baseUrl + "language/web", data, configHeader);
  };

  const languageUpdate = async (data) => {
    return await axios.post(baseUrl + "tasker/language/add", data, configAuth);
  };

  const requestVerification = async (data) => {
    return await axios.post(baseUrl + "verification/submit", data, configAuth);
  };

  const workSampleUpload = async (data) => {
    return await axios.post(
      "https://us-central1-jobninja-staging.cloudfunctions.net/uploadFile",
      data,
      configAuth
    );
  };

  const savePortfolio = async (data) => {
    return await axios.post(baseUrl + "trady/portfolio/add", data, configAuth);
  };

  const updatePortfolio = async (data) => {
    return await axios.post(baseUrl + "trady/portfolio/edit", data, configAuth);
  };

  const workerServiceList = async (data) => {
    return await axios.post(baseUrl + "trady/job/type/web", data, configAuth);
  };

  const allCategoryList = async (data) => {
    return await axios.post(baseUrl + "job/category/all", data, configAuth);
  };

  const allJobTypes = async (data) => {
    return await axios.post(baseUrl + "job/type/all", data, configAuth);
  };

  const addJobType = async (data) => {
    return await axios.post(baseUrl + "trady/job/type/add", data, configAuth);
  };

  const fetchAllUOM = async (data) => {
    return await axios.post(baseUrl + "uom/all", data, configAuth);
  };

  const fetchAllServiceAreas = async (data) => {
    return await axios.post(baseUrl + "tasker/service/level", data, configAuth);
  };

  const editJobType = async (data) => {
    return await axios.post(baseUrl + "trady/job/type/edit", data, configAuth);
  };

  const getAvailableIns = async (data) => {
    return await axios.post(baseUrl + "trady/suburb/web", data, configAuth);
  };

  const getWorkingAreas = async (data) => {
    return await axios.post(baseUrl + "worker/suburb/web", data, configAuth);
  };

  const fetchAIBaseURL = async () => {
    return await axios.post(baseUrl + "nearme/url", configAuth);
  };

  const removeAvailableIn = async (data) => {
    return await axios.post(baseUrl + "trady/suburb/delete", data, configAuth);
  };

  const removeSample = async (data) => {
    return await axios.post(
      baseUrl + "trady/portfolio/delete",
      data,
      configAuth
    );
  };

  const deleteJobType = async (data) => {
    return await axios.post(
      baseUrl + "trady/job/type/status",
      data,
      configAuth
    );
  };

  const addTradySuburb = async (data) => {
    return await axios.post(baseUrl + "worker/suburb/submit", data, configAuth);
  };

  const addRecomondation = async (data) => {
    return await axios.post(baseUrl + "recommendation/add", data, configHeader);
  };

  const getRecomondationKonwsArray = async (data) => {
    return await axios.post(
      baseUrl + "recommendation/data",
      data,
      configHeader
    );
  };

  const getRecomondationOTP = async (data) => {
    return await axios.post(
      baseUrl + "recommendation/otp/send",
      data,
      configHeader
    );
  };

  const verifyRecomondationOTP = async (data) => {
    return await axios.post(
      baseUrl + "recommendation/otp/validate",
      data,
      configHeader
    );
  };

  const getprofileCompletionInfo = async (data) => {
    return await axios.post(
      baseUrl + "user/completion/check",
      data,
      configAuth
    );
  };

  return {
    fetchPublicWorkerProfile,
    fileUpload,
    updateSkillExperience,
    usernameCheck,
    changeUsername,
    cvUpload,
    cvUrlUpdate,
    updateProfile,
    updateCover,
    updatePersonalData,
    getLanguageList,
    languageUpdate,
    requestVerification,
    workSampleUpload,
    savePortfolio,
    updatePortfolio,
    workerServiceList,
    allCategoryList,
    allJobTypes,
    addJobType,
    fetchAllUOM,
    fetchAllServiceAreas,
    editJobType,
    getAvailableIns,
    fetchAIBaseURL,
    removeAvailableIn,
    fetchPublicProfile,
    removeSample,
    deleteJobType,
    addTradySuburb,
    getLanguageListNotAuthorize,

    addRecomondation,
    getRecomondationKonwsArray,
    getRecomondationOTP,
    verifyRecomondationOTP,
    getWorkingAreas,
    getprofileCompletionInfo,
  };
};

export default PublicProfileService;
