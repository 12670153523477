import React, { useContext, useEffect, useState } from "react";
import BackIcon from "../../assets/svg/BackIcon";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Dropdown,
  Row,
  Select,
  Spin,
} from "antd";
import { FaCaretDown } from "react-icons/fa";
import NoData from "../../components/noData/NoData";
import TaskerListCard from "../../components/taskerCard/TaskerListCard";
import { NotificationContext } from "../../context/NotificationContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import PublicProfileService from "../../services/PublicProfileService";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { useNavigate } from "react-router-dom";
import TaskerListCardAboutSearchResult from "../../components/taskerCard/TaskerListCardAboutSearchResult";
import WorkerSelectedSearchListForJobPost from "../../components/alertBox/WorkerSelectedSearchListForJobPost";
import CalendarOrange from "../../assets/svg/CalendarOrange";
import DoubleArrowBlack from "../../assets/svg/DoubleArrowBlack";
import UrgentRed from "../../assets/svg/UrgentRed";
import HoorayBox from "../../components/alertBox/HoorayBox";
import NoImage from "../../assets/img/no_image_jobtype.jpg";
import BackIconNew from "../../assets/svg/BackIconNew";

const WorkerSearchResultListAlreadyLogin = () => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const {
    setLoginJobPostSteps,
    setStepperCurrentSteps,
    selectedJobDetailsData,
    selectedJobType,

    locationSearchValue,
    setLocationSearchValue,

    selectedTaskerArrayForJobPost,
    setSelectedTaskerArrayForJobPost,
  } = useContext(CreateJobPostContext);
  const navigate = useNavigate();

  const { getTaskerSearchListForJobPost } = CreateJobPostServices();
  const { getLanguageListNotAuthorize } = PublicProfileService();

  const [language, setLanguage] = useState("All");
  const [languageItems, setLanguageItems] = useState([]);
  const [searchTaskerList, setSearchTaskerList] = useState([]);
  const [searchSelectedTaskerList, serSearchSelectedTaskerList] = useState([]);
  const [openCloseSelectedTaskerList, setOpenCloseSelectedTaskerList] =
    useState(false);

  const [showHoorayBox, setShowHoorayBox] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [languageRelatedWorkers, setLanguageRelatedWorkers] = useState([]);

  useEffect(() => {
    fetchAllLanguages();
    fetchTaskerSearchListForJobPost(selectedJobDetailsData?.language);

    setShowHoorayBox(true);

    setTimeout(() => {
      setShowHoorayBox(false);
    }, 3000);

    window.scrollTo(0, 0);
  }, []);

  const handleSelectLanguage = async ({ key }) => {
    const languageData = await languageItems?.find((item) => {
      return item?.key == key;
    });
    setLanguage(languageData?.label);

    fetchTaskerSearchListForJobPost(languageData?.key);

    // setLocationSearchValue({
    //   ...locationSearchValue,
    //   language: languageData?.key,
    // });
  };

  //fetch tasker list
  const fetchTaskerSearchListForJobPost = async (language) => {
    setListLoading(true);
    try {
      await getTaskerSearchListForJobPost({
        job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
        language_id: language,
        country_id: selectedJobDetailsData?.propertyData?.country_id,
        state_id: selectedJobDetailsData?.propertyData?.state_id,
        suburb_id: selectedJobDetailsData?.propertyData?.suburb_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setLanguageRelatedWorkers(response?.data?.output?.lang_user_ids);
            setSearchTaskerList(response?.data?.output?.workers);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setListLoading(false);
    } catch (error) {
      setListLoading(false);
      openNotification("error", error);
    }
  };

  //get all language list
  const fetchAllLanguages = async () => {
    try {
      await getLanguageListNotAuthorize({})
        .then((response) => {
          if (response?.data?.success) {
            setLanguage(
              response?.data?.output
                ?.find((item) => {
                  return item?.id === selectedJobDetailsData?.language;
                })
                ?.language?.split(":")[0]
            );
            setLanguageData(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLanguageData = (items) => {
    var languageObject = [];
    items?.forEach((item) => {
      let data = {
        key: item?.id,
        label: item?.language?.split(":")[0],
      };
      languageObject.push(data);
    });
    setLanguageItems(languageObject);
  };

  return (
    <div className="flex w-full justify-center items-center">
      <div className="w-full max-w-[1073px] xl:w-[1073px] mx-2 mt-6">
        {/* green section.......................................................................................... */}
        <div className="my-3 sticky-header-selectbuttons">
          <div className="bg-primaryColor rounded-lg p-2 flex flex-col xsm:flex-row justify-between items-baseline lg:items-center xsm:gap-5">
            <div
              className="flex justify-start cursor-pointer w-fit"
              onClick={() => {
                setLoginJobPostSteps(1);
                setStepperCurrentSteps(0);
              }}
            >
              <BackIconNew />
            </div>
            <div className="flex flex-col lg:flex-row justify-between items-center w-full">
              <div>
                {searchSelectedTaskerList?.length > 0 ? (
                  <p className="text-white font-medium text-base">
                    <span className="text-[30px] font-bold">
                      {searchSelectedTaskerList?.length}{" "}
                    </span>
                    Selected from {searchTaskerList?.length} worker(s)
                  </p>
                ) : (
                  <p className="text-white font-medium text-base">
                    <span className="text-[30px] font-bold">
                      {searchTaskerList?.length}{" "}
                    </span>
                    Matching worker(s) Found
                  </p>
                )}
              </div>

              {searchSelectedTaskerList?.length > 0 && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenCloseSelectedTaskerList(true);
                  }}
                >
                  <p className="text-white font-medium underline">
                    View selected
                  </p>
                </div>
              )}

              <div className="flex flex-col sm:flex-row gap-2">
                {searchSelectedTaskerList?.length > 0 && (
                  <Button
                    type="primary"
                    size="large"
                    className="bg-primaryDarkest"
                    onClick={() => {
                      setSelectedTaskerArrayForJobPost(
                        searchSelectedTaskerList
                      );
                      setLoginJobPostSteps(3);
                      setStepperCurrentSteps(1);
                    }}
                  >
                    Post job to selected workers
                  </Button>
                )}

                <Button
                  className="text-primaryDark bg-white border-primaryDark border-[1px]"
                  size="large"
                  onClick={() => {
                    if (searchTaskerList?.length > 0) {
                      setSelectedTaskerArrayForJobPost(
                        searchTaskerList?.map((item) => item?.user_id)
                      );
                      setLoginJobPostSteps(3);
                      setStepperCurrentSteps(1);
                    } else {
                      openNotification("warning", "No Workers Available!");
                    }
                  }}
                >
                  Post job to all workers
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* tasker card........................................................................ */}
        <div className="mt-5">
          <Row className="flex flex-row" gutter={8}>
            <Col span={24} lg={{ span: 6 }} className="mb-5 sidebar-jobDetails">
              {/* language...................................................................................... */}
              <div className="bg-white rounded-md p-3 mb-3">
                <p className="text-primaryDark font-semibold text-base">
                  Preferred Language :
                </p>
                <div className="flex flex-row gap-2 justify-end items-end mt-2">
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          activeBorderColor: "rgb(82,196,26)",
                          selectorBg: "rgb(224,239,226)",
                          colorText: "rgb(7,73,36)",
                        },
                      },
                    }}
                  >
                    <Select
                      size="small"
                      placeholder="Select language"
                      className="w-full rounded-md bg-primaryLightest"
                      dropdownClassName="bg-primaryLightest"
                      options={languageItems.map((item) => ({
                        value: item.key,
                        label: item.label,
                      }))}
                      value={language}
                      onChange={(key) => handleSelectLanguage({ key })}
                      suffixIcon={<FaCaretDown />}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </ConfigProvider>
                </div>
              </div>

              <div className="bg-white rounded-md p-3">
                <p className="text-primaryDark font-semibold text-base">
                  Job Type :
                </p>

                <div className="flex flex-row gap-1 justify-start items-center">
                  <img
                    src={
                      getLocalStoragedata("searchSelectedJobTypeImage") ||
                      NoImage
                    }
                    width={50}
                  />
                  <p className="font-semibold text-lg">{selectedJobType}</p>
                </div>

                <Divider className="my-3" />

                <p className="text-primaryDark font-semibold text-base">
                  Job Location :
                </p>

                <p className="font-normal text-base">
                  {selectedJobDetailsData?.propertyData?.suburb},{" "}
                  {selectedJobDetailsData?.propertyData?.state},{" "}
                  {selectedJobDetailsData?.propertyData?.country}
                </p>

                <Divider className="my-3" />

                <p className="text-primaryDark font-semibold text-base">
                  Language :
                </p>
                <p className="font-normal text-base">
                  {
                    languageItems
                      ?.find((item) => {
                        return item?.key === selectedJobDetailsData?.language;
                      })
                      ?.label?.split(":")[0]
                  }
                </p>

                <Divider className="my-3" />

                <p className="text-primaryDark font-semibold text-base">
                  Job Urgency :
                </p>
                {selectedJobDetailsData?.urgency === 2 ? (
                  <div className="flex gap-1">
                    <CalendarOrange />
                    <p className="text-md font-medium text-textColorThree">
                      Within few days
                    </p>
                  </div>
                ) : selectedJobDetailsData?.urgency === 3 ? (
                  <div className="flex gap-1">
                    <DoubleArrowBlack />
                    <p className="text-md font-medium text-textColorThree">
                      I’m flexible
                    </p>
                  </div>
                ) : (
                  <div className="flex gap-1">
                    <UrgentRed />
                    <p className="text-md font-medium text-textColorThree">
                      Urgent
                    </p>
                  </div>
                )}

                <Divider className="my-3" />

                <p className="text-primaryDark font-semibold text-base">
                  Job Description :
                </p>
                <p className="font-normal text-base break-words break-30 overflow-hidden">
                  {selectedJobDetailsData?.description}
                </p>
              </div>
            </Col>
            <Col
              span={24}
              lg={{ span: 18 }}
              className="profile-cards-container"
            >
              <div className="rounded-md">
                {searchTaskerList?.length === 0 ? (
                  <NoData
                    title="No Related Workers Available"
                    subTitle="Currently, there are no related workers available for this job. Please try again later or explore other categories"
                  />
                ) : (
                  <div>
                    {listLoading ? (
                      <div className="flex justify-center items-end mt-10">
                        <Spin />
                      </div>
                    ) : (
                      <TaskerListCardAboutSearchResult
                        taskerList={searchTaskerList}
                        searchSelectedTaskerList={searchSelectedTaskerList}
                        serSearchSelectedTaskerList={
                          serSearchSelectedTaskerList
                        }
                        languageRelatedWorkers={languageRelatedWorkers}
                      />
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {openCloseSelectedTaskerList && (
        <WorkerSelectedSearchListForJobPost
          open={openCloseSelectedTaskerList}
          onCancel={() => {
            setOpenCloseSelectedTaskerList(false);
          }}
          searchTaskerList={searchTaskerList}
          searchSelectedTaskerList={searchSelectedTaskerList}
          serSearchSelectedTaskerList={serSearchSelectedTaskerList}
        />
      )}

      {showHoorayBox && (
        <HoorayBox
          open={showHoorayBox}
          onCancel={() => {
            setShowHoorayBox(false);
          }}
          title={listLoading ? "Loading..." : "Hooray!"}
          content={
            <div className="flex flex-col w-[250px] h-[250px] p-12 rounded-full justify-center items-center bg-primaryDark text-white">
              {listLoading ? (
                <Spin />
              ) : (
                <>
                  <p className="text-base text-white">We found</p>
                  <p className="text-8xl font-bold text-white">
                    {searchTaskerList.length}
                  </p>
                  <p className="text-base text-white text-center">
                    worker(s) for you
                  </p>
                </>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default WorkerSearchResultListAlreadyLogin;
