import React from "react";

const CVIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.32699C4 6.81283 4 5.55575 4.78105 4.7747C5.5621 3.99365 6.81918 3.99365 9.33333 3.99365H10.6667C13.1808 3.99365 14.4379 3.99365 15.219 4.7747C16 5.55575 16 6.81283 16 9.32699V11.9937C16 14.5078 16 15.7649 15.219 16.5459C14.4379 17.327 13.1808 17.327 10.6667 17.327H9.33333C6.81918 17.327 5.5621 17.327 4.78105 16.5459C4 15.7649 4 14.5078 4 11.9937V9.32699Z"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
      <path
        d="M7.33334 10.6604H12.6667"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M7.33334 7.99365H12.6667"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M7.33334 13.3271H10.6667"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CVIcon;
