import React from "react";
import { Divider, Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";
import PhoneGreen from "../../assets/svg/PhoneGreen";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import UserColorProfile from "../userColorProfile/UserColorProfile";
import MailGreen from "../../assets/svg/MailGreen";

const WorkerContactDetails = ({
  open,
  onCancel,
  workerData,
  contactDetails,
}) => {
  const phoneNumber = decryptSecureData(contactDetails?.mobile_number) || "_";
  const emailAddress = decryptSecureData(contactDetails?.email_address) || "_";
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon color="#000000" />}
      footer={false}
      width={500}
    >
      <div className="flex flex-row justify-start">
        <p className="text-sm font-medium">Contact Related Worker</p>
      </div>
      <Divider className="mt-2 mb-5 bg-gray-300" />

      <div>
        <div className="flex flex-row gap-3 justify-start items-center mb-5">
          {workerData?.profile_picture === null ? (
            <UserColorProfile
              name={decryptSecureData(workerData?.full_name) || "N Z"}
              color={"#3bb96e"}
              size="60px"
              textSize="25px"
            />
          ) : (
            <img
              src={workerData?.profile_picture}
              alt=""
              className="flex w-[60px] h-[60px] rounded-full border-[2px] object-cover"
            />
          )}
          <div>
            <p className="text-lg font-bold text-primaryDarkest">
              {decryptSecureData(workerData?.full_name)}
            </p>
            <p className="text-xs font-normal text-gray-600">
              {workerData?.work_title || "No designation"}
            </p>
          </div>
        </div>

        <p className="text-base font-medium text-primaryDark">
          Contact Details
        </p>

        {phoneNumber === "_" && emailAddress === "_" ? (
          <p className="text-sm font-medium text-textColorTwo">
            No Contact Details
          </p>
        ) : (
          <>
            {phoneNumber !== "_" && (
              <a href={`tel:${phoneNumber}`} className="no-underline">
                <div className="flex flex-row gap-4 justify-start items-center bg-slate-200 p-3 rounded-lg mt-3">
                  <PhoneGreen />
                  <p className="text-base font-medium">{phoneNumber}</p>
                </div>
              </a>
            )}

            {emailAddress !== "_" && (
              <a href={`mailto:${emailAddress}`} className="no-underline">
                <div className="flex flex-row gap-4 justify-start items-center bg-slate-200 p-3 rounded-lg mt-3">
                  <MailGreen />
                  <p className="text-base font-medium">{emailAddress}</p>
                </div>
              </a>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default WorkerContactDetails;
