import HeaderConfig from "../helpers/HeaderConfig";
import axios from "axios";

const ClientProfileService = () => {
  const { baseUrl} = HeaderConfig();

  const changeClinetDetails = async (data,configAuth) => {
    return await axios.post(
      baseUrl + "customer/detail/change",
      data,
      configAuth
    );
  };

  return {
    changeClinetDetails
  };
};

export default ClientProfileService;
