import { Progress, Typography } from "antd";
import React, { useState } from "react";
import ReviewStar from "../../assets/svg/ReviewStar";
import FavoriteIcon from "../../assets/svg/FavoriteIcon";
import CompleteIcon from "../../assets/svg/CompleteIcon";
import WorkerReviewBox from "../../components/alertBox/WorkerReviewBox";

const { Text } = Typography;

const Review = ({ data }) => {
  const [openCloseReviewBox, setOpenCloseReviewBox] = useState(false);
  return (
    <div className="flex flex-col rounded-lg p-1 md:p-4 shadow-lg w-full mt-2">
      <Text className="flex text-xl font-bold text-primaryDarkest">Review</Text>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col w-full md:w-2/3 gap-2 md:border-r-2 md:p-2 md:px-4 border-primaryColor justify-center items-center">
          <div className="flex flex-row gap-2 justify-evenly bg-[#E0EFE2] items-center py-2 md:p-8 rounded-lg w-full md:min-w-[500px]">
            <div className="flex flex-col gap-2 w-full justify-center items-center">
              <span className="flex flex-row justify-center items-center gap-1 font-medium text-darkestColor text-sm md:text-base">
                <CompleteIcon />
                Favorites
              </span>
              <span className="font-semibold text-2xl">
                {data?.favorited_count || 0}
              </span>
            </div>
            <div className="flex border-l-2 h-full border-grayDividerLine2"></div>
            <div className="flex flex-col gap-2 w-full justify-center items-center">
              <span className="flex flex-row justify-center items-center gap-1 font-medium text-sm md:text-base text-darkestColor">
                <FavoriteIcon />
                Completed Tasks
              </span>
              <span className="font-semibold text-2xl">
                {data?.completed_task_count || 0}{" "}
                {data?.completed_task_count > 1 && "+"}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/3 gap-2 p-4">
          <div className="flex flex-row gap-2 items-center">
            <ReviewStar />
            <Text className="text-base font-bold">{data?.rate}</Text>
            <Text className="text-base font-normal">
              ({data?.rate_count} Reviews)
            </Text>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row gap-4">
              <Text className="flex min-w-fit break-keep font-medium text-base">
                5 Star
              </Text>
              <div className="flex flex-row gap-4 w-full">
                <Progress
                  percent={data?.rate_data["5_star"]}
                  showInfo={false}
                />
                <Text className="break-keep">
                  ({data?.rate_data["5_star"]})
                </Text>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <Text className="flex min-w-fit break-keep font-medium text-base">
                4 Star
              </Text>
              <div className="flex flex-row gap-4 w-full">
                <Progress
                  percent={data?.rate_data["4_star"]}
                  showInfo={false}
                />
                <Text className="break-keep">
                  ({data?.rate_data["4_star"]})
                </Text>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <Text className="flex min-w-fit break-keep font-medium text-base">
                3 Star
              </Text>
              <div className="flex flex-row gap-4 w-full">
                <Progress
                  percent={data?.rate_data["3_star"]}
                  showInfo={false}
                />
                <Text className="break-keep">
                  ({data?.rate_data["3_star"]})
                </Text>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <Text className="flex min-w-fit break-keep font-medium text-base">
                2 Star
              </Text>
              <div className="flex flex-row gap-4 w-full">
                <Progress
                  percent={data?.rate_data["2_star"]}
                  showInfo={false}
                />
                <Text className="break-keep">
                  ({data?.rate_data["2_star"]})
                </Text>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <Text className="flex min-w-fit break-keep font-medium text-base">
                1 Star
              </Text>
              <div className="flex flex-row gap-4 w-full">
                <Progress
                  percent={data?.rate_data["1_star"]}
                  showInfo={false}
                />
                <Text className="break-keep">
                  ({data?.rate_data["1_star"]})
                </Text>
              </div>
            </div>
            <div
              className="flex flex-row gap-2 text-primaryDarkest items-center font-semibold text-base cursor-pointer justify-end"
              onClick={() => {
                setOpenCloseReviewBox(true);
              }}
            >
              Show All Reviews
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.44971 7.75391H12.3982"
                  stroke="#074924"
                  strokeWidth="1.91752"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.92334 3.2793L12.3976 7.75352L7.92334 12.2277"
                  stroke="#074924"
                  strokeWidth="1.91752"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        {openCloseReviewBox && (
          <WorkerReviewBox
            open={openCloseReviewBox}
            onCancel={() => {
              setOpenCloseReviewBox(false);
            }}
            workerData={data}
          />
        )}
      </div>
    </div>
  );
};

export default Review;
