import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Typography,
  Divider,
} from "antd";

import NameIcon from "../../assets/svg/recomondation/NameIcon";
import HowKnowIcon from "../../assets/svg/recomondation/HowKnowIcon";
import WhyChooseIcon from "../../assets/svg/recomondation/WhyChooseIcon";
import RecommondationTextIcon from "../../assets/svg/recomondation/RecommendationTextIcon";
import HighlySkillIcon from "../../assets/svg/recomondation/HighlySkillIcon";
import FriendlyIcon from "../../assets/svg/recomondation/FriendlyIcon";
import ProfessionalIcon from "../../assets/svg/recomondation/ProfessionalIcon";
import TimeSavingIcon from "../../assets/svg/recomondation/TimeSavingIcon";
import RecomondationIcon from "../../assets/svg/RecomondationIcon";
import EmailGreenOutline from "../../assets/svg/EmailGreenOutline";

import RecomColorIcon from "../../assets/svg/RecomColorIcon";
import PublicProfileService from "../../services/PublicProfileService";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import { NotificationContext } from "../../context/NotificationContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";

const { TextArea } = Input;

const AddRecomondationForm = ({
  setRecomondationSteps,
  selectedReasons,
  setSelectedReasons,
  setAddFormValues,
  submitRecomondationData,
}) => {
  const [form] = Form.useForm();
  let { openNotification, handleError } = useContext(NotificationContext);

  const { getRecomondationKonwsArray } = PublicProfileService();
  const [knowSelectorList, setKnowSelectorList] = useState([]);

  useEffect(() => {
    fetchRecomondationKonwsArray();
  }, []);

  const fetchRecomondationKonwsArray = async () => {
    try {
      await getRecomondationKonwsArray({})
        .then((response) => {
          if (response?.data?.success) {
            var knowObject = [];
            response?.data?.output?.knows?.forEach((item) => {
              let data = {
                value: item?.know_id,
                label: item?.know,
              };
              knowObject.push(data);
              setKnowSelectorList(knowObject);
            });
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      handleError(error);
    }
  };

  const handleCheckboxChange = (value) => {
    setSelectedReasons((prev) =>
      prev.includes(value)
        ? prev.filter((reason) => reason !== value)
        : [...prev, value]
    );
  };

  const handleFormSubmit = (values) => {
    setAddFormValues(values);

    if (
      getLocalStoragedata("userData")?.email != null &&
      getLocalStoragedata("token")
    ) {
      submitRecomondationData(values, "success", null);
    }

    if (
      getLocalStoragedata("userData")?.email == null &&
      getLocalStoragedata("token")
    ) {
      setRecomondationSteps("emailForm");
    }

    if (!getLocalStoragedata("token")) {
      setRecomondationSteps("emailForm");
    }
  };

  return (
    <div className="w-full">
      <div className="flex gap-1 mt-4">
        <RecomColorIcon width={50} height={50} />
        <p className="font-bold text-primaryDark text-md">
          Would you recommend this worker?{" "}
          <p>Please write a brief recommendation about them</p>
        </p>
      </div>

      <Divider className="bg-gray-300 my-3" />

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        className="md:px-5"
        requiredMark={false}
        initialValues={{
          name: decryptSecureData(getLocalStoragedata("userData")?.first_name),
        }}
      >
        <Form.Item
          name="name"
          label={
            <span className="flex flex-row gap-1">
              <NameIcon /> Your Name <span className="text-red-500">*</span>
            </span>
          }
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input
            placeholder="Enter your full name"
            size="large"
            disabled={getLocalStoragedata("token")}
          />
        </Form.Item>

        <Form.Item
          name="relationship"
          label={
            <span className="flex flex-row gap-1">
              <HowKnowIcon /> How do you know this worker{" "}
              <span className="text-red-500">*</span>
            </span>
          }
          rules={[{ required: true, message: "Reason is required!" }]}
        >
          <Select
            placeholder="Select a reason"
            size="large"
            options={knowSelectorList}
          />
        </Form.Item>

        <Form.Item
          label={
            <span className="flex flex-row gap-1">
              <WhyChooseIcon /> Why choose to recommend this worker
              <span className="text-red-500">*</span>
            </span>
          }
          name="reasons"
          rules={[
            { required: true, message: "Please select at least one reason" },
          ]}
        >
          <div className="grid grid-cols-2 gap-4">
            <div className="relative p-3 bg-[#E0EFE2] rounded-md flex items-center justify-center">
              <Checkbox
                checked={selectedReasons.includes(1)}
                onChange={() => handleCheckboxChange(1)}
                className="absolute top-2 right-2"
              />
              <div className="flex flex-col items-center justify-center">
                <HighlySkillIcon className=" text-2xl" />
                <p className="text-center font-bold text-sm">Expert</p>
              </div>
            </div>

            <div className="relative p-3 bg-[#E0EFE2] rounded-md flex items-center justify-center">
              <Checkbox
                checked={selectedReasons.includes(2)}
                onChange={() => handleCheckboxChange(2)}
                className="absolute top-2 right-2"
              />
              <div className="flex flex-col items-center justify-center">
                <ProfessionalIcon className=" text-2xl" />
                <p className="text-center font-bold text-sm">Professional</p>
              </div>
            </div>

            <div className="relative p-3 bg-[#E0EFE2] rounded-md flex items-center justify-center">
              <Checkbox
                checked={selectedReasons.includes(3)}
                onChange={() => handleCheckboxChange(3)}
                className="absolute top-2 right-2"
              />
              <div className="flex flex-col items-center justify-center">
                <FriendlyIcon className=" text-2xl" />
                <p className="text-center font-bold text-sm">Optimist</p>
              </div>
            </div>

            <div className="relative p-3 bg-[#E0EFE2] rounded-md flex items-center justify-center">
              <Checkbox
                checked={selectedReasons.includes(4)}
                onChange={() => handleCheckboxChange(4)}
                className="absolute top-2 right-2"
              />
              <div className="flex flex-col items-center justify-center">
                <TimeSavingIcon className=" text-2xl" />
                <p className="text-center font-bold text-sm">Speedster</p>
              </div>
            </div>
          </div>
        </Form.Item>

        <Form.Item
          name="recommendation"
          label={
            <span className="flex flex-row gap-1">
              <RecommondationTextIcon /> Recommendation
            </span>
          }
          rules={[
            // { required: true, message: "Recommendation is required!" },
            {
              max: 120,
              message: "Recommendation cannot exceed 120 characters",
            },
          ]}
        >
          <TextArea
            placeholder="Write a recommendation (0/120)"
            maxLength={120}
            rows={4}
          />
        </Form.Item>

        {/* Continue Button */}
        <Form.Item>
          <div className="flex justify-center items-center">
            <Button
              type="primary"
              htmlType="submit"
              className="bg-primaryDarkest w-2/3 "
              size="large"
            >
              Continue
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddRecomondationForm;
