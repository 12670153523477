import React, { useContext, useEffect, useState } from "react";
import SignUpLayout from "./SignUpLayout";
import { Button, Pagination } from "antd";
import PropertyService from "../../services/PropertyService";
import { NotificationContext } from "../../context/NotificationContext";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import AddNewProperty from "../../components/myProperties/AddNewProperty";
import MyPropertyCardSignUp from "../../components/myProperties/PropertyCardSignUp";
import { useNavigate } from "react-router-dom";
import { QuickSignupContext } from "../../context/QuickSignUpContext";

const AddFirstProperty = ({ stateValue }) => {
  const navigate = useNavigate();
  const { fetchMyProperties } = PropertyService();
  let { openNotification, handleError } = useContext(NotificationContext);
  const { setStepperClientCurrentSteps, setFormValues } =
    useContext(QuickSignupContext);

  const [properties, setProperties] = useState([]);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = properties.slice(startIndex, endIndex);

  const [openAddNewPropertyModal, setAddNewPropertyModal] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getMyProperties();
  }, []);

  const getMyProperties = async () => {
    try {
      await fetchMyProperties({
        user_id: getLocalStoragedata("userId"),
        status: 1,
      })
        .then((response) => {
          if (response?.data?.success) {
            setProperties(response.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <SignUpLayout>
      <div className="flex flex-col gap-4 w-full sm:max-w-[800px]">
        <div className="grid grid-cols-12 gap-4 items-start">
          <div className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex flex-col items-start">
            <p className="text-[32px] font-bold text-primaryDarkest whitespace-normal md:whitespace-nowrap">
              Add Your Property Details
            </p>
            <p className="text-base font-normal">
              At least add one property, You can add more properties later
            </p>
          </div>

          <div className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex justify-start md:justify-center ">
            <Button
              className="px-8 py-5 border-primaryDark text-primaryDark border-[1px] text-base font-medium"
              onClick={() => {
                setAddNewPropertyModal(true);
              }}
            >
              + Add new property
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full min-h-[90%]">
          <div className="flex flex-col gap-2 min-h-[80%]">
            {properties.length > 0 ? (
              <>
                {currentItems?.map((item, index) => (
                  <MyPropertyCardSignUp
                    data={item}
                    getMyProperties={getMyProperties}
                    key={index}
                  />
                ))}
              </>
            ) : (
              <div className="flex flex-col border-[1px] justify-center items-center border-dotted border-textColorFour bg-[#F1F1F1] p-4 rounded-md">
                <p className="flex font-medium text-textColorTwo">
                  You don't have any added properties
                </p>
                <p
                  className="flex font-semibold text-primaryDark cursor-pointer"
                  onClick={() => {
                    setAddNewPropertyModal(true);
                  }}
                >
                  Add Property +
                </p>
              </div>
            )}
          </div>

          {properties.length > 2 && (
            <div className="flex justify-end mt-10 bottom-0">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={properties.length}
                onChange={handlePageChange}
                showSizeChanger={false}
                responsive={true}
              />
            </div>
          )}

          <div className="flex justify-center items-center w-full bottom-0 left-0 my-4">
            <Button
              className="w-48 h-12 text-base font-bold"
              type="primary"
              disabled={properties.length < 1}
              onClick={() => {
                if (
                  stateValue?.from === "directSignin" ||
                  stateValue?.from === "directRegi"
                ) {
                  navigate("../");
                } else if (stateValue?.from === "searchNotLogin") {
                  navigate("/create-job-post", {
                    state: { from: "searchJustNowLogin" },
                  });
                }
                // else if (stateValue?.from === "search") {
                //   navigate("/create-job-post");
                // }
                setStepperClientCurrentSteps(0);
                setFormValues({
                  name: null,
                  country: null,
                  postalCode: null,
                  state: null,
                  suburb: null,
                  email: null,
                  phoneNumber: null,
                  dialCode: null,
                  countryCode: null,
                  isPostalCodeRequired: 0,
                  isEmailRequired: 0,
                });
                setLocalStorageData("userTypeId", 2);
              }}
            >
              Finish Signup
            </Button>
          </div>
        </div>

        {openAddNewPropertyModal && (
          <AddNewProperty
            open={openAddNewPropertyModal}
            onCancel={() => {
              setAddNewPropertyModal(false);
            }}
            count={properties.length}
            getMyProperties={getMyProperties}
          />
        )}
      </div>
    </SignUpLayout>
  );
};

export default AddFirstProperty;
