import React, { useContext, useEffect, useState } from "react";
import JobpostLayout from "./jobpostLayout/JobpostLayout";
import { Button } from "antd";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import DotIcon from "../../assets/svg/DotIcon";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";

const AdditionalDetails = () => {
  const { setStepperCurrentSteps, selectedJobType, selectedJobDetailsData } =
    useContext(CreateJobPostContext);
  const { openNotification, handleError } = useContext(NotificationContext);
  const { getLanguageListNotAuthorize } = PublicProfileService();
  const [languageItems, setLanguageItems] = useState("-");

  useEffect(() => {
    fetchAllLanguages();
  }, []);

  //get all language list
  const fetchAllLanguages = async () => {
    try {
      await getLanguageListNotAuthorize({})
        .then((response) => {
          if (response?.data?.success) {
            setLanguageItems(
              response?.data?.output?.find(
                (item) => item?.id === selectedJobDetailsData?.language
              )?.language
            );
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <JobpostLayout>
      <div>
        <h1 className="text-xl md:text-[26px] font-bold text-primaryDarkest pt-5 md:pt-10 text-center">
          Additional details
        </h1>

        {/* <div className="pt-3 md:pt-5 flex flex-col">
          <p className="text-base font-medium flex flex-row justify-start items-center gap-2 text-textColorTwo">
            <DotIcon width="12" color="#2C8B52" />
            Selected Job type :
            <span className="text-primaryDarkest"> {selectedJobType}</span>
          </p>

          <p className="text-base font-medium flex flex-row justify-start items-center gap-2 text-textColorTwo">
            <DotIcon width="12" color="#2C8B52" />
           <span className="whitespace-nowrap">Preferred Location:</span> 
            <span className="text-primaryDarkest">
              {selectedJobDetailsData?.propertyData?.suburb},{" "}
              {selectedJobDetailsData?.propertyData?.state},{" "}
              {selectedJobDetailsData?.propertyData?.country},
            </span>
          </p>

          <p className="text-base font-medium flex flex-row justify-start items-center gap-2 text-textColorTwo">
            <DotIcon width="12" color="#2C8B52" />
            Preferred Language :
            <span className="text-primaryDarkest"> {languageItems}</span>
          </p>
        </div> */}

        <div className="pt-3 md:pt-5 flex flex-col gap-2">
          {/* Selected Job Type */}
          <div className="flex flex-wrap md:flex-nowrap items-center gap-2 text-textColorTwo text-base font-medium">
            <div className="flex items-center gap-2">
              <DotIcon width="12" color="#2C8B52" />
              <span className="whitespace-nowrap">Selected Job Type:</span>
            </div>
            <span className="text-primaryDarkest md:block md:ml-5">
              {selectedJobType}
            </span>
          </div>

          {/* Preferred Location */}
          <div className="flex flex-wrap md:flex-nowrap items-center gap-2 text-textColorTwo text-base font-medium">
            <div className="flex items-center gap-2">
              <DotIcon width="12" color="#2C8B52" />
              <span className="whitespace-nowrap">Preferred Location:</span>
            </div>
            <span className="text-primaryDarkest md:block md:ml-5">
              {selectedJobDetailsData?.propertyData?.suburb},{" "}
              {selectedJobDetailsData?.propertyData?.state},{" "}
              {selectedJobDetailsData?.propertyData?.country}
            </span>
          </div>

          {/* Preferred Language */}
          <div className="flex flex-wrap md:flex-nowrap items-center gap-2 text-textColorTwo text-base font-medium">
            <div className="flex items-center gap-2">
              <DotIcon width="12" color="#2C8B52" />
              <span className="whitespace-nowrap">Preferred Language:</span>
            </div>
            <span className="text-primaryDarkest md:block md:ml-5">
              {languageItems}
            </span>
          </div>
        </div>

        <div className="bg-white rounded-lg py-10 flex flex-col justify-center items-center mt-5">
          <p className="text-textColorTwo">
            No additional details needed for this job
          </p>

          <Button
            type="primary"
            htmlType="submit"
            className="bg-primaryDark font-semibold px-8 flex flex-row gap-2 justify-center items-center mt-4"
            onClick={() => {
              setStepperCurrentSteps(2);
            }}
          >
            Next <MdOutlineKeyboardArrowRight />
          </Button>
        </div>
      </div>
    </JobpostLayout>
  );
};

export default AdditionalDetails;
