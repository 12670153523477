import React, { useContext } from "react";
import { Button, Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";
import RecomColorIcon from "../../assets/svg/RecomColorIcon";
import CopyIcon from "../../assets/svg/CopyIcon";
import { NotificationContext } from "../../context/NotificationContext";
import Facebook from "../../assets/svg/recomondation/Facebook";
import Whatsapp from "../../assets/svg/recomondation/Whatsapp";
import Insta from "../../assets/svg/recomondation/Insta";
import XIcon from "../../assets/svg/recomondation/XIcon";
import Linkedln from "../../assets/svg/recomondation/Linkedln";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

const RequestRecomondation = ({ open, onCancel, data }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const publicUrl = process.env.REACT_APP_DOMAIN;
  const fullUrl = `${publicUrl}${data?.username}?status=true`;
  // const fullUrl = `http://localhost:3000/profile/${data?.username}?status=true`;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        openNotification("success", "Link copied to clipboard!");
      })
      .catch((error) => {
        openNotification("warning", "Failed to copy link. Please try again.");
        handleError(error);
      });
  };

  const socialUrls = {
    whatsapp: `https://wa.me/?text=${encodeURIComponent(fullUrl)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      fullUrl
    )}`,
    instagram: `https://www.instagram.com/?url=${encodeURIComponent(fullUrl)}`,
    twitter: `https://twitter.com/share?url=${encodeURIComponent(fullUrl)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      fullUrl
    )}`,
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={false}
      closeIcon={false}
      width={600}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-row justify-between items-center w-full">
          <p className="text-sm font-medium">Request Recommendation</p>
          <div
            className="cursor-pointer"
            onClick={() => {
              onCancel();
            }}
          >
            <CloseIcon color="#000000" />
          </div>
        </div>

        <p className="text-base font-semibold text-primaryDark text-center my-5">
          You can now request recommendations and showcase them on your public
          profile.
        </p>

        <div className="flex justify-center items-center">
          <RecomColorIcon />
        </div>

        <p className="text-sm font-normal text-textColorTwo text-center my-5">
          Share this form with those who would like to write a recommendation
          for you and make your profile stand out even more!
        </p>

        <div className="bg-gray-300 rounded-lg text-center overflow-x-scroll w-[270px] xs:w-[400px] sm:w-fit">
          <p className="p-3 whitespace-nowrap">{fullUrl}</p>
        </div>

        <Button
          type="primary"
          size="large"
          className="bg-primaryDarkest flex flex-row justify-center items-center my-5"
          onClick={handleCopy}
        >
          <CopyIcon />
          Copy Link to Clipboard
        </Button>

        <div>
          <p className="text-center text-black">Share</p>
          <div className="flex justify-center items-center gap-1 mt-2">
            <a
              href={socialUrls?.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Whatsapp />
            </a>

            <FacebookShareButton
              url={fullUrl}
              quote={"Check out this profile!"}
              hashtag={`${fullUrl?.replace(/https?:\/\//, "")}`}
            >
              <Facebook />
            </FacebookShareButton>

            <a
              href={fullUrl?.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Insta />
            </a>

            {/* Twitter Share Button */}
            <TwitterShareButton url={fullUrl} title="Check out this profile!">
              <XIcon />
            </TwitterShareButton>

            <LinkedinShareButton url={fullUrl} title="Check out this profile!">
              <Linkedln />
            </LinkedinShareButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequestRecomondation;
