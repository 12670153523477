import React from "react";
import { Col, Row } from "antd";
import DemandWorkers from "../../assets/img/demandWorker.webp";

const OnDemandWorkers = ({ title, description }) => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] mx-2 bg-[#F5F5F5] px-8 pt-10 rounded-lg">
        <Row>
          <Col
            span={24}
            md={{ span: 14 }}
            className="flex flex-col text-center items-center md:text-start md:items-start"
          >
            <h1 className="title-level2 custom-font">{title}</h1>

            <div className="border-b-[2px] border-black w-60 mt-5"></div>
            <p className="text-lg lg:text-xl font-normal mt-5 mb-10 max-w-[500px]">
              {description}
            </p>
          </Col>

          <Col
            span={24}
            md={{ span: 10 }}
            className="flex items-center justify-center md:items-end md:justify-end"
          >
            <div className="max-w-[463px] md:max-w-[563px]">
              <img src={DemandWorkers} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OnDemandWorkers;
