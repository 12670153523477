import React from "react";
import SubscriptionServiceMan from "../assets/img/SubscriptionServiceMan.webp";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import CorrectIcon from "../assets/svg/CorrectIcon";
import NavBar from "../components/navbar/NavBar";
import { setLocalStorageData } from "../helpers/encryptHelpers/storageHelper";

const WorkerSignUpSuccess = () => {
  const navigate = useNavigate();
  return (
    <div>
      <NavBar />
      <div className="content flex justify-center items-center">
        <div className="flex justify-center items-center my-28">
          <div className="max-w-[1073px] xl:w-[1073px] lg:w-[1073px] w-full rounded-xl">
            <Row gutter={16}>
              <Col
                span={24}
                md={{ span: 10 }}
                className="flex justify-center items-center"
              >
                <img src={SubscriptionServiceMan} className="rounded-lg" />
              </Col>

              <Col
                span={24}
                md={{ span: 12 }}
                className="flex flex-col justify-center items-center md-justify-center"
              >
                <div className="p-3 w-full">
                  <p className="text-2xl text-black font-bold text-center ">
                    Join our subscription service
                  </p>

                  <div className="flex justify-center items-center">
                    <div className="px-8 lg:px-10 w-full lg:w-4/5">
                      <p className="mt-3 text-center text-lg">
                        To gain access to exclusive task leads and
                        opportunities.
                      </p>

                      <div className="bg-[#E0EFE2] flex-col text-start p-4 rounded-xl mt-2">
                        <div className="flex gap-2">
                          <CorrectIcon />
                          <p className="text-primaryDarkest font-semibold ml-2 text-md">
                            Free 30 Days of unlimited lead interests
                          </p>
                        </div>
                        <div className="flex gap-2 mt-1">
                          <CorrectIcon />
                          <p className="text-primaryDarkest font-semibold ml-2 text-md">
                            Chat directly with client
                          </p>
                        </div>
                        <div className="flex gap-2 mt-1">
                          <CorrectIcon />
                          <p className="text-primaryDarkest font-semibold ml-2 text-md">
                            Exclusive promotions
                          </p>
                        </div>
                      </div>

                      <p className="font-bold text-black text-center mt-2">
                        Start Your Free Trial
                      </p>

                      <p className="font-normal text-black text-center text-xs mt-1">
                        After 30 days, you'll need to purchase a package to
                        continue accessing lead interests
                      </p>

                      <>
                        <Button
                          type="primary"
                          size="large"
                          className=" text-white text-sm font-semibold rounded-md mt-4 w-full bg-primaryDarkest"
                          onClick={() => {
                            navigate("../");
                            setLocalStorageData("userTypeId", 6);
                          }}
                        >
                          Get Started
                        </Button>
                      </>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerSignUpSuccess;
