import { Spin, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import EditIconInfo from "../../assets/svg/EditIconInfo";
import EditServicesModal from "../../components/workerProfile/EditServicesModal";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import PublicServicesCard from "../../components/workerProfile/PublicServiceCard";

const { Text } = Typography;

const Services = ({ data, editable }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { workerServiceList } = PublicProfileService();

  const [openAddEditService, setOpenAddEditService] = useState(false);
  const [openedService, setOpenedService] = useState(false);

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    try {
      await workerServiceList({
        user_id: data?.user_id,
        status: 1,
        country_id: data?.country_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setServices(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-white rounded-lg p-4 shadow-lg w-full mt-2">
      <div className="flex flex-row w-full gap-2 justify-between items-center">
        <Text className="flex text-xl font-bold text-primaryDarkest">
          Services
        </Text>
        {editable && (
          <div
            className="cursor-pointer scale-75"
            onClick={() => {
              setOpenAddEditService(true);
            }}
          >
            <EditIconInfo />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2 w-full justify-center items-center">
        {loading ? (
          <Spin />
        ) : (
          <>
            {services.length == 0 ? (
              <div className="flex w-full justify-items-center items-center">
                No Services
              </div>
            ) : showMore ? (
              services.map((item, index) => {
                return (
                  <PublicServicesCard
                    key={index}
                    data={item}
                    openedService={openedService}
                    setOpenedService={(id) => {
                      setOpenedService(id);
                    }}
                    currency={data?.currency_label}
                  />
                );
              })
            ) : (
              services.slice(0, 3).map((item, index) => {
                return (
                  <PublicServicesCard
                    key={index}
                    data={item}
                    openedService={openedService}
                    setOpenedService={(id) => {
                      setOpenedService(id);
                    }}
                    currency={data?.currency_label}
                  />
                );
              })
            )}
          </>
        )}

        {!showMore && services.length > 3 && (
          <div
            className="flex text-primaryDark items-center cursor-pointer gap-2"
            onClick={() => {
              setShowMore(true);
            }}
          >
            Show All{" "}
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.48926 8.5332H12.4377"
                stroke="#2C8B52"
                stroke-width="1.91752"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.96338 4.05762L12.4376 8.53184L7.96338 13.0061"
                stroke="#2C8B52"
                stroke-width="1.91752"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
        {showMore && (
          <div
            className="flex text-primaryDark items-center cursor-pointer gap-2"
            onClick={() => {
              setShowMore(false);
            }}
          >
            Show Less{" "}
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.48926 8.5332H12.4377"
                stroke="#2C8B52"
                stroke-width="1.91752"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.96338 4.05762L12.4376 8.53184L7.96338 13.0061"
                stroke="#2C8B52"
                stroke-width="1.91752"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
      </div>

      {openAddEditService && (
        <EditServicesModal
          open={openAddEditService}
          onClose={() => {
            setOpenAddEditService(false);
          }}
          data={data}
          refreshServices={fetchServices}
          currency={data?.currency_label}
        />
      )}
    </div>
  );
};

export default Services;
