import { Button, Form, Typography, Statistic, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import PassCode from "../../assets/svg/PassCode";
import { InputOTP } from "antd-input-otp";
import BackIcon from "../../assets/svg/BackIcon";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import LoginServices from "../../services/LoginServices";
import WorkerProfileService from "../../services/WorkerProfileService";
import HeaderConfig from "../../helpers/HeaderConfig";
import { maskPhoneNumber } from "../../helpers/phoneNumberStructure/PhoneNumberStructure";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";

const { Text } = Typography;

const DeleteAccountOtpCard = ({ setDeleteAccountSteps }) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const { getOtpByMsg, verifyByMsgOtp, userLogOut } = LoginServices();
  const { deleteWorkerAccount } = WorkerProfileService();

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState(null);

  const [countDownTime, setCountDownTime] = useState(0);
  const [blockCountDownTime, setBlockCountDownTime] = useState(0);

  const [enteredOTPValue, setEnteredOTPValue] = useState();
  const [attemptReleaseTime, setAttemptReleaseTime] = useState(0);
  const [otpReference, setOtpReference] = useState();

  const { Countdown } = Statistic;
  const deadline = Date.now() + 1000 * 60 * 2;
  const { configAuth } = HeaderConfig();

  useEffect(() => {
    generateOTP();

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //generate
  const generateOTP = async () => {
    setPageLoading(true);
    setEnteredOTPValue();
    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "App-Type": 2, //client
      },
    };
    try {
      await getOtpByMsg(
        {
          country_id:
            getLocalStoragedata("userData")?.country_id ||
            getLocalStoragedata("userCountryID"),
          mobile_number:
            getLocalStoragedata("userData")?.mobile_number ||
            getLocalStoragedata("userContactNumber"),
          sms_type: 1,
          should_generate: 1,
          is_web: 1,
        },
        config
      )
        .then((response) => {
          if (response?.data?.success) {
            // const attemptReleaseTimeInMillis =
            //   response?.data?.output?.attempt_release_time * 1000;
            // setCountDownTime(Date.now() + attemptReleaseTimeInMillis);
            // setOtpReference(response?.data?.output?.reference);
            // setPageLoading(false);

            setAttemptReleaseTime(response?.data?.output?.attempt_release_time);
            //if attept count was exceed get blocked time
            const attemptReleaseTimeInMillis =
              response?.data?.output?.attempt_release_time * 1000;
            setBlockCountDownTime(Date.now() + attemptReleaseTimeInMillis);
            setCountDownTime(deadline);
            setOtpReference(response?.data?.output?.reference);
            setPageLoading(false);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //countdown finshed
  const onFinishCountDown = () => {
    setCountDownTime(0);
  };

  //block countdown finshed
  const onFinishBlockCountDown = () => {
    setAttemptReleaseTime(0);
    setCountDownTime(0);
  };

  //verify OTP
  const handleOTPVerification = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "App-Type": 2,
      },
    };
    try {
      await verifyByMsgOtp(
        {
          mobile_number:
            getLocalStoragedata("userData")?.mobile_number ||
            getLocalStoragedata("userContactNumber"),
          otp: enteredOTPValue,
          reference: otpReference,
          is_web: 1,
        },
        config
      )
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("userId", response?.data?.output?.user_id);
            handleDeleteAccount();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  //delete account
  const handleDeleteAccount = async () => {
    setLoading(true);

    try {
      await deleteWorkerAccount(
        {
          user_id: getLocalStoragedata("userData").user_id,
          user_type_id: getLocalStoragedata("userTypeId"),
        },
        configAuth
      )
        .then((response) => {
          if (response?.data?.success) {
            setDeleteAccountSteps("DELETE_ACCOUNT_SUCCESS");
            openNotification("success", response.data.message);
            logOut();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setLoading(false);
  };

  const logOut = () => {
    userLogOut({ token: getLocalStoragedata("token") }, configAuth)
      .then((response) => {
        if (response.data.success) {
          localStorage.clear();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return (
    <>
      {pageLoading ? (
        <div className="flex flex-col justify-center items-center">
          <Spin />
        </div>
      ) : (
        <>
          {attemptReleaseTime === 0 ? (
            //if phone number was not blocked.................................................
            <>
              <div
                className="flex flex-row gap-2 items-center cursor-pointer mb-5"
                onClick={() => {
                  setDeleteAccountSteps("DELETE_ACCOUNT");
                }}
              >
                <BackIcon />
                <Text className="flex text-textColorTwo font-medium">Back</Text>
              </div>

              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center shadow-lg rounded-lg w-full md:w-[500px] bg-primaryLightest p-8">
                  <p className="text-[20px] md:text-[32px] font-bold text-primaryDarkest">
                    Enter OTP
                  </p>

                  <p className="text-base mb-5">
                    A six-digit verification code has been sent to{" "}
                    <span className="font-bold">
                      {maskPhoneNumber(
                        decryptSecureData(
                          getLocalStoragedata("userData")?.mobile_number ||
                            getLocalStoragedata("userContactNumber")
                        )
                      )}
                    </span>
                  </p>
                  <Form
                    className="flex flex-col gap-2 w-full"
                    onFinish={handleOTPVerification}
                  >
                    {/* OTP input */}
                    <span className="flex flex-row gap-2">
                      <PassCode />
                      <p className="text-sm font-medium">Enter the OTP *</p>
                    </span>

                    <div>
                      <Form.Item
                        name="pin"
                        className="flex items-start w-full mb-0"
                        rules={[
                          {
                            required: true,
                            message: "OTP is required!",
                          },
                          // { min: 1, message: "Invalid PIN!" },
                        ]}
                      >
                        <InputOTP
                          type="password"
                          autoFocus
                          onChange={(e) => {
                            setEnteredOTPValue(e.join(""));
                            setError(null);
                          }}
                          length={6}
                          inputType="numeric"
                          inputClassName="w-full"
                          className="!md:w-[50px] !h-[50px] !max-w-60"
                        />
                      </Form.Item>
                    </div>

                    {error && (
                      <p className="text-sm font-medium text-warringColorOne">
                        Incorrect PIN. Please try again.
                      </p>
                    )}

                    <div className="flex justify-center items-center">
                      {countDownTime === 0 ? (
                        <p
                          className="cursor-pointer underline text-[20px] font-medium text-primaryDarkest"
                          onClick={() => {
                            generateOTP();
                          }}
                        >
                          Resend
                        </p>
                      ) : (
                        <Countdown
                          value={countDownTime}
                          onFinish={onFinishCountDown}
                          format="mm:ss"
                          valueStyle={{
                            fontSize: "20px",
                            fontWeight: 600,
                            color: "#2C8B52",
                          }}
                        />
                      )}
                    </div>

                    <Form.Item>
                      <Button
                        type="primary"
                        className="w-full bg-primaryDarkest text-base font-bold h-12 rounded-lg text-white "
                        loading={loading}
                        htmlType="submit"
                      >
                        Continue
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </>
          ) : (
            //if phone number was blocked......................................................
            <>
              <div
                className="flex flex-row gap-2 items-center cursor-pointer mb-5"
                onClick={() => {
                  setDeleteAccountSteps("DELETE_ACCOUNT");
                }}
              >
                <BackIcon />
                <Text className="flex text-textColorTwo font-medium">Back</Text>
              </div>

              <div className="flex flex-col justify-center items-center bg-primaryLightest py-5 px-10 rounded-lg">
                <p className="text-[20px] md:text-[30px] font-bold text-warringColorOne">
                  Failed !
                </p>

                <p className="text-base mb-5 text-center">
                  You cannot resend OTPs due to multiple number of
                  failed attempts.
                </p>

                <Countdown
                  value={blockCountDownTime}
                  onFinish={onFinishBlockCountDown}
                  format="mm:ss"
                  valueStyle={{
                    fontSize: "30px",
                    fontWeight: 700,
                    color: "#2C8B52",
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DeleteAccountOtpCard;
