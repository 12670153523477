import React, { useContext, useEffect } from "react";
import { Image, Modal, Spin, Typography } from "antd";
import { Button } from "antd";
import { useState } from "react";
import { Input } from "antd";
import { AddJobTypesCardSignup } from "./cards/AddJobTypesCardSignup";
import SearchIcon from "../../assets/svg/SearchIcon";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";
const { Text } = Typography;

export const AddJobTypesSignup = ({ open, onClose, jobType }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { workerFormValues } = useContext(WorkerSignUpContext);
  const { allJobTypes } = PublicProfileService();

  const [searchTerm, setSearchTerm] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const [rawJobTypes, setRawJobTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllJobTypes();
  }, []);

  const getAllJobTypes = async () => {
    setLoading(true);
    try {
      await allJobTypes({
        job_category_id: jobType.id || jobType.job_category_id,
        status: 1,
        country_id: workerFormValues?.country,
      })
        .then((response) => {
          if (response?.data?.success) {
            setRawJobTypes(response?.data.output);
            setJobTypes(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    const searchResult = rawJobTypes.filter((item) =>
      item.job_type.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setJobTypes(searchResult);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={1000}
      title="Add Job Type"
    >
      <div className="flex flex-col">
        <Text className="text-textColorFour text-sm py-2">
          Selected Job Category
        </Text>
        <div className="flex flex-row py-2 gap-4">
          <Image
            src={jobType.icon_image || jobType.image}
            width={50}
            height={50}
            preview={false} // Disable preview on click, optional
          />
          <p className="text-md font-medium  flex items-center">
            {jobType.job_category}
          </p>
        </div>
        <div className="py-2">
          <Input
            value={searchTerm}
            onChange={onSearch}
            suffix={<SearchIcon className="w-6 h-6" />}
            placeholder="Search job type"
            className="border border-primaryDarkest px-4 py-2"
            style={{ borderRadius: 40 }}
          />
        </div>

        <div className="h-[47vh] overflow-y-auto">
          {loading ? (
            <div className="flex w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {jobTypes.length > 0 ? (
                jobTypes.map((item) => (
                  <AddJobTypesCardSignup key={item.id} data={item} />
                ))
              ) : (
                <Text>No job types found</Text>
              )}
            </>
          )}
        </div>

        <div className="w-full flex p-2 justify-end">
          <Button onClick={onClose} className="rounded-xl">
            <Text className="font-semibold">Go Back</Text>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
