import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Form, Select, Divider } from "antd";
import { Typography } from "antd";
import { Modal } from "antd";
import FillFB from "../../assets/svg/FillFB";
import EmptyFB from "../../assets/svg/EmptyFB";
import FillInsta from "../../assets/svg/FillInsta";
import EmptyInsta from "../../assets/svg/EmptyInsta";
import FillX from "../../assets/svg/FillX";
import EmptyX from "../../assets/svg/EmptyX";
import FillLinkedIn from "../../assets/svg/FillLinkedIn";
import EmptyLinkedIn from "../../assets/svg/EmptyLinkedIn";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import { NotificationContext } from "../../context/NotificationContext";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import PublicProfileService from "../../services/PublicProfileService";
import GoogleBusiness from "../../assets/svg/GoogleBusiness";
import EmptyGoogleBusiness from "../../assets/svg/EmptyGoogleBusiness";
const { Text } = Typography;

const UpdatePersonalInfo = ({ open, onClose, getWorkerProfileData, data }) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const {
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
    getRelatedSearchedPostalCode,
  } = CreateJobPostServices();
  const { updatePersonalData } = PublicProfileService();

  const [fbURL, setFbURL] = useState(data?.facebook);
  const [instaURL, setInstaURL] = useState(data?.instagram);
  const [xURL, setXURL] = useState(data?.x_url);
  const [linkedInURL, setLinkedInURL] = useState(data?.linkedin);
  const [gBisURL, setGBisURL] = useState(data?.linkedin);

  const [local1SelectData, setLocation1SelectorData] = useState([]);
  const [local2SelectData, setLocation2SelectorData] = useState([]);
  const [postalCodeRequired, setPostalCodeRequired] = useState();
  const [postalCodeData, setPostalCodeData] = useState(null);
  const [allPostalCodes, setAllPostalCodes] = useState([]);

  const [isStateSelected, setIsStateSelected] = useState(true);

  const [suburbId, setSuburbId] = useState(data?.suburb_id);
  const [stateId, setStateId] = useState(data?.state_id);
  const [isFieldChanged, setIsFieldChanged] = useState(false);

  const [form] = Form.useForm();

  const [labels, setLabels] = useState({
    level_1_label: "State",
    level_2_label: "Suburb",
  });

  const [showSMUpdate, setShowSMUpdate] = useState(null);
  const [isUrlChanged, setIsUrlChanged] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  useEffect(() => {
    fetchCountryData();
  }, []);

  useEffect(() => {
    // Check if editing existing property
    if (data) {
      form.setFieldsValue({
        fullName: decryptSecureData(data?.full_name),
        email: decryptSecureData(data?.email),
        phone: decryptSecureData(data?.mobile_number),
        businessName: data?.company_name,
        regNo: data?.business_reg_number,
        stateProvince: data?.state_id,
        citySuburb: data?.suburb_id,
        address1: decryptSecureData(data?.street_address),
        address2: decryptSecureData(data?.apt),
        nic: data?.dl,
        zip: data?.suburb_id,
      });

      // Fetch relevant data based on pre-selected values (if needed)
      fetchLocationLevel1Data(data?.country_id);
      fetchLocationLevel2Data(data?.state_id);
    }
  }, [data]);

  const fetchCountryData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            let selectedCountry = response?.data?.output.find(
              (country) => country.country_id == data?.country_id
            );

            setPostalCodeRequired(
              selectedCountry.postal_code_required === 1 ? true : false
            );

            setLabels({
              level_1_label: selectedCountry?.level_1_label,
              level_2_label: selectedCountry?.level_2_label,
            });

            if (selectedCountry.postal_code_required === 1) {
              handleGetRelatedPostalCode(data?.postal_code);
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: data?.country_id,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setAllPostalCodes(response?.data.output);

            var postalCodeObject = [];
            response?.data.output?.forEach((item) => {
              let data = {
                value: item?.suberb_id,
                label:
                  item?.postal_code + ", " + item?.suberb + ", " + item?.state,
              };
              postalCodeObject.push(data);
            });
            setPostalCodeData(postalCodeObject);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchLocationLevel1Data = async (e) => {
    try {
      await getAllLocationLevel1Data({ country_id: e })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location1) => {
              let data = {
                value: location1?.level1_id,
                label: location1?.level1_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation1SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchLocationLevel2Data = async (e) => {
    try {
      await getAllLocationLevel2Data({
        country_id: data?.country_id,
        level1_id: e,
      })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location2) => {
              let data = {
                value: location2?.level2_id,
                label: location2?.level2_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation2SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const onFinish = async (values) => {
    const data = {
      user_id: getLocalStoragedata("userId"),
      full_name: values.fullName, //encrypted
      email: values.email, //encrypted
      company_name: values.businessName,
      business_reg_number: values.regNo,
      postal_code: values.zip,
      state_id: stateId,
      suburb_id: suburbId,
      street_address: values.address1, //encrypted
      apt: values.address2, //encrypted
      dl_number: values.nic,
      x_url: xURL,
      linkedin_url: linkedInURL,
      fb_url: fbURL,
      insta_url: instaURL,
      google_business: gBisURL,
    };

    try {
      await updatePersonalData(data)
        .then((response) => {
          if (response?.data?.success) {
            onClose();
            getWorkerProfileData();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const compareData = (initial, updated) => {
    let changes = {};

    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });

    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false); // Return null if no changes
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      {
        fullName: decryptSecureData(data?.full_name),
        email: decryptSecureData(data?.email),
        businessName: data?.company_name,
        regNo: data?.business_reg_number,
        stateProvince: data?.state_id,
        citySuburb: data?.suburb_id,
        address1: decryptSecureData(data?.street_address),
        address2: decryptSecureData(data?.apt),
        nic: data?.dl,
        zip: data?.suburb_id,
      },
      allFields
    );
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        if (isFieldChanged) {
          setShowExitConfirmation(true);
        } else {
          onClose();
        }
      }}
      footer={null}
      width={1000}
      title="Personal Info"
      className="xxsm:p-0 sm:p-4"
      style={{ top: 20 }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        className="flex flex-col gap-2"
        onFieldsChange={handleFormChange}
      >
        <div className="flex flex-col w-full">
          <Text className="ml-2">
            Full Name <span className="text-red-500">*</span>
          </Text>
          <Form.Item
            name="fullName"
            rules={[
              { required: true, message: "Please enter your full name!" },
            ]}
            className="mb-0"
          >
            <Input
              placeholder="Full Name"
              className="rounded-lg"
              size="large"
              maxLength={50}
            />
          </Form.Item>
        </div>

        <div className="flex flex-col w-full">
          <Text className="ml-2">
            Email
            {data?.country_id == 2 ||
            data?.country_id == 4 ||
            data?.country_id == 9 ? (
              <span></span>
            ) : (
              <span className="text-red-500"> *</span>
            )}
          </Text>
          <Form.Item
            name="email"
            rules={[
              {
                required:
                  data?.country_id == 2 ||
                  data?.country_id == 4 ||
                  data?.country_id == 9
                    ? false
                    : true,
                message: "Please enter your email!",
              },
              { type: "email", message: "Please enter a valid email!" },
            ]}
            className="mb-0"
          >
            <Input
              placeholder="Email"
              className="rounded-lg"
              size="large"
              maxLength={100}
            />
          </Form.Item>
        </div>

        <div className="flex flex-col w-full">
          <Text className="ml-2">
            Phone <span className="text-red-500">*</span>
          </Text>

          <Input
            placeholder="Phone"
            className="rounded-lg"
            size="large"
            value={decryptSecureData(data?.mobile_number)}
            disabled
          />
          <p className="text-xs font-medium text-[#939292] ml-2">
            Use mobile app to change your registered mobile number
          </p>
        </div>

        <div className="flex flex-col w-full mt-4">
          <Text className="ml-2">Business Name</Text>
          <Form.Item
            name="businessName"
            // rules={[
            //   { required: true, message: "Please enter your business name!" },
            // ]}
            className="mb-0"
          >
            <Input
              placeholder="Business Name"
              className="rounded-lg"
              size="large"
              maxLength={50}
            />
          </Form.Item>
        </div>

        <div className="flex flex-col w-full mb-4">
          <Text className="ml-2">Business Registration Number</Text>
          <Form.Item
            name="regNo"
            rules={[
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: "Invalid Characters not allowed!",
              },
            ]}
            className="mb-0"
          >
            <Input
              placeholder="Business Registration Number"
              className="rounded-lg"
              size="large"
              maxLength={25}
            />
          </Form.Item>
        </div>

        <div className="flex flex-col w-full">
          {postalCodeRequired && (
            <>
              <Text className="ml-2">
                Postal Code <span className="text-red-500">*</span>
              </Text>
              <Form.Item
                name="zip"
                rules={[
                  { required: true, message: "Postal Code is required!" },
                ]}
                className="mb-0"
              >
                <Select
                  size="large"
                  className="w-full"
                  placeholder="Enter Postal Code"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={postalCodeData}
                  notFoundContent={
                    postalCodeData === null
                      ? "Please enter your property postal code"
                      : "No Data"
                  }
                  onSearch={(e) => {
                    if (e?.length === 4) {
                      handleGetRelatedPostalCode(e);
                    }

                    if (e?.length < 4) {
                      setPostalCodeData(null);
                    }
                  }}
                  onSelect={(e) => {
                    let suburb = allPostalCodes.find(
                      (item) => item.suberb_id === e
                    );

                    setSuburbId(suburb.suberb_id);
                    setStateId(suburb.state_id);
                  }}
                />
              </Form.Item>
            </>
          )}
        </div>

        {!postalCodeRequired && (
          <>
            <div className="flex flex-col w-full">
              <Text className="ml-2">
                {labels.level_1_label} <span className="text-red-500">*</span>
              </Text>
              <Form.Item
                name="stateProvince"
                rules={[
                  {
                    required: true,
                    message: `${labels.level_1_label} is required!`,
                  },
                ]}
                className="mb-0"
              >
                <Select
                  size="large"
                  placeholder={`Select ${labels.level_1_label}`}
                  options={local1SelectData}
                  onSelect={(e) => {
                    setStateId(e);
                    fetchLocationLevel2Data(e);
                    setIsStateSelected(true);
                    setSuburbId(null);
                    form.setFieldValue("citySuburb", null);
                  }}
                  showSearch
                  className="rounded-lg"
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>

            <div className="flex flex-col w-full">
              <Text className="ml-2">
                {labels.level_2_label} <span className="text-red-500">*</span>
              </Text>
              <Form.Item
                name="citySuburb"
                rules={[
                  {
                    required: true,
                    message: `${labels.level_2_label} is required!`,
                  },
                ]}
                className="mb-0"
              >
                <Select
                  size="large"
                  placeholder={`Select ${labels.level_2_label}`}
                  options={local2SelectData}
                  className="rounded-lg"
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onSelect={(e) => {
                    setSuburbId(e);
                  }}
                  disabled={!isStateSelected}
                />
              </Form.Item>
            </div>
          </>
        )}

        <div className="flex flex-col w-full">
          <Text className="ml-2">Address</Text>
          <Form.Item
            name="address1"
            // rules={[{ required: true, message: "Address Line 1 required!" }]}
            className="mb-0"
          >
            <Input
              placeholder="Address Line 1"
              className="rounded-lg"
              size="large"
              maxLength={150}
            />
          </Form.Item>
          <Form.Item name="address2" className="mb-0 mt-2">
            <Input
              placeholder="Address Line 2"
              className="rounded-lg"
              size="large"
              maxLength={150}
            />
          </Form.Item>
        </div>

        <div className="flex flex-col w-full">
          <Text className="ml-2">
            {data?.country_id == 1 || data?.country_id == 3 ? "DL" : "NIC"}
          </Text>
          <Form.Item
            name="nic"
            className="mb-0"
            rules={[
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: "Invalid Characters not allowed!",
              },
            ]}
          >
            <Input
              placeholder={
                data?.country_id == 1 || data?.country_id == 3 ? "DL" : "NIC"
              }
              className="rounded-lg"
              size="large"
              maxLength={25}
            />
          </Form.Item>
        </div>

        <div className="flex flex-col sm:flex-row gap-2 justify-between items-center p-1 rounded-md shadow-md mt-2">
          <Text className="flex font-semibold text-base">Social Media</Text>
          <div className="flex flex-row gap-2">
            <div
              className="flex cursor-pointer"
              onClick={() => {
                setShowSMUpdate("fb");
              }}
            >
              {fbURL ? <FillFB size={50} /> : <EmptyFB />}
            </div>
            <div
              className="flex cursor-pointer"
              onClick={() => {
                setShowSMUpdate("ig");
              }}
            >
              {instaURL ? <FillInsta size={50} /> : <EmptyInsta />}
            </div>
            <div
              className="flex cursor-pointer"
              onClick={() => {
                setShowSMUpdate("x");
              }}
            >
              {xURL ? <FillX size={50} /> : <EmptyX />}
            </div>
            <div
              className="flex cursor-pointer"
              onClick={() => {
                setShowSMUpdate("li");
              }}
            >
              {linkedInURL ? <FillLinkedIn size={50} /> : <EmptyLinkedIn />}
            </div>
            <div
              className="flex cursor-pointer"
              onClick={() => {
                setShowSMUpdate("gb");
              }}
            >
              {gBisURL ? (
                <GoogleBusiness size={50} />
              ) : (
                <EmptyGoogleBusiness size={50} />
              )}
            </div>
          </div>
        </div>

        {/* Save Button */}
        <Form.Item>
          <div className="w-full flex justify-end mt-4">
            <Button
              type="primary"
              htmlType="submit"
              className="bg-primaryDarkest rounded-md min-w-40"
              disabled={!isFieldChanged}
            >
              <Text
                className={`text-xs font-semibold ${
                  !isFieldChanged ? "text-gray-500" : "text-white"
                }`}
              >
                Save Changes
              </Text>
            </Button>
          </div>
        </Form.Item>
      </Form>

      {showSMUpdate !== null && (
        <NotificationAlertBox
          open={showSMUpdate}
          onCancel={() => {
            setIsUrlChanged(false);
            setShowSMUpdate(null);
          }}
          popupWidth={700}
          content={
            <div className="flex flex-col gap-2 justify-center items-center">
              {showSMUpdate === "fb" ? (
                <FillFB size={81} />
              ) : showSMUpdate === "ig" ? (
                <FillInsta size={81} />
              ) : showSMUpdate === "x" ? (
                <FillX size={81} />
              ) : showSMUpdate === "gb" ? (
                <GoogleBusiness size={81} />
              ) : (
                <FillLinkedIn size={81} />
              )}
              <Text className="flex text-lg font-bold text-primaryDarkest">
                {showSMUpdate === "fb"
                  ? "Add your Facebook Profile"
                  : showSMUpdate === "ig"
                  ? "Add your Instagram Profile"
                  : showSMUpdate === "x"
                  ? "Add your X Profile"
                  : showSMUpdate === "gb"
                  ? "Google Business"
                  : "Add your LinkedIn Profile"}
              </Text>
              <div className="flex flex-col gap-2 w-[80%] justify-center items-center">
                <Text className="flex text-base text-center leading-tight">
                  Adding a social media account can help build trust with
                  clients and increase your chances of winning a job.
                </Text>
                <Divider className="my-2" />
              </div>
              <div className="flex flex-col w-full justify-center items-center">
                <Form
                  className="flex flex-col w-[80%] gap-4"
                  initialValues={{
                    link:
                      showSMUpdate === "fb"
                        ? fbURL
                        : showSMUpdate === "ig"
                        ? instaURL
                        : showSMUpdate === "x"
                        ? xURL
                        : showSMUpdate === "gb"
                        ? gBisURL
                        : linkedInURL,
                  }}
                  onFinish={(e) => {
                    if (showSMUpdate === "fb") {
                      setFbURL(e.link);
                    } else if (showSMUpdate === "ig") {
                      setInstaURL(e.link);
                    } else if (showSMUpdate === "x") {
                      setXURL(e.link);
                    } else if (showSMUpdate === "gb") {
                      setGBisURL(e.link);
                    } else {
                      setLinkedInURL(e.link);
                    }
                    setIsFieldChanged(true);
                    setIsUrlChanged(false);
                    setShowSMUpdate(null);
                  }}
                  onFieldsChange={(e) => {
                    let value = e[0].value;
                    if (showSMUpdate === "fb") {
                      if (fbURL !== value) {
                        setIsUrlChanged(true);
                      } else {
                        setIsUrlChanged(false);
                      }
                    } else if (showSMUpdate === "insta") {
                      if (instaURL !== value) {
                        setIsUrlChanged(true);
                      } else {
                        setIsUrlChanged(false);
                      }
                    } else if (showSMUpdate === "x") {
                      if (xURL !== value) {
                        setIsUrlChanged(true);
                      } else {
                        setIsUrlChanged(false);
                      }
                    } else if (showSMUpdate === "gb") {
                      if (gBisURL !== value) {
                        setIsUrlChanged(true);
                      } else {
                        setIsUrlChanged(false);
                      }
                    } else {
                      if (linkedInURL !== value) {
                        setIsUrlChanged(true);
                      } else {
                        setIsUrlChanged(false);
                      }
                    }
                  }}
                >
                  <div className="flex flex-col">
                    <Text className="ml-2">
                      {showSMUpdate === "fb"
                        ? "Enter Your Facebook Profile Link"
                        : showSMUpdate === "ig"
                        ? "Enter Your Instagram Profile Link"
                        : showSMUpdate === "x"
                        ? "Enter Your X Profile Link"
                        : showSMUpdate === "gb"
                        ? "Enter Your Google Business Profile Link"
                        : "Enter Your LinkedIn Profile Link"}
                    </Text>
                    <Form.Item
                      name="link"
                      rules={[
                        {
                          pattern:
                            showSMUpdate === "fb"
                              ? /^(https?:\/\/)?(www\.)?facebook\.com\/(?:[a-zA-Z0-9\.]+\/?)$/
                              : showSMUpdate === "ig"
                              ? /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+\/?$/
                              : showSMUpdate === "x"
                              ? /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/[a-zA-Z0-9_]{1,15}\/?$/
                              : showSMUpdate === "gb"
                              ? /^(https?:\/\/)?(www\.)?(maps.app.goo)\.gl\/[a-zA-Z0-9_-]+\/?$/
                              : /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+\/?$/,
                          message: `Invalid ${
                            showSMUpdate === "fb"
                              ? "Facebook"
                              : showSMUpdate === "ig"
                              ? "Instagram"
                              : showSMUpdate === "x"
                              ? "X"
                              : showSMUpdate === "gb"
                              ? "Google Business"
                              : "LinkedIn"
                          } Link!`,
                        },
                      ]}
                      className="mb-0"
                    >
                      <Input
                        size="large"
                        placeholder={
                          showSMUpdate === "fb"
                            ? "www.facebook.com/username"
                            : showSMUpdate === "ig"
                            ? "www.instagram.com/username"
                            : showSMUpdate === "x"
                            ? "www.x.com/username"
                            : showSMUpdate === "gb"
                            ? "www.maps.app.goo.gl/business-id"
                            : "www.linkedin.com/in/username"
                        }
                        className="rounded-lg"
                        maxLength={100}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item className="flex justify-center">
                    {showSMUpdate === "fb" ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="flex h-10 bg-primaryDarkest rounded-md min-w-60"
                        disabled={!isUrlChanged}
                      >
                        Add Facebook
                      </Button>
                    ) : showSMUpdate === "ig" ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="flex h-10 bg-primaryDarkest rounded-md min-w-60"
                        disabled={!isUrlChanged}
                      >
                        Add Instagram
                      </Button>
                    ) : showSMUpdate === "x" ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="flex h-10 bg-primaryDarkest rounded-md min-w-60"
                        disabled={!isUrlChanged}
                      >
                        Add X
                      </Button>
                    ) : showSMUpdate === "gb" ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="flex h-10 bg-primaryDarkest rounded-md min-w-60"
                        disabled={!isUrlChanged}
                      >
                        Add Google Business
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="flex h-10 bg-primaryDarkest rounded-md min-w-60"
                        disabled={!isUrlChanged}
                      >
                        Add LinkedIn
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          title="Social Media"
        />
      )}

      {showExitConfirmation && (
        <NotificationAlertBox
          open={showExitConfirmation}
          onCancel={() => {
            setShowExitConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-bold text-darkestColor text-center">
                You have unsaved changes. Would you like to save before leaving?
              </p>
              <p className="text-xs text-textColorThree text-center">
                You've made changes to your personal Profile. Save before
                leaving the page, or any unsaved changes will be lost.{" "}
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  No, Discard
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    setShowExitConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          }
          title="Are you sure?"
        />
      )}
    </Modal>
  );
};

export default UpdatePersonalInfo;
