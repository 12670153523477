import React from "react";

const NewWorkerWhite = ({ width, height }) => {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0291 15.8985C8.78872 16.6331 8.16851 17.0004 7.71301 16.7398C7.25752 16.4792 7.2684 15.7633 7.29016 14.3315L7.29579 13.961C7.30197 13.5542 7.30507 13.3507 7.22924 13.1719C7.15342 12.9932 7.00726 12.8593 6.71494 12.5915L6.44881 12.3477C5.42013 11.4054 4.90579 10.9343 5.02636 10.4052C5.14693 9.87616 5.82257 9.63954 7.17383 9.16629L7.52342 9.04386C7.90741 8.90938 8.0994 8.84214 8.24669 8.70733C8.39398 8.57252 8.4799 8.3854 8.65172 8.01116L8.80816 7.67045C9.41282 6.3535 9.71516 5.69502 10.2452 5.62864C10.7752 5.56226 11.1819 6.13193 11.9952 7.27128L12.2057 7.56605C12.4368 7.88981 12.5524 8.0517 12.7192 8.14716C12.8861 8.24262 13.0853 8.26086 13.4838 8.29734L13.8467 8.33055C15.249 8.45891 15.9502 8.52309 16.1572 9.01114C16.3642 9.49919 15.9399 10.0879 15.0914 11.2653L14.8718 11.5699C14.6307 11.9045 14.5101 12.0718 14.4659 12.2656C14.4218 12.4594 14.459 12.6578 14.5335 13.0546L14.6013 13.4158C14.8633 14.8121 14.9944 15.5102 14.5923 15.8782C14.1902 16.2462 13.5213 16.0404 12.1835 15.6287L11.8374 15.5222C11.4572 15.4052 11.2671 15.3467 11.0729 15.3711C10.8788 15.3954 10.7025 15.4997 10.3501 15.7085L10.0291 15.8985Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6333 20.8477C14.399 21.082 14.0191 21.082 13.7848 20.8477L12.1848 19.2477C11.9505 19.0134 11.9505 18.6335 12.1848 18.3992C12.4191 18.1649 12.799 18.1649 13.0333 18.3992L14.6333 19.9992C14.8677 20.2335 14.8677 20.6134 14.6333 20.8477ZM19.0333 19.6477C18.799 19.882 18.4191 19.882 18.1848 19.6477L16.1848 17.6477C15.9505 17.4134 15.9505 17.0335 16.1848 16.7992C16.4191 16.5649 16.799 16.5649 17.0333 16.7992L19.0333 18.7992C19.2677 19.0335 19.2677 19.4134 19.0333 19.6477ZM11.8333 18.0477C11.599 18.282 11.2191 18.282 10.9848 18.0477L10.5848 17.6477C10.3505 17.4134 10.3505 17.0335 10.5848 16.7992C10.8191 16.5649 11.199 16.5649 11.4333 16.7992L11.8333 17.1992C12.0677 17.4335 12.0677 17.8134 11.8333 18.0477ZM20.6333 16.4477C20.399 16.682 20.0191 16.682 19.7848 16.4477L19.3848 16.0477C19.1505 15.8134 19.1505 15.4335 19.3848 15.1992C19.6191 14.9649 19.999 14.9649 20.2333 15.1992L20.6333 15.5992C20.8677 15.8335 20.8677 16.2134 20.6333 16.4477ZM18.6333 14.4477C18.399 14.682 18.0191 14.682 17.7848 14.4477L16.5848 13.2477C16.3505 13.0134 16.3505 12.6335 16.5848 12.3992C16.8191 12.1649 17.199 12.1649 17.4333 12.3992L18.6333 13.5992C18.8677 13.8335 18.8677 14.2134 18.6333 14.4477Z"
        fill="white"
      />
    </svg>
  );
};

export default NewWorkerWhite;
