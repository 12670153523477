import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Input, Modal, Row, Typography } from "antd";
import React, { useContext, useState, useRef } from "react";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";
import EditImage from "../../assets/svg/EditImage";
import { NotificationContext } from "../../context/NotificationContext";
import TextArea from "antd/es/input/TextArea";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import PublicProfileService from "../../services/PublicProfileService";

const { Text } = Typography;

const UpdateWorkSamples = ({ open, onClose, getWorkerProfileData, data }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const [openAddEditDialog, setOpenAddEditDialog] = useState(null);
  const { workSampleUpload, savePortfolio, updatePortfolio, removeSample } =
    PublicProfileService();

  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Create a ref for the file input

  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [showMore, setShowMore] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file.size > 10 * 1024 * 1024) {
      openNotification("error", "File size exceeds the limit of 10 MB");
      return;
    }

    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      openNotification("error", "Please upload a JPEG/PNG image file.");
      return;
    }

    // Preview the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result); // Set the selected image as base64 string
    };
    reader.readAsDataURL(file);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const updateWorkSample = async (values) => {
    setUploading(true);

    if (selectedImage !== null) {
      try {
        const fileData = {
          userId: getLocalStoragedata("userId"),
          usecaseId: 4,
          file: selectedImage.split(",")[1],
        };

        await workSampleUpload(fileData)
          .then((response) => {
            if (response?.data?.success) {
              if (openAddEditDialog !== "new") {
                const formObj = new FormData();
                formObj.append("portfolio_id", openAddEditDialog.portfolio_id);
                formObj.append("trady_id", getLocalStoragedata("userId"));
                formObj.append("title", values.projectTitle);
                formObj.append("description", values.description);
                formObj.append("extension", response?.data.output.url);

                uploadWorkSample(formObj);
              } else {
                const formObj = new FormData();
                formObj.append("trady_id", getLocalStoragedata("userId"));
                formObj.append("title", values.projectTitle);
                formObj.append("description", values.description);
                formObj.append("extension", response?.data.output.url);

                saveWorkSample(formObj);
              }
            } else {
              openNotification("warning", response?.data?.message);
              setUploading(false);
            }
          })
          .catch((error) => {
            handleError(error);
            setUploading(false);
          });
      } catch (error) {
        openNotification("error", error);
        setUploading(false);
      }
    } else {
      if (openAddEditDialog !== "new") {
        const formObj = new FormData();
        formObj.append("portfolio_id", openAddEditDialog.portfolio_id);
        formObj.append("trady_id", getLocalStoragedata("userId"));
        formObj.append("title", values.projectTitle);
        formObj.append("description", values.description);

        uploadWorkSample(formObj);
      } else {
        const formObj = new FormData();
        formObj.append("trady_id", getLocalStoragedata("userId"));
        formObj.append("title", values.projectTitle);
        formObj.append("description", values.description);

        saveWorkSample(formObj);
      }
    }
  };

  const saveWorkSample = async (data) => {
    try {
      await savePortfolio(data)
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            setOpenAddEditDialog(null);
            getWorkerProfileData();
          } else {
            openNotification("warning", response?.data?.message);
            setUploading(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setUploading(false);
        });
    } catch (error) {
      openNotification("error", error);
      setUploading(false);
    }
  };

  const uploadWorkSample = async (data) => {
    try {
      await updatePortfolio(data)
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            setOpenAddEditDialog(null);
            getWorkerProfileData();
          } else {
            openNotification("warning", response?.data?.message);
            setUploading(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setUploading(false);
        });
    } catch (error) {
      openNotification("error", error);
      setUploading(false);
    }
  };

  const compareData = (initial, updated) => {
    let changes = {};

    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });

    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false); // Return null if no changes
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      {
        projectTitle: openAddEditDialog?.title,
        description: openAddEditDialog?.description,
      },
      allFields
    );
  };

  const removeWorkSample = async (selectedPortfolio) => {
    try {
      await removeSample({
        portfolio_id: selectedPortfolio.portfolio_id,
        status: 0,
      })
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            onClose();
            getWorkerProfileData();
          } else {
            openNotification("warning", response?.data?.message);
            setUploading(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setUploading(false);
        });
    } catch (error) {
      openNotification("error", error);
      setUploading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={1000}
      title="Add/Edit Work Samples"
      className=" p-2"
    >
      <div className="flex flex-col gap-4 w-full">
        <button
          className="flex flex-row gap-2 p-2 rounded-md border-[1px] border-primaryDarkest text-primaryDarkest font-medium text-base items-center justify-center w-fit"
          onClick={() => {
            setOpenAddEditDialog("new");
            setSelectedImage(null); // Reset selected image for new entry
          }}
        >
          <PlusOutlined className="text-primaryDarkest" />
          Add New Work Samples
        </button>
        <Row gutter={8} className="flex flex-row">
          {data?.portfolios.map((portfolio, index) => {
            return (
              <Col
                key={index}
                className="flex flex-col gap-2 mb-4 max-w-[300px]"
              >
                <Image
                  preview={false}
                  src={portfolio?.image}
                  alt={`Work sample ${index + 1}`}
                  className="object-cover rounded-lg md:max-w-[300px] md:max-h-[300px]"
                  onClick={() => {
                    setOpenAddEditDialog(portfolio);
                  }}
                  width={280}
                  height={200}
                />
                <div className="flex flex-col gap-0">
                  <Text className="flex font-bold text-base">
                    {portfolio?.title}
                  </Text>
                  <p className="font-normal text-xs text-black inline">
                    {portfolio?.description?.substring(0, 50)}.. &nbsp;
                    <span
                      className="underline text-primaryDark cursor-pointer text-black"
                      onClick={() => {
                        setShowMore(portfolio);
                      }}
                    >
                      Read More
                    </span>
                  </p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>

      {openAddEditDialog !== null && (
        <NotificationAlertBox
          open={openAddEditDialog !== null}
          onCancel={() => {
            if (!selectedImage && !isFieldChanged) {
              setOpenAddEditDialog(null);
              setSelectedImage(null); // Reset selected image when closing
            } else {
              setShowCloseConfirmation(true);
            }
          }}
          content={
            <div className="flex flex-col w-full">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    {openAddEditDialog === "new" ? (
                      <div
                        className="flex flex-col w-full h-full rounded-md justify-center items-center bg-[#E0EFE2] min-w-[300px] min-h-[260px] cursor-pointer"
                        onClick={triggerFileInput} // Trigger file input on click
                      >
                        {selectedImage ? (
                          <Image
                            preview={false}
                            src={selectedImage} // Display selected image preview
                            alt="Uploaded Preview"
                            className="object-cover rounded-lg md:max-w-[300px] md:max-h-[260px]"
                          />
                        ) : (
                          <>
                            <EditImage />
                            <Text className="flex text-sm text-primaryDark">
                              Upload Image
                            </Text>
                          </>
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          className="hidden" // Hiding the input
                          ref={fileInputRef} // Attach ref to input element
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col w-full h-full rounded-md justify-center items-center bg-[#E0EFE2] min-w-[300px] min-h-[260px] cursor-pointer">
                        <Image
                          preview={false}
                          src={selectedImage || openAddEditDialog?.image}
                          alt={openAddEditDialog?.title}
                          className="object-cover rounded-lg md:max-w-[300px] md:max-h-[300px]"
                        />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          className="hidden" // Hiding the input
                          ref={fileInputRef} // Attach ref to input element
                        />
                      </div>
                    )}

                    <button
                      className="flex flex-row gap-2 p-2 rounded-md border-[1px] border-primaryDarkest text-primaryDarkest font-medium text-base items-center justify-center w-full"
                      onClick={triggerFileInput} // Trigger file input on click
                    >
                      {selectedImage ? "Change Image" : "Upload Image"}
                    </button>
                  </div>

                  <div className="flex mt-4 ml-4">
                    {openAddEditDialog === "new" ? (
                      <div className="flex"></div>
                    ) : (
                      <div
                        className="flex text-red-600 cursor-pointer text-base font-medium"
                        onClick={() => {
                          removeWorkSample(openAddEditDialog);
                        }}
                      >
                        Remove
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <Form
                    onFinish={updateWorkSample}
                    initialValues={{
                      projectTitle: openAddEditDialog?.title,
                      description: openAddEditDialog?.description,
                    }}
                    onFieldsChange={handleFormChange}
                  >
                    <div className="flex flex-col w-full">
                      <Text className="ml-2">
                        Project Title <span className="text-red-500">*</span>
                      </Text>
                      <Form.Item
                        name="projectTitle"
                        rules={[
                          {
                            required: true,
                            message: "Project Title is required!",
                          },
                        ]}
                        className="mb-0"
                      >
                        <Input
                          placeholder="Project Title"
                          className="rounded-lg"
                          size="large"
                          maxLength={80}
                        />
                      </Form.Item>
                    </div>
                    <div className="flex flex-col w-full">
                      <Text className="ml-2">
                        Description <span className="text-red-500">*</span>
                      </Text>
                      <Form.Item
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Description is required!",
                          },
                        ]}
                        className="mb-0"
                      >
                        <TextArea
                          placeholder="Description"
                          className="rounded-lg"
                          autoSize={{ minRows: 8 }}
                          style={{
                            resize: "none",
                            padding: "12px",
                          }}
                          size="large"
                          maxLength={300}
                        />
                      </Form.Item>
                    </div>
                    <div className="flex flex-row w-fulll justify-end gap-2 mt-4">
                      <Button
                        type="default"
                        className="text-red-500 border border-red-500 text-sm font-semibold min-w-32"
                        onClick={() => {
                          setOpenAddEditDialog(null);
                        }}
                      >
                        Go Back
                      </Button>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold min-w-32"
                          disabled={!selectedImage && !isFieldChanged}
                          loading={uploading}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          }
          title={
            openAddEditDialog === "new"
              ? "Add New Work Sample"
              : "Edit Work Sample"
          }
          popupWidth={1000}
        />
      )}
      {showCloseConfirmation && (
        <NotificationAlertBox
          open={showCloseConfirmation}
          onCancel={() => {
            setShowCloseConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-medium text-darkestColor text-center">
                Are you sure you want to discard changes?
              </p>
              <p className="text-xs text-textColorThree text-center">
                All entered details will be lost.
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Yes,Exit
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    setShowCloseConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          }
          title="Are you sure?"
        />
      )}

      {showMore !== null && (
        <NotificationAlertBox
          open={showMore !== null}
          onCancel={() => {
            setShowMore(null);
          }}
          content={
            <div className="flex flex-col">
              <img
                src={showMore?.image}
                alt="Work sample preview"
                className="w-full h-auto"
              />
              <p className="text-sm font-medium mt-2">
                {showMore?.description}
              </p>
            </div>
          }
          title={showMore?.title}
          popupWidth={700}
        />
      )}
    </Modal>
  );
};

export default UpdateWorkSamples;
