import HeaderConfig from "../helpers/HeaderConfig";
import axios from "axios";

const PropertyService = () => {
  const { baseUrl, configAuth } = HeaderConfig();

  const fetchPropertyMetaData = async (data) => {
    return await axios.post(baseUrl + "property/meta", data, configAuth);
  };

  const fetchMyProperties = async (data) => {
    return await axios.post(baseUrl + "property/all", data, configAuth);
  };

  const createProperty = async (data) => {
    return await axios.post(baseUrl + "property/add", data, configAuth);
  };

  const deleteProperty = async (data) => {
    return await axios.post(baseUrl + "property/status", data, configAuth);
  };

  const updateProperty = async (data) => {
    return await axios.post(baseUrl + "property/edit", data, configAuth);
  };

  const makeDefaultProperty = async (data) => {
    return await axios.post(baseUrl + "property/default", data, configAuth);
  };

  return {
    fetchMyProperties,
    fetchPropertyMetaData,
    createProperty,
    deleteProperty,
    updateProperty,
    makeDefaultProperty,
  };
};

export default PropertyService;
