import React from "react";
import BackIcon from "../../assets/svg/BackIcon";
import { Button, Typography } from "antd";
import { SadIcon } from "../../assets/svg/SadIcon";
import { useNavigate } from "react-router-dom";

export const DeleteAccount = ({setDeleteAccountSteps }) => {
  const { Text } = Typography;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-6">
      <div
        className="flex flex-row gap-2 items-center cursor-pointer mb-5"
        onClick={() => {
          navigate("../my-account-worker");
        }}
      >
        <BackIcon />
        <Text className="flex text-textColorTwo font-medium">Back</Text>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center shadow-lg rounded-lg w-full md:w-[450px] bg-primaryLightest p-8">
          <SadIcon />
          <Text className="flex font-bold text-lg  text-textColorThree">
            We're Sad to See You Go!
          </Text>
          <p className="text-sm font-normal text-textColorThree text-center">
            Before you go, we want to make sure you're absolutely <br />
            and certain about deleting your account. Your data <br />
            settings will be lost permanently
          </p>

          <div>
            <Button
              type="primary"
              className="w-full bg-primaryDarkest text-base font-bold h-14 rounded-lg text-white mt-5"
              onClick={() => {
                navigate("/my-account-worker");
              }}
            >
              Go back, I have changed my mind
            </Button>
            <Button
              type="primary"
              className="w-full text-base font-bold h-14 rounded-lg text-primaryDarkest bg-white border border-primaryDarkest mt-5"
              onClick={() => {
                setDeleteAccountSteps("DELETE_ACCOUNT_OTP_CARD"); 
              }}
            >
              Continue Deleting
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
