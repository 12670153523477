import { Button } from "antd";
import SuccessIcon from "../../assets/svg/recomondation/SuccessIcon";
import Facebook from "../../assets/svg/recomondation/Facebook";
import Whatsapp from "../../assets/svg/recomondation/Whatsapp";
import Insta from "../../assets/svg/recomondation/Insta";
import XIcon from "../../assets/svg/recomondation/XIcon";
import Linkedln from "../../assets/svg/recomondation/Linkedln";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";

const Success = ({ onCancel, addFormValues, setShowQR }) => {
  const currentUrl = window.location.href;

  const socialUrls = {
    whatsapp: `https://wa.me/?text=${encodeURIComponent(currentUrl)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      currentUrl
    )}`,
    instagram: `https://www.instagram.com/?url=${encodeURIComponent(
      currentUrl
    )}`,
    twitter: `https://twitter.com/share?url=${encodeURIComponent(currentUrl)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      currentUrl
    )}`,
  };

  return (
    <div>
      <div className="items-center">
        <div className="flex justify-center items-center mt-5">
          <SuccessIcon />
        </div>
        <p className="text-center text-base font-medium">
          Support{" "}
          {getLocalStoragedata("token")
            ? decryptSecureData(getLocalStoragedata("userData")?.first_name)
            : addFormValues?.name}{" "}
          by sharing his profile
        </p>
        <div className="flex justify-center items-center mt-3">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="bg-primaryDarkest md:px-20 my-5"
            onClick={() => {
              // onCancel();
              setShowQR(true);
            }}
          >
            Share Profile
          </Button>
        </div>

        <div className="mt-5">
          <p className="text-center text-black">Share</p>
          <div className="flex justify-center items-center gap-1 mt-2">
            <a
              href={socialUrls.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Whatsapp />
            </a>

            <FacebookShareButton
              url={currentUrl}
              quote={"Check out this profile!"}
              hashtag={`${currentUrl.replace(/https?:\/\//, "")}`}
            >
              <Facebook />
            </FacebookShareButton>

            <a
              href={socialUrls.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Insta />
            </a>

            {/* Twitter Share Button */}
            <TwitterShareButton
              url={currentUrl}
              title="Check out this profile!"
            >
              <XIcon />
            </TwitterShareButton>

            {/* LinkedIn */}

            {/* <a
              href={linkedInShareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedln size={32} round />
            </a> */}

            <LinkedinShareButton
              url={currentUrl}
              title="Check out this profile!"
            >
              <Linkedln />
            </LinkedinShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Success;
