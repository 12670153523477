import React, { useEffect, useState } from "react";
import { Col, Image, Modal, Row, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import EditIconInfo from "../../assets/svg/EditIconInfo";
import UpdateWorkSamples from "../../components/workerProfile/UpdateWorkSamples";
import NotificationAlertBox from "../../components/alertBox/NotificationAlertBox";
import NoWorkSamples from "../../assets/svg/NoWorkSamples";

const { Text } = Typography;

const WorkSampleImageList = ({
  workSamples,
  listSize,
  data,
  getWorkerProfileData,
  editable,
}) => {
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [imageCount, setImageCount] = useState();

  const [editWorkSample, setEditWorkSample] = useState(false);
  const [showMore, setShowMore] = useState(null);

  const showModal = (index) => {
    setCurrentImage(index);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePrev = () => {
    setCurrentImage(
      (prev) => (prev - 1 + workSamples?.length) % workSamples?.length
    );
  };

  const handleNext = () => {
    setCurrentImage((prev) => (prev + 1) % workSamples?.length);
  };

  useEffect(() => {
    if (listSize === "small") {
      if (window.innerWidth < 640) {
        setImageCount(3);
      } else if (window.innerWidth < 768) {
        setImageCount(4);
      } else if (window.innerWidth < 1024) {
        setImageCount(6);
      } else {
        setImageCount(6);
      }
    } else {
      if (window.innerWidth < 640) {
        setImageCount(2);
      } else if (window.innerWidth < 992) {
        setImageCount(3);
      } else if (window.innerWidth < 1440) {
        setImageCount(4);
      } else if (window.innerWidth < 2560) {
        setImageCount(5);
      } else {
        setImageCount(6);
      }
    }
  }, []);

  return (
    <div className="flex flex-col items-center bg-white rounded-lg p-4 shadow-lg w-full mt-2">
      <div className="flex flex-row gap-2 justify-between w-full">
        <Text className="flex text-xl font-bold text-primaryDarkest w-full">
          Work Highlights
        </Text>
        {editable && workSamples?.length !== 0 && (
          <div
            className="cursor-pointer scale-75"
            onClick={() => {
              setEditWorkSample(true);
            }}
          >
            <EditIconInfo />
          </div>
        )}
      </div>

      {workSamples?.length === 0 ? (
        <div className="flex flex-col md:flex-row justify-between gap-2 w-full">
          <div className="flex flex-col gap-2 justify-between w-full lg:w-[40%]">
            <Text className="flex text-base font-normal">
              Showing your past work makes your profile more discoverable in job
              searches. Hurry up and add your work photos!
            </Text>
            <button
              className="flex border border-primaryDarkest text-primaryDarkest rounded-lg h-10 items-center justify-center"
              onClick={() => {
                setEditWorkSample(true);
              }}
            >
              Add Work Sample
            </button>
          </div>
          <div className="flex flex-col justify-center items-center">
            <NoWorkSamples />
          </div>
        </div>
      ) : (
        <>
          {listSize === "small" ? (
            <Row className="flex flex-row mt-3 w-full" gutter={32}>
              {workSamples?.slice(0, imageCount).map((sample, index) => (
                <Col
                  key={index}
                  className="relative cursor-pointer w-full "
                  onClick={() => showModal(index)}
                  span={
                    imageCount === 6
                      ? 2
                      : imageCount === 6
                      ? 4
                      : imageCount === 4
                      ? 4
                      : 4
                  }
                >
                  <Image
                    preview={false}
                    src={sample?.image}
                    alt={`Work sample ${index + 1}`}
                    width={80}
                    height={80}
                    className="object-cover rounded-lg h-[150px]"
                  />
                  {sample?.title && (
                    <div className="flex flex-col">
                      <div className="flex font-semibold text-sm">
                        {sample?.title}
                      </div>
                      <div className="flex font-normal text-xs">
                        {sample?.description}
                      </div>
                    </div>
                  )}
                  {index === imageCount - 1 &&
                    workSamples.length > imageCount && (
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center rounded-lg ml-4 md:max-w-[100px] lg:max-w-[100px] lg:max-h-[100px] md:max-h-[100px]">
                        <p className="text-white text-lg font-bold">
                          +{workSamples.length - imageCount}
                        </p>
                        <p className="text-white text-sm font-bold">See All</p>
                      </div>
                    )}
                </Col>
              ))}
            </Row>
          ) : (
            <Row
              className={`flex flex-row ${
                workSamples?.length < 5 ? "justify-start" : "justify-evenly"
              } mt-3 w-full`}
              gutter={32}
            >
              {workSamples?.slice(0, imageCount).map((sample, index) => (
                <Col
                  key={index}
                  className="flex flex-col gap-2 relative cursor-pointer w-full"
                  span={
                    imageCount === 2
                      ? 12
                      : imageCount === 3
                      ? 8
                      : imageCount === 4
                      ? 6
                      : imageCount === 5
                      ? 4
                      : imageCount === 6
                      ? 4
                      : 4
                  }
                >
                  <img
                    preview={false}
                    src={sample?.image}
                    alt={`Work sample ${index + 1}`}
                    onClick={() => showModal(index)}
                    className="object-cover rounded-lg h-[150px]"
                  />

                  {index !== imageCount && sample?.title ? (
                    <div className="flex flex-col">
                      <div className="flex font-semibold text-sm">
                        {sample?.title}
                      </div>
                      <div className="flex font-normal text-xs">
                        {sample?.description?.length > 50 ? (
                          <div>
                            <p className="inline">
                              {sample?.description?.substring(0, 50)}{" "}
                              <span
                                className="underline text-primaryDark cursor-pointer"
                                onClick={() => {
                                  setShowMore(sample);
                                }}
                              >
                                Read More
                              </span>
                            </p>
                          </div>
                        ) : (
                          sample?.description
                        )}
                      </div>
                    </div>
                  ) : (
                    <>{/* <div className="h-[47px]"></div> */}</>
                  )}

                  {index === imageCount - 1 &&
                    workSamples.length > imageCount && (
                      <div
                        className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center rounded-lg ml-4 h-[150px]"
                        onClick={() => showModal(index)}
                      >
                        <p className="text-white text-2xl font-bold">
                          +{workSamples.length - imageCount}
                        </p>
                        <p className="text-white text-base font-bold">
                          See All
                        </p>
                      </div>
                    )}
                </Col>
              ))}
            </Row>
          )}

          <Modal open={visible} footer={null} onCancel={handleCancel} centered>
            <div className="relative pt-4">
              <img
                src={workSamples && workSamples[currentImage]?.image}
                alt={`Work sample ${currentImage + 1}`}
                className="w-full h-auto mt-4"
              />
              {workSamples && workSamples[currentImage]?.title && (
                <div className="absolute flex flex-col bottom-0 p-2 bg-slate-900 w-full bg-opacity-50">
                  <div className="flex font-semibold text-sm text-white">
                    {workSamples[currentImage]?.title}
                  </div>
                  <p className="font-normal text-xs text-white inline">
                    {workSamples[currentImage]?.description?.substring(0, 50)}..
                    &nbsp;
                    <span
                      className="underline text-primaryDark cursor-pointer text-white"
                      onClick={() => {
                        setShowMore(workSamples[currentImage]);
                      }}
                    >
                      Read More
                    </span>
                  </p>
                </div>
              )}
              <LeftOutlined
                className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-2xl cursor-pointer"
                onClick={handlePrev}
              />
              <RightOutlined
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-2xl cursor-pointer"
                onClick={handleNext}
              />
            </div>
            <div className="flex overflow-x-auto mt-2">
              {workSamples?.map((sample, index) => (
                <img
                  key={index}
                  src={sample.image}
                  alt={`Thumbnail ${index + 1}`}
                  className={`w-16 h-16 object-cover mx-1 cursor-pointer ${
                    index === currentImage ? "border-2 border-primaryColor" : ""
                  }`}
                  onClick={() => setCurrentImage(index)}
                />
              ))}
            </div>
          </Modal>
        </>
      )}

      {editWorkSample && (
        <UpdateWorkSamples
          open={editWorkSample}
          onClose={() => {
            setEditWorkSample(false);
          }}
          getWorkerProfileData={getWorkerProfileData}
          data={data}
        />
      )}

      {showMore !== null && (
        <NotificationAlertBox
          open={showMore !== null}
          onCancel={() => {
            setShowMore(null);
          }}
          content={
            <div className="flex flex-col">
              <img
                src={showMore?.image}
                alt="Work sample preview"
                className="w-full h-auto"
              />
              <p className="text-sm font-medium mt-2">
                {showMore?.description}
              </p>
            </div>
          }
          title={showMore?.title}
          popupWidth={700}
        />
      )}
    </div>
  );
};

export default WorkSampleImageList;
