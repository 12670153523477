import React from "react";

const Whatsapp = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.3848 0.00927734H8.89481C4.21695 0.00927734 0.424805 3.80143 0.424805 8.47928V38.9693C0.424805 43.6471 4.21695 47.4393 8.89481 47.4393H39.3848C44.0627 47.4393 47.8548 43.6471 47.8548 38.9693V8.47928C47.8548 3.80143 44.0627 0.00927734 39.3848 0.00927734Z"
        fill="#25D366"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.1348 23.0691C37.0096 19.7872 35.6256 16.6794 33.2701 14.3906C30.9146 12.1019 27.7684 10.8078 24.4842 10.7769C21.2 10.7461 18.0299 11.9807 15.6318 14.2248C13.2337 16.4689 11.7916 19.5501 11.6047 22.8291C11.6047 23.0191 11.6047 23.1991 11.6047 23.3891C11.6028 25.6989 12.2359 27.9648 13.4348 29.9391L11.1348 36.7291L18.2048 34.4891C20.0964 35.5248 22.2181 36.0681 24.3748 36.0691C26.0485 36.0744 27.7069 35.7493 29.2548 35.1124C30.8026 34.4755 32.2096 33.5394 33.395 32.3577C34.5804 31.176 35.5209 29.772 36.1627 28.2261C36.8044 26.6802 37.1348 25.0229 37.1348 23.3491V23.0691ZM24.3647 34.0691C22.2676 34.0767 20.215 33.4644 18.4647 32.3091L14.3347 33.6291L15.6747 29.6291C14.36 27.8117 13.6594 25.6222 13.6747 23.3791C13.6757 23.0449 13.6957 22.711 13.7347 22.3791C14.0157 19.7239 15.2757 17.2685 17.2689 15.4919C19.2621 13.7153 21.8456 12.7449 24.5156 12.7698C27.1855 12.7948 29.7504 13.8134 31.71 15.627C33.6696 17.4406 34.8835 19.9191 35.1147 22.5791C35.1299 22.8556 35.1299 23.1327 35.1147 23.4091C35.0962 26.2435 33.9539 28.9547 31.9384 30.9476C29.923 32.9406 27.1991 34.0524 24.3647 34.0391V34.0691Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.2248 25.9493C29.9048 25.7893 28.3648 25.0393 28.0848 24.9493C27.8048 24.8593 27.5848 24.7893 27.3748 25.0993C27.1648 25.4093 26.5648 26.0993 26.3748 26.3193C26.1848 26.5393 26.0048 26.5493 25.6948 26.3993C24.7703 26.0346 23.9172 25.5099 23.1748 24.8493C22.4912 24.2268 21.9047 23.5055 21.4348 22.7093C21.2548 22.3993 21.4348 22.2293 21.5748 22.0693C21.7148 21.9093 21.8848 21.7092 22.0448 21.5292L22.1548 21.3793C22.2298 21.2606 22.2966 21.137 22.3548 21.0093C22.3994 20.9245 22.4228 20.8301 22.4228 20.7343C22.4228 20.6384 22.3994 20.544 22.3548 20.4593C22.2748 20.3093 21.6448 18.7793 21.3548 18.1593C21.0648 17.5393 20.8348 17.6392 20.6448 17.6392C20.4548 17.6392 20.2548 17.6392 20.0448 17.6392C19.887 17.6432 19.7317 17.68 19.5888 17.7471C19.446 17.8142 19.3186 17.9103 19.2148 18.0292C18.8599 18.3617 18.5787 18.7649 18.3893 19.2127C18.1998 19.6605 18.1063 20.1431 18.1148 20.6293C18.1273 20.9679 18.181 21.3037 18.2748 21.6293C18.5221 22.4079 18.9009 23.1384 19.3948 23.7893C20.7073 25.8197 22.56 27.4438 24.7448 28.4793C27.9248 29.7193 27.9248 29.2992 28.5048 29.2492C28.941 29.1653 29.3544 28.99 29.7181 28.7349C30.0817 28.4799 30.3873 28.1508 30.6148 27.7693C30.8201 27.3082 30.8827 26.7962 30.7948 26.2993C30.7448 26.1793 30.5348 26.0993 30.2248 25.9493Z"
        fill="white"
      />
    </svg>
  );
};

export default Whatsapp;
