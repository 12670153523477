import React from "react";

const CopyIcon = () => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32983 13.5421C9.32983 10.8303 9.32983 9.47438 10.1723 8.63193C11.0147 7.78949 12.3706 7.78949 15.0824 7.78949H17.9587C20.6705 7.78949 22.0264 7.78949 22.8688 8.63193C23.7113 9.47438 23.7113 10.8303 23.7113 13.5421V18.3359C23.7113 21.0477 23.7113 22.4036 22.8688 23.246C22.0264 24.0884 20.6705 24.0884 17.9587 24.0884H15.0824C12.3706 24.0884 11.0147 24.0884 10.1723 23.246C9.32983 22.4036 9.32983 21.0477 9.32983 18.3359V13.5421Z"
        stroke="white"
        stroke-width="1.43814"
      />
      <path
        d="M9.3299 21.2117C7.74137 21.2117 6.45361 19.924 6.45361 18.3355V12.5829C6.45361 8.96716 6.45361 7.1593 7.57687 6.03604C8.70013 4.91278 10.508 4.91278 14.1237 4.91278H17.9588C19.5473 4.91278 20.835 6.20054 20.835 7.78907"
        stroke="white"
        stroke-width="1.43814"
      />
    </svg>
  );
};

export default CopyIcon;
