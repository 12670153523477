import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Avatar,
  Row,
  Col,
  Select,
  DatePicker,
  Pagination,
  Dropdown,
  Menu,
} from "antd";
import NavBar from "../components/navbar/NavBar";
import SearchButton from "../assets/svg/SearchButton";
import LocationIconFull from "../assets/svg/LocationIconFull";
import KebabMenu from "../assets/svg/KebabMenu";
import NoImage from "../assets/img/noImageProfile.png";
import { useNavigate } from "react-router-dom";
import CreateJobPostHelpers from "../helpers/apiHelpers/CreateJobPostHelpers";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import { CreateJobPostContext } from "../context/CreateJobPostContext";
import NoData from "../components/noData/NoData";
import { NotificationContext } from "../context/NotificationContext";
import CreateJobPostServices from "../services/CreateJobPostServices";
import { formatDate } from "../helpers/dateFormatter/dateFormatter";
import NotificationAlertBox from "../components/alertBox/NotificationAlertBox";

const MyJobs = () => {
  const navigate = useNavigate();
  const { openNotification, handleError } = useContext(NotificationContext);
  const { jobPostList } = useContext(CreateJobPostContext);

  const { getAllJobTypes } = CreateJobPostServices();

  const { handleGetAllJobPostList, handleDeleteJobPost } =
    CreateJobPostHelpers();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedJobTypeValue, setSelectedJobTypeValue] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);

  const [jobTypeSelectorArray, setJobTypeSelectorArray] = useState([]);
  const [openDeleteAlertBox, setOpenDeleteAlertBox] = useState(false);
  const [jobToDeleteId, setJobToDeleteId] = useState(null);

  useEffect(() => {
    handleGetAllJobPostList({
      user_id: getLocalStoragedata("userData")?.user_id,
      user_type_id: 2, //client,
      offset: 5,
      page_number: currentPage,
      search: searchText,
      posted_date: selectedDate, //"2024-10-05"
      job_type_id: selectedJobTypeValue,
    });

    fetchAllJobTypes();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Dropdown menu for each job
  const menu = (job_id) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setOpenDeleteAlertBox(true);
          setJobToDeleteId(job_id);
          // deleteTheJobPost(job_id); // Call delete with job_id
        }}
      >
        <p className="text-sm font-medium text-warringColorOne">Delete Job</p>
      </Menu.Item>
    </Menu>
  );

  const deleteTheJobPost = (job_id) => {
    // Call your delete API here using job_id
    handleDeleteJobPost({
      job_id: job_id,
      user_id: getLocalStoragedata("userData")?.user_id,
    })
      .then(() => {
        handleGetAllJobPostList({
          user_id: getLocalStoragedata("userData")?.user_id,
          user_type_id: 2, //client,
          offset: 5,
          page_number: currentPage,
          search: searchText,
          posted_date: selectedDate, //"2024-10-05"
          job_type_id: selectedJobTypeValue,
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  //tasker user images
  // const renderProfileAvatars = (profiles) => {
  //   const displayedProfiles = profiles.slice(0, 5); // Limit to 5 profiles
  //   const remainingSlots = 5 - displayedProfiles.length; // Calculate the number of placeholders needed

  //   return (
  //     <Avatar.Group maxCount={5}>
  //       {displayedProfiles.map((profile, index) => (
  //         <Avatar
  //           key={index}
  //           src={profile?.profile_picture || NoImage} // Fallback to placeholder if no profile_picture
  //         />
  //       ))}

  //       {/* Fill remaining slots with placeholders if needed */}
  //       {Array.from({ length: remainingSlots }).map((_, index) => (
  //         <Avatar key={`placeholder-${index}`} src={NoImage} />
  //       ))}
  //     </Avatar.Group>
  //   );
  // };

  const renderProfileAvatars = (profiles) => {
    // Limit to a maximum of 5 profiles
    const displayedProfiles = profiles.slice(0, 5);

    return (
      <Avatar.Group maxCount={5}>
        {displayedProfiles.map((profile, index) => (
          <Avatar
            key={index}
            src={profile?.profile_picture || NoImage} // Fallback to placeholder if no profile_picture
          />
        ))}
      </Avatar.Group>
    );
  };

  //get all job type value
  const fetchAllJobTypes = async () => {
    try {
      await getAllJobTypes()
        .then((response) => {
          if (response?.data.success) {
            var allJobTypeObject = [];
            response?.data.output?.forEach((item) => {
              let data = {
                value: item?.job_type_id,
                label: item?.job_type,
              };
              allJobTypeObject.push(data);
            });
            setJobTypeSelectorArray(allJobTypeObject);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <Layout>
      <NavBar />
      <div className="relative content overflow-hidden bg-white">
        <div className="mt-10 flex flex-col gap-8 md:gap-10 lg:gap-16">
          <div className="flex w-full justify-center items-center">
            <div className="max-w-[1073px] xl:w-[1073px] mx-5">
              <Row className="flex flex-row mb-5" gutter={32}>
                <Col
                  span={24}
                  md={{ span: 12 }}
                  className="flex flex-row gap-5 mb-3"
                >
                  <Input
                    placeholder="Search Job..."
                    onChange={(e) => {
                      setSearchText(e?.target?.value);
                      setCurrentPage(1);
                      handleGetAllJobPostList({
                        user_id: getLocalStoragedata("userData")?.user_id,
                        user_type_id: 2, //client,
                        offset: 5,
                        page_number: 1,
                        search: e?.target?.value,
                        posted_date: selectedDate === "" ? null : selectedDate, //"2024-10-05"
                        job_type_id: selectedJobTypeValue,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.value === "") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <button
                    onClick={() => {
                      setCurrentPage(1);
                      handleGetAllJobPostList({
                        user_id: getLocalStoragedata("userData")?.user_id,
                        user_type_id: 2, //client,
                        offset: 5,
                        page_number: 1,
                        search: searchText,
                        posted_date: selectedDate === "" ? null : selectedDate, //"2024-10-05"
                        job_type_id: selectedJobTypeValue,
                      });
                    }}
                  >
                    <SearchButton />
                  </button>
                </Col>

                <Col span={12} md={{ span: 6 }}>
                  <Select
                    showSearch
                    allowClear
                    onChange={(value, option) => {
                      setSelectedJobTypeValue(value);
                      setCurrentPage(1);
                      handleGetAllJobPostList({
                        user_id: getLocalStoragedata("userData")?.user_id,
                        user_type_id: 2, //client,
                        offset: 5,
                        page_number: 1,
                        search: searchText,
                        posted_date: selectedDate === "" ? null : selectedDate, //"2024-10-05"
                        job_type_id: value === undefined ? 0 : value,
                      });
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={jobTypeSelectorArray}
                    placeholder="All job types"
                    className="w-full"
                  />
                </Col>

                <Col span={12} md={{ span: 6 }}>
                  <DatePicker
                    className="w-full"
                    placeholder="Search by date"
                    onChange={(date, dateString) => {
                      setCurrentPage(1);
                      setSelectedDate(dateString);
                      handleGetAllJobPostList({
                        user_id: getLocalStoragedata("userData")?.user_id,
                        user_type_id: 2, //client,
                        offset: 5,
                        page_number: 1,
                        search: searchText,
                        posted_date: dateString === "" ? null : dateString, //"2024-10-05"
                        job_type_id: selectedJobTypeValue,
                      });
                    }}
                  />
                </Col>
              </Row>

              {jobPostList?.jobList === null ||
              jobPostList?.jobList?.length === 0 ? (
                <NoData
                  title="No Jobs Posted Yet"
                  subTitle="You haven't posted any jobs yet. Start by creating a new job to find the right workers for your needs!"
                />
              ) : (
                <div>
                  {jobPostList?.jobList?.map((job, index) => {
                    return (
                      <div
                        key={index}
                        className="border-[1px] rounded-lg p-2 mb-3 flex flex-row justify-between items-center gap-2"
                      >
                        <Row gutter={32} className="w-full">
                          <Col
                            span={24}
                            sm={{ span: 6 }}
                            lg={{ span: 10 }}
                            className="flex flex-col lg:flex-row gap-5 justify-between border-r-[1px]"
                          >
                            <div>
                              <p className="text-sm font-bold text-textColorThree">
                                {job?.job_type}
                              </p>

                              <p className="flex flex-row gap-1 text-xs font-normal">
                                <LocationIconFull />
                                {job?.state},{job?.suburb},
                                {job?.job_postal_code}
                              </p>
                            </div>

                            <div>
                              <p className="text-sm font-bold">
                                Job ID : {job?.job_number}
                              </p>
                              <p className="text-xs font-medium">
                                Posted: {formatDate(job?.posted_date)}
                                {/* no.......................................... */}
                              </p>
                            </div>
                          </Col>

                          <Col
                            span={24}
                            sm={{ span: 18 }}
                            lg={{ span: 14 }}
                            className="flex justify-start items-center"
                          >
                            <Row gutter={16}>
                              <Col
                                span={24}
                                md={{ span: 8 }}
                                className="flex flex-row gap-2 justify-start items-center md:w-[300px]"
                              >
                                <p className="text-4xl font-bold text-primaryColor">
                                  {job?.worker_count}
                                </p>
                                <p className="text-sm font-medium text-primaryColor">
                                  Worker(s) Selected for this Job
                                </p>
                              </Col>

                              <Col
                                span={24}
                                md={{ span: 6 }}
                                className="flex md:justify-start items-center min-w-[120px]"
                              >
                                {/* Render Profile Avatars */}
                                {renderProfileAvatars(job?.profiles || [])}
                              </Col>

                              <Col span={24} md={{ span: 5 }}>
                                {job?.job_status === 1 ||
                                job?.job_status === 2 ||
                                job?.job_status === 3 ||
                                job?.job_status === 4 ? (
                                  <p className="bg-[#89ED3B] rounded-full text-center mt-3 w-[100px] text-[12px]">
                                    ONGOING
                                  </p>
                                ) : job?.job_status === 5 ||
                                  job?.job_status === 6 ? (
                                  <p className="bg-[#89BFFF] rounded-full text-center mt-3 w-[100px] text-[12px]">
                                    COMPLETED
                                  </p>
                                ) : (
                                  <p className="bg-[#89ED3B] rounded-full text-center mt-3 w-[100px] text-[12px]">
                                    ONGOING
                                  </p>
                                )}
                              </Col>

                              <Col
                                span={24}
                                md={{ span: 5 }}
                                className="flex flex-row gap-3 md:justify-end items-center"
                              >
                                <Button
                                  type="primary"
                                  className="bg-primaryDark mt-3 md:mt-0"
                                  onClick={() => {
                                    navigate(
                                      `../my-jobs/job-id=${job?.job_id}=${job?.country_id}`
                                    );
                                  }}
                                >
                                  View job
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Dropdown
                          overlay={menu(job?.job_id)} // Pass the job_id here
                          trigger={["click"]}
                          placement="bottomRight"
                        >
                          <div
                            onClick={(e) => e.preventDefault()}
                            className="cursor-pointer"
                          >
                            <KebabMenu />
                          </div>
                        </Dropdown>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* pagination............... */}
              {jobPostList?.jobCount / 5 > 1 &&
                jobPostList?.jobList !== null && (
                  <div className="flex justify-end mt-10">
                    <Pagination
                      current={currentPage}
                      pageSize={5}
                      total={jobPostList?.jobCount}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      responsive={true}
                    />
                  </div>
                )}
            </div>

            {openDeleteAlertBox && (
              <NotificationAlertBox
                open={openDeleteAlertBox}
                onCancel={() => {
                  setOpenDeleteAlertBox(false);
                  setJobToDeleteId(null);
                }}
                title="Delete Job"
                content={
                  <div>
                    <p className="text-md font-medium text-darkestColor text-center">
                      Are you sure you want to delete this job?{" "}
                    </p>
                    <p className="text-xs text-textColorThree text-center">
                      Once deleted, it cannot be recovered, and potential
                      candidates will no longer be able to apply.
                    </p>

                    <div className="flex justify-center items-center mt-5 gap-4">
                      <Button
                        type="default"
                        className="text-warringColorOne border border-warringColorOne text-sm font-semibold w-full"
                        

                        onClick={() => {
                          deleteTheJobPost(jobToDeleteId);
                          // setSelectedServicesAreaSuburbList([]);
                          setOpenDeleteAlertBox(false);
                          setJobToDeleteId(null);
                        }}
                      >
                        Delete Job
                      </Button>
                      <Button
                        type="primary"
                        className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                        onClick={() => {
                          setOpenDeleteAlertBox(false);
                        }}
                      >
                        No,Keep it
                      </Button>
                    </div>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyJobs;
