import React from "react";

const RecommendationTextIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 9.39602C4.75 6.88186 4.75 5.62478 5.53105 4.84373C6.3121 4.06268 7.56918 4.06268 10.0833 4.06268H11.4167C13.9308 4.06268 15.1879 4.06268 15.969 4.84373C16.75 5.62478 16.75 6.88186 16.75 9.39602V12.0627C16.75 14.5768 16.75 15.8339 15.969 16.615C15.1879 17.396 13.9308 17.396 11.4167 17.396H10.0833C7.56918 17.396 6.3121 17.396 5.53105 16.615C4.75 15.8339 4.75 14.5768 4.75 12.0627V9.39602Z"
        stroke="#2C8B52"
        stroke-width="1.2"
      />
      <path
        d="M8.08331 10.7294H13.4166"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M8.08331 8.06268H13.4166"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M8.08331 13.3961H11.4166"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default RecommendationTextIcon;
