import React, { useState } from "react";
import { Col, Divider, Modal, Pagination, Row } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";
import { formatDate } from "../../helpers/dateFormatter/dateFormatter";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import HighlySkillIcon from "../../assets/svg/recomondation/HighlySkillIcon";
import ProfessionalIcon from "../../assets/svg/recomondation/ProfessionalIcon";
import FriendlyIcon from "../../assets/svg/recomondation/FriendlyIcon";
import TimeSavingIcon from "../../assets/svg/recomondation/TimeSavingIcon";

const WorkerRecomondationBox = ({ open, onCancel, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = data?.recommends?.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon color="#000000" />}
      footer={false}
      width={600}
    >
      <div>
        <div className="mt-5">
          <p className="text-base font-semibold text-primaryDarkest">
            All Recommendations ({data?.recommends?.length})
          </p>
        </div>
      </div>

      <div className="mt-3">
        <Row gutter={8}>
          {currentItems?.map((item) => {
            return (
              <Col span={24} className="mb-3">
                <div className="border-[1px] border-gray-400 rounded-lg p-3">
                  <div className="flex flex-row justify-between items-center">
                    <p className="text-base font-semibold">
                      {decryptSecureData(item?.full_name)}{" "}
                    </p>
                    {/* {Array.from({ length: item?.rate }, (_, i) => (
                        <ReviewStar height={13} width={13} key={i} />
                      ))} */}

                    <div className="flex flex-row justify-end items-center gap-1">
                      {item?.reasons?.map((reson) => {
                        return (
                          <div>
                            {reson?.reason_id === 1 && (
                              <HighlySkillIcon className="w-5" />
                            )}

                            {reson?.reason_id === 2 && (
                              <ProfessionalIcon className="w-5" />
                            )}

                            {reson?.reason_id === 3 && (
                              <FriendlyIcon className="w-5" />
                            )}

                            {reson?.reason_id === 4 && (
                              <TimeSavingIcon className="w-5" />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <p className="text-xs text-textColorTwo font-medium">
                    {formatDate(item?.created_at)}, {item?.know}
                  </p>
                  <Divider className="my-1 bg-gray-200" />
                  <p className="text-xs">{item?.recommendation}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      {data?.recommends?.length > 2 && (
        <div className="flex justify-end">
          <Pagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={data?.recommends?.length}
            onChange={handlePageChange}
            showSizeChanger={false}
            responsive={true}
          />
        </div>
      )}
    </Modal>
  );
};

export default WorkerRecomondationBox;
