import React from "react";

const NoWorkSamples = () => {
  return (
    <svg
      width="250"
      height="180"
      viewBox="0 0 333 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6614_215045)">
        <path
          d="M355.265 181.177H79.4893V181.315H355.265V181.177Z"
          fill="#EBEBEB"
        />
        <path
          d="M327.633 190.051H309.366V190.189H327.633V190.051Z"
          fill="#EBEBEB"
        />
        <path
          d="M262.175 191.551H257.382V191.689H262.175V191.551Z"
          fill="#EBEBEB"
        />
        <path
          d="M308.814 184.933H298.23V185.071H308.814V184.933Z"
          fill="#EBEBEB"
        />
        <path
          d="M132.246 185.859H108.424V185.997H132.246V185.859Z"
          fill="#EBEBEB"
        />
        <path
          d="M140.651 185.859H137.16V185.997H140.651V185.859Z"
          fill="#EBEBEB"
        />
        <path
          d="M203.671 188.187H152.002V188.325H203.671V188.187Z"
          fill="#EBEBEB"
        />
        <path
          d="M210.207 156.577H103.708C102.874 156.576 102.074 156.243 101.485 155.653C100.895 155.062 100.564 154.262 100.564 153.428V3.71993C100.571 2.89041 100.906 2.09725 101.494 1.51274C102.083 0.928226 102.879 0.599566 103.708 0.598145H210.207C211.043 0.598145 211.844 0.929952 212.434 1.52057C213.025 2.11119 213.357 2.91224 213.357 3.74751V153.428C213.357 154.263 213.025 155.064 212.434 155.655C211.844 156.245 211.043 156.577 210.207 156.577ZM103.708 0.708455C102.91 0.709916 102.146 1.02784 101.582 1.59244C101.019 2.15705 100.702 2.92219 100.702 3.71993V153.428C100.702 154.226 101.019 154.991 101.582 155.555C102.146 156.12 102.91 156.438 103.708 156.439H210.207C211.006 156.438 211.771 156.12 212.335 155.556C212.9 154.991 213.217 154.226 213.219 153.428V3.71993C213.217 2.92169 212.9 2.15655 212.335 1.59211C211.771 1.02766 211.006 0.709913 210.207 0.708455H103.708Z"
          fill="#EBEBEB"
        />
        <path
          d="M329.513 156.577H223.009C222.174 156.576 221.374 156.243 220.783 155.653C220.193 155.063 219.861 154.263 219.859 153.428V3.71993C219.868 2.88991 220.204 2.09675 220.793 1.51239C221.383 0.928033 222.179 0.59955 223.009 0.598145H329.513C330.342 0.601015 331.136 0.930316 331.724 1.51467C332.311 2.09903 332.645 2.89136 332.652 3.71993V153.428C332.652 154.261 332.322 155.061 331.733 155.651C331.145 156.241 330.347 156.574 329.513 156.577ZM223.009 0.708455C222.21 0.709913 221.445 1.02766 220.881 1.59211C220.316 2.15655 219.999 2.92169 219.997 3.71993V153.428C219.999 154.226 220.316 154.991 220.881 155.556C221.445 156.12 222.21 156.438 223.009 156.439H329.513C330.312 156.438 331.077 156.12 331.641 155.556C332.206 154.991 332.524 154.226 332.525 153.428V3.71993C332.524 2.92169 332.206 2.15655 331.641 1.59211C331.077 1.02766 330.312 0.709913 329.513 0.708455H223.009Z"
          fill="#EBEBEB"
        />
        <path
          d="M310.364 163.45C310.447 162.898 312.377 149.562 308.511 144.967C308.126 144.482 307.638 144.089 307.083 143.815C306.528 143.542 305.919 143.394 305.301 143.384C297.491 143.202 296.426 149.451 296.415 149.534L297.336 149.677C297.369 149.451 298.296 144.162 305.273 144.316C305.76 144.322 306.239 144.437 306.675 144.653C307.111 144.869 307.494 145.18 307.794 145.563C311.395 149.843 309.448 163.174 309.448 163.312L310.364 163.45Z"
          fill="#EBEBEB"
        />
        <path
          d="M310.833 162.843L311.765 162.81C311.765 162.688 311.456 150.538 317.44 148.028C323.309 145.574 325.951 150.626 326.056 150.841L326.888 150.422C326.861 150.361 323.761 144.355 317.082 147.173C310.507 149.925 310.816 162.313 310.833 162.843Z"
          fill="#EBEBEB"
        />
        <path
          d="M310.805 163.797C310.805 163.604 310.866 144.388 314.611 138.768C318.207 133.368 323.987 135.911 324.257 136.01L324.638 135.161C324.572 135.133 317.876 132.177 313.833 138.238C309.934 144.09 309.878 162.981 309.878 163.781L310.805 163.797Z"
          fill="#EBEBEB"
        />
        <path
          d="M304.181 143.847C304.181 143.847 307.59 145.105 306.575 148.067C305.56 151.029 294.788 155.915 294.788 155.915C294.788 155.915 290.271 151.779 294.419 145.05C298.566 138.321 304.087 141.724 304.181 143.847Z"
          fill="#E0E0E0"
        />
        <path
          d="M314.776 137.753C314.776 137.753 312.36 137.494 312.57 136.021C312.78 134.548 321.974 130.754 327.412 136.887C327.412 136.887 323.932 142.866 318.902 142.954C313.872 143.042 314.776 137.753 314.776 137.753Z"
          fill="#E0E0E0"
        />
        <path
          d="M317.264 147.598C317.264 147.598 315.609 146.253 317.396 145.596C319.183 144.94 328.041 145.122 328.129 153.869C328.129 153.869 317.832 155.585 316.547 152.248C315.262 148.911 317.264 147.598 317.264 147.598Z"
          fill="#E0E0E0"
        />
        <path
          d="M302.35 181.292H318.334L320.055 163.797H300.629L302.35 181.292Z"
          fill="#EBEBEB"
        />
        <path
          d="M299.774 165.728H320.91V161.867H299.774V165.728Z"
          fill="#E0E0E0"
        />
        <path
          d="M155.3 125.663C155.3 125.663 153.309 121.328 155.582 117.048C157.854 112.768 158.51 111.764 157.065 110.887C155.62 110.01 154.953 114.312 154.953 114.312C154.229 112.366 154.154 110.238 154.738 108.245C155.747 105.151 156.392 100.109 155.002 99.5689C153.613 99.0284 154.738 105.857 153.546 108.184C152.937 106.549 152.544 104.841 152.377 103.104C152.212 100.622 152.471 96.6512 151.384 95.6363C150.298 94.6215 149.597 95.322 150.667 100.804C151.309 104.076 151.664 107.398 151.726 110.732C151.726 110.732 149.366 103.562 147.783 103.325C146.2 103.088 146.205 105.448 147.556 107.467C148.908 109.486 151.528 113.11 151.969 114.974C151.969 114.974 149.377 109.558 147.049 111.218C144.721 112.878 149.079 116.149 151.02 117.329C152.962 118.509 154.33 125.917 154.33 125.917L155.3 125.663Z"
          fill="#EBEBEB"
        />
        <path
          d="M153.728 127.455C153.728 127.455 155.664 123.087 153.342 118.846C151.02 114.604 150.347 113.606 151.787 112.707C153.226 111.808 153.938 116.099 153.938 116.099C154.635 114.144 154.685 112.017 154.081 110.032C153.033 106.954 152.344 101.924 153.712 101.361C155.08 100.799 154.048 107.644 155.273 109.955C155.859 108.311 156.23 106.598 156.376 104.858C156.508 102.371 156.205 98.4107 157.28 97.3738C158.356 96.3368 159.062 97.0318 158.058 102.536C157.454 105.821 157.139 109.152 157.115 112.492C157.115 112.492 159.387 105.261 160.976 105.029C162.564 104.798 162.581 107.125 161.252 109.166C159.922 111.207 157.347 114.869 156.944 116.739C156.944 116.739 159.47 111.284 161.82 112.911C164.169 114.538 159.851 117.875 157.92 119.077C155.99 120.28 154.732 127.715 154.732 127.715L153.728 127.455Z"
          fill="#F5F5F5"
        />
        <path
          d="M162.658 124.328H146.663L147.959 135.778H161.367L162.658 124.328Z"
          fill="#E0E0E0"
        />
        <path
          d="M107.051 135.514V181.293H110.36V142.132H162.758V181.293H166.618V135.514H107.051Z"
          fill="#E0E0E0"
        />
        <path
          d="M137.386 135.514V181.293H140.695V142.132H193.093V181.293H196.953V135.514H137.386Z"
          fill="#EBEBEB"
        />
        <path
          d="M207.963 163.224L206.308 162.992C206.6 160.929 208.327 157.024 212.739 157.024C217.962 157.024 220.566 160.041 220.825 163.036L219.17 163.179C218.977 160.94 216.909 158.679 212.734 158.679C208.685 158.679 208.001 163.042 207.963 163.224Z"
          fill="#E0E0E0"
        />
        <path
          d="M227.322 161.767H200.445C198.973 161.767 197.781 162.96 197.781 164.431V178.33C197.781 179.801 198.973 180.994 200.445 180.994H227.322C228.793 180.994 229.986 179.801 229.986 178.33V164.431C229.986 162.96 228.793 161.767 227.322 161.767Z"
          fill="#EBEBEB"
        />
        <path
          d="M220.841 181.292C198.073 181.292 196.97 165.678 196.97 165.512L197.897 163.703C197.935 164.282 199.728 179.626 220.858 179.626C229.556 179.626 237.063 179.626 237.824 177.972C238.491 176.51 235.231 172.754 230.003 166.737V164.409C236.665 172.081 240.438 176.207 239.329 178.639C238.16 181.193 233.13 181.292 220.841 181.292Z"
          fill="#E0E0E0"
        />
        <path
          d="M108.231 10.3827V26.6811C108.233 26.8919 108.279 27.0999 108.365 27.2925C108.45 27.4851 108.574 27.6582 108.729 27.8013C108.884 27.9443 109.067 28.0542 109.265 28.1243C109.464 28.1944 109.675 28.2231 109.886 28.2089H164.942C164.942 28.2089 171.306 25.2415 170.854 21.0938C170.49 17.7845 170.121 15.0874 169.812 13.0357C169.631 11.8304 169.022 10.7307 168.096 9.93838C167.17 9.14609 165.989 8.71439 164.771 8.72252H109.886C109.668 8.72252 109.452 8.7655 109.251 8.849C109.05 8.9325 108.867 9.05488 108.714 9.20911C108.56 9.36334 108.438 9.5464 108.355 9.74779C108.273 9.94918 108.23 10.1649 108.231 10.3827Z"
          fill="#F5F5F5"
        />
        <path
          d="M108.231 10.3828V26.6812C108.233 26.892 108.279 27.1 108.365 27.2926C108.45 27.4852 108.574 27.6583 108.729 27.8014C108.884 27.9444 109.067 28.0543 109.265 28.1244C109.464 28.1944 109.675 28.2232 109.886 28.209H114.739C114.739 28.209 121.11 25.2416 120.652 21.0939C120.293 17.7846 119.918 15.0875 119.615 13.0358C119.433 11.831 118.824 10.7319 117.898 9.93983C116.972 9.14774 115.792 8.71563 114.574 8.72262H109.886C109.668 8.72262 109.452 8.76559 109.251 8.84909C109.05 8.93259 108.867 9.05497 108.714 9.2092C108.56 9.36344 108.438 9.54649 108.355 9.74788C108.273 9.94927 108.23 10.165 108.231 10.3828Z"
          fill="#E6E6E6"
        />
        <path
          d="M169.586 22.4009H121.496C121.496 22.4009 119.907 25.9253 117.21 27.3649H164.197C164.197 27.3649 169.172 25.098 169.586 22.4009Z"
          fill="#E0E0E0"
        />
        <path
          d="M166.271 25.7217H119.836L120.531 27.3818H166.271V25.7217Z"
          fill="#EBEBEB"
        />
        <path
          d="M167.931 23.6477H121.496L122.185 25.3079H167.931V23.6477Z"
          fill="#EBEBEB"
        />
        <path
          d="M123.15 21.6455H124.325V21.1601H123.15V21.6455Z"
          fill="#E0E0E0"
        />
        <path
          d="M124.816 21.6455H125.991V21.1601H124.816V21.6455Z"
          fill="#E0E0E0"
        />
        <path
          d="M217.378 206.084C276.44 206.084 324.318 203.289 324.318 199.841C324.318 196.392 276.44 193.597 217.378 193.597C158.316 193.597 110.438 196.392 110.438 199.841C110.438 203.289 158.316 206.084 217.378 206.084Z"
          fill="#F5F5F5"
        />
        <path
          d="M313.1 51.2201V143.55C313.1 144.061 312.999 144.566 312.804 145.038C312.609 145.51 312.322 145.938 311.961 146.3C311.6 146.661 311.171 146.947 310.7 147.142C310.228 147.338 309.722 147.438 309.211 147.438H178.494C177.462 147.438 176.472 147.029 175.742 146.3C175.012 145.571 174.601 144.582 174.6 143.55V51.2201C174.6 50.1874 175.01 49.1969 175.74 48.4667C176.47 47.7364 177.461 47.3262 178.494 47.3262H309.211C310.243 47.3276 311.232 47.7385 311.961 48.4686C312.69 49.1987 313.1 50.1883 313.1 51.2201Z"
          fill="#407BFF"
        />
        <path
          opacity="0.8"
          d="M313.1 51.2201V143.55C313.1 144.061 312.999 144.566 312.804 145.038C312.609 145.51 312.322 145.938 311.961 146.3C311.6 146.661 311.171 146.947 310.7 147.142C310.228 147.338 309.722 147.438 309.211 147.438H178.494C177.462 147.438 176.472 147.029 175.742 146.3C175.012 145.571 174.601 144.582 174.6 143.55V51.2201C174.6 50.1874 175.01 49.1969 175.74 48.4667C176.47 47.7364 177.461 47.3262 178.494 47.3262H309.211C310.243 47.3276 311.232 47.7385 311.961 48.4686C312.69 49.1987 313.1 50.1883 313.1 51.2201Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M313.1 51.2201V58.3903H174.61V51.2201C174.61 50.1874 175.021 49.1969 175.751 48.4667C176.481 47.7364 177.472 47.3262 178.504 47.3262H309.222C310.252 47.3305 311.238 47.7427 311.965 48.4725C312.692 49.2023 313.1 50.1902 313.1 51.2201Z"
          fill="black"
        />
        <path
          opacity="0.4"
          d="M308.505 53.2496C308.506 53.6559 308.387 54.0534 308.162 54.3916C307.937 54.7299 307.616 54.9937 307.241 55.1497C306.866 55.3057 306.453 55.3468 306.055 55.2678C305.656 55.1888 305.29 54.9932 305.003 54.706C304.716 54.4187 304.52 54.0526 304.441 53.6541C304.362 53.2556 304.403 52.8426 304.559 52.4674C304.715 52.0923 304.979 51.7719 305.317 51.5469C305.655 51.3218 306.053 51.2023 306.459 51.2034C307.002 51.2034 307.522 51.419 307.906 51.8027C308.29 52.1865 308.505 52.7069 308.505 53.2496Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M301.06 53.2496C301.061 53.6559 300.941 54.0534 300.716 54.3916C300.491 54.7299 300.171 54.9937 299.796 55.1497C299.42 55.3057 299.007 55.3468 298.609 55.2678C298.21 55.1888 297.844 54.9932 297.557 54.706C297.27 54.4187 297.074 54.0526 296.995 53.6541C296.916 53.2556 296.957 52.8426 297.113 52.4674C297.269 52.0923 297.533 51.7719 297.871 51.5469C298.21 51.3218 298.607 51.2023 299.013 51.2034C299.556 51.2034 300.077 51.419 300.46 51.8027C300.844 52.1865 301.06 52.7069 301.06 53.2496Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M293.614 53.2496C293.615 53.6559 293.495 54.0534 293.27 54.3916C293.045 54.7299 292.725 54.9937 292.35 55.1497C291.975 55.3057 291.562 55.3468 291.163 55.2678C290.765 55.1888 290.398 54.9932 290.111 54.706C289.824 54.4187 289.628 54.0526 289.549 53.6541C289.47 53.2556 289.512 52.8426 289.667 52.4674C289.823 52.0923 290.087 51.7719 290.426 51.5469C290.764 51.3218 291.161 51.2023 291.568 51.2034C292.11 51.2034 292.631 51.419 293.014 51.8027C293.398 52.1865 293.614 52.7069 293.614 53.2496Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M310.563 63.1279H177.098V70.6897H310.563V63.1279Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M310.563 73.0613H177.148V138.508H310.563V73.0613Z"
          fill="white"
        />
        <path
          d="M289.787 37.7361L324.638 37.7361V8.9379L289.787 8.9379V37.7361Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M289.787 8.93799V37.7362H324.638V8.93799H289.787ZM290.92 10.078H323.491V36.5962H290.92V10.078Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M289.787 37.7361L324.638 37.7361V8.9379L289.787 8.9379V37.7361Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M323.505 28.7382L313.51 21.4775L306.093 28.7382L298.67 24.4971L290.92 29.2607V36.5959H323.505V28.7382Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M302.353 19.0077C303.673 19.0077 304.742 17.9383 304.742 16.6191C304.742 15.3 303.673 14.2306 302.353 14.2306C301.034 14.2306 299.965 15.3 299.965 16.6191C299.965 17.9383 301.034 19.0077 302.353 19.0077Z"
          fill="white"
        />
        <path
          d="M280.646 147.969H238.494V182.799H280.646V147.969Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M238.494 147.969V182.799H280.646V147.969H238.494ZM279.276 181.422H239.865V149.339H279.276V181.422Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M239.872 168.23L247.872 160.142L254.596 167.66L258.919 165.38L262.563 169.18L265.447 167.66L269.131 170.992H279.276V181.422H239.872V168.23Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M266.343 154.509C266.511 154.948 266.569 155.42 266.513 155.887C266.457 156.353 266.287 156.798 266.02 157.184C265.752 157.57 265.395 157.884 264.978 158.101C264.561 158.317 264.098 158.428 263.629 158.425C263.324 158.426 263.022 158.378 262.733 158.282C262.907 158.737 263.194 159.14 263.566 159.454C263.938 159.768 264.383 159.983 264.861 160.078C265.338 160.174 265.832 160.147 266.296 160C266.76 159.853 267.18 159.591 267.515 159.238C267.851 158.885 268.092 158.453 268.215 157.983C268.339 157.512 268.341 157.017 268.222 156.545C268.103 156.073 267.867 155.639 267.534 155.283C267.202 154.927 266.785 154.661 266.323 154.509H266.343Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M266.126 181.442H239.865V168.23L247.872 160.142L254.597 167.66L258.926 165.38L262.563 169.174L265.447 167.66L269.132 170.992C269.132 170.992 257.121 173.028 257.236 176.638C257.352 180.248 266.126 181.442 266.126 181.442Z"
          fill="white"
        />
        <path
          d="M313.008 77.6831H256.667V124.239H313.008V77.6831Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M256.667 77.6831V124.239H312.987V77.6831H256.667ZM311.175 122.407H258.505V79.522H311.175V122.407Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M258.505 103.013L268.235 95.3185L280.436 104.676L296.009 92.2039L311.175 103.393V122.4H258.505V103.013Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M311.168 110.254H258.505V122.4H311.168V110.254Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M281.4 110.254L291.911 122.4H301.709L281.4 110.254Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M279.676 94.6267C281.34 94.6267 282.689 93.2778 282.689 91.6139C282.689 89.95 281.34 88.6011 279.676 88.6011C278.012 88.6011 276.664 89.95 276.664 91.6139C276.664 93.2778 278.012 94.6267 279.676 94.6267Z"
          fill="white"
        />
        <path
          d="M145.327 96.9065H181.902V66.6833H145.327V96.9065Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M145.32 66.6904V96.9136H181.902V66.6904H145.32ZM146.515 67.8847H180.707V95.7057H146.515V67.8847Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M145.327 96.9065H181.902V66.6833H145.327V96.9065Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M180.708 83.1318L174.39 78.1376L166.472 84.2107L156.361 76.1155L146.515 83.3761V95.7191H180.708V83.1318Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M180.708 87.8276H146.515V95.7125H180.708V87.8276Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M165.841 87.8276L159.021 95.7193H152.656L165.841 87.8276Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M165.006 75.7284C165.006 76.1149 165.121 76.4928 165.336 76.8142C165.55 77.1355 165.856 77.386 166.213 77.5339C166.57 77.6818 166.963 77.7205 167.342 77.6451C167.721 77.5697 168.069 77.3836 168.342 77.1103C168.616 76.837 168.802 76.4888 168.877 76.1097C168.953 75.7306 168.914 75.3377 168.766 74.9806C168.618 74.6235 168.368 74.3183 168.046 74.1035C167.725 73.8888 167.347 73.7742 166.961 73.7742C166.442 73.7742 165.945 73.9801 165.579 74.3466C165.212 74.7131 165.006 75.2101 165.006 75.7284Z"
          fill="white"
        />
        <path d="M270 16.5918H186.795V85.3437H270V16.5918Z" fill="#407BFF" />
        <path
          opacity="0.6"
          d="M186.795 16.5918V85.3437H270V16.5918H186.795ZM267.286 82.6295H189.509V19.306H267.286V82.6295Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M189.509 63.8676L213.36 46.5371L231.078 63.8676L248.795 53.7299L267.286 65.1229V82.6298H189.509V63.8676Z"
          fill="white"
        />
        <path
          opacity="0.6"
          d="M245.694 34.9267C245.694 36.0541 245.36 37.1561 244.733 38.0934C244.107 39.0308 243.217 39.7614 242.175 40.1928C241.134 40.6242 239.988 40.7371 238.882 40.5171C237.776 40.2972 236.761 39.7543 235.963 38.9572C235.166 38.16 234.623 37.1444 234.403 36.0387C234.184 34.9331 234.296 33.787 234.728 32.7455C235.159 31.7039 235.89 30.8137 236.827 30.1874C237.765 29.5611 238.867 29.2268 239.994 29.2268C241.506 29.2268 242.955 29.8273 244.024 30.8963C245.093 31.9652 245.694 33.415 245.694 34.9267Z"
          fill="white"
        />
        <path
          d="M101.555 89.8698L106.88 85.7342C106.88 85.7342 107.164 83.3825 108.327 82.6814C109.489 81.9803 114.264 80.0989 115.426 80.1876C116.589 80.2764 116.926 84.7314 116.598 85.4591C116.27 86.1868 109.33 88.2191 109.33 88.2191L102.913 94.5556L101.555 89.8698Z"
          fill="#E4897B"
        />
        <path
          d="M61.8679 104.566C69.8551 102.578 82.3505 100.431 87.2315 97.9104C92.1126 95.39 99.9666 89.4617 101.475 89.1245C101.475 89.1245 104.404 91.1568 105.345 92.8607C105.345 92.8607 98.4047 101.895 88.2521 106.705C78.0996 111.515 64.69 115.296 64.69 115.296L61.8679 104.566Z"
          fill="#407BFF"
        />
        <path
          opacity="0.5"
          d="M105.345 92.8518C105.345 92.8518 98.4047 101.895 88.2521 106.705C84.8088 108.329 80.9838 109.856 77.434 111.142C70.5384 113.654 64.69 115.305 64.69 115.305L63.1991 109.643L61.8679 104.575C69.8551 102.578 82.3505 100.44 87.2227 97.9192C92.0948 95.3988 99.9666 89.4706 101.475 89.1333C101.475 89.1245 104.413 91.1567 105.345 92.8518Z"
          fill="white"
        />
        <path
          d="M101.476 89.1331L103.73 87.3582L107.43 90.5264L105.345 92.8604L101.476 89.1331Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M77.4341 111.134C70.5385 113.645 64.6902 115.296 64.6902 115.296L63.1992 109.634C67.9901 109.49 72.7784 109.995 77.4341 111.134Z"
          fill="black"
        />
        <path
          d="M64.2464 153.474L68.5151 166.457C81.6761 163.396 115.87 156.962 121.053 171.436C127.487 189.416 80.9661 203.757 55.212 204.636C29.4579 205.515 15.8709 191.209 32.138 153.678L64.2464 153.474Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M64.2464 153.474L68.5151 166.457C81.6761 163.396 115.87 156.962 121.053 171.436C127.487 189.416 80.9661 203.757 55.212 204.636C29.4579 205.515 15.8709 191.209 32.138 153.678L64.2464 153.474Z"
          fill="black"
        />
        <path
          d="M113.598 196.853C113.57 196.875 113.536 196.886 113.501 196.886C113.465 196.886 113.431 196.875 113.403 196.853C113.383 196.829 113.373 196.799 113.373 196.769C113.373 196.738 113.383 196.708 113.403 196.684C113.492 196.4 114.344 193.924 115.178 193.667C115.269 193.644 115.365 193.646 115.456 193.673C115.546 193.699 115.628 193.749 115.693 193.818C115.81 193.917 115.9 194.046 115.953 194.191C116.006 194.335 116.02 194.491 115.994 194.643C115.857 195.107 115.57 195.513 115.178 195.797C114.691 196.205 114.161 196.559 113.598 196.853ZM113.82 196.383C114.621 196.027 115.269 195.398 115.648 194.608C115.672 194.503 115.665 194.393 115.627 194.292C115.589 194.191 115.522 194.103 115.435 194.04C115.412 194.01 115.379 193.988 115.343 193.977C115.306 193.965 115.267 193.966 115.231 193.978C115.159 194.002 115.092 194.041 115.036 194.093C114.493 194.775 114.081 195.551 113.82 196.383Z"
          fill="#263238"
        />
        <path
          d="M113.545 196.889C113.517 196.896 113.487 196.897 113.459 196.889C113.431 196.881 113.405 196.865 113.385 196.844C113.305 196.773 112.116 195.273 112.311 194.262C112.335 194.135 112.385 194.015 112.456 193.908C112.528 193.801 112.621 193.71 112.729 193.64C112.819 193.554 112.935 193.501 113.059 193.49C113.183 193.479 113.308 193.51 113.412 193.578C114.033 194.013 113.962 196.019 113.651 196.782V196.862C113.621 196.884 113.583 196.893 113.545 196.889ZM112.871 193.951C112.763 194.042 112.691 194.168 112.666 194.306C112.699 195.064 112.979 195.791 113.465 196.374C113.66 195.486 113.598 194.102 113.234 193.853C113.19 193.853 113.119 193.774 112.933 193.898L112.871 193.951Z"
          fill="#263238"
        />
        <path
          d="M68.329 201.122C68.344 201.091 68.3689 201.067 68.3994 201.053C68.43 201.038 68.4646 201.034 68.4976 201.042C68.5285 201.044 68.5578 201.056 68.5804 201.077C68.6031 201.098 68.6178 201.127 68.6218 201.157C68.684 201.45 69.1366 204.024 68.5065 204.707C68.4382 204.773 68.3532 204.818 68.2609 204.838C68.1685 204.859 68.0723 204.853 67.9829 204.822C67.8313 204.793 67.6902 204.724 67.5734 204.622C67.4567 204.521 67.3684 204.391 67.3173 204.246C67.2128 203.772 67.2626 203.278 67.4593 202.835C67.6899 202.235 67.9813 201.661 68.329 201.122ZM68.329 201.645C67.7961 202.34 67.5314 203.203 67.5835 204.077C67.6139 204.18 67.6738 204.272 67.7558 204.341C67.8378 204.411 67.9383 204.455 68.045 204.467C68.0795 204.481 68.1175 204.484 68.1538 204.477C68.1902 204.469 68.2234 204.45 68.2491 204.423C68.3035 204.367 68.3458 204.301 68.3733 204.228C68.5312 203.359 68.5281 202.469 68.3645 201.601L68.329 201.645Z"
          fill="#263238"
        />
        <path
          d="M85.1817 188.05L74.5943 191.822L69.5002 193.632L70.8048 199.197L75.7391 198.149L87.7731 195.585L85.1817 188.05Z"
          fill="#EBB376"
        />
        <path
          d="M72.1977 199.462L71.3103 192.895C71.2995 192.808 71.2712 192.724 71.227 192.648C71.1827 192.572 71.1236 192.506 71.053 192.454C70.9824 192.401 70.902 192.364 70.8166 192.344C70.7311 192.323 70.6424 192.321 70.5559 192.336L67.3167 192.877C67.3167 192.877 62.8794 193.285 61.9919 194.421C59.436 197.723 61.0334 212.304 62.7374 212.818C63.3675 213.005 64.0242 212.144 64.2993 211.558C65.1868 209.677 66.6777 204.76 67.4143 203.207C68.3018 201.388 70.6535 200.492 71.7185 200.172C71.8711 200.132 72.0039 200.038 72.0922 199.908C72.1804 199.777 72.2179 199.619 72.1977 199.462Z"
          fill="#407BFF"
        />
        <path
          d="M68.3551 201.025C68.3787 201.009 68.4065 201 68.435 201C68.4635 201 68.4913 201.009 68.5149 201.025C68.6213 201.025 70.3874 201.788 70.7069 202.8C70.7475 202.922 70.763 203.05 70.7523 203.179C70.7416 203.307 70.7051 203.431 70.6448 203.545C70.6087 203.665 70.5328 203.769 70.4295 203.84C70.3262 203.911 70.2018 203.945 70.0768 203.935C69.3224 203.847 68.4172 202.054 68.3019 201.273V201.202C68.288 201.171 68.2858 201.136 68.2955 201.103C68.3053 201.071 68.3264 201.043 68.3551 201.025ZM70.3696 203.27C70.4186 203.138 70.4186 202.993 70.3696 202.862C70.1655 202.24 69.1804 201.681 68.6746 201.442C68.9142 202.285 69.6508 203.483 70.0945 203.527C70.0945 203.527 70.2365 203.527 70.3341 203.341V203.27H70.3696Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M85.1814 188.05L74.594 191.822L75.7388 198.149L87.7728 195.585L85.1814 188.05Z"
          fill="black"
        />
        <path
          d="M77.6117 199.098L80.6557 199.32L78.6234 188.839L75.1624 190.747L77.6117 199.098Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M77.6117 199.098L80.6557 199.32L78.6234 188.839L75.1624 190.747L77.6117 199.098Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M33.4246 158.373L49.2835 175.962C49.2835 175.962 66.8286 175.572 88.1277 185.991C88.1277 185.991 110.989 176.548 113.092 168.703C115.195 160.858 65.1069 166.404 65.1069 166.404L33.4246 158.373Z"
          fill="black"
        />
        <path
          d="M30.1857 115.349C33.0167 123.851 33.1498 143.064 32.147 153.678L64.2464 153.474C69.7664 132.299 67.9028 117.443 67.4324 112.482C66.8467 106.448 65.9149 106.217 65.9149 106.217C65.9149 106.217 59.3654 105.578 53.6679 105.622C49.2556 105.787 44.8605 106.265 40.5157 107.051C37.2832 107.591 34.0834 108.311 30.9311 109.208C30.5986 109.302 30.2887 109.464 30.0205 109.682C29.7523 109.9 29.5314 110.171 29.3711 110.477C29.2109 110.783 29.1147 111.119 29.0884 111.464C29.0621 111.809 29.1064 112.155 29.2183 112.482C29.5556 113.432 29.8839 114.408 30.1857 115.349Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M64.2464 153.475L44.8909 153.599L32.147 153.679C32.9833 143.791 32.8971 133.847 31.8896 123.975C31.6006 121.052 31.0299 118.163 30.1857 115.349C29.8839 114.408 29.5556 113.432 29.2183 112.438C29.1064 112.111 29.0621 111.765 29.0884 111.42C29.1147 111.075 29.2109 110.739 29.3711 110.433C29.5314 110.127 29.7523 109.856 30.0205 109.638C30.2887 109.42 30.5986 109.258 30.9311 109.164C34.0842 108.282 37.2841 107.577 40.5157 107.051C44.8616 106.28 49.2567 105.817 53.6679 105.667C59.3654 105.623 65.9149 106.262 65.9149 106.262C65.9149 106.262 66.8467 106.492 67.4324 112.527C67.9028 117.444 69.7664 132.3 64.2464 153.475Z"
          fill="white"
        />
        <path
          d="M54.6795 122.501C67.9914 118.33 68.231 141.928 54.4132 145.203C40.5955 148.477 42.0332 126.477 54.6795 122.501Z"
          fill="#407BFF"
        />
        <path
          d="M62.1963 123.842C64.6013 123.789 53.4814 142.984 43.3022 144.742C43.3022 144.742 50.5084 145.833 58.9837 136.195C67.4589 126.558 66.6247 123.602 65.9769 122.883C65.329 122.165 62.1963 123.842 62.1963 123.842Z"
          fill="white"
        />
        <path
          d="M50.4199 128.164L51.4848 129.087L52.8426 128.723L52.3013 130.028L53.0645 131.208L51.6623 131.093L50.766 132.185L50.4465 130.809L49.1331 130.303L50.34 129.575L50.4199 128.164Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M32.1475 153.678C32.9838 143.791 32.8976 133.847 31.8901 123.975C35.8393 129.584 42.7882 140.899 44.8914 153.599L32.1475 153.678Z"
          fill="black"
        />
        <path
          d="M53.0557 165.171C53.0557 165.171 61.0428 170.389 61.9835 170.797C62.9242 171.205 68.8436 172.661 70.5475 172.572C72.2515 172.483 69.9707 167.159 68.5064 166.466C67.0421 165.774 61.806 166.724 61.806 166.724L55.5938 162.056L53.0557 165.171Z"
          fill="#E4897B"
        />
        <path
          d="M107.848 170.797V173.407C107.848 174.072 107.058 174.347 106.392 174.347H54.2981C53.6237 174.347 52.825 174.072 52.825 173.407V170.797H107.848Z"
          fill="#263238"
        />
        <path
          opacity="0.4"
          d="M107.848 170.797V173.407C107.848 174.072 107.058 174.347 106.392 174.347H54.2981C53.6237 174.347 52.825 174.072 52.825 173.407V170.797H107.848Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M107.848 170.797V173.407C107.848 174.072 107.058 174.347 106.392 174.347H72.0299C71.3643 174.347 70.5745 174.072 70.5745 173.407V170.797H107.848Z"
          fill="black"
        />
        <path
          d="M116.562 143.729L110.35 171.862C110.236 172.313 109.978 172.714 109.613 173.003C109.249 173.291 108.8 173.452 108.335 173.459H74.4696C74.2574 173.455 74.049 173.402 73.8611 173.303C73.6731 173.205 73.5107 173.064 73.3867 172.892C73.2627 172.719 73.1805 172.521 73.1467 172.311C73.1128 172.102 73.1282 171.887 73.1916 171.684L79.4038 143.765C79.5321 143.34 79.8019 142.972 80.1684 142.722C80.5349 142.472 80.976 142.354 81.4184 142.389H115.302C116.189 142.398 116.766 142.807 116.562 143.729Z"
          fill="#263238"
        />
        <path
          opacity="0.4"
          d="M116.562 143.73L110.35 171.863C110.236 172.313 109.978 172.714 109.613 173.003C109.249 173.292 108.8 173.452 108.335 173.46H75.6143C75.4022 173.456 75.1938 173.402 75.0059 173.304C74.8179 173.205 74.6555 173.064 74.5315 172.892C74.4075 172.72 74.3253 172.521 74.2914 172.312C74.2576 172.102 74.273 171.888 74.3364 171.685L80.5486 143.766C80.6755 143.34 80.9449 142.971 81.3118 142.72C81.6786 142.47 82.1205 142.353 82.5632 142.39H115.293C116.189 142.399 116.766 142.807 116.562 143.73Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M99.8609 158.027C99.8609 160.432 97.2784 162.393 95.3792 162.393C93.48 162.393 92.3707 160.432 92.9032 158.027C93.4357 155.622 95.326 153.74 97.1807 153.669C96.6394 156.074 97.802 158.027 99.6834 158.027H99.8609Z"
          fill="black"
        />
        <path
          d="M92.5391 200.102L103.632 201.93L108.966 202.808L110.519 197.297L105.7 195.833L93.9324 192.256L92.5391 200.102Z"
          fill="#EBB376"
        />
        <path
          d="M109.427 196.401L107.031 202.613C106.994 202.696 106.975 202.786 106.975 202.878C106.975 202.969 106.994 203.059 107.031 203.143C107.068 203.226 107.121 203.301 107.189 203.363C107.256 203.424 107.335 203.471 107.422 203.5L110.519 204.601C110.519 204.601 114.584 206.376 115.897 205.808C119.731 204.157 125.393 190.623 124.15 189.345C123.689 188.884 122.695 189.345 122.171 189.7C120.512 190.925 116.847 194.493 115.4 195.495C113.767 196.667 111.265 196.303 110.173 196.063C110.029 196.029 109.877 196.043 109.742 196.104C109.607 196.165 109.496 196.269 109.427 196.401Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M92.5391 200.102L103.632 201.93L105.7 195.833L93.9324 192.256L92.5391 200.102Z"
          fill="black"
        />
        <path
          d="M103.792 194.688C103.792 194.688 58.5319 169.253 42.9393 169.981C27.3466 170.709 25.0835 184.464 31.5176 193.055C37.9517 201.646 49.8526 203.784 49.8526 203.784C49.8526 203.784 41.7589 192.345 42.9304 189.123C44.1018 185.902 48.6811 190.401 48.6811 190.401C48.6811 190.401 77.142 199.587 102.035 201.699L103.792 194.688Z"
          fill="#263238"
        />
        <path
          d="M104.519 193.987L101.954 192.265L98.6707 202.373H102.62L104.519 193.987Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M104.519 193.987L101.954 192.265L98.6707 202.373H102.62L104.519 193.987Z"
          fill="white"
        />
        <path
          d="M37.6851 108.178C36.0521 110.512 56.1265 114.266 56.1265 114.266C56.1265 114.266 67.8765 106.643 54.1563 105.454C51.3608 105.205 40.2676 104.513 37.6851 108.178Z"
          fill="#E4897B"
        />
        <path
          d="M56.7826 118.588C56.7826 118.588 67.6007 109.66 62.0009 104.318C56.401 98.9752 30.0523 96.588 32.2798 108.755C32.2798 108.755 35.6699 107.752 39.0955 111.018C46.3105 117.923 56.7826 118.588 56.7826 118.588Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M55.8778 116.023C55.8778 116.023 62.0012 111.267 59.8447 106.98C57.6882 102.694 44.9442 97.999 35.8123 103.91C38.0019 105.032 40.0365 106.434 41.8647 108.081C45.4856 111.178 55.8778 116.023 55.8778 116.023Z"
          fill="black"
        />
        <path
          d="M41.8645 108.08C41.8645 108.08 44.802 112.278 55.8775 116.023C55.8775 116.023 60.8118 109.749 53.3128 106.882C53.3128 106.882 51.7508 106.394 51.4136 103.909C51.2551 102.157 51.4823 100.391 52.0792 98.7354C52.1591 98.4692 52.2567 98.1852 52.3543 97.9012L43.2312 92.1948C43.2312 92.1948 46.4438 103.758 41.8645 108.08Z"
          fill="#E4897B"
        />
        <path
          opacity="0.2"
          d="M45.2815 95.5142C45.2815 95.5142 45.7696 102.037 51.4139 103.918C51.2553 102.166 51.4825 100.4 52.0795 98.7445C48.7515 96.9785 45.2815 95.5142 45.2815 95.5142Z"
          fill="black"
        />
        <path
          d="M38.9536 97.9723C38.1268 98.0427 37.2951 97.9188 36.5246 97.6107C35.7542 97.3025 35.0665 96.8185 34.5163 96.1974C34.4757 96.1559 34.4529 96.1002 34.4529 96.0421C34.4529 95.984 34.4757 95.9283 34.5163 95.8868C34.5362 95.8656 34.5603 95.8487 34.587 95.8371C34.6137 95.8255 34.6425 95.8196 34.6716 95.8196C34.7007 95.8196 34.7295 95.8255 34.7562 95.8371C34.7829 95.8487 34.807 95.8656 34.8269 95.8868C37.3207 99.1349 44.3849 96.3749 44.4559 96.3483C44.4832 96.3368 44.5126 96.3309 44.5422 96.3309C44.5719 96.331 44.6012 96.337 44.6285 96.3486C44.6558 96.3602 44.6805 96.3771 44.7012 96.3984C44.7218 96.4197 44.738 96.4449 44.7487 96.4725C44.7601 96.4992 44.7659 96.528 44.7658 96.557C44.7656 96.5861 44.7595 96.6147 44.7479 96.6413C44.7363 96.6679 44.7193 96.6919 44.6981 96.7117C44.6769 96.7315 44.6518 96.7467 44.6245 96.7565C42.8153 97.4755 40.8985 97.8864 38.9536 97.9723Z"
          fill="#263238"
        />
        <path
          d="M57.235 77.2501C57.1832 77.2482 57.1334 77.2295 57.0931 77.1969C57.0524 77.1554 57.0296 77.0997 57.0296 77.0416C57.0296 76.9835 57.0524 76.9278 57.0931 76.8863C57.9216 75.8327 58.335 74.5119 58.2548 73.1739C58.1747 71.836 57.6066 70.5739 56.6582 69.6268C53.2858 66.0326 48.2539 69.0855 48.2007 69.1121C48.1764 69.133 48.1479 69.1484 48.117 69.1572C48.0862 69.166 48.0539 69.168 48.0222 69.1631C47.9905 69.1582 47.9603 69.1465 47.9336 69.1288C47.9069 69.1111 47.8843 69.0879 47.8674 69.0606C47.8506 69.0334 47.8398 69.0028 47.8359 68.971C47.832 68.9392 47.835 68.9069 47.8447 68.8764C47.8545 68.8459 47.8707 68.8178 47.8923 68.7942C47.9139 68.7705 47.9404 68.7518 47.9699 68.7394C48.0232 68.7394 53.3657 65.4646 56.9866 69.3251C57.9925 70.3581 58.592 71.7193 58.6751 73.1588C58.7583 74.5983 58.3194 76.0194 57.4392 77.1614C57.4144 77.191 57.383 77.2145 57.3476 77.2299C57.3122 77.2453 57.2736 77.2522 57.235 77.2501Z"
          fill="#263238"
        />
        <path
          d="M56.8721 77.5165C56.8356 77.5162 56.7996 77.5072 56.7672 77.4902C56.7348 77.4732 56.7069 77.4487 56.6858 77.4189C56.655 77.3703 56.6443 77.3118 56.6559 77.2555C56.6675 77.1992 56.7005 77.1496 56.7479 77.1171C56.7479 77.1171 59.0642 75.5375 59.3215 73.4342C59.3697 72.8963 59.2975 72.3544 59.1103 71.8478C58.923 71.3413 58.6253 70.8828 58.2388 70.5056C58.2174 70.4849 58.2003 70.4601 58.1886 70.4326C58.1769 70.4052 58.1709 70.3757 58.1709 70.3458C58.1709 70.316 58.1769 70.2865 58.1886 70.259C58.2003 70.2316 58.2174 70.2068 58.2388 70.1861C58.2818 70.1448 58.339 70.1218 58.3986 70.1218C58.4581 70.1218 58.5153 70.1448 58.5583 70.1861C58.9887 70.6106 59.3194 71.1253 59.5266 71.6932C59.7337 72.261 59.8122 72.8678 59.7564 73.4697C59.4724 75.7771 57.094 77.3923 56.9875 77.4633C56.9557 77.4928 56.9153 77.5115 56.8721 77.5165Z"
          fill="#263238"
        />
        <path
          d="M56.9776 76.407C57.2352 74.159 56.6644 71.8942 55.3723 70.0368C54.0801 68.1794 52.1551 66.8567 49.9578 66.3165C41.8198 63.8583 33.4244 71.943 33.4244 71.943C33.4244 71.943 27.1057 97.9989 44.5975 96.5967C62.0894 95.1945 56.9776 76.407 56.9776 76.407Z"
          fill="#263238"
        />
        <path
          d="M47.7311 73.6735C55.2568 72.9724 58.0612 76.7708 59.3391 84.4828C60.9366 94.1206 59.4367 101.211 49.8255 100.022C36.7266 98.407 35.1735 74.845 47.7311 73.6735Z"
          fill="#E4897B"
        />
        <path
          d="M54.387 84.5186C55.0965 85.587 55.9306 86.5671 56.8719 87.4383C56.6799 87.77 56.4003 88.0423 56.0637 88.2255C55.7271 88.4087 55.3466 88.4956 54.9638 88.4767L54.387 84.5186Z"
          fill="#DE5753"
        />
        <path
          d="M49.7189 84.3324C49.7899 84.9536 49.5147 85.495 49.1065 85.5394C48.6983 85.5837 48.2989 85.1311 48.2191 84.5099C48.1392 83.8887 48.4232 83.3562 48.8314 83.3029C49.2396 83.2497 49.6479 83.7201 49.7189 84.3324Z"
          fill="#263238"
        />
        <path
          d="M48.6804 83.3555L49.9584 82.6721C49.9584 82.6721 49.417 83.8791 48.6804 83.3555Z"
          fill="#263238"
        />
        <path
          d="M57.378 83.3117C57.449 83.9241 57.1828 84.4654 56.7657 84.5098C56.3486 84.5542 55.967 84.1016 55.8782 83.4804C55.7895 82.8591 56.0823 82.3266 56.4994 82.2734C56.9165 82.2202 57.307 82.6905 57.378 83.3117Z"
          fill="#263238"
        />
        <path
          d="M56.3391 82.3262L57.6171 81.6428C57.6171 81.6428 57.0846 82.8498 56.3391 82.3262Z"
          fill="#263238"
        />
        <path
          d="M46.0001 80.9068C45.9369 80.907 45.8752 80.8884 45.8226 80.8536C45.7866 80.8311 45.7556 80.8016 45.7315 80.7667C45.7073 80.7319 45.6906 80.6924 45.6823 80.6509C45.674 80.6093 45.6743 80.5665 45.6831 80.525C45.692 80.4836 45.7092 80.4444 45.7338 80.4098C46.1438 79.8968 46.6676 79.4861 47.2637 79.2104C47.8598 78.9347 48.5118 78.8015 49.1683 78.8213C49.2103 78.8224 49.2516 78.8318 49.2899 78.8489C49.3282 78.8661 49.3628 78.8906 49.3917 78.9211C49.4205 78.9516 49.4431 78.9874 49.458 79.0267C49.473 79.0659 49.4801 79.1077 49.4789 79.1496C49.4705 79.2321 49.4327 79.3089 49.3724 79.3659C49.3122 79.4229 49.2334 79.4564 49.1506 79.4602C48.6028 79.4477 48.0592 79.5584 47.5599 79.7842C47.0607 80.01 46.6186 80.3451 46.2663 80.7648C46.2377 80.8091 46.1982 80.8454 46.1516 80.8703C46.105 80.8951 46.0529 80.9077 46.0001 80.9068Z"
          fill="#263238"
        />
        <path
          d="M57.6613 78.9716C57.6114 78.9808 57.5603 78.9808 57.5104 78.9716C57.1034 78.7121 56.6434 78.5471 56.1642 78.4887C55.6851 78.4304 55.1989 78.4801 54.7415 78.6344C54.665 78.6709 54.5772 78.6755 54.4973 78.6472C54.4174 78.6189 54.352 78.56 54.3155 78.4835C54.2791 78.407 54.2745 78.3191 54.3028 78.2393C54.3311 78.1594 54.3899 78.094 54.4664 78.0575C55.0136 77.8564 55.5995 77.7831 56.1794 77.8431C56.7592 77.903 57.3177 78.0948 57.8121 78.4036C57.8505 78.4223 57.8846 78.4486 57.9124 78.4811C57.9401 78.5135 57.9609 78.5513 57.9734 78.5921C57.9859 78.6329 57.9898 78.6758 57.985 78.7182C57.9801 78.7606 57.9666 78.8015 57.9453 78.8385C57.9134 78.8832 57.8705 78.919 57.8207 78.9423C57.771 78.9656 57.7161 78.9757 57.6613 78.9716Z"
          fill="#263238"
        />
        <path
          d="M40.6311 87.1361C40.6311 87.1361 43.5775 80.6222 42.5924 75.892C42.5924 75.892 56.9515 68.4018 59.108 83.4798C59.108 83.4798 59.3033 69.8927 46.3286 70.8068C33.3539 71.7209 35.1821 90.6505 42.3971 95.6557C42.406 95.6735 38.8562 91.2184 40.6311 87.1361Z"
          fill="#263238"
        />
        <path
          d="M42.0246 88.6185C41.8626 87.7587 41.3686 86.9972 40.6494 86.4989C39.9303 86.0007 39.0438 85.8056 38.1819 85.9561C35.8745 86.4264 35.2089 91.3252 41.1194 91.9287C41.9713 91.9642 42.4062 91.1388 42.0246 88.6185Z"
          fill="#E4897B"
        />
        <path
          d="M56.1259 91.3877C55.7933 92.2252 55.5205 93.0853 55.3094 93.9613H54.8302C52.6737 93.775 51.7418 92.9763 51.3514 92.1864C51.1701 91.7798 51.0909 91.335 51.1206 90.8907C51.1281 90.6378 51.1699 90.387 51.2449 90.1453C52.5235 90.8526 53.9407 91.2735 55.3982 91.3788C55.8419 91.3699 56.1259 91.3877 56.1259 91.3877Z"
          fill="#263238"
        />
        <path
          d="M55.3984 91.3076L55.1322 92.0619C53.1088 91.8844 51.6977 91.485 51.1208 90.8194C51.1283 90.5665 51.1701 90.3157 51.2451 90.074C52.5238 90.7813 53.9409 91.2022 55.3984 91.3076Z"
          fill="white"
        />
        <path
          d="M54.8304 93.9253C52.6739 93.7389 51.742 92.9402 51.3516 92.1504C52.1701 92.2353 52.9667 92.4668 53.7033 92.8338C54.1828 93.0724 54.5765 93.4537 54.8304 93.9253Z"
          fill="#DE5753"
        />
        <path
          d="M36.7887 70.7623C36.7887 70.7623 40.4096 65.0027 34.2595 62.713C28.1094 60.4234 22.1545 68.3484 25.8375 73.824C26.8394 75.3624 28.3147 76.533 30.0406 77.1589C31.7664 77.7849 33.6491 77.8323 35.4043 77.294L36.7887 70.7623Z"
          fill="#263238"
        />
        <path
          d="M37.1877 70.2037C37.1364 70.2083 37.0854 70.1923 37.0457 70.1594C37.0245 70.1394 37.0076 70.1154 36.996 70.0887C36.9845 70.062 36.9785 70.0332 36.9785 70.0041C36.9785 69.9749 36.9845 69.9461 36.996 69.9194C37.0076 69.8927 37.0245 69.8687 37.0457 69.8487C37.2055 69.6624 40.7731 65.2428 37.4806 62.4828C37.44 62.4413 37.4172 62.3856 37.4172 62.3275C37.4172 62.2694 37.44 62.2137 37.4806 62.1722C37.5005 62.151 37.5246 62.1341 37.5513 62.1225C37.578 62.1109 37.6068 62.105 37.6359 62.105C37.665 62.105 37.6938 62.1109 37.7205 62.1225C37.7472 62.1341 37.7713 62.151 37.7912 62.1722C41.4298 65.2073 37.454 70.1061 37.4185 70.1594C37.388 70.1876 37.3503 70.2068 37.3095 70.2146C37.2687 70.2224 37.2265 70.2187 37.1877 70.2037Z"
          fill="#263238"
        />
        <path
          d="M30.5938 78.7588C29.6351 78.7206 28.6962 78.4743 27.8423 78.0369C26.9884 77.5994 26.2399 76.9814 25.6488 76.2256C25.0577 75.4699 24.6382 74.5946 24.4194 73.6604C24.2006 72.7263 24.1877 71.7557 24.3816 70.816C24.7632 68.8725 25.9613 67.6034 27.5942 67.4259C27.6235 67.4209 27.6536 67.4221 27.6825 67.4294C27.7114 67.4367 27.7384 67.45 27.7618 67.4684C27.7852 67.4868 27.8045 67.5099 27.8184 67.5362C27.8324 67.5625 27.8406 67.5915 27.8427 67.6212C27.8477 67.6787 27.8301 67.7358 27.7937 67.7805C27.7572 67.8253 27.7048 67.8541 27.6475 67.8608C26.2098 68.0205 25.1537 69.1565 24.8164 70.9048C24.5637 72.1647 24.7079 73.4721 25.2293 74.6467C25.7506 75.8213 26.6234 76.8053 27.7273 77.4631C31.7653 79.6285 33.2473 77.0282 33.3006 76.9217C33.3145 76.896 33.3334 76.8734 33.3561 76.855C33.3788 76.8366 33.405 76.8229 33.433 76.8147C33.461 76.8064 33.4904 76.8038 33.5195 76.8069C33.5485 76.8101 33.5767 76.819 33.6023 76.833C33.6277 76.8461 33.6502 76.8641 33.6685 76.886C33.6868 76.9079 33.7004 76.9333 33.7087 76.9606C33.717 76.988 33.7197 77.0167 33.7167 77.045C33.7136 77.0734 33.7049 77.1009 33.6911 77.1259C33.36 77.6425 32.9006 78.0646 32.3578 78.3508C31.8149 78.6369 31.2072 78.7775 30.5938 78.7588Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M53.5523 116.627C53.554 116.748 53.5194 116.868 53.4528 116.97C53.3862 117.072 53.2906 117.152 53.1784 117.199C53.0661 117.247 52.9423 117.26 52.8227 117.236C52.7031 117.213 52.5932 117.154 52.507 117.068C52.4208 116.982 52.3623 116.872 52.339 116.753C52.3156 116.633 52.3285 116.509 52.376 116.397C52.4234 116.285 52.5032 116.189 52.6053 116.123C52.7073 116.056 52.8269 116.021 52.9488 116.023C53.1081 116.025 53.2603 116.09 53.373 116.202C53.4857 116.315 53.55 116.467 53.5523 116.627Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M59.6583 114.87C59.6595 114.95 59.6447 115.029 59.6149 115.104C59.5851 115.178 59.5409 115.245 59.4847 115.302C59.4286 115.359 59.3616 115.405 59.2878 115.435C59.214 115.466 59.1348 115.482 59.0548 115.482C58.9688 115.493 58.8816 115.485 58.7989 115.459C58.7162 115.433 58.6399 115.39 58.5751 115.333C58.5103 115.275 58.4584 115.205 58.4228 115.126C58.3873 115.047 58.3689 114.961 58.3689 114.874C58.3689 114.788 58.3873 114.702 58.4228 114.623C58.4584 114.544 58.5103 114.473 58.5751 114.416C58.6399 114.359 58.7162 114.316 58.7989 114.29C58.8816 114.264 58.9688 114.256 59.0548 114.266C59.2149 114.266 59.3684 114.33 59.4815 114.443C59.5947 114.556 59.6583 114.71 59.6583 114.87Z"
          fill="black"
        />
        <path
          d="M65.3467 120.993C65.3485 121.114 65.3141 121.233 65.2478 121.335C65.1816 121.437 65.0866 121.517 64.9749 121.565C64.8632 121.612 64.7398 121.626 64.6204 121.603C64.5011 121.581 64.3911 121.523 64.3046 121.438C64.2181 121.352 64.1589 121.243 64.1345 121.124C64.1101 121.005 64.1217 120.882 64.1678 120.769C64.2139 120.657 64.2924 120.561 64.3932 120.493C64.4941 120.425 64.6129 120.389 64.7344 120.389C64.8952 120.389 65.0497 120.452 65.1642 120.565C65.2788 120.678 65.3444 120.832 65.3467 120.993Z"
          fill="#263238"
        />
        <path
          d="M52.1679 124.117C52.1679 124.237 52.1325 124.353 52.0662 124.452C51.9999 124.552 51.9056 124.629 51.7953 124.675C51.6851 124.72 51.5637 124.732 51.4467 124.709C51.3296 124.686 51.2221 124.628 51.1377 124.544C51.0533 124.459 50.9958 124.352 50.9725 124.235C50.9492 124.118 50.9612 123.996 51.0069 123.886C51.0526 123.776 51.1299 123.682 51.2291 123.615C51.3284 123.549 51.4451 123.514 51.5644 123.514C51.7245 123.514 51.878 123.577 51.9911 123.69C52.1043 123.804 52.1679 123.957 52.1679 124.117Z"
          fill="#263238"
        />
        <path
          d="M64.5925 121.251C64.5925 121.251 62.223 120.931 61.8148 117.905C61.7917 117.201 61.4951 116.534 60.9881 116.045C60.4811 115.556 59.8034 115.284 59.0992 115.287C58.9749 115.287 58.8684 115.198 58.8684 115.074C58.866 115.045 58.8696 115.015 58.8792 114.987C58.8888 114.959 58.9041 114.933 58.9242 114.912C58.9442 114.89 58.9685 114.873 58.9956 114.861C59.0226 114.849 59.0519 114.843 59.0814 114.843C59.8935 114.844 60.6747 115.155 61.2652 115.713C61.8557 116.27 62.211 117.032 62.2585 117.843C62.6135 120.505 64.628 120.807 64.6458 120.816C64.7031 120.823 64.7555 120.851 64.792 120.896C64.8284 120.941 64.846 120.998 64.841 121.055C64.8322 121.114 64.8016 121.166 64.7555 121.202C64.7093 121.239 64.651 121.256 64.5925 121.251Z"
          fill="#263238"
        />
        <path
          d="M51.5648 124.116C51.5074 124.116 51.4523 124.094 51.411 124.055C51.3696 124.015 51.3452 123.961 51.3429 123.903C51.2946 122.346 51.8659 120.833 52.9315 119.697C53.1381 119.515 53.3054 119.293 53.4229 119.044C53.5404 118.795 53.6057 118.525 53.6148 118.25C53.5692 117.693 53.3307 117.169 52.9403 116.768C52.9145 116.75 52.8929 116.726 52.8769 116.699C52.861 116.671 52.8512 116.641 52.8481 116.609C52.845 116.577 52.8487 116.546 52.8589 116.516C52.8692 116.486 52.8858 116.458 52.9076 116.435C52.9294 116.412 52.9558 116.394 52.9852 116.382C53.0146 116.37 53.0462 116.364 53.0779 116.366C53.1097 116.367 53.1407 116.375 53.169 116.389C53.1973 116.404 53.2223 116.424 53.2421 116.449C53.7229 116.92 54.0135 117.552 54.0585 118.224C54.0516 118.559 53.9764 118.889 53.8376 119.194C53.6987 119.499 53.4991 119.773 53.251 119.999C52.2794 121.046 51.7542 122.431 51.7866 123.859C51.7893 123.917 51.7697 123.975 51.7318 124.019C51.6939 124.063 51.6406 124.092 51.5825 124.099L51.5648 124.116Z"
          fill="#263238"
        />
        <path
          d="M29.5559 110.157C35.919 105.045 33.5051 133.808 36.5047 140.331C39.5043 146.854 57.3955 162.367 57.3955 162.367L53.5528 166.68C53.5528 166.68 31.7213 154.637 28.7039 146.561C24.7725 136.337 24.8523 113.92 29.5559 110.157Z"
          fill="#407BFF"
        />
        <path
          opacity="0.5"
          d="M29.5559 110.157C35.919 105.045 33.5051 133.808 36.5047 140.331C39.5043 146.854 57.3955 162.367 57.3955 162.367L53.5528 166.68C53.5528 166.68 31.7213 154.637 28.7039 146.561C24.7725 136.337 24.8523 113.92 29.5559 110.157Z"
          fill="white"
        />
        <path
          d="M57.3957 162.402L59.8451 164.47L56.4816 168.57L53.553 166.715L57.3957 162.402Z"
          fill="#407BFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_6614_215045">
          <rect
            width="332"
            height="212"
            fill="white"
            transform="translate(0.854492 0.84436)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoWorkSamples;
