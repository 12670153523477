import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import LocationIconFull from "../../assets/svg/LocationIconFull";
import LanguageFullIcon from "../../assets/svg/LanguageFullIcon";
import UserBoxProfileImageCard from "../userColorProfile/UserBoxProfileImageCard";
import HighlySkillIcon from "../../assets/svg/recomondation/HighlySkillIcon";
import ProfessionalIcon from "../../assets/svg/recomondation/ProfessionalIcon";
import FriendlyIcon from "../../assets/svg/recomondation/FriendlyIcon";
import TimeSavingIcon from "../../assets/svg/recomondation/TimeSavingIcon";
import { IoIosArrowForward } from "react-icons/io";
import VerifiedBlueIcon from "../../assets/svg/VerifiedBlueIcon";
import PremiumBlueIcon from "../../assets/svg/PremiumBlueIcon";
import NewUserGreenIcon from "../../assets/svg/NewUserGreenIcon";

const { Text } = Typography;

const TaskerListCardAboutSearchResult = ({
  taskerList,
  searchSelectedTaskerList,
  serSearchSelectedTaskerList,
  languageRelatedWorkers,
}) => {
  const [sortedTaskerList, setSortedTaskerList] = useState([]);

  const handleSelectWorker = (userId) => {
    // Check if the user is already in the list
    if (searchSelectedTaskerList.includes(userId)) {
      // If user is in the list, remove it
      serSearchSelectedTaskerList(
        searchSelectedTaskerList.filter((id) => id !== userId)
      );
    } else {
      // If user is not in the list, add it
      serSearchSelectedTaskerList([...searchSelectedTaskerList, userId]);
    }
  };

  useEffect(() => {
    getPriorityList();
  }, []);

  const getPriorityList = async () => {
    const prioritizedItems = await taskerList?.filter((tasker) =>
      languageRelatedWorkers.includes(tasker.user_id)
    );
    const remainingItems = await taskerList?.filter(
      (tasker) => !languageRelatedWorkers.includes(tasker.user_id)
    );

    setSortedTaskerList([...prioritizedItems, ...remainingItems]);
  };

  return (
    <>
      <div>
        {sortedTaskerList?.map((data, index) => {
          return (
            <div>
              <div className="border-[1px] w-full mb-3 rounded-lg shadow-lg">
                <Row className="flex flex-row">
                  <Col
                    span={24}
                    md={{ span: 6 }}
                    className="bg-gray-100 hidden md:flex flex-col justify-center items-center py-3"
                  >
                    <div className="flex flex-col justify-center items-center w-full">
                      {/* profile image............................ */}
                      <UserBoxProfileImageCard data={data} />

                      {/* selected tasker and view button */}
                      <div className="flex flex-col justify-center items-center p-2 w-full">
                        <div className="flex flex-col gap-2 mt-5 w-full">
                          <Button
                            type="primary"
                            size="large"
                            className="bg-primaryDark w-full font-semibold"
                            onClick={() => {
                              handleSelectWorker(data?.user_id);
                            }}
                          >
                            {searchSelectedTaskerList?.includes(data?.user_id)
                              ? "Unselect"
                              : "Select"}{" "}
                            Worker
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col span={24} md={{ span: 18 }} className="bg-white">
                    <div className="p-2" key={data.id}>
                      {/* name and rate */}
                      <div className="flex flex-col justify-start sm:flex-row sm:justify-between sm:items-center">
                        <div className="flex flex-row gap-2 justify-start items-center">
                          <Text className="text-xl md:text-2xl font-bold text-primaryDarkest">
                            {decryptSecureData(data?.full_name)?.length > 20
                              ? `${decryptSecureData(data?.full_name).slice(
                                  0,
                                  20
                                )}...`
                              : decryptSecureData(data?.full_name)}
                          </Text>
                          <div className="flex flex-row justify-start items-center gap-1">
                            {data?.has_verified === 2 && <VerifiedBlueIcon />}

                            {data?.account_type === 2 ? (
                              <PremiumBlueIcon />
                            ) : (
                              <NewUserGreenIcon />
                            )}
                          </div>
                        </div>
                        <div className="text-2xl font-semibold">
                          <span className="font-medium text-xs">
                            {data?.uom}{" "}
                          </span>
                          ({data?.currency_label}){data?.price}
                        </div>
                      </div>

                      <div className="flex md:hidden">
                        <Button
                          type="primary"
                          size="small"
                          className="bg-primaryDark font-semibold px-5"
                          onClick={() => {
                            handleSelectWorker(data?.user_id);
                          }}
                        >
                          {searchSelectedTaskerList?.includes(data?.user_id)
                            ? "Unselect"
                            : "Select"}{" "}
                          Worker
                        </Button>
                      </div>

                      {/* recomondation card */}
                      <div className="bg-primaryLightest flex flex-row justify-around rounded-lg p-4 items-center mt-2">
                        <div>
                          <div className="relative">
                            <HighlySkillIcon />
                            <span
                              className={`absolute top-0 right-0 text-white rounded-full px-1 text-xs ${
                                data?.rec_level1 === 0
                                  ? " bg-gray-500 "
                                  : "bg-purple-500"
                              }`}
                            >
                              {data?.rec_level1 || 0}
                            </span>
                          </div>
                          <p className="text-center text-xs xsm:text-sm mt-1 font-semibold hidden xsm:block">
                            Expert
                          </p>
                        </div>

                        <div>
                          <div className="relative">
                            <ProfessionalIcon />
                            <span
                              className={`absolute top-0 right-0 text-white rounded-full px-1 text-xs ${
                                data?.rec_level2 === 0
                                  ? " bg-gray-500 "
                                  : "bg-purple-500"
                              }`}
                            >
                              {data?.rec_level2 || 0}
                            </span>
                          </div>
                          <p className="text-center text-xs xsm:text-sm mt-1 font-semibold hidden xsm:block">
                            Professional
                          </p>
                        </div>

                        <div>
                          <div className="relative">
                            <FriendlyIcon />
                            <span
                              className={`absolute top-0 right-0 text-white rounded-full px-1 text-xs ${
                                data?.rec_level3 === 0
                                  ? " bg-gray-500 "
                                  : "bg-purple-500"
                              }`}
                            >
                              {data?.rec_level3 || 0}
                            </span>
                          </div>
                          <p className="text-center text-xs xsm:text-sm mt-1 font-semibold hidden xsm:block">
                            Optimist
                          </p>
                        </div>

                        <div>
                          <div className="relative">
                            <TimeSavingIcon />
                            <span
                              className={`absolute top-0 right-0 text-white rounded-full px-1 text-xs ${
                                data?.rec_level4 === 0
                                  ? " bg-gray-500 "
                                  : "bg-purple-500"
                              }`}
                            >
                              {data?.rec_level4 || 0}
                            </span>
                          </div>
                          <p className="text-center text-xs xsm:text-sm mt-1 font-semibold hidden xsm:block">
                            Speedster
                          </p>
                        </div>
                      </div>

                      {/* experiance...... */}
                      <div className="mt-2">
                        <div className="flex flex-col">
                          <Text className="text-sm md:text-base font-semibold text-primaryDarkest">
                            Experienced Cleaner
                          </Text>

                          <Text className="text-base text-textColorTwo">
                            {data?.work_description?.length > 200
                              ? `${data?.work_description?.slice(0, 200)}...`
                              : data?.work_description || "No description"}

                            {data?.work_description?.length > 200 && (
                              <Button
                                type="link"
                                className="p-0 ml-1 text-primaryDark"
                                onClick={() => {
                                  window.open(
                                    `../profile/${data?.username}`,
                                    "_blank"
                                  );
                                }}
                              >
                                Read more
                              </Button>
                            )}
                          </Text>
                        </div>
                      </div>

                      {/* location and language */}
                      <div className="mt-5 flex flex-col sm:flex-row justify-start sm:justify-between sm:items-center">
                        <div>
                          <Text className="flex flex-row gap-1 justify-start items-center text-sm font-semibold">
                            <LocationIconFull />
                            {data?.state || "no state"},
                            {data?.suburb || "no suburb"}
                          </Text>

                          <div className="flex items-start gap-2">
                            {/* Language Icon */}
                            <div
                              className={`self-start items-center ${
                                data?.languages?.length > 2 ? "pt-1" : "pt-0"
                              }`}
                            >
                              <LanguageFullIcon />
                            </div>

                            {/* Language Text */}
                            <div className="flex items-center flex-wrap text-sm font-semibold">
                              <Text
                                className={`whitespace-normal overflow-hidden ${
                                  data?.languages?.length > 2 ? "pt-1" : "pt-0"
                                }`}
                              >
                                {data?.languages
                                  .slice(0, 2)
                                  .map((lang) => lang.language)
                                  .join(" | ") || "No Language"}
                                ...
                              </Text>

                              {data?.languages?.length > 2 && (
                                <Button
                                  type="link"
                                  className="p-0 ml-1 pt-1 text-primaryDark"
                                  onClick={() => {
                                    window.open(
                                      `../profile/${data?.username}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  Read more
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>

                        <Button
                          type="link"
                          className="text-primaryDarkest text-sm font-semibold flex flex-row gap-1 justify-center items-center"
                          onClick={() => {
                            window.open(
                              `../profile/${data?.username}`,
                              "_blank"
                            );
                          }}
                        >
                          See Profile <IoIosArrowForward />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TaskerListCardAboutSearchResult;

// import { Button, Col, Divider, Row, Typography } from "antd";
// import React, { useEffect, useState } from "react";
// import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
// import DefaultCoverWhite from "../../assets/img/defaultcoverWhite.png";
// import { GreenHeart } from "../../assets/svg/GreenHeart";
// import StarIcon from "../../assets/svg/StarIcon";
// import ThumbUpIcon from "../../assets/svg/ThumbUpIcon";
// import PremiumIcon from "../../assets/svg/PremiumIcon";
// import LocationIconFull from "../../assets/svg/LocationIconFull";
// import LanguageFullIcon from "../../assets/svg/LanguageFullIcon";
// import UserColorProfile from "../userColorProfile/UserColorProfile";
// import ViewsIcon from "../../assets/svg/ViewsIcon";
// import UserBoxProfileImageCard from "../userColorProfile/UserBoxProfileImageCard";
// import { getDateDifference } from "../../utils/dateDifference";
// import WorkerContactDetails from "../alertBox/WorkerContactDetails";
// import { useNavigate } from "react-router-dom";
// import PremiumWhiteIcon from "../../assets/svg/PremiumWhiteIcon";
// import NewWorkerWhite from "../../assets/svg/NewWorkerWhite";
// import VerifyedGreenIcon from "../../assets/svg/VerifyedGreenIcon";
// import DesignationIcon from "../../assets/svg/DesignationIcon";
// import CompleteIcon from "../../assets/svg/CompleteIcon";
// import FavoriteIcon from "../../assets/svg/FavoriteIcon";
// import RecomondationIcon from "../../assets/svg/RecomondationIcon";

// const { Text } = Typography;

// const TaskerListCardAboutSearchResult = ({
//   taskerList,
//   searchSelectedTaskerList,
//   serSearchSelectedTaskerList,
//   languageRelatedWorkers,
// }) => {
//   const navigate = useNavigate();
//   // const [openCloseContactDetails, setOpenCloseContactDetails] = useState(false);
//   // const [workerContactDetails, setWorkerContactDetails] = useState();
//   const [expandedCard, setExpandedCard] = useState(null);
//   const [sortedTaskerList, setSortedTaskerList] = useState([]);
//   const [expandedLanguages, setExpandedLanguages] = useState(false);

//   const toggleExpandedLanguages = (index) => {
//     setExpandedLanguages((prev) => ({
//       ...prev,
//       [index]: !prev[index],
//     }));
//   };

//   const toggleExpanded = (index) => {
//     setExpandedCard(expandedCard === index ? null : index);
//   };

//   const handleSelectWorker = (userId) => {
//     // Check if the user is already in the list
//     if (searchSelectedTaskerList.includes(userId)) {
//       // If user is in the list, remove it
//       serSearchSelectedTaskerList(
//         searchSelectedTaskerList.filter((id) => id !== userId)
//       );
//     } else {
//       // If user is not in the list, add it
//       serSearchSelectedTaskerList([...searchSelectedTaskerList, userId]);
//     }
//   };

//   useEffect(() => {
//     getPriorityList();
//   }, []);

//   const getPriorityList = async () => {
//     const prioritizedItems = await taskerList?.filter((tasker) =>
//       languageRelatedWorkers.includes(tasker.user_id)
//     );
//     const remainingItems = await taskerList?.filter(
//       (tasker) => !languageRelatedWorkers.includes(tasker.user_id)
//     );

//     setSortedTaskerList([...prioritizedItems, ...remainingItems]);
//   };

//   return (
//     <>
//       <div>
//         {sortedTaskerList?.map((data, index) => {
//           return (
//             <div>
//               <div className="border-[1px] w-full mb-3 rounded-lg shadow-lg">
//                 <Row className="flex flex-row">
//                   <Col span={24} md={{ span: 6 }} className="bg-gray-100">
//                     <div className="flex flex-col justify-center items-center p-2">
//                       {/* profile image............................ */}
//                       <UserBoxProfileImageCard data={data} />
//                       <Divider className="bg-slate-200 my-3 hidden md:block" />
//                       {/* user account type............................ */}
//                       <div className="bg-white rounded-xl w-full p-2 mt-3 md:mt-0">
//                         <div className="mb-2">
//                           {data?.account_type === 2 ? (
//                             <div className="bg-[#1D66F6] px-2 rounded-full flex flex-row justify-center items-center gap-1">
//                               <PremiumWhiteIcon />
//                               <p className="text-sm font-bold text-white">
//                                 Premium{" "}
//                               </p>
//                             </div>
//                           ) : (
//                             <div className="bg-primaryDark px-2 rounded-full flex flex-row justify-center items-center gap-1">
//                               <NewWorkerWhite />
//                               <p className="text-sm font-bold text-white">
//                                 New Worker{" "}
//                               </p>
//                             </div>
//                           )}
//                         </div>

//                         {/* has_verified .................................................*/}
//                         {data?.has_verified === 2 && (
//                           <div className="mb-2 flex flex-row gap-1 justify-start items-center">
//                             <VerifyedGreenIcon />
//                             <p className="text-sm text-primaryColor font-bold">
//                               Verified
//                             </p>
//                           </div>
//                         )}

//                         <Divider className="bg-slate-400 my-3" />

//                         {/* rating list............................ */}
//                         <div className="flex flex-row gap-3 justify-start items-center">
//                           <StarIcon />
//                           <p className="text-sm font-medium text-textColorTwo flex flex-row justify-start items-center gap-2">
//                             <span className="text-xl font-bold text-primaryDarkest">
//                               {" "}
//                               {data?.rate || 0}
//                             </span>{" "}
//                             ({data?.review_count || 0} Reviews)
//                           </p>
//                         </div>
//                       </div>
//                     </div>

//                     {/* selected tasker and view button */}
//                     <div className="flex flex-col justify-center items-center p-2">
//                       <div className="flex flex-col gap-2 mt-5 w-full">
//                         <Button
//                           type="primary"
//                           size="large"
//                           className="bg-primaryDark w-full font-semibold"
//                           onClick={() => {
//                             handleSelectWorker(data?.user_id);
//                           }}
//                         >
//                           {searchSelectedTaskerList?.includes(data?.user_id)
//                             ? "Unselect"
//                             : "Select"}{" "}
//                           Worker
//                         </Button>

//                         <Button
//                           size="large"
//                           className="text-primaryDark border-primaryDark w-full font-semibold"
//                           onClick={() => {
//                             window.open(
//                               `../profile/${data?.username}`,
//                               "_blank"
//                             );
//                           }}
//                         >
//                           View Profile
//                         </Button>
//                       </div>
//                     </div>
//                   </Col>

//                   <Col span={24} md={{ span: 18 }} className="bg-gray-100">
//                     {/* <img
//                       src={data?.cover_photo || DefaultCoverWhite}
//                       className="w-full h-40 object-cover hidden md:block"
//                       alt="cover image"
//                     /> */}

//                     <div className="p-2" key={data.id}>
//                       {/* green section */}
//                       <div className="bg-primaryLightest p-3 rounded-lg">
//                         {/* name...... */}
//                         <div className="flex flex-col justify-start sm:flex-row sm:justify-between sm:items-center">
//                           <Text className="text-2xl ms:text-3xl font-bold text-primaryDarkest">
//                             {decryptSecureData(data?.full_name)?.length > 20
//                               ? `${decryptSecureData(data?.full_name).slice(
//                                   0,
//                                   20
//                                 )}...`
//                               : decryptSecureData(data?.full_name)}
//                           </Text>
//                           <div className="text-xl font-bold text-primaryDarkest">
//                             ({data?.currency_label}){data?.price}/
//                             <span className="font-normal text-base">
//                               {data?.uom}
//                             </span>
//                           </div>
//                         </div>

//                         <div className="flex flex-col justify-start">
//                           {/* designation....... */}
//                           {data?.work_title !== null &&
//                             data?.work_title !== "" &&
//                             data?.work_title !== undefined && (
//                               <Text className="flex flex-row gap-1 justify-start items-center text-sm font-semibold">
//                                 <DesignationIcon />
//                                 {data?.work_title}
//                               </Text>
//                             )}

//                           {/* address....... */}
//                           <Text className="flex flex-row gap-1 justify-start items-center text-sm font-semibold">
//                             <LocationIconFull />
//                             {data?.state || "no state"},
//                             {data?.suburb || "no suburb"}
//                           </Text>

//                           <div className="flex items-start gap-2">
//                             {/* Language Icon */}
//                             <div
//                               className={`self-start items-center ${
//                                 data?.languages?.length > 4 ? "pt-1" : "pt-0"
//                               }`}
//                             >
//                               <LanguageFullIcon />
//                             </div>

//                             {/* Language Text */}
//                             <div className="flex items-center flex-wrap text-sm font-semibold">
//                               <Text
//                                 className={`whitespace-normal overflow-hidden ${
//                                   data?.languages?.length > 4 ? "pt-1" : "pt-0"
//                                 }`}
//                               >
//                                 {data?.languages
//                                   .slice(
//                                     0,
//                                     expandedLanguages[index]
//                                       ? data?.languages?.length
//                                       : 4
//                                   )
//                                   .map((lang) => lang.language)
//                                   .join(" | ") || "No Language"}
//                               </Text>
//                               {data?.languages?.length > 4 && (
//                                 <span
//                                   onClick={() => toggleExpandedLanguages(index)}
//                                   className="text-primaryDark text-sm font-medium cursor-pointer ml-1 pt-1"
//                                 >
//                                   {expandedLanguages[index]
//                                     ? "Show Less"
//                                     : "See more"}
//                                 </span>
//                               )}
//                             </div>
//                           </div>
//                         </div>

//                         {/* skills...... */}
//                         <div className="mt-2 flex flex-col">
//                           <Row
//                             gutter={8}
//                             className="flex flex-row mt-2 items-center"
//                           >
//                             {data?.jobTypes
//                               .slice(
//                                 0,
//                                 expandedCard === index
//                                   ? data?.jobTypes?.length
//                                   : 3
//                               )
//                               .map((item, i) => (
//                                 <Col key={i} className="mb-3">
//                                   <Text className="border-primaryDark border-[1px] text-primaryDark px-2 py-1 bg-white rounded-full text-sm font-medium">
//                                     {item?.job_type}
//                                   </Text>
//                                 </Col>
//                               ))}

//                             {data?.jobTypes?.length > 3 && (
//                               <Col className="mb-3">
//                                 <Button
//                                   type="link"
//                                   onClick={() => toggleExpanded(index)}
//                                   className="px-2 py-1 border-primaryDark border-[1px] text-primaryDark bg-white rounded-full text-sm font-medium"
//                                 >
//                                   {expandedCard === index
//                                     ? "Show Less"
//                                     : `+${data?.jobTypes?.length - 3} more`}
//                                 </Button>
//                               </Col>
//                             )}
//                           </Row>
//                         </div>
//                       </div>

//                       {/* experiance...... */}
//                       <div className="p-3 mt-2">
//                         <div className="flex flex-col">
//                           <Text className="text-sm font-bold text-primaryDarkest">
//                             Skills & Experience
//                           </Text>

//                           <Text className="text-base text-textColorTwo">
//                             {data?.work_description || "No description"}
//                           </Text>
//                         </div>
//                         <Divider className="bg-slate-200 my-4" />
//                         {/* rating list........................... */}
//                         <div className="my-2">
//                           <div className="hidden md:flex flex-row justify-evenly rounded-md bg-[#E0EFE2] py-2 px-1">
//                             <div className="text-primaryDarkest border-r-[1px] border-primaryDarkest justify-center items-center w-full leading-3">
//                               <p className="font-medium flex flex-row justify-center items-center gap-2 text-xs">
//                                 <CompleteIcon />
//                                 Favorited by
//                               </p>
//                               <p className="font-semibold text-xl text-center">
//                                 {data?.favorited_count || 0}
//                               </p>
//                             </div>
//                             <div className="text-primaryDarkest border-r-[1px] border-primaryDarkest justify-center items-center w-full leading-3">
//                               <p className="font-medium flex flex-row justify-center items-center gap-2 text-xs">
//                                 <FavoriteIcon />
//                                 Completed Tasks
//                               </p>
//                               <p className="font-semibold text-xl text-center">
//                                 {data?.completed_task_count || 0}{" "}
//                                 {data?.completed_task_count > 1 && "+"}
//                               </p>
//                             </div>
//                             <div className="text-primaryDarkest justify-center items-center w-full leading-3">
//                               <p className="font-medium flex flex-row justify-center items-center gap-2 text-xs">
//                                 <RecomondationIcon />
//                                 Recommendations
//                               </p>
//                               <p className="font-semibold text-xl text-center">
//                                 {data?.recommendation_count || 0}
//                               </p>
//                             </div>
//                           </div>
//                           {/* in mobile: rating list */}
//                           <div className="flex md:hidden flex-col gap-1 rounded-md bg-[#E0EFE2] p-3">
//                             <p className="flex flex-row gap-1 justify-start items-center">
//                               <CompleteIcon /> Favorited by{" "}
//                               <span className="font-bold">
//                                 ({data?.favorited_count || 0})
//                               </span>
//                             </p>

//                             <p className="flex flex-row gap-1 justify-start items-center">
//                               <FavoriteIcon /> Completed Tasks{" "}
//                               <span className="font-bold">
//                                 ({data?.completed_task_count || 0}{" "}
//                                 {data?.completed_task_count > 1 && "+"})
//                               </span>
//                             </p>

//                             <p className="flex flex-row gap-1 justify-start items-center">
//                               <RecomondationIcon /> Recommendations{" "}
//                               <span className="font-bold">({0 || 0})</span>
//                             </p>
//                           </div>
//                         </div>

//                         {/* Latest reviews list........................... */}
//                         <div>
//                           <Text className="text-sm font-bold text-primaryDarkest">
//                             Latest reviews
//                           </Text>

//                           {data?.rates?.length === 0 ? (
//                             <p className="text-base text-textColorTwo">
//                               No reviews
//                             </p>
//                           ) : (
//                             <div className="max-h-[150px] overflow-y-scroll scrollbar-hide">
//                               {data?.rates?.map((item) => {
//                                 return (
//                                   <div>
//                                     <div className="pt-3 flex flex-row gap-3 w-full">
//                                       <div>
//                                         {item?.profile_picture === null ? (
//                                           <UserColorProfile
//                                             name={
//                                               decryptSecureData(
//                                                 item?.full_name
//                                               ) || "N Z"
//                                             }
//                                             color={"#3bb96e"}
//                                             size="32px"
//                                             textSize="14px"
//                                           />
//                                         ) : (
//                                           <img
//                                             src={item?.profile_picture}
//                                             alt=""
//                                             className="flex w-[32px] h-[32px] rounded-full border-[2px]"
//                                           />
//                                         )}
//                                       </div>

//                                       <div className="w-full">
//                                         <div className="flex flex-row justify-between w-full">
//                                           <p className="text-base font-semibold leading-4">
//                                             <p>
//                                               {decryptSecureData(
//                                                 item?.full_name
//                                               )}{" "}
//                                             </p>
//                                             <span className="font-normal text-textColorTwo text-xs">
//                                               {getDateDifference(
//                                                 item?.rated_date_time
//                                               )}
//                                             </span>
//                                           </p>

//                                           <div className="flex flex-row gap-1">
//                                             {Array.from(
//                                               { length: item?.rate },
//                                               (_, i) => (
//                                                 <ViewsIcon key={i} />
//                                               )
//                                             )}
//                                           </div>
//                                         </div>

//                                         <p className="text-xs font-normal text-textColorTwo">
//                                           {item?.rate_des || "No description"}
//                                         </p>
//                                       </div>
//                                     </div>
//                                     <Divider className="bg-slate-200 my-2" />
//                                   </div>
//                                 );
//                               })}
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </Col>
//                 </Row>
//               </div>
//             </div>
//           );
//         })}
//       </div>

//       {/* {openCloseContactDetails && (
//         <WorkerContactDetails
//           open={openCloseContactDetails}
//           onCancel={() => {
//             setOpenCloseContactDetails(false);
//           }}
//           workerData={workerContactDetails}
//         />
//       )} */}
//     </>
//   );
// };

// export default TaskerListCardAboutSearchResult;
