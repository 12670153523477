import React from "react";

const Icon2 = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6814 9.21042C8.54685 9.40006 8.54685 9.66863 8.54685 10.2058C8.54685 10.7429 8.54685 11.0115 8.6814 11.2011C8.72888 11.268 8.78731 11.3265 8.85422 11.3739C9.04386 11.5085 9.31243 11.5085 9.84958 11.5085C10.3867 11.5085 10.6553 11.5085 10.8449 11.3739C10.9118 11.3265 10.9703 11.268 11.0177 11.2011C11.1523 11.0115 11.1523 10.7429 11.1523 10.2058C11.1523 9.66863 11.1523 9.40006 11.0177 9.21042C10.9703 9.14351 10.9118 9.08508 10.8449 9.0376C10.6553 8.90305 10.3867 8.90305 9.84958 8.90305C9.31243 8.90305 9.04386 8.90305 8.85422 9.0376C8.78731 9.08508 8.72888 9.14351 8.6814 9.21042Z"
        fill="#2C8B52"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4072 5.03678C12.8683 5.08061 13.2979 5.17572 13.692 5.41721C14.0776 5.65353 14.4018 5.97776 14.6381 6.36339C14.8796 6.75746 14.9747 7.18709 15.0186 7.64811C15.0605 8.08909 15.0605 8.63204 15.0605 9.28263L15.0605 10.5205C15.0605 11.4569 15.0605 12.2377 14.9772 12.8576C14.8892 13.5119 14.6957 14.1026 14.221 14.5772C13.7464 15.0519 13.1557 15.2454 12.5014 15.3334C11.8815 15.4167 11.1007 15.4167 10.1643 15.4167L8.92652 15.4167C8.27592 15.4167 7.73289 15.4167 7.29191 15.3748C6.83089 15.3309 6.40126 15.2358 6.00719 14.9943C5.62156 14.758 5.29732 14.4338 5.06101 14.0482C4.81952 13.6541 4.72441 13.2245 4.68058 12.7634C4.63865 12.3224 4.63866 11.7795 4.63867 11.1288V11.0524C4.63866 10.0623 4.63865 9.26371 4.69977 8.6209C4.7628 7.95798 4.89631 7.38041 5.21458 6.86103C5.53683 6.33517 5.97897 5.89303 6.50483 5.57078C7.02421 5.25251 7.60178 5.11901 8.2647 5.05597C8.90751 4.99486 9.70603 4.99486 10.6962 4.99487H10.7726C11.4233 4.99486 11.9662 4.99485 12.4072 5.03678ZM12.2593 6.59303C11.9011 6.55897 11.4329 6.55815 10.7354 6.55815C9.69684 6.55815 8.97279 6.55897 8.41267 6.61223C7.86418 6.66438 7.5541 6.76124 7.32164 6.90369C7.00612 7.09704 6.74084 7.36232 6.54749 7.67784C6.40503 7.91031 6.30818 8.22038 6.25603 8.76887C6.20277 9.32899 6.20194 10.053 6.20194 11.0916C6.20194 11.7891 6.20277 12.2573 6.23683 12.6155C6.26978 12.9621 6.32825 13.1242 6.39391 13.2314C6.50133 13.4066 6.64871 13.554 6.824 13.6614C6.93116 13.7271 7.09329 13.7856 7.43988 13.8185C7.7981 13.8526 8.26629 13.8534 8.96372 13.8534H10.1101C11.1148 13.8534 11.7897 13.8517 12.2931 13.7841C12.774 13.7194 12.9798 13.6077 13.1156 13.4718C13.2515 13.336 13.3632 13.1302 13.4279 12.6493C13.4955 12.1459 13.4972 11.471 13.4972 10.4663V9.31992C13.4972 8.62249 13.4964 8.1543 13.4623 7.79608C13.4294 7.44949 13.3709 7.28736 13.3052 7.1802C13.1978 7.00491 13.0504 6.85753 12.8752 6.75011C12.768 6.68445 12.6059 6.62598 12.2593 6.59303Z"
        fill="#2C8B52"
      />
      <path
        d="M20.6665 9.21042C20.5319 9.40006 20.5319 9.66863 20.5319 10.2058C20.5319 10.7429 20.5319 11.0115 20.6665 11.2011C20.714 11.268 20.7724 11.3265 20.8393 11.3739C21.0289 11.5085 21.2975 11.5085 21.8346 11.5085C22.3718 11.5085 22.6404 11.5085 22.83 11.3739C22.8969 11.3265 22.9553 11.268 23.0028 11.2011C23.1374 11.0115 23.1374 10.7429 23.1374 10.2058C23.1374 9.66863 23.1374 9.40006 23.0028 9.21042C22.9553 9.14351 22.8969 9.08508 22.83 9.0376C22.6404 8.90305 22.3718 8.90305 21.8346 8.90305C21.2975 8.90305 21.0289 8.90305 20.8393 9.0376C20.7724 9.08508 20.714 9.14351 20.6665 9.21042Z"
        fill="#2C8B52"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9116 4.99487H20.988C21.9782 4.99486 22.7767 4.99486 23.4195 5.05597C24.0824 5.11901 24.66 5.25251 25.1794 5.57078C25.7053 5.89303 26.1474 6.33517 26.4696 6.86103C26.7879 7.38041 26.9214 7.95798 26.9845 8.6209C27.0456 9.26372 27.0456 10.0622 27.0456 11.0524V11.1288C27.0456 11.7795 27.0456 12.3224 27.0036 12.7634C26.9598 13.2245 26.8647 13.6541 26.6232 14.0482C26.3869 14.4338 26.0627 14.758 25.677 14.9943C25.283 15.2358 24.8533 15.3309 24.3923 15.3748C23.9514 15.4167 23.4084 15.4167 22.7579 15.4167L21.5199 15.4167C20.5836 15.4167 19.8027 15.4167 19.1828 15.3334C18.5286 15.2454 17.9379 15.0519 17.4632 14.5772C16.9885 14.1026 16.795 13.5119 16.707 12.8576C16.6237 12.2377 16.6237 11.4569 16.6237 10.5205V9.28273C16.6237 8.63209 16.6237 8.08912 16.6657 7.64811C16.7095 7.18709 16.8046 6.75746 17.0461 6.36339C17.2824 5.97776 17.6066 5.65353 17.9923 5.41721C18.3863 5.17572 18.816 5.08061 19.277 5.03678C19.718 4.99485 20.261 4.99486 20.9116 4.99487ZM19.425 6.59303C19.0784 6.62598 18.9162 6.68445 18.8091 6.75011C18.6338 6.85753 18.4864 7.00491 18.379 7.1802C18.3133 7.28736 18.2549 7.44949 18.2219 7.79608C18.1878 8.1543 18.187 8.62249 18.187 9.31992V10.4663C18.187 11.471 18.1887 12.1459 18.2564 12.6493C18.321 13.1302 18.4328 13.336 18.5686 13.4718C18.7044 13.6077 18.9102 13.7194 19.3911 13.7841C19.8946 13.8517 20.5694 13.8534 21.5741 13.8534H22.7205C23.4179 13.8534 23.8861 13.8526 24.2443 13.8185C24.5909 13.7856 24.7531 13.7271 24.8602 13.6614C25.0355 13.554 25.1829 13.4066 25.2903 13.2314C25.356 13.1242 25.4144 12.9621 25.4474 12.6155C25.4815 12.2573 25.4823 11.7891 25.4823 11.0916C25.4823 10.053 25.4815 9.32899 25.4282 8.76887C25.376 8.22038 25.2792 7.91031 25.1367 7.67784C24.9434 7.36232 24.6781 7.09704 24.3626 6.90369C24.1301 6.76124 23.82 6.66438 23.2716 6.61223C22.7114 6.55897 21.9874 6.55815 20.9488 6.55815C20.2514 6.55815 19.7832 6.55897 19.425 6.59303Z"
        fill="#2C8B52"
      />
      <path
        d="M8.54685 22.1908C8.54685 21.6537 8.54685 21.3851 8.6814 21.1955C8.72888 21.1286 8.78731 21.0702 8.85422 21.0227C9.04386 20.8881 9.31243 20.8881 9.84958 20.8881C10.3867 20.8881 10.6553 20.8881 10.8449 21.0227C10.9118 21.0702 10.9703 21.1286 11.0177 21.1955C11.1523 21.3851 11.1523 21.6537 11.1523 22.1908C11.1523 22.728 11.1523 22.9966 11.0177 23.1862C10.9703 23.2531 10.9118 23.3115 10.8449 23.359C10.6553 23.4936 10.3867 23.4936 9.84958 23.4936C9.31243 23.4936 9.04386 23.4936 8.85422 23.359C8.78731 23.3115 8.72888 23.2531 8.6814 23.1862C8.54685 22.9966 8.54685 22.728 8.54685 22.1908Z"
        fill="#2C8B52"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5014 17.0632C13.1557 17.1512 13.7464 17.3447 14.221 17.8194C14.6957 18.2941 14.8892 18.8848 14.9772 19.539C15.0605 20.1589 15.0605 20.9397 15.0605 21.8761L15.0605 23.1139C15.0605 23.7644 15.0605 24.3076 15.0186 24.7485C14.9747 25.2095 14.8796 25.6392 14.6381 26.0332C14.4018 26.4189 14.0776 26.7431 13.692 26.9794C13.2979 27.2209 12.8683 27.316 12.4072 27.3598C11.9662 27.4018 11.4233 27.4018 10.7726 27.4018H10.6962C9.70604 27.4018 8.90751 27.4018 8.2647 27.3407C7.60178 27.2776 7.02421 27.1441 6.50483 26.8258C5.97897 26.5036 5.53683 26.0615 5.21458 25.5356C4.89631 25.0162 4.7628 24.4386 4.69977 23.7757C4.63865 23.1329 4.63866 22.3344 4.63867 21.3443V21.2678C4.63866 20.6172 4.63865 20.0742 4.68058 19.6332C4.72441 19.1722 4.81952 18.7425 5.06101 18.3485C5.29732 17.9628 5.62156 17.6386 6.00719 17.4023C6.40126 17.1608 6.83089 17.0657 7.29191 17.0219C7.73292 16.9799 8.27589 16.9799 8.92653 16.9799H10.1643C11.1007 16.9799 11.8815 16.9799 12.5014 17.0632ZM12.2931 18.6126C11.7897 18.5449 11.1148 18.5432 10.1101 18.5432H8.96372C8.26629 18.5432 7.7981 18.544 7.43988 18.5781C7.09329 18.6111 6.93116 18.6695 6.824 18.7352C6.64871 18.8426 6.50133 18.99 6.39391 19.1653C6.32825 19.2724 6.26978 19.4346 6.23683 19.7812C6.20277 20.1394 6.20194 20.6076 6.20194 21.305C6.20194 22.3436 6.20277 23.0676 6.25603 23.6278C6.30818 24.1763 6.40503 24.4863 6.54749 24.7188C6.74084 25.0343 7.00612 25.2996 7.32164 25.4929C7.5541 25.6354 7.86418 25.7323 8.41267 25.7844C8.97279 25.8377 9.69684 25.8385 10.7354 25.8385C11.4329 25.8385 11.9011 25.8377 12.2593 25.8036C12.6059 25.7706 12.768 25.7122 12.8752 25.6465C13.0504 25.5391 13.1978 25.3917 13.3052 25.2164C13.3709 25.1093 13.4294 24.9471 13.4623 24.6005C13.4964 24.2423 13.4972 23.7741 13.4972 23.0767V21.9303C13.4972 20.9256 13.4955 20.2508 13.4279 19.7473C13.3632 19.2664 13.2515 19.0606 13.1156 18.9248C12.9798 18.789 12.774 18.6772 12.2931 18.6126Z"
        fill="#2C8B52"
      />
      <path
        d="M21.0131 16.9799L23.1374 16.9799V18.5432H21.053C20.3049 18.5432 19.8026 18.5442 19.4197 18.5831C19.0499 18.6207 18.879 18.6872 18.766 18.7628C18.6237 18.8578 18.5016 18.9799 18.4066 19.1222C18.331 19.2352 18.2645 19.4061 18.2269 19.7759C18.188 20.1588 18.187 20.6611 18.187 21.4092H16.6237L16.6237 21.3693C16.6237 20.6714 16.6237 20.0891 16.6717 19.6177C16.7219 19.1243 16.831 18.6664 17.1068 18.2537C17.3159 17.9407 17.5845 17.6721 17.8975 17.463C18.3102 17.1872 18.7681 17.0781 19.2615 17.0279C19.7329 16.9799 20.3152 16.9799 21.0131 16.9799Z"
        fill="#2C8B52"
      />
      <path
        d="M16.6237 26.6201V23.4936H18.187V26.6201C18.187 27.0518 17.8371 27.4018 17.4054 27.4018C16.9737 27.4018 16.6237 27.0518 16.6237 26.6201Z"
        fill="#2C8B52"
      />
      <path
        d="M27.0456 17.7616C27.0456 17.3299 26.6956 16.9799 26.2639 16.9799C25.8322 16.9799 25.4823 17.3299 25.4823 17.7616V21.4092H27.0456V17.7616Z"
        fill="#2C8B52"
      />
      <path
        d="M25.4823 23.4936C25.4823 23.9899 25.4819 24.3229 25.4643 24.5808C25.4471 24.8317 25.4164 24.9539 25.3831 25.0343C25.2509 25.3535 24.9973 25.6071 24.6781 25.7393C24.5977 25.7726 24.4755 25.8033 24.2246 25.8205C23.9667 25.8381 23.6337 25.8385 23.1374 25.8385H21.053V27.4018H23.1637C23.6269 27.4018 24.0136 27.4018 24.3311 27.3801C24.6615 27.3576 24.9737 27.309 25.2763 27.1836C25.9786 26.8927 26.5365 26.3348 26.8274 25.6325C26.9527 25.3299 27.0014 25.0177 27.0239 24.6873C27.0456 24.3698 27.0456 23.9832 27.0456 23.52V23.4936H25.4823Z"
        fill="#2C8B52"
      />
      <path
        d="M20.1865 20.8302C20.0108 21.0931 20.0108 21.459 20.0108 22.1908C20.0108 22.9227 20.0108 23.2886 20.1865 23.5515C20.2625 23.6653 20.3602 23.763 20.474 23.839C20.7369 24.0147 21.1028 24.0147 21.8346 24.0147C22.5665 24.0147 22.9324 24.0147 23.1953 23.839C23.3091 23.763 23.4068 23.6653 23.4828 23.5515C23.6585 23.2886 23.6585 22.9227 23.6585 22.1908C23.6585 21.459 23.6585 21.0931 23.4828 20.8302C23.4068 20.7164 23.3091 20.6187 23.1953 20.5427C22.9324 20.367 22.5665 20.367 21.8346 20.367C21.1028 20.367 20.7369 20.367 20.474 20.5427C20.3602 20.6187 20.2625 20.7164 20.1865 20.8302Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default Icon2;
