import { Image, Typography } from "antd";
import React, { useState } from "react";
import DropDownIcon from "../../assets/svg/DropDownIcon";
import DropDownIconUpward from "../../assets/svg/DropDownIconUpward";

const { Text } = Typography;

const PublicServicesCard = ({
  data,
  openedService,
  setOpenedService,
  currency,
}) => {
  return (
    <div
      className={`flex flex-col p-2 w-full rounded-md ${
        openedService === data.job_category_id ? "bg-[#E0EFE2]" : "bg-[#F2F2F2]"
      } `}
    >
      <div
        className={`flex flex-col items-center justify-center rounded-lg w-full p-2 h-20`}
      >
        <div className="flex flex-row gap-2 justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center">
            <Image
              src={data?.image}
              width={50}
              height={50}
              preview={false}
              className="rounded-md"
            />
            <div className="flex flex-col">
              <Text className="text-base font-bold">{data?.job_category}</Text>
              <Text className="text-sm">
                {data?.job_type.length} Job types added
              </Text>
            </div>
          </div>
          <button
            onClick={() => {
              setOpenedService(
                openedService === data.job_category_id
                  ? null
                  : data.job_category_id
              );
            }}
          >
            {openedService === data.job_category_id ? (
              <DropDownIconUpward />
            ) : (
              <DropDownIcon />
            )}
          </button>
        </div>
      </div>
      {openedService === data.job_category_id && (
        <>
          {data.job_type.length == 0 ? (
            <div className="flex w-full justify-center items-center">
              No Job Types
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {data.job_type.map((type, index) => (
                <div
                  className={`flex flex-col gap-2 border border-cardBorderColorOne bg-white rounded-lg w-full p-4 min-h-24`}
                  key={index}
                >
                  <div className="flex flex-row gap-2 w-full items-center justify-between">
                    <Text className="text-base font-bold">{type.job_type}</Text>
                  </div>
                  <div className="flex flex-row w-full gap-2 justify-between items-center">
                    <Text className="text-base">Rate</Text>
                    <Text className="text-base">
                      <span className="text-xs">({currency})</span>
                      {type.min} /{type.uom_label}
                    </Text>
                  </div>
                  <div className="flex flex-row w-full gap-2 justify-between items-center">
                    <Text className="text-base">Service Area</Text>
                    <Text className="text-base">{type.service_level}</Text>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PublicServicesCard;
