import React from "react";

const Facebook = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1136 0H6.54676C2.93109 0 0 2.93109 0 6.54676V30.1136C0 33.7292 2.93109 36.6603 6.54676 36.6603H30.1136C33.7292 36.6603 36.6603 33.7292 36.6603 30.1136V6.54676C36.6603 2.93109 33.7292 0 30.1136 0Z"
        fill="#0866FF"
      />
      <path
        d="M15.5362 29.4102H19.9961V18.2336H23.0878L23.4202 14.4926H19.9729V12.367C19.9729 11.4859 20.1506 11.1303 21.0009 11.1303H23.4202V7.26562H20.3284C17.0048 7.26562 15.5131 8.72648 15.5131 11.5245V14.508H13.1942V18.2954H15.5131L15.5362 29.4102Z"
        fill="white"
      />
    </svg>
  );
};

export default Facebook;
