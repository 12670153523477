import React, { useState } from "react";
import { Divider, Modal, Pagination } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";
import ReviewStar from "../../assets/svg/ReviewStar";
import UserColorProfile from "../userColorProfile/UserColorProfile";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import ViewsIcon from "../../assets/svg/ViewsIcon";
import { getDateDifference } from "../../utils/dateDifference";

const WorkerReviewBox = ({ open, onCancel, workerData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = workerData?.rates?.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon color="#000000" />}
      footer={false}
      width={600}
    >
      <div>
        <div className="flex flex-row justify-between items-center mt-5">
          <p className="text-base font-semibold text-primaryDarkest">
            All Reviews ({workerData?.rates?.length})
          </p>
          <p className="font-semibold text-xl flex flex-row gap-1 justify-start items-center">
            <ReviewStar width={18} height={18} /> {workerData?.rate}
          </p>
        </div>

        <Divider className="bg-slate-200 my-5" />

        <div>
          {workerData?.rates?.length === 0 ? (
            <p className="text-base text-textColorTwo">No reviews</p>
          ) : (
            <div>
              {currentItems?.map((item) => {
                return (
                  <div>
                    <div className="pt-3 flex flex-row gap-3 w-full">
                      <div className="flex flex-row justify-center gap-1">
                        {item?.profile_picture === null ? (
                          <UserColorProfile
                            name={decryptSecureData(item?.full_name) || "N Z"}
                            color={"#3bb96e"}
                            size="40px"
                            textSize="16px"
                          />
                        ) : (
                          <img
                            src={item?.profile_picture}
                            alt=""
                            className="flex w-[32px] h-[32px] rounded-full border-[2px]"
                          />
                        )}
                      </div>

                      <div className="w-full">
                        <div className="flex flex-row justify-between items-center w-full">
                          <p className="text-base font-semibold">
                            {decryptSecureData(item?.full_name)}{" "}
                            <span className="font-normal text-textColorTwo">
                              {getDateDifference(item?.rated_date_time)}
                              {/* 1 day ago */}
                            </span>
                          </p>

                          <div className="flex flex-row gap-1">
                            {Array.from({ length: item?.rate }, (_, i) => (
                              <ViewsIcon key={i} />
                            ))}
                          </div>
                        </div>

                        <p className="text-xs font-normal text-textColorTwo">
                          {item?.rate_des || "No description"}
                        </p>
                      </div>
                    </div>
                    <Divider className="bg-slate-200 my-2" />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {workerData?.rates?.length > 5 && (
          <div className="flex justify-end">
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={workerData?.rates?.length}
              onChange={handlePageChange}
              showSizeChanger={false}
              responsive={true}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WorkerReviewBox;
