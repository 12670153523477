import React from "react";

const DoneGrayIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.99967"
        cy="10.3464"
        r="6.66667"
        stroke="#939292"
        strokeWidth="1.2"
      />
      <path
        d="M7.66699 10.6797L9.00033 12.013L12.3337 8.67969"
        stroke="#939292"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoneGrayIcon;
