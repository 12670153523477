import React from "react";

const Twitter = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1684 0H6.60154C2.98587 0 0.0547791 2.93109 0.0547791 6.54676V30.1136C0.0547791 33.7292 2.98587 36.6603 6.60154 36.6603H30.1684C33.784 36.6603 36.7151 33.7292 36.7151 30.1136V6.54676C36.7151 2.93109 33.784 0 30.1684 0Z"
        fill="black"
      />
      <path
        d="M27.8806 8.64107C26.8294 8.64107 26.0024 8.58696 25.1908 8.64107C24.7692 8.69242 24.3758 8.87963 24.0701 9.17439C22.6015 10.7744 21.187 12.4285 19.7494 14.0671C19.5562 14.2835 19.3474 14.4922 19.1078 14.7473C18.0412 13.3328 16.9204 12.0729 16.0625 10.6584C15.6978 9.90574 15.0947 9.2946 14.3469 8.92C13.5991 8.54539 12.7485 8.42832 11.9273 8.58697C10.4918 8.6898 9.05135 8.70271 7.61429 8.62562C10.3891 12.2507 13.0248 15.7134 15.7224 19.2303L7.99302 28.0263C9.04421 28.0263 9.86354 28.0881 10.6674 28.0263C11.0918 27.9852 11.4909 27.806 11.8036 27.5161C12.8702 26.3799 13.8673 25.1974 14.8953 24.0225L17.276 21.3172C18.7291 23.2109 20.1745 24.9809 21.4808 26.8592C21.7339 27.2864 22.1068 27.6298 22.5534 27.8469C23 28.064 23.5005 28.1452 23.9928 28.0804C25.6314 27.9722 27.2855 28.0804 29.1174 28.0804L20.6692 16.9037C23.0653 14.1521 25.4072 11.47 27.8806 8.64107ZM24.6575 25.3906C24.7734 25.5452 24.8817 25.6998 25.0517 25.9471C24.0933 26.063 23.4054 26.1017 22.7329 25.1742C19.2547 20.5365 15.6915 15.8989 12.1592 11.3C12.0123 11.099 11.8732 10.8903 11.6722 10.6198C12.1443 10.453 12.6596 10.455 13.1303 10.6256C13.6011 10.7961 13.9981 11.1246 14.2538 11.555C17.6856 16.2313 21.187 20.7993 24.6575 25.3906Z"
        fill="white"
      />
    </svg>
  );
};

export default Twitter;
