import React from "react";

const LocationPInIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 8.37488C11.1569 8.37488 12.5 7.03174 12.5 5.37488C12.5 3.71802 11.1569 2.37488 9.5 2.37488C7.84314 2.37488 6.5 3.71802 6.5 5.37488C6.5 7.03174 7.84314 8.37488 9.5 8.37488Z"
        fill="#474747"
        stroke="#474747"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 8.37488V15.1249"
        stroke="#474747"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 12.8749H5L2 17.3749H17L14 12.8749H12.5"
        stroke="#474747"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationPInIcon;
