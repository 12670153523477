import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/navbar/NavBar";
import PersonalInfo from "../sections/Profile/PersonalInfo";
import AvailableIn from "../sections/Profile/AvailableIn";
import Review from "../sections/Profile/Review";
import { Spin } from "antd";
import Services from "../sections/Profile/Services";
import PublicProfileService from "../services/PublicProfileService";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../context/NotificationContext";
import CoverSection from "../sections/Profile/CoverSection";
import PublicURL from "../sections/Profile/PublicURL";
import WorkSampleImageList from "../sections/Profile/WorkSampleImageList";
import WorkerProfileProgressBar from "../components/workerProfile/WorkerProfileProgressBar";

const PublicProfileEditor = () => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { fetchPublicWorkerProfile } = PublicProfileService();

  const [profileData, setProfileData] = useState(null);
  const [profileLoading, setProfileLoading] = useState(false);

  useEffect(() => {
    getWorkerProfileData();
  }, []);

  const getWorkerProfileData = async () => {
    setProfileLoading(true);

    try {
      await fetchPublicWorkerProfile({
        worker_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            setProfileData(response.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setProfileLoading(false);
    } catch (error) {
      openNotification("error", error);
      setProfileLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <div className="flex flex-col w-full justify-center items-center mt-28">
        <div className="max-w-[1280px] w-full mx-2">
          {profileLoading ? (
            <div className="flex w-full justify-center items-center">
              <Spin className="flex " />
            </div>
          ) : (
            <>
              <CoverSection
                data={profileData}
                getWorkerProfileData={getWorkerProfileData}
                editable={true}
              />
              <WorkerProfileProgressBar
                data={profileData}
                getWorkerProfileData={getWorkerProfileData}
              />
              <PersonalInfo
                data={profileData}
                getWorkerProfileData={getWorkerProfileData}
                editable={true}
              />
              <PublicURL
                data={profileData}
                getWorkerProfileData={getWorkerProfileData}
                editable={true}
              />
              <WorkSampleImageList
                workSamples={profileData?.portfolios}
                listSize="large"
                data={profileData}
                getWorkerProfileData={getWorkerProfileData}
                editable={true}
              />
              {profileData !== null && (
                <Services
                  data={profileData}
                  getWorkerProfileData={getWorkerProfileData}
                  editable={true}
                />
              )}
              {profileData !== null && (
                <AvailableIn data={profileData} editable={true} />
              )}
              <Review data={profileData} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PublicProfileEditor;
