import React from "react";

const NameIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.75"
        cy="6.93945"
        r="2.66667"
        stroke="#2C8B52"
        stroke-width="1.2"
      />
      <path
        d="M16.0834 14.6061C16.0834 16.263 16.0834 17.6061 10.75 17.6061C5.41669 17.6061 5.41669 16.263 5.41669 14.6061C5.41669 12.9493 7.8045 11.6061 10.75 11.6061C13.6955 11.6061 16.0834 12.9493 16.0834 14.6061Z"
        stroke="#2C8B52"
        stroke-width="1.2"
      />
    </svg>
  );
};

export default NameIcon;
