import React from "react";

const PhoneGreen = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5542 18.1874L18.0988 18.6669C18.0988 18.6669 17.0162 19.8066 14.0612 16.6955C11.1062 13.5845 12.1888 12.4447 12.1888 12.4447L12.4756 12.1428C13.1821 11.3989 13.2487 10.2046 12.6323 9.33275L11.3713 7.54926C10.6083 6.47015 9.13401 6.3276 8.2595 7.24829L6.6899 8.90078C6.25628 9.35731 5.9657 9.9491 6.00093 10.6056C6.09108 12.2851 6.80876 15.8987 10.8134 20.1148C15.0602 24.5859 19.0449 24.7636 20.6743 24.6027C21.1897 24.5519 21.638 24.2739 21.9992 23.8937L23.4197 22.3981C24.3786 21.3886 24.1083 19.6578 22.8814 18.9516L20.9709 17.852C20.1653 17.3883 19.1838 17.5245 18.5542 18.1874Z"
        fill="#2C8B52"
      />
      <path
        d="M16.2575 5.51948C16.3237 5.11059 16.7103 4.83322 17.1192 4.89941C17.1445 4.90426 17.2259 4.91948 17.2686 4.92898C17.3539 4.94798 17.4729 4.97724 17.6213 5.02047C17.9181 5.10691 18.3327 5.24932 18.8303 5.47745C19.8266 5.93421 21.1524 6.73321 22.5282 8.10901C23.904 9.48481 24.703 10.8106 25.1598 11.8069C25.3879 12.3045 25.5303 12.7191 25.6168 13.0159C25.66 13.1643 25.6893 13.2833 25.7083 13.3687C25.7178 13.4113 25.7247 13.4456 25.7295 13.4709L25.7353 13.5021C25.8015 13.911 25.5266 14.3135 25.1178 14.3797C24.71 14.4457 24.3259 14.1697 24.2581 13.7627C24.256 13.7518 24.2503 13.7224 24.2441 13.6947C24.2318 13.6393 24.2104 13.5515 24.1766 13.4354C24.1089 13.203 23.9914 12.8577 23.7963 12.432C23.4064 11.5817 22.7054 10.4075 21.4676 9.16967C20.2297 7.93181 19.0555 7.23081 18.2052 6.84098C17.7795 6.64583 17.4342 6.52829 17.2019 6.46062C17.0857 6.42679 16.9397 6.39328 16.8843 6.38094C16.4774 6.31312 16.1915 5.9272 16.2575 5.51948Z"
        fill="#2C8B52"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4838 8.96895C16.5976 8.57067 17.0127 8.34005 17.411 8.45384L17.2049 9.17499C17.411 8.45384 17.411 8.45384 17.411 8.45384L17.4124 8.45426L17.4139 8.4547L17.4173 8.45567L17.425 8.45799L17.4448 8.46415C17.4599 8.46897 17.4787 8.47525 17.5012 8.48322C17.5461 8.49916 17.6054 8.52182 17.6782 8.55302C17.8239 8.61546 18.023 8.71188 18.2675 8.85659C18.757 9.14627 19.4252 9.62745 20.2101 10.4124C20.9951 11.1974 21.4763 11.8656 21.766 12.355C21.9107 12.5996 22.0071 12.7986 22.0695 12.9443C22.1007 13.0171 22.1234 13.0765 22.1393 13.1214C22.1473 13.1438 22.1536 13.1627 22.1584 13.1777L22.1646 13.1975L22.1669 13.2053L22.1679 13.2086L22.1683 13.2101C22.1683 13.2101 22.1687 13.2116 21.4476 13.4176L22.1687 13.2116C22.2825 13.6099 22.0519 14.025 21.6536 14.1388C21.2587 14.2516 20.8473 14.0258 20.7294 13.6338L20.7257 13.623C20.7204 13.608 20.7093 13.5784 20.6908 13.5352C20.6538 13.4488 20.5867 13.3076 20.4751 13.119C20.2521 12.7423 19.8494 12.173 19.1495 11.4731C18.4495 10.7731 17.8803 10.3704 17.5035 10.1475C17.3149 10.0358 17.1737 9.96874 17.0874 9.93174C17.0442 9.91323 17.0146 9.90219 16.9996 9.89686L16.9888 9.89316C16.5967 9.77529 16.371 9.36384 16.4838 8.96895Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default PhoneGreen;
