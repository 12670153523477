import React from "react";

const VerifiedBlueIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1668 9.99967C20.1668 15.3384 15.8389 19.6663 10.5002 19.6663C5.16141 19.6663 0.833496 15.3384 0.833496 9.99967C0.833496 4.66092 5.16141 0.333008 10.5002 0.333008C15.8389 0.333008 20.1668 4.66092 20.1668 9.99967ZM14.3961 7.07035C14.6793 7.35348 14.6793 7.81253 14.3961 8.09566L9.56281 12.929C9.27969 13.2121 8.82064 13.2121 8.53751 12.929L6.60418 10.9957C6.32105 10.7125 6.32105 10.2535 6.60418 9.97035C6.88731 9.68722 7.34635 9.68722 7.62948 9.97035L9.05016 11.391L11.2105 9.23069L13.3708 7.07035C13.654 6.78722 14.113 6.78722 14.3961 7.07035Z"
        fill="#1D66F6"
      />
    </svg>
  );
};

export default VerifiedBlueIcon;
