import React from "react";

const CountryGreenOutlineIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6331 10.1686C17.6331 11.0441 17.4607 11.911 17.1257 12.7198C16.7906 13.5287 16.2996 14.2636 15.6805 14.8827C15.0615 15.5017 14.3265 15.9928 13.5177 16.3278C12.7089 16.6628 11.842 16.8353 10.9665 16.8353C10.091 16.8353 9.22409 16.6628 8.41525 16.3278C7.60641 15.9928 6.87148 15.5017 6.25243 14.8827C5.63337 14.2636 5.14231 13.5287 4.80727 12.7198C4.47224 11.911 4.2998 11.0441 4.2998 10.1686C4.2998 9.29314 4.47224 8.42623 4.80727 7.6174C5.14231 6.80856 5.63337 6.07363 6.25243 5.45457C6.87148 4.83552 7.60641 4.34445 8.41525 4.00942C9.22409 3.67439 10.091 3.50195 10.9665 3.50195C11.842 3.50195 12.7089 3.67439 13.5177 4.00942C14.3265 4.34445 15.0615 4.83552 15.6805 5.45458C16.2996 6.07363 16.7906 6.80856 17.1257 7.6174C17.4607 8.42624 17.6331 9.29314 17.6331 10.1686L17.6331 10.1686Z"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
      <path
        d="M13.6331 10.1686C13.6331 11.0441 13.5642 11.911 13.4301 12.7198C13.2961 13.5287 13.0997 14.2636 12.8521 14.8827C12.6045 15.5017 12.3105 15.9928 11.987 16.3278C11.6634 16.6628 11.3167 16.8353 10.9665 16.8353C10.6163 16.8353 10.2695 16.6628 9.94598 16.3278C9.62245 15.9928 9.32848 15.5017 9.08085 14.8827C8.83323 14.2636 8.63681 13.5287 8.50279 12.7198C8.36878 11.911 8.2998 11.0441 8.2998 10.1686C8.2998 9.29314 8.36878 8.42623 8.50279 7.6174C8.63681 6.80856 8.83323 6.07363 9.08085 5.45457C9.32848 4.83552 9.62245 4.34445 9.94598 4.00942C10.2695 3.67439 10.6163 3.50195 10.9665 3.50195C11.3167 3.50195 11.6634 3.67439 11.987 4.00942C12.3105 4.34445 12.6045 4.83552 12.8521 5.45458C13.0997 6.07363 13.2961 6.80856 13.4301 7.6174C13.5642 8.42624 13.6331 9.29314 13.6331 10.1686L13.6331 10.1686Z"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
      <path
        d="M4.2998 10.1689H17.6331"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CountryGreenOutlineIcon;
