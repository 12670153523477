import {
  Button,
  Checkbox,
  Form,
  Modal,
  Select,
  Slider,
  Spin,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import PublicProfileService from "../../services/PublicProfileService";
import axios from "axios";
import HeaderConfig from "../../helpers/HeaderConfig";
import SmallI from "../../assets/svg/SmallI";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";

const { Text } = Typography;

const NewServiceArea = ({ open, onClose, data, fetchAvailableIns }) => {
  const { configAuth } = HeaderConfig();
  let { openNotification, handleError } = useContext(NotificationContext);
  const {
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
    getRelatedSearchedPostalCode,
  } = CreateJobPostServices();
  const { fetchAIBaseURL, addTradySuburb } = PublicProfileService();

  const [aIBaseURL, setAIBaseURL] = useState(null);

  const [location1SelectorData, setLocation1SelectorData] = useState([]);
  const [location2SelectorData, setLocation2SelectorData] = useState([]);
  const [postalCodeRequired, setPostalCodeRequired] = useState();
  const [postalCodeData, setPostalCodeData] = useState(null);
  const [allPostalCodes, setAllPostalCodes] = useState([]);
  const [isStateSelected, setIsStateSelected] = useState(false);

  const [suburbId, setSuburbId] = useState(null);
  const [sliderValue, setSliderValue] = useState(25);

  const [loading, setLoading] = useState(false);

  const [suburbsList, setSuburbList] = useState([]);
  const [selectedSuburbs, setSelectedSuburbs] = useState([]); // NEW state to track selected suburbs

  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  const [form] = Form.useForm();

  const [labels, setLabels] = useState({
    level_1_label: "State",
    level_2_label: "Suburb",
  });

  useEffect(() => {
    getAIBaseURL();
    fetchCountryData();
    fetchLocationLevel1Data();
  }, []);

  const getAIBaseURL = async () => {
    try {
      await fetchAIBaseURL()
        .then((response) => {
          if (response?.data.success) {
            setAIBaseURL(response?.data.output.url);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchCountryData = async () => {
    setLoading(true);
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            let selectedCountry = response?.data?.output.find(
              (country) => country.country_id == data?.country_id
            );

            setPostalCodeRequired(
              selectedCountry.postal_code_required === 1 ? true : false
            );

            setLabels({
              level_1_label: selectedCountry?.level_1_label,
              level_2_label: selectedCountry?.level_2_label,
            });

            if (selectedCountry.postal_code_required === 1) {
              handleGetRelatedPostalCode(data?.postal_code);
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const fetchLocationLevel1Data = async (e) => {
    try {
      await getAllLocationLevel1Data({ country_id: data?.country_id })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location1) => {
              let data = {
                value: location1?.level1_id,
                label: location1?.level1_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation1SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchLocationLevel2Data = async (e) => {
    try {
      await getAllLocationLevel2Data({
        country_id: data?.country_id,
        level1_id: e,
      })
        .then((response) => {
          if (response?.data.success) {
            var locationLevel1Object = [];
            response?.data.output?.forEach((location2) => {
              let data = {
                value: location2?.level2_id,
                label: location2?.level2_location,
              };
              locationLevel1Object.push(data);
            });
            setLocation2SelectorData(locationLevel1Object);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: data?.country_id,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setAllPostalCodes(response?.data.output);

            var postalCodeObject = [];
            response?.data.output?.forEach((item) => {
              let data = {
                value: item?.suberb_id,
                label:
                  item?.postal_code + ", " + item?.suberb + ", " + item?.state,
              };
              postalCodeObject.push(data);
            });
            setPostalCodeData(postalCodeObject);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const onFinish = async (values) => {
    const jsnObj = {
      l1_id: suburbId.toString(),
      country_id: data?.country_id,
      radius: values.radius,
    };

    try {
      await axios
        .post(aIBaseURL, jsnObj, configAuth)
        .then((response) => {
          if (response?.data.success) {
            setSelectedSuburbs([]);
            setSuburbList(response.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const onSliderChange = (value) => {
    setSliderValue(value);
  };

  // Function to toggle checkbox selection
  const handleSuburbSelect = (suburbId) => {
    let updatedSelection;
    if (selectedSuburbs.includes(suburbId)) {
      updatedSelection = selectedSuburbs.filter((id) => id !== suburbId); // Deselect if already selected
    } else {
      if (selectedSuburbs.length < 50) {
        updatedSelection = [...selectedSuburbs, suburbId]; // Add if not selected and below the limit
      } else {
        openNotification(
          "warning",
          "Maximum location limit has been exceed. Try again!"
        );
        return;
      }
    }

    setSelectedSuburbs(updatedSelection);
  };

  const handleAreaSave = async () => {
    const dataToSave = selectedSuburbs.map((suburbId) => {
      const suburb = suburbsList.find((item) => item.l1_id === suburbId);

      return {
        state_id: suburb.l2_id,
        suburb_id: suburb.l1_id,
      };
    });

    try {
      await addTradySuburb({
        user_id: getLocalStoragedata("userId"),
        country_id: data?.country_id,
        service_suburbs: dataToSave,
      })
        .then((response) => {
          if (response?.data.success) {
            openNotification("success", response.data.message);
            onClose();
            fetchAvailableIns();
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const compareData = (initial, updated) => {
    let changes = {};

    updated.forEach((field) => {
      const key = field.name[0]; // Get the field name
      if (initial[key] !== field.value) {
        changes[key] = {
          initial: initial[key],
          updated: field.value,
        };
      }
    });

    setIsFieldChanged(Object.keys(changes).length > 0 ? true : false); // Return null if no changes
  };

  const handleFormChange = (_, allFields) => {
    compareData(
      {
        zip: null,
        stateProvince: null,
        citySuburb: null,
        radius: 25,
      },
      allFields
    );
  };

  return (
    <Modal
      open={open}
      footer={null}
      width={1000}
      title="Add New Service Areas"
      onCancel={() => {
        if (isFieldChanged) {
          setShowExitConfirmation(true);
        } else {
          onClose();
        }
      }}
    >
      <div className="flex flex-col gap-2">
        <p className="flex text-sm text-[#7B7B7B]">
          Search nearby Locations and choose your preferred service locations
        </p>
        {loading ? (
          <div className="flex w-full justify-center items-center">
            <Spin />
          </div>
        ) : (
          <Form
            onFinish={onFinish}
            layout="vertical"
            form={form}
            className="flex flex-col gap-2"
            initialValues={{ radius: 25 }}
            onFieldsChange={handleFormChange}
          >
            <div className="flex flex-col w-full gap-4">
              {postalCodeRequired && (
                <>
                  <Text className="font-semibold ml-2">
                    Postal Code <span className="text-red-500">*</span>
                  </Text>
                  <Form.Item
                    name="zip"
                    rules={[
                      { required: true, message: "Postal Code is required!" },
                    ]}
                    className="mb-0"
                  >
                    <Select
                      size="large"
                      className="w-full"
                      placeholder="Enter Postal Code"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={postalCodeData}
                      notFoundContent={
                        postalCodeData === null
                          ? "Please enter your property postal code"
                          : "No Data"
                      }
                      onSearch={(e) => {
                        if (e?.length === 4) {
                          handleGetRelatedPostalCode(e);
                        }

                        if (e?.length < 4) {
                          setPostalCodeData(null);
                        }
                      }}
                      onSelect={(e) => {
                        let suburb = allPostalCodes.find(
                          (item) => item.suberb_id === e
                        );

                        setSuburbId(suburb.suberb_id);
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </div>
            <div className="flex flex-col w-full gap-2">
              {!postalCodeRequired && (
                <>
                  <div className="flex flex-col w-full">
                    <Text className="font-semibold ml-2">
                      {labels.level_1_label}{" "}
                      <span className="text-red-500">*</span>
                    </Text>
                    <Form.Item
                      name="stateProvince"
                      rules={[
                        {
                          required: true,
                          message: `${labels.level_1_label} is required!`,
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        size="large"
                        placeholder={`Select ${labels.level_1_label}`}
                        options={location1SelectorData}
                        onSelect={(e) => {
                          fetchLocationLevel2Data(e);
                          setIsStateSelected(true);
                          setSuburbId(null);
                          form.setFieldValue("citySuburb", null);
                        }}
                        showSearch
                        className="rounded-lg"
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </div>

                  <div className="flex flex-col w-full">
                    <Text className="font-semibold ml-2">
                      {labels.level_2_label}{" "}
                      <span className="text-red-500">*</span>
                    </Text>
                    <Form.Item
                      name="citySuburb"
                      rules={[
                        {
                          required: true,
                          message: `${labels.level_2_label} is required!`,
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        size="large"
                        placeholder={`Select ${labels.level_2_label}`}
                        options={location2SelectorData}
                        className="rounded-lg"
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onSelect={(e) => {
                          setSuburbId(e);
                        }}
                        disabled={!isStateSelected}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-col w-full p-2 rounded-md bg-[#F0F0F0]">
              <Text className="font-semibold text-base ml-2">
                Expand search area
              </Text>
              <Form.Item name="radius" className="mb-0">
                <Slider
                  min={15}
                  max={50}
                  trackStyle={{ backgroundColor: "green" }}
                  railStyle={{ backgroundColor: "#B8B8B8" }}
                  handleStyle={{
                    borderColor: "green",
                    backgroundColor: "green",
                  }}
                  onChange={onSliderChange}
                  value={sliderValue}
                />
              </Form.Item>
              <Text className="font-semibold text-base ml-2 text-[#7B7B7B]">
                Radius : {sliderValue}
              </Text>
            </div>

            <div className="flex w-full justify-center items-center mt-4">
              <Form.Item className="w-full">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="flex w-full h-10 font-bold text-base bg-primaryDarkest"
                >
                  Search
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}

        {suburbsList?.length !== 0 && (
          <div className="flex flex-col rounded-md bg-[#F1F1F1] gap-2 p-2">
            <div className="flex flex-col xs:flex-row xs:justify-between xs:items-center px-4">
              <div className="flex flex-row gap-1 items-center">
                <div className="hidden xs:block">
                  <SmallI />
                </div>
                <Text className="flex text-sm text-[#828282]">
                  You can add up to 50 service locations.
                </Text>
              </div>
              <Text className="flex text-sm text-[#828282]">
                {selectedSuburbs?.length}/50
              </Text>
            </div>
            <div className="flex flex-col max-h-[50vh] overflow-scroll px-4">
              {suburbsList.map((suburb) => {
                const isChecked = selectedSuburbs.includes(suburb.l1_id);
                return (
                  <div
                    className="flex flex-row justify-between w-full cursor-pointer"
                    key={suburb.l1_id}
                    onClick={() => handleSuburbSelect(suburb.l1_id)}
                  >
                    <Text className="flex text-base">{suburb.l1_name}</Text>
                    <Checkbox
                      className="flex w-4 h-4"
                      checked={isChecked}
                      onChange={() => handleSuburbSelect(suburb.l1_id)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="flex flex-row gap-2 justify-between">
          <div className="flex"></div>
          <div className="flex flex-row gap-2">
            <Button
              type="default"
              className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
              onClick={() => {
                onClose();
              }}
            >
              Go Back
            </Button>
            <Button
              type="primary"
              className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
              onClick={handleAreaSave}
              disabled={selectedSuburbs.length == 0}
            >
              Add Area
            </Button>
          </div>
        </div>
      </div>

      {showExitConfirmation && (
        <NotificationAlertBox
          open={showExitConfirmation}
          onCancel={() => {
            setShowExitConfirmation(false);
          }}
          content={
            <div>
              <p className="text-md font-bold text-darkestColor text-center">
                You have unsaved changes. Would you like to save before leaving?
              </p>
              <p className="text-xs text-textColorThree text-center">
                You've made changes to your Available In Areas. Save before
                leaving the page, or any unsaved changes will be lost.
              </p>

              <div className="flex justify-center items-center mt-5 gap-4">
                <Button
                  type="default"
                  className="text-primaryDarkest border border-primaryDarkest text-sm font-semibold w-full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  No, Discard
                </Button>
                <Button
                  type="primary"
                  className="bg-primaryDarkest border border-primaryDarkest text-sm font-semibold  w-full"
                  onClick={() => {
                    setShowExitConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          }
          title="Are you sure?"
        />
      )}
    </Modal>
  );
};

export default NewServiceArea;
