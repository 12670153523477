import React, { useState } from "react";
import { Button, Col, ConfigProvider, Row, Select } from "antd";
import SearchIcon from "../../assets/svg/SearchIcon";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { NotificationContext } from "../../context/NotificationContext";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import NotificationAlertBox from "../../components/alertBox/NotificationAlertBox";

const SearchWithSelector = () => {
  const {
    searchDropDownData,
    setSerchDropDownData,
    setStepperCurrentSteps,

    isSearchDropdownOpen,
    setSearchDropdownIsOpen,

    setSelectedCategoryID,
    setSelectedCategoryDescription,

    setLocationValues,

    // setjobValues,
    setdescriptionValues,

    setJobDetailsSteps,
    setJobSummarySteps,

    setSelectedCountryType,
    searchRef,

    searchValue,
    setSearchValue,
    setSelectedJobType,

    taskerListArray,
    setTaskerListArray,

    setPageEnterdType,

    setSelectedJobDetailsData,

    setLocationSearchValue,
    setNotLoginSteps,
    setLoginJobPostSteps,
  } = useContext(CreateJobPostContext);

  const navigate = useNavigate();

  const { openNotification, handleError } = useContext(NotificationContext);

  const { getRelatedSearchedJobTypes } = CreateJobPostServices();

  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);

  const [selectedValue, setSelectedValue] = useState(undefined);

  const handleGetRelatedJobTypes = async (e) => {
    try {
      const response = await getRelatedSearchedJobTypes({
        search: e,
        job_category_id: 0,
      });
      if (response?.data?.success) {
        setTaskerListArray(response?.data?.output);
        setTaskerListData(response?.data?.output);
      } else {
        openNotification("warning", response?.data?.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const setTaskerListData = (items) => {
    var taskerListObject = [];
    items?.forEach((item) => {
      let data = {
        value: item.job_type_id,
        label: (
          <p className="flex flex-row gap-1 justify-start items-center">
            {item.job_type}
            {/* {item?.similar_job_type !== "-" && (
              <p className="text-base text-textColorZero">
                ({item?.similar_job_type})
              </p>
            )} */}
          </p>
        ),
      };
      taskerListObject.push(data);
    });
    setSerchDropDownData(taskerListObject);
  };

  return (
    <div className="flex w-full justify-center items-center overflow-hidden">
      <div className="max-w-[1073px] mx-2">
        <div className="flex flex-col items-center w-full p-4">
          <div className="flex flex-row gap-2 items-center border-primaryColor border-[2px] rounded-full p-2 px-5 h-[50px] max-w-[300px] sm:h-[60px] sm:max-w-[600px] sm:max-[600px] md:w-[730px] md:max-w-[730px] md:h-[82px]">
            <Row className="flex flex-row w-full">
              <Col span={22} className="flex justify-center items-center">
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#ffffff",
                      colorInfo: "#ffffff",
                    },
                    components: {
                      Select: {
                        lineWidth: 0,
                        boxShadow: "none",
                        boxShadow: "#ffffff",
                        fontSize: 20,
                      },
                    },
                  }}
                >
                  <Select
                    showSearch
                    allowClear={false}
                    ref={searchRef}
                    className="w-full custom-clear-icon border-none focus-within:shadow-none focus:shadow-none sm:!text-base md:!text-xl font-medium"
                    placeholder="What home service are you looking for?"
                    suffixIcon={false}
                    value={selectedValue}
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        "'",
                        '"',
                        "{",
                        "}",
                        "(",
                        ")",
                        "[",
                        "]",
                        ".",
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    style={{
                      width: "100%",
                    }}
                    filterOption={false}
                    open={
                      isSearchDropdownOpen === false || searchValue === ""
                        ? false
                        : true
                    }
                    onBlur={() => {
                      setSearchDropdownIsOpen(false);
                      setSerchDropDownData([]);
                      setSelectedCategoryDescription("");
                    }}
                    options={searchDropDownData}
                    searchValue={searchValue}
                    onSearch={(e) => {
                      setSearchValue(e);
                      handleGetRelatedJobTypes(e);
                      setSelectedCategoryID(null);
                      setSelectedCategoryDescription("");
                      setSearchDropdownIsOpen(true);
                    }}
                    onSelect={async (e) => {
                      setPageEnterdType(0);
                      setSelectedCategoryID(null);
                      setSelectedCategoryDescription("");
                      setSearchDropdownIsOpen(false);
                      setStepperCurrentSteps(0);
                      setJobDetailsSteps(1);
                      setJobSummarySteps(1);

                      setLocationValues({
                        country: undefined,
                        conuntryName: undefined,
                        state: undefined,
                        suburb: undefined,
                        postalCodetype0: undefined,
                        postalCodetype1: undefined,
                        postalCodetype2: undefined,
                        district: undefined,
                        districtName: undefined,
                        city: undefined,
                        cityName: undefined,
                      });

                      setLocationSearchValue({
                        country: null,
                        postalCodeIsRequired: 1,

                        conuntryName: null,

                        state: null,
                        stateName: null,

                        suburb: null,
                        suburbName: null,

                        postalCodetype: null,
                        postalCodeSerchValue: null,

                        language: null,
                      });
                      setNotLoginSteps(1);
                      setLoginJobPostSteps(1);

                      setdescriptionValues("");
                      setSelectedCountryType(1);
                      setSelectedJobDetailsData({
                        propertyData: null,
                        urgency: 1,
                        description: null,
                        dataSharing: 1,
                        language: 7,
                      });

                      //new login
                      if (
                        getLocalStoragedata("token") &&
                        getLocalStoragedata("userTypeId") === 6
                      ) {
                        setOpenCloseNotificationAlertBox(true);
                      } else if (
                        getLocalStoragedata("token") &&
                        getLocalStoragedata("userTypeId") === 2
                      ) {
                        setSelectedValue(e);
                        setLocalStorageData("searchSelectedJobTypeId", e);
                        // setSelectedJobType(
                        //   taskerListArray?.find(
                        //     (item) => item?.job_type_id === e
                        //   )?.job_type
                        // );

                        // setLocalStorageData(
                        //   "searchSelectedJobTypeName",
                        //   taskerListArray?.find(
                        //     (item) => item?.job_type_id === e
                        //   )?.job_type
                        // );

                        let jobTypeData = await taskerListArray?.find(
                          (item) => item?.job_type_id === e
                        );

                        setSelectedJobType(jobTypeData?.job_type);

                        setLocalStorageData(
                          "searchSelectedJobTypeName",
                          jobTypeData?.job_type
                        );
                        setLocalStorageData(
                          "searchSelectedJobTypeImage",
                          jobTypeData?.image_path
                        );

                        navigate("/create-job-post", {
                          state: { from: "searchAreadyLogin" },
                        });
                      } else {
                        setSelectedValue(e);
                        setLocalStorageData("searchSelectedJobTypeId", e);

                        // setSelectedJobType(
                        //   taskerListArray?.find(
                        //     (item) => item?.job_type_id === e
                        //   )?.job_type
                        // );
                        // setLocalStorageData(
                        //   "searchSelectedJobTypeName",
                        //   taskerListArray?.find(
                        //     (item) => item?.job_type_id === e
                        //   )?.job_type
                        // );

                        let jobTypeData = await taskerListArray?.find(
                          (item) => item?.job_type_id === e
                        );

                        setSelectedJobType(jobTypeData?.job_type);

                        setLocalStorageData(
                          "searchSelectedJobTypeName",
                          jobTypeData?.job_type
                        );
                        setLocalStorageData(
                          "searchSelectedJobTypeImage",
                          jobTypeData?.image_path
                        );

                        navigate("/create-job-post", {
                          state: { from: "searchNotLogin" },
                        });
                      }
                    }}
                  />
                </ConfigProvider>
              </Col>

              <Col span={2} className="flex justify-end items-end">
                <SearchIcon className="w-[30px] sm:w-[40px] sm:h-[40px] md:w-[51px] md:h-[51px]" />
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex justify-center items-center mt-8 md:mt-10">
          <h1 className="title-level1 text-center custom-font max-w-[700px]">
            Find Reliable Workers For Home Services,{" "}
            <span className="text-primaryColor">Fast.</span>
          </h1>
        </div>
      </div>

      {openCloseNotificationAlertBox && (
        <NotificationAlertBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
            setSelectedValue(null);
          }}
          title="Can't Post a Job"
          content={
            <div>
              <p className="text-sm font-semibold text-warringColorOne text-center">
                You are currently logged in as a 'Worker.' Posting a new job is
                not available for workers. Please log in as a 'Client' to post a
                job.
              </p>

              <div className="flex justify-center items-center mt-5">
                <Button
                  type="primary"
                  className="px-10"
                  onClick={() => {
                    setOpenCloseNotificationAlertBox(false);
                    setSelectedValue(null);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default SearchWithSelector;
