import React, { useContext, useState } from "react";
import { Button, Modal } from "antd";
import CloseIcon from "../../assets/svg/CloseIcon";
import AddRecomondationForm from "./AddRecomondationForm";
import AddEmailForm from "./AddEmailForm";
import Success from "./Success";
import { NotificationContext } from "../../context/NotificationContext";
import PublicProfileService from "../../services/PublicProfileService";
import {
  decryptSecureData,
  encryptSecureData,
} from "../../helpers/encryptHelpers/encryption";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import NotificationAlertBox from "../alertBox/NotificationAlertBox";
import QRCard from "../workerProfile/QRCard";

const AddRecomondationLogInFlow = ({ open, onCancel, data }) => {
  const [recomondationSteps, setRecomondationSteps] = useState("addForm");
  let { openNotification, handleError } = useContext(NotificationContext);

  const { addRecomondation, getRecomondationOTP } = PublicProfileService();

  const [selectedReasons, setSelectedReasons] = useState([]);
  const [addFormValues, setAddFormValues] = useState({});
  const [userEmail, setUserEmail] = useState(
    getLocalStoragedata("userData")?.email
  );
  const [otpReferance, setOtpReferance] = useState();
  const [openCloseComfirmPopup, setOpenCloseComfirmPopup] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const submitRecomondationData = async (value, step, email) => {
    try {
      await addRecomondation({
        worker_id: data?.user_id,
        full_name: encryptSecureData(value?.name || addFormValues?.name),
        email_address: encryptSecureData(email || userEmail),
        worker_know_id: value?.relationship || addFormValues?.relationship,
        recommend_reasons: selectedReasons,
        recommendation:
          value?.recommendation || addFormValues?.recommendation || null,
        rate: 5,
      })
        .then((response) => {
          if (response?.data?.success) {
            setRecomondationSteps(step);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      handleError(error);
    }
  };

  const fetchRecomondationOTP = async (email, step) => {
    try {
      await getRecomondationOTP({
        email_address: encryptSecureData(email || userEmail),
        full_name: encryptSecureData(addFormValues?.name),
      })
        .then((response) => {
          if (response?.data?.success) {
            setOtpReferance(response?.data?.output?.reference);
            setRecomondationSteps(step);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        footer={false}
        closeIcon={false}
        width={recomondationSteps !== "setOtp" ? 600 : 500}
      >
        <div className="flex flex-col justify-center items-center">
          {recomondationSteps !== "setOtp" && (
            <div className="flex flex-row justify-between items-center w-full">
              <p className="text-sm font-medium">
                {recomondationSteps === "success"
                  ? "Thanks For Recommending!"
                  : "Write a Recommendation"}
              </p>
              <div
                className="cursor-pointer"
                onClick={() => {
                  // if (recomondationSteps === "addForm") {
                  setOpenCloseComfirmPopup(true);
                  // } else {
                  //   onCancel();
                  // }
                }}
              >
                <CloseIcon color="#000000" />
              </div>
            </div>
          )}

          {getLocalStoragedata("userData")?.email === null ||
          getLocalStoragedata("userData")?.email === undefined ? (
            <>
              {recomondationSteps === "addForm" ? (
                <AddRecomondationForm
                  setRecomondationSteps={setRecomondationSteps}
                  selectedReasons={selectedReasons}
                  setSelectedReasons={setSelectedReasons}
                  setAddFormValues={setAddFormValues}
                  submitRecomondationData={submitRecomondationData}
                />
              ) : recomondationSteps === "emailForm" ? (
                <AddEmailForm
                  setRecomondationSteps={setRecomondationSteps}
                  submitRecomondationData={submitRecomondationData}
                  setUserEmail={setUserEmail}
                  addFormValues={addFormValues}
                  fetchRecomondationOTP={fetchRecomondationOTP}
                />
              ) : recomondationSteps === "success" ? (
                <Success
                  onCancel={onCancel}
                  addFormValues={addFormValues}
                  setShowQR={setShowQR}
                />
              ) : (
                <AddRecomondationForm
                  setRecomondationSteps={setRecomondationSteps}
                  selectedReasons={selectedReasons}
                  setSelectedReasons={setSelectedReasons}
                  setAddFormValues={setAddFormValues}
                  submitRecomondationData={submitRecomondationData}
                />
              )}
            </>
          ) : (
            <>
              {recomondationSteps === "addForm" ? (
                <AddRecomondationForm
                  setRecomondationSteps={setRecomondationSteps}
                  selectedReasons={selectedReasons}
                  setSelectedReasons={setSelectedReasons}
                  setAddFormValues={setAddFormValues}
                  submitRecomondationData={submitRecomondationData}
                />
              ) : recomondationSteps === "success" ? (
                <Success
                  onCancel={onCancel}
                  addFormValues={addFormValues}
                  setShowQR={setShowQR}
                />
              ) : (
                <AddRecomondationForm
                  setRecomondationSteps={setRecomondationSteps}
                  selectedReasons={selectedReasons}
                  setSelectedReasons={setSelectedReasons}
                  setAddFormValues={setAddFormValues}
                  submitRecomondationData={submitRecomondationData}
                />
              )}
            </>
          )}
        </div>
      </Modal>
      {openCloseComfirmPopup && (
        <NotificationAlertBox
          open={openCloseComfirmPopup}
          onCancel={() => {
            setOpenCloseComfirmPopup(false);
          }}
          content={
            <div className="flex flex-col items-center justify-center">
              <p className="text-base font-semibold">
                <p>Are you sure want to close?</p>
              </p>
              <p className="text-xs font-normal mt-2 text-center">
                You’re about to leave without posting your recommendation. If
                you close now, any content you've entered will be lost
              </p>

              <div className="flex flex-row justify-between items-center w-full mt-3 gap-5">
                <Button
                  size="large"
                  className="text-primaryDarkest border-primaryDarkest border-[1px] w-1/2"
                  onClick={() => {
                    setOpenCloseComfirmPopup(false);
                    onCancel();
                  }}
                >
                  Close
                </Button>

                <Button
                  type="primary"
                  className="bg-primaryDarkest w-1/2"
                  size="large"
                  onClick={() => {
                    setOpenCloseComfirmPopup(false);
                  }}
                >
                  No, Go Back
                </Button>
              </div>
            </div>
          }
          title="Close Recommendation"
          popupWidth={500}
        />
      )}

      {showQR && (
        <Modal
          open={showQR}
          footer={null}
          width={550}
          closeIcon={null}
          maskClosable={true}
          onCancel={() => {
            setShowQR(false);
            onCancel();
          }}
        >
          <QRCard
            full_name={data?.full_name}
            work_title={data?.work_title}
            rate={data?.rate}
            rate_count={data?.rate_count}
            username={data?.username}
          />
        </Modal>
      )}
    </>
  );
};

export default AddRecomondationLogInFlow;
