import React from "react";

export const ArrowRightGreen = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1699 24.2998L20.1021 16.3677L12.1699 8.43555"
        stroke="#2C8B52"
        strokeWidth="1.90371"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
