import React, { useContext, useEffect, useState } from "react";
import WorkerSignUpLayout from "./WorkerSignUpLayout";
import { Button, Image, Select } from "antd";
import MaskGroupImg from "../../assets/img/Mask group.webp";
import SignupAddServices from "../../components/workerSignup/SignupAddServices";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { EditOutlined } from "@ant-design/icons";
import { AddJobTypesDetailsSignup } from "../../components/workerSignup/AddJobTypesDetailsSignup";

const AddFirstServices = () => {
  const { openNotification, handleError } = useContext(NotificationContext);

  const {
    setStepperWorkerCurrentSteps,
    workerFormValues,
    initialServicesList,
  } = useContext(WorkerSignUpContext);
  const [openAddNewPropertyModal, setAddNewPropertyModal] = useState(false);

  const [openAddJobType, setOpenAddJobType] = useState(false);
  const [editableServiceData, setEditableServiceData] = useState({
    trady_job_type_id: null,
    job_category_id: null,
    job_type_id: null,
    uom_id: null,
    min: null,
    service_level_id: null,
    urgent_image_path: null,
    job_type: null,
    job_category: null,
  });

  return (
    <WorkerSignUpLayout>
      <div className="flex flex-col gap-4 w-full sm:max-w-[800px]">
        <div className="flex justify-between items-baseline bg-bgColourFive rounded-md p-2">
          <div>
            <p className="text-[16px]  text-darkestColor">Currency Type</p>

            <p className="text-textColorFour font-normal text-xs">
              You can choose your default currency now or update it later in
              your account settings
            </p>
          </div>

          {/* <Select
            defaultValue="USD"
            style={{
              width: 112,
            }}
            options={[
              {
                value: "USD",
                label: "USD",
              },
              {
                value: "EUR",
                label: "EUR",
              },
            ]}
          /> */}
          <div className="border border-gray-400 rounded-md px-4 py-1">
            {workerFormValues.currency}
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-baseline">
          <div>
            <p className="text-[32px] font-bold text-primaryDarkest">
              Pick your services
            </p>
            <p className="text-base font-normal">
              At least add one service in which you're an expert; you can add
              more later.
            </p>
          </div>
          <Button
            className="px-8 py-5 border-primaryDark text-primaryDark border-[1px] text-base font-medium"
            onClick={() => {
              setAddNewPropertyModal(true);
            }}
          >
            + Add New Services
          </Button>
        </div>

        {initialServicesList?.length > 0 ? (
          <>
            {initialServicesList.map((item) => {
              return item?.job_type.map((type) => (
                <div className="flex flex-col gap-2 bg-primaryLightest rounded-lg w-full p-4 min-h-24 shadow-lg">
                  <div className="flex flex-row gap-2 w-full items-center justify-between">
                    <div>
                      <p>{item?.job_category}</p>
                      <p className="text-base font-bold">{type.job_type}</p>
                    </div>
                    <button
                      onClick={() => {
                        setEditableServiceData({
                          trady_job_type_id: type?.trady_job_type_id,
                          job_category_id: item?.job_category_id,
                          job_type_id: type?.job_type_id,
                          uom_id: type?.uom_id,
                          min: type?.min,
                          service_level_id: type?.service_level_id,
                          urgent_image_path: item?.image,
                          job_type: type?.job_type,
                          job_category: item?.job_category,
                        });
                        setOpenAddJobType(true);
                      }}
                    >
                      <div className="flex flex-row gap-2 border border-primaryDarkest justify-center items-center p-1 rounded-xl cursor-pointer">
                        <EditOutlined /> Edit
                      </div>
                    </button>
                  </div>
                  <div className="flex flex-row w-full gap-2 justify-between items-center">
                    <p className="text-base">Rate</p>
                    <p className="text-base">
                      <span className="text-xs">
                        ({workerFormValues.currency})
                      </span>
                      {type.min} /{type.uom_label}
                    </p>
                  </div>
                  <div className="flex flex-row w-full gap-2 justify-between items-center">
                    <p className="text-base">Service Area</p>
                    <p className="text-base">{type.service_level}</p>
                  </div>
                </div>
              ));
            })}
          </>
        ) : (
          <>
            <div className="flex justify-center  items-center p-2">
              <div className="justify-center text-center">
                <img src={MaskGroupImg} />
              </div>
            </div>

            <div className="flex items-center justify-center bg-bgColourFive rounded-md p-2 border-dotted border-2 border-gray-400">
              <div className="text-center">
                <p className="text-[16px] text-darkestColor">
                  You don't have any added services
                </p>

                <div
                  onClick={() => {
                    setAddNewPropertyModal(true);
                  }}
                  className="cursor-pointer"
                >
                  <p className="text-primaryDarkest font-bold text-sm underline decoration-primaryDarkest cursor-pointer">
                    Add New Services+
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <div className="flex flex-col gap-2 w-full min-h-[90%]">
          {properties.length > 2 && (
            <div className="flex justify-end mt-10 bottom-0">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={properties.length}
                onChange={handlePageChange}
                showSizeChanger={false}
                responsive={true}
              />
            </div>
          )} */}

        <div className="flex justify-center items-center w-full bottom-0 left-0 my-4">
          <Button
            className="w-48 h-12 text-base font-bold"
            type="primary"
            disabled={initialServicesList?.length === 0}
            onClick={() => {
              setStepperWorkerCurrentSteps(4);
            }}
          >
            Continue
          </Button>
        </div>
        {/* </div> */}

        {openAddNewPropertyModal && (
          <SignupAddServices
            open={openAddNewPropertyModal}
            onClose={() => {
              setAddNewPropertyModal(false);
            }}
          />
        )}

        {openAddJobType && (
          <AddJobTypesDetailsSignup
            open={openAddJobType}
            onClose={() => {
              setOpenAddJobType(false);
            }}
            data={editableServiceData}
            type={1}
          />
        )}
      </div>
    </WorkerSignUpLayout>
  );
};

export default AddFirstServices;
