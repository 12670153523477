import React from "react";

const FillFB = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.8304 17.9882C76.8171 14.293 75.3434 10.7529 72.7304 8.13995C70.1175 5.52703 66.5774 4.05326 62.8822 4.04004H17.4254C13.7302 4.05326 10.1901 5.52703 7.57721 8.13995C4.96429 10.7529 3.49052 14.293 3.47729 17.9882V63.445C3.49052 67.1402 4.96429 70.6803 7.57721 73.2932C10.1901 75.9061 13.7302 77.3799 17.4254 77.3931H62.8822C66.5774 77.3799 70.1175 75.9061 72.7304 73.2932C75.3434 70.6803 76.8171 67.1402 76.8304 63.445V17.9882Z"
        fill="#0080D4"
      />
      <path
        d="M52.917 46.5628L54.372 37.1303H45.3157V31.0342C45.2651 30.336 45.3705 29.6353 45.6243 28.9829C45.8782 28.3305 46.2741 27.7428 46.7833 27.2624C47.2925 26.782 47.9022 26.421 48.5683 26.2056C49.2344 25.9902 49.94 25.9257 50.6341 26.0169H54.7483V17.8889C52.3334 17.5022 49.8935 17.2926 47.4481 17.2617C39.9221 17.2617 35.1306 21.7773 35.1306 29.9555V37.1303H26.8521V46.5628H35.1306V68.6891H45.3157V46.5628H52.917Z"
        fill="white"
      />
    </svg>
  );
};

export default FillFB;
