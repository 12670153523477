import React from "react";

export const ChatTasker = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0888 36.3205C28.632 36.3205 35.5576 29.3948 35.5576 20.8516C35.5576 12.3084 28.632 5.38281 20.0888 5.38281C11.5456 5.38281 4.61995 12.3084 4.61995 20.8516C4.61995 23.3262 5.20099 25.665 6.23406 27.7391C6.5086 28.2903 6.59998 28.9203 6.44082 29.5151L5.51948 32.9586C5.11953 34.4534 6.48706 35.8209 7.98187 35.4209L11.4253 34.4996C12.0201 34.3405 12.6501 34.4318 13.2013 34.7064C15.2754 35.7394 17.6143 36.3205 20.0888 36.3205Z"
        fill="#2C8B52"
      />
      <path
        d="M24.7294 20.8516C24.7294 21.706 25.422 22.3985 26.2763 22.3985C27.1306 22.3985 27.8232 21.706 27.8232 20.8516C27.8232 19.9973 27.1306 19.3048 26.2763 19.3048C25.422 19.3048 24.7294 19.9973 24.7294 20.8516Z"
        fill="white"
      />
      <path
        d="M18.5419 20.8516C18.5419 21.706 19.2345 22.3985 20.0888 22.3985C20.9431 22.3985 21.6357 21.706 21.6357 20.8516C21.6357 19.9973 20.9431 19.3048 20.0888 19.3048C19.2345 19.3048 18.5419 19.9973 18.5419 20.8516Z"
        fill="white"
      />
      <path
        d="M12.3544 20.8516C12.3544 21.706 13.0469 22.3985 13.9013 22.3985C14.7556 22.3985 15.4481 21.706 15.4481 20.8516C15.4481 19.9973 14.7556 19.3048 13.9013 19.3048C13.0469 19.3048 12.3544 19.9973 12.3544 20.8516Z"
        fill="white"
      />
    </svg>
  );
};
