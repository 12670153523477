import React from "react";

const MailIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 10.6732C3.33301 8.15902 3.33301 6.90194 4.11406 6.12089C4.89511 5.33984 6.15218 5.33984 8.66634 5.33984H11.333C13.8472 5.33984 15.1042 5.33984 15.8853 6.12089C16.6663 6.90194 16.6663 8.15902 16.6663 10.6732C16.6663 13.1873 16.6663 14.4444 15.8853 15.2255C15.1042 16.0065 13.8472 16.0065 11.333 16.0065H8.66634C6.15218 16.0065 4.89511 16.0065 4.11406 15.2255C3.33301 14.4444 3.33301 13.1873 3.33301 10.6732Z"
        stroke="#2C8B52"
        strokeWidth="0.9"
      />
      <path
        d="M6 8.00195L7.43926 9.20134C8.66369 10.2217 9.2759 10.7319 10 10.7319C10.7241 10.7319 11.3363 10.2217 12.5607 9.20134L14 8.00195"
        stroke="#2C8B52"
        strokeWidth="0.9"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MailIcon;
