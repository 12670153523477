import React from "react";

export const JobPostSuccess = () => {
  return (
    <svg
      width="127"
      height="110"
      viewBox="0 0 127 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="62.7776" cy="63.8464" r="33.8034" fill="#2C8B52" />
      <path
        d="M57.7829 76.1025L46.1636 64.4832L49.0684 61.5784L57.7829 70.2928L76.4858 51.5898L79.3907 54.4947L57.7829 76.1025Z"
        fill="white"
      />
      <path
        d="M9.64889 33.4395C10.7495 37.9117 14.2943 41.371 18.7922 42.362L19.1161 42.4333L18.7922 42.5047C14.2943 43.4957 10.7495 46.9549 9.64889 51.4272C8.54826 46.9549 5.00344 43.4957 0.505609 42.5047L0.181641 42.4333L0.505601 42.362C5.00344 41.371 8.54826 37.9117 9.64889 33.4395Z"
        fill="#2C8B52"
      />
      <path
        d="M28.9724 97.6504C29.7273 100.718 32.1586 103.09 35.2435 103.77L35.4657 103.819L35.2435 103.868C32.1586 104.548 29.7273 106.92 28.9724 109.988C28.2175 106.92 25.7862 104.548 22.7012 103.868L22.479 103.819L22.7012 103.77C25.7862 103.09 28.2175 100.718 28.9724 97.6504Z"
        fill="#2C8B52"
      />
      <path
        d="M70.8609 0.285156C72.1571 5.55233 76.332 9.62638 81.6293 10.7935L82.0108 10.8776L81.6293 10.9617C76.332 12.1288 72.1571 16.2029 70.8609 21.47C69.5646 16.2029 65.3898 12.1288 60.0925 10.9617L59.7109 10.8776L60.0925 10.7935C65.3898 9.62638 69.5646 5.55233 70.8609 0.285156Z"
        fill="#2C8B52"
      />
      <path
        d="M118.229 51.4287C119.152 55.1821 122.127 58.0853 125.902 58.917L126.174 58.9769L125.902 59.0368C122.127 59.8685 119.152 62.7717 118.229 66.5251C117.305 62.7717 114.33 59.8685 110.555 59.0368L110.283 58.9769L110.555 58.917C114.33 58.0853 117.305 55.1821 118.229 51.4287Z"
        fill="#2C8B52"
      />
      <path
        d="M96.578 91.6455C97.3128 94.6312 99.6793 96.9405 102.682 97.6021L102.898 97.6497L102.682 97.6974C99.6793 98.359 97.3128 100.668 96.578 103.654C95.8433 100.668 93.4768 98.359 90.4741 97.6974L90.2578 97.6497L90.4741 97.6021C93.4768 96.9405 95.8433 94.6312 96.578 91.6455Z"
        fill="#2C8B52"
      />
    </svg>
  );
};
