import React from "react";

const EmptyGoogleBusiness = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83386 2.68392C8.721 2.90407 7.68642 3.34191 6.77632 3.95121L7.24641 4.65338C6.41316 5.21122 5.69559 5.9288 5.13775 6.76204L4.43558 6.29195C3.82628 7.20205 3.38845 8.23664 3.16829 9.3495L3.99723 9.51349C3.90193 9.99519 3.85184 10.4938 3.85184 11.005V12.5317H3.00684V15.5852H3.85184V18.6387H3.00684V21.6922H3.85184V24.7457H3.00684V27.7992H3.85184V30.8527H3.00684V33.9062H3.85184V36.9597H3.00684V40.0132H3.85184V41.54C3.85184 42.0511 3.90193 42.5497 3.99723 43.0314L3.16829 43.1954C3.38845 44.3083 3.82628 45.3429 4.43558 46.253L5.13775 45.7829C5.69559 46.6161 6.41316 47.3337 7.24641 47.8915L6.77632 48.5937C7.68642 49.203 8.721 49.6409 9.83386 49.861L9.99785 49.0321C10.4796 49.1274 10.9782 49.1775 11.4893 49.1775H13.0161V50.0225H16.0696V49.1775H19.1231V50.0225H22.1766V49.1775H25.2301V50.0225H28.2836V49.1775H31.3371V50.0225H34.3906V49.1775H37.4441V50.0225H40.4976V49.1775H42.0243C42.5355 49.1775 43.0341 49.1274 43.5158 49.0321L43.6798 49.861C44.7927 49.6409 45.8272 49.203 46.7373 48.5937L46.2673 47.8915C47.1005 47.3337 47.8181 46.6161 48.3759 45.7829L49.0781 46.253C49.6874 45.3429 50.1252 44.3083 50.3454 43.1954L49.5164 43.0314C49.6117 42.5497 49.6618 42.0511 49.6618 41.54V40.0132H50.5068V36.9597H49.6618V33.9062H50.5068V30.8527H49.6618V27.7992H50.5068V24.7457H49.6618V21.6922H50.5068V18.6387H49.6618V15.5852H50.5068V12.5317H49.6618V11.005C49.6618 10.4938 49.6117 9.99519 49.5164 9.51349L50.3454 9.3495C50.1252 8.23664 49.6874 7.20205 49.0781 6.29195L48.3759 6.76204C47.8181 5.9288 47.1005 5.21122 46.2673 4.65338L46.7373 3.95121C45.8272 3.34191 44.7927 2.90407 43.6798 2.68392L43.5158 3.51285C43.0341 3.41756 42.5355 3.36746 42.0243 3.36746H40.4976V2.52246H37.4441V3.36746H34.3906V2.52246H31.3371V3.36746H28.2836V2.52246H25.2301V3.36746H22.1766V2.52246H19.1231V3.36746H16.0696V2.52246H13.0161V3.36746H11.4893C10.9782 3.36746 10.4796 3.41756 9.99785 3.51285L9.83386 2.68392Z"
        fill="#CDCDCD"
        stroke="#979797"
        strokeWidth="1.69"
        strokeDasharray="3.38 3.38"
      />
      <path
        d="M36.4429 20.8701H16.4621C15.9874 20.8701 15.6025 21.255 15.6025 21.7297V36.2363C15.6025 36.711 15.9874 37.0959 16.4621 37.0959H36.4429C36.9176 37.0959 37.3025 36.711 37.3025 36.2363V21.7297C37.3025 21.255 36.9176 20.8701 36.4429 20.8701Z"
        fill="#AAAAAA"
      />
      <path
        d="M36.1908 20.748H16.6876C16.0834 20.748 15.6133 25.4236 15.6133 26.0278L26.2938 37.0957H36.1908C36.7967 37.0941 37.2875 36.6031 37.2892 35.9972V21.8463C37.2874 21.2404 36.7966 20.7497 36.1908 20.748Z"
        fill="#979797"
      />
      <path
        d="M20.5566 23.5866H26.7566V15.4492H21.485L20.5566 23.5866Z"
        fill="#BBBBBB"
      />
      <path
        d="M32.9558 23.5866H26.7559V15.4492H32.0275L32.9558 23.5866ZM37.301 16.9918L37.3087 17.0195C37.3068 17.0097 37.3031 17.0006 37.301 16.9918Z"
        fill="#979797"
      />
      <path
        d="M37.3124 17.0195L37.3048 16.9918C37.1082 16.0917 36.3115 15.4498 35.3902 15.4492H32.0312L32.9589 23.5868H39.1589L37.3124 17.0195Z"
        fill="#BBBBBB"
      />
      <path
        d="M16.2031 17.0195L16.2108 16.9918C16.4074 16.0917 17.204 15.4498 18.1253 15.4492H21.4845L20.5555 23.5868H14.3555L16.2031 17.0195Z"
        fill="#979797"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5564 23.584C20.5564 25.2932 19.1685 26.679 17.4564 26.679C15.7444 26.679 14.3564 25.2932 14.3564 23.584H20.5564Z"
        fill="#CDCDCD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7547 23.584C26.7547 25.2932 25.3668 26.679 23.6547 26.679C21.9426 26.679 20.5547 25.2932 20.5547 23.584H26.7547Z"
        fill="#A5A5A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9568 23.584C32.9568 25.2932 31.5689 26.679 29.8568 26.679C28.1448 26.679 26.7568 25.2932 26.7568 23.584H32.9568Z"
        fill="#CDCDCD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.156 23.584C39.156 25.2932 37.7681 26.679 36.056 26.679C34.344 26.679 32.9561 25.2932 32.9561 23.584H39.156Z"
        fill="#A5A5A5"
      />
      <path
        d="M36.06 31.9783C36.049 31.8308 36.0307 31.6961 36.0049 31.524H32.6253C32.6253 31.9967 32.6253 32.5126 32.6234 32.9855H34.5813C34.5397 33.2059 34.4549 33.4159 34.3319 33.6035C34.2089 33.791 34.0501 33.9524 33.8645 34.0784C33.8645 34.0784 33.8645 34.0703 33.8631 34.07C33.6236 34.2273 33.3535 34.3327 33.0706 34.3793C32.7871 34.4312 32.4963 34.43 32.2132 34.3756C31.9257 34.3161 31.6535 34.1981 31.4135 34.029C31.0592 33.7778 30.786 33.4287 30.6272 33.0245C30.6151 32.993 30.6039 32.9613 30.5926 32.9291V32.9256L30.5955 32.9233C30.4416 32.4712 30.4412 31.9809 30.5944 31.5285C30.7024 31.2112 30.88 30.9221 31.1144 30.6824C31.6642 30.1138 32.4856 29.8999 33.2428 30.1281C33.5333 30.2173 33.7986 30.3734 34.0174 30.584L34.678 29.9236C34.7945 29.8059 34.9156 29.6918 35.0276 29.5702C34.6927 29.2578 34.2996 29.0143 33.8706 28.8537C33.09 28.5705 32.236 28.5631 31.4506 28.8325C31.4231 28.8418 31.3959 28.8515 31.369 28.8615C30.5215 29.1801 29.8244 29.8049 29.4153 30.6124C29.2708 30.8983 29.1655 31.2024 29.1025 31.5164C28.7283 33.3757 29.8628 35.2059 31.6945 35.6979C32.2933 35.8582 32.9299 35.8543 33.5333 35.7173C34.0816 35.5935 34.5886 35.3299 35.005 34.9525C35.4395 34.5527 35.7508 34.0202 35.914 33.4544C36.0505 32.9751 36.0999 32.4752 36.06 31.9783Z"
        fill="#CDCDCD"
      />
    </svg>
  );
};

export default EmptyGoogleBusiness;
