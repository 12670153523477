import React from "react";

const EmailGreenOutline = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2998 10.1693C4.2998 7.65511 4.2998 6.39803 5.08085 5.61699C5.8619 4.83594 7.11898 4.83594 9.63314 4.83594H12.2998C14.814 4.83594 16.071 4.83594 16.8521 5.61699C17.6331 6.39803 17.6331 7.65511 17.6331 10.1693C17.6331 12.6834 17.6331 13.9405 16.8521 14.7216C16.071 15.5026 14.814 15.5026 12.2998 15.5026H9.63314C7.11898 15.5026 5.8619 15.5026 5.08085 14.7216C4.2998 13.9405 4.2998 12.6834 4.2998 10.1693Z"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
      <path
        d="M6.9668 7.50195L8.40606 8.70134C9.63048 9.72169 10.2427 10.2319 10.9668 10.2319C11.6909 10.2319 12.3031 9.72169 13.5275 8.70134L14.9668 7.50195"
        stroke="#2C8B52"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default EmailGreenOutline;
