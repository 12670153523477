import React, { useContext, useState } from "react";
import { Divider, Popover, Tooltip, Typography } from "antd";
import UserColorProfile from "../userColorProfile/UserColorProfile";
import { Link, useNavigate } from "react-router-dom";
import NavBarUserIcon from "../../assets/svg/NavBarUserIcon";
import NavbarLogout from "../../assets/svg/NavbarLogout";
import { MdArrowForwardIos } from "react-icons/md";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import HeaderConfig from "../../helpers/HeaderConfig";
import LoginServices from "../../services/LoginServices";
import MyAccountIcon from "../../assets/svg/MyAccountIcon";
import SettingIcon from "../../assets/svg/SettingIcon";
import PersonGrayIcon from "../../assets/svg/PersonGrayIcon";
const { Text } = Typography;

const NavbarProfile = ({ open }) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const { configAuth } = HeaderConfig();
  const { userLogOut } = LoginServices();
  const [loggingOut, setLoggingOut] = useState(false);

  const navigate = useNavigate();

  const logOut = () => {
    setLoggingOut(true);
    userLogOut({ token: getLocalStoragedata("token") }, configAuth)
      .then((response) => {
        if (response.data.success) {
          navigate("/");
          openNotification("success", response.data.message);
          localStorage.clear();
        } else {
          openNotification("error", response.data.message);
        }
        setLoggingOut(false);
      })
      .catch((error) => {
        setLoggingOut(false);
        handleError(error);
      });
  };

  return (
    <div className="flex flex-row gap-4">
      <Popover
        content={
          <div className="w-[200px] h-fit flex flex-col items-center justify-start">
            <Link
              className="w-full"
              to={
                getLocalStoragedata("userTypeId") === 6
                  ? "/my-account-worker"
                  : "/my-account-client"
              }
            >
              <div className="w-full h-12 flex justify-center items-start">
                <div className="w-[20%] h-full flex-row justify-start items-center">
                  <PersonGrayIcon />
                </div>
                <div className="w-[80%] h-full flex flex-col justify-start">
                  <Tooltip
                    className="text-sm font-semibold leading-tight"
                    title={decryptSecureData(getLocalStoragedata("userName"))}
                  >
                    {decryptSecureData(getLocalStoragedata("userName"))
                      ?.length > 13
                      ? decryptSecureData(
                          getLocalStoragedata("userName")
                        )?.substring(0, 13) + "..."
                      : decryptSecureData(getLocalStoragedata("userName"))}
                  </Tooltip>

                  <p className="border-[1px] border-primaryDark mt-2 max-w-fit px-2 rounded-full text-center text-primaryDark font-bold text-xs">
                    {getLocalStoragedata("userTypeId") === 6
                      ? "Worker"
                      : "Client"}
                  </p>
                </div>
              </div>
            </Link>
            <Divider className="my-2 bg-textColorZero" />

            <Link
              className="w-full"
              to={
                getLocalStoragedata("userTypeId") === 6
                  ? "/my-account-worker"
                  : "/my-account-client"
              }
            >
              <div className="w-full h-7 flex justify-center items-start">
                <div className="w-[20%] h-full justify-start items-center">
                  <MyAccountIcon />
                </div>
                <div className="w-[80%] h-full flex flex-row items-center justify-between">
                  <Text className="text-sm font-semibold">My Account</Text>
                  <MdArrowForwardIos />
                </div>
              </div>
            </Link>

            <Divider className="my-2 bg-textColorZero" />

            {getLocalStoragedata("userTypeId") === 6 && (
              <>
                <Link className="w-full" to="/public-profile">
                  <div className="w-full h-7 flex justify-center items-start">
                    <div className="w-[20%] h-full justify-start items-center">
                      <NavBarUserIcon />
                    </div>
                    <div className="w-[80%] h-full flex flex-row items-center justify-between">
                      <Text className="text-sm font-semibold">
                        My Public Profile
                      </Text>
                      <MdArrowForwardIos />
                    </div>
                  </div>
                </Link>

                <Divider className="my-2 bg-textColorZero" />
              </>
            )}

            {getLocalStoragedata("userTypeId") === 2 && (
              <>
                <Link className="w-full" to="/my-properties">
                  <div className="w-full h-7 flex justify-center items-start">
                    <div className="w-[20%] h-full justify-start items-center">
                      <SettingIcon />
                    </div>
                    <div className="w-[80%] h-full flex flex-row items-center justify-between">
                      <Text className="text-sm font-semibold">
                        My Properties
                      </Text>
                      <MdArrowForwardIos />
                    </div>
                  </div>
                </Link>

                <Divider className="my-2 bg-textColorZero" />
              </>
            )}

            <div
              className="w-full h-7 flex justify-center items-start cursor-pointer"
              onClick={() => {
                if (!loggingOut) {
                  logOut();
                }
              }}
            >
              <div className="w-[20%] h-full justify-start items-center">
                <NavbarLogout />
              </div>
              <div className="w-[80%] h-full flex flex-row items-center justify-between">
                <Text className="text-sm font-semibold">Log out</Text>
              </div>
            </div>
          </div>
        }
        placement="bottomRight"
        arrow={false}
        trigger="click"
        open={open}
      >
        <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
          {getLocalStoragedata("userData")?.profile_picture === null ? (
            <UserColorProfile
              name={decryptSecureData(getLocalStoragedata("userName")) || "N Z"}
              color={"#3bb96e"}
              size="40px"
              textSize="16px"
            />
          ) : (
            <img
              src={getLocalStoragedata("userData")?.profile_picture}
              alt=""
              className="flex w-[32px] h-[32px] rounded-full border-[2px]"
            />
          )}
        </div>
      </Popover>
    </div>
  );
};

export default NavbarProfile;
