import { createContext, useRef, useState } from "react";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";

export const CreateJobPostContext = createContext({});

export function CreateJobPostContextProvider({ children }) {
  const [stepperCurrentSteps, setStepperCurrentSteps] = useState(0);
  const [notLoginSteps, setNotLoginSteps] = useState(1);
  const [loginJobPostSteps, setLoginJobPostSteps] = useState(1);

  const [completedStep, setCompletedStep] = useState(0);
  const [jobDetailSteps, setJobDetailsSteps] = useState(1);
  const [jobSummarySteps, setJobSummarySteps] = useState(1);

  const [searchValue, setSearchValue] = useState("");
  const [searchDropDownData, setSerchDropDownData] = useState([]);
  const [isSearchDropdownOpen, setSearchDropdownIsOpen] = useState(true);
  const [selectedCategoryID, setSelectedCategoryID] = useState(null);

  //to get pure array
  const [taskerListArray, setTaskerListArray] = useState();

  //page entered type
  const [pageEnterdType, setPageEnterdType] = useState(0);

  //job post posted id
  const [postedJobPostId, setPostedJobPostId] = useState(undefined);

  const [selectedCategoryDescription, setSelectedCategoryDescription] =
    useState("");

  const [locationValues, setLocationValues] = useState({
    country: undefined,
    conuntryName: undefined,

    state: undefined,
    suburb: undefined,

    postalCodetype0: undefined,
    postalCodetype1: undefined,
    postalCodetype2: undefined,

    district: undefined,
    districtName: undefined,
    city: undefined,
    cityName: undefined,
  });

  const [jobValues, setjobValues] = useState({
    jobOne: true,
    jobTwo: false,
    jobThree: false,
  });
  const [descriptionValues, setdescriptionValues] = useState("");

  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [location1SelectorData, setLocation1SelectorData] = useState([]);
  const [location2SelectorData, setLocation2SelectorData] = useState([]);

  const [selectedJobType, setSelectedJobType] = useState(
    getLocalStoragedata("searchSelectedJobTypeName")
  );

  //for tasker list
  const [taskerList, setTaskerList] = useState([]);
  const [taskerListCount, setTaskerListCount] = useState(0);

  const [allCountryValues, setAllCountryValues] = useState();
  const [selectedCountryType, setSelectedCountryType] = useState(1);

  const searchRef = useRef(null);

  const [listLoading, setListLoading] = useState(true);
  const [jobPostLoading, setJobPostLoading] = useState(false);

  // new create job post
  const [selectedJobDetailsData, setSelectedJobDetailsData] = useState({
    propertyData: null,
    urgency: 1,
    description: null,
    dataSharing: 1, //0->false,1->true
    language: 7,
  });

  const [jobPostList, setJobPostList] = useState({
    jobCount: 0,
    jobList: [],
  });

  const [locationSearchValue, setLocationSearchValue] = useState({
    country: null,
    postalCodeIsRequired: 1,

    conuntryName: null,

    state: null,
    stateName: null,

    suburb: null,
    suburbName: null,

    postalCodetype: null,
    postalCodeSerchValue: null,

    language: null,
  });

  const [selectedTaskerArrayForJobPost, setSelectedTaskerArrayForJobPost] =
    useState([]);

  return (
    <CreateJobPostContext.Provider
      value={{
        searchValue,
        setSearchValue,
        searchDropDownData,
        setSerchDropDownData,
        isSearchDropdownOpen,
        setSearchDropdownIsOpen,
        selectedCategoryID,
        setSelectedCategoryID,

        selectedCategoryDescription,
        setSelectedCategoryDescription,

        stepperCurrentSteps,
        setStepperCurrentSteps,
        completedStep,
        setCompletedStep,
        jobDetailSteps,
        setJobDetailsSteps,
        jobSummarySteps,
        setJobSummarySteps,

        listLoading,
        setListLoading,

        jobPostLoading,
        setJobPostLoading,

        locationValues,
        setLocationValues,

        jobValues,
        setjobValues,
        descriptionValues,
        setdescriptionValues,

        countrySelectorData,
        setCountrySelectorData,
        location1SelectorData,
        setLocation1SelectorData,
        location2SelectorData,
        setLocation2SelectorData,

        selectedJobType,
        setSelectedJobType,

        taskerList,
        setTaskerList,
        taskerListCount,
        setTaskerListCount,

        taskerListArray,
        setTaskerListArray,

        allCountryValues,
        setAllCountryValues,
        selectedCountryType,
        setSelectedCountryType,
        searchRef,

        pageEnterdType,
        setPageEnterdType,

        postedJobPostId,
        setPostedJobPostId,

        ///// new
        selectedJobDetailsData,
        setSelectedJobDetailsData,
        jobPostList,
        setJobPostList,

        notLoginSteps,
        setNotLoginSteps,

        locationSearchValue,
        setLocationSearchValue,

        selectedTaskerArrayForJobPost,
        setSelectedTaskerArrayForJobPost,

        loginJobPostSteps,
        setLoginJobPostSteps,
      }}
    >
      {children}
    </CreateJobPostContext.Provider>
  );
}
