import React from "react";

const PersonGreenOutlineIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.99967"
        cy="6.36784"
        r="2.66667"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
      <path
        d="M15.3337 14.0332C15.3337 15.6901 15.3337 17.0332 10.0003 17.0332C4.66699 17.0332 4.66699 15.6901 4.66699 14.0332C4.66699 12.3763 7.05481 11.0332 10.0003 11.0332C12.9458 11.0332 15.3337 12.3763 15.3337 14.0332Z"
        stroke="#2C8B52"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default PersonGreenOutlineIcon;
