import React, { useContext, useEffect } from "react";
import PersonalInfo from "./quick-signup/PersonalInfo";
import NavBar from "../components/navbar/NavBar";
import { QuickSignupContext } from "../context/QuickSignUpContext";
import SetOTP from "./quick-signup/SetOTP";
import SetNewPin from "./quick-signup/SetNewPin";
import AddFirstProperty from "./quick-signup/AddFirstProperty";
import { useLocation } from "react-router-dom";

const QuickSignUp = () => {
  const location = useLocation();
  const { state } = location;
  //state.type==6;worker----2;client
  //state.from==search,directSignin

  const { stepperClientCurrentSteps, setStepperClientCurrentSteps } =
    useContext(QuickSignupContext);

  useEffect(() => {
    setStepperClientCurrentSteps(0);
  }, []);

  return (
    <div>
      <NavBar />
      <div className="content flex justify-center items-baseline">
        {stepperClientCurrentSteps === 0 ? (
          <PersonalInfo />
        ) : stepperClientCurrentSteps === 1 ? (
          <SetOTP />
        ) : stepperClientCurrentSteps === 2 ? (
          <SetNewPin />
        ) : stepperClientCurrentSteps === 3 ? (
          <AddFirstProperty stateValue={state} />
        ) : (
          <PersonalInfo />
        )}
      </div>
    </div>
  );
};

export default QuickSignUp;
