import { CloseOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Typography } from "antd";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import React, { useContext, useRef, useState } from "react";
import { NotificationContext } from "../../context/NotificationContext";
import DefaultCover from "../../assets/img/defaultcover.webp";
import DefaultProfile from "../../assets/img/default_profile.png";
import PublicProfileService from "../../services/PublicProfileService";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import LoginServices from "../../services/LoginServices";
import HeaderConfig from "../../helpers/HeaderConfig";
const { Title, Text } = Typography;

const ImageCropper = ({
  modelOpen,
  handleCancel,
  title,
  currentImage,
  rWidth,
  rHeight,
  type,
  getWorkerProfileData,
}) => {
  const { fileUpload, updateProfile, updateCover } = PublicProfileService();
  const { getAllAuthUserData } = LoginServices();
  const { configAuth } = HeaderConfig();
  const { openNotification, handleError } = useContext(NotificationContext);
  const imageRef = useRef(null); //to get selected image
  const [cropper, setCropper] = useState(null); //cropper

  const [uploadButtonState, setUploadButtonState] = useState(true); //to handle upload button
  const [uploadButtonLoading, setUploadButtonLoading] = useState(false); //uploading progress

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 10 * 1024 * 1024) {
      openNotification("error", "File size exceeds the limit of 10 MB");
      return; // Stop further execution
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      openNotification("error", "Please upload a JPEG/PNG image file.");
      return;
    }

    if (uploadButtonState) {
      setUploadButtonState(false);
    }
    // Get the selected file
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = imageRef.current;
      image.src = e.target.result;

      if (cropper) {
        cropper.destroy();
      }
      const newCropper = new Cropper(image, {
        aspectRatio: rWidth / rHeight,
      });
      setCropper(newCropper);
    };
    // // Read the selected file as a data URL
    reader.readAsDataURL(file);
  };

  //   Crop method
  const handleCrop = () => {
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      if (!croppedCanvas) {
        openNotification("error", "Unable to crop the image. Try again.");
        return;
      }

      // Convert the cropped canvas to a Base64-encoded string
      const base64Image = croppedCanvas.toDataURL("image/jpeg");

      handleUpload(base64Image);
    }
  };

  const handleUpload = async (image) => {
    setUploadButtonLoading(true);

    const data = {
      userId: getLocalStoragedata("userId"),
      usecaseId: type === "profile" ? 2 : 3,
      file: image.split(",")[1],
    };

    try {
      await fileUpload(data)
        .then((response) => {
          if (response?.data?.success) {
            updateLink(response?.data.output.url);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
          setUploadButtonLoading(false);
          setUploadButtonState(false);
        });
    } catch (error) {
      openNotification("error", error);
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    }
  };

  const updateLink = async (url) => {
    const data = {
      user_id: getLocalStoragedata("userId"),
      file_extension: url,
    };

    if (type === "profile") {
      updateProfileURL(data);
    } else {
      updateCoverURL(data);
    }
  };

  const updateProfileURL = async (data) => {
    try {
      await updateProfile(data)
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            handleCancel();
            getWorkerProfileData();
            getAuthUserData();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    } catch (error) {
      openNotification("error", error);
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    }
  };

  const getAuthUserData = async () => {
    try {
      await getAllAuthUserData(configAuth)
        .then((response) => {
          if (response?.data?.success) {
            setLocalStorageData("userData", response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const updateCoverURL = async (data) => {
    try {
      await updateCover(data)
        .then((response) => {
          if (response?.data?.success) {
            openNotification("success", response?.data?.message);
            handleCancel();
            getWorkerProfileData();
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    } catch (error) {
      openNotification("error", error);
      setUploadButtonLoading(false);
      setUploadButtonState(false);
    }
  };

  return (
    <Modal
      open={modelOpen}
      onCancel={handleCancel}
      closeIcon={false}
      className="flex flex-col rounded-md"
      width={750}
      footer={null}
      destroyOnClose={true}
    >
      <div className="flex flex-row justify-between pt-1">
        <Title className="flex !text-lg font-semibold">{title}</Title>
        <button onClick={handleCancel}>
          <CloseOutlined />
        </button>
      </div>
      <Divider className="flex my-2" />
      <div className="flex justify-center">
        <div className="flex flex-row gap-3 p-4 w-full justify-center">
          <div className="flex items-center justify-center border-[2px] min-h-[120px] h-[300px] w-[80%]">
            {currentImage ? (
              <img
                src={currentImage}
                className="max-w-full max-h-full"
                ref={imageRef}
                alt="Preview"
              />
            ) : (
              <img
                src={type === "profile" ? DefaultProfile : DefaultCover}
                className="max-w-full max-h-full"
                ref={imageRef}
                alt="Preview"
              />
            )}
          </div>
          <div className="flex flex-col gap-2 items-center justify-center w-[30%]">
            {/* Input element to select a file */}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden" // Hide the default input style
              id="file-input" // Add an id for styling purposes
              disabled={uploadButtonLoading}
            />
            {/* Button to trigger file input click */}
            <label
              htmlFor="file-input"
              className="cursor-pointer w-20 text-center bg-primaryBgColor  rounded-lg border-[2px]"
            >
              Browse
            </label>
            <Button
              disabled={uploadButtonState}
              type="primary"
              className=""
              onClick={handleCrop}
              loading={uploadButtonLoading}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropper;
