import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import { useNavigate } from "react-router-dom";
import NotificationAlertBox from "../../components/alertBox/NotificationAlertBox";

const PopularServices = ({ jobs }) => {
  const navigate = useNavigate();
  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);

  const {
    setStepperCurrentSteps,
    setLocationValues,
    setdescriptionValues,
    setSelectedCountryType,
    setSelectedJobType,
    setPageEnterdType,
    setSelectedJobDetailsData,
    setLocationSearchValue,
    setNotLoginSteps,
    setLoginJobPostSteps,
  } = useContext(CreateJobPostContext);

  useEffect(() => {
    setSelectedJobDetailsData({
      propertyData: null,
      urgency: 1,
      description: null,
      dataSharing: 1,
      language: 7,
    });
  }, []);

  const handleCreateJobPost = (item) => {
    setStepperCurrentSteps(0);
    setPageEnterdType(0);
    setLocationValues({
      country: undefined,
      conuntryName: undefined,
      state: undefined,
      suburb: undefined,
      postalCodetype0: undefined,
      postalCodetype1: undefined,
      postalCodetype2: undefined,
      district: undefined,
      districtName: undefined,
      city: undefined,
      cityName: undefined,
    });
    setLocationSearchValue({
      country: null,
      postalCodeIsRequired: 1,

      conuntryName: null,

      state: null,
      stateName: null,

      suburb: null,
      suburbName: null,

      postalCodetype: null,
      postalCodeSerchValue: null,

      language: null,
    });
    setNotLoginSteps(1);
    setLoginJobPostSteps(1);
    setdescriptionValues("");
    setSelectedCountryType(1);

    if (
      getLocalStoragedata("token") &&
      getLocalStoragedata("userTypeId") === 6
    ) {
      setOpenCloseNotificationAlertBox(true);
    } else if (
      getLocalStoragedata("token") &&
      getLocalStoragedata("userTypeId") === 2
    ) {
      setSelectedJobType(item?.job_type);
      setLocalStorageData("searchSelectedJobTypeId", item?.id);
      setLocalStorageData("searchSelectedJobTypeName", item?.job_type);
      setLocalStorageData("searchSelectedJobTypeImage", item?.image_path);
      navigate("/create-job-post", {
        state: { from: "searchAreadyLogin" },
      });
    } else {
      setSelectedJobType(item?.job_type);
      setLocalStorageData("searchSelectedJobTypeId", item?.id);
      setLocalStorageData("searchSelectedJobTypeName", item.job_type);
      setLocalStorageData("searchSelectedJobTypeImage", item?.image_path);
      navigate("/create-job-post", {
        state: { from: "searchNotLogin" },
      });
    }
  };
  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] overflow-hidden">
        <h2 className="custom-font font-medium text-4xl md:text-[48px] text-center">
          Hire workers for your needs
        </h2>
        <Row gutter={16} className="mt-8 md:mt-10">
          {jobs.map((job, index) => (
            <Col span={24} sm={{ span: 12 }} md={{ span: 8 }} key={index}>
              <div className="shadow-lg mb-5 rounded-lg p-5 cursor-pointer hover:shadow-2xl">
                <img
                  src={job?.web_image_path}
                  alt={job.job_category}
                  height={100}
                />

                <div className="h-[300px] overflow-y-scroll thin-scrollbar">
                  <div className="sticky top-0 bg-white pt-3">
                    <p className="text-base lg:text-xl xl:text-[24px] font-semibold text-start mt-2">
                      {job.job_category}
                    </p>

                    <p>{job?.description}</p>
                    <div className="border-b-[2px] border-primaryColor w-40 mt-5"></div>
                  </div>

                  <div className="mt-2">
                    {job?.job_types?.map((item, index) => {
                      return (
                        <p
                          className="text-base font-normal hover:text-primaryColor cursor-pointer"
                          key={index}
                          onClick={() => {
                            handleCreateJobPost(item);
                          }}
                        >
                          {item?.job_type}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {openCloseNotificationAlertBox && (
        <NotificationAlertBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
          }}
          title="Can't Post a Job"
          content={
            <div>
              <p className="text-sm font-semibold text-warringColorOne text-center">
                You are currently logged in as a 'Worker.' Posting a new job is
                not available for workers. Please log in as a 'Client' to post a
                job.
              </p>

              <div className="flex justify-center items-center mt-5">
                <Button
                  type="primary"
                  className="px-10"
                  onClick={() => {
                    setOpenCloseNotificationAlertBox(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default PopularServices;
