import React from "react";

const Icon3 = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1384 23.6052H11.6094C7.37695 23.6052 5.26074 22.5471 5.26074 17.2566V11.9661C5.26074 7.73364 7.37695 5.61743 11.6094 5.61743H20.0742C24.3066 5.61743 26.4228 7.73364 26.4228 11.9661V17.2566C26.4228 21.489 24.3066 23.6052 20.0742 23.6052H19.5451C19.2171 23.6052 18.8997 23.7639 18.6987 24.0284L17.1115 26.1446C16.4132 27.0758 15.2704 27.0758 14.5721 26.1446L12.9849 24.0284C12.8156 23.7957 12.4241 23.6052 12.1384 23.6052Z"
        stroke="#2C8B52"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0867 15.1404H21.0962"
        stroke="#2C8B52"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8367 15.1404H15.8462"
        stroke="#2C8B52"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5879 15.1404H10.5976"
        stroke="#2C8B52"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Icon3;
