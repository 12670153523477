import React, { useContext, useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import PersonalInfo from "./workerSignUp/PersonalInfo";
import { WorkerSignUpContext } from "../context/WorkerSignUpContext";
import SetOTP from "./workerSignUp/SetOTP";
import WorkerSetNewPIN from "./workerSignUp/WorkerSetNewPIN";
import AddFirstServices from "./workerSignUp/AddFirstServices";
import AddServiceAreaSignUp from "./workerSignUp/AddServiceAreaSignUp";

const WorkerSignUp = () => {
  const { stepperWorkerCurrentSteps, setStepperWorkerCurrentSteps } =
    useContext(WorkerSignUpContext);

  useEffect(() => {
    setStepperWorkerCurrentSteps(0);
  }, []);

  return (
    <div>
      <NavBar />
      <div className="content flex justify-center items-baseline">
        {stepperWorkerCurrentSteps === 0 ? (
          <PersonalInfo />
        ) : stepperWorkerCurrentSteps === 1 ? (
          <SetOTP />
        ) : stepperWorkerCurrentSteps === 2 ? (
          <WorkerSetNewPIN />
        ) : stepperWorkerCurrentSteps === 3 ? (
          <AddFirstServices />
        ) : stepperWorkerCurrentSteps === 4 ? (
          <AddServiceAreaSignUp />
        ) : (
          <PersonalInfo />
        )}
      </div>
    </div>
  );
};

export default WorkerSignUp;
