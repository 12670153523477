import React from "react";

const Enlarge = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96783 15.0259C5.96783 10.3118 5.96783 7.95481 7.4323 6.49035C8.89677 5.02588 11.2538 5.02588 15.9678 5.02588C20.6819 5.02588 23.0389 5.02588 24.5034 6.49035C25.9678 7.95481 25.9678 10.3118 25.9678 15.0259C25.9678 19.7399 25.9678 22.0969 24.5034 23.5614C23.0389 25.0259 20.6819 25.0259 15.9678 25.0259C11.2538 25.0259 8.89677 25.0259 7.4323 23.5614C5.96783 22.0969 5.96783 19.7399 5.96783 15.0259Z"
        stroke="#979797"
        strokeWidth="1.5"
      />
      <path
        d="M20.9678 10.0259H17.9678M20.9678 10.0259V13.0259M20.9678 10.0259L17.4678 13.5259M10.9678 20.0259H13.9678M10.9678 20.0259V17.0259M10.9678 20.0259L14.4678 16.5259"
        stroke="#979797"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Enlarge;
