import React from "react";

export const CustomizedOffers = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4214 32.5192C19.4214 31.5985 18.6754 30.8525 17.7547 30.8525C17.2941 30.8525 16.8774 31.0399 16.5747 31.3419L13.9107 34.0059C13.6087 34.3085 13.4214 34.7252 13.4214 35.1859C13.4214 36.1065 14.1674 36.8525 15.0881 36.8525C15.5487 36.8525 15.9654 36.6652 16.2681 36.3632L18.9321 33.6992C19.2341 33.3965 19.4214 32.9799 19.4214 32.5192Z"
        fill="#2C8B52"
      />
      <path
        d="M15.4215 29.8542C15.4215 28.9335 14.6755 28.1875 13.7549 28.1875C13.2942 28.1875 12.8775 28.3748 12.5749 28.6768L9.24422 32.0075C8.94222 32.3102 8.75488 32.7268 8.75488 33.1875C8.75488 34.1082 9.50088 34.8542 10.4215 34.8542C10.8822 34.8542 11.2989 34.6668 11.6015 34.3648L14.9322 31.0342C15.2342 30.7315 15.4215 30.3148 15.4215 29.8542Z"
        fill="#2C8B52"
      />
      <path
        d="M12.0882 26.5192C12.0882 25.5985 11.3422 24.8525 10.4215 24.8525C9.96088 24.8525 9.54422 25.0399 9.24155 25.3419L5.24422 29.3392C4.94222 29.6419 4.75488 30.0585 4.75488 30.5192C4.75488 31.4399 5.50088 32.1859 6.42155 32.1859C6.88222 32.1859 7.29888 31.9985 7.60155 31.6965L11.5989 27.6992C11.9009 27.3965 12.0882 26.9799 12.0882 26.5192Z"
        fill="#2C8B52"
      />
      <path
        d="M8.75439 23.1872C8.75439 22.2665 8.00839 21.5205 7.08773 21.5205C6.62706 21.5205 6.21039 21.7078 5.90773 22.0098L3.24373 24.6738C2.94173 24.9765 2.75439 25.3932 2.75439 25.8538C2.75439 26.7745 3.50039 27.5205 4.42106 27.5205C4.88173 27.5205 5.29839 27.3332 5.60106 27.0312L8.26506 24.3672C8.56706 24.0645 8.75439 23.6478 8.75439 23.1872Z"
        fill="#2C8B52"
      />
      <path
        d="M32.9888 23.1178L24.6735 15.0248C24.2114 14.575 23.6056 14.3316 22.9887 14.3216C22.6152 14.3152 22.2387 14.3955 21.8868 14.5621L16.8068 16.9699C16.4051 17.1611 16.0491 17.2537 15.7201 17.2537C14.6106 17.2537 13.6372 16.3119 13.6372 15.2376C13.6372 14.6102 13.8759 14.0783 14.3474 13.659L17.1252 11.1867C17.5797 10.7821 18.1186 10.483 18.7032 10.3118L22.6539 9.15419C22.8386 9.09966 23.062 9.04395 23.313 9.04395C23.6314 9.04395 23.9082 9.13132 24.1129 9.20756L28.3397 10.5516C28.8898 10.7269 29.4656 10.8173 30.0479 10.8032C30.6297 10.7944 31.1991 10.6947 31.7415 10.5059L35.3133 9.0762C35.3679 9.05509 35.423 9.04395 35.4775 9.04395C35.7121 9.04336 35.9208 9.23277 35.9208 9.48786V18.6376C35.9208 18.8816 35.844 19.1203 35.7021 19.319L32.9888 23.1178Z"
        fill="#2C8B52"
      />
      <path
        d="M21.3672 33.0732C21.2512 33.8412 20.9065 34.5526 20.3465 35.1126L19.4858 35.9732L20.5365 37.0239C21.1965 37.6839 22.2672 37.6839 22.9272 37.0239C23.5872 36.3639 23.5872 35.2932 22.9272 34.6332L21.3672 33.0732Z"
        fill="#2C8B52"
      />
      <path
        d="M14.1812 11.45C13.4722 11.2999 12.6653 11.1187 11.9604 10.9528C10.8474 10.6918 9.78484 10.2602 8.80377 9.67381L4.86192 7.31702C4.80738 7.29532 4.75226 7.28418 4.69772 7.28418C4.46316 7.28359 4.25439 7.47301 4.25439 7.72809V18.4489C4.25439 18.8154 4.36874 19.1725 4.5822 19.471L5.77496 21.1411L6.37134 20.5447C6.97769 19.9354 7.78811 19.5988 8.65248 19.5988C10.3361 19.5988 11.7212 20.8971 11.8643 22.5449C13.4194 22.6792 14.662 23.9213 14.7963 25.477C16.1591 25.5943 17.2798 26.5636 17.6275 27.8485C17.7624 27.8314 17.8967 27.8086 18.0351 27.8086C19.2753 27.8086 20.3531 28.514 20.8926 29.5432L24 32.6512C24.5806 33.2317 25.5224 33.2317 26.1029 32.6512C26.6835 32.0706 26.6835 31.1288 26.1029 30.5483L22.0186 26.4639C21.9124 26.3578 21.8467 26.2112 21.8467 26.0493C21.8467 25.7251 22.1089 25.4629 22.4331 25.4629C22.595 25.4629 22.7416 25.5286 22.8477 25.6348L26.9321 29.7191C27.5126 30.2997 28.4544 30.2997 29.035 29.7191C29.6155 29.1386 29.6155 28.1968 29.035 27.6162L24.9506 23.5319C24.8445 23.4257 24.7788 23.2791 24.7788 23.1173C24.7788 22.793 25.0409 22.5309 25.3652 22.5309C25.5271 22.5309 25.6737 22.5966 25.7798 22.7027L29.8642 26.7871C30.4447 27.3676 31.3865 27.3676 31.967 26.7871C32.5476 26.2065 32.5476 25.2647 31.967 24.6842L23.4435 16.2874C23.3069 16.1531 23.1251 16.0798 22.9416 16.0804C22.8395 16.0809 22.7369 16.1056 22.6407 16.1513L17.5607 18.5591C16.9179 18.864 16.3151 19.0124 15.7199 19.0124C12.9169 19.0124 10.6991 15.8798 12.5697 13.0562C12.7673 12.7577 13.0119 12.4909 13.2799 12.2534L14.1812 11.45Z"
        fill="#2C8B52"
      />
    </svg>
  );
};
