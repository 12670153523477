import React from "react";
import HeaderConfig from "../helpers/HeaderConfig";
import axios from "axios";

const QuickSignUpService = () => {
  const { configHeader, baseUrl } = HeaderConfig();

  //user register
  const userRegister = async (data) => {
    return await axios.post(baseUrl + "auth/user/register", data, configHeader);
  };

  return { userRegister };
};

export default QuickSignUpService;
