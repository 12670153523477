import React from "react";

const MiniPhoneIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66699 9.33724C4.66699 6.82308 4.66699 5.566 5.44804 4.78495C6.22909 4.00391 7.48617 4.00391 10.0003 4.00391C12.5145 4.00391 13.7716 4.00391 14.5526 4.78495C15.3337 5.566 15.3337 6.82308 15.3337 9.33724V12.0039C15.3337 14.5181 15.3337 15.7751 14.5526 16.5562C13.7716 17.3372 12.5145 17.3372 10.0003 17.3372C7.48617 17.3372 6.22909 17.3372 5.44804 16.5562C4.66699 15.7751 4.66699 14.5181 4.66699 12.0039V9.33724Z"
        stroke="#2C8B52"
        strokeWidth="0.9"
      />
      <path
        d="M12.0002 15.3379H8.00024"
        stroke="#2C8B52"
        strokeWidth="0.9"
        strokeLinecap="round"
      />
      <path
        d="M13.1659 4.25586L13.1098 4.33997C12.6058 5.09603 12.3538 5.47407 11.9857 5.70281C11.9125 5.74829 11.8364 5.789 11.758 5.82467C11.3635 6.00407 10.9091 6.00407 10.0004 6.00407C9.09176 6.00407 8.63742 6.00407 8.24291 5.82467C8.16446 5.789 8.0884 5.74829 8.0152 5.70281C7.6471 5.47407 7.39508 5.09604 6.89103 4.33997L6.83496 4.25586"
        stroke="#2C8B52"
        strokeWidth="0.9"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MiniPhoneIcon;
