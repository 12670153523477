import React from "react";

const WhyChooseIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4167 5.60612C14.8667 5.6142 15.652 5.6785 16.1642 6.19075C16.75 6.77654 16.75 7.71935 16.75 9.60497V13.605C16.75 15.4906 16.75 16.4334 16.1642 17.0192C15.5784 17.605 14.6356 17.605 12.75 17.605H8.75C6.86438 17.605 5.92157 17.605 5.33579 17.0192C4.75 16.4334 4.75 15.4906 4.75 13.605V9.60497C4.75 7.71935 4.75 6.77654 5.33579 6.19075C5.84804 5.6785 6.63332 5.6142 8.08333 5.60612"
        stroke="#2C8B52"
        stroke-width="1.2"
      />
      <path
        d="M8.75 11.8728L9.89286 12.9394L12.75 10.2728"
        stroke="#2C8B52"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.08331 5.27278C8.08331 4.7205 8.53103 4.27278 9.08331 4.27278H12.4166C12.9689 4.27278 13.4166 4.7205 13.4166 5.27278V5.93945C13.4166 6.49173 12.9689 6.93945 12.4166 6.93945H9.08331C8.53103 6.93945 8.08331 6.49173 8.08331 5.93945V5.27278Z"
        stroke="#2C8B52"
        stroke-width="1.2"
      />
    </svg>
  );
};

export default WhyChooseIcon;
