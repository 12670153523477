import { InputOTP } from "antd-input-otp";
import PassCode from "../../assets/svg/PassCode";
import { Form, Button, Modal, ConfigProvider } from "antd";
import { useContext, useEffect, useState } from "react";
import Success from "./Success";
import { NotificationContext } from "../../context/NotificationContext";
import PublicProfileService from "../../services/PublicProfileService";
import { encryptSecureData } from "../../helpers/encryptHelpers/encryption";

const EnterOtp = ({
  submitRecomondationData,
  otpReferance,
  userEmail,
  addFormValues,
  setOtpReferance,
}) => {
  let { openNotification, handleError } = useContext(NotificationContext);

  const [timeLeft, setTimeLeft] = useState(120);
  const [resendLoading, setResendLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { verifyRecomondationOTP, getRecomondationOTP } =
    PublicProfileService();

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timeLeft % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleSubmit = async (value) => {
    setSubmitLoading(true);
    try {
      await verifyRecomondationOTP({
        email_address: encryptSecureData(userEmail),
        reference: otpReferance,
        otp: value?.pin?.join(""),
      })
        .then((response) => {
          if (response?.data?.success) {
            submitRecomondationData({}, "success", null);
            setSubmitLoading(false);
          } else {
            openNotification("warning", response?.data?.message);
            setSubmitLoading(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setSubmitLoading(false);
        });
    } catch (error) {
      handleError(error);
      setSubmitLoading(false);
    }
    setSubmitLoading(false);
  };

  const fetchRecomondationOTP = async () => {
    setResendLoading(true);
    try {
      await getRecomondationOTP({
        email_address: encryptSecureData(userEmail),
        full_name: encryptSecureData(addFormValues?.name),
      })
        .then((response) => {
          if (response?.data?.success) {
            setOtpReferance(response?.data?.output?.reference);
            setTimeLeft(120);
            setResendLoading(false);
          } else {
            openNotification("warning", response?.data?.message);
            setResendLoading(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setResendLoading(false);
        });
    } catch (error) {
      handleError(error);
    }
    setResendLoading(false);
  };

  const maskEmail = (email) => {
    const [localPart, domain] = email?.split("@");

    if (localPart.length <= 3) {
      return `${localPart[0]}*****@${domain}`;
    }

    return `${localPart.slice(0, 4)}*****${localPart.slice(-1)}@${domain}`;
  };

  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <p className="text-lg md:text-lg font-bold my-5">Enter OTP</p>

          <p>
            A six-digit verification code has been sent to{" "}
            <span className="font-bold">{maskEmail(userEmail)} </span>
            Enter the verification code to continue
          </p>

          <Form className="mt-5 w-full" onFinish={handleSubmit}>
            <span className="flex flex-row justify-start items-center gap-2 mb-5">
              <PassCode />
              <p className="text-sm font-medium">Enter OTP *</p>
            </span>

            <Form.Item
              name="pin"
              rules={[{ required: true, message: "OTP is required!" }]}
            >
              <InputOTP
                type="password"
                autoFocus
                length={6}
                inputType="numeric"
                inputClassName="w-full"
                className="!md:w-[50px] !h-[50px] !max-w-60"
              />
            </Form.Item>

            <p className="text-lg font-normal text-center text-primaryDark">
              {/* 02:00 */}
              {formatTime()}
            </p>

            <div className="flex items-center justify-center">
              <Button
                type="link"
                disabled={formatTime() !== "00:00"}
                onClick={() => {
                  fetchRecomondationOTP();
                }}
                loading={resendLoading}
              >
                <p className="text-sm font-normal text-center text-textColorTwo underline">
                  Resend code
                </p>
              </Button>
            </div>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                className="w-full bg-primaryDarkest text-sm font-semibold mt-3"
                disabled={formatTime() === "00:00"}
                htmlType="submit"
                loading={submitLoading}
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {/* <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "rgb(255,255,255)",
            },
          },
        }}
      >
        <Modal
          title="Thanks For Recommending!"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Success />
        </Modal>
      </ConfigProvider> */}
    </div>
  );
};
export default EnterOtp;
