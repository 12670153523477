import React from "react";

const Icon1 = () => {
  return (
    <svg
      width="27"
      height="32"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.36713 21.4439C6.95796 22.0901 6.01527 22.0901 5.6061 21.4439L2.58695 16.6757C2.14758 15.9818 2.64615 15.076 3.46746 15.076L9.50578 15.076C10.3271 15.076 10.8257 15.9818 10.3863 16.6757L7.36713 21.4439Z"
        stroke="#2C8B52"
        stroke-width="1.56327"
      />
      <path
        d="M16.9121 28.398C16.5029 29.0442 15.5602 29.0442 15.151 28.398L12.1319 23.6298C11.6925 22.9359 12.1911 22.0301 13.0124 22.0301L19.0507 22.0301C19.872 22.0301 20.3706 22.9359 19.9312 23.6298L16.9121 28.398Z"
        stroke="#2C8B52"
        stroke-width="1.56327"
      />
      <path
        d="M18.3979 16.2715C17.9887 16.9177 17.046 16.9177 16.6368 16.2715L10.4314 6.47111C9.99201 5.77721 10.4906 4.87141 11.3119 4.87141L23.7228 4.87141C24.5441 4.87141 25.0427 5.77721 24.6033 6.47111L18.3979 16.2715Z"
        stroke="#2C8B52"
        stroke-width="1.56327"
      />
    </svg>
  );
};

export default Icon1;
