import React from "react";

const InfoOutline = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00579 11.5773C8.22671 11.5773 8.40579 11.3983 8.40579 11.1773V7.97734C8.40579 7.75643 8.22671 7.57734 8.00579 7.57734C7.78488 7.57734 7.60579 7.75643 7.60579 7.97734V11.1773C7.60579 11.3983 7.78488 11.5773 8.00579 11.5773Z"
        fill="#939292"
      />
      <path
        d="M8.00579 5.84401C8.30035 5.84401 8.53913 6.08279 8.53913 6.37734C8.53913 6.6719 8.30035 6.91068 8.00579 6.91068C7.71124 6.91068 7.47246 6.6719 7.47246 6.37734C7.47246 6.08279 7.71124 5.84401 8.00579 5.84401Z"
        fill="#939292"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.27246 8.51068C2.27246 5.34424 4.83936 2.77734 8.00579 2.77734C11.1722 2.77734 13.7391 5.34424 13.7391 8.51068C13.7391 11.6771 11.1722 14.244 8.00579 14.244C4.83936 14.244 2.27246 11.6771 2.27246 8.51068ZM8.00579 3.57734C5.28119 3.57734 3.07246 5.78607 3.07246 8.51068C3.07246 11.2353 5.28119 13.444 8.00579 13.444C10.7304 13.444 12.9391 11.2353 12.9391 8.51068C12.9391 5.78607 10.7304 3.57734 8.00579 3.57734Z"
        fill="#939292"
      />
    </svg>
  );
};

export default InfoOutline;
