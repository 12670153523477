import { Button, Modal, Spin, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import LocationPInIcon from "../../assets/svg/LocationPInIcon";
import NewServiceArea from "./NewServiceArea";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { FaPlus } from "react-icons/fa";

const UpdateAvailableIns = ({ open, onClose, data, fetchAll }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const [addAvailableIn, setAddAvailableIn] = useState(false);
  const { removeAvailableIn, getAvailableIns } = PublicProfileService();

  const [availableInsList, setAvailableInsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAvailableIns();
  }, []);

  const fetchAvailableIns = async () => {
    setLoading(true);
    try {
      await getAvailableIns({
        user_id: data?.user_id,
        country_id: data?.country_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setAvailableInsList(response.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const handleRemove = async (e) => {
    try {
      await removeAvailableIn({
        trady_suburb_id: e.trady_suburb_id,
        user_id: getLocalStoragedata("userId"),
        status: 0,
      })
        .then((response) => {
          if (response?.data?.success) {
            fetchAll();
            fetchAvailableIns();
            openNotification("success", response?.data?.message);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={950}
      title="Available In"
    >
      <div className="flex flex-col gap-4">
        <div className="flex">
          <Button
            className="border-[#074924] text-[#074924] font-semibold"
            size="large"
            onClick={() => setAddAvailableIn(true)}
          >
            <FaPlus />
            Add New Service Areas
          </Button>
        </div>
        <div className=" flex flex-col gap-2 overflow-y-auto">
          {loading ? (
            <div className="flex w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {availableInsList.length == 0 ? (
                <div className="flex w-full justify-center items-center">
                  No Available Ins
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-3 3xl:grid-cols-4 gap-2">
                  {availableInsList?.map((state) => {
                    return (
                      <>
                        {state.suburb.map((suburb, index) => {
                          return (
                            <Tag
                              className="flex flex-row gap-1 justify-center items-center p-1 rounded-lg w-fit"
                              key={index}
                              closeIcon
                              onClose={() => {
                                handleRemove(suburb);
                              }}
                            >
                              <LocationPInIcon />
                              <p className="text-sm text-darkestColor text-left truncate w-[150px] lg:w-[250px]">
                                {suburb?.postal_code},&nbsp;{suburb?.suburb}
                                ,&nbsp;
                                {state?.state}
                              </p>
                            </Tag>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {addAvailableIn && (
        <NewServiceArea
          open={addAvailableIn}
          onClose={() => {
            setAddAvailableIn(false);
          }}
          data={data}
          fetchAvailableIns={() => {
            fetchAvailableIns();
            fetchAll();
          }}
        />
      )}
    </Modal>
  );
};

export default UpdateAvailableIns;
