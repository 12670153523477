import React, { useContext, useEffect, useState } from "react";
import EditIconInfo from "../../assets/svg/EditIconInfo";
import LocationPInIcon from "../../assets/svg/LocationPInIcon";
import { Col, Row, Spin, Tag, Typography } from "antd";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import UpdateAvailableIns from "../../components/workerProfile/UpdateAvailableIns";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";

const { Text } = Typography;

const AvailableIn = ({ data, editable }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { getWorkingAreas } = PublicProfileService();

  const [loading, setLoading] = useState(false);
  const [availableInsList, setAvailableInsList] = useState([]);

  const [showAddEditAvailableIns, setShowAvailableIns] = useState(false);

  const [showMore, setShowMore] = useState(availableInsList?.length > 6);
  const [isWorkInYourArea, setIsWorkInYourArea] = useState(0);

  useEffect(() => {
    fetchAvailableIns();
  }, []);

  const fetchAvailableIns = async () => {
    setLoading(true);
    try {
      await getWorkingAreas({
        user_id: data?.user_id,
        country_id: data?.country_id,
        customer_id: getLocalStoragedata("userData")?.user_id,
      })
        .then((response) => {
          if (response?.data?.success) {
            setIsWorkInYourArea(response?.data?.output?.in_your_area || 0);
            setAvailableInsList(response?.data?.output?.suburbs);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-white rounded-lg p-4 shadow-lg w-full mt-2">
      <div className="flex justify-start flex-col">
        <div className="flex justify-between">
          <Text className="flex text-xl font-bold text-primaryDarkest">
            Available In
          </Text>
          {editable ? (
            <div
              className="cursor-pointer scale-75"
              onClick={() => {
                setShowAvailableIns(true);
              }}
            >
              <EditIconInfo />
            </div>
          ) : (
            <>
              {isWorkInYourArea == 1 && (
                <div className="flex rounded-full text-sm font-bold text-primaryDark bg-[#E0EFE2] px-2 items-center justify-center">
                  This worker is serving in your area
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex flex-col w-full justify-center items-start">
          {loading ? (
            <div className="flex w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {availableInsList?.length == 0 ? (
                <div className="flex w-full justify-center items-center mt-3">
                  No Data
                </div>
              ) : (
                <Row gutter={8} className="flex flex-row mt-3 items-center">
                  {showMore
                    ? availableInsList?.map((area, index) => {
                        return (
                          <Col className="mb-2">
                            <Tag
                              className="flex flex-row gap-1 justify-center items-center p-1 rounded-lg w-fit"
                              key={index}
                            >
                              <LocationPInIcon />
                              <p className="text-sm text-darkestColor text-left truncate w-[150px] lg:w-[250px]">
                                {area?.postal_code},&nbsp;
                                {area?.suburb}
                                ,&nbsp;
                                {area?.state}
                              </p>
                            </Tag>
                          </Col>
                        );
                      })
                    : availableInsList?.slice(0, 6).map((area, index) => {
                        return (
                          <Col className="mb-2">
                            <Tag
                              className="flex flex-row gap-1 justify-center items-center p-1 rounded-lg w-fit"
                              key={index}
                            >
                              <LocationPInIcon />
                              <p className="text-sm text-darkestColor text-left truncate w-[150px] lg:w-[250px]">
                                {area?.postal_code},&nbsp;
                                {area?.suburb}
                                ,&nbsp;
                                {area?.state}
                              </p>
                            </Tag>
                          </Col>
                        );
                      })}
                </Row>
              )}
            </>
          )}
        </div>

        <div className="flex flex-row justify-center items-center text-center">
          {availableInsList.length > 6 && (
            <>
              {!showMore && (
                <div
                  className="flex text-primaryDark items-center cursor-pointer mt-2"
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  Show All{" "}
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.48926 8.5332H12.4377"
                      stroke="#2C8B52"
                      strokeWidth="1.91752"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.96338 4.05762L12.4376 8.53184L7.96338 13.0061"
                      stroke="#2C8B52"
                      strokeWidth="1.91752"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
              {showMore && (
                <div
                  className="flex text-primaryDark items-center cursor-pointer mt-2"
                  onClick={() => {
                    setShowMore(false);
                  }}
                >
                  Show Less{" "}
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.48926 8.5332H12.4377"
                      stroke="#2C8B52"
                      strokeWidth="1.91752"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.96338 4.05762L12.4376 8.53184L7.96338 13.0061"
                      stroke="#2C8B52"
                      strokeWidth="1.91752"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showAddEditAvailableIns && (
        <UpdateAvailableIns
          open={showAddEditAvailableIns}
          onClose={() => {
            setShowAvailableIns(false);
          }}
          data={data}
          fetchAll={fetchAvailableIns}
        />
      )}
    </div>
  );
};

export default AvailableIn;
