import React, { useEffect } from "react";
import WorkDoneIcon from "../../assets/svg/WorkDoneIcon";
import { Button } from "antd";
import JobninjaClient from "../../assets/img/jobninjaClient.png";
import { useNavigate } from "react-router-dom";

const PinResetSuccessCard = ({ stateValue }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="flex justify-center items-center mt-10">
      <div className="flex flex-col justify-center shadow-lg rounded-lg w-full md:w-[500px] bg-primaryLightest p-8">
        <div className="flex flex-col justify-center items-center">
          <img
            src={JobninjaClient}
            alt="JobninjaClient"
            width={160}
            className="my-5"
          />
          <p className="text-lg font-bold">Done!</p>
          <WorkDoneIcon />

          <p className="'text-base font-normal text-center">
            Your passcode has been successfully reset. please log in with new
            passcode
          </p>

          <Button
            type="primary"
            size="large"
            className="bg-primaryDarkest w-full max-w-[400px] mt-8"
            htmlType="submit"
            onClick={() => {
              navigate("../new-login", {
                state: { from: stateValue?.from, type: stateValue?.type },
              });
              //type-->2 = Client, 6--->worker
              //from: search,directSignin
            }}
          >
            Log in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PinResetSuccessCard;
