import React from "react";

const CompleteIcon = () => {
  return (
    <svg
      width="21"
      height="27"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.444336 6.19469C0.444336 11.0826 4.4186 13.6872 7.32784 16.0186C8.35444 16.8413 9.3432 17.6159 10.332 17.6159C11.3207 17.6159 12.3095 16.8413 13.3361 16.0186C16.2453 13.6872 20.2196 11.0826 20.2196 6.19469C20.2196 1.30682 14.7812 -2.15956 10.332 2.53958C5.88269 -2.15956 0.444336 1.30682 0.444336 6.19469Z"
        fill="#2C8B52"
      />
    </svg>
  );
};

export default CompleteIcon;
