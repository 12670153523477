import React from "react";

export const SadIcon = () => {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.8885 118.706H81.1113C107.964 118.706 118.705 107.965 118.705 81.113V48.8902C118.705 22.0378 107.964 11.2969 81.1113 11.2969H48.8885C22.0361 11.2969 11.2952 22.0378 11.2952 48.8902V81.113C11.2952 107.965 22.0361 118.706 48.8885 118.706Z"
        stroke="#2C8B52"
        strokeWidth="8.05571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.1475 47.5474C43.5179 42.1769 52.2718 42.1769 57.696 47.5474"
        stroke="#2C8B52"
        strokeWidth="8.05571"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.3042 47.5474C77.6747 42.1769 86.4285 42.1769 91.8527 47.5474"
        stroke="#2C8B52"
        strokeWidth="8.05571"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.106 91.4822C54.2345 78.3536 75.6341 78.3536 88.8939 91.4822"
        stroke="#2C8B52"
        strokeWidth="8.05571"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
