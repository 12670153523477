import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Divider,
  Dropdown,
  Radio,
  Select,
  Spin,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import JobpostLayout from "./jobpostLayout/JobpostLayout";
import NotificationAlertBox from "../../components/alertBox/NotificationAlertBox";

import DotIcon from "../../assets/svg/DotIcon";
import LocationIconCard from "../../assets/svg/LocationIconCard";

import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { NotificationContext } from "../../context/NotificationContext";

import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import ArrowDownOutline from "../../assets/svg/ArrowDownOutline";
import CalendarGreenOutlineIcon from "../../assets/svg/CalendarGreenOutlineIcon";
import UrgentRed from "../../assets/svg/UrgentRed";
import DoubleArrowBlack from "../../assets/svg/DoubleArrowBlack";
import InfoOutline from "../../assets/svg/InfoOutline";
import CalendarOrange from "../../assets/svg/CalendarOrange";
import DiscriptionIcon from "../../assets/svg/DiscriptionIcon";
import { LoadingOutlined } from "@ant-design/icons";
import PropertyService from "../../services/PropertyService";
import PropertyIcon from "../../assets/svg/PropertyIcon";
import AddNewProperty from "../../components/myProperties/AddNewProperty";
import LanguageGreenOutLineIcon from "../../assets/svg/LanguageGreenOutLineIcon";
import PublicProfileService from "../../services/PublicProfileService";

const { Text } = Typography;

const JobDetails = ({ type }) => {
  const navigate = useNavigate();
  const { openNotification, handleError } = useContext(NotificationContext);
  const {
    setStepperCurrentSteps,
    selectedJobType,
    selectedJobDetailsData,
    setSelectedJobDetailsData,
    locationSearchValue,

    setLoginJobPostSteps,
  } = useContext(CreateJobPostContext);

  const { fetchMyProperties } = PropertyService();
  const { getLanguageList } = PublicProfileService();
  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);
  const [dropdownPropertyList, setDropdownPropertyList] = useState([]);
  const [openAddNewPropertyModal, setAddNewPropertyModal] = useState(false);
  const [languageSelectorData, setLanguageSelectorData] = useState([]);
  const [openWrongPropertySelectAlert, setOpenWrongPropertySelectAlert] =
    useState(false);

  useEffect(() => {
    fetchAllLanguages();
    if (selectedJobDetailsData?.propertyData === null) {
      getMyProperties();
    }

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //get all property list
  const getMyProperties = async () => {
    try {
      await fetchMyProperties({
        user_id: getLocalStoragedata("userId"),
        status: 1,
      })
        .then((response) => {
          if (response?.data?.success) {
            setDropdownList(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  //set property dropdown list
  const setDropdownList = (dataList) => {
    const dropdownList = [
      {
        key: "1",
        label: (
          <div
            className="flex flex-col w-full items-start"
            onClick={() => {
              setAddNewPropertyModal(true);
            }}
          >
            <button className="text-base font-semibold text-primaryDark">
              + Add New Property
            </button>

            <Divider className="my-2 bg-slate-200" />
          </div>
        ),
      },
    ];

    if (type === "searchAreadyLogin") {
      let initialValues = dataList?.find((item) => item?.is_default === 1);

      //only need to set default one when  not selected any property
      if (
        selectedJobDetailsData?.propertyData === null ||
        selectedJobDetailsData?.propertyData === undefined
      ) {
        setSelectedJobDetailsData({
          ...selectedJobDetailsData,
          propertyData: initialValues,
        });
      }
    }

    dataList?.forEach((list) => {
      let data = {
        key: list?.id,
        label: (
          <div
            className="flex flex-col w-full items-start"
            onClick={() => handlePropertySelect(list)}
          >
            <div className="flex flex-row justify-start items-baseline gap-4">
              <Checkbox
                checked={selectedJobDetailsData?.propertyData?.id === list?.id}
              />
              <div className="flex flex-col sm:justify-start">
                <div className="flex flex-row justify-start items-center gap-2">
                  {/* <HomeOutline /> */}
                  <PropertyIcon />
                  <Text className="text-base font-semibold">
                    {list?.property_name}
                  </Text>
                </div>
                <Text className="text-textColorTwo">
                  {list?.address +
                    ", " +
                    list?.state +
                    ", " +
                    list?.suburb +
                    list?.postal_code +
                    ", " +
                    list?.country}
                </Text>
              </div>
            </div>

            <Divider className="my-2 bg-slate-200" />
          </div>
        ),
      };
      dropdownList.push(data);
    });

    setDropdownPropertyList(dropdownList);
  };

  // Handle property selection
  const handlePropertySelect = (property) => {
    if (
      type === "searchJustNowLogin" &&
      property.suburb_id !== locationSearchValue.suburb
    ) {
      setOpenWrongPropertySelectAlert(true);
    } else {
      setSelectedJobDetailsData({
        ...selectedJobDetailsData,
        propertyData: property,
      });
    }
  };

  // Fetch properties when dropdown is about to open
  const handleDropdownVisibleChange = async (visible) => {
    if (visible) {
      await getMyProperties(); // Fetch or refresh property list when dropdown opens
    }
  };

  //get all language list
  const fetchAllLanguages = async () => {
    try {
      await getLanguageList({
        trady_id: getLocalStoragedata("userId"),
      })
        .then((response) => {
          if (response?.data?.success) {
            setLanguageData(response?.data?.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLanguageData = (items) => {
    var languageObject = [];
    items?.forEach((item) => {
      let data = {
        value: item?.id,
        label: item?.language,
      };
      languageObject.push(data);
    });
    setLanguageSelectorData(languageObject);
  };

  useEffect(() => {
    setSelectedJobDetailsData({
      ...selectedJobDetailsData,
      language:
        type === "searchJustNowLogin"
          ? locationSearchValue?.language
          : selectedJobDetailsData?.language,
    });
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              controlHeight: 38,
              colorBorder: "rgb(123,123,123)",
            },
            Input: {
              controlHeight: 38,
              colorBorder: "rgb(123,123,123)",
            },
          },
        }}
      >
        <JobpostLayout>
          {/* <div>
            <h1 className="text-xl md:text-[26px] font-bold text-primaryDarkest pt-5 md:pt-10 text-center">
              Create Your Job Post
            </h1>

            <div className="pt-3 md:pt-5">
              <div className="flex flex-row justify-start items-center gap-2 pl-2">
                <DotIcon width="8" color="#40D37B" />
                <p className="text-xs md:text-sm font-normal text-textColorTwo">
                  Selected Job Type :
                </p>
                <p className="text-sm md:text-xl font-semibold text-textColorOne">
                  {selectedJobType}
                </p>
              </div>
              
              <div className="flex flex-row justify-start items-center gap-2 pl-2">
                <DotIcon width="8" color="#40D37B" />
                <p className="text-xs md:text-sm font-normal text-textColorTwo whitespace-nowrap">
                  Preferred Location :
                </p>
                {type === "searchJustNowLogin" ? (
                  <p className="text-sm md:text-xl font-semibold text-textColorOne">
                    {locationSearchValue?.postalCodeIsRequired === 1 ? (
                      <>{locationSearchValue?.postalCodetype}</>
                    ) : (
                      <>
                        {locationSearchValue?.suburbName},{" "}
                        {locationSearchValue?.stateName},{" "}
                        {locationSearchValue?.countryName}.
                      </>
                    )}
                  </p>
                ) : (
                  <p className="text-sm md:text-xl font-semibold text-textColorOne">
                    {selectedJobDetailsData?.propertyData?.suburb},{" "}
                    {selectedJobDetailsData?.propertyData?.state},{" "}
                    {selectedJobDetailsData?.propertyData?.country}.
                  </p>
                )}
              </div>

            </div>
          </div> */}

          <div>
            <h1 className="text-xl md:text-[26px] font-bold text-primaryDarkest pt-5 md:pt-10 text-center">
              Create Your Job Post
            </h1>

            <div className="pt-3 md:pt-5">
              <div className="flex flex-wrap md:flex-nowrap items-center gap-2 pl-2">
                <DotIcon width="8" color="#40D37B" />
                <p className="text-xs md:text-sm font-normal text-textColorTwo whitespace-nowrap">
                  Selected Job Type:
                </p>
                <p className="text-xs md:text-lg font-semibold text-textColorOne">
                  {selectedJobType}
                </p>
              </div>

              <div className="flex flex-wrap md:flex-nowrap items-center gap-2 pl-2 mt-2">
                <DotIcon width="8" color="#40D37B" />
                <p className="text-xs md:text-sm font-normal text-textColorTwo whitespace-nowrap">
                  Preferred Location:
                </p>
                {type === "searchJustNowLogin" ? (
                  <p className="text-xs md:text-lg font-semibold text-textColorOne">
                    {locationSearchValue?.postalCodeIsRequired === 1 ? (
                      <>{locationSearchValue?.postalCodetype}</>
                    ) : (
                      <>
                        {locationSearchValue?.suburbName},{" "}
                        {locationSearchValue?.stateName},{" "}
                        {locationSearchValue?.countryName}.
                      </>
                    )}
                  </p>
                ) : (
                  <p className="text-xs md:text-lg font-semibold text-textColorOne">
                    {selectedJobDetailsData?.propertyData?.suburb},{" "}
                    {selectedJobDetailsData?.propertyData?.state},{" "}
                    {selectedJobDetailsData?.propertyData?.country}.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5 mt-2 md:mt-5">
            {/* Property.............................................................................. */}
            <div className="p-4 rounded-lg border-[2px] pb-6 bg-white">
              <p className="flex flex-row items-center gap-1 text-base font-bold">
                <LocationIconCard />
                Select a Property <span className="text-red-500">*</span>
              </p>

              <Dropdown
                menu={{
                  items: dropdownPropertyList,
                }}
                trigger={["click"]}
                placement="bottom"
                onOpenChange={handleDropdownVisibleChange}
                dropdownRender={(menu) => (
                  <div className="max-h-[300px] overflow-y-scroll shadow-lg thin-scrollbar">
                    {menu}
                  </div>
                )}
              >
                <div className="flex flex-row justify-between items-center border-[1px] rounded-lg p-2 cursor-pointer">
                  {dropdownPropertyList?.length === 1 ? (
                    <Text className="text-textColorTwo">
                      Add a property to continue
                    </Text>
                  ) : (
                    <div>
                      {selectedJobDetailsData?.propertyData === null ? (
                        <>
                          {type === "searchAreadyLogin" ? (
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="small"
                            />
                          ) : (
                            <Text className="text-textColorTwo">
                              Select a property to continue
                            </Text>
                          )}
                        </>
                      ) : (
                        <div className="flex flex-col xl:flex-row xl:justify-start xl:items-center xl:gap-4">
                          <div className="flex flex-row justify-start items-center gap-2">
                            <PropertyIcon />
                            <Text className="text-base font-semibold">
                              {
                                selectedJobDetailsData?.propertyData
                                  ?.property_name
                              }
                            </Text>
                          </div>
                          <Text className="text-textColorTwo">
                            {selectedJobDetailsData?.propertyData?.address},{" "}
                            {selectedJobDetailsData?.propertyData?.state},{" "}
                            {selectedJobDetailsData?.propertyData?.suburb},{" "}
                            {selectedJobDetailsData?.propertyData?.postal_code},{" "}
                            {selectedJobDetailsData?.propertyData?.country}
                          </Text>
                        </div>
                      )}
                    </div>
                  )}

                  <ArrowDownOutline />
                </div>
              </Dropdown>
            </div>

            {/* About Urgency.......................................................................... */}
            <div className="p-4 rounded-lg border-[2px] bg-white">
              <p className="flex flex-row items-center gap-1 text-base font-bold">
                <CalendarGreenOutlineIcon />
                Job Urgency <span className="text-red-500">*</span>
              </p>

              <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex flex-row gap-2 justify-between items-center border-[1px] border-black p-2 rounded-lg min-w-[200px] w-full">
                    <div className="flex flex-row gap-2 items-center">
                      <UrgentRed /> <Text>|</Text>
                      <Text className="text-base">Urgent</Text>
                    </div>
                    <Radio
                      checked={selectedJobDetailsData?.urgency === 1}
                      onChange={() => {
                        setSelectedJobDetailsData({
                          ...selectedJobDetailsData,
                          urgency: 1,
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <InfoOutline />
                    <span className="text-xs text-textColorTwo">
                      with in next 72 hours
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex flex-row gap-2 justify-between items-center border-[1px] border-black p-2 rounded-lg min-w-[200px] w-full">
                    <div className="flex flex-row gap-2 items-center">
                      <CalendarOrange /> <Text>|</Text>
                      <Text className="text-base">Within few days</Text>
                    </div>
                    <Radio
                      checked={selectedJobDetailsData?.urgency === 2}
                      onChange={() => {
                        setSelectedJobDetailsData({
                          ...selectedJobDetailsData,
                          urgency: 2,
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <InfoOutline />
                    <span className="text-xs text-textColorTwo">
                      with in next 3 - 7 days
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex flex-row gap-2 justify-between items-center border-[1px] border-black p-2 rounded-lg min-w-[200px] w-full">
                    <div className="flex flex-row gap-2 items-center">
                      <DoubleArrowBlack /> <Text>|</Text>
                      <Text className="text-base">I’m flexible</Text>
                    </div>
                    <Radio
                      checked={selectedJobDetailsData?.urgency === 3}
                      onChange={() => {
                        setSelectedJobDetailsData({
                          ...selectedJobDetailsData,
                          urgency: 3,
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <InfoOutline />
                    <span className="text-xs text-textColorTwo">
                      Not urgent
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* About language................................................................................. */}
            <div className="p-4 rounded-lg border-[2px] bg-white">
              <p className="flex flex-row items-center gap-1 text-base font-bold">
                <LanguageGreenOutLineIcon />
                Preferred Language <span className="text-red-500">*</span>
              </p>

              <div className="w-full">
                <Select
                  placeholder="Select language"
                  className="w-full"
                  options={languageSelectorData}
                  value={selectedJobDetailsData?.language}
                  onChange={(e) => {
                    setSelectedJobDetailsData({
                      ...selectedJobDetailsData,
                      language: e,
                    });
                  }}
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </div>
            </div>

            {/* About description........................................................................ */}
            <div className="p-4 rounded-lg border-[2px] bg-white">
              <p className="flex flex-row items-center gap-1 text-base font-bold">
                <DiscriptionIcon />
                Job Description <span className="text-red-500">*</span>
              </p>
              <div className="flex rounded-lg">
                <TextArea
                  rows={4}
                  maxLength={2048}
                  placeholder="Describe your job in detail."
                  value={selectedJobDetailsData?.description}
                  onChange={(e) => {
                    setSelectedJobDetailsData({
                      ...selectedJobDetailsData,
                      description: e?.target?.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.value === "") {
                      e.preventDefault();
                    }
                  }}
                />
              </div>

              <div className="pt-5 flex justify-end">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-primaryDark font-semibold px-8 flex flex-row gap-2 justify-center items-center"
                  onClick={() => {
                    setStepperCurrentSteps(1);

                    if (type === "searchAreadyLogin") {
                      setLoginJobPostSteps(2);
                    }
                  }}
                  disabled={
                    selectedJobDetailsData?.description === null ||
                    selectedJobDetailsData?.description === "" ||
                    selectedJobDetailsData?.propertyData === null
                  }
                >
                  Next <MdOutlineKeyboardArrowRight />
                </Button>
              </div>
            </div>

            <div className="mb-5"></div>
          </div>
        </JobpostLayout>
      </ConfigProvider>
      {openCloseNotificationAlertBox && (
        <NotificationAlertBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
          }}
          title="Can't Post a Job"
          content={
            <div>
              <p className="text-sm font-semibold text-warringColorOne text-center">
                You are currently logged in as a 'Worker.' Posting a new job is
                not available for workers. Please log in as a 'Client' to post a
                job.
              </p>

              <div className="flex justify-center items-center mt-5">
                <Button
                  type="primary"
                  className="px-10"
                  onClick={() => {
                    navigate("../");
                  }}
                >
                  Back to Home
                </Button>
              </div>
            </div>
          }
        />
      )}

      {openWrongPropertySelectAlert && (
        <NotificationAlertBox
          open={openWrongPropertySelectAlert}
          onCancel={() => {
            setOpenWrongPropertySelectAlert(false);
          }}
          title="The preferred location does not match."
          content={
            <div className="flex flex-col gap-5 text-center">
              <p className="text-sm text-warringColorOne font-semibold">
                The property you selected does not match your preferred
                location.
              </p>

              <p className="text-sm font-semibold">
                If you wish to proceed with the new location, you must select
                new workers based on that location.
              </p>

              <Button
                type="primary"
                className="bg-primaryDarkest px-10"
                size="large"
                onClick={() => {
                  setOpenWrongPropertySelectAlert(false);
                }}
              >
                Ok
              </Button>
            </div>
          }
        />
      )}

      {openAddNewPropertyModal && (
        <AddNewProperty
          open={openAddNewPropertyModal}
          onCancel={() => {
            setAddNewPropertyModal(false);
          }}
          getMyProperties={getMyProperties}
          count={dropdownPropertyList?.length - 1}
        />
      )}
    </>
  );
};

export default JobDetails;
