import { Typography } from "antd";
import React, { useState } from "react";
import { AddJobTypesDetailsSignup } from "../AddJobTypesDetailsSignup";
import DropDownIconRight from "../../../assets/svg/DropDownIconRight";

const { Text } = Typography;

export const AddJobTypesCardSignup = ({ data }) => {
  const [openAddJobType, setOpenAddJobType] = useState(false);

  return (
    <>
      <div
        className="flex flex-col items-center justify-center bg-[#F2F2F2] rounded-lg mt-2 w-full p-2  h-10 cursor-pointer"
        onClick={() => {
          setOpenAddJobType(true);
        }}
      >
        <div className="flex flex-row gap-2 justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center pl-3">
            <div className="flex flex-col">
              <Text className="text-base font-bold">{data?.job_type}</Text>
            </div>
          </div>
          <div className="xxsm:w-30 sm:w-40 flex justify-end pr-4">
            <DropDownIconRight />
          </div>
        </div>
      </div>

      {openAddJobType && (
        <AddJobTypesDetailsSignup
          open={openAddJobType}
          onClose={() => {
            setOpenAddJobType(false);
          }}
          data={data}
          type={0}
        />
      )}
    </>
  );
};
