import React from "react";

const UrgentRed = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.0059" cy="12.5107" r="12" fill="#DF6959" />
      <g clipPath="url(#clip0_2293_26446)">
        <path
          d="M6.33138 12.3041C6.33064 12.1292 6.26121 11.9617 6.13805 11.8375C6.07558 11.7788 6.00339 11.7314 5.92471 11.6975C5.84294 11.6707 5.75742 11.6572 5.67138 11.6575H4.77805C4.60481 11.6242 4.42544 11.661 4.27924 11.7597C4.13304 11.8584 4.03193 12.011 3.99805 12.1841V12.4508C4.05181 12.6184 4.16456 12.7608 4.31537 12.8515C4.46618 12.9422 4.6448 12.9752 4.81805 12.9441C5.09805 12.9441 5.38471 12.9441 5.67138 12.9441C5.75539 12.9465 5.83908 12.9329 5.91805 12.9041C5.99751 12.8716 6.06995 12.8241 6.13138 12.7641C6.19366 12.7046 6.24154 12.6316 6.27138 12.5508C6.30855 12.4737 6.329 12.3897 6.33138 12.3041Z"
          fill="white"
        />
        <path
          d="M16.2455 16.344C16.1055 16.344 16.0788 16.284 16.0855 16.1573C16.1255 14.824 16.1588 13.544 16.1588 12.2373C16.16 11.9121 16.1242 11.5878 16.0522 11.2706C15.7994 10.1981 15.162 9.2556 14.2606 8.62176C13.3593 7.98793 12.2567 7.70683 11.1619 7.83176C10.0671 7.95669 9.05625 8.47897 8.32088 9.29957C7.58551 10.1202 7.17678 11.1821 7.17218 12.284C7.17218 13.6173 7.17218 14.8773 7.24551 16.1773C7.24551 16.2973 7.24551 16.3306 7.09885 16.344C5.64551 16.344 5.31885 19.1173 7.39885 19.1173C8.81885 19.1173 15.4588 19.1173 16.1922 19.1173C17.9055 19.0906 17.4655 16.344 16.2455 16.344ZM10.9788 9.01063C11.4188 8.92084 11.8723 8.92084 12.3122 9.01063C12.7497 9.10318 13.1658 9.27733 13.5388 9.52397C13.9111 9.77502 14.2304 10.0967 14.4788 10.4706C14.7232 10.8482 14.8929 11.2692 14.9788 11.7106C15.0054 11.867 15.0188 12.0253 15.0188 12.184C15.0188 13.5173 14.9855 14.8106 14.9522 16.1173C14.9522 16.224 14.9188 16.2506 14.8122 16.244C13.7588 16.1773 12.7122 16.124 11.5655 16.1306C10.6122 16.1306 9.56551 16.184 8.51218 16.244C8.39885 16.244 8.37885 16.244 8.37885 16.1173C8.34551 14.844 8.32551 13.5773 8.31218 12.304C8.30584 11.531 8.56837 10.7798 9.05483 10.179C9.54129 9.57824 10.2214 9.16521 10.9788 9.01063Z"
          fill="white"
        />
        <path
          d="M6.9033 8.43824C6.97253 8.51576 7.0629 8.57138 7.1633 8.59824C7.2638 8.6248 7.36947 8.6248 7.46997 8.59824C7.5691 8.58844 7.66478 8.55654 7.74997 8.5049C7.82825 8.43771 7.88986 8.35328 7.92997 8.25824C7.99494 8.14533 8.01851 8.01331 7.99664 7.8849C7.97565 7.76352 7.9174 7.65168 7.82997 7.5649C7.57219 7.30268 7.31441 7.04935 7.05664 6.8049C7.00027 6.74502 6.93219 6.69737 6.85664 6.6649C6.7784 6.63287 6.6945 6.61699 6.60997 6.61824C6.5276 6.61696 6.44586 6.63285 6.36997 6.6649C6.20454 6.72647 6.07028 6.85114 5.99664 7.01157C5.98029 7.09073 5.98029 7.1724 5.99664 7.25157C5.98029 7.33298 5.98029 7.41682 5.99664 7.49824C6.0291 7.57379 6.07676 7.64187 6.13664 7.69824C6.4033 7.95157 6.6633 8.19824 6.9033 8.43824Z"
          fill="white"
        />
        <path
          d="M15.7247 8.57751C15.8394 8.62957 15.9672 8.64584 16.0913 8.62417C16.2156 8.6018 16.3298 8.54121 16.418 8.45084C16.6713 8.21084 16.918 7.95751 17.1647 7.70417C17.2328 7.64643 17.2893 7.57631 17.3313 7.49751C17.3445 7.41581 17.3445 7.33253 17.3313 7.25084C17.3444 7.17137 17.3444 7.09031 17.3313 7.01084C17.2981 6.93375 17.2506 6.86363 17.1913 6.80417C17.1322 6.74623 17.0618 6.70088 16.9847 6.67084C16.9077 6.63802 16.825 6.62109 16.7413 6.62109C16.6577 6.62109 16.5749 6.63802 16.498 6.67084C16.4208 6.70088 16.3505 6.74623 16.2913 6.80417C16.0447 7.04417 15.8047 7.29084 15.5647 7.53751C15.5002 7.59917 15.4501 7.67428 15.418 7.75751C15.3853 7.84239 15.3716 7.93343 15.378 8.02417C15.3784 8.14155 15.4158 8.25582 15.4847 8.35084C15.5416 8.44758 15.6248 8.52618 15.7247 8.57751Z"
          fill="white"
        />
        <path
          d="M11.6705 6.84618C11.7522 6.84735 11.8333 6.83201 11.9089 6.80108C11.9845 6.77015 12.0531 6.72427 12.1105 6.66618C12.2299 6.55309 12.2994 6.39722 12.3038 6.23285V5.17285C12.3051 5.08957 12.2891 5.00694 12.257 4.93009C12.2249 4.85325 12.1773 4.78384 12.1172 4.72618C12.0589 4.66692 11.9893 4.61986 11.9126 4.58773C11.836 4.55561 11.7536 4.53906 11.6705 4.53906C11.5874 4.53906 11.5051 4.55561 11.4284 4.58773C11.3517 4.61986 11.2822 4.66692 11.2238 4.72618C11.1637 4.78384 11.1161 4.85325 11.084 4.93009C11.0519 5.00694 11.036 5.08957 11.0372 5.17285C11.0372 5.53285 11.0372 5.89952 11.0372 6.25952C11.0416 6.42388 11.1112 6.57976 11.2305 6.69285C11.2901 6.74615 11.3597 6.78713 11.4352 6.81344C11.5107 6.83976 11.5907 6.85089 11.6705 6.84618Z"
          fill="white"
        />
        <path
          d="M18.7117 11.6709H17.6184C17.456 11.6782 17.3019 11.7446 17.185 11.8576C17.073 11.9771 17.0089 12.1338 17.005 12.2976C17.0013 12.4603 17.0611 12.6181 17.1717 12.7376C17.2876 12.853 17.4418 12.9218 17.605 12.9309H18.725C18.8902 12.924 19.046 12.8521 19.1584 12.7309C19.215 12.673 19.2591 12.6041 19.2878 12.5283C19.3164 12.4526 19.3291 12.3718 19.325 12.2909C19.3241 12.1285 19.2595 11.9728 19.145 11.8576C19.0282 11.7446 18.8741 11.6782 18.7117 11.6709Z"
          fill="white"
        />
        <path
          d="M11.6569 13.7059C11.8337 13.7059 12.0033 13.6356 12.1283 13.5106C12.2533 13.3856 12.3236 13.216 12.3236 13.0392V10.4059C12.3482 10.3076 12.3501 10.205 12.3291 10.1058C12.3082 10.0067 12.2649 9.91359 12.2025 9.83369C12.1402 9.7538 12.0604 9.68916 11.9694 9.64471C11.8783 9.60025 11.7783 9.57715 11.6769 9.57715C11.5756 9.57715 11.4756 9.60025 11.3845 9.64471C11.2934 9.68916 11.2137 9.7538 11.1513 9.83369C11.089 9.91359 11.0457 10.0067 11.0247 10.1058C11.0037 10.205 11.0056 10.3076 11.0303 10.4059V13.0126C11.023 13.1872 11.0845 13.3577 11.2018 13.4874C11.319 13.6171 11.4824 13.6955 11.6569 13.7059Z"
          fill="white"
        />
        <path
          d="M11.6658 15.7049C11.7666 15.705 11.8664 15.6845 11.9591 15.6449C12.0522 15.6106 12.1365 15.556 12.2058 15.4849C12.2771 15.412 12.3337 15.326 12.3724 15.2316C12.4068 15.1376 12.4248 15.0384 12.4258 14.9383C12.4273 14.8395 12.4068 14.7415 12.3658 14.6516C12.3285 14.5584 12.2717 14.4743 12.1991 14.4049C12.0947 14.2963 11.9596 14.2221 11.8118 14.1923C11.6641 14.1625 11.5108 14.1786 11.3724 14.2383C11.281 14.2788 11.1975 14.3353 11.1258 14.4049C10.9886 14.5492 10.9102 14.7393 10.9058 14.9383C10.9067 15.0384 10.9248 15.1376 10.9591 15.2316C10.9978 15.326 11.0544 15.412 11.1258 15.4849C11.2717 15.6236 11.4645 15.7022 11.6658 15.7049Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2293_26446">
          <rect
            width="15.32"
            height="14.6267"
            fill="white"
            transform="translate(4.00537 4.51074)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UrgentRed;
