import React, { useState } from "react";
import { Card, Divider } from "antd";
import PropertyIcon from "../../assets/svg/PropertyIcon";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import PersonIconGreenOutline from "../../assets/svg/PersonIconGreenOutline";
import EditProperty from "./EditProperty";
import EditIconInfo from "../../assets/svg/EditIconInfo";
import EditPropertySignUp from "./EditPropertySignUp";

const MyPropertyCardSignUp = ({ data, getMyProperties }) => {
  const [openNewPropertyModal, setOpenNewPropertyModal] = useState(false);
  const [openEditPropertyModal, setOpenEditPropertyModal] = useState(false);

  return (
    <Card
      className="rounded-xl bg-primaryLightest border-primaryLight border-[1px]"
      bordered={false}
    >
      <div className="flex justify-between items-center">
        <div>
          <span className="flex flex-row gap-1 justify-start items-center">
            <PropertyIcon />
            <p className="text-xl md:text-2xl font-bold">
              {data?.property_name.length > 50
                ? data?.property_name?.substring(0, 50) + "..."
                : data?.property_name}
            </p>
          </span>
          <p className="text-base font-normal">
            {data?.address}, {data?.state}, {data?.suburb}&nbsp;
            {data?.postal_code}
            ,&nbsp;
            {data?.country}
          </p>
        </div>

        <div className="flex flex-row gap-2 items-center">
          {data?.is_default === 1 && (
            <div className="bg-[#2C8B52] rounded-lg text-xs px-2 text-white w-fit h-fit">
              Default
            </div>
          )}
          <div
            className="cursor-pointer scale-75"
            onClick={setOpenEditPropertyModal}
          >
            <EditIconInfo />
          </div>
        </div>
      </div>

      <Divider className="bg-primaryColor my-3" />

      <div className="flex flex-col sm:flex-row sm:space-x-4 text-sm mt-2">
        <p className="flex flex-row gap-1 justify-start items-center">
          <LocationIconCard /> Property Type:
          <span className="font-semibold">{data?.property_type}</span>
        </p>

        <div className="border-l-[2px] h-6 border-primaryColor hidden sm:block"></div>

        <p className="flex flex-row gap-1 justify-start items-center">
          <PersonIconGreenOutline /> Role:
          <span className="font-semibold">{data?.property_role}</span>
        </p>
      </div>

      {openNewPropertyModal && (
        <EditProperty
          open={openNewPropertyModal}
          onCancel={() => {
            setOpenNewPropertyModal(false);
          }}
          getMyProperties={getMyProperties}
          propertyData={data}
        />
      )}

      {openEditPropertyModal && (
        <EditPropertySignUp
          open={openEditPropertyModal}
          onCancel={() => {
            setOpenEditPropertyModal(false);
          }}
          getMyProperties={getMyProperties}
          propertyData={data}
        />
      )}
    </Card>
  );
};

export default MyPropertyCardSignUp;
