import React, { useContext, useEffect } from "react";
import { Modal, Spin } from "antd";
import { useState } from "react";
import { Input } from "antd";
import { Typography } from "antd";
import SearchIcon from "../../assets/svg/SearchIcon";
import { AddCategoryCardSignup } from "./cards/AddCategoryCardSignup";
import PublicProfileService from "../../services/PublicProfileService";
import { NotificationContext } from "../../context/NotificationContext";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";

const { Text } = Typography;

const SignupAddServices = ({ open, onClose }) => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const { workerFormValues } = useContext(WorkerSignUpContext);
  const { allCategoryList } = PublicProfileService();

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [rawCategoryList, setRawCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    setLoading(true);
    try {
      await allCategoryList({
        category_id: 0,
        country_id: workerFormValues?.country,
      })
        .then((response) => {
          if (response?.data?.success) {
            setRawCategoryList(response?.data.output);
            setCategoryList(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setLoading(false);
    } catch (error) {
      openNotification("error", error);
      setLoading(false);
    }
  };

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    const searchResult = rawCategoryList.filter((item) =>
      item.job_category.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCategoryList(searchResult);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width={1000}
      title="Add Services"
    >
      <div className="flex flex-col">
        <div className="py-3">
          <Input
            value={searchTerm}
            onChange={onSearch}
            suffix={<SearchIcon className="w-6 h-6" />}
            placeholder="Search services (Ex : Cleaning)"
            className="border border-primaryDarkest px-4 py-2"
            style={{ borderRadius: 40 }}
          />
        </div>

        <div className="h-[63vh] overflow-y-auto">
          {loading ? (
            <div className="flex w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {categoryList?.length >= 0 ? (
                categoryList?.map((item) => (
                  <AddCategoryCardSignup key={item?.id} data={item} />
                ))
              ) : (
                <Text>No services found</Text>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SignupAddServices;
