import React from "react";

const SmallI = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.17591"
        cy="5.53382"
        r="4.97054"
        stroke="#828282"
        strokeWidth="0.83751"
      />
      <circle cx="6.1752" cy="3.7255" r="0.569245" fill="#828282" />
      <path
        d="M6.19604 5.11914V7.91108"
        stroke="#828282"
        strokeWidth="0.83751"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SmallI;
