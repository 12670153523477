import React from "react";

const Instagram = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6447_30410)">
        <path
          d="M36.6869 29.6895V6.97157C36.6869 3.12168 33.5659 0.000732422 29.7161 0.000732422L6.99816 0.000732422C3.14828 0.000732422 0.0273247 3.12168 0.0273247 6.97157V29.6895C0.0273247 33.5393 3.14828 36.6603 6.99816 36.6603H29.7161C33.5659 36.6603 36.6869 33.5393 36.6869 29.6895Z"
          fill="url(#paint0_linear_6447_30410)"
        />
        <path
          d="M23.5568 14.3208C24.1958 14.3208 24.7138 13.8027 24.7138 13.1637C24.7138 12.5246 24.1958 12.0066 23.5568 12.0066C22.9177 12.0066 22.3997 12.5246 22.3997 13.1637C22.3997 13.8027 22.9177 14.3208 23.5568 14.3208Z"
          fill="white"
        />
        <path
          d="M18.4454 13.0376C17.399 13.0376 16.3761 13.3479 15.506 13.9293C14.6359 14.5106 13.9578 15.3369 13.5573 16.3037C13.1569 17.2705 13.0521 18.3343 13.2563 19.3606C13.4604 20.3869 13.9643 21.3296 14.7042 22.0696C15.4442 22.8095 16.3869 23.3134 17.4132 23.5176C18.4395 23.7217 19.5034 23.6169 20.4701 23.2165C21.4369 22.816 22.2632 22.1379 22.8446 21.2678C23.4259 20.3978 23.7362 19.3748 23.7362 18.3284C23.7329 16.9262 23.1744 15.5824 22.1829 14.5909C21.1914 13.5994 19.8476 13.0409 18.4454 13.0376ZM18.4454 21.7261C17.7759 21.7261 17.1214 21.5275 16.5647 21.1556C16.0081 20.7836 15.5742 20.2549 15.318 19.6364C15.0618 19.0178 14.9947 18.3372 15.1253 17.6805C15.256 17.0239 15.5784 16.4207 16.0518 15.9473C16.5252 15.4739 17.1284 15.1515 17.785 15.0209C18.4417 14.8903 19.1223 14.9573 19.7408 15.2135C20.3594 15.4697 20.8881 15.9036 21.26 16.4603C21.632 17.017 21.8305 17.6714 21.8305 18.3409C21.8239 19.2355 21.4648 20.0914 20.831 20.7229C20.1973 21.3543 19.34 21.7103 18.4454 21.7135V21.7261Z"
          fill="white"
        />
        <path
          d="M22.6436 29.0747H14.068C12.3566 29.0714 10.7162 28.39 9.50602 27.1799C8.29585 25.9697 7.61452 24.3293 7.61121 22.6179V14.0422C7.61452 12.3308 8.29585 10.6904 9.50602 9.48026C10.7162 8.27009 12.3566 7.58876 14.068 7.58545H22.6436C24.3551 7.58876 25.9955 8.27009 27.2056 9.48026C28.4158 10.6904 29.0971 12.3308 29.1004 14.0422V22.6179C29.0971 24.3293 28.4158 25.9697 27.2056 27.1799C25.9955 28.39 24.3551 29.0714 22.6436 29.0747ZM14.068 9.55383C13.4767 9.54711 12.89 9.65862 12.3424 9.8818C11.7948 10.105 11.2974 10.4353 10.8792 10.8535C10.4611 11.2716 10.1307 11.7691 9.90756 12.3167C9.68438 12.8643 9.57287 13.451 9.57959 14.0422V22.6179C9.57287 23.2092 9.68438 23.7958 9.90756 24.3434C10.1307 24.891 10.4611 25.3885 10.8792 25.8066C11.2974 26.2248 11.7948 26.5551 12.3424 26.7783C12.89 27.0015 13.4767 27.113 14.068 27.1063H22.6436C23.2349 27.113 23.8216 27.0015 24.3692 26.7783C24.9168 26.5551 25.4143 26.2248 25.8324 25.8066C26.2505 25.3885 26.5809 24.891 26.8041 24.3434C27.0273 23.7958 27.1388 23.2092 27.132 22.6179V14.0422C27.1388 13.451 27.0273 12.8643 26.8041 12.3167C26.5809 11.7691 26.2505 11.2716 25.8324 10.8535C25.4143 10.4353 24.9168 10.105 24.3692 9.8818C23.8216 9.65862 23.2349 9.54711 22.6436 9.55383H14.068Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6447_30410"
          x1="37.9908"
          y1="-1.3157"
          x2="-11.783"
          y2="48.4455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE185" />
          <stop offset="0.21" stop-color="#FFBB36" />
          <stop offset="0.38" stop-color="#FF5176" />
          <stop offset="0.52" stop-color="#F63395" />
          <stop offset="0.74" stop-color="#A436D2" />
          <stop offset="1" stop-color="#5F4EED" />
        </linearGradient>
        <clipPath id="clip0_6447_30410">
          <rect
            width="36.6603"
            height="36.6603"
            fill="white"
            transform="translate(0.0270691)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
