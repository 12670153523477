import React, { useEffect } from "react";
import NavBar from "../../components/navbar/NavBar";
import { JobPostSuccess } from "../../assets/svg/JobPostSuccess";
import { Button, Col, Image, Row, Typography } from "antd";
import phoneImage from "../../assets/img/greenPhone.webp";
import { AiSearch } from "../../assets/svg/AiSearch";
import { ChatTasker } from "../../assets/svg/ChatTasker";
import { FavouriteWorkers } from "../../assets/svg/FavouriteWorkers";
import { CustomizedOffers } from "../../assets/svg/CustomizedOffers";
import { JobManagementAndRating } from "../../assets/svg/JobManagementAndRating";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export const NewJobPostSuccess = () => {
  const navigate = useNavigate();

  const ListArray = [
    { icon: <AiSearch />, title: "AI Search assistant" },
    { icon: <ChatTasker />, title: "Chat with workers" },
    { icon: <FavouriteWorkers />, title: "Favorite workers list" },
    { icon: <CustomizedOffers />, title: "Get customized offers" },
    { icon: <JobManagementAndRating />, title: "Job management & rating" },
  ];
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Trigger form submit
        document.querySelector("button[type='submit']").click();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup event listener when component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    // Prevent going back to the previous page
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", () => {
      navigate("../my-jobs"); // Redirect user to a safe page like the job list
    });

    // Cleanup event listener
    return () => {
      window.removeEventListener("popstate", () => {});
    };
  }, [navigate]);

  return (
    <div>
      <NavBar />
      <div className="content flex flex-col justify-center items-center my-28">
        <div className="flex flex-col justify-center items-center">
          <JobPostSuccess />
          <Text className="text-md font-medium text-textColorFive pt-2">
            Your job posted successfully!
          </Text>
          <Button
            type="primary"
            className="text-sm font-medium bg-primaryDarkest w-72 h-10 mt-4 mb-6"
            onClick={() => {
              navigate("../my-jobs");
            }}
            htmlType="submit"
          >
            View My Jobs
          </Button>
        </div>

        <div className="flex justify-center items-center mt-5">
          <div className="bg-primaryLightest max-w-[843px] p-5 rounded-lg">
            <Row gutter={32}>
              <Col className="hidden lg:block">
                <Image
                  src={phoneImage}
                  width={134}
                  height={275}
                  preview={false}
                />
              </Col>
              <Col>
                <div className="xsm:max-w-[400px]">
                  <p className="text-4xl font-bold text-primaryDarkest leading-10 text-center xsm:text-start">
                    Haven’t Downloaded The Mobile app yet?
                  </p>
                </div>

                <div>
                  <Row
                    gutter={32}
                    className="mt-5 flex justify-center items-center"
                  >
                    {ListArray?.map((item) => {
                      return (
                        <Col className="mb-5">
                          <div className="p-2 rounded-lg bg-white w-[101px] h-28 flex flex-col items-center justify-center">
                            {item?.icon}
                            <Text className="text-sm font-medium text-textColorThree text-center">
                              {item?.title}
                            </Text>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
