import React from "react";

const FillInsta = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.8286 63.228V17.7695C76.8286 10.0658 70.5836 3.8208 62.88 3.8208L17.4214 3.8208C9.71778 3.8208 3.47276 10.0658 3.47276 17.7695V63.228C3.47276 70.9316 9.71778 77.1767 17.4214 77.1767H62.88C70.5836 77.1767 76.8286 70.9316 76.8286 63.228Z"
        fill="url(#paint0_linear_3173_113489)"
      />
      <path
        d="M51.5412 31.72C52.9406 31.72 54.075 30.5856 54.075 29.1862C54.075 27.7868 52.9406 26.6523 51.5412 26.6523C50.1418 26.6523 49.0073 27.7868 49.0073 29.1862C49.0073 30.5856 50.1418 31.72 51.5412 31.72Z"
        fill="white"
      />
      <path
        d="M40.3281 29.9087C38.2342 29.9087 36.1874 30.5296 34.4464 31.6929C32.7053 32.8562 31.3484 34.5097 30.5471 36.4442C29.7458 38.3787 29.5361 40.5074 29.9446 42.561C30.3531 44.6147 31.3614 46.5011 32.8421 47.9817C34.3227 49.4623 36.2091 50.4706 38.2627 50.8791C40.3164 51.2876 42.4451 51.078 44.3796 50.2767C46.3141 49.4754 47.9675 48.1184 49.1308 46.3774C50.2942 44.6364 50.9151 42.5895 50.9151 40.4956C50.9084 37.6898 49.7909 35.0008 47.8069 33.0168C45.8229 31.0329 43.1339 29.9153 40.3281 29.9087ZM40.3281 47.2943C38.9884 47.2943 37.6788 46.8971 36.5649 46.1528C35.451 45.4085 34.5828 44.3506 34.0701 43.1129C33.5574 41.8751 33.4233 40.5132 33.6847 39.1992C33.946 37.8853 34.5912 36.6783 35.5385 35.731C36.4858 34.7837 37.6927 34.1386 39.0067 33.8772C40.3206 33.6159 41.6826 33.75 42.9203 34.2627C44.158 34.7754 45.2159 35.6436 45.9602 36.7575C46.7045 37.8714 47.1018 39.181 47.1018 40.5207C47.0886 42.3108 46.3699 44.0234 45.1017 45.2869C43.8336 46.5504 42.1183 47.2627 40.3281 47.2692V47.2943Z"
        fill="white"
      />
      <path
        d="M48.731 61.9975H31.5712C28.1466 61.9909 24.8642 60.6276 22.4426 58.206C20.0211 55.7845 18.6578 52.502 18.6511 49.0775V31.9176C18.6578 28.493 20.0211 25.2106 22.4426 22.7891C24.8642 20.3675 28.1466 19.0042 31.5712 18.9976H48.731C52.1556 19.0042 55.438 20.3675 57.8596 22.7891C60.2811 25.2106 61.6445 28.493 61.6511 31.9176V49.0775C61.6445 52.502 60.2811 55.7845 57.8596 58.206C55.438 60.6276 52.1556 61.9909 48.731 61.9975ZM31.5712 22.9363C30.388 22.9229 29.2141 23.146 28.1183 23.5926C27.0226 24.0392 26.0272 24.7002 25.1905 25.5369C24.3538 26.3736 23.6927 27.369 23.2461 28.4648C22.7995 29.5605 22.5764 30.7344 22.5899 31.9176V49.0775C22.5764 50.2606 22.7995 51.4346 23.2461 52.5303C23.6927 53.6261 24.3538 54.6215 25.1905 55.4582C26.0272 56.2949 27.0226 56.9559 28.1183 57.4025C29.2141 57.8491 30.388 58.0722 31.5712 58.0588H48.731C49.9142 58.0722 51.0881 57.8491 52.1839 57.4025C53.2796 56.9559 54.2751 56.2949 55.1118 55.4582C55.9484 54.6215 56.6095 53.6261 57.0561 52.5303C57.5027 51.4346 57.7258 50.2606 57.7124 49.0775V31.9176C57.7258 30.7344 57.5027 29.5605 57.0561 28.4648C56.6095 27.369 55.9484 26.3736 55.1118 25.5369C54.2751 24.7002 53.2796 24.0392 52.1839 23.5926C51.0881 23.146 49.9142 22.9229 48.731 22.9363H31.5712Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3173_113489"
          x1="79.4377"
          y1="1.18661"
          x2="-20.1597"
          y2="100.759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE185" />
          <stop offset="0.21" stopColor="#FFBB36" />
          <stop offset="0.38" stopColor="#FF5176" />
          <stop offset="0.52" stopColor="#F63395" />
          <stop offset="0.74" stopColor="#A436D2" />
          <stop offset="1" stopColor="#5F4EED" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FillInsta;
