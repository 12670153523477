import React, { useContext, useState } from "react";
import EditIconInfo from "../../assets/svg/EditIconInfo";
import { Button, Typography } from "antd";
import PublicURLEditor from "../../components/workerProfile/PublicURLEditor";
import { NotificationContext } from "../../context/NotificationContext";

const { Text } = Typography;

const PublicURL = ({ data, getWorkerProfileData }) => {
  const publicUrl = process.env.REACT_APP_DOMAIN;
  const { openNotification } = useContext(NotificationContext);
  const [openPublicUrlEditor, setOpenPublicUrlEditor] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(`${publicUrl}${data?.username}`)
      .then(() => {
        openNotification("success", "URL copied to clipboard!");
      })
      .catch(() => {
        openNotification("warning", "Failed to copy URL!");
      });
  };

  return (
    <div className="flex flex-col gap-2 bg-white rounded-lg p-4 shadow-lg w-full">
      <div className="flex flex-row w-full gap-2 justify-between items-center">
        <Text className="flex text-xl font-bold text-primaryDarkest">
          Public Profile URL
        </Text>
        <div
          className="cursor-pointer scale-75"
          onClick={() => {
            setOpenPublicUrlEditor(true);
          }}
        >
          <EditIconInfo />
        </div>
      </div>
      {data?.username ? (
        <div className="flex flex-row w-full gap-2 justify-between p-2 bg-[#F0F0F0] rounded-md">
          <div className="flex flex-row items-center">
            <Text className="flex text-base font-medium text-textColorTwo w-full">
              {`${publicUrl + data?.username}`}
            </Text>
          </div>
          <Button
            className="min-w-32 font-medium text-base h-12"
            onClick={copyToClipboard}
          >
            Copy
          </Button>
        </div>
      ) : (
        <div className="flex flex-row w-full gap-2 justify-between p-2 bg-[#F0F0F0] rounded-md">
          No Public URL
        </div>
      )}

      {openPublicUrlEditor && (
        <PublicURLEditor
          open={openPublicUrlEditor}
          onClose={() => {
            setOpenPublicUrlEditor(false);
          }}
          getWorkerProfileData={getWorkerProfileData}
          data={data}
        />
      )}
    </div>
  );
};

export default PublicURL;
