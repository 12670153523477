import React from "react";

const CorrectIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4922 11.8926V12.8126C22.491 14.969 21.7927 17.0673 20.5015 18.7944C19.2104 20.5216 17.3955 21.7851 15.3275 22.3965C13.2596 23.0079 11.0494 22.9345 9.02666 22.1872C7.00387 21.4399 5.27684 20.0587 4.10315 18.2497C2.92945 16.4406 2.37198 14.3007 2.51386 12.1489C2.65575 9.99717 3.48939 7.94893 4.89047 6.30968C6.29154 4.67043 8.18497 3.52799 10.2884 3.05275C12.3918 2.57752 14.5925 2.79495 16.5622 3.67261"
        stroke="#2C8B52"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.4912 4.8125L12.4912 14.8225L9.49121 11.8225"
        stroke="#2C8B52"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CorrectIcon;
