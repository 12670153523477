import { createContext, useState } from "react";

export const MyPublicProfileContext = createContext({});

export function MyPublicProfileContextProvider({ children }) {
  const [editServicesSteps, setEditServicesSteps] = useState("FIRST_STEP");
  return (
    <MyPublicProfileContext.Provider value={{editServicesSteps, setEditServicesSteps}}>
      {children}
    </MyPublicProfileContext.Provider>
  );
}
