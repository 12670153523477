import React, { useContext, useEffect } from "react";
import { Modal, Button } from "antd";
import RegiClient from "../../assets/img/regiClient.png";
import RegiWorker from "../../assets/img/regiWorker.png";
import { useNavigate } from "react-router-dom";
import { WorkerSignUpContext } from "../../context/WorkerSignUpContext";

const SignUpSelection = ({ open, onCancel, width }) => {
  const navigate = useNavigate();
  const {
    setWorkerFormValues,
    setInitialServicesList,
    setServicesAreaSuburbList,
    setSelectedServicesAreaSuburbList,
  } = useContext(WorkerSignUpContext);

  useEffect(() => {
    setInitialServicesList([]);
    setServicesAreaSuburbList([]);
    setSelectedServicesAreaSuburbList([]);

    setWorkerFormValues({
      name: null,
      country: null,
      postalCode: null,
      state: null,
      suburb: null,
      email: null,
      phoneNumber: null,
      dialCode: null,
      countryCode: null,
      isPostalCodeRequired: 0,
      isEmailRequired: 0,
      currency: "USD",
    });
  }, []);

  return (
    <Modal open={open} footer={null} onCancel={onCancel} centered width={width}>
      <div className="flex flex-col md:flex-row mt-5 md:mt-0">
        {/* Worker Section */}
        <div className="flex-1 bg-primaryLightest p-6 flex flex-col">
          <div className="flex justify-center items-center">
            <img
              src={RegiWorker}
              alt="Worker Registration"
              className="w-32 md:w-48 mb-5"
            />
          </div>

          <h2 className="text-xl font-bold text-primaryDarkest">
            I'm a Worker, <br /> Looking for New Jobs!
          </h2>
          <p className="mt-2 mb-5">
            Over 1,000 new job opportunities are waiting for you. Sign up in
            just 2 minutes to get started.
          </p>
          <Button
            type="primary"
            size="large"
            className="bg-primaryDarkest"
            onClick={() => {
              setWorkerFormValues({
                name: null,
                country: null,
                postalCode: null,
                state: null,
                suburb: null,
                email: null,
                phoneNumber: null,
                dialCode: null,
                countryCode: null,
                isPostalCodeRequired: 0,
                isEmailRequired: 0,
                currency: "USD",
              });
              navigate("../worker-sign-up", {
                state: { from: "directRegi", type: 6 },
              });
            }}
          >
            Register as a Worker
          </Button>
        </div>

        {/* Client Section */}
        <div className="flex-1 bg-white p-6 flex flex-col">
          <div className="flex justify-center items-center">
            <img
              src={RegiClient}
              alt="Client Registration"
              className="w-32 md:w-48 mb-5"
            />
          </div>

          <h2 className="text-xl font-bold text-primaryDarkest">
            I'm a Client, <br /> Looking to Hire Someone!
          </h2>
          <p className="mt-2 mb-5">
            Find skilled professionals near you for any job, anytime! Sign up to
            get started.
          </p>
          <Button
            type="primary"
            size="large"
            className="bg-primaryDarkest"
            onClick={() => {
              navigate("../quick-sign-up", {
                state: { from: "directRegi", type: 2 },
              });
            }}
          >
            Register as a Client
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpSelection;
